import { commonComponents as cc } from '@/components/common';
import { ui } from '@/components/ui';
import { selectFormByIdQueryOptions, useSelectFormById } from '@/features/forms/api/selectFormById';
import FormPage from '@/features/pages/components/FormPage';
import { createFileRoute } from '@tanstack/react-router';

const Preview = () => {
	const { formId, pageIndex } = Route.useParams();
	const { data: form } = useSelectFormById(formId);

	return (
		<section className="flex w-full flex-col gap-4 px-2">
			<cc.PhonePreview>
				<div className="flex items-center justify-between">
					<h4 className="pb-2 text-xl text-slate-800">{form?.form_name}</h4>
					<ui.Button className="pointer-events-none cursor-not-allowed" variant="link" size="sm" className="px-0">
						Edit
					</ui.Button>
				</div>
				<FormPage
					pageIndex={Number(pageIndex)}
					pages={form?.pages}
					// page={form?.pages?.find((p) => p.page_index === pageIndex)}
					diaryName={form?.form_name}
					route={Route}
				/>
			</cc.PhonePreview>
		</section>
	);
};

export const Route = createFileRoute(
	'/_app/builder/tracker-templates/$formId/page-input/$pageInputId/input/$inputId/page/$pageIndex/',
)({
	component: Preview,
	loader: async ({ context, params }) => {
		return await context?.queryClient.ensureQueryData(selectFormByIdQueryOptions(params.formId));
	},
});
