import { useSelectFormById } from '@/features/forms/api/selectFormById';
import FormPage from '@/features/pages/components/FormPage';
import type { FormViewT, PageViewT } from '@/types/database/forms';
import { createFileRoute } from '@tanstack/react-router';

const Page = () => {
	const { pageIndex, formId } = Route.useParams();
	const { data: form } = useSelectFormById(formId);

	return (
		<section className="md-width">
			<FormPage
				pageIndex={Number(pageIndex)}
				form={form as FormViewT}
				route={Route}
				page={form?.pages?.find((p: PageViewT) => p.page_index === Number(pageIndex)) as PageViewT}
			/>
		</section>
	);
};

export const Route = createFileRoute('/public/form/$formId/page/$pageIndex/')({
	component: Page,
});
