import React from "react";

const Invited = () => {
  return (
    <svg
      role="img"
      aria-label="Invited"
      width="70"
      height="70"
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="6" y="16" width="57" height="37" rx="4" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58.4893 17.4174H10.7903C9.57295 17.4174 8.58609 18.4042 8.58609 19.6216V49.3784C8.58609 50.5957 9.57295 51.5826 10.7903 51.5826H58.4893C59.7066 51.5826 60.6935 50.5957 60.6935 49.3784V19.6216C60.6935 18.4042 59.7066 17.4174 58.4893 17.4174ZM10.7903 14.1111C7.74693 14.1111 5.27979 16.5782 5.27979 19.6216V49.3784C5.27979 52.4217 7.74692 54.8889 10.7903 54.8889H58.4893C61.5326 54.8889 63.9998 52.4217 63.9998 49.3784V19.6216C63.9998 16.5782 61.5326 14.1111 58.4893 14.1111H10.7903Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1425 21.2722C12.6918 20.5582 13.7159 20.4246 14.4299 20.9738L34.6398 36.5199L54.8498 20.9738C55.5638 20.4246 56.5879 20.5582 57.1371 21.2722C57.6864 21.9862 57.5528 23.0103 56.8388 23.5595L41.4257 35.4158L56.7332 45.3656C57.4885 45.8565 57.7028 46.8668 57.2118 47.6221C56.7209 48.3774 55.7106 48.5917 54.9553 48.1008L38.6842 37.5246L36.6289 39.1057C35.4562 40.0077 33.8234 40.0077 32.6508 39.1057L30.5956 37.5247L14.3246 48.1008C13.5693 48.5917 12.5591 48.3774 12.0681 47.6221C11.5772 46.8668 11.7915 45.8565 12.5468 45.3656L27.8541 35.4158L12.4409 23.5595C11.7269 23.0103 11.5933 21.9862 12.1425 21.2722Z"
        fill="black"
      />
      <rect width="70" height="70" fill="white" fillOpacity="0.01" />
    </svg>
  );
};

export default Invited;
