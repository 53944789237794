import { ui } from '@/components/ui';
import type { ProcessedScheduleT } from '@/types/database/forms';
import dayjs from 'dayjs';
import { Lock, Unlock } from 'lucide-react';

interface Props {
	processedSchedule: ProcessedScheduleT[];
}

const TimingPreview = (props: Props) => {
	return (
		<ui.Card className="flex flex-col w-full h-full md:min-h-[50vh]">
			<ui.CardHeader>
				<ui.CardTitle>Preview (Starting Today)</ui.CardTitle>
			</ui.CardHeader>
			<ui.CardContent className="flex flex-col gap-y-2 max-h-[600px] overflow-scroll">
				<ui.Table>
					<ui.TableHeader>
						<ui.TableRow>
							<ui.TableHead className="sr-only">Schedule Index</ui.TableHead>
							<ui.TableHead>Label</ui.TableHead>
							<ui.TableHead>Due Date</ui.TableHead>
							<ui.TableHead>Time from Now</ui.TableHead>
							<ui.TableHead>Locked</ui.TableHead>
						</ui.TableRow>
					</ui.TableHeader>
					<ui.TableBody>
						{props.processedSchedule.map((time, index) => (
							<ui.TableRow key={time.due_entry_id}>
								<ui.TableCell>{index + 1}</ui.TableCell>
								<ui.TableCell className="font-medium">{time.label}</ui.TableCell>
								<ui.TableCell>{dayjs(time.due_at).format('HH:mm DD MMM YYYY')}</ui.TableCell>
								<ui.TableCell>{dayjs(time.due_at).fromNow()}</ui.TableCell>
								<ui.TableCell>{dayjs().isBefore(time.due_at) ? <Lock /> : <Unlock />}</ui.TableCell>
							</ui.TableRow>
						))}
					</ui.TableBody>
				</ui.Table>
			</ui.CardContent>
		</ui.Card>
	);
};

export default TimingPreview;
