import { ui } from "@/components/ui";
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "@/components/ui/tooltip";
import { Route as PreviewDiaryRoute } from "@/routes/preview/patient/$patientId/diary/$diaryId";
import { Printer } from "lucide-react";
import { useNavigateAndPrint } from "@/features/previews/hooks/useNavigateAndPrint";

interface Props {
	diaryId: string;
	patientId: string;
}

const DownloadButton = ({ diaryId, patientId }: Props) => {
	const { navigateAndPrint, isPrinting } = useNavigateAndPrint();

	const handlePrint = () => {
		console.log("handlePrint");
		navigateAndPrint({
			to: PreviewDiaryRoute.to,
			params: { diaryId, patientId },
			search: {},
		});
	};

	return (
		<TooltipProvider>
			<Tooltip>
				<TooltipTrigger asChild>
					<ui.Button
						onClick={handlePrint}
						className="px-2 w-fit"
						variant="ghost"
						size="sm"
						disabled={isPrinting}
					>
						<Printer />
					</ui.Button>
				</TooltipTrigger>
				<TooltipContent>
					<p>Print to PDF</p>
				</TooltipContent>
			</Tooltip>
		</TooltipProvider>
	);
};

export default DownloadButton;
