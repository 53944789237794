import { supabaseClient } from '@/lib/supabase';
import type { UuidT } from '@/types/zod/auth';
import { queryOptions, useSuspenseQuery } from '@tanstack/react-query';

export async function getDiariesByPatient(patientId: UuidT) {
	const { data, error } = await supabaseClient.functions.invoke('public-get-diaries', {
		body: { patient_id: patientId },
	});

	if (error) throw error;

	return data;
}

export const patientDiariesQueryOptions = (patientId: string) =>
	queryOptions({
		queryKey: ['patient-diaries', { patient_id: patientId }],
		queryFn: () => getDiariesByPatient(patientId),
	});

export const usePatientDiariesQuery = (patientId: string) => {
	return useSuspenseQuery(patientDiariesQueryOptions(patientId));
};
