import { cn } from '@/lib/utils';

interface RatingButtonProps {
	value: string;
	isSelected: boolean;
	isFocused: boolean;
	onClick: () => void;
	onFocus: () => void;
	onBlur: () => void;
}

function RatingButton({ value, isSelected, isFocused, onClick, onFocus, onBlur }: RatingButtonProps) {
	return (
		<button
			type="button"
			onClick={onClick}
			onFocus={onFocus}
			onBlur={onBlur}
			aria-checked={isSelected}
			role="radio"
			className={cn(
				'rounded-md h-12 w-12 text-lg transition-all duration-300 ease-in-out',
				'focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2',
				isSelected ? 'bg-indigo-600 text-white' : 'bg-slate-200 text-slate-700 hover:bg-slate-300',
				isFocused && 'ring-2 ring-indigo-500 ring-offset-2',
			)}
		>
			{value}
		</button>
	);
}

export default RatingButton;
