import { supabaseClient } from '@/lib/supabase';
import { useMutation } from '@tanstack/react-query';
import { useNavigate, useSearch } from '@tanstack/react-router';
import { toast } from 'sonner';

import useCreateImageLabelMutation from './useCreateImageLabelMutation';

export type Images = {
	RT: File;
	LT: File;
	RS: File;
	LS: File;
};

interface ImageDetails {
	image: File;
	imagePath: string;
	footLogId: string;
}

const uploadImage = async (image: File, imagePath: string, footLogId: string) => {
	return supabaseClient.storage
		.from('foot_watch')
		.upload(imagePath, image, {
			cacheControl: '3600',
			upsert: false,
		})
		.then((result) => {
			return { footLogId: footLogId, imagePath: imagePath, result: result.data };
		});
};

const useUploadImageMutation = () => {
	const { imageUploads } = useSearch({ strict: false });
	const navigate = useNavigate();
	const createImageLabel = useCreateImageLabelMutation();

	return useMutation({
		mutationKey: ['uploadingImage'],
		mutationFn: async (data: ImageDetails) => {
			return uploadImage(data.image, data.imagePath, data.footLogId);
		},
		onError: (error) => {
			console.error(error);
			toast.error(error.message);
		},
		onSuccess: (data) => {
			toast.success('Successfully uploaded image');
			navigate({ search: { imageUploads: Number.parseInt(imageUploads) + 1 } });
			createImageLabel.mutate({ imagePath: data.imagePath, footLogId: data.footLogId });
			return 'ok';
		},
	});
};

const useUploadImages = () => {
	const uploadImage = useUploadImageMutation();

	const uploadImages = async (images: Images, userId: string, now: string, footLogId: string) => {
		const uploadPromises = Object.entries(images).map(async ([variation, image]) => {
			const imagePath = `${userId}/${now}/${variation}`;
			uploadImage.mutate({ image: image, imagePath: imagePath, footLogId: footLogId });
		});

		const results = await Promise.all(uploadPromises);

		const successes = results.filter((result) => result);
		if (successes.length === 4) {
			return 'ok';
		} else {
			toast.error('Error while uploading');
		}
	};

	return uploadImages;
};

export default useUploadImages;
