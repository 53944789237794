import { ui } from '@/components/ui';
import type { Watch, WatchDetail } from '@/definitions/diaryTypes';
import type { DiaryTemplateT, ExtendedFavouriteDiaryTemplateT } from '@/types/database/diaries';
import { Star } from 'lucide-react';
import React from 'react';
import { extractFavouriteDiaries } from '../../builder/services/separateTemplatesByUser';
import DiaryTemplateCard from './DiaryTemplateCard';

export interface FaveWatchT extends WatchDetail {
	favourite: boolean;
	watchKey: Watch;
}

interface Props {
	templates: DiaryTemplateT[];
	favouriteDiaryTemplates: ExtendedFavouriteDiaryTemplateT[];
	diaryId: string;
	patientId: string;
	organisationId: string;
	createable: boolean;
	showCustomizeButton?: boolean;
}

interface SplitTemplates {
	matching: DiaryTemplateT[];
	not_matching: DiaryTemplateT[];
}

const DiaryTemplates = ({
	templates,
	favouriteDiaryTemplates: favouriteDiaries,
	createable,
	patientId,
	organisationId,
	diaryId,
}: Props) => {
	const [splitTemplates, setSplitTemplates] = React.useState<SplitTemplates>({ matching: [], not_matching: [] });
	React.useEffect(() => {
		const { matching, notMatching } = extractFavouriteDiaries(templates, favouriteDiaries);
		setSplitTemplates({ matching: matching, not_matching: notMatching });
	}, [templates, favouriteDiaries]);
	return (
		<>
			<ui.Accordion type="multiple" defaultValue={['favourites']}>
				<ui.AccordionItem value="favourites">
					<ui.AccordionTrigger>
						<h3 className="text-lg font-medium text-slate-700 dark:text-slate-200">Your Favourite Templates</h3>
					</ui.AccordionTrigger>
					<ui.AccordionContent>
						<ul className="flex flex-col gap-2">
							{splitTemplates.matching?.length ? (
								splitTemplates.matching?.map((template: DiaryTemplateT) => (
									<li key={template.diary_template_id}>
										<DiaryTemplateCard
											createable={createable}
											template={template}
											diaryId={diaryId}
											organisationId={organisationId}
											patientId={patientId}
											favourite={true}
										/>
									</li>
								))
							) : (
								<p className="inline-flex flex-wrap items-center rounded-lg border border-indigo-300 bg-indigo-100 p-4 text-left text-lg text-gray-700">
									No Favourite Templates, click the{' '}
									<span className="mx-2 inline-flex items-center gap-1 rounded-md border  bg-slate-100 p-1">
										<Star className="" size={20} />
										<span className="sr-only">Star</span>
									</span>{' '}
									to add.
								</p>
							)}
						</ul>
					</ui.AccordionContent>
				</ui.AccordionItem>
				<ui.AccordionItem value="others">
					<ui.AccordionTrigger>
						<h3 className="text-lg font-medium text-slate-700 dark:text-slate-200">Other Diary Templates</h3>
					</ui.AccordionTrigger>
					<ui.AccordionContent>
						<ul className="flex flex-col gap-2">
							{splitTemplates.not_matching?.map((template: DiaryTemplateT) => (
								<li key={template.diary_template_id}>
									<DiaryTemplateCard
										organisationId={organisationId}
										createable={createable}
										favourite={false}
										template={template}
										patientId={patientId}
										diaryId={diaryId}
									/>
								</li>
							))}
						</ul>
					</ui.AccordionContent>
				</ui.AccordionItem>
			</ui.Accordion>
		</>
	);
};

export default DiaryTemplates;
