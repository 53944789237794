import NewFisherEntryCard from '@/features/diary/new/components/tracker-type-cards/NewFisherEntryCard';
import type { StatefulTrackerViewT } from '@/types/database/trackers';

import NewCard from './NewCard';

interface Props {
	tracker: StatefulTrackerViewT;
}
const NewEntrySwitch = ({ tracker }: Props) => {
	switch (tracker.tracker_type) {
		case 'FISHER':
			return <NewFisherEntryCard tracker={tracker} />;
		case 'PAIN':
			return <NewCard tracker={tracker} />;
		default:
			return <NewCard tracker={tracker} />;
	}
};

export default NewEntrySwitch;
