import Knock, { type PreferenceSet } from '@knocklabs/client';
import { create } from 'zustand';

export const knockPublicApiKey: string = import.meta.env.VITE_KNOCK_PUBLIC_API_KEY;
export const knockClient = new Knock(knockPublicApiKey as string);

type PreferencesState = {
	preferences: PreferenceSet;
	knockClient: Knock;
	setPreferences: (preferences: PreferenceSet) => void;
};

const usePreferencesStore = create<PreferencesState>((set) => ({
	preferences: {} as PreferenceSet,
	knockClient: knockClient,
	setPreferences: (preferences: PreferenceSet) => set({ preferences }),
}));

export default usePreferencesStore;
