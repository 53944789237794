import OkHand from '@/assets/openmoji/OkHand';
import Navigation from '@/features/navigation/components/Navigation';
import { Link } from '@tanstack/react-router';

import { cn } from '../../lib/utils';
import Title from '../common/Title';
import { buttonVariants } from '../ui/button';

const SuccessPage = () => {
	return (
		<section>
			<div className="md-width">
				<Title words="Success, form submitted!" />
				<OkHand className="flex w-32 h-32 mx-auto" />
				<div className="flex flex-col items-center justify-center w-full gap-4 p-4 mx-auto text-center">
					<Link to="/" className={cn(buttonVariants({}))}>
						Next
					</Link>
				</div>
			</div>
		</section>
	);
};
export default SuccessPage;
