import type { InputT } from '@/types/database/forms';

import { inputSettings } from './inputSettings';

// Function to generate the default values object
export function createDefaultValues(inputsFromDatabase: InputT[]) {
	return inputsFromDatabase?.reduce((acc, { input_id, input_key }) => {
		const setting = inputSettings[input_key as keyof typeof inputSettings];
		if (setting === undefined) {
			throw new Error(`Unknown input type: ${input_key}`);
		}
		return {
			...acc,
			[input_id as string]: setting.default,
		};
	}, {});
}
