import InjectionFormFields from '@/features/injection/components/InjectionFormFields';
import { createFileRoute } from '@tanstack/react-router';

const InjectionTime = () => {
	const { organisationId } = Route.useParams();
	return <InjectionFormFields organisationId={organisationId} />;
};

export const Route = createFileRoute(
	'/_app/new-diary/patient/$patientId/organisation/$organisationId/diary/guided/injection-diary/injection',
)({
	component: InjectionTime,
});
