import React from 'react';

const Multiply = () => {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.66663 6.00004L12 1.66666L10.3333 0L5.99996 4.33338L1.66666 7.66438e-05L0 1.66674L4.3333 6.00004L4.99324e-05 10.3333L1.66671 12L5.99997 7.6667L10.3333 12L12 10.3334L7.66663 6.00004Z"
                fill="black"
            />
        </svg>
    );
};

export default Multiply;
