import type { SizeT } from '@/types/zod/common';

import type { ActionViewT } from '@/types/database/actions';
import ActionBody from './ActionBody';
import ActionTitle from './ActionTitle';
import HistoryCard from './HistoryCard';

interface Props {
	action: ActionViewT;
	size?: SizeT;
} // ActionCard.tsx
const ActionCard = ({ action, size }: Props) => {
	const headerContent = <ActionTitle action={action} />;
	const bodyContent = <ActionBody action={action} />;

	return (
		<HistoryCard
			size={size}
			className="border-indigo-300 bg-indigo-100"
			headerContent={headerContent}
			bodyContent={bodyContent}
		/>
	);
};

export default ActionCard;
