import { AlarmCheck, Circle, Clock, DollarSign, FootprintsIcon, LineChart, Star } from 'lucide-react';

import AnimateInView from '../../../../components/ui/animate-in-view';
import Section from '../../components/Section';

const DfuProblem = () => {
	return (
		<Section row={true}>
			<div className="w-full flex flex-col md:p-0 items-center md:items-start md:justify-start justify-center md:text-left text-center gap-y-4 md:pr-12 px-2">
				<p className="text-cyan-600 text-lg w-fit">DFU Issue</p>
				<h3 className="font-extrabold text-3xl md:text-4xl leading-12">
					An expensive problem for a preventable disease
				</h3>
				<p className="text-slate-800 text-xl font-light max-w-[800px] ">
					Diabetic Foot Ulcers are responsible for the majority of lower limb amputations and yet every single one is
					preventable.
				</p>
			</div>
			<div className="w-full">
				{/* Group 1 */}
				<div className="flex flex-col md:flex-row gap-x-4">
					{/* Stat 1 */}
					<AnimateInView>
						<div className="flex flex-col gap-y-2 w-full p-2 md:p-0 items-center md:items-start md:justify-start justify-center md:text-left text-center pt-2 px-4">
							<span className="py-2 flex mt-2 text-cyan-600">
								<Circle size={40} />
							</span>
							<h4 className="text-xl text-slate-800 font-extrabold">Diabetes is Common</h4>
							<p className="text-md text-slate-600">{<p className="">1 in 10 over 40s have Type 2 diabetes.</p>}</p>
						</div>
					</AnimateInView>

					{/* Stat 2 */}
					<AnimateInView>
						<div className="flex flex-col gap-y-2 w-full p-2 md:p-0 items-center md:items-start md:justify-start justify-center md:text-left text-center pt-2 px-4">
							<span className="py-2 flex mt-2 text-cyan-600">
								<FootprintsIcon size={40} />
							</span>
							<h4 className="text-xl text-slate-800 font-extrabold">25% get Ulcers</h4>
							<p className="text-md text-slate-600">1 in 4 develop a foot ulcer at some point in their life</p>
						</div>
					</AnimateInView>
				</div>
				{/* Group 2 */}
				<div className="flex flex-col md:flex-row gap-x-4">
					{/* Stat 3 */}
					<AnimateInView>
						<div className="flex flex-col gap-y-2 w-full p-2 md:p-0 items-center md:items-start md:justify-start justify-center md:text-left text-center pt-2 px-4">
							<span className="py-2 flex mt-2 text-cyan-600">
								<LineChart size={40} />
							</span>
							<h4 className="text-xl text-slate-800 font-extrabold">High Amputation Rate</h4>
							<p className="text-md text-slate-600">
								1 in 2 of all lower-limb amputations are a result of Diabetic Foot Ulcers (DFU)
							</p>
						</div>
					</AnimateInView>

					{/* Stat 4 */}
					<AnimateInView>
						<div className="flex flex-col gap-y-2 w-full p-2 md:p-0 items-center md:items-start md:justify-start justify-center md:text-left text-center pt-2 px-4">
							<span className="py-2 flex mt-2 text-cyan-600">
								<DollarSign size={40} />
							</span>
							<h4 className="text-xl text-slate-800 font-extrabold">£900M+</h4>
							<p className="text-md text-slate-600">NHS spends £900 million treating DFU</p>
						</div>
					</AnimateInView>
				</div>
			</div>
		</Section>
	);
};

export default DfuProblem;
