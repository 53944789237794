import { useMutation } from '@tanstack/react-query';
import { toast } from 'sonner';
import usePreferencesStore from '../hooks/usePreferencesStore';

export const useUpdatePreferencesMutation = () => {
	const knockClient = usePreferencesStore((state) => state.knockClient);
	const setPreferences = usePreferencesStore((state) => state.setPreferences);

	return useMutation({
		mutationFn: (data) => knockClient.user.setPreferences({ channel_types: data }),
		onSuccess: (data) => {
			setPreferences(data);
		},
		onError: () => {
			toast.error('Failed to save preferences');
		},
	});
};
