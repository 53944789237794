import { ui } from '@/components/ui';
import { TrackerT } from '@/definitions/managements';
import { services as s } from '@/services';
import { Link } from '@tanstack/react-router';

import { useTrackerMutation } from '../api/upsertTracker';

interface Props {
    tracker: TrackerT;
    scheduleId: string;
    diaryId: string;
    trackerId: string;
}

const TrackerOptionCard = ({ tracker, scheduleId, diaryId, trackerId }: Props) => {
    const upsertTracker = useTrackerMutation({ diaryId: diaryId });
    console.log(scheduleId);
    return (
        <ui.Card key={tracker.path}>
            <ui.CardHeader>
                <ui.CardTitle className="flex flex-row gap-x-2">
                    {tracker.icon}
                    {tracker.name}
                </ui.CardTitle>
            </ui.CardHeader>
            <ui.CardContent>
                <ui.CardDescription>{tracker.description}</ui.CardDescription>
            </ui.CardContent>
            <ui.CardFooter>
                <ui.Button
                    onClick={() =>
                        upsertTracker.mutate({
                            tracker_id: trackerId,
                            diary_id: diaryId,
                            schedule_id: scheduleId,
                        })
                    }
                    asChild
                >
                    <Link to={`./${tracker.path}`}>Next</Link>
                </ui.Button>
            </ui.CardFooter>
        </ui.Card>
    );
};

export default TrackerOptionCard;
