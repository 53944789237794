import type { EntryValueViewT } from '@/types/database/entries';
import type { InputKeyT } from '@/types/database/forms';
import { charts as c } from '..';
import { cn } from '../../../lib/utils';

interface Props {
	entryValues: EntryValueViewT[];
	inputType: InputKeyT;
	className?: string;
}

const OverallChart = (props: Props) => {
	switch (props.inputType) {
		case 'glucose-slider':
			return (
				<>
					<c.gc.GlucoseMinMaxAvChart
						className={cn('h-full max-h-[450px]', props.className)}
						entryValues={props.entryValues}
					/>
				</>
			);
		case 'pain-slider':
			return <c.pc.PainChart entryValues={props.entryValues} trackers={props.trackers} />;
	}
};

export default OverallChart;
