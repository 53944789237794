import { cn } from '@/lib/utils';
import * as SwitchPrimitives from '@radix-ui/react-switch';
import * as React from 'react';

interface SwitchProps extends React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root> {
	color?: 'slate' | 'indigo';
}

const Switch = React.forwardRef<React.ElementRef<typeof SwitchPrimitives.Root>, SwitchProps>(
	({ className, color = 'indigo', ...props }, ref) => (
		<SwitchPrimitives.Root
			className={cn(
				'peer inline-flex h-6 w-11 shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-offset-white dark:ring-offset-slate-900 disabled:cursor-not-allowed disabled:opacity-50',
				color === 'indigo' &&
					'focus-visible:ring-indigo-950  data-[state=checked]:bg-indigo-600 data-[state=unchecked]:bg-indigo-200 dark:focus-visible:ring-indigo-300 dark:focus-visible:ring-offset-indigo-950 dark:data-[state=unchecked]:bg-indigo-300',
				color === 'slate' &&
					'focus-visible:ring-slate-950  data-[state=checked]:bg-slate-900 data-[state=unchecked]:bg-slate-200 dark:focus-visible:ring-slate-300 dark:focus-visible:ring-offset-slate-950 :bg-slate-50 dark:data-[state=unchecked]:bg-slate-800',
				className,
			)}
			{...props}
			ref={ref}
		>
			<SwitchPrimitives.Thumb
				className={cn(
					'pointer-events-none block h-5 w-5 rounded-full bg-white shadow-lg ring-0 transition-transform data-[state=checked]:translate-x-5 data-[state=unchecked]:translate-x-0',
				)}
			/>
		</SwitchPrimitives.Root>
	),
);
Switch.displayName = SwitchPrimitives.Root.displayName;

export { Switch };
