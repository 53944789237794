import { colors } from '@/assets/colors';
import type { ChartDataset, ChartOptions } from 'chart.js';
import type { LegendOptions, ScaleOptions, TooltipOptions } from 'chart.js';
import type { ChartData } from 'chart.js';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import minMax from 'dayjs/plugin/minMax';
dayjs.extend(minMax);

type LineChartData = ChartData<'line', (number | null)[]>;

export const defaultTooltip = {
	enabled: true,
	padding: 10,
	displayColors: false,
	backgroundColor: 'rgba(253,253,253,1)',
	titleColor: 'black',
	bodyColor: 'black',
	borderColor: 'rgba(190,190,190,1)',
	borderWidth: 1,
	titleFont: { size: 14 }, // Adjust this value as needed
	bodyFont: { size: 16 }, // Adjust this value as needed
};

export const defaultLegend = (datasets: LineChartData) => {
	return {
		display: Number(datasets?.labels?.length) > 1,
		position: 'top' as const,
		labels: {
			usePointStyle: true,
			useBorderRadius: true,
			color: colors['slate-800'],
			padding: 10,
			font: {
				size: 15, // Add your font here to change the font of your legend label
			},
		},
	};
};

const getMinMaxTimes = (datasets: ChartDataset[]): { min: string; max: string } => {
	let minTime: Dayjs | null = null;
	let maxTime: Dayjs | null = null;

	for (const dataset of datasets) {
		if (Array.isArray(dataset.data)) {
			for (const point of dataset.data) {
				if (typeof point === 'object' && point !== null && 'x' in point) {
					const time = dayjs(point.x);
					if (time.isValid()) {
						if (!minTime || time.isBefore(minTime)) {
							minTime = time;
						}
						if (!maxTime || time.isAfter(maxTime)) {
							maxTime = time;
						}
					}
				}
			}
		}
	}

	return {
		min: minTime ? minTime.toISOString() : '',
		max: maxTime ? maxTime.toISOString() : '',
	};
};

export const defaultX = (datasets: ChartDataset[]) => {
	const { min, max } = getMinMaxTimes(datasets);
	return {
		grid: {
			display: true,
		},
		border: {
			display: false, // This hides the axis lines but allows grid lines
		},
		offset: true, // prevents top spots being cut off
		type: 'time',
		time: {
			minUnit: 'minute',
			round: 'minute',
			tooltipFormat: 'yyyy-mm-dd hh:mm',
			displayFormats: {
				minute: 'hh:mm',
				hour: 'dd MMM hh:mm',
			},
		},
		ticks: {
			autoSkip: true,
			autoSkipPadding: 20,
			maxRotation: 0,
			padding: 20,
			backdropPadding: 20,
			color: colors['slate-600'],
			font: {
				// family: 'Inter', // Add your font here to change the font of your y axis
				size: 14,
				weight: 500,
			},
		},
		min: min, // 24 hours ago
		max: max,
	};
};
export const defaultY = {
	type: 'linear',
	min: 0,
	max: 10,
	offset: true, // prevents top spots being cut off
	axis: {
		display: false,
	},
	border: {
		display: false, // This hides the axis lines but allows grid lines
	},
	grid: {
		// display: false,
		drawBorder: true, // <-- this removes y-axis line
		lineWidth: 1,
		color: colors['slate-200'],
	},
	ticks: {
		padding: 16,
		stepSize: 2,
		backdropColor: 'rgba(255,255,255,1)',
		color: colors['slate-600'],
		font: {
			size: 18,
			weight: 400,
		},
		major: {
			enable: true,
		},
	},
};

interface OptionProps {
	datasets: LineChartData;
	x?: ScaleOptions<'linear'>;
	y?: ScaleOptions<'linear'>;
	tooltip?: TooltipOptions<'line'>;
	legend?: LegendOptions<'line'>;
	options?: Omit<ChartOptions<'line'>, 'scales' | 'plugins'>;
}

export const generateOptions = ({ datasets, x, y, legend, tooltip, options }: OptionProps): ChartOptions<'line'> => {
	return {
		responsive: true,
		maintainAspectRatio: false,
		spanGaps: true,
		plugins: {
			tooltip: { ...defaultTooltip, ...tooltip },
			legend: { ...defaultLegend(datasets), ...legend },
		},
		scales: {
			x: { ...defaultX(datasets.datasets), ...x },
			y: { ...defaultY, ...y },
		},
		...options,
	};
};
