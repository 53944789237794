interface Props {
    color: string;
}

const Update = ({ color }: Props) => {
    return (
        <svg
            className="h-full w-full"
            width="41"
            height="49"
            viewBox="0 0 41 49"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M20.4049 1.1875C19.9475 0.746872 19.2319 0.775932 18.8091 1.25108C18.3888 1.72338 18.4156 2.45932 18.8702 2.89725L21.1461 5.08985C12.4126 4.97068 5.32132 10.3415 2.36426 17.478C-0.858538 25.2558 0.869994 35.0028 10.305 41.9109C10.816 42.285 11.5211 42.1556 11.8782 41.6274C12.2335 41.1022 12.1137 40.3761 11.6058 40.0042C3.00779 33.709 1.64712 25.1276 4.43516 18.399C7.09149 11.9883 13.551 7.14463 21.5467 7.4353L18.839 10.2526C18.4013 10.708 18.4013 11.4445 18.839 11.8999C19.2794 12.3582 19.9956 12.3582 20.4361 11.8999L24.6989 7.46457C25.2824 6.8574 25.2644 5.8691 24.6585 5.28538L20.4049 1.1875Z"
                fill={color}
                stroke={color}
                strokeWidth="0.25"
                strokeLinecap="round"
            />
            <path
                d="M20.5951 47.8125C21.0525 48.2531 21.7681 48.2241 22.1909 47.7489C22.6112 47.2766 22.5844 46.5407 22.1298 46.1028L19.8539 43.9101C28.5874 44.0293 35.6787 38.6585 38.6357 31.522C41.8585 23.7442 40.13 13.9973 30.695 7.08914C30.184 6.715 29.4789 6.84444 29.1218 7.37256C28.7665 7.8978 28.8863 8.62388 29.3942 8.99577C37.9922 15.291 39.3529 23.8724 36.5648 30.601C33.9085 37.0117 27.449 41.8554 19.4533 41.5647L22.161 38.7474C22.5987 38.292 22.5987 37.5555 22.161 37.1001C21.7206 36.6418 21.0044 36.6418 20.5639 37.1001L16.3011 41.5354C15.7176 42.1426 15.7356 43.1309 16.3415 43.7146L20.5951 47.8125Z"
                fill={color}
                stroke={color}
                strokeWidth="0.25"
                strokeLinecap="round"
            />
            {/* <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M29.201 19.8073C29.5915 19.4168 29.5915 18.7836 29.201 18.3931L28.1008 17.2929C27.7103 16.9024 27.0771 16.9024 26.6866 17.2929L16.2465 27.733L13.8073 25.2938C13.4168 24.9032 12.7836 24.9032 12.3931 25.2938L11.2929 26.394C10.9024 26.7845 10.9024 27.4177 11.2929 27.8082L15.5361 32.0514C15.9266 32.4419 16.5598 32.4419 16.9503 32.0514L17.2912 31.7105C17.316 31.69 17.3401 31.6682 17.3633 31.645L29.201 19.8073Z"
                fill={color}
            /> */}
        </svg>
    );
};

export default Update;
