import { Button } from '@/components/ui/button';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { useForm as formspree } from '@formspree/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Link, createFileRoute } from '@tanstack/react-router';
import { useForm } from 'react-hook-form';
import * as z from 'zod';

import { ui } from '../../components/ui';
import FootIcon from '../../features/foot-watch/assets/FootIcon';
import InjectionIcon from '../../features/pain-watch/assets/InjectionIcon';
import Section from '../../features/website/components/Section';
import SectionTitle from '../../features/website/components/SectionTitle';

const FormSchema = z.object({
	watch: z.string().min(1, {
		message: 'This field has to be filled.',
	}),
	organisation: z.string().min(1, { message: 'This field has to be filled.' }).max(50, {
		message: 'Organisation name must not be longer than 50 characters.',
	}),
	email: z.string().min(1, { message: 'This field has to be filled.' }).email('This is not a valid email.'),
});

const GetStarted = () => {
	const form = useForm<z.infer<typeof FormSchema>>({
		resolver: zodResolver(FormSchema),
	});

	const [state, handleSubmit] = formspree('mjvqnzob');

	if (state.succeeded) {
		return (
			<Section>
				<SectionTitle
					left={true}
					title="Thank You for Contacting Patient Watch"
					caption="Message Sent"
					description="Your message has been sent to the Patient Watch Team, we aim to get back to you within 24 hours with your sign-up code."
				/>
			</Section>
		);
	}

	function onSubmit(values: z.infer<typeof FormSchema>) {
		handleSubmit(values);
	}

	return (
		<Section>
			<div className="flex w-full flex-col lg:flex-row">
				<div className="mb-8 max-w-[600px]">
					<SectionTitle left={true} caption="Create a Diary" title="Get a Sign-up Code Today" />
					<p className="text-md mx-auto flex w-full items-center justify-center gap-2 text-center text-slate-800 md:mx-0 md:w-fit md:text-left">
						Already Registered?
						<Link to="/login" className="link">
							Log in
						</Link>
					</p>

					<ol className="text-md mt-4 flex h-fit flex-col gap-2 rounded-lg bg-indigo-200 bg-opacity-50 p-4 font-medium  text-slate-800 md:p-8">
						<li>
							<h3 className="text-md font-semibold">Get a sign-up code</h3>
							<p className="md:text-md text-sm font-normal text-slate-600">
								Complete this form to get a sign-up code emailed to you.
							</p>
						</li>
						<li>
							<h3 className="text-md font-semibold">Sign-up</h3>
							<p className="md:text-md text-sm font-normal text-slate-600">
								Register using the sign-up code. N.B. the email can take up to 24 hrs to arrive, don't forget to check
								your junk!.
							</p>
						</li>
						<li>
							<h3 className="text-md font-semibold">Add Patients</h3>
							<p className="md:text-md text-sm font-normal text-slate-600">
								You can begin to use Patient Watch immediately, just click add patients.
							</p>
						</li>
					</ol>
				</div>

				<ui.Card className="space-y-6 rounded-lg border border-slate-300 p-8 text-lg shadow-sm lg:ml-16 lg:w-2/3">
					<Form {...form}>
						<form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6 text-lg ">
							<h2 className="text-xl font-medium text-slate-800">Get Code</h2>
							<p>
								Already have a code?{' '}
								<Link className="text-indigo-600 underline" to="/signup">
									Sign Up
								</Link>
							</p>
							<FormField
								control={form.control}
								name="watch"
								render={({ field }) => (
									<FormItem>
										<FormLabel>Solution</FormLabel>
										<FormControl>
											<Select onValueChange={field.onChange}>
												<SelectTrigger className="w-full">
													<SelectValue placeholder="Choose Solution" />
												</SelectTrigger>
												<SelectContent>
													<SelectItem value="Pain Watch">
														<div className="flex items-center gap-x-2">
															<InjectionIcon />
															<p>Pain Watch</p>
														</div>
													</SelectItem>
													<SelectItem value="Foot Watch">
														<div className="flex items-center gap-x-2">
															<FootIcon />
															Foot Watch
														</div>
													</SelectItem>
												</SelectContent>
											</Select>
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>
							<FormField
								control={form.control}
								name="organisation"
								render={({ field }) => (
									<FormItem>
										<FormLabel>Organisation</FormLabel>
										<FormControl>
											<Input placeholder="e.g. Frimley Park Hospital" {...field} />
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>
							<FormField
								control={form.control}
								name="email"
								render={({ field }) => (
									<FormItem>
										<FormLabel>Email</FormLabel>
										<FormControl>
											<Input placeholder="e.g. joe@nhs.net" {...field} />
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>
							<Button type="submit">{state.submitting ? 'Submitting' : 'Submit'}</Button>
						</form>
					</Form>
				</ui.Card>
			</div>
		</Section>
	);
};
export const Route = createFileRoute('/_website/get-started')({
	component: GetStarted,
});
export default GetStarted;
