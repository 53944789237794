import { cn } from '@/lib/utils';

import SectionTitle from './SectionTitle';

import type React from 'react';

interface Props {
	children: React.ReactNode;
	color?: 'indigo' | 'sky' | 'blue' | 'slate' | 'dark' | 'white';
	id?: string;
	row?: boolean;
	className?: string;
	title?: React.ReactNode;
	caption?: string;
	description?: React.ReactNode;
}
const Section = ({ children, color, id, row, className, title, caption, description }: Props) => {
	return (
		<section
			id={id}
			className={cn(
				'mx-auto px-2 md:px-4 lg:px-8 py-8 lg:py-32 ',
				!color ? '' : ' bg-white',
				color === 'blue' && 'bg-blue-50  border-y-blue-100',
				color === 'sky' && 'bg-sky-50 border-y-sky-100',
				color === 'indigo' && 'bg-indigo-50 border-y-indigo-100 ',
				color === 'slate' && 'bg-slate-50 border-y-slate-100',
				color === 'dark' && 'bg-slate-950 text-white',
				color === 'white' && 'text-slate-950 bg-white',
				'bg-opacity-50',
				className,
			)}
		>
			{title && <SectionTitle className="mb-8" title={title} caption={caption} description={description} />}

			<div
				className={cn(
					'w-full mx-auto max-w-[1200px] flex flex-col items-start gap-y-8',
					row && 'lg:flex-row lg:gap-y-0 lg:gap-x-8 items-center',
				)}
			>
				{children}
			</div>
		</section>
	);
};

export default Section;
