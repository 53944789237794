import { commonComponents as cc } from '@/components/common';
import { cn } from '@/lib/utils';
import type { SurgeryViewT } from '@/types/database/events';
import { useAuth } from '@/utils/auth';
import { warningColors } from '../../../../../utils/warningColors';
import SurgeryFormTabs from './SurgeryFormTabs';
import SurgerySummary from './SurgerySummary';
interface Props {
	diaryId: string;
	eventId: string;
	trackerId?: string;
	surgeryId: string;
	surgery: SurgeryViewT;
	organisationId: string;
}
const SurgeryDialog = (props: Props) => {
	const auth = useAuth();
	return (
		<cc.EditDialog
			locked={!auth?.roles?.isClinician}
			label={
				<h4 className={cn('text-semibold text-md', warningColors(props.surgery?.is_incomplete ?? false))}>
					Surgery {props.surgery?.is_incomplete && 'Missing Information'}
				</h4>
			}
			info={<SurgerySummary surgery={props.surgery} />}
		>
			<SurgeryFormTabs
				surgery={props.surgery}
				trackerId={props.trackerId}
				diaryId={props.diaryId}
				eventId={props.eventId}
				surgeryId={props.surgeryId}
				organisationId={props.organisationId}
			/>
		</cc.EditDialog>
	);
};

export default SurgeryDialog;
