import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';

import Timer from '../../../assets/icons/Timer';
import { useCameraStore } from '../hooks/useCameraStore';

export type TimerT = 0 | 5 | 10;

const SelectTimer = () => {
    const camera = useCameraStore();
    const { delay } = useCameraStore((state) => state.cameraStatus);

    return (
        <div className="w-full h-12 mx-auto my-2">
            <Select defaultValue={String(delay)} onValueChange={(value) => camera.setDelay(Number(value))}>
                <SelectTrigger className="w-full  [&>span]:flex [&>span]:gap-x-2 [&>span]:flex-row [&>span]:items-center">
                    <SelectValue />
                </SelectTrigger>
                <SelectContent>
                    <SelectDelay delay={0} />
                    <SelectDelay delay={5} />
                    <SelectDelay delay={10} />
                </SelectContent>
            </Select>
        </div>
    );
};

export default SelectTimer;

interface SelectDelayProps {
    delay: TimerT;
}

const SelectDelay = ({ delay }: SelectDelayProps) => {
    return (
        <SelectItem
            className="w-full bg-white [&>*]:flex [&>*]:gap-x-2 [&>*]:flex-row [&>*]:items-center"
            value={String(delay)}
        >
            <Timer delay={delay} stroke="black" className="w-8 h-8 lg:h-8 lg:w-8" />
            {delayWordMap[delay]}
        </SelectItem>
    );
};

const delayWordMap = {
    0: 'Off',
    5: 'Five',
    10: 'Ten',
};
