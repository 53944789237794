import type { ExtendedPageInputT, PageViewT } from '@/types/database/diaries';

export type FlattenedPageOrInput = PageViewT &
	ExtendedPageInputT & {
		type: 'page' | 'input';
	};

export const flattenPageInputs = (pages: PageViewT[]): FlattenedPageOrInput[] => {
	const flattenedPages: FlattenedPageOrInput[] = [];

	pages.forEach((page) => {
		const { page_id, page_index, page_label, form_id, inputs } = page;
		flattenedPages.push({
			page_id,
			page_index,
			page_label,
			form_id,
			inputs: [],
			...page,
			type: 'page',
		});

		if (inputs && inputs?.length > 0) {
			inputs?.forEach((input) => {
				flattenedPages.push({
					page_id,
					page_index,
					page_label,
					form_id,
					...input,
					type: 'input',
				});
			});
		}
	});

	return flattenedPages;
};

export const unflattenPageInputs = (flattenedPages: FlattenedPageOrInput[]): PageViewT[] => {
	const pages: PageViewT[] = [];
	const pageInputs: Record<string, ExtendedPageInputT[]> = {};

	flattenedPages.forEach((item) => {
		if (item.type === 'page') {
			const { page_id, page_index, page_label, form_id } = item;
			pages.push({
				page_id,
				page_index,
				page_label,
				form_id,
				inputs: [],
			});
			pageInputs[page_id] = [];
		} else if (item.type === 'input') {
			const { page_id, ...input } = item;
			pageInputs[page_id].push(input);
		}
	});

	pages.forEach((page) => {
		page.inputs = pageInputs[page.page_id] || [];
	});

	return pages;
};
