import { supabaseClient } from '@/lib/supabase';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';
import { toast } from 'sonner';

const updatePassword = async (newPassword: string) => {
	const { data, error } = await supabaseClient.auth.updateUser({ password: newPassword });
	if (error) throw error;
	return data;
};

const useUpdatePasswordMutation = () => {
	const navigate = useNavigate();
	return useMutation({
		mutationFn: async (password: string) => {
			return updatePassword(password);
		},
		onError: (error) => {
			toast.error(error.message);
		},
		onSuccess: () => {
			toast.success('Password updated successfully!');
			navigate({ to: '/profile' });
		},
		onSettled: () => {},
	});
};

export default useUpdatePasswordMutation;
