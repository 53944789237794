import { icons, stateIcons as si } from '@/assets';

const Benefits = () => {
	return (
		<section className="section-content">
			<h3>Benefits</h3>
			<div className="img-row">
				<si.AmberFlag />
				<p>
					<b>Time-saving</b> algorithm ensures the doctor is only alerted when action may be required.
				</p>
			</div>
			<div className="img-row">
				<si.MIA />
				<p>
					Identifying diaries not completed as expected acts as a <b>safety net</b> for vulnerable patients.
				</p>
			</div>
			<div className="img-row">
				<si.Invited />
				<p>
					<b>Pre-written templates</b> enable doctors to quickly contact patients at the time of <b>clinical need</b>.
				</p>
			</div>
			<div className="img-row">
				<icons.Clipboard />
				<p>
					A <b>PDF report</b> is generated on diary completion for <b>seamless integration</b> with patient records.
				</p>
			</div>
			<div className="img-row">
				<icons.Database />
				<p>
					Results from every diary add to our anonymised dataset on pain injections - for <b>research</b> and{' '}
					<b>audit</b>.
				</p>
			</div>
		</section>
	);
};

export default Benefits;
