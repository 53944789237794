import Hero from '@/features/website/components/Hero';
import JoinTeam from '@/features/website/components/JoinTeam';
import Section from '@/features/website/components/Section';
import TeamMember from '@/features/website/team/TeamMember';
import { content } from '@/features/website/team/content/team-content';
import { createFileRoute } from '@tanstack/react-router';
import { BarChart3, HeartHandshake, Wand } from 'lucide-react';
import React from 'react';

import type { TeamMemberI } from '@/features/website/team/TeamMember';
const Team = () => {
	return (
		<div>
			<Section className="pb-2">
				<Hero
					caption="About Us"
					title="What is Patient Watch"
					description="At Patient Watch we believe that for healthcare to work best patients and clinicians must work together. We
					empower patients and clinicians with accessible, easy-to-use digital tools which solve the simplest healthcare
					issues."
				/>
				<ul className="mx-auto px-8 pt-16 md:px-0 [&>li]:flex [&>li]:items-center [&>li]:gap-x-2 flex flex-col md:flex-row gap-x-4 justify-around">
					<li className="text-center gap-y-4 flex flex-col items-center justify-center py-8 md:py-0 md:px-8 w-full md:w-1/4">
						<span className="p-4 rounded-md bg-indigo-100 text-indigo-500">
							<HeartHandshake className="" size={24} />
						</span>
						<p className="text-md">Collaborative care is the best care.</p>
					</li>
					<li className="text-center gap-y-4 flex flex-col items-center  justify-center py-8 md:py-0 md:px-8  w-full md:w-1/4">
						<span className="p-4 rounded-md bg-pink-100 text-pink-500">
							<BarChart3 className="" size={24} />
						</span>

						<p className="text-md">Our value relies on quality evidence.</p>
					</li>
					<li className="text-center gap-y-4 flex flex-col items-center justify-center py-8 md:py-0 md:px-8  w-full md:w-1/4">
						<span className="p-4 rounded-md bg-amber-100 text-amber-500">
							<Wand className="" size={24} />
						</span>

						<p className="text-md">The simplest problems are the ones most worth solving.</p>
					</li>
				</ul>
			</Section>

			{/* <MissionVision /> */}

			<Section
				caption="Team"
				title="A Designer, a Developer, and a Doctor"
				description="Our core team is small, well rounded and responsive. We facilitate rapid product development, secure and reliable services and 1st class customer support."
			>
				<div className="flex flex-col md:flex-row md:flex-wrap md:justify-between">
					{content?.map((person: TeamMemberI) => (
						<React.Fragment key={person.name}>
							<TeamMember teamMember={person} />
						</React.Fragment>
					))}
				</div>
			</Section>

			<Section>
				<JoinTeam />
			</Section>
			{/* <Section>
				<SectionTitle
					caption="Clinical Advisors"
					title="Guided by medical experience"
					description="We have a diverse team of advisors whose expertise helps us design solutions which slot directly into the existing clinical pathways."
				/>
				<div className="flex flex-col md:flex-row md:flex-wrap md:gap-x-[15px]">
					{clinicians?.map((person: Partial<TeamMemberI>) => {
						return <TeamMember teamMember={person} />;
					})}
				</div>
			</Section> */}
		</div>
	);
};
createFileRoute;
export const Route = createFileRoute('/_website/about')({
	component: Team,
});

export default Team;
