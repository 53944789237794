import DiaryRouteForm from '@/features/new-diary/features/DiaryRouteForm';
import { createFileRoute } from '@tanstack/react-router';
import Title from '../../../components/common/Title';

const NewDiaryPage = () => {
	return (
		<section className="md-width">
			<Title words="New Diary" />
			<DiaryRouteForm />
		</section>
	);
};

export const Route = createFileRoute('/_app/new-diary/')({
	component: NewDiaryPage,
});
