import { commonComponents as cc } from '@/components/common';
import { Route as EntryValueRoute } from '@/routes/_app/diary/$diaryId/edit/tracker/$trackerId/entry/$entryId/entry-value/$entryValueId';
import { services as s } from '@/services';
import type { EntryViewT } from '@/types/database/diaries';
import { useParams } from '@tanstack/react-router';
import { Fragment } from 'react';
import SimpleEntryValueSwitch from '../../diary/history/SimpleEntryValueSwitch';
import { useProfileQuery } from '../../profile/api/selectProfile';
import { sortEntryValuesView } from '../services/sortEntryValuesView';
interface Props {
	entry: EntryViewT;
}
const EditEntry = ({ entry }: Props) => {
	const { diaryId } = useParams({ strict: false });
	const { data: profile } = useProfileQuery();
	return (
		<>
			{profile?.is_super_user && (
				<cc.Group title="Entry Details">
					<cc.EditDialog locked={true} label="Entry Label" info={entry.timing_label} />
					<cc.EditDialog
						locked={true}
						label="Entry Time"
						info={s.time.formatDateTime(entry.calculated_entry_time ?? entry.entry_time)}
					/>
				</cc.Group>
			)}
			<cc.Group title="Entry Values">
				{sortEntryValuesView(entry.entry_values)?.map((ev) => (
					<Fragment key={ev.entry_value_id}>
						<cc.EditRow
							route={{
								to: EntryValueRoute.to,
								params: {
									entryId: entry.entry_id,
									trackerId: entry.tracker_id,
									diaryId: diaryId,
									entryValueId: ev.entry_value_id,
								},
							}}
							show={true}
							label={ev.input_label as string}
							info={<SimpleEntryValueSwitch entryValue={ev} />}
						/>
					</Fragment>
				))}
			</cc.Group>
		</>
	);
};

export default EditEntry;
