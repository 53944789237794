import { supabaseClient } from '@/lib/supabase';
import { Route as TrackerTemplatesRoute } from '@/routes/_app/new-diary/patient/$patientId/organisation/$organisationId/diary/$diaryId/tracker/$trackerId/tracker-templates';
import { services as s } from '@/services';
import type { Diary } from '@/types/database/diaries';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';
import { toast } from 'sonner';
import useInvalidateDiaries from './invalidateDiaries';

const insertDiary = async (diary: Diary['Insert']) => {
	const { data, error } = await supabaseClient.from('diaries').upsert(diary).select().single();
	if (error) throw error;
	return data;
};

export const useInsertDiaryMutation = () => {
	const queryClient = useQueryClient();
	const navigate = useNavigate();
	const invalidateDiaries = useInvalidateDiaries();
	return useMutation({
		mutationFn: async (data: Diary['Insert']) => {
			return await insertDiary(data);
		},
		onError: (error) => {
			console.error('An error occurred:', error);
			toast.error(`${error.message}`);
		},
		onSuccess: (data: Diary['Row']) => {
			toast.success('Diary created successfully');
			navigate({
				to: TrackerTemplatesRoute.to,
				params: (prev) => ({ ...prev, trackerId: s.uuidv4() }),
			});
			queryClient.setQueryData(['diary', { diaryId: data.diary_id }], data);
		},
		onSettled: (data) => {
			invalidateDiaries(data?.diary_id as string);
		},
	});
};
export default useInsertDiaryMutation;
