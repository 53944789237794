interface Props {
	children: React.ReactNode;
}

const HeroTitle = ({ children }: Props) => {
	return (
		<h1 className="mx-auto px-0 md:px-4 lg:px-8 w-full font-medium text-5xl text-center text-slate-900 lg:text-6xl dark:text-slate-100 leading-[125%] lg:leading:10">
			{children}
		</h1>
	);
};

export default HeroTitle;
