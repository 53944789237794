import { icons } from '@/assets/icons';
import { commonComponents as e } from '@/components/common';
import FooterButtons from '@/components/common/FooterButtons';
import usePatientsQuery from '@/features/profile/api/usePatientsQuery';
import { fuzzySearch } from '@/services/fuzzySearch';
import { createFileRoute } from '@tanstack/react-router';
import { useEffect, useRef, useState } from 'react';
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { shallow } from 'zustand/shallow';

// import { PartialPatientT } from '@/@types/SupabaseTypes';
type State = {
	selectedPatient: any;
};

type Action = {
	updateSelectedPatient: (selectedPatient: State['selectedPatient']) => void;
};

// Create your store, which includes both state and (optionally) actions
export const useSelectedPatientStore = create<State & Action>()(
	devtools(
		persist(
			(set) => ({
				selectedPatient: {},
				updateSelectedPatient: (selectedPatient) => set(() => ({ selectedPatient: selectedPatient })),
			}),
			{
				name: 'selected-patient',
			},
		),
	),
);

interface Props {
	nextStage: () => void;
}

const SelectPatient = ({ nextStage }: Props) => {
	const [searchFocused, setSearchFocused] = useState(false);
	const [searchInput, setSearchInput] = useState('');
	const searchRef = useRef<any>(null);

	const [selectedPatient, updateSelectedPatient] = useSelectedPatientStore(
		(state) => [state.selectedPatient, state.updateSelectedPatient],
		shallow,
	);

	useEffect(() => updateSelectedPatient({}), []);

	const { data: patients } = usePatientsQuery();

	return (
		<div>
			{!searchFocused && (
				<e.Title
					words="Select Patient"
					// description="Search for a patient or, if they are not signed up, add a new patient."
				/>
			)}
			<section className={`mx-auto flex flex-col items-center justify-center ${searchFocused ? 'px-3' : 'px-4'}`}>
				<div className="w-full">
					<div
						onClick={() => searchRef?.current?.focus()}
						className={`form-input flex h-14 cursor-text items-center gap-x-2 bg-white`}
					>
						<icons.Search className="h-[20px] min-h-[20px] w-[20px] min-w-[20px]" />
						<input
							autoFocus
							onFocus={() => {
								setSearchFocused(true);
								updateSelectedPatient({});
							}}
							type="text"
							name="search"
							value={searchInput}
							onChange={(e) => setSearchInput(e.target.value)}
							placeholder="Search by name"
							className="reset-input"
							ref={searchRef}
						></input>
						{searchFocused && (
							<button
								className="ml-auto"
								onClick={() => {
									setSearchFocused(false);
									setSearchInput('');
									updateSelectedPatient({});
								}}
							>
								<icons.CrossThin fill="black" className="h-[24px] min-h-[24px] w-[24px] min-w-[24px]" />
							</button>
						)}
					</div>
					{!selectedPatient?.uuid && searchInput && (
						<>
							<div className="h-fill">
								{fuzzySearch(searchInput, 'name', patients as any[])?.map((patient: any) => (
									<button
										key={patient?.uuid}
										onClick={() => {
											updateSelectedPatient(patient);
										}}
										className="my-4 flex w-full items-center justify-between rounded-xl px-2 py-4 text-lg hover:bg-slate-100 focus:border"
									>
										<p>{patient.name}</p>
										<icons.BackArrow fill="gray" className="mr-2 h-5 w-5 rotate-180" />
									</button>
								))}
							</div>

							{fuzzySearch(searchInput, 'name', patients as any[])?.length === 0 && (
								<div className="my-4 flex items-center justify-between p-2 text-lg ">No Results</div>
							)}
						</>
					)}
					{selectedPatient?.uuid && (
						<e.Card className="mt-2">
							<div className="flex items-center justify-between text-lg">
								<p className="font-semibold text-gray-800">PATIENT</p>
								<e.CloseButton close={() => updateSelectedPatient({})} />
							</div>
							<div className="my-2 flex flex-col gap-y-2 text-lg">
								<div>
									<label className="font-semibold text-gray-600">Name</label>
									<p className="">{selectedPatient?.name}</p>
								</div>
								<div>
									<label className="font-semibold text-gray-600">Email</label>
									<p className="">{selectedPatient?.email}</p>
								</div>
								<div>
									<label className="font-semibold text-gray-600">NHS Number</label>
									<p>{selectedPatient?.nhs_number ? selectedPatient?.nhs_number : 'Unknown'}</p>
								</div>
								<div>
									<label className="font-semibold text-gray-600">Date of Birth</label>
									<p>{selectedPatient?.date_of_birth ? selectedPatient?.date_of_birth : 'Unknown'}</p>
								</div>
							</div>
						</e.Card>
					)}
				</div>
				{/* {!searchFocused && (
                    <>
                        <p className="pt-8 text-lg font-semibold">OR</p>
                        <e.Button
                            btn="btn-4"
                            words="Add New Patient"
                            className="font-semibold"
                            href="/check-in/new-patient"
                        />
                    </>
                )} */}
			</section>
			<FooterButtons
				func1={nextStage}
				words1="Next"
				icon1={
					<div className="ml-[2px] flex h-8 w-8 rotate-180 items-center justify-center p-[6px]">
						<icons.BackArrow fill={selectedPatient?.uuid ? 'white' : 'gray'} />
					</div>
				}
				iconSwap1={true}
				disabled1={!selectedPatient?.uuid}
			/>
		</div>
	);
};

export default SelectPatient;
export const Route = createFileRoute('/_app/foot-watch/$diaryId/new/select-patient')({
	component: SelectPatient,
});
