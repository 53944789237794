import React from 'react';

const Email = () => {
    return (
        <svg width="36" height="25" viewBox="0 0 36 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5 1H31C33.2091 1 35 2.79086 35 5V20C35 22.2091 33.2091 24 31 24H5C2.79086 24 1 22.2091 1 20V5C1 2.79086 2.79086 1 5 1Z"
                stroke="black"
                strokeWidth="2"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.20745 4.39031C4.54419 3.95256 5.17203 3.87066 5.60979 4.2074L18.0001 13.7384L30.3904 4.2074C30.8281 3.87066 31.456 3.95256 31.7927 4.39031C32.1294 4.82806 32.0475 5.45591 31.6098 5.79265L22.1603 13.0615L31.5449 19.1615C32.008 19.4625 32.1393 20.0819 31.8384 20.545C31.5374 21.008 30.918 21.1394 30.4549 20.8384L20.4795 14.3544L19.2195 15.3236C18.5006 15.8766 17.4996 15.8766 16.7807 15.3236L15.5206 14.3544L5.54513 20.8384C5.08207 21.1394 4.46269 21.008 4.1617 20.545C3.86071 20.0819 3.99209 19.4625 4.45515 19.1615L13.8398 13.0615L4.39036 5.79265C3.95261 5.45591 3.87072 4.82806 4.20745 4.39031Z"
                fill="black"
            />
        </svg>
    );
};

export default Email;
