import './diaryRecord.css';

import { useEffect, useState } from 'react';

import AnimateInView from '../../../components/ui/animate-in-view';
import Tab from './components/Tab';
import { actions as a } from './content/actionExamples';
import { ACTION_EMAIL_TEMPLATES } from './content/emailTemplates';

import type { DiaryT } from './content/exampleDiaries';
import type { ActionKey } from './content/actionExamples';
interface Props {
    diary: DiaryT;
    action: any;
    setAction: (action: any) => void;
}
const ExampleEmail = ({ diary, action, setAction }: Props) => {
    const [actionEmail, setActionEmail] = useState<string>();

    useEffect(() => {
        setActionEmail(makeEmail(diary, action));
        // eslint-disable-next-line
    }, [action]);

    function makeEmail(diary: DiaryT, action: ActionKey): string {
        return `Dear ${diary?.patient_name}\n\n${ACTION_EMAIL_TEMPLATES[action]}\n\nDr ${diary?.doctor_name}\n`;
    }

    return (
        <AnimateInView>
            <div className="flex flex-wrap mb-2">
                {Object.entries(a).map(([key, value]) => {
                    return (
                        <div key={value?.name}>
                            <Tab
                                onClick={() => setAction(key)}
                                icon={value?.icon}
                                words={value?.name}
                                active={key === action}
                            />
                        </div>
                    );
                })}
            </div>
            <div className="p-4 bg-white border shadow-md rounded-xl border-slate-300">
                {diary?.current_state && action && (
                    <>
                        <div className="text-md flex gap-x-2 border-b border-b-slate-300 py-2 px-2 [&>h4]:font-semibold [&>h4]:min-w-[60px]">
                            <h4>From</h4>
                            <h3>doctor@patient-watch.com</h3>
                        </div>
                        <div className="text-md flex gap-x-2 border-b border-b-slate-300 py-2 px-2 [&>h4]:font-semibold [&>h4]:min-w-[60px]">
                            <h4>To</h4>
                            <h3>{diary.patient_email}</h3>
                        </div>
                        <div className="text-md flex gap-x-2 border-b border-b-slate-300 py-2 px-2 [&>h4]:font-semibold [&>h4]:min-w-[60px]">
                            <h4>CC</h4>
                            <h3>{diary.doctor_sec_email}</h3>
                        </div>
                        <div className="w-full mt-4">
                            <textarea
                                className="w-full m-2 !min-h-[250px] !h-fit !text-sm cursor-not-allowed "
                                // onChange={setActionEmail}
                                value={actionEmail}
                            >
                                {actionEmail}
                            </textarea>
                        </div>
                    </>
                )}
            </div>
        </AnimateInView>
    );
};

export default ExampleEmail;
