import { ui } from '@/components/ui';
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { useDeleteOrganisationMutation } from '@/features/auth/api/deleteOrganisation';
import type { UsersOrganisationsRolesViewT } from '@/types/database/profiles';
import { DotsHorizontalIcon } from '@radix-ui/react-icons';
import { Link } from '@tanstack/react-router';
import { QrCode, Trash2, Users } from 'lucide-react';

interface Props {
	userId: string;
	usersOrganisationsRoles: UsersOrganisationsRolesViewT[];
}
const OrganisationsRolesTable = (props: Props) => {
	const deleteUserOrganisationRole = useDeleteOrganisationMutation(props.userId);

	if (!props.usersOrganisationsRoles.length)
		return (
			<div>
				<p className="text-amber-600 text-lg">Information Missing</p>
				<p className="text-md text-slate-600">Please join an organisation to create diaries.</p>
			</div>
		);
	return (
		<div>
			<ui.Table className="text-lg">
				<ui.TableHeader className="text-slate-700 dark:text-slate-300">
					<ui.TableRow>
						<ui.TableHead className="pl-0 font-semibold">Organisation</ui.TableHead>
						<ui.TableHead className="font-semibold">Role</ui.TableHead>
						<ui.TableHead className="sr-only">Menu</ui.TableHead>
					</ui.TableRow>
				</ui.TableHeader>
				<ui.TableBody>
					{props.usersOrganisationsRoles.map((row: UsersOrganisationsRolesViewT) => (
						<ui.TableRow key={`${row.user_id}_${row.organisation_id}_${row.role}`}>
							<ui.TableCell className="pl-0 font-medium">{row.organisation_name}</ui.TableCell>
							<ui.TableCell>{row.role}</ui.TableCell>
							<ui.TableCell>
								<DropdownMenu>
									<DropdownMenuTrigger asChild>
										<ui.Button variant="ghost" className="p-0 w-8 h-8">
											<span className="sr-only">Open menu</span>
											<DotsHorizontalIcon className="w-4 h-4" />
										</ui.Button>
									</DropdownMenuTrigger>
									<DropdownMenuContent align="end">
										{row.role?.toUpperCase() !== 'PATIENT' && (
											<DropdownMenuItem asChild className="gap-x-2 cursor-pointer">
												<Link to="/organisation/$organisationId" params={{ organisationId: row.organisation_id }}>
													<Users />
													Show Users
												</Link>
											</DropdownMenuItem>
										)}
										{row.role?.toUpperCase() !== 'PATIENT' && (
											<DropdownMenuItem asChild className="gap-x-2 cursor-pointer">
												<Link
													to="/organisation/$organisationId/qr-code"
													params={{ organisationId: row.organisation_id, role: row.role }}
												>
													<QrCode />
													Show QR Code
												</Link>
											</DropdownMenuItem>
										)}
										<DropdownMenuItem
											onClick={() =>
												// @ts-ignore
												deleteUserOrganisationRole.mutate({
													organisation_id: row.organisation_id as string,
													role: row.role as string,
													user_id: row.user_id as string,
												})
											}
											className="gap-x-2 text-red-600 cursor-pointer"
										>
											<Trash2 />
											Delete
										</DropdownMenuItem>
									</DropdownMenuContent>
								</DropdownMenu>
							</ui.TableCell>
						</ui.TableRow>
					))}
				</ui.TableBody>
			</ui.Table>
		</div>
	);
};

export default OrganisationsRolesTable;
