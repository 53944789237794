import type { StatefulDiaryT } from '@/types/database/diaries';
import type { ExtendedInjectionViewT } from '@/types/database/trackers';

import { useDiaryByIdQuery } from '../../diary/api/getDiaryById';

function extractInjectionById(diary: StatefulDiaryT, injectionId: string) {
	const injections = diary?.trackers_view?.events?.flatMap((e) => e?.injections_view);
	const injection = injections?.find((injection: ExtendedInjectionViewT) => injection.injection_id === injectionId);
	return injection;
}

const useExtractInjection = (diaryId: string, injectionId: string) => {
	const { data: diary } = useDiaryByIdQuery(diaryId);
	return extractInjectionById(diary, injectionId) as ExtendedInjectionViewT;
};

export default useExtractInjection;
