const Ten = () => {
	return (
		<svg
			role="img"
			aria-label="Pain Ten"
			width="50"
			height="50"
			viewBox="0 0 50 50"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect x="1" y="1" width="48" height="48" rx="24" fill="white" stroke="black" strokeWidth="2" />
			<rect x="1" y="1" width="48" height="48" rx="24" fill="white" stroke="black" strokeWidth="2" />
			<path
				d="M18.1483 35.805L16.9793 36.9548C16.2819 37.6407 15.7669 38.49 15.4811 39.4255C15.2642 40.1355 15.8857 40.8191 16.6131 40.6705L18.6697 40.2505C20.1361 39.9509 21.6291 39.8 23.1259 39.8L26.3333 39.8C27.8855 39.8 29.4342 39.9454 30.9592 40.2344L33.3948 40.6959C34.1243 40.8341 34.7396 40.1477 34.5226 39.4376C34.2343 38.4941 33.715 37.6377 33.0117 36.9459L31.8517 35.805C30.0242 34.0074 27.5634 33 25 33C22.4366 33 19.9758 34.0074 18.1483 35.805Z"
				fill="black"
				stroke="black"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M6.20367 16.8176C6.05836 16.2847 6.3725 15.735 6.90532 15.5897L7.18756 15.5127C9.99644 14.7466 12.4717 13.0923 14.2512 10.8105C14.5909 10.375 15.2089 10.2481 15.6684 10.5544V10.5544C16.128 10.8608 16.2547 11.484 15.9192 11.9227C13.8687 14.6048 10.9887 16.5491 7.7138 17.4422L7.43156 17.5192C6.89873 17.6645 6.34899 17.3504 6.20367 16.8176Z"
				fill="black"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M44.2968 16.8176C44.4421 16.2847 44.128 15.735 43.5952 15.5897L43.3129 15.5127C40.504 14.7466 38.0288 13.0923 36.2492 10.8105C35.9096 10.375 35.2916 10.2481 34.8321 10.5544V10.5544C34.3725 10.8608 34.2458 11.484 34.5813 11.9227C36.6318 14.6048 39.5118 16.5491 42.7867 17.4422L43.0689 17.5192C43.6018 17.6645 44.1515 17.3504 44.2968 16.8176Z"
				fill="black"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.0413 28.8209L12.0044 28.9195C12.0002 28.9307 12.0008 28.9355 12.0011 28.9376L12.0011 28.9376C12.0017 28.9417 12.0037 28.9484 12.0089 28.9559C12.0141 28.9634 12.0196 28.9676 12.0232 28.9696L12.0233 28.9696C12.0251 28.9706 12.0294 28.9729 12.0413 28.9729C12.0533 28.9729 12.0576 28.9706 12.0594 28.9696L12.0594 28.9696C12.063 28.9676 12.0686 28.9634 12.0738 28.9559C12.079 28.9484 12.081 28.9417 12.0816 28.9376L12.0816 28.9376C12.0819 28.9355 12.0825 28.9307 12.0783 28.9195L12.0413 28.8209ZM11.0913 25.6584C11.4205 24.7805 12.6622 24.7806 12.9914 25.6584L13.951 28.2173C14.451 29.5506 13.4653 30.9729 12.0413 30.9729C10.6174 30.9729 9.6317 29.5506 10.1317 28.2173L11.0913 25.6584Z"
				fill="black"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M38.4998 28.848L38.4629 28.9466C38.4587 28.9578 38.4593 28.9626 38.4596 28.9647L38.4596 28.9647C38.4602 28.9688 38.4622 28.9755 38.4674 28.983C38.4725 28.9905 38.4781 28.9947 38.4817 28.9967L38.4818 28.9967C38.4836 28.9977 38.4879 29 38.4998 29C38.5118 29 38.5161 28.9977 38.5179 28.9967L38.5179 28.9967C38.5215 28.9947 38.5271 28.9905 38.5323 28.983C38.5375 28.9755 38.5395 28.9688 38.5401 28.9647L38.5401 28.9647C38.5404 28.9626 38.541 28.9578 38.5368 28.9466L38.4998 28.848ZM37.5498 25.6855C37.879 24.8076 39.1207 24.8077 39.4499 25.6855L40.4095 28.2444C40.9095 29.5777 39.9238 31 38.4998 31C37.0759 31 36.0902 29.5777 36.5902 28.2444L37.5498 25.6855Z"
				fill="black"
			/>
			<path
				d="M10.3125 21.9922C9.58763 20.7367 10.0178 19.1312 11.2733 18.4064L14.5209 16.5314C15.7764 15.8065 17.3819 16.2367 18.1067 17.4922V17.4922C19.3149 19.5847 18.5979 22.2604 16.5054 23.4685L16.2889 23.5935C14.1963 24.8017 11.5206 24.0847 10.3125 21.9922V21.9922Z"
				fill="#0E0E0E"
			/>
			<path
				d="M30.9395 17.4923C31.6643 16.2368 33.2698 15.8066 34.5253 16.5315L37.7729 18.4065C39.0284 19.1314 39.4586 20.7368 38.7337 21.9923V21.9923C37.5256 24.0848 34.8499 24.8018 32.7573 23.5937L32.5408 23.4687C30.4483 22.2605 29.7313 19.5848 30.9395 17.4923V17.4923Z"
				fill="#0E0E0E"
			/>
		</svg>
	);
};

export default Ten;
