import { supabaseClient } from '@/lib/supabase';
import { queryOptions, useQuery } from '@tanstack/react-query';

export function selectForms() {
	return supabaseClient
		.from('forms')
		.select('*, pages(*, inputs(*, input_types(*)))')
		.throwOnError()
		.then((result) => result.data);
}

export const selectFormsQueryOptions = () =>
	queryOptions({
		queryKey: ['forms'],
		queryFn: async () => selectForms(),
	});

export function useSelectForms() {
	const options = selectFormsQueryOptions();
	return useQuery(options);
}
