import { components as c } from '@/components';
import { createRedirectUrl } from '@/features/auth/utils/createRedirectUrl';
import { supabaseClient } from '@/lib/supabase';
import { services as s } from '@/services';
import { EmailZ, RoleZ } from '@/types/zod/auth';
import { FirstNameZ, LastNameZ, TitleZ } from '@/types/zod/profiles';
import type { RouteT } from '@/types/zod/routes';
import { useAuth } from '@/utils/auth';
import { useForm, zodResolver } from '@/utils/form';
import type { SubmitHandler } from '@/utils/form';
import { useNavigate } from '@tanstack/react-router';
import { toast } from 'sonner';
import { z } from 'zod';
// TODO - Add Hospital Number - NON OPTIONAL FOR NHS

export const formSchema = z.object({
	role: RoleZ,
	title: TitleZ,
	first_name: FirstNameZ,
	last_name: LastNameZ,
	email: EmailZ,
	invited_by: z.string().uuid(),
	redirect_url: z.string().url(),
});

type FormSchemaType = z.infer<typeof formSchema>;

import type { NewDiaryRouteSearchT } from '@/routes/_app/new-diary/patient/select';

const InvitePatientForm = (props: NewDiaryRouteSearchT) => {
	const auth = useAuth();
	const form = useForm<FormSchemaType>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			role: 'PATIENT',
			invited_by: auth.id,
			redirect_url: createRedirectUrl('/confirm-account'),
		},
	});

	const navigate = useNavigate({ from: '/new/invite' });

	const onSubmit: SubmitHandler<FormSchemaType> = async (formData) => {
		const { data, error } = await supabaseClient.functions.invoke<{ user_id: string; organisation_id: string }>(
			'public-invite',
			{
				body: { ...formData, organisation: JSON.stringify(props.organisation) },
			},
		);
		if (error) {
			toast.error(error.message);
		}

		if (data) {
			toast.success('Invite Sent');
			navigate({
				to: props.diary_route as RouteT,
				params: {
					patientId: data.user_id,
					organisationId: data.organisation_id,
					diaryId: s.uuidv4(),
				},
			});
		}
	};

	return (
		<c.ui.Form {...form}>
			<form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4 text-lg">
				<c.fc.Email label="Patient Email" name="email" />
				<c.fc.SelectTitle />
				<c.fc.FirstName name="first_name" />
				<c.fc.LastName name="last_name" />
				<br />
				<div className="flex flex-col gap-y-2">
					<c.ui.Button
						onClick={() => console.log(form.formState.errors)}
						disabled={form.formState.isSubmitting}
						size="lg"
						type="submit"
					>
						Next
					</c.ui.Button>
				</div>
			</form>
		</c.ui.Form>
	);
};

export default InvitePatientForm;
