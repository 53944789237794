import { ui } from '@/components/ui';
import { services as s } from '@/services';
import type { ScheduleT } from '@/types/zod/timings';
import { Link } from '@tanstack/react-router';

import { useAuth } from '@/utils/auth';

interface Props {
	schedule: ScheduleT;
}
const ScheduleCard = ({ schedule }: Props) => {
	const auth = useAuth();
	return (
		<ui.Card>
			<ui.CardHeader>
				<div className="flex flex-col px-2 w-full">
					<div className="flex justify-between">
						<div>
							<h4 className="font-normal text-lg text-slate-800">{schedule?.schedule_name ?? 'Schedule'}</h4>
							<h5 className="font-medium text-slate-600 text-sm">
								{!!schedule?.created_at && s.time.formatDate(schedule.created_at)}
							</h5>
						</div>
					</div>
				</div>
			</ui.CardHeader>
			<ui.CardContent>
				{!!schedule?.schedule_description && <ui.CardDescription>{schedule.schedule_description}</ui.CardDescription>}
				{auth?.id === schedule.author && 'Your Diary'}
			</ui.CardContent>
			<ui.CardFooter className="flex flex-wrap items-center gap-2">
				<ui.Button size="md" asChild>
					<Link to="/builder/schedule/$scheduleId" params={{ scheduleId: schedule.schedule_id }}>
						Edit
					</Link>
				</ui.Button>
				<ui.Button asChild variant="secondary" size="md">
					<Link to="/public/schedule/$scheduleId" params={{ scheduleId: schedule.schedule_id }}>
						Preview
					</Link>
				</ui.Button>
			</ui.CardFooter>
		</ui.Card>
	);
};

export default ScheduleCard;
