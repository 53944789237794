import { supabaseClient } from '@/lib/supabase';
import { queryOptions, useQuery } from '@tanstack/react-query';

export async function getClinicians() {
	const { data, error } = await supabaseClient.from('clinicians').select('*, profiles(*)');
	if (error) throw error;
	return data;
}

export const cliniciansQueryKey = ['clinicians'];

export const cliniciansQueryOptions = () => {
	return queryOptions({
		queryKey: ['clinicians'],
		queryFn: () => getClinicians(),
	});
};

export const useCliniciansQuery = () => {
	const options = cliniciansQueryOptions();
	const query = useQuery(options);
	return query;
};
