import type { ExtendedPageInputT, PageViewT } from '@/types/database/diaries';
import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

import type { FlattenedPageOrInput } from '../services/manipulatePageInputs';
import { flattenPageInputs, unflattenPageInputs } from '../services/manipulatePageInputs';

type State = {
	pages: PageViewT[];
};

type Actions = {
	setPages: (pages: PageViewT[]) => void;
	upsertPage: (page: PageViewT) => void;
	removePage: (page_id: string) => void;
	upsertPageInput: (pageInput: ExtendedPageInputT) => void;
	removePageInput: (input_id: string) => void;
	getFlattenedPageInputs: () => (PageViewT & ExtendedPageInputT & { type: 'page' | 'input' })[];
	setFlattenedPageInputs: (
		flattenedPageInputs: (PageViewT & ExtendedPageInputT & { type: 'page' | 'input' })[],
	) => void;
};

const initialState: State = {
	pages: [],
};

export const useFormStore = create<State & Actions>()(
	devtools(
		persist(
			(set, get) => ({
				...initialState,
				setPages: (pages: PageViewT[]) => set({ pages }),
				upsertPage: (page: PageViewT) =>
					set((state) => ({
						pages: state.pages.some((p) => p.page_id === page.page_id)
							? state.pages.map((p) => (p.page_id === page.page_id ? page : p))
							: [...state.pages, page],
					})),
				removePage: (page_id: string) =>
					set((state) => ({
						pages: state.pages.filter((p) => p.page_id !== page_id),
					})),
				upsertPageInput: (pageInput: ExtendedPageInputT) =>
					set((state) => ({
						pages: state.pages.map((page) => ({
							...page,
							inputs: page.inputs?.some((pi) => pi.input_id === pageInput.input_id)
								? page.inputs?.map((pi) => (pi.input_id === pageInput.input_id ? pageInput : pi))
								: [...page.inputs, pageInput],
						})),
					})),
				removePageInput: (input_id: string) =>
					set((state) => ({
						pages: state.pages.map((page) => ({
							...page,
							inputs: page.inputs?.filter((input) => input.input_id !== input_id),
						})),
					})),
				getFlattenedPageInputs: () => flattenPageInputs(get().pages),
				setFlattenedPageInputs: (flattenedPageInputs: FlattenedPageOrInput[]) => {
					const unflattendedPages = unflattenPageInputs(flattenedPageInputs);
					set({ pages: unflattendedPages });
				},
			}),
			{
				name: 'form-store',
				storage: createJSONStorage(() => localStorage),
			},
		),
	),
);
