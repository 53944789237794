import { Footprints, Hammer, PersonStanding, Pill, Syringe } from 'lucide-react';

export type TrackerPathT = 'injection' | 'surgery' | 'medication';

export interface TrackerT {
	name: string;
	path: string;
	description: React.ReactNode;
	icon: JSX.Element;
}

export function findTrackerByPath(path: string): TrackerT | undefined {
	return managements.find((tracker) => tracker.path.toUpperCase() === path.toUpperCase());
}

export const managements: TrackerT[] = [
	{
		name: 'Injection',
		path: 'injection',
		description: 'Therapeutic injection to a joint or tendon.',
		icon: <Syringe size={28} />,
	},
	{
		name: 'Surgery',
		path: 'surgery',
		description: 'Invasive procedure performed by a surgeon.',
		icon: <Hammer size={28} />,
	},
	{
		name: 'Physiotherapy', // Renamed for clarity
		path: 'physiotherapy', // Path updated for consistency
		description: 'Non-invasive exercise based tracker.',
		icon: <PersonStanding size={28} />, // Replace with your physiotherapy icon component
	},
	{
		name: 'Medication',
		path: 'medication',
		description: 'Oral or topical drug.',
		icon: <Pill size={28} />,
	},
	{
		name: 'Orthoses or Prostheses', // Renamed for clarity
		path: 'orthosis-prosthesis', // Path updated for consistency
		description: 'Insole, splint or artificial limb.',
		icon: <Footprints size={28} />, // Replace with your insole icon component
	},
];

const trackerKeys = managements.map((tracker) => tracker.path);
export type TrackerKeyT = (typeof trackerKeys)[number];

export const getManagements = (iconSize?: number, iconStrokeWidth?: number) => {
	const size = iconSize ?? 28;
	const strokeWidth = iconStrokeWidth ?? 1.75;
	return [
		{
			name: 'Injection',
			path: 'injection',
			description: 'Therapeutic injection to a joint or tendon.',
			icon: <Syringe size={size} strokeWidth={strokeWidth} />,
		},
		{
			name: 'Surgery',
			path: 'surgery',
			description: 'Invasive procedure performed by a surgeon.',
			icon: <Hammer size={size} strokeWidth={strokeWidth} />,
		},
		{
			name: 'Physiotherapy', // Renamed for clarity
			path: 'physiotherapy', // Path updated for consistency
			description: 'Non-invasive exercise based tracker.',
			icon: <PersonStanding size={size} strokeWidth={strokeWidth} />, // Replace with your physiotherapy icon component
		},
		{
			name: 'Medication',
			path: 'medication',
			description: 'Oral or topical drug.',
			icon: <Pill size={size} strokeWidth={strokeWidth} />,
		},
		{
			name: 'Orthoses or Prostheses', // Renamed for clarity
			path: 'orthosis-prosthesis', // Path updated for consistency
			description: 'Insole, splint or artificial limb.',
			icon: <Footprints size={size} strokeWidth={strokeWidth} />, // Replace with your insole icon component
		},
	];
};
