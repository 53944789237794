import { cn } from '@/lib/utils';

import type React from 'react';

interface Props {
	title: string | React.ReactNode;
	description?: React.ReactNode;
	caption?: string;
	left?: boolean;
	className?: string;
}

const SectionTitle = ({ title, caption, description, left, className }: Props) => {
	return (
		<div
			className={cn(
				'flex w-full flex-col items-center justify-center text-center',
				left && 'lg:items-start lg:justify-start lg:text-left',
				className,
			)}
		>
			{caption && <p className="font-medium text-cyan-600 text-lg dark:text-cyan-500">{caption}</p>}
			<h3
				className={cn(
					'mb-4 flex items-center justify-between gap-x-4 text-2xl font-semibold text-slate-800 dark:text-slate-200',
				)}
			>
				{title}
			</h3>
			{description && <div className={cn('max-w-lg text-lg text-slate-600 dark:text-slate-300')}>{description}</div>}
		</div>
	);
};

export default SectionTitle;
