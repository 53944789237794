import { colors } from '@/assets/colors';
import { ui } from '@/components/ui';
import { useAuth } from '@/utils/auth';
import { Star } from 'lucide-react';

import { useFavouriteTemplatesMutation, useUnfavouriteTemplateMutation } from '../api/upsertFavouriteDiaries';

interface Props {
	favourite: boolean;
	templateId: string;
}
const TemplateStar = ({ favourite, templateId }: Props) => {
	const auth = useAuth();

	const favouriteTemplate = useFavouriteTemplatesMutation();
	const unfavouriteTemplate = useUnfavouriteTemplateMutation();

	return (
		<ui.Button
			onClick={() => {
				favourite
					? unfavouriteTemplate.mutate({ user_id: auth.id, diary_template_id: templateId })
					: favouriteTemplate.mutate({ user_id: auth.id, diary_template_id: templateId });
			}}
			variant="ghost"
			className="[&_svg]:hover:animate-spin-slow h-fit w-fit p-2"
		>
			<Star
				fill={favourite ? colors['indigo-600'] : 'white'}
				stroke={favourite ? colors['indigo-600'] : colors['slate-300']}
			/>
		</ui.Button>
	);
};

export default TemplateStar;
