import { commonComponents as cc } from '@/components/common';
import { cn } from '@/lib/utils';
import { services as s } from '@/services';
import type { StatefulTrackerViewT } from '@/types/database/trackers';

import { useAuth } from '../../../utils/auth';
import { EventPreviews } from '../../events/components/EventPreview';
import { useProfileQuery } from '../../profile/api/selectProfile';
import TrackerContactPersonForm from './TrackerContactPersonForm';
import TrackerReferenceDateForm from './TrackerReferenceDateForm';
import TrackerStartDateForm from './TrackerStartDateForm';

interface Props {
	tracker: StatefulTrackerViewT;
}
const EditTracker = ({ tracker }: Props) => {
	const auth = useAuth();
	const { data: profile } = useProfileQuery();
	if (!tracker) {
		return <p>No Tracker</p>;
	}
	return (
		<cc.Group title={tracker.tracker_name ?? 'Tracker Details'}>
			<cc.EditDialog
				label="Start Date"
				description="The date from which you start tracking your pain."
				info={s.time.formatShortDateTime(tracker.start_date)}
				noInfo={!tracker.start_date}
			>
				<TrackerStartDateForm diaryId={tracker.diary_id} trackerId={tracker.tracker_id} />
			</cc.EditDialog>
			<cc.EditDialog
				label="Reference Date"
				description="The date of the surgery, injection, or medication change."
				info={s.time.formatShortDateTime(tracker.reference_date)}
				noInfo={!tracker.reference_date}
			>
				<TrackerReferenceDateForm diaryId={tracker.diary_id} trackerId={tracker.tracker_id} />
			</cc.EditDialog>
			<cc.EditDialog locked={!auth.roles?.isClinician} label="Contact Person" info={tracker.contact_email}>
				<TrackerContactPersonForm diaryId={tracker.diary_id} trackerId={tracker.tracker_id} />
			</cc.EditDialog>

			{profile?.is_super_user && (
				<>
					<cc.EditDialog show={true} label="Form" info={tracker.form?.form_name} locked={true} />
					<cc.EditDialog show={true} label="Schedule" info={tracker.schedule?.schedule_name} locked={true} />
				</>
			)}
		</cc.Group>
	);
};

export default EditTracker;
