import React from 'react';

const BeenAWhile = () => {
    return (
        <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M31 15.5C31 24.0604 24.0604 31 15.5 31C6.93959 31 0 24.0604 0 15.5C0 6.93959 6.93959 0 15.5 0C24.0604 0 31 6.93959 31 15.5Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.5 29.5C23.232 29.5 29.5 23.232 29.5 15.5C29.5 7.76801 23.232 1.5 15.5 1.5C7.76801 1.5 1.5 7.76801 1.5 15.5C1.5 23.232 7.76801 29.5 15.5 29.5ZM15.5 31C24.0604 31 31 24.0604 31 15.5C31 6.93959 24.0604 0 15.5 0C6.93959 0 0 6.93959 0 15.5C0 24.0604 6.93959 31 15.5 31Z"
                fill="black"
            />
            <path
                d="M10.5 7L11.2895 6.63561C14.7691 5.02965 18.8954 5.62459 21.8051 8.11864V8.11864C26.5044 12.1466 26.2921 19.5715 21.3611 23.3123V23.3123C18.4097 25.5512 14.4049 25.9026 11.1233 24.1836V24.1836C7.97362 22.5338 6 19.272 6 15.7164V14.5M7 11C7 11 7.5 10 8 9.5"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.5 8.25C15.9142 8.25 16.25 8.58579 16.25 9V15.7818L12.5644 19.9939C12.2917 20.3056 11.8179 20.3372 11.5061 20.0644C11.1944 19.7917 11.1628 19.3178 11.4356 19.0061L14.75 15.2182V9C14.75 8.58579 15.0858 8.25 15.5 8.25Z"
                fill="black"
            />
            <circle cx="26" cy="26" r="6.25" fill="#F24E1E" stroke="black" strokeWidth="1.5" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M26 21.75C26.4142 21.75 26.75 22.0858 26.75 22.5V26.5C26.75 26.9142 26.4142 27.25 26 27.25C25.5858 27.25 25.25 26.9142 25.25 26.5V22.5C25.25 22.0858 25.5858 21.75 26 21.75Z"
                fill="black"
            />
            <path
                d="M27 29C27 29.5523 26.5523 30 26 30C25.4477 30 25 29.5523 25 29C25 28.4477 25.4477 28 26 28C26.5523 28 27 28.4477 27 29Z"
                fill="black"
            />
        </svg>
    );
};

export default BeenAWhile;
