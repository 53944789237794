import { cn } from '@/lib/utils';
import { type VariantProps, cva } from 'class-variance-authority';
import type * as React from 'react';

const badgeVariants = cva(
	'inline-flex items-center gap-1 border-indigo-200 dark:border-indigo-800 px-2.5 py-0.5 border rounded-full focus:ring-2 focus:ring-indigo-950 dark:focus:ring-indigo-300 focus:ring-offset-2 font-medium text-xs transition-colors focus:outline-none',
	{
		variants: {
			variant: {
				default:
					'border-transparent bg-indigo-900 text-indigo-50 hover:bg-indigo-900/80 dark:bg-indigo-50 dark:text-indigo-900 dark:hover:bg-indigo-50/80',
				secondary:
					'border-transparent bg-indigo-100 text-indigo-900 hover:bg-indigo-100/80 dark:bg-indigo-800 dark:text-indigo-50 dark:hover:bg-indigo-800/80',
				destructive:
					'border-transparent bg-red-500 text-indigo-50 hover:bg-red-500/80 dark:bg-red-900 dark:text-indigo-50 dark:hover:bg-red-900/80',
				outline: 'text-indigo-950 dark:text-indigo-50 hover:bg-slate-50',
			},
			color: {
				pink: 'border-pink-400 bg-white hover:bg-pink-50 text-slate-700',
				rose: 'border-rose-400 bg-white hover:bg-rose-50 text-slate-700',
				fuchsia: 'border-fuchsia-400 bg-white hover:bg-fuchsia-50 text-slate-700',
				purple: 'border-purple-400 bg-white hover:bg-purple-50 text-slate-700',
				violet: 'border-violet-400 bg-white hover:bg-violet-50 text-slate-700',
				indigo: 'border-indigo-400 bg-white hover:bg-indigo-50 text-slate-700',
				blue: 'border-blue-400 bg-white hover:bg-blue-50 text-slate-700',
				sky: 'border-sky-400 bg-white hover:bg-sky-50 text-slate-700',
				cyan: 'border-cyan-400 bg-white hover:bg-cyan-50 text-slate-700',
				teal: 'border-teal-400 bg-white hover:bg-teal-50 text-slate-700',
				emerald: 'border-green-400 bg-white hover:bg-green-50 text-slate-700',
				green: 'border-green-400 bg-white hover:bg-green-50 text-slate-700',
				lime: 'border-lime-400 bg-white hover:bg-lime-50 text-slate-700',
				yellow: 'border-yellow-400 bg-white hover:bg-yellow-50 text-slate-700',
				amber: 'border-amber-400 bg-white hover:bg-amber-50 text-slate-700',
				orange: 'border-orange-400 bg-white hover:bg-orange-50 text-slate-700',
				red: 'border-red-400 bg-white hover:bg-red-50 text-slate-700',
				gray: 'border-gray-400 bg-white hover:bg-gray-50 text-slate-700',
			},
		},

		defaultVariants: {
			variant: 'default',
		},
	},
);

export interface BadgeProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, color, ...props }: BadgeProps) {
	return <div className={cn(badgeVariants({ variant, color }), className)} {...props} />;
}

export { Badge, badgeVariants };
