import { ui } from '@/components/ui';
import { createFileRoute } from '@tanstack/react-router';

const Diabetes = () => {
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    };

    return (
        <>
            <form className="px-2" onSubmit={handleSubmit}>
                <ui.Card title="Medical Details">
                    <ui.CardContent className="py-2">
                        <ui.Input></ui.Input>
                    </ui.CardContent>
                </ui.Card>
                <ui.Button type="submit">Next</ui.Button>
            </form>
        </>
    );
};
createFileRoute;
export const Route = createFileRoute('/_app/profile/medical/diabetes')({
    component: Diabetes,
    createFileRoute,
});

export default Diabetes;
