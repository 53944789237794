import { ui } from '@/components/ui';
import React from 'react';

interface Props {
    label: React.ReactNode;
    hint: string;
}
const TimeTooltip = (props: Props) => {
    return (
        <ui.TooltipProvider>
            <ui.Tooltip>
                <ui.TooltipTrigger className="w-full p-0">
                    <span className="flex w-full overflow-x-overflow">{props.label}</span>
                </ui.TooltipTrigger>
                <ui.TooltipContent>
                    <p className="max-w-sm text-wrap">{props.hint}</p>
                </ui.TooltipContent>
            </ui.Tooltip>
        </ui.TooltipProvider>
    );
};

export default TimeTooltip;
