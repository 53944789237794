interface Props {
    children: React.ReactNode;
}
const PhonePreview = ({ children }: Props) => {
    return (
        <div className="mx-auto px-2 pt-2 pb-2 w-fit bg-slate-800 rounded-[50px]">
            <div className="relative">
                <div className="absolute top-0 left-0 z-10 w-full h-8 bg-transparent">
                    <div className="h-6 mx-auto w-[100px] mt-2 bg-slate-900 rounded-full"></div>
                </div>
            </div>
            <div className="rounded-[43px] overflow-hidden pt-6 no-scrollbar overflow-y-scroll mx-auto bg-indigo-50 h-[600px] w-[280px]">
                <div className="scale-90">{children}</div>
            </div>
        </div>
    );
};

export default PhonePreview;
