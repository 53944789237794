const Line = () => {
	return (
		<div className="flex w-full h-[40px] -z-10">
			<div className="w-[30%]" />
			<div className="w-[6px] bg-cyan-500" />
			<div className="w-[calc(70%-10px)]" />
		</div>
	);
};

export default Line;
