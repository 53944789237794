import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { useNavigate, useParams, useSearch } from '@tanstack/react-router';

import Timer from '../../../../assets/icons/Timer';

export type TimerT = 0 | 5 | 10;

const SelectTimer = () => {
    const { diaryId } = useParams({ strict: false });
    const navigate = useNavigate();

    const { delay, angle, side } = useSearch({
        strict: false,
    });

    return (
        <div className="mx-auto w-full my-2 h-12">
            <Select
                defaultValue={String(delay)}
                onValueChange={(value) =>
                    navigate({
                        to: '/foot-watch/$diaryId/new/camera',
                        params: { diaryId: diaryId },
                        search: { delay: parseInt(value), angle: angle, side: side },
                    })
                }
            >
                <SelectTrigger className="w-full  [&>span]:flex [&>span]:gap-x-2 [&>span]:flex-row [&>span]:items-center">
                    <SelectValue />
                </SelectTrigger>
                <SelectContent>
                    <SelectDelay delay={0} />
                    <SelectDelay delay={5} />
                    <SelectDelay delay={10} />
                </SelectContent>
            </Select>
        </div>
    );
};

export default SelectTimer;

interface SelectDelayProps {
    delay: TimerT;
}

const SelectDelay = ({ delay }: SelectDelayProps) => {
    return (
        <SelectItem
            className="w-full bg-white [&>*]:flex [&>*]:gap-x-2 [&>*]:flex-row [&>*]:items-center"
            value={String(delay)}
        >
            <Timer delay={delay} stroke="black" className="h-8 w-8 lg:h-8 lg:w-8" />
            {delayWordMap[delay]}
        </SelectItem>
    );
};

const delayWordMap = {
    0: 'Off',
    5: 'Five',
    10: 'Ten',
};
