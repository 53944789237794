import {
	Card,
	CardContent,
	CardDescription,
	CardFooter,
	CardHeader,
	CardTitle,
} from "@/components/ui/card";

import Spinner from "@/components/common/Spinner";
import { Button, buttonVariants } from "@/components/ui/button";
import { useDub } from "../api/useDub";
import { useNavigateAndPrint } from '../../previews/hooks/useNavigateAndPrint';

interface Props {
	url: string;
	title?: string;
	description?: string;
}

const DubQrCode = ({ url, title, description }: Props) => {
	const { data, isFetching } = useDub(url);
	const {isPrinting,navigateAndPrint} = useNavigateAndPrint();
	return (
		<>
			<Card>
				<CardHeader>
					<CardTitle className="text-center">
						{title ?? "QR Code"}
					</CardTitle>
					<CardDescription className="text-center">
						{description ?? "Scan the QR code to Join"}
					</CardDescription>
				</CardHeader>
				<CardContent>
					<div className="flex justify-center items-center mx-auto min-w-sm min-h-sm">
						{isFetching ? <Spinner className="w-16 h-16" /> : (
							<img
								className="mx-auto p-4 max-w-sm max-h-sm"
								src={data?.qrCode}
								alt="QR Code"
							/>
						)}
					</div>
					{isFetching
						? (
							<a
								className={buttonVariants({ variant: "link" })}
								target="_blank"
								href={data?.shortLink}
								rel="noreferrer"
							>
								Go to Link
							</a>
						)
						: (
							<a
								className={buttonVariants({ variant: "link" })}
								target="_blank"
								href={data?.shortLink}
								rel="noreferrer"
							>
								Go to Link
							</a>
						)}
				</CardContent>
				<CardFooter>
					<p>
						By scanning, you agree to our{" "}
						<a
							tabIndex={-1}
							target="_blank"
							className="font-medium text-indigo-600 underline"
							href="https://patient-watch.notion.site/Terms-of-Use-816e45ec28e14eedba843dc8f3f74407"
							rel="noreferrer"
						>
							Terms of Use
						</a>{" "}
						and{" "}
						<a
							tabIndex={-1}
							target="_blank"
							className="font-medium text-indigo-600 underline"
							href="https://patient-watch.notion.site/Privacy-Policy-50288e6ac2d946bca935fbf5bdc30782"
							rel="noreferrer"
						>
							Privacy Policy
						</a>
						. Your care team will have access to your shared data.
					</p>
				</CardFooter>
			</Card>
			<Button
				variant="secondary"
				onClick={() => navigateAndPrint({ to: '/preview/invite/qr-code', search: { qrCode: data?.qrCode, title, description } })}
			>
				{isPrinting ? "Printing..." : "Print"}
			</Button>
		</>
	);
};

export default DubQrCode;
