import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';

import type React from 'react';

interface Skill {
	icon: React.ReactNode;
	name: string;
}
export interface TeamMemberI {
	image: string;
	name: string;
	role: string;
	description?: string | React.ReactNode;
	skills?: Skill[];
}

interface Props {
	teamMember: Partial<TeamMemberI>;
}

const TeamMember = ({ teamMember }: Props) => {
	return (
		// Make max width same as width of image
		<div className="flex flex-col items-center md:items-start mr-10px mb-8 w-full md:w-[29%] md:text-start">
			<img className="border-slate-300 mb-2 border rounded-[2rem]" src={teamMember.image} alt={teamMember.name} />
			<h2 className="mt-6 px-8 md:px-0 font-semibold text-2xl text-slate-900 lg:text-xl">{teamMember.name}</h2>
			<h3 className="mb-2 px-2 md:px-0 text-slate-500 text-xl lg:text-lg">{teamMember.role}</h3>
			{teamMember.description && <p className="mt-6 px-4 md:px-0 text-md text-slate-800">{teamMember.description}</p>}
			{teamMember.skills && (
				<ul className="flex flex-wrap gap-x-3 mx-2 mt-8">
					{teamMember.skills?.map((skill) => {
						return (
							<li className="text-slate-700" key={skill.name}>
								<Popover>
									<PopoverTrigger>{skill.icon}</PopoverTrigger>
									<PopoverContent className="w-fit text-lg">{skill.name}</PopoverContent>
								</Popover>
							</li>
						);
					})}
				</ul>
			)}
		</div>
	);
};

export default TeamMember;
