import { useNavigate } from '@tanstack/react-router';
import dayjs from 'dayjs';
import { toast } from 'sonner';

import { ImagesT } from '../../hooks/useImagesStore';
import { compressFootImages } from '../services/compressFootImages';
import useCreateFootEntryMutation from './useCreateFootEntryMutation';
import useCreateFootLogMutation from './useCreateFootLogMutation';
import useUploadImages from './useUploadImageMutation';

export const useUpload = () => {
    const createFootLog = useCreateFootLogMutation();
    const createFootEntry = useCreateFootEntryMutation();
    const uploadImage = useUploadImages();
    const navigate = useNavigate();

    async function upload(userId: string, diaryId: string, images: ImagesT) {
        navigate({
            to: '/foot-watch/$diaryId/new/upload/uploading',
            search: { uploadStage: 'Uploading Images', imageUploads: 0 },
        });

        try {
            const now = dayjs().format('DD-MM-YYYYTHH:mm');
            const compressedImages = await compressFootImages(images);

            const footLog = await createFootLog.mutateAsync({ diaryId: diaryId });
            createFootEntry.mutate({
                footLogId: footLog!.foot_log_id,
                userId: userId,
            });
            uploadImage(compressedImages, userId, now, footLog!.foot_log_id);

            toast.success('Successfully uploaded images');
            navigate({
                to: '/foot-watch/$diaryId/$view',
                search: { view: 'history' },
            });
        } catch (e) {
            console.log(e);
            toast.error('Error uploading images');
        }
    }

    return upload;
};
