import { cn } from '../../lib/utils';

interface Props {
	fill?: string;
	className?: string;
}

const JazzySpinner = ({ fill, className }: Props) => {
	return (
		<div className={cn('relative h-full w-full', className ?? '')}>
			<svg
				className="animate-midspin t-0 l-0 absolute h-full w-full"
				width="16"
				height="16"
				viewBox="0 0 16 16"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M8 16C12.4183 16 16 12.4183 16 8H8V0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14C11.3137 14 14 11.3137 14 8H8V2Z"
					fill={fill ? fill : 'white'}
					fillOpacity="0.175"
				/>
			</svg>
			<svg
				className="animate-fastspin t-0 l-0 absolute h-full w-full "
				width="16"
				height="16"
				viewBox="0 0 16 16"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M8 -3.49691e-07C3.58172 -1.56562e-07 -1.93129e-07 3.58172 0 8L2 8C2 4.68629 4.68629 2 8 2L8 -3.49691e-07Z"
					fill={fill ? fill : 'white'}
					fillOpacity="0.5"
				/>
			</svg>
		</div>
	);
};

export default JazzySpinner;
