import { cn } from '@/lib/utils';
import { services as s } from '@/services';
import type { StatefulDiaryT } from '@/types/database/diaries';
import type { StatefulTrackerViewT } from '@/types/database/trackers';
import dayjs from 'dayjs';
import { Clock, Lock } from 'lucide-react';

import Description from '@/components/common/Description';
import NewEntryLink from '../NewEntryLink';

interface Props {
	tracker: StatefulTrackerViewT;
	diary: StatefulDiaryT;
}

const EntryLocked = ({ tracker, diary }: Props) => {
	const is_locked = tracker?.next_entry?.is_locked;

	return (
		<>
			<NewEntryLink is_locked={is_locked} diaryId={diary.diary_id} trackerId={tracker.tracker_id!}>
				<p className="inline-flex items-center gap-x-4 border-indigo-400 mx-auto mb-4 p-2 border rounded-full w-fit font-medium text-center text-indigo-700 text-md text-wrap dark:text-indigo-300 capitalize">
					<Clock size={32} />
					<span className="inline-flex pr-2 w-fit">Next Log Due {dayjs(tracker.next_entry?.due_at).fromNow()}</span>
				</p>
			</NewEntryLink>
			<div className={cn('my-4 mt-8', is_locked ? 'animate-bounce' : 'animate-big-wiggle')}>
				<Lock size={60} strokeWidth={1.75} />
			</div>
			<Description>
				Everything is up to date. You will be prompted for your next entry at{' '}
				{s.time.formatShortDateTime(tracker.next_entry?.due_at)}.
			</Description>
		</>
	);
};

export default EntryLocked;
