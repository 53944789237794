import { useEffect, useState } from 'react';
import type { ProfileT } from '../../../types/database/profiles';
import { useAuth } from '../../../utils/auth';
import { useSelectFavouriteUsers } from '../api/selectFavouriteUsers';
import { useSelectStaff } from '../api/selectStaff';

const useFavouriteStaff = () => {
	const auth = useAuth();

	const [faves, setFaves] = useState<ProfileT[]>([]);
	const [nonFaves, setNonFaves] = useState<ProfileT[]>([]);
	const [all, setAll] = useState<ProfileT[]>([]);

	const { data: favourites } = useSelectFavouriteUsers();
	const { data: staff } = useSelectStaff();

	const processFavouriteUsers = (favourites, staff) => {
		try {
			const faveIds = favourites?.map((f) => f.favourited_user) ?? [];
			const faves: ProfileT[] = favourites?.map((f) => f.profiles)?.filter((f) => !!f.user_id) ?? [];
			const nonFaves: ProfileT[] =
				staff
					?.filter((s) => !faveIds?.includes(s?.user_id as string))
					?.filter((s) => !!s?.user_id && s?.user_id !== auth.id) ?? [];

			return { faves, nonFaves };
		} catch (e) {
			console.error(e);
			return { faves: [], nonFaves: [] };
		}
	};

	useEffect(() => {
		const { faves, nonFaves } = processFavouriteUsers(favourites, staff);
		setFaves(faves);
		setNonFaves(nonFaves);
		setAll([...faves, ...nonFaves]);
	}, [favourites, staff]);

	return { all, faves, nonFaves };
};

export default useFavouriteStaff;
