import { ui } from '@/components/ui';
import { processScheduleFromNowQueryOptions } from '@/features/schedules/api/processSchedule';
import { selectScheduleById } from '@/features/schedules/api/selectSchedulesById';
import TimingPreview from '@/features/schedules/components/TimingPreview';
import { createFileRoute } from '@tanstack/react-router';

const NewSchedule = () => {
	const { schedule, processedSchedule } = Route.useLoaderData();

	return (
		<section className="mx-auto py-8 container">
			<ui.Card className="mb-8">
				<ui.CardHeader>
					<ui.CardTitle className="flex items-center gap-2">{schedule?.schedule_name}</ui.CardTitle>
					<ui.CardDescription>{schedule?.schedule_description}</ui.CardDescription>
				</ui.CardHeader>
				<ui.CardFooter />
			</ui.Card>

			<TimingPreview processedSchedule={processedSchedule} />
		</section>
	);
};

export const Route = createFileRoute('/public/schedule/$scheduleId')({
	component: NewSchedule,
	loader: async ({ context, params }) => {
		const schedule = await selectScheduleById(params.scheduleId);
		const processedSchedule = await context.queryClient.ensureQueryData(processScheduleFromNowQueryOptions(schedule));
		return { schedule, processedSchedule };
	},
});
