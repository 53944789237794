import { supabaseClient } from '@/lib/supabase';
import type { InputKeyT, InputT } from '@/types/database/forms';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'sonner';

type UpsertInputT = Partial<InputT> & { input_id: string; input_key: InputKeyT };

export async function updateInput(input: UpsertInputT) {
	return await supabaseClient
		.from('inputs')
		.upsert(input)
		.eq('input_id', input.input_id)
		.eq('input_key', input.input_key)
		.select()
		.throwOnError()
		.single()
		.then((res) => res.data);
}

export const useUpdateInput = () => {
	return useMutation({
		mutationKey: ['input'],
		mutationFn: async (input: UpsertInputT) => {
			return await updateInput(input);
		},
		onError: () => {
			toast.error('Error creating diary log');
		},
		onSuccess: (data) => {
			return data;
		},
	});
};
