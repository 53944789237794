import { supabaseClient } from '@/lib/supabase';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';
import { toast } from 'sonner';

import type { EntryT } from '@/types/entries';

const updateEntry = async (data: Partial<EntryT>) => {
	return supabaseClient
		.from('entries')
		.update(data)
		.eq('entry_id', data.entry_id)
		.select()
		.throwOnError()
		.single()
		.then((result) => result.data);
};

export const useUpdateEntry = () => {
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	return useMutation({
		mutationKey: ['entry'],
		mutationFn: async (data: Partial<EntryT>) => {
			return await updateEntry(data);
		},
		onError: (error) => {
			console.error('An error occurred:', error);
			toast.error(`${error.message}`);
		},
		onSuccess: (data: EntryT) => {
			toast.success('Entry updated successfully');
			queryClient.setQueryData(['diary', { diaryId: data.diary_id }], data);

			navigate({
				to: '/diary/$diaryId/edit/entry/$entryId',
				params: (prev) => ({ ...prev }),
			});
		},
	});
};
export default useUpdateEntry;
