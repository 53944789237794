import React from 'react';
import { Button, buttonVariants } from '@/components/ui/button';
import { ArrowRight } from 'lucide-react';
import { Link } from '@tanstack/react-router';
import { cn } from '@/lib/utils';
interface Props {
    emoji: string;
    description: string;
    href: string;
    link: string;
}
const EmojiLink = ({ emoji, description, link, href }: Props) => {
    return (
        <div className="w-full flex flex-col items-center justify-center text-center">
            <p className="text-[60px]">{emoji}</p>
            <p className="text-xl mb-8 mt-4">{description}</p>
            <Link to={href} className={cn(buttonVariants({ variant: 'outline', size: 'lg', className: 'gap-x-1' }))}>
                {link} <ArrowRight />
            </Link>
        </div>
    );
};

export default EmojiLink;
