import * as React from 'react';

import { cn } from '@/lib/utils';

export function textAreaClassName(className: string) {
	return cn(
		'flex min-h-[80px] w-full rounded-md border border-slate-300 bg-white px-3 py-2 text-sm ring-offset-white dark:ring-offset-slate-900 placeholder:text-slate-500 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-indigo-300 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 dark:bg-slate-900 dark:border-slate-500 dark:text-slate-100',
		className,
	);
}

export interface TextareaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(({ className, ...props }, ref) => {
	return <textarea className={textAreaClassName(className ?? '')} ref={ref} {...props} />;
});
Textarea.displayName = 'Textarea';

export { Textarea };
