import { formComponents as fc } from '@/components/form';
import { ui } from '@/components/ui';
import { useInjectionMutation } from '@/features/injection/api/upsertInjection';
import type { ExtendedInjectionViewT } from '@/types/database/events';
import { zodResolver } from '@hookform/resolvers/zod';
import { type SubmitHandler, useForm } from 'react-hook-form';
// import InviteDialog from '../../invite/components/InviteDialog';
import { type InjectionSchemaT, InjectionSchemaZ } from '../types/injectionSchema';
import InjectionFormFields from './InjectionFormFields';

interface Props {
	organisationId: string;
	diaryId: string;
	eventId: string;
	injectionId: string;
	injection?: ExtendedInjectionViewT;
	onSuccess?: () => void;
}
const InjectionForm = ({ diaryId, eventId, injectionId, injection, organisationId, onSuccess }: Props) => {
	const injectionMutation = useInjectionMutation({ diaryId: diaryId });

	const form = useForm<InjectionSchemaT>({
		resolver: zodResolver(InjectionSchemaZ),
		defaultValues: {
			event_id: eventId,
			injection_id: injectionId,
			referring_clinician_id: injection?.referring_clinician_id ?? undefined,
			injection_method_id: injection?.injection_method_id ?? undefined,
			injecting_clinician_id: injection?.injecting_clinician_id ?? undefined,
		},
	});

	const onSubmit: SubmitHandler<InjectionSchemaT> = async (formData) => {
		injectionMutation.mutate(formData, { onSuccess: onSuccess });
	};

	return (
		<ui.Form {...form}>
			<form onSubmit={form.handleSubmit(onSubmit)} className="form">
				<InjectionFormFields organisationId={organisationId} />
				<fc.SaveButton />
			</form>
		</ui.Form>
	);
};

export default InjectionForm;
