import type { OptionT } from '@/components/form/Select';
import { cliniciansQueryOptions } from '@/features/auth/api/cliniciansQuery';
import { injectionMethodsQueryOptions } from '@/features/injection/api/selectInjectionMethods';
import { injectionComponents as ic } from '@/features/injection/components';
import useExtractInjection from '@/features/injection/hooks/useExtractInjection';
import { Route as EditInjectionDetailsRoute } from '@/routes/_app/diary/$diaryId/edit/event/$eventId/injection/$injectionId/details';
import { createFileRoute, useNavigate } from '@tanstack/react-router';
const Injection = () => {
	// const { diaryId, injectionId, eventId } = Route.useParams();
	// const { clinicianOptions, methodOptions } = Route.useLoaderData();
	// const injection = useExtractInjection(diaryId, injectionId);
	// const navigate = useNavigate();

	return (
		<></>
		// <ic.InjectionForm
		// 	injection={injection}
		// 	diaryId={diaryId}
		// 	eventId={eventId}
		// 	injectionId={injectionId}
		// 	clinicianOptions={clinicianOptions}
		// 	methodOptions={methodOptions}
		// 	navigateToNextPage={() =>
		// 		navigate({ to: EditInjectionDetailsRoute.to, params: { diaryId, injectionId, eventId } })
		// 	}
		// />
	);
};

export const Route = createFileRoute('/_app/diary/$diaryId/edit/event/$eventId/injection/$injectionId/')({
	loader: async ({ context }) => {
		const { data: clinicians, error: cliniciansError } = await context?.queryClient.ensureQueryData(
			cliniciansQueryOptions(),
		);
		if (cliniciansError) throw cliniciansError;

		const clinicianOptions = clinicians?.map((clinician) => {
			return { label: clinician.profiles?.name, value: clinician.clinician_id };
		}) as OptionT[];

		const { data: methods, error: methodsError } = await context?.queryClient.ensureQueryData(
			injectionMethodsQueryOptions(),
		);
		if (methodsError) throw methodsError;

		const methodOptions = methods?.map((m) => {
			return { label: m.injection_method, value: m.injection_method_id };
		}) as OptionT[];

		return { clinicianOptions, methodOptions };
	},
	component: Injection,
});
