import { colors } from '@/assets/colors';
import { ui } from '@/components/ui';
import { Star } from 'lucide-react';

import useDeleteFavouriteUser from '../api/deleteFavouriteUser';
import useInsertFavouriteUser from '../api/insertFavouriteUser';

interface Props {
	favourite: boolean;
	userId: string;
}
const FavouriteUserStar = ({ favourite, userId }: Props) => {
	const favouriteUser = useInsertFavouriteUser();
	const deleteUser = useDeleteFavouriteUser();

	return (
		<ui.Button
			tabIndex={-1}
			onClick={() => {
				favourite ? deleteUser.mutate(userId) : favouriteUser.mutate(userId);
			}}
			variant="ghost"
			className="p-2 w-fit h-fit [&_svg]:hover:animate-spin-slow"
		>
			<Star
				fill={favourite ? colors['indigo-600'] : 'transparent'}
				stroke={favourite ? colors['indigo-600'] : colors['slate-300']}
			/>
		</ui.Button>
	);
};

export default FavouriteUserStar;
