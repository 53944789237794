import { commonComponents as cc } from '@/components/common';
import { Route as HistoryRoute } from '@/routes/_app/diary/$diaryId/home/history';
import { useNavigate, useParams } from '@tanstack/react-router';
import { useDeleteEntryMutation } from '../api/deleteEntry';

interface Props {
	entryId: string;
	diaryId: string;
}

const DeleteEntryButton = (props: Props) => {
	const navigate = useNavigate();
	const deleteEntry = useDeleteEntryMutation(props.diaryId);

	return (
		<cc.DeleteButton
			variant="destructive-secondary"
			deleteFunction={() =>
				deleteEntry.mutate(
					{ entry_id: props.entryId },
					{ onSuccess: () => navigate({ to: HistoryRoute.to, params: { diaryId: props.diaryId } }) },
				)
			}
		/>
	);
};

export default DeleteEntryButton;
