interface Props {
    fill?: string;
}

function Warning({ fill }: Props) {
    return (
        <svg
            className="h-full w-full"
            width="44"
            height="38"
            viewBox="0 0 44 38"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M42.262 34.9979L23.7309 2.98965C22.9605 1.65908 21.0395 1.65909 20.2691 2.98966L1.73804 34.9979C0.966113 36.3313 1.92823 38 3.4689 38H40.5311C42.0718 38 43.0339 36.3313 42.262 34.9979Z"
                fill={fill ? fill : '#F24E1E'}
            />
            <path
                d="M20 14.3333C20 13.597 20.597 13 21.3333 13H22.6667C23.403 13 24 13.597 24 14.3333V25C24 25.7364 23.403 26.3333 22.6667 26.3333H21.3333C20.597 26.3333 20 25.7364 20 25V14.3333Z"
                fill="white"
            />
            <path
                d="M24 31C24 32.1046 23.1046 33 22 33C20.8954 33 20 32.1046 20 31C20 29.8954 20.8954 29 22 29C23.1046 29 24 29.8954 24 31Z"
                fill="white"
            />
        </svg>
    );
}

export default Warning;
