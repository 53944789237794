import FlipCamera from '@/assets/icons/FlipCamera';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';

import { FacingModeT, useCameraStore } from '../hooks/useCameraStore';

const SelectFace = () => {
    const camera = useCameraStore();
    const { facingMode } = useCameraStore((state) => state.cameraStatus);

    return (
        <div className="w-full h-12 mx-auto my-2">
            <Select defaultValue={facingMode} onValueChange={(value) => camera.setFacingMode(value as FacingModeT)}>
                <SelectTrigger className="w-full [&>span]:flex [&>span]:gap-x-2 [&>span]:flex-row [&>span]:items-center">
                    <SelectValue />
                </SelectTrigger>
                <SelectContent>
                    <SelectItem
                        className="w-full bg-white [&>*]:flex [&>*]:gap-x-2 [&>*]:flex-row [&>*]:items-center"
                        value="user"
                    >
                        <FlipCamera strokeWidth="2.5" stroke="black" className="w-8 h-8 px-1 lg:h-8 lg:w-8" />
                        Top
                    </SelectItem>
                    <SelectItem
                        className="flex bg-white [&>*]:flex [&>*]:gap-x-2 [&>*]:flex-row [&>*]:items-center"
                        value="environment"
                    >
                        <FlipCamera strokeWidth="2.5" stroke="black" className="w-8 h-8 px-1 lg:h-8 lg:w-8" />
                        Sole
                    </SelectItem>
                </SelectContent>
            </Select>
        </div>
    );
};

export default SelectFace;
