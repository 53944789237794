import { commonComponents as cc } from '../../../../components/common';
import { useDeleteDiaryMutation } from '../../api/deleteDiary';

interface Props {
	diaryId: string;
}

const DeleteDiaryButton = (props: Props) => {
	const deleteDiary = useDeleteDiaryMutation(props.diaryId);
	return <cc.DeleteButton words="Delete Diary" deleteFunction={() => deleteDiary.mutate(props.diaryId)} />;
};

export default DeleteDiaryButton;
