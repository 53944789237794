import ChartMap from '@/features/charts/components/ChartMap';
import { groupByInputId } from '@/features/charts/utils/groupByInputId';
import { diaryByIdQueryOptions } from '@/features/diary/api/getDiaryById';
import useExtractTracker from '@/features/entries/hooks/useExtractTracker';
import { trackerComponents as tc } from '@/features/trackers/components';
import { services as s } from '@/services';
import { createFileRoute } from '@tanstack/react-router';
import useExtractEntryValues from '../../../../../../../../features/entries/hooks/useExtractEntryValues';

export const TrackerPreview = () => {
	const { diaryId, trackerId } = Route.useParams();
	const { diary } = Route.useLoaderData();
	const tracker = useExtractTracker(diaryId, trackerId);
	const entryValues = useExtractEntryValues(diaryId);

	return (
		<>
			<h1>{diary.diary_name}</h1>
			<h2>{tracker?.tracker_name}</h2>
			<p>{s.time.formatRange(tracker?.start_date, tracker?.end_date)}</p>
			<p>Tracker type: {tracker?.tracker_type}</p>
			{diary.trackers_view.length > 1 && (
				<ul>
					{groupByInputId(entryValues).map(
						(evs) =>
							['pain-slider', 'glucose-slider'].includes(evs[0]?.input_key) && (
								<li key={`evs-${evs[0].entry_value_id}`} className="my-4 w-full">
									<ChartMap entryValues={evs} trackersView={diary.trackers_view} inputType={evs[0].input_key} />
								</li>
							),
					)}
				</ul>
			)}
			<tc.TrackerEntries tracker={tracker} />
		</>
	);
};
export const Route = createFileRoute('/preview/patient/$patientId/diary/$diaryId/tracker/$trackerId/')({
	component: TrackerPreview,
	loader: async ({ context, params }) => {
		const diary = await context?.queryClient.ensureQueryData(diaryByIdQueryOptions(params.diaryId));
		return { diary };
	},
});
