import { supabaseClient } from '@/lib/supabase';
import { queryOptions, useQuery } from '@tanstack/react-query';

export async function selectTemplates() {
	return await supabaseClient
		.from('schedules')
		.select('*, timings(*)')
		.throwOnError()
		.then((res) => res.data);
}

export const templatesQueryKey = ['time-templates'];

export const schedulesQueryOptions = () => {
	return queryOptions({
		queryKey: templatesQueryKey,
		queryFn: () => selectTemplates(),
	});
};

export const useSchedulesQuery = () => {
	const options = schedulesQueryOptions();
	const query = useQuery(options);
	return query;
};
