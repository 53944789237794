import type { Tracker, TrackerTemplateViewT } from '@/types/database/trackers';
import dayjs from 'dayjs';

export const trackerTemplateToTracker = (
	diaryId: string,
	tracker_template: TrackerTemplateViewT,
): Tracker['Insert'] => {
	return {
		tracker_name: tracker_template.tracker_template_name,
		diary_id: diaryId,
		start_date: dayjs().toISOString(),
		form_id: tracker_template.form_id,
		schedule_id: tracker_template.schedule_id,
		contact_id: tracker_template.contact_id ?? null,
		emergency_contact_id: tracker_template.emergency_contact_id ?? null,
		tracker_template_id: tracker_template.tracker_template_id,
		management_types: tracker_template.management_types,
		tracker_type: tracker_template.tracker_type,
		is_pausable: tracker_template.is_pausable ?? false,
		late_timing_id: tracker_template.late_timing_id,
		reminder_schedule_id: tracker_template.reminder_schedule_id,
	};
};
