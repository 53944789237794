import { commonComponents as cc } from '@/components/common';
import { diaryByIdQueryOptions, useDiaryByIdQuery } from '@/features/diary/api/getDiaryById';
import DiaryTrackersTemplates from '@/features/templates/components/DiaryTrackersTemplates';
import { Route as EventRoute } from '@/routes/_app/new-diary/patient/$patientId/organisation/$organisationId/diary/$diaryId/event/$eventId/event-options';
import { services as s } from '@/services';
import { z } from '@/utils/form';
import { createFileRoute, useNavigate } from '@tanstack/react-router';
// TODO - Do you want to cancel on-going trackers dialog

const TrackerTemplates = () => {
	const navigate = useNavigate();
	const { patientId, organisationId, diaryId, trackerId } = Route.useParams();
	const { data: diary } = useDiaryByIdQuery(diaryId);

	const navigateNextPage = () =>
		navigate({
			to: EventRoute.to,
			params: { diaryId: diaryId, patientId: patientId, organisationId: organisationId, eventId: s.uuidv4() },
			search: { trackerId: trackerId },
		});

	return (
		<>
			<cc.Title words="Form and Schedule" />
			<cc.Stepper
				steps={[
					{ stage: 'past', words: 'Patient' },
					{ stage: 'past', words: 'Diary' },
					{ stage: 'current', words: 'Form and Schedule' },
					{ stage: 'future', words: 'Intervention' },
				]}
			/>
			<DiaryTrackersTemplates trackerId={trackerId} onSuccess={() => navigateNextPage()} diary={diary} />
		</>
	);
};

const NewTrackerSearchSchemaZ = z.object({
	eventId: z.string().uuid().optional(),
});
export const Route = createFileRoute(
	'/_app/new-diary/patient/$patientId/organisation/$organisationId/diary/$diaryId/tracker/$trackerId/tracker-templates',
)({
	component: TrackerTemplates,
	loader: async ({ context, params }) => {
		await context?.queryClient.ensureQueryData(diaryByIdQueryOptions(params.diaryId));
	},
	validateSearch: (search) => NewTrackerSearchSchemaZ.parse(search),
	errorComponent: () => <p>Tracker Templates Error</p>,
});