// NOTE - This button is intended for use within a link row so that the whole row is clicked not the button
import { ui } from '@/components/ui';
import { ChevronRight } from 'lucide-react';

const ChevronButton = () => {
	return (
		<ui.Button asChild size="icon" variant="outline" className="h-10 w-10 rounded-full p-0 pl-[2px]" tabIndex={-1}>
			<ChevronRight size={24} />
		</ui.Button>
	);
};

export default ChevronButton;
