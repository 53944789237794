import { supabaseClient } from '@/lib/supabase';
import type { SurgeryOption } from '@/types/database/events';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';

import { surgeryOptionsQueryKey } from './selectSurgeryOptions';

export async function upsertSurgeryOption(surgeryOptionData: SurgeryOption['Insert']) {
	const { data, error } = await supabaseClient.from('surgery_options').upsert(surgeryOptionData).select().single();
	if (error) throw error;
	return data;
}

export const useUpsertSurgeryOption = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationKey: ['upsert-surgery-option'],
		mutationFn: async (data: SurgeryOption['Insert']) => {
			return await upsertSurgeryOption(data);
		},
		onError: () => {
			toast.error('Error creating new surgery');
		},
		onSuccess: (mutation) => {
			toast.success('New surgery option saved');

			mutation?.onSuccess && mutation.onSuccess();
		},
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey: surgeryOptionsQueryKey });
		},
	});
};
