import { formComponents as fc } from '@/components/form';
import { Form } from '@/components/ui/form';
import { useForm } from '@/utils/form';
import { Button } from '../../../components/ui/button';
import { useUpdatePreferencesMutation } from '../api/setPreferencesMutation';
import usePreferencesStore from '../hooks/usePreferencesStore';

interface Props {
	onSuccess?: () => void;
}

export default function PreferencesForm({ onSuccess }: Props) {
	const preferences = usePreferencesStore((state) => state.preferences);

	const form = useForm({
		defaultValues: {
			email: preferences.channel_types?.email ?? false,
			sms: preferences.channel_types?.sms ?? false,
		},
	});

	if (!preferences) {
		return null;
	}

	const updatePreferences = useUpdatePreferencesMutation();

	const onSubmit = async (data) => {
		await updatePreferences.mutate(data, { onSuccess });
	};

	return (
		<Form {...form}>
			<form className="flex flex-col gap-y-2 my-4" onSubmit={form.handleSubmit(onSubmit)}>
				<div className="space-y-4 border-slate-200 bg-white my-4 p-4 border rounded-lg">
					<fc.Switch name="email" label="Email" />
					<fc.Switch name="sms" label="SMS" />
				</div>
				<Button type="submit">Next</Button>
			</form>
		</Form>
	);
}
