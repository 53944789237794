import React from 'react';

const OpenDeleted = () => {
    return (
        <svg width="20" height="26" viewBox="0 0 20 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.94843 9.75181C2.36166 9.72331 2.71975 10.0352 2.74825 10.4484L3.70009 24.25H15.3L16.2518 10.4484C16.2803 10.0352 16.6384 9.72331 17.0516 9.75181C17.4649 9.78031 17.7768 10.1384 17.7483 10.5516L16.7483 25.0516C16.7211 25.4449 16.3942 25.75 16 25.75H3.00003C2.60584 25.75 2.27893 25.4449 2.25181 25.0516L3.00003 25C2.25181 25.0516 2.25181 25.0517 2.25181 25.0516L1.25181 10.5516C1.22331 10.1384 1.5352 9.78031 1.94843 9.75181Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.60067 0.690496C6.20711 0.561351 5.78337 0.775707 5.65422 1.16927L4.7968 3.78225L1.23349 2.61298C0.83992 2.48384 0.416179 2.69819 0.287034 3.09176C0.157889 3.48532 0.372245 3.90907 0.765811 4.03821L18.8187 9.9621C19.2123 10.0912 19.636 9.87689 19.7652 9.48332C19.8943 9.08975 19.68 8.66601 19.2864 8.53687L15.7236 7.36776L16.581 4.75478C16.7101 4.36122 16.4958 3.93748 16.1022 3.80833L6.60067 0.690496ZM14.2983 6.90008L14.9219 4.99972L6.84561 2.34956L6.22203 4.24992L14.2983 6.90008Z"
                fill="black"
            />
        </svg>
    );
};

export default OpenDeleted;
