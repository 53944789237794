import { supabaseClient } from '@/lib/supabase';
import type { FavouriteUserT } from '@/types/database/profiles';
import { useAuth } from '@/utils/auth';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';

import { createFavouriteUsersQueryKey } from './selectFavouriteUsers';

const insertFavouriteUser = async (newUser: FavouriteUserT) => {
	const { data, error } = await supabaseClient.from('favourite_users').insert(newUser).select().single();
	if (error) throw error;
	return data;
};
export const useInsertFavouriteUser = () => {
	const queryClient = useQueryClient();
	const auth = useAuth();
	return useMutation({
		mutationFn: async (userId: string) => insertFavouriteUser({ favourited_by: auth.id, favourited_user: userId }),
		onError: (error) => {
			toast.error(`${error.message}`);
		},
		onSuccess: () => {
			toast.success('Favourite user created successfully');
		},
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey: createFavouriteUsersQueryKey(auth.id) });
		},
	});
};
export default useInsertFavouriteUser;
