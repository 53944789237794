import { supabaseClient } from '@/lib/supabase';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';
import { toast } from 'sonner';

import type { TrackerT } from '@/types/database/diaries';
const deleteTracker = async (trackerId: string) => {
	return supabaseClient.from('trackers').delete().eq('tracker_id', trackerId).throwOnError();
};

export const useDeleteTrackerMutation = () => {
	const queryClient = useQueryClient();
	const navigate = useNavigate();
	return useMutation({
		mutationKey: ['delete-tracker'],
		mutationFn: async (trackerId: string) => {
			return await deleteTracker(trackerId);
		},
		onError: (error) => {
			console.error('An error occurred:', error);
			toast.error(`${error.message}`);
		},
		onSuccess: (data: TrackerT) => {
			toast.success('Tracker deleted successfully');
			queryClient.setQueryData(
				[
					'tracker',
					{
						trackerId: data.tracker_id,
					},
				],
				null,
			);
			navigate({ to: '/diary/$diaryId/home/history', params: (prev) => ({ ...prev }) });
		},
	});
};
export default useDeleteTrackerMutation;
