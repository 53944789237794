import { Link } from '@tanstack/react-router';
import { ChevronRight } from 'lucide-react';
import { buttonVariants } from '../../../components/ui/button';
import { cn } from '../../../lib/utils';

interface TrackerLinkItemProps {
	to: string;
	params: Record<string, string>;
	icon: React.ReactNode;
	text: string | undefined;
	className?: string;
}

function TrackerLinkItem({ to, params, icon, text, className }: TrackerLinkItemProps) {
	return (
		<Link
			className={cn(
				'w-full flex justify-between items-center hover:bg-slate-100 dark:hover:bg-slate-800 mb-2 px-3 py-2 rounded-md font-medium text-left text-sm transition-all duration-200',
				className,
			)}
			to={to}
			params={params}
		>
			<span className="flex items-center gap-2 w-full">
				{icon}
				<span className="max-w-[200px] overflow-x-scroll">{text}</span>
			</span>
			<ChevronRight />
		</Link>
	);
}

export default TrackerLinkItem;
