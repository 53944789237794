import EditInputCard from './EditInputCard';
import InputConfig from './InputConfig';
import InputParameter from './InputParameter';
import InputSettings from './InputSettings';
import InputSettingsDrawer from './InputSettingsDrawer';
import InputSettingsSheet from './InputSettingsSheet';
import InputSwitch from './InputSwitch';
import InputType from './InputType';
import Inputs from './Inputs';
import InputsTitle from './InputsTitle';
import OrderInputs from './OrderInputs';

export const inputComponents = {
	EditInputCard,
	InputSwitch,
	InputsTitle,
	InputConfig,
	InputSettings,
	InputSettingsDrawer,
	InputSettingsSheet,
	InputParameter,
	InputType,
	Inputs,
	OrderInputs,
};
