import { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import WarningMessage from './WarningMessage';

interface Props {
    initialSeconds: number;
    runFunction: () => void;
}
const Timer = ({ initialSeconds, runFunction }: Props) => {
    const [seconds, setSeconds] = useState<number>(initialSeconds);

    useEffect(() => {
        const myInterval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                runFunction();
            }
        }, 1000);
        return () => {
            clearInterval(myInterval);
        };
    }, [seconds, runFunction]);

    return (
        <ErrorBoundary fallback={<WarningMessage words="Something went with camera timer" />}>
            <div className="z-50 w-full absolute top-[40vh] justify-center align-center flex">
                {seconds === 0 ? null : <h1 className="z-50 text-white mx-auto font-medium text-[5rem]">{seconds}</h1>}
            </div>
        </ErrorBoundary>
    );
};

export default Timer;
