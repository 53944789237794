import Title from '@/components/common/Title';
import { ui } from '@/components/ui';
import { Link, createFileRoute } from '@tanstack/react-router';
import { UserCheck2 } from 'lucide-react';

const EmailConfirmed = () => {
	return (
		<div className="auth-wrap space-y-8">
			<Title words="Email Confirmed" description="You can now proceed to login." />
			<UserCheck2 className="icon-xl mx-auto flex w-fit py-8" strokeWidth="1" />
			<Link to="profile">
				<ui.Button>Next</ui.Button>
			</Link>
		</div>
	);
};
createFileRoute;

export const Route = createFileRoute('/_auth/email-confirmed')({
	component: EmailConfirmed,
});

export default EmailConfirmed;
