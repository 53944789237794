import { services as s } from '@/services';
import { filter } from 'fuzzy';
import { Search } from 'lucide-react';
import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { sortBy } from 'underscore';
import { ui } from '../ui';
import type { OptionT } from './Select';

interface Props {
	label?: string;
	name: string;
	options: OptionT[];
}

const CheckboxList = ({ label, name, options }: Props) => {
	const { control, setValue, watch } = useFormContext();
	const [search, setSearch] = useState<string>('');
	const [isAllSelected, setIsAllSelected] = useState<boolean>(false);

	const selectedValues = watch(name);

	useEffect(() => {
		setIsAllSelected(selectedValues?.length === options.length);
	}, [selectedValues, options.length]);

	const handleSelectAll = (checked: boolean) => {
		setValue(name, checked ? options.map((option) => option.value) : []);
	};

	const showSelectAll = options.length > 5;

	return (
		<>
			{label && <ui.Label>{label}</ui.Label>}
			{showSelectAll && (
				<>
					<div className="flex items-center border-slate-300 px-2 border-b text-slate-600">
						<Search className="ml-2" />
						<input
							placeholder="Filter the options"
							className="border-0 p-2 ring-0 text-sm outline-none"
							value={search}
							onChange={(e) => setSearch(e.target.value)}
						/>
					</div>
					{!search && (
						<div className="flex items-center px-4 py-2 border-b">
							<ui.Checkbox checked={isAllSelected} onCheckedChange={handleSelectAll} id="select-all" className="mr-2" />
							<ui.Label htmlFor="select-all" className="">
								{isAllSelected ? 'Deselect All' : 'Select All'}
							</ui.Label>
						</div>
					)}
				</>
			)}
			<Controller
				name={name}
				control={control}
				render={({ field: { value, onChange } }) => (
					<ul className="flex flex-col px-2 py-1">
						{sortBy(filter(search, options, { pre: '<', post: '>', extract: (el) => String(el['label']) }), 'label')
							?.map((o) => o.original)
							?.map((option) => (
								<li key={option.value} className="flex items-center gap-2 p-2">
									<ui.Checkbox
										checked={Array.isArray(value) && value.includes(option.value)}
										onCheckedChange={(checked) => {
											const newValue = Array.isArray(value) ? value : [];
											if (checked) {
												newValue.push(option.value);
											} else {
												newValue.splice(newValue.indexOf(option.value), 1);
											}
											onChange(newValue);
										}}
									/>
									<ui.Label className="font-normal capitalize">{s.capitaliseSentence(option.label, '-')}</ui.Label>
								</li>
							))}
					</ul>
				)}
			/>
			<ui.FormMessage />
		</>
	);
};

export default CheckboxList;
