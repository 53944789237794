import React from 'react';

const FinishFlag = () => {
	return (
		<svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M20.39 9.80011L13.5864 12.4035V39.7171L20.39 37.1137C25.1548 35.2904 30.4486 35.5739 35.0002 37.8962C39.5519 40.2185 44.8456 40.502 49.6105 38.6787L56.414 36.0753V8.7617L49.6105 11.3651C44.8456 13.1884 39.5519 12.9049 35.0002 10.5826C30.4486 8.26033 25.1548 7.97681 20.39 9.80011Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M34.2088 12.3822C30.1361 10.3014 25.3994 10.0473 21.1358 11.681L15.5385 13.8258V37.0817L19.8934 35.413C25.0782 33.4263 30.8384 33.7352 35.7912 36.2656C39.8639 38.3464 44.6006 38.6004 48.8642 36.9667L54.4615 34.8219V11.5661L50.1066 13.2348C44.9218 15.2215 39.1616 14.9126 34.2088 12.3822ZM15.5385 40.9432L21.1358 38.7984C25.3994 37.1647 30.1361 37.4188 34.2088 39.4996C39.1616 42.03 44.9218 42.3389 50.1066 40.3522L56.852 37.7675C57.5425 37.5029 58 36.8284 58 36.0748V8.95736C58 8.36443 57.7155 7.80919 57.2385 7.47146C56.7616 7.13373 56.1529 7.05645 55.6095 7.26464L48.8642 9.84934C44.6006 11.483 39.8639 11.229 35.7912 9.14822C30.8384 6.61781 25.0782 6.30888 19.8934 8.29559L15.5385 9.96432V8.95736C15.5385 7.95893 14.7463 7.14953 13.7692 7.14953C12.7921 7.14953 12 7.95893 12 8.95736V63.1922C12 64.1906 12.7921 65 13.7692 65C14.7463 65 15.5385 64.1906 15.5385 63.1922V40.9432Z"
				fill="currentColor"
			/>
			<path
				d="M22.1202 27.5806L20.9126 27.9899C18.9602 28.6516 17.0578 29.4527 15.2202 30.387V38.3386L22.1202 35.5322V27.5806Z"
				fill="black"
				stroke="black"
				strokeWidth="0.01"
			/>
			<path
				d="M55.2399 27.1128L54.7645 27.4149C52.6416 28.7641 50.3177 29.7673 47.8799 30.387V38.8063L55.2399 35.5321V27.1128Z"
				fill="black"
				stroke="black"
				strokeWidth="0.01"
			/>
			<path
				d="M31.6242 27.5278L30.3999 27.1128V35.9999L38.6799 39.2741V30.387C37.457 30.0761 36.2723 29.6307 35.1475 29.0588L34.6053 28.7831C33.6429 28.2939 32.6467 27.8743 31.6242 27.5278Z"
				fill="black"
				stroke="black"
				strokeWidth="0.01"
			/>
			<path
				d="M47.8799 13.0806V21.0322L47.9506 21.0143C50.3452 20.4055 52.6436 19.4671 54.7799 18.2258V10.2742L47.8799 13.0806Z"
				fill="black"
				stroke="black"
				strokeWidth="0.01"
			/>
			<path
				d="M30.3999 10.2742V18.6935C31.0091 18.6935 31.6142 18.7933 32.1912 18.9888L35.4599 20.0968L38.6799 21.5V13.0806L30.3999 10.2742Z"
				fill="black"
				stroke="#FF0000"
				strokeWidth="0.01"
			/>
			<path
				d="M18.8998 20.0968L14.7598 21.5V12.6129L22.1198 10.2742V19.1613L22.24 19.1205C24.8687 18.2295 27.6924 18.0818 30.3998 18.6935V27.1129C27.6924 26.5011 24.8687 26.6489 22.24 27.5399L22.1198 27.5806V19.1613L18.8998 20.0968Z"
				fill="black"
			/>
			<path
				d="M22.1198 19.1613L18.8998 20.0968L14.7598 21.5V12.6129L22.1198 10.2742V19.1613ZM22.1198 19.1613L22.24 19.1205C24.8687 18.2295 27.6924 18.0818 30.3998 18.6935V18.6935V27.1129V27.1129C27.6924 26.5011 24.8687 26.6489 22.24 27.5399L22.1198 27.5806V19.1613Z"
				stroke="#FF0000"
				strokeWidth="0.01"
			/>
			<path
				d="M47.8802 21.0322L47.7701 21.0658C44.8234 21.9647 41.6991 22.1139 38.6802 21.5V30.3871C41.7158 31.0044 44.8446 31.0044 47.8802 30.3871V21.0322Z"
				fill="black"
				stroke="black"
				strokeWidth="0.01"
			/>
		</svg>
	);
};

export default FinishFlag;
