import { ui } from '@/components/ui';

import { HelpCircle } from 'lucide-react';
import { sliderFace } from './sliderFace';

const PainScaleKey = () => {
	return (
		<ui.Dialog>
			<ui.DialogTrigger className="flex items-center  justify-center gap-x-2 text-slate-800 dark:text-slate-200 text-md">
				<HelpCircle />
				Pain Scale Definitions
			</ui.DialogTrigger>
			<ui.DialogContent className="text-slate-800 dark:text-slate-200">
				<ui.DialogHeader className="  text-xl">Pain Scale Definitions</ui.DialogHeader>
				<ui.Table className="text-lg">
					<ui.TableHeader className="text-slate-700 dark:text-slate-300">
						<ui.TableRow>
							<ui.TableHead className="font-semibold">Face</ui.TableHead>
							<ui.TableHead className="font-semibold">Description</ui.TableHead>
							<ui.TableHead className="font-semibold">Pain Score</ui.TableHead>
						</ui.TableRow>
					</ui.TableHeader>
					<ui.TableBody>
						{[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => (
							<ui.TableRow key={`pain_scale_${i}`} className="  ">
								<ui.TableCell className="[&_svg]:h-8 [&_svg]:w-8">{sliderFace(i)?.icon}</ui.TableCell>
								<ui.TableCell className="text-md">{sliderFace(i)?.message}</ui.TableCell>
								<ui.TableCell className="ml-auto text-md">{i}</ui.TableCell>
							</ui.TableRow>
						))}
					</ui.TableBody>
				</ui.Table>
			</ui.DialogContent>
		</ui.Dialog>
	);
};

export default PainScaleKey;
