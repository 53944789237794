import Title from '@/components/common/Title';
import { selectUsersStatisticsQueryOptions } from '@/features/admin/api/selectUsersStatistics';
import UsersStatisticsTable from '@/features/admin/components/UsersStatisticsTable';
import { createFileRoute } from '@tanstack/react-router';
import { components as c } from '../../../components';
const UserssAdminPage = () => {
	const { orgStats } = Route.useLoaderData();
	return (
		<div className="lg-width">
			<Title words="Users Statistics" />
			<c.ui.Card className="p-4">
				<UsersStatisticsTable usersStatistics={orgStats} />
			</c.ui.Card>
		</div>
	);
};

export const Route = createFileRoute('/_app/admin/users-statistics')({
	component: UserssAdminPage,
	loader: async ({ context }) => {
		const orgStats = await context?.queryClient.ensureQueryData(selectUsersStatisticsQueryOptions());
		return { orgStats };
	},
});
