import CameraHeader from "./CameraHeader";
import CameraPermissions from "./CameraPermissions";
import CheckFoot from "./CheckFoot";
import CheckFootModal from "./CheckFootModal";
import DeviceCheck from "./DeviceCheck";
import { FaceT } from "./SelectCamera";
import SelectCamera from "./SelectCamera";
import SelectTimer from "./SelectTimer";
import { TimerT } from "./SelectTimer";
import Timer from "./Timer";

export const cameraComponents = {
  CameraHeader,
  CameraPermissions,
  CheckFoot,
  CheckFootModal,
  DeviceCheck,
  SelectCamera,
  SelectTimer,
  Timer,
};

export type { FaceT, TimerT };
