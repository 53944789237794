import { ui } from '@/components/ui';
import { useFormContext } from 'react-hook-form';

const ConsentCheckbox = () => {
	const form = useFormContext();

	return (
		<ui.FormField
			control={form.control}
			name="terms"
			render={({ field }) => {
				return (
					<ui.FormItem>
						<div className="flex flex-row items-center space-x-3 space-y-0">
							<ui.FormControl>
								<ui.Checkbox
									role="checkbox"
									id="consent-checkbox"
									className="rounded-[4px] w-[24px] h-[24px]"
									checked={field.value}
									onCheckedChange={field.onChange}
									data-testid="consent-checkbox"
								/>
							</ui.FormControl>
							<ui.FormLabel htmlFor="consent-checkbox" className="text-sm">
								I accept the{' '}
								<a
									tabIndex={-1}
									target="_blank"
									className="font-medium text-indigo-600 underline"
									href="https://patient-watch.notion.site/Terms-of-Use-816e45ec28e14eedba843dc8f3f74407"
									rel="noreferrer"
								>
									Terms of Use
								</a>{' '}
								and{' '}
								<a
									tabIndex={-1}
									target="_blank"
									className="font-medium text-indigo-600 underline"
									href="https://patient-watch.notion.site/Privacy-Policy-50288e6ac2d946bca935fbf5bdc30782"
									rel="noreferrer"
								>
									Privacy Policy
								</a>
								.
							</ui.FormLabel>
						</div>
						<ui.FormMessage />
					</ui.FormItem>
				);
			}}
		/>
	);
};

export default ConsentCheckbox;
