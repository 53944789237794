import type { ActionViewT } from '../../../types/database/actions';

interface Props {
	action: ActionViewT;
}
const ActionBody = (props: Props) => {
	return (
		<div className="flex flex-col">
			<b className="text-md">{props.action.subject}</b>
			<p className="text-sm">{props.action.body}</p>
		</div>
	);
};

export default ActionBody;
