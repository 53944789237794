import { cn } from '../../../lib/utils';
import Section from '../components/Section';
import SectionTitle from '../components/SectionTitle';
import WrappingIconPoints from '../components/WrappingIconPoints';
import { problemContent } from './content/problemContent';

const Problem = () => {
	return (
		<Section>
			<SectionTitle
				caption="Problem"
				title="Why would you need Patient Watch?"
				description={<span>Here are some common problems clinicians face in their daily practice.</span>}
			/>

			<WrappingIconPoints content={problemContent} />
		</Section>
	);
};

export default Problem;
