import { cameraComponents as cac } from '@/features/camera/components';
import { services as s } from '@/services';
import { InputT } from '@/types/database/diaries';
import React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import Webcam from 'react-webcam';

import { useCameraStore } from '../hooks/useCameraStore';
import { usePhotosStore } from '../hooks/usePhotoStore';

export type Props = {
    nextPage?: () => void;
    overlay?: React.ReactNode;
    qualityCheck?: React.ReactNode;
    // label: string;
    input: InputT;
};

const Camera = ({ nextPage, qualityCheck, overlay, input }: Props) => {
    const currentPhoto = usePhotosStore((state) => state.getCurrentPhoto());
    const currentPhotoId = usePhotosStore((state) => state.currentPhotoId);
    const photos = usePhotosStore();
    const camera = useCameraStore();
    const { delay, facingMode } = useCameraStore((state) => state.cameraStatus);

    const [timerRunning, setTimerRunning] = useState<boolean>(false);

    function startTimer() {
        setTimerRunning(true);
    }
    function stopTimer() {
        setTimerRunning(false);
    }

    const retake = () => {
        photos.resetPhoto(currentPhotoId);
        startTimer();
    };

    useEffect(() => {
        stopTimer();
    }, [photos]);

    const webcamRef = useRef(null);

    const capture = useCallback(() => {
        stopTimer();

        const imageSrc = webcamRef?.current?.getScreenshot();
        console.log(imageSrc);

        if (imageSrc) {
            photos.upsertPhoto({ img: imageSrc, label: input.label ?? '', id: currentPhotoId });
        }
    }, [webcamRef]);

    return (
        <section className="absolute left-0 top-0 h-[100vh] max-h-[100vh] overflow-hidden w-[100vw] flex-col items-center justify-between">
            <ErrorBoundary fallback={<p>Something went with camera permissions</p>}>
                {/* <cac.CameraPermissions /> */}
            </ErrorBoundary>

            <ErrorBoundary fallback={<p>Something went with camera header</p>}>
                <cac.CameraHeader />
            </ErrorBoundary>

            {qualityCheck ?? <></>}
            {/* {images[variation]?.img && (
                <cac.CheckFoot
                    nextPage={nextPage}
                    qualityCheck={qualityCheck}
                    variation={variation}
                    retake={retake}
                    face={angle === 'top' ? 'environment' : 'user'}
                    nextStage={() =>
                        navigate({ to: `/foot-watch/instructions/${angle}/$side`, params: { side: side } })
                    }
                />
            )} */}

            {/* Title */}
            <div className="z-50 w-full overflow-hidden absolute top-[10vh] justify-center align-center flex">
                <h1 className="z-40 mx-auto text-4xl font-medium text-white">{input.label}</h1>
            </div>

            {timerRunning && (
                <cac.Timer runFunction={capture} initialSeconds={photos.getPhoto(currentPhotoId)?.delay ?? 0} />
            )}

            {/* Taking photo black flash effect */}
            {/* {takingPhoto && <div className={`absolute bg-black top-0 h-full w-full  left-0 z-40`}></div>} */}

            <ErrorBoundary fallback={<p>Something went with webcam or images</p>}>
                {currentPhoto?.img ? (
                    // If Image Show Image
                    <img
                        className={`absolute top-0 h-full w-full left-0 z-[35] object-cover `}
                        src={currentPhoto?.img}
                        alt="Latest Photo"
                    ></img>
                ) : (
                    <>
                        <Webcam
                            className={`absolute top-0 h-full w-full left-0 z-[35]  object-cover`}
                            audio={false}
                            mirrored={facingMode === 'user'}
                            ref={webcamRef}
                            // screenshotQuality={photos.getPhoto(photoId)?.facingMode === 'user' ? 0.92 : 0.76}
                            screenshotFormat="image/webp"
                            // videoConstraints={{
                            //     width: isMobile ? 900 : 1800,
                            //     facingMode: photos.getPhoto(photoId)?.facingMode,
                            // }}
                            forceScreenshotSourceSize={false}
                        ></Webcam>

                        {overlay ?? <></>}
                    </>
                )}
            </ErrorBoundary>

            {/* Black Drop incase nothing above is rendered  */}
            <div className="absolute top-0 left-0 w-[100vw] h-[100vh] bg-black z-30"></div>

            <ErrorBoundary fallback={<p>Something went wrong with camera settings</p>}>
                {/* Camera Settings */}
                <div className="fixed bottom-[100px] flex items-center justify-center mx-auto px-4 z-50 left-0 w-full ">
                    <div className="w-full max-w-[400px] mx-auto gap-x-4 flex h-20 items-center justify-center">
                        <cac.SelectCamera />
                        <cac.SelectTimer />
                    </div>
                </div>
            </ErrorBoundary>

            <cac.CameraButtons
                delay={delay ?? 0}
                stopTimer={stopTimer}
                retake={retake}
                image={currentPhoto?.img ?? ''}
                func1={camera.toggleCamera}
                words1="Next"
                timerRunning={timerRunning}
            />
        </section>
    );
};

export default Camera;
