import { ui } from '@/components/ui';
import { timeUnits } from '@/types/zod/timings';
import type { ManipulateType } from 'dayjs';
import { Reorder } from 'framer-motion';
import { GripVertical, Infinity, Trash, Wand2 } from 'lucide-react';

import { timeComponents as tc } from '.';
import { useScheduleStore } from '../hooks/useTimingStore';

// import useSchedule from '../hooks/useSchedule';
const TimingTable = () => {
	const { times, setTimes, updateTimes, deleteTime, addNewTime } = useScheduleStore();

	return (
		<ui.Card className="flex flex-col w-full h-full md:min-h-[50vh]">
			<ui.CardHeader>
				<ui.CardTitle>Time Series</ui.CardTitle>
			</ui.CardHeader>
			<ui.CardContent className="flex gap-x-2 px-2 w-full">
				<ui.ScrollArea className="pr-2 pb-2 w-full max-h-[450px]">
					<div className="w-full">
						<div className="flex items-center gap-x-1 md:gap-x-2 w-full font-medium text-slate-600 text-sm">
							<div className="flex pb-1 w-4 text-left"></div>

							<div className="w-6">
								<tc.TimeTooltip label={<Wand2 size={20} />} hint="Auto Generate Label" />
							</div>
							<div className="pb-1 w-3/12 text-ellipsis text-left overflow-clip">
								<tc.TimeTooltip label="Label" hint="Type of time unit used for the interval (e.g., week, hour, day)." />
							</div>
							<div className="pb-1 w-2/12 text-ellipsis text-left overflow-clip">
								<tc.TimeTooltip label="Interval" hint="Number of time units between each occurrence of the event." />
							</div>
							<div className="pb-1 w-3/12 text-ellipsis text-left overflow-hidden">
								<tc.TimeTooltip label="Unit" hint="Type of time unit used for the interval." />
							</div>
							<div className="w-6">
								<tc.TimeTooltip label={<Infinity />} hint="Auto Generate Label" />
							</div>
							<div className="pb-1 w-2/12 text-ellipsis text-left overflow-clip">
								<tc.TimeTooltip label="Repeats" hint="Number of times the event repeats at the set interval." />
							</div>

							<div className="w-6 text-white">_</div>
						</div>
						<Reorder.Group className="flex flex-col gap-y-2" axis="y" values={times} onReorder={setTimes}>
							{times.map((time) => {
								return (
									<Reorder.Item className="pb-2" key={time.timing_id} value={time}>
										<div className="flex [&_div]:flex [&_div]:justify-center items-center [&_div]:items-center gap-x-1 md:gap-x-2">
											<div className="flex justify-center items-center w-4 cursor-move">
												<tc.TimeTooltip label={<GripVertical />} hint="Drag to Reorder" />
											</div>

											<div className="flex justify-center items-center w-6 cursor-move">
												<ui.CheckboxSlate
													className="w-5 h-5"
													checked={time.is_autoname}
													onCheckedChange={() => {
														updateTimes({
															...time,
															is_autoname: !time.is_autoname,
														});
													}}
												/>
											</div>
											<div className="w-3/12">
												<ui.Input
													className="font-medium text-slate-600"
													type="text"
													value={time.label}
													disabled={time.is_autoname}
													onChange={(e) =>
														updateTimes({
															...time,
															label: e.target.value,
														})
													}
												></ui.Input>
											</div>
											<div className="w-2/12">
												<ui.Input
													value={time.interval}
													min="-99"
													max="99"
													type="number"
													onChange={(e) =>
														updateTimes({
															...time,
															interval: Number(e.target.value),
														})
													}
												></ui.Input>
											</div>

											<div className="w-3/12">
												<ui.Select
													onValueChange={(unit) =>
														updateTimes({
															...time,
															time_unit: unit as ManipulateType,
														})
													}
													value={time.time_unit}
												>
													<ui.SelectTrigger className="capitalize">
														<ui.SelectValue placeholder="Select" />
													</ui.SelectTrigger>
													<ui.SelectContent>
														{Object.keys(timeUnits).map((name) => (
															<ui.SelectItem className="capitalize" key={name} value={name}>
																{name}
															</ui.SelectItem>
														))}
													</ui.SelectContent>
												</ui.Select>
											</div>
											<div className="flex justify-center items-center w-6 cursor-move">
												<ui.CheckboxSlate
													checked={time.is_infinite}
													onCheckedChange={() =>
														updateTimes({
															...time,
															is_infinite: !time.is_infinite,
														})
													}
													className="w-5 h-5"
												/>
											</div>
											<div className="w-2/12">
												<ui.Input
													min="0"
													max="99"
													disabled={time.is_infinite}
													value={time.is_infinite ? 'Infinite' : time.repeats}
													type={time.is_infinite ? 'text' : 'number'}
													onChange={(e) =>
														updateTimes({
															...time,
															repeats: Number(e.target.value),
														})
													}
												></ui.Input>
											</div>

											<div className="p-0 w-0 w-6">
												<ui.Button
													onClick={() => deleteTime(time)}
													className="flex justify-center items-center text-slate-800"
													size="icon"
													variant="destructive-link"
												>
													<Trash />
												</ui.Button>
											</div>
										</div>
									</Reorder.Item>
								);
							})}
						</Reorder.Group>
					</div>
				</ui.ScrollArea>
			</ui.CardContent>
			<ui.CardFooter className="mt-auto">
				<ui.Button className="ml-auto" type="button" size="lg" variant="link" onClick={() => addNewTime()}>
					Add Time
				</ui.Button>
			</ui.CardFooter>
		</ui.Card>
	);
};

export default TimingTable;
