import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { z } from 'zod';

export const RoleZ = z
	.enum(['PATIENT', 'CLINICIAN', 'ADMIN', 'SECRETARY', ''])
	.default('PATIENT')
	.transform((val) => val.toLowerCase());
export type RoleT = z.infer<typeof RoleZ>;

export const UuidZ = z.string().uuid();
export type UuidT = z.infer<typeof UuidZ>;

export const EmailZ = z.string().email().min(1, 'Email is required');
export type EmailT = z.infer<typeof EmailZ>;

export const PasswordZ = z.string().min(1, 'Password is required').min(8, 'Password must have more than 8 characters');
export type PasswordT = z.infer<typeof PasswordZ>;

export const AuthStatusZ = z.enum(['loggedIn', 'loggedOut']);
export type AuthStatusT = z.infer<typeof AuthStatusZ>;

export const LoginSchemaZ = z.object({
	email: EmailZ,
	password: PasswordZ,
});

export type LoginSchemaT = z.infer<typeof LoginSchemaZ>;

export const PhoneZ = z.string().refine((value) => isPossiblePhoneNumber(value), {
	message: 'Invalid phone number',
});

export type PhoneT = z.infer<typeof PhoneZ>;

export const TokenZ = z.string().refine((value) => /^[0-9]{6}$/.test(value), {
	message: 'Invalid verification code. Code should be exactly 6 digits.',
});

export type TokenT = z.infer<typeof TokenZ>;

export const TitleZ = z.enum(['Mr', 'Mrs', 'Ms', 'Miss', 'Dr', 'Professor']);
export type TitleT = z.infer<typeof TitleZ>;

export const FirstNameZ = z.string().min(1, 'First Name is required');
export type FirstNameT = z.infer<typeof FirstNameZ>;

export const LastNameZ = z.string().min(1, 'Last Name is required');
export type LastNameT = z.infer<typeof LastNameZ>;
