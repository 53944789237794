import React from 'react';

const Rewind = () => {
    return (
        <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.8529 9.02206C21.2289 9.19987 22.1927 10.4044 22.0057 11.7126L21.244 17.0397C25.2916 14.6478 29.9935 13.3728 34.8171 13.4051L35.8072 13.4117C49.7494 13.5051 61 24.2766 61 37.5317V38.8794C61 52.2008 49.6408 63 35.6286 63H34.3714C20.3592 63 9 52.2008 9 38.8794V37.2496C9 35.9295 10.1257 34.8593 11.5143 34.8593C12.9029 34.8593 14.0286 35.9295 14.0286 37.2496V38.8794C14.0286 49.5606 23.1364 58.2193 34.3714 58.2193H35.6286C46.8636 58.2193 55.9714 49.5606 55.9714 38.8794V37.5317C55.9714 26.9037 46.9507 18.2671 35.7718 18.1923L34.7817 18.1857C31.129 18.1612 27.5633 19.0717 24.4445 20.7886L29.8168 21.2893C31.1987 21.4181 32.2092 22.5876 32.0737 23.9014C31.9382 25.2153 30.7081 26.1759 29.3261 26.0471L17.669 24.9606C16.9891 24.8973 16.3656 24.5736 15.942 24.0642C15.5183 23.5547 15.3309 22.9033 15.4229 22.2598L17.0229 11.0687C17.2099 9.76056 18.477 8.84426 19.8529 9.02206Z"
                fill="black"
            />
        </svg>
    );
};

export default Rewind;
