export const convertFileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();

    fileReader.onload = () => {
      if (typeof fileReader.result === "string") {
        resolve(fileReader.result);
      } else {
        reject(new Error("Failed to convert file to Base64"));
      }
    };

    fileReader.onerror = () => {
      reject(new Error("Failed to read file"));
    };

    fileReader.readAsDataURL(file);
  });
};
