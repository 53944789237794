import { components as c } from '@/components';
import useCreateInjectionDiaryMutation from '@/features/injection/api/createInjectionDiary';
import { useInjectionDiaryDefaultsQuery } from '@/features/injection/api/selectInjectionDiaryDefaults';
import { InjectionDetailsZ } from '@/features/injection/components/InjectionDetailsForm';
import NewInjectionStepper from '@/features/injection/components/NewInjectionStepper';
import { InjectionSchemaZ } from '@/features/injection/types/injectionSchema';
import { Route as NewDiaryRoute } from '@/routes/_app/new-diary/index';
import { Route as InjectionDetailsRoute } from '@/routes/_app/new-diary/patient/$patientId/organisation/$organisationId/diary/guided/injection-diary/details';
import { Route as InjectionRoute } from '@/routes/_app/new-diary/patient/$patientId/organisation/$organisationId/diary/guided/injection-diary/injection';
import { Route as InjectionTimeRoute } from '@/routes/_app/new-diary/patient/$patientId/organisation/$organisationId/diary/guided/injection-diary/time';
import { services as s } from '@/services';
import { useAuth } from '@/utils/auth';
import { useForm, z, zodResolver } from '@/utils/form';
import { Link, Outlet, createFileRoute, useNavigate } from '@tanstack/react-router';
import { AlertCircle } from 'lucide-react';
import React from 'react';
import { toast } from 'sonner';
import { validate } from 'uuid';

// Zod schemas for each page
const TimePageSchemaZ = z.object({
	created_by: z.string().uuid('User is required'),
	organisation_id: z.string().uuid('Organisation is required'),
	patient_id: z.string().uuid('Patient is required'),
	diary_id: z.string().uuid('Diary is required'),
	form_id: z.string().uuid('Form is required'),
	tracker_id: z.string().uuid('Tracker is required'),
	contact_id: z.string().optional(),
	injection_time: z.date().optional(),
	hospital_number: z.string().optional(),
});

const CreateInjectionDiarySchemaZ = TimePageSchemaZ.merge(InjectionSchemaZ).merge(InjectionDetailsZ);

export type CreateInjectionDiarySchemaT = z.infer<typeof CreateInjectionDiarySchemaZ>;

const pages = [InjectionTimeRoute, InjectionRoute, InjectionDetailsRoute] as z.ZodObject<any>[];
const schemas = [TimePageSchemaZ, InjectionSchemaZ, InjectionDetailsZ] as z.ZodObject<any>[];

const NewInjectionDiaryPage = () => {
	const { organisationId, patientId } = Route.useParams();

	const validateOrganisationIdAndPatientId = (patientId: string, organisationId: string) => {
		try {
			return validate(patientId) && validate(organisationId);
		} catch (e) {
			return false;
		}
	};

	const eventId = s.uuidv4();
	const trackerId = s.uuidv4();

	const auth = useAuth();
	const [page, setPage] = React.useState(0);
	const { data: defaultDiary } = useInjectionDiaryDefaultsQuery(auth.id);
	const createDiary = useCreateInjectionDiaryMutation();

	const findPageNumber = () => {
		const location = window.location.pathname;
		return pages.findIndex((p) => p.to.split('/').pop() === location.split('/').pop());
	};

	React.useEffect(() => {
		setPage(findPageNumber());
	}, [findPageNumber]);

	const navigate = useNavigate();

	const form = useForm<CreateInjectionDiarySchemaT>({
		resolver: zodResolver(CreateInjectionDiarySchemaZ),
		defaultValues: {
			created_by: auth.id,
			organisation_id: organisationId,
			patient_id: patientId,
			diary_id: s.uuidv4(),
			tracker_id: trackerId,
			event_id: eventId,
			injection_id: s.uuidv4(),
			hospital_number: '',
			injection_details: [
				{
					// FIXME I have allocated a random injection_id which is then not used in the supabase db function just to please zod
					injection_id: s.uuidv4(),
					injection_detail_id: s.uuidv4(),
				},
			],
		},
	});

	React.useEffect(() => {
		form.setValue('form_id', defaultDiary?.form_id ?? '');
		form.setValue('referring_clinician_id', defaultDiary?.referring_clinician_id ?? '');
		form.setValue('injecting_clinician_id', defaultDiary?.injecting_clinician_id ?? undefined);
		form.setValue('injection_method_id', defaultDiary?.injection_method_id ?? undefined);
		form.setValue('contact_id', defaultDiary?.contact_id ?? '');
	}, [defaultDiary, form.setValue]);

	const { trigger } = form;

	const nextPage = async () => {
		let schema: z.ZodObject<any>;
		const isLastPage = pages.length === page + 1;
		if (isLastPage) {
			schema = CreateInjectionDiarySchemaZ;
		} else {
			schema = page + 1 === pages.length ? CreateInjectionDiarySchemaZ : schemas[page];
		}

		if (!schema) {
			console.error('Schema not found for page:', page);
			return;
		}
		const isValid = await trigger(Object.keys(schema.shape));

		if (!isValid) {
			console.error(form.formState.errors);
			toast.error('Please correct the form.');
			return;
		}

		if (isLastPage) {
			// setDialogOpen(true);
			createDiary.mutate(form.getValues());
		} else {
			navigateNextPage();
		}
	};

	const navigateNextPage = () => {
		const nextPage = pages[page + 1];
		navigate({ to: nextPage?.to, params: { patientId, organisationId } });
	};

	const navigatePrevPage = () => {
		const prevPage = pages[page - 1];
		navigate({ to: prevPage?.to, params: { patientId, organisationId } });
	};

	return (
		// Add padding to the bottom of the page for the long non scrollable selects
		// FIXME - Make selects scrollable
		<div className="mb-24">
			<c.cc.Title words="New Injection Diary" />

			{validateOrganisationIdAndPatientId(patientId, organisationId) === false && (
				<c.cc.Call
					icon={<AlertCircle />}
					variant="warning"
					words={
						<p className="text-md">
							Missing details please{' '}
							<Link className="link" to={NewDiaryRoute.to}>
								select a patient
							</Link>
							.
						</p>
					}
				/>
			)}
			{/* <OrderScanDialog
				setOpen={setDialogOpen}
				onSubmit={() => createDiary.mutate(form.getValues())}
				submitIsSuccess={createDiary.isSuccess}
				open={dialogOpen}
				eventId={eventId}
				trackerId={trackerId}
				patientId={patientId}
			/> */}
			<NewInjectionStepper page={page} />
			<c.ui.Form {...form}>
				<form className="form">
					<Outlet />
					<c.cc.FooterButtons
						words1="Next"
						words2="Back"
						type1="button"
						func1={() => nextPage()}
						func2={() => navigatePrevPage()}
						type2="button"
						disabled2={page === 0}
					/>
				</form>
			</c.ui.Form>
		</div>
	);
};

export const Route = createFileRoute(
	'/_app/new-diary/patient/$patientId/organisation/$organisationId/diary/guided/injection-diary',
)({
	component: NewInjectionDiaryPage,
});
