import { createClient } from '@supabase/supabase-js';
import type { SupabaseClient } from '@supabase/supabase-js';

import { supabaseClient } from '../lib/supabase';

// https://makerkit.dev/blog/saas/supabase-react-query
// import { useMemo } from 'react';
import type { Database } from '../types/database/database';
// import { supabaseClient } from './supabaseClient';
const supabaseUrl: string = import.meta.env.VITESUPABASE_URL!;
const supabaseKey: string = import.meta.env.VITE_SUPABASE_KEY!;

export function getSupabaseBrowserClient() {
	return createClient<Database>(supabaseUrl, supabaseKey);
}

function useSupabase(): SupabaseClient {
	return supabaseClient;
	// return useMemo(getSupabaseBrowserClient, []);
}

export default useSupabase;
