import { v4 as uuidv4 } from 'uuid';

import { capitaliseSentence } from './capitaliseSentence';
import { ciEquals } from './ciEquals';
import { convertFileToBase64 } from './converFileToBase64';
import { fuzzySearch } from './fuzzySearch';
import { getMostRecent } from './getMostRecent';
import { getRandomFromList } from './getRandomFromList';
import { hasCommonElements } from './hasCommonElements';
import { isSmall } from './isSmall';
import { scroll } from './scroll';
import { time } from './time';

export const services = {
	ciEquals,
	capitaliseSentence,
	fuzzySearch,
	getMostRecent,
	isSmall,
	convertFileToBase64,
	time,
	getRandomFromList,
	uuidv4,
	hasCommonElements,
	scroll,
};
