import { AlarmCheck, Microscope, TrafficCone, Unplug, Zap } from 'lucide-react';

export const benefitsContent = [
    {
        // icon: <si.AmberFlag />,
        icon: <AlarmCheck />,
        title: 'Time-saving',
        description: 'Time-saving algorithm ensures the doctor is only alerted when action may be required.',
    },
    {
        // icon: <si.MIA />,
        icon: <TrafficCone />,
        title: 'Safety Net',
        description: 'Identifying diaries not completed as expected acts as a safety net for vulnerable patients.',
    },
    {
        // icon: <si.Invited />,
        icon: <Zap />,
        title: 'Pre-written Templates',
        description:
            'Editable email templates enable doctors to quickly contact patients at the time of clinical need.',
    },
    {
        // icon: <icons.Clipboard />,
        icon: <Unplug />,
        title: 'Seamless Integration',
        description: 'A PDF report is generated on diary completion for seamless integration with patient records.',
    },
    {
        // icon: <icons.Database />,
        icon: <Microscope />,
        title: 'Research and Audit',
        description:
            'Results from every diary add to our anonymized dataset on pain injections - for research and audit.',
    },
];
