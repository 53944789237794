import { ui } from '@/components/ui';

import { inputComponents as ic } from '.';

const InputSettingsSheet = () => {
    return (
        <ui.Sheet modal={false}>
            <ui.Button className="md:flex hidden text-md" size="sm" variant="secondary" asChild>
                <ui.SheetTrigger>Edit</ui.SheetTrigger>
            </ui.Button>
            <ui.SheetContent>
                <ui.SheetHeader>
                    <ui.SheetTitle>Are you absolutely sure?</ui.SheetTitle>
                </ui.SheetHeader>
                <ic.InputSettings />
                <ui.DrawerClose className="flex mt-4 ml-auto">
                    <ui.Button size="lg" className="ml-auto" variant="outline">
                        Close
                    </ui.Button>
                </ui.DrawerClose>
            </ui.SheetContent>
        </ui.Sheet>
    );
};

export default InputSettingsSheet;
