import { ui } from "@/components/ui";
import { Copy } from "@/components/common/Copy";

const CopyCell = ({ id, value, className }: { id: string; value: string, className? : string }) => {
    return (
        <ui.TableCell className={className}>
            <Copy id={`copy-${id}`} value={value}>{value}</Copy>
        </ui.TableCell>
    );
};

export default CopyCell;
