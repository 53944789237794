import { formComponents as fc } from '@/components/form';

import { useFormContext } from 'react-hook-form';
import { useSelectProfiles } from '../../profile/api/selectProfiles';

const UsersCombobox = () => {
	const form = useFormContext();
	const { data: profiles } = useSelectProfiles();
	return (
		<fc.UserCombobox
			placeholder="Select a contact person"
			disabled={false}
			attribute="user_id"
			options={
				profiles?.map((p) => {
					return {
						label: p.name,
						value: p.user_id as string,
					};
				}) || []
			}
			label="User"
			form={form}
		/>
	);
};

export default UsersCombobox;
