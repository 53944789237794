import InviteForm from '@/features/invite/components/InviteForm';
import { useNav } from '@/utils/nav';
import { createFileRoute } from '@tanstack/react-router';

const InvitePage = () => {
	useNav([{ route: { to: '/invite' }, label: 'Invite' }]);
	return (
		<div className="md-width">
			<InviteForm />
		</div>
	);
};

export const Route = createFileRoute('/_app/invite/')({
	component: InvitePage,
});
