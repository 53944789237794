import { useFormContext } from 'react-hook-form';

import { capitaliseSentence } from '../../services/capitaliseSentence';
import { InputT, TimeKeyT } from '../../types/database/forms';
import { timeUnits } from '../../types/zod/timings';
import { formComponents as fc } from '../form';
import { ui } from '../ui';

interface TimeValue {
	interval: number;
	timeUnit: TimeKeyT;
}

interface Props {
	form: any;
	input: InputT;
}

const Duration = ({ form, input }: Props) => {
	const { control } = useFormContext();

	// const {
	// 	field: { value, onChange },
	// } = useController<TimeValue>({
	// 	name: input.input_id,
	// 	control: form.control,
	// });

	// const handleIntervalChange = (newInterval: number) => {
	// 	onChange({ interval: newInterval, timeUnit: value?.timeUnit ?? 'day' });
	// };

	// const handleTimeUnitChange = (newTimeUnit: TimeKeyT) => {
	// 	onChange({ interval: value?.interval ?? 0, timeUnit: newTimeUnit });
	// };

	return (
		<>
			<ui.Label>{input.input_label}</ui.Label>
			<div className="flex items-start justify-center gap-2">
				<fc.Number name={`${input.input_id}.interval`} />
				<fc.Select
					name={`${input.input_id}.timeUnit`}
					options={Object.keys(timeUnits).map((t) => {
						return { value: t, label: capitaliseSentence(t) };
					})}
				/>
			</div>
			{input.input_description && <p className="text-sm font-normal text-slate-700">{input.input_description}</p>}
		</>
	);
};

export default Duration;
