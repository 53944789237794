interface Props {
    stroke?: string;
    className?: string;
}
const Dryness = ({ stroke, className }: Props) => {
    return (
        <svg
            className={className}
            width="39"
            height="48"
            viewBox="0 0 39 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M20.1703 40.8C20.6578 40.76 21.0746 40.5704 21.4207 40.2312C21.7652 39.8904 21.9375 39.48 21.9375 39C21.9375 38.44 21.7547 37.9904 21.3891 37.6512C21.0234 37.3104 20.5562 37.16 19.9875 37.2C18.3219 37.32 16.5547 36.8704 14.6859 35.8512C12.8172 34.8304 11.6391 32.98 11.1516 30.3C11.0703 29.86 10.8566 29.5 10.5105 29.22C10.166 28.94 9.77031 28.8 9.32344 28.8C8.75469 28.8 8.2875 29.0096 7.92187 29.4288C7.55625 29.8496 7.43437 30.34 7.55625 30.9C8.24687 34.54 9.87188 37.14 12.4313 38.7C14.9906 40.26 17.5703 40.96 20.1703 40.8ZM19.5 48C13.9344 48 9.29338 46.12 5.577 42.36C1.859 38.6 0 33.92 0 28.32C0 24.32 1.61525 19.9704 4.84575 15.2712C7.91456 10.8034 12.4791 5.98374 18.5395 0.812121C19.0926 0.340137 19.9074 0.340137 20.4605 0.812117C26.5209 5.98374 31.0863 10.8034 34.1567 15.2712C37.3856 19.9704 39 24.32 39 28.32C39 33.92 37.141 38.6 33.423 42.36C29.7066 46.12 25.0656 48 19.5 48ZM19.5 43.2C23.725 43.2 27.2187 41.7904 29.9812 38.9712C32.7437 36.1504 34.125 32.6 34.125 28.32C34.125 25.4 32.8965 22.1 30.4395 18.42C28.0954 14.9115 24.6716 11.0938 20.168 6.96718C19.7901 6.62099 19.2099 6.62099 18.832 6.96718C14.3285 11.0938 10.9055 14.9115 8.56294 18.42C6.10431 22.1 4.875 25.4 4.875 28.32C4.875 32.6 6.25625 36.1504 9.01875 38.9712C11.7812 41.7904 15.275 43.2 19.5 43.2Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Dryness;
