import React from 'react';

const Doctor = () => {
    return (
        <svg width="691" height="708" viewBox="0 0 691 708" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M577.591 633.279C637.47 604.249 663 569.512 663 540.033C663 510.555 637.47 475.818 577.591 446.788C519.642 418.694 437.62 400.533 345.5 400.533C253.38 400.533 171.358 418.694 113.409 446.788C53.5301 475.818 28 510.555 28 540.033C28 569.512 53.5301 604.249 113.409 633.279C171.358 661.372 253.38 679.533 345.5 679.533C437.62 679.533 519.642 661.372 577.591 633.279ZM345.5 707.533C536.314 707.533 691 632.541 691 540.033C691 447.526 536.314 372.533 345.5 372.533C154.686 372.533 0 447.526 0 540.033C0 632.541 154.686 707.533 345.5 707.533Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M345.501 294.467C422.742 294.467 483.853 233.998 483.853 161.233C483.853 88.469 422.742 28 345.501 28C268.26 28 207.149 88.469 207.149 161.233C207.149 233.998 268.26 294.467 345.501 294.467ZM345.501 322.467C437.375 322.467 511.853 250.28 511.853 161.233C511.853 72.1866 437.375 0 345.501 0C253.628 0 179.149 72.1866 179.149 161.233C179.149 250.28 253.628 322.467 345.501 322.467Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M184 395.533C190.075 395.533 195 400.458 195 406.533C195 443.924 199.007 473.695 212.616 498.191C215.566 503.502 213.653 510.199 208.342 513.149C203.031 516.1 196.335 514.186 193.384 508.876C176.993 479.372 173 444.976 173 406.533C173 400.458 177.925 395.533 184 395.533Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M216 513.533C205.261 513.533 196 522.624 196 534.533C196 546.443 205.261 555.533 216 555.533C226.739 555.533 236 546.443 236 534.533C236 522.624 226.739 513.533 216 513.533ZM176 534.533C176 512.201 193.602 493.533 216 493.533C238.398 493.533 256 512.201 256 534.533C256 556.866 238.398 575.533 216 575.533C193.602 575.533 176 556.866 176 534.533Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M512.593 393.556L510.285 405.841C508.359 416.091 503.43 433.344 499.65 443.064L495.119 454.714L471.819 445.653L476.35 434.003C479.57 425.722 484.074 409.957 485.715 401.225L488.023 388.94L512.593 393.556Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M473.367 441.897C474.978 436.039 481.032 432.596 486.89 434.206C509.999 440.56 521.36 451.285 528.138 463.203C538.859 482.055 543.538 505.992 543.841 520.647C544.111 533.701 542.893 549.402 540.585 563.939C538.315 578.232 534.818 592.528 530.036 602.193C521.023 620.406 508.098 631.569 495.271 634.932C484.565 637.74 471 637.548 459.302 634.332C453.445 632.721 450.001 626.667 451.612 620.809C453.223 614.952 459.277 611.509 465.135 613.119C473.4 615.391 483.017 615.402 489.69 613.652C494.627 612.357 503.224 606.772 510.318 592.436C513.589 585.825 516.68 574.198 518.857 560.489C520.995 547.023 522.084 532.642 521.846 521.102C521.603 509.364 517.592 489.162 509.014 474.079C505.339 467.616 498.982 460.347 481.057 455.419C475.2 453.809 471.756 447.754 473.367 441.897Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M493.507 447.434C495.117 441.576 491.674 435.522 485.816 433.911C462.701 427.556 447.41 430.995 435.542 437.744C416.69 448.465 400.431 466.646 392.678 479.086C385.773 490.167 378.792 504.284 373.344 517.957C367.988 531.402 363.684 545.476 362.854 556.227C361.288 576.488 366.69 592.689 375.994 602.138C383.76 610.025 395.516 616.794 407.214 620.01C413.072 621.621 419.126 618.178 420.736 612.32C422.347 606.462 418.904 600.408 413.046 598.798C404.781 596.525 396.51 591.617 391.67 586.702C388.088 583.065 383.556 573.87 384.788 557.922C385.357 550.568 388.644 538.995 393.782 526.1C398.829 513.434 405.245 500.518 411.349 490.722C417.559 480.758 431.334 465.446 446.418 456.868C452.84 453.216 462.066 450.198 479.984 455.124C485.842 456.735 491.896 453.292 493.507 447.434Z"
                fill="black"
            />
        </svg>
    );
};

export default Doctor;
