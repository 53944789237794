import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';

import HeroTitle from './HeroTitle';

import type React from 'react';

interface Props {
	title: string | React.ReactNode;
	caption?: React.ReactNode;
	description?: React.ReactNode;
	image?: string;
	imageAlt?: string;
	primaryText?: string;
	primaryOnClick?: () => void;
	secondaryText?: string;
	secondaryOnClick?: () => void;
	primaryButton?: React.ReactNode;
	secondaryButton?: React.ReactNode;
	hideSecondary?: boolean;
	disclaimer?: React.ReactNode;
}
const Hero = ({
	title,
	caption,
	description,
	image,
	imageAlt,
	primaryText,
	secondaryText,
	primaryOnClick,
	secondaryOnClick,
	secondaryButton,
	disclaimer,
	hideSecondary = false, // set default value to true
}: Props) => {
	return (
		<>
			<div className={cn('w-full flex flex-col items-center text-center gap-y-8 lg:px-48')}>
				{caption && <b className="font-medium text-cyan-600 text-xl">{caption}</b>}
				<HeroTitle>{title}</HeroTitle>
				{description && <p className="mt-2 text-lg text-slate-600 dark:text-slate-300">{description}</p>}

				{primaryText && primaryOnClick && (
					<div className="flex flex-wrap justify-center items-center gap-4 mx-auto mt-2">
						<Button data-testid="landing-primary-button" className="min-w-[225px]" size="lg" onClick={primaryOnClick}>
							{primaryText}
						</Button>
						{secondaryButton && secondaryButton}
						{!hideSecondary && secondaryText && (
							<Button
								data-testid="landing-secondary-button"
								className="min-w-[225px]"
								size="lg"
								onClick={secondaryOnClick}
								variant="secondary"
							>
								{secondaryText}
							</Button>
						)}
					</div>
				)}
				{disclaimer && disclaimer}
			</div>
			{image && (
				<img className="mx-auto mt-8 px-0 md:px-12 w-full max-w-[900px] max-h-[600px]" src={image} alt={imageAlt} />
			)}
		</>
	);
};

export default Hero;
