import { commonComponents as cc } from '@/components/common';
import useHandleNewEntry from '@/features/entries/hooks/useHandleNewEntry';
import { selectFormByIdQueryOptions } from '@/features/forms/api/selectFormById';
import { createFileRoute } from '@tanstack/react-router';

const Page = () => {
	const { formId, entryId, pageIndex } = Route.useParams();
	const { form } = Route.useLoaderData();
	const { handleNewEntry } = useHandleNewEntry(formId);

	return (
		<section className="md-width">
			<cc.Title words={form.form_name} description={form.form_description}></cc.Title>
		</section>
	);
};

export const Route = createFileRoute('/tracker/$trackerId/form/$formId/entry/$entryId/page/')({
	component: Page,
	loader: async ({ context, params }) => {
		const form = await context?.queryClient.ensureQueryData(selectFormByIdQueryOptions(params.formId));
		return { form };
	},
});
