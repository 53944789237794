import { createFileRoute } from '@tanstack/react-router';
import { Outlet } from '@tanstack/react-router';

const Edit = () => {
    return (
        <section className="max-w-md mx-auto my-8">
            <Outlet />
        </section>
    );
};

export const Route = createFileRoute('/_app/foot-watch/$diaryId/edit')({
    component: Edit,
});
