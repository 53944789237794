interface Props {
    fill: string;
    className: string;
    side: 'left' | 'right';
    angle: 'top' | 'sole';
}

const Foot = ({ fill, side, angle, className }: Props) => {
    return (
        <div>
            <svg
                className={`${side === 'right' && 'reflect'} ${className}`}
                width="28"
                height="61"
                viewBox="0 0 28 61"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M20.75 6C20.75 7.52944 21.1646 8.88016 21.7956 9.82662C22.4301 10.7783 23.2242 11.25 24 11.25C24.7758 11.25 25.5699 10.7783 26.2044 9.82662C26.8354 8.88016 27.25 7.52944 27.25 6C27.25 4.47056 26.8354 3.11984 26.2044 2.17338C25.5699 1.22169 24.7758 0.75 24 0.75C23.2242 0.75 22.4301 1.22169 21.7956 2.17338C21.1646 3.11984 20.75 4.47056 20.75 6Z"
                    fill={angle === 'top' ? fill : ''}
                    stroke={fill}
                    strokeWidth="1.5"
                />
                <path
                    d="M14.75 4C14.75 5.37649 15.6557 6.25 16.5 6.25C17.3443 6.25 18.25 5.37649 18.25 4C18.25 2.62351 17.3443 1.75 16.5 1.75C15.6557 1.75 14.75 2.62351 14.75 4Z"
                    fill={angle === 'top' ? fill : ''}
                    stroke={fill}
                    strokeWidth="1.5"
                />
                <path
                    d="M8.75 6C8.75 7.37649 9.65573 8.25 10.5 8.25C11.3443 8.25 12.25 7.37649 12.25 6C12.25 4.62351 11.3443 3.75 10.5 3.75C9.65573 3.75 8.75 4.62351 8.75 6Z"
                    fill={angle === 'top' ? fill : ''}
                    stroke={fill}
                    strokeWidth="1.5"
                />
                <path
                    d="M3.75 9.5C3.75 10.6313 4.45717 11.25 5 11.25C5.54283 11.25 6.25 10.6313 6.25 9.5C6.25 8.36868 5.54283 7.75 5 7.75C4.45717 7.75 3.75 8.36868 3.75 9.5Z"
                    fill={angle === 'top' ? fill : ''}
                    stroke={fill}
                    strokeWidth="1.5"
                />
                <circle
                    cx="2"
                    cy="2"
                    r="1.25"
                    transform="matrix(-1 0 0 1 4 13)"
                    fill={angle === 'top' ? fill : ''}
                    stroke={fill}
                    strokeWidth="1.5"
                />
                <path
                    d="M11.6017 12.348C15.11 11.4523 21.2034 12.348 23.7885 14.3186C26.3735 16.2892 26.4453 19.5624 25.2656 22.2009C23.5036 26.142 16.9565 23.9326 13.4482 25.0672C11.2324 25.7837 11.2324 29.3666 11.2324 31.3372C11.2324 40.459 18.3926 46.206 20.2801 50.3263C22.1676 54.4466 21.0187 60 14.9253 60C8.83196 60 10.3091 46.7434 9.38592 41.0108C8.6358 36.3531 2 27.5752 2 23.8132C2 20.7677 4.8944 19.6175 6.98549 17.1849C8.34381 15.6047 8.09338 13.2437 11.6017 12.348Z"
                    fill={angle === 'top' ? fill : ''}
                    stroke={fill}
                    strokeWidth="1.5"
                />
            </svg>
        </div>
    );
};

export default Foot;
