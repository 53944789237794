import * as React from 'react';

import type { UsersOrganisationsRolesViewT } from '../types/database/profiles';
import { type RolesT, roles } from './roles';

export interface Auth {
	isAuthenticated: boolean;
	login: (id: string) => void;
	logout: () => void;
	id: string;
	roles: RolesT;
	setRoles: (roles: UsersOrganisationsRolesViewT[]) => void;
	isSuperUser: boolean;
	setIsSuperUser: (isSuperUser: boolean) => void;
}

const auth = React.createContext<Auth | null>(null);

const key = 'tanstack.auth.id';

function getStoredId() {
	return localStorage.getItem(key);
}

function setStoredId(id: string): string {
	if (id) {
		localStorage.setItem(key, id);
	} else {
		localStorage.removeItem(key);
	}
	return '';
}

export function AuthProvider({ children }: { children: React.ReactNode }) {
	const [id, setId] = React.useState<string>(getStoredId() as string);
	const isAuthenticated = !!id;

	const logout = React.useCallback(() => {
		setStoredId('');
		setId('');
	}, []);

	const login = React.useCallback((id: string) => {
		setStoredId(id);
		setId(id);
	}, []);

	const [isSuperUser, setIsSuperUser] = React.useState<boolean>(false); // Declare the setIsSuperUser variable

	return (
		<auth.Provider
			value={{ isAuthenticated, id, login, logout, roles, setRoles: roles.setRoles, isSuperUser, setIsSuperUser }}
		>
			{children}
		</auth.Provider>
	);
}

export function useAuth() {
	const context = React.useContext(auth);
	if (!context) {
		throw new Error('useAuth must be used within an AuthProvider');
	}
	return context;
}
