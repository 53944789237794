import { NavigationMenu } from '@/components/ui/navigation-menu';
import { cn } from '@/lib/utils';

import AppDesktopNavigation from '../app/DesktopNavigation';
import WebsiteDesktopNavigation from '../website/DesktopNavigation';
import Breadcrumb from './Breadcrumb';
import { Burger } from './Burger';
import MainIcon from './MainIcon';

interface Props {
	app: boolean;
}

const Navigation = (props: Props) => {
	return (
		<nav
			className={cn(
				'item-center top-0 z-30 m-auto my-auto flex !w-full border-y border-indigo-200 bg-indigo-100 bg-opacity-50 px-2 py-2.5 backdrop-blur-xl lg:px-4 dark:bg-slate-900 dark:border-slate-500 dark:bg-opacity-50',
				props.app ? 'sticky ' : 'sticky ',
			)}
		>
			{/* Desktop */}
			<section className="lg:flex hidden w-full">
				<div className="flex justify-between items-center gap-x-6 !w-full">
					<div className="flex items-center">
						<MainIcon />
						<Breadcrumb />
					</div>
					<div className="flex justify-center items-center gap-x-4 my-auto ml-auto text-md">
						<NavigationMenu>{props.app ? <AppDesktopNavigation /> : <WebsiteDesktopNavigation />}</NavigationMenu>
					</div>
				</div>
			</section>

			{/* Mobile */}
			<section className="z-20 lg:hidden !w-full">
				<div className="flex justify-between items-center !w-full">
					<div className="flex items-center">
						<MainIcon />
						<Breadcrumb backOnly={true} />
					</div>

					<Burger app={props.app} />
				</div>
			</section>
		</nav>
	);
};

export default Navigation;
