import { useFormContext } from 'react-hook-form';

import { ui } from '../ui';

interface Props {
	name?: string;
	label?: string;
	tabIndex?: number;
	autoFocus?: boolean;
	disabled?: boolean;
}

const Email: React.FC<Props> = ({ name = 'email', tabIndex, autoFocus, label, disabled = false }) => {
	const { control } = useFormContext();
	return (
		<ui.FormField
			control={control}
			name={name}
			data-cy={name}
			render={({ field }) => (
				<ui.FormItem>
					<ui.FormLabel>{label ?? 'Email'}</ui.FormLabel>
					<ui.FormControl>
						<ui.Input
							disabled={disabled}
							autoFocus={autoFocus}
							tabIndex={tabIndex}
							autoComplete="email"
							type="email"
							placeholder="e.g. name@company.com"
							{...field}
						/>
					</ui.FormControl>
					<ui.FormMessage />
				</ui.FormItem>
			)}
		/>
	);
};

export default Email;
