import { Card, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import Section from '@/features/website/components/Section';
import SectionTitle from '@/features/website/components/SectionTitle';

import { content } from './content/gdprContent';

const Gdpr = () => {
	return (
		<Section>
			<SectionTitle
				title="GDPR Compliant"
				description={<>We know that your data is yours alone. We are GDPR compliant.</>}
			/>
			<div className="flex flex-wrap justify-start items-start w-full">
				{content.map((item) => {
					return (
						<Card
							key={item.title}
							className="flex-auto border-0 bg-transparent shadow-none md:mr-2 mb-2 w-full md:w-[24%] md:min-w-[240px] h-full md:min-h-[280px]"
						>
							<CardHeader>
								<span className="mb-2 rounded-sm w-fit text-cyan-500">{item.icon}</span>

								<CardTitle className="text-lg">{item.title}</CardTitle>
								<CardDescription className="text-md">{item.description}</CardDescription>
							</CardHeader>
						</Card>
					);
				})}
			</div>
		</Section>
	);
};

export default Gdpr;
