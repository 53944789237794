import { queryOptions, useQuery } from '@tanstack/react-query';

import { supabaseClient } from '../../../../lib/supabase';
import { useAuth } from '../../../../utils/auth';
import { flattenOrganisations } from './flattenOrganisations';

export async function getOrganisations(authId: string) {
	return await supabaseClient
		.from('users_organisations_roles')
		.select('*, organisations(*)')
		.eq('user_id', authId)
		.throwOnError()
		.then((result) => flattenOrganisations(result.data));
}

export const organisationQueryOptions = (authId: string) =>
	queryOptions({
		queryKey: ['organisations'],
		queryFn: async () => await getOrganisations(authId),
		retry: 2,
	});

function useOrganisationsQuery() {
	const auth = useAuth();
	const authId = auth.id;
	return useQuery(organisationQueryOptions(authId));
}

export default useOrganisationsQuery;
