import type { InputT } from '@/types/database/forms';
import type { StatefulTrackerViewT } from '@/types/database/trackers';
import type { RouteT } from '@/types/zod/routes';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate, useParams } from '@tanstack/react-router';
import { type SubmitHandler, useForm } from 'react-hook-form';
import type { z } from 'zod';

import { toast } from 'sonner';
import useHandleNewEntry from '../../entries/hooks/useHandleNewEntry';
import { updateEntryValuesArray } from '../../inputs/hooks/updateEntryValues';
import { useEntryValuesStore } from '../../inputs/hooks/useEntryValuesStore';
import { createDefaultValues } from '../utils/createDefaultValues';
import { createValidationSchema } from '../utils/createValidationSchema';
import { formatEntryValues } from '../utils/formatEntryValues';

interface Props {
	route: RouteT;
	pageCount: number;
	inputs: InputT[];
	// scheduleId: string;
	// timingId: string;
	nextEntry: StatefulTrackerViewT['next_entry'];
}

const useFormPage = (props: Props) => {
	// @ts-ignore
	const { entryId, trackerId, diaryId, pageIndex } = useParams({ strict: false });
	const navigate = useNavigate();
	const { handleNewEntry } = useHandleNewEntry(diaryId);

	// Keep track of the entry values across pages in a store
	const updateEntryValuesStore = useEntryValuesStore((state) => state.upsertEntryValues);
	const entryValues = useEntryValuesStore((state) => state.entry_values);

	const nextPage = () =>
		navigate({
			to: props.route,
			params: (prev) => ({ ...prev, pageIndex: Number(pageIndex) + 1 }),
		});
	const prevPage = () =>
		navigate({
			to: props.route,
			params: (prev) => ({ ...prev, pageIndex: Number(pageIndex) - 1 }),
		});

	// Dynamically create the validation schema and default values for the form
	const DiaryPageSchemaZ = createValidationSchema(props.inputs);
	const defaults = createDefaultValues(props.inputs);

	const form = useForm<z.infer<typeof DiaryPageSchemaZ>>({
		resolver: zodResolver(DiaryPageSchemaZ),
		defaultValues: defaults,
	});

	type DiaryPageSchemaT = z.infer<typeof DiaryPageSchemaZ>;

	const onSubmit: SubmitHandler<DiaryPageSchemaT> = async (formData) => {
		const formatted = formatEntryValues(formData, props.inputs, entryId);

		if (Number(pageIndex) < props.pageCount) {
			// If not last page of form, log the entry values in the store and move to the next page
			updateEntryValuesStore(formatted);
			nextPage();
		} else if (Number(pageIndex) === props.pageCount) {
			// If no tracker id - we can't create an entry
			if (!trackerId) {
				navigate({
					to: '/tracker/$trackerId/success',
					params: { trackerId: trackerId },
				});
				toast.info('This form is for example purposes only.');
				return;
			}

			// If last page of form, handle submit and navigate away
			const newEntry = {
				entry_id: entryId,
				schedule_id: props.nextEntry?.schedule_id,
				timing_id: props.nextEntry?.timing_id ?? null,
				tracker_id: trackerId,
				entry_time: props.nextEntry?.due_at,
				timing_label: props.nextEntry?.label ?? undefined,
				time_unit: props.nextEntry?.time_unit ?? undefined,
				timing_interval: props.nextEntry?.interval ? String(props.nextEntry?.interval) : undefined,
				schedule_index: props.nextEntry?.schedule_index ?? undefined,
			};

			if (props.pageCount === 1) {
				handleNewEntry(newEntry, formatted);
			} else {
				const newEntryValues = updateEntryValuesArray(entryValues, formatted);
				handleNewEntry(newEntry, newEntryValues);
			}

			if (diaryId) {
				navigate({
					to: '/diary/$diaryId/home/new',
					params: { diaryId: diaryId },
				});
			} else {
				navigate({
					to: '/tracker/$trackerId/success',
					params: { trackerId: trackerId },
				});
			}
		}
	};

	return { form, onSubmit, prevPage, nextPage };
};

export default useFormPage;
