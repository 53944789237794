import { supabaseClient } from '@/lib/supabase';
import type { DashboardSearchT } from '@/routes/_app/dashboard';
import type { StatefulDiaryT } from '@/types/database/diaries';
import { type Auth, useAuth } from '@/utils/auth';
import { queryOptions, useQuery, useSuspenseQuery } from '@tanstack/react-query';
import { toast } from 'sonner';
import { prepDiariesData } from './utils';

export async function getDiaries(body): Promise<StatefulDiaryT[]> {
	const { data, error } = await supabaseClient.functions.invoke('public-get-diaries', {
		body: body,
	});

	if (error) {
		const errorMessage = `Error fetching diaries. ${error.message}`;
		console.error(errorMessage);
		toast.error(errorMessage);
		return [];
	}

	return data;
}

const allDiariesQueryOptions = () => {
	return queryOptions({
		queryKey: ['all-diaries'],
		queryFn: () => getDiaries({}),
	});
};
export const useAllDiariesQuery = () => {
	return useSuspenseQuery(allDiariesQueryOptions());
};

export const diaryQueryOptions = () => {
	return queryOptions({
		queryKey: ['diaries'],
		queryFn: () => getDiaries({}),
	});
};
export const useDiariesQuery = () => {
	return useSuspenseQuery(diaryQueryOptions());
};

export const createDashboardDiariesQueryKey = (search?: DashboardSearchT) => ['dashboard', 'diaries', { ...search }];

export const dashboardDiaryQueryOptions = (settings: DashboardSearchT, auth: Auth) => {
	const data = prepDiariesData(settings, auth);

	return queryOptions({
		queryKey: createDashboardDiariesQueryKey(settings),
		queryFn: () => getDiaries(data),
	});
};
export const useDashboardDiariesQuery = (settings: DashboardSearchT) => {
	const auth = useAuth();
	return useQuery(dashboardDiaryQueryOptions(settings, auth));
};
