import { commonComponents as cc } from '@/components/common';
import { ui } from '@/components/ui';
import SaveTimingButtons from '@/features/schedules/components/SaveTimingButtons';
import TimingPreview from '@/features/schedules/components/TimingPreview';
import TimingTable from '@/features/schedules/components/TimingTable';
import useFindSchedule from '@/features/schedules/hooks/useFindSchedule';
import { useScheduleStore } from '@/features/schedules/hooks/useTimingStore';
import { processSchedule } from '@/features/schedules/services/processTimeSeries';
import type { EntryT } from '@/types/zod/timings';
import { createFileRoute } from '@tanstack/react-router';
import { BadgeInfo } from 'lucide-react';
import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { processScheduleFromNowQueryOptions } from '../../../../features/schedules/api/processSchedule';
import { selectScheduleById } from '../../../../features/schedules/api/selectSchedulesById';

const NewSchedule = () => {
	const { auth } = Route.useRouteContext();
	const [processedTimes, setProcessedTimes] = React.useState<EntryT[]>([]);
	const times = useScheduleStore((state) => state.times);
	const setTimes = useScheduleStore((state) => state.setTimes);
	const { scheduleId } = Route.useParams();
	const template = useFindSchedule(scheduleId);

	useEffect(() => {
		if (template) {
			setTimes(template.timings);
		}
	}, [template]);

	useEffect(() => {
		setProcessedTimes(processSchedule(times, scheduleId));
	}, [times, scheduleId]);

	return (
		<section className="flex flex-col justify-center items-center mx-auto lg:px-4 lg:py-8 p-2">
			<cc.Title
				words="Time Series Builder"
				description="Schedule the timing of diary entries after a medical intervention or for pre-intervention tracking."
			/>
			<div className="flex justify-between px-2 w-full">
				<ui.Input
					className="flex mr-auto max-w-[450px] h-14 text-lg"
					placeholder="Time Series Name"
					value={template?.schedule_name}
				/>
				<ui.Popover>
					<ui.PopoverTrigger>
						<ui.Button variant="secondary" size="icon">
							<BadgeInfo className="text-indigo-600" size={32} />
						</ui.Button>
					</ui.PopoverTrigger>
					<ui.PopoverContent className="text-md">
						Schedule the timing of diary entries after a medical intervention or for pre-intervention tracking.
					</ui.PopoverContent>
				</ui.Popover>
			</div>

			<div className="flex flex-col justify-center items-start gap-4 p-2 w-full md:max-w-lg lg:max-w-xl">
				<ui.ResizablePanelGroup className="gap-1" direction={isMobile ? 'vertical' : 'horizontal'}>
					<ui.ResizablePanel className="min-h-sm" defaultSize={62}>
						<TimingTable />
					</ui.ResizablePanel>
					<ui.ResizableHandle />
					<ui.ResizablePanel className="min-h-sm" defaultSize={38}>
						<TimingPreview processedTimes={processedTimes} />
					</ui.ResizablePanel>
				</ui.ResizablePanelGroup>
				<SaveTimingButtons
					saveDisabled={template?.author !== auth.id && template}
					timings={times}
					scheduleId={scheduleId}
				/>
			</div>
		</section>
	);
};

export const Route = createFileRoute('/_app/builder/schedule/$scheduleId')({
	component: NewSchedule,
	loader: async ({ context, params }) => {
		const schedule = await selectScheduleById(params.scheduleId);
		const processedSchedule = await context.queryClient.ensureQueryData(processScheduleFromNowQueryOptions(schedule));
		return { processedSchedule };
	},
});
