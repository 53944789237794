import { cn } from '@/lib/utils';
import { cva } from 'class-variance-authority';
import type React from 'react';

interface Props {
	icon: string | React.ReactNode;
	words: string | React.ReactNode;
	className?: string;
	variant?: 'info' | 'destructive' | 'warning' | 'success';
	children?: React.ReactNode;
	size?: 'sm' | 'md' | 'lg';
}

const callVariants = cva(
	'relative w-full flex w-full gap-2 flex-row items-center justify-center rounded-lg gap-2 border shadow-md dark:border-slate-800',
	{
		variants: {
			variant: {
				info: 'bg-indigo-100 border-indigo-300 text-indigo-700 [&_h5]:text-indigo-800 dark:[&_h5]:text-indigo-100  dark:bg-indigo-900 dark:text-indigo-200 dark:border-indigo-700',
				destructive:
					'bg-red-100 border-red-300 text-red-700 [&_h5]:text-red-800 dark:[&_h5]:text-red-100    dark:bg-red-900 dark:text-red-200 dark:border-red-700',
				warning:
					'bg-amber-50 border-amber-500 text-amber-700 [&_h5]:text-amber-800 dark:[&_h5]:text-amber-100    dark:bg-amber-900 dark:text-amber-200 dark:border-amber-700',
				success:
					'bg-green-100 border-green-300 text-green-700  [&_h5]:text-green-800 dark:[&_h5]:text-green-100  dark:bg-green-900 dark:text-green-200 dark:border-green-700',
			},
			size: {
				lg: 'text-lg p-4 ',
				md: 'text-md p-3 ',
				sm: 'text-sm p-2 ',
			},
		},
		defaultVariants: {
			variant: 'info',
			size: 'md',
		},
	},
);
const Call = ({ icon, words, className, children, variant, size }: Props) => {
	return (
		<section className={cn(callVariants({ variant, size }), className)}>
			<span className="m-2">{icon}</span>
			<div className="w-full">
				<h5 className={cn('', size === 'sm' ? 'text-md' : 'text-lg', '')}>{words}</h5>
				<div className={cn('flex flex-col gap-y-2')}>{children}</div>
			</div>
		</section>
	);
};

export default Call;
