import Call from './Call';
import ChevronButton from './ChevronButton';
import DeleteButton from './DeleteButton';
import Description from './Description';
import EditButton from './EditButton';
import EditDialog from './EditDialog';
import EditRow from './EditRow';
import FooterButtons from './FooterButtons';
import Group from './Group';
import IconDetail from './IconDetail';
import Or from './Or';
import PhonePreview from './PhonePreview';
import Spinner from './Spinner';
import Stepper from './Stepper';
import Subtitle from './Subtitle';

import Title from './Title';
export const commonComponents = {
	Or,
	ChevronButton,
	PhonePreview,
	Title,
	Description,
	Group,
	EditRow,
	EditDialog,
	DeleteButton,
	Call,
	FooterButtons,
	EditButton,
	IconDetail,
	Stepper,
	Subtitle,
	Spinner,
};
