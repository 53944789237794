import { commonComponents as cc } from '@/components/common';
import { cn } from '@/lib/utils';
import { services as s } from '@/services';
import type { ExtendedEventViewT } from '@/types/database/events';

import { useAuth } from '../../../utils/auth';
import DeleteEventButton from './DeleteEventButton';
import EventSwitches from './EventSwitches';

interface Props {
	event: ExtendedEventViewT;
}
const EditEvent = ({ event }: Props) => {
	const auth = useAuth();
	if (!event) {
		return <p>No Event</p>;
	}
	return (
		<>
			<cc.Group title={event.event_name ?? 'Event Overview'}>
				<cc.EditRow
					show={true}
					label="Event Time"
					info={s.time.formatDateTime(event.event_time)}
					route={{
						to: '/diary/$diaryId/edit/tracker/$trackerId/event/$eventId/event-time',
						params: (prev) => ({ ...prev }),
					}}
				/>
				<cc.EditRow
					show={auth.isSuperUser}
					locked={false}
					label="Events"
					info={s.capitaliseSentence(event.required_events?.map((mt) => mt).join(', '))}
					route={{
						to: '/diary/$diaryId/edit/tracker/$trackerId/event/$eventId/required-events',
						params: (prev) => ({ ...prev }),
					}}
				/>
			</cc.Group>

			<cc.Group className={cn(!event?.required_events?.length && 'hidden')} title="Event Details">
				<EventSwitches event={event} />
			</cc.Group>

			<DeleteEventButton eventId={event.event_id} diaryId={event.diary_id as string} />
		</>
	);
};

export default EditEvent;
