import type { ExtendedDiaryViewT, InputT } from '../../types/database/diaries';
import PainChart from './pain/PainChart';
import { extractGraphData } from './services/extractGraphData';

interface Props {
	diary: ExtendedDiaryViewT;
	is_preview?: boolean;
	input: InputT;
	pageInputId: string;
	label: string;
}
const Chart = ({ diary, input }: Props) => {
	switch (input.input_key) {
		case 'pain-slider':
			return <PainChart data={extractGraphData(diary)} />;
	}
};

export default Chart;
