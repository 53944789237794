import { ui } from '@/components/ui';
import type { InputT } from '@/types/database/diaries';
import { SelectValue } from '@radix-ui/react-select';
import { useNavigate } from '@tanstack/react-router';

interface Props {
	input: InputT;
}
const InputConfig = ({ input }: Props) => {
	const navigate = useNavigate();
	const setInput = (key: string, val: any) => {
		navigate({ search: (prev) => ({ ...prev, [key]: val }) });
	};

	switch (input.input_key) {
		case 'long-text':
			return (
				<>
					<ui.Label>Label</ui.Label>
					<ui.Input value={input.label} type="text" onChange={(e) => setInput('label', e.target.value)} />
					<ui.Label>Max Length</ui.Label>
					<ui.Input
						value={input.max_length}
						type="number"
						onChange={(e) => setInput('max_length', Number(e.target.value))}
					/>
				</>
			);
		case 'slider':
			return (
				<div>
					<div>
						<ui.Label>Label</ui.Label>
						<ui.Input value={input.label} type="text" onChange={(e) => setInput('label', e.target.value)} />
					</div>
					<div>
						<ui.Label>Min Value</ui.Label>
						<ui.Input
							value={input.min_value}
							type="number"
							onChange={(e) => SelectValue('min_value', e.target.value)}
						/>
					</div>
					<div>
						<ui.Label>Max Value</ui.Label>
						<ui.Input value={input.max_value} type="number" onChange={(e) => setInput('max_value', e.target.value)} />
					</div>
					<div>
						<ui.Label>Face</ui.Label>
						<ui.Switch
							checked={input.show_slider_face}
							onCheckedChange={(e) => setInput('show_slider_face', !input.show_slider_face)}
						/>
					</div>
				</div>
			);
	}
};

export default InputConfig;
