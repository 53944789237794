import { ImagesT } from '../../hooks/useImagesStore';
import { compressImage } from './compressImage';

export const compressFootImages = async (images: ImagesT) => {
    const compressedImages = {
        RT: await compressImage(images.RT.base64, 'right_top'),
        LT: await compressImage(images.LT.base64, 'left_top'),
        RS: await compressImage(images.RS.base64, 'right_sole'),
        LS: await compressImage(images.LS.base64, 'left_sole'),
    };

    return compressedImages;
};
