import { useEffect, useRef, useState } from 'react';

import { cn } from '../../lib/utils';

import type React from 'react';
interface AnimateInViewProps {
	children: React.ReactNode;
	threshold?: number;
	duration?: number;
	delay?: number;
	className?: string;
}

const AnimateInView: React.FC<AnimateInViewProps> = ({
	className,
	children,
	threshold = 0.5,
	duration = 2000,
	delay = 500,
}) => {
	const [isVisible, setIsVisible] = useState(false);
	const componentRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						setIsVisible(true);
					}
				});
			},
			{ threshold },
		);

		if (componentRef.current) {
			observer.observe(componentRef.current);
		}

		return () => {
			if (componentRef.current) {
				observer.unobserve(componentRef.current);
			}
		};
	}, [threshold]);

	return (
		<div
			ref={componentRef}
			className={cn(`w-full h-full
        transform transition-all duration-&lsqb;${duration}ms&rsqb; delay-&lsqb;${delay}ms&rsqb;
        ${isVisible ? 'translate-y-0 opacity-100 scale-100 blur-0' : '-translate-y-8 opacity-0 scale-90 blur-sm'}
      ${className}`)}
		>
			<div
				className={`h-full
          transform transition-all duration-&lsqb;${duration}ms&rsqb; delay-&lsqb;${delay}ms&rsqb;
          ${isVisible ? 'translate-x-0 opacity-100 scale-100' : '-translate-x-4 opacity-0 scale-95'}
        `}
			>
				{children}
			</div>
		</div>
	);
};

export default AnimateInView;
