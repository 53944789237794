interface Props {
    stroke: string;
    className: string;
}

const UpdateTick = ({ stroke, className }: Props) => {
    return (
        <svg
            className={`w-full h-full ${className}`}
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M25.1461 29.9986C24.4835 30.01 23.937 29.4757 23.9255 28.8051L23.7254 17.167L21.0222 19.9981C20.5618 20.4803 19.8021 20.4933 19.3254 20.0273C18.8487 19.5612 18.8355 18.7924 19.2959 18.3102L24.0117 13.3713C24.4721 12.8891 25.2318 12.8761 25.7085 13.3422L30.5913 18.1161C31.068 18.5822 31.0812 19.3509 30.6208 19.8331C30.1604 20.3153 29.4007 20.3283 28.924 19.8623L26.1251 17.1257L26.3251 28.7639C26.3366 29.4344 25.8088 29.9872 25.1461 29.9986Z"
                fill={stroke ? stroke : 'white'}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.6931 30.1049C13.2872 29.8382 13.9879 30.0976 14.2579 30.6842L15.7769 33.9829C15.9686 34.3994 16.3893 34.6668 16.8527 34.6668H33.1473C33.6107 34.6668 34.0314 34.3994 34.2231 33.9829L35.7421 30.6842C36.0121 30.0976 36.7128 29.8382 37.3069 30.1049C37.9011 30.3715 38.1639 31.0631 37.8938 31.6497L36.3749 34.9484C35.7995 36.1978 34.5376 37 33.1473 37H16.8527C15.4624 37 14.2005 36.1978 13.6251 34.9484L12.1062 31.6497C11.8361 31.0631 12.0989 30.3715 12.6931 30.1049Z"
                fill={stroke ? stroke : 'white'}
            />
        </svg>
    );
};

export default UpdateTick;
