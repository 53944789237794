import { z } from "zod";

import { EmailZ } from "../../../types/zod/auth";

export const PasswordZ = z
  .string()
  .min(1, "Password is required")
  .min(8, "Password must have more than 8 characters")
  .refine(
    (password) =>
      /[A-Z]/.test(password) &&
      /\d/.test(password) &&
      /[^a-zA-Z0-9]/.test(password),
    {
      message:
        "Password should include an uppercase letter, a number, and a special character",
    },
  );

export const TermsZ = z.literal(true, {
  errorMap: () => ({ message: "You must accept the terms and conditions" }),
});

export const signupSchema = z.object({
  email: EmailZ,
  password: PasswordZ,
  terms: TermsZ,
  metadata: z.any().optional(),
  options: z.any().optional(),
});

export const ConfirmAccountZ = z.object({
  email: EmailZ,
  terms: TermsZ,
});

export type SignupSchemaType = z.infer<typeof signupSchema>;
