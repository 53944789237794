import { ui } from '@/components/ui';

import { inputComponents as ic } from '.';

const InputSettingsDrawer = () => {
    return (
        <ui.Drawer>
            <ui.Button variant="secondary" asChild className="flex md:hidden">
                <ui.DrawerTrigger>Edit</ui.DrawerTrigger>
            </ui.Button>
            <ui.DrawerContent>
                <ui.DrawerHeader>
                    <ui.DrawerTitle>Are you absolutely sure?</ui.DrawerTitle>
                </ui.DrawerHeader>
                <ic.InputSettings />
                <ui.DrawerFooter>
                    <ui.Button>Submit</ui.Button>
                    <ui.DrawerClose>
                        <ui.Button variant="outline">Cancel</ui.Button>
                    </ui.DrawerClose>
                </ui.DrawerFooter>
            </ui.DrawerContent>
        </ui.Drawer>
    );
};

export default InputSettingsDrawer;
