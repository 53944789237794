import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { CheckSquare } from 'lucide-react';
import { ui } from '../../../../../components/ui';
const ReadIcon = () => {
	return (
		<TooltipProvider>
			<Tooltip>
				<TooltipTrigger>
					<ui.Button className="w-fit px-0" variant="ghost" size="sm">
						<CheckSquare />
					</ui.Button>
				</TooltipTrigger>
				<TooltipContent className="max-w-sm">
					<p>Marked as Done</p>
				</TooltipContent>
			</Tooltip>
		</TooltipProvider>
	);
};

export default ReadIcon;
