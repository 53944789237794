import { formComponents as fc } from '@/components/form';
import { ui } from '@/components/ui';
import { services as s } from '@/services';
import type { ExtendedInjectionViewT } from '@/types/database/events';
import { SideZ } from '@/types/zod/common';
import { type SubmitHandler, useForm, z, zodResolver } from '@/utils/form';
import { useReplaceInjectionDetails } from '../api/useReplaceInjectionDetails';
import InjectionDetailsFormFields from './InjectionDetailsFormFields';

export const InjectionDetailsZ = z.object({
	injection_details: z.array(
		z.object({
			injection_detail_id: z.string().uuid(),
			injection_id: z.string().uuid(),
			side: SideZ.optional().nullable(),
			orthopaedic_area: z.string().optional(),
			injection_site: z.string().optional().nullable(),
			injection_subsite: z.string().optional().nullable(),
			injection_substance: z.string().optional().nullable(),
			dose: z.string().optional().nullable(),
			unit: z.string().optional().nullable(),
		}),
	),
});

export const InjectionDetailSchemaZ = z
	.object({
		injection_id: z.string().uuid(),
	})
	.merge(InjectionDetailsZ);

export type InjectionDetailSchemaT = z.infer<typeof InjectionDetailSchemaZ>;

interface Props {
	injection?: ExtendedInjectionViewT;
	diaryId: string;
	injectionId: string;
	eventId: string;
	onSuccess?: () => void;
}

const convertDoseToString = (array) => {
	return array.map((item) => ({
		...item,
		dose: item.dose?.toString(),
	}));
};

const InjectionDetailsForm = ({ injection, diaryId, eventId, injectionId, onSuccess }: Props) => {
	const replaceInjectionDetails = useReplaceInjectionDetails({
		diaryId: diaryId,
		eventId: eventId,
		injectionId: injectionId,
	});

	const form = useForm<InjectionDetailSchemaT>({
		resolver: zodResolver(InjectionDetailSchemaZ),
		defaultValues: {
			injection_id: injectionId,
			injection_details: injection?.injection_details
				? convertDoseToString(injection.injection_details)
				: [
						{
							injection_id: injectionId,
							injection_detail_id: s.uuidv4(),
						},
					],
		},
	});
	const onSubmit: SubmitHandler<InjectionDetailSchemaT> = async (formData) => {
		replaceInjectionDetails.mutate(formData, { onSuccess: onSuccess });
	};

	return (
		<ui.Form {...form}>
			<form onSubmit={form.handleSubmit(onSubmit)} className="w-full form">
				<InjectionDetailsFormFields injectionId={injectionId} />
				<fc.SaveButton />
			</form>
		</ui.Form>
	);
};

export default InjectionDetailsForm;
