import React from 'react';

const Hourglass = () => {
    return (
        <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.2162 7C15.9922 7 15 8.02335 15 9.28571C15 10.5481 15.9922 11.5714 17.2162 11.5714H18.8784V15.8012C18.8784 20.4993 20.6879 25.005 23.909 28.327L28.0956 32.645C28.7448 33.3144 28.7448 34.3999 28.0956 35.0693L23.909 39.3872C20.688 42.7093 18.8784 47.215 18.8784 51.9131V58.4286H17.2162C15.9922 58.4286 15 59.4519 15 60.7143C15 61.9767 15.9922 63 17.2162 63H53.7838C55.0078 63 56 61.9767 56 60.7143C56 59.4519 55.0078 58.4286 53.7838 58.4286H52.1216V51.9131C52.1216 47.215 50.312 42.7093 47.091 39.3872L42.9044 35.0693C42.2552 34.3999 42.2552 33.3144 42.9044 32.645L47.091 28.327C50.3121 25.005 52.1216 20.4993 52.1216 15.8012V11.5714H53.7838C55.0078 11.5714 56 10.5481 56 9.28572C56 8.02335 55.0078 7 53.7838 7L17.2162 7ZM22.2027 58.4286H48.7973V51.9131C48.7973 48.1243 47.338 44.4907 44.7403 41.8116L40.5537 37.4937C38.6064 35.4853 38.6064 32.229 40.5537 30.2206L44.7403 25.9027C47.338 23.2236 48.7973 19.59 48.7973 15.8012V11.5714L22.2027 11.5714V15.8012C22.2027 19.59 23.662 23.2236 26.2597 25.9027L30.4463 30.2206C32.3936 32.229 32.3936 35.4853 30.4463 37.4937L26.2597 41.8116C23.662 44.4907 22.2027 48.1243 22.2027 51.9131V58.4286Z"
                fill="#222222"
            />
        </svg>
    );
};

export default Hourglass;
