import { icons } from '@/assets';
import { commonComponents as cc } from '@/components/common';
import { useImagesStore } from '@/features/foot-watch/hooks/useImagesStore';
import UploadImageGallery from '@/features/foot-watch/photos/UploadGallery';
import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { shallow } from 'zustand/shallow';

const AddPhotos = () => {
	const { diaryId } = Route.useParams();
	const navigate = useNavigate();
	const [images] = useImagesStore((state) => [state.images, state.updateImages], shallow);

	return (
		<>
			<cc.Title words="Add Photos" />
			<UploadImageGallery
				imageTL={images.LT.base64}
				imageTR={images.RT.base64}
				imageBL={images.LS.base64}
				imageBR={images.RS.base64}
			/>
			<cc.FooterButtons
				func1={() => navigate({ to: '/foot-watch/$diaryId/new/upload/message', params: { diaryId: diaryId } })}
				words1="Next"
				icon1={
					<div className="ml-[2px] flex h-8 w-8 rotate-180 items-center justify-center p-[6px]">
						<icons.BackArrow fill="white" />
					</div>
				}
			/>
		</>
	);
};
export const Route = createFileRoute('/_app/foot-watch/$diaryId/new/add-photos')({
	component: AddPhotos,
});
