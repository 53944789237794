import { useDoppioMutation } from "@/features/previews/hooks/useDoppio";
import { useRouter } from "@tanstack/react-router";
import useSessionQuery from "../../auth/api/useSessionQuery";

interface NavigateAndPrintOptions {
  to: string;
  params?: Record<string, string>;
  search?: Record<string, string | undefined>;
}

export function useNavigateAndPrint() {
  const { data: session } = useSessionQuery();

  const router = useRouter();
  const doppio = useDoppioMutation();

  const navigateAndPrint = (
    { to, params, search }: NavigateAndPrintOptions,
  ) => {
    const searchWithToken = {
      ...search,
      accessToken: session?.access_token,
      refreshToken: session?.refresh_token,
    };
    const location = router.buildLocation({
      to,
      params,
      search: searchWithToken,
    });
    const href = location.href;
    const url = new URL(href, window.location.origin).toString();

    doppio.mutate({ url: url });
  };

  return { navigateAndPrint, isPrinting: doppio.isPending };
}
