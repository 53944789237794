import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Label } from '@/components/ui/label';
import type { InputT } from '@/types/database/forms';
import { useFormContext } from 'react-hook-form';

import { ui } from '../ui';

interface Props {
	input: InputT;
}

const SingleChoice = ({ input }: Props) => {
	const { control } = useFormContext();

	return (
		<ui.Card className="p-4">
			<FormField
				control={control}
				name={input.input_id}
				render={({ field }) => (
					<FormItem>
						<FormLabel>
							<Label>{input.input_label}</Label>
						</FormLabel>
						<FormControl>
							<ui.RadioGroup onValueChange={field.onChange} value={field.value} className="flex flex-col">
								{input.options?.map((o) => (
									<div className="flex justify-start items-center gap-2 p-2" key={o}>
										<ui.RadioGroupItem value={o} key={o} id={o} className="flex items-center gap-2 p-2" />
										<ui.Label htmlFor={o}>{o}</ui.Label>
									</div>
								))}
							</ui.RadioGroup>
						</FormControl>
						<FormMessage />
					</FormItem>
				)}
			/>
		</ui.Card>
	);
};

export default SingleChoice;
