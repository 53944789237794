import { icons } from '@/assets/icons';
import { commonComponents as e } from '@/components/common';
import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { useEffect } from 'react';

const UploadComplete = () => {
	const navigate = useNavigate();
	useEffect(() => {
		if (localStorage.getItem('prolific') === 'true') {
			window.location.replace('https://form.jotform.com/230876676076065');
		}
	}, []);
	return (
		<div className="mx-auto h-full w-full  flex-col content-between items-stretch justify-between px-2">
			<e.Title words="Complete" />
			<div className="my-10">
				<div className="mx-auto mb-6 mt-4 w-[50vw] max-w-[250px] flex-col justify-center">
					<icons.CelebrateSittingDrawing />
				</div>
				<p className="block w-full items-center justify-center pt-4 text-center text-lg">
					Complete our 2 minute{' '}
					<span className="font-semibold text-indigo-600 underline underline-offset-1">
						<a href="https://form.jotform.com/230876676076065">survey</a>
					</span>{' '}
					on what features Foot Watch needs or click Next to go to see your uploaded photos.
				</p>
			</div>
			<e.Button btn="btn-1" words="Next" onClick={() => navigate('/')} />
		</div>
	);
};

export default UploadComplete;

export const Route = createFileRoute(
	'/_app/diary/$diaryId/new/tracker/$trackerId/entry/$entryId/page/$pageIndex/foot-photos/upload/complete',
)({
	component: UploadComplete,
});
