import { supabaseClient } from '@/lib/supabase';
import type { Tracker } from '@/types/database/trackers';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'sonner';

import useInvalidateDiaries from '../../diary/api/invalidateDiaries';

export async function insertTracker(tracker: Tracker['Insert']) {
	const { data, error } = await supabaseClient
		.from('trackers')
		.upsert(tracker)
		.select('*, diaries(patient_id)')
		.single();
	if (error) throw error;
	return data;
}

export const useInsertTracker = () => {
	const invalidateDiaries = useInvalidateDiaries();
	return useMutation({
		mutationKey: ['tracker'],
		mutationFn: (tracker: Tracker['Insert']) => {
			return insertTracker(tracker);
		},
		onError: () => {
			toast.error('Error saving tracker');
		},
		onSuccess: (mutation) => {
			toast.success('Tracker saved');
			mutation?.onSuccess && mutation?.onSuccess();
		},
		onSettled: (data) => {
			invalidateDiaries(data?.diary_id as string);
		},
	});
};
