const SoleOfFootDrawing = () => {
	return (
		<svg
			className="h-full w-full"
			width="512"
			height="465"
			viewBox="0 0 512 465"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect x="360" y="453" width="8" height="57" rx="1" transform="rotate(90 360 453)" fill="#4F46E5" />
			<circle cx="96" cy="49" r="47" fill="white" stroke="black" strokeWidth="4" />
			<path
				d="M54 271.224V156.652C54 143.575 57.1577 130.692 63.2046 119.097C67.4028 111.048 75.7283 106 84.8068 106H107.889C116.967 106 125.293 111.048 129.491 119.097C135.538 130.692 138.695 143.575 138.695 156.652V261.571C138.695 267.094 143.173 271.571 148.695 271.571H214.675C222.572 271.571 230.149 274.684 235.765 280.235L318.539 362.056C319.475 362.981 320.738 363.5 322.054 363.5H353C362.113 363.5 369.5 370.887 369.5 380C369.5 389.113 362.113 396.5 353 396.5H302.037C296.811 396.5 291.793 394.455 288.056 390.801L225.629 329.772C220.024 324.293 212.496 321.224 204.657 321.224H104C76.3858 321.224 54 298.839 54 271.224Z"
				fill="white"
				stroke="black"
				strokeWidth="4"
			/>
			<path
				d="M106.904 225.275L77.9009 146.625C71.7133 129.845 88.9443 113.984 105.155 121.536C109.949 123.769 113.665 127.807 115.493 132.769L139.523 197.998C142.261 205.429 147.826 211.476 155.004 214.821L212.604 241.656C222.239 246.145 226.411 257.595 221.922 267.231C217.433 276.866 205.983 281.038 196.348 276.549L122.382 242.089C115.206 238.746 109.643 232.702 106.904 225.275Z"
				fill="white"
				stroke="black"
				strokeWidth="4"
			/>
			<path
				d="M11 137.5L35.1143 279.703C40.8228 313.367 69.9851 338 104.129 338H189.5"
				stroke="black"
				strokeWidth="20"
				strokeLinecap="round"
			/>
			<path d="M167 338L194 458" stroke="black" strokeWidth="12" strokeLinecap="round" />
			<path d="M71 338L44 458" stroke="black" strokeWidth="12" strokeLinecap="round" />
			<rect x="450" y="266" width="17" height="195" fill="black" />
			<rect x="512" y="258" width="17" height="227" transform="rotate(90 512 258)" fill="black" />
		</svg>
	);
};

export default SoleOfFootDrawing;
