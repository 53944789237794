import { ExtendedInjectionT, InjectionDetailViewT } from '@/types/database/trackers';

import useExtractInjection from './useExtractInjection';

function extractInjectionDetailById(
    injection: ExtendedInjectionT,
    injectionDetailId: string,
): InjectionDetailViewT | null {
    return (
        injection?.injection_details_view?.find((detail) => detail.injection_detail_id === injectionDetailId) ?? null
    );
}

const useExtractInjectionDetail = (diaryId: string, injectionId: string, injectionDetailId: string) => {
    const injection = useExtractInjection(diaryId, injectionId);
    const injectionDetail = extractInjectionDetailById(injection, injectionDetailId);
    return injectionDetail;
};

export default useExtractInjectionDetail;
