import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { Link } from '@tanstack/react-router';
import { ArrowLeft } from 'lucide-react';

import { useNav } from '../../../utils/nav';
import Thetis from '../assets/Thetis';

const MainIcon = () => {
	const nav = useNav();
	return (
		<Button asChild size="sm" variant="ghost" className="gap-x-0 px-2">
			<Link
				to={nav?.backRoute?.route?.to}
				params={nav?.backRoute?.route?.params}
				search={nav?.backRoute?.route?.search}
			>
				<span className={cn('gap-x-2 bg-transparent px-0 text-slate-800 dark:text-slate-200 ')}>
					{nav?.breadcrumbRoutes.length > 1 ? (
						<span className="flex items-center gap-x-1">
							<ArrowLeft strokeWidth={1.75} size={32} />
							<span className={cn(nav?.breadcrumbRoutes.length > 1 ? 'sr-only' : 'text-md pr-2')}>
								{String(nav?.backRoute?.label)?.toUpperCase()}
							</span>
						</span>
					) : (
						<span className="[&_svg]:w-[35px] [&_svg]:h-[35px]">
							<Thetis />
						</span>
					)}
				</span>
			</Link>
		</Button>
	);
};

export default MainIcon;
