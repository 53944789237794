import { commonComponents as cc } from '@/components/common';
import { ui } from '@/components/ui';
import { cn } from '@/lib/utils';
import { services as s } from '@/services';
import type { StatefulTrackerViewT } from '@/types/database/trackers';
import type { SizeT } from '@/types/zod/common';
import { Link } from '@tanstack/react-router';

interface Props {
	tracker: StatefulTrackerViewT;
	index?: number;
	size?: SizeT;
	editable?: boolean;
	printable?: boolean;
}
const TrackerPreview = (props: Props) => {
	return (
		<div className="flex flex-col gap-y-2">
			<div className="flex w-full flex-row items-center justify-between">
				<h3 className={cn(props.size === 'sm' ? 'text-md' : 'text-lg')}>{props.tracker.tracker_name}</h3>

				<div className="flex w-fit flex-row items-center justify-center">
					{props.printable && (
						<ui.Button className="px-2" size="md" variant="link">
							<Link
								to="/diary/$diaryId/tracker/$trackerId/print"
								params={{ trackerId: props.tracker.tracker_id!, diaryId: props.tracker.diary_id! }}
							>
								Print
							</Link>
						</ui.Button>
					)}
					{props.printable && props.editable && (
						<ui.Separator orientation="vertical" className="mx-1 h-6  border-indigo-600" />
					)}
					{props.editable !== false && (
						<cc.EditButton>
							<Link
								to="/diary/$diaryId/edit/tracker/$trackerId"
								params={{ diaryId: props.tracker.diary_id as string, trackerId: props.tracker.tracker_id }}
							>
								Edit
							</Link>
						</cc.EditButton>
					)}
				</div>
			</div>
			<p className={cn('font-medium text-slate-600', props.size === 'sm' ? 'text-sm' : 'text-md')}>
				{props.tracker.start_date
					? `${s.time.formatDate(props.tracker.start_date)} to ${
							props.tracker.end_date === null ? 'Now' : s.time.formatDate(props.tracker.end_date)
						}`
					: 'Tracker Time not specified'}
			</p>
		</div>
	);
};

export default TrackerPreview;
