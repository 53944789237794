import { supabaseClient } from '@/lib/supabase';
import type { UserT } from '@/types/database/profiles';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';
import { createProfileQueryKey } from './selectProfile';
type PartialUser = Partial<UserT>;

async function updateUser(userId: string, data: PartialUser) {
	return supabaseClient.from('users').update(data).eq('user_id', userId).select().throwOnError().single();
}

function useUserMutation(userId: string) {
	// const auth = useAuth();
	// const userId = auth?.id as string;
	const queryClient = useQueryClient();
	const queryKey = ['profile', userId];

	return useMutation({
		mutationFn: async (data: PartialUser) => {
			return updateUser(userId, data).then((result) => result.data);
		},
		onError: (error) => {
			toast.error(error.message);
		},
		onSuccess: (mutation) => {
			toast.success('Successfully updated profile');
			mutation?.onSuccess?.();
		},
		onSettled: (data) => {
			// Invalidate to make sure last image label updates...
			queryClient.invalidateQueries({ queryKey: queryKey });
			queryClient.invalidateQueries({ queryKey: createProfileQueryKey(data?.user_id as string) });
			// Route.invalidate();
		},
	});
}

export default useUserMutation;
