import { formComponents as fc } from '@/components/form';
import { useFormContext } from 'react-hook-form';
import { buttonVariants } from '../../../components/ui/button';
import { cn } from '../../../lib/utils';
import InviteDialog from '../../invite/components/InviteDialog';
import useInjectionOptions from '../hooks/useInjectionOptions';
import InjectionDiarySaveLabel from './InjectionDiarySaveLabel';

interface Props {
	organisationId: string;
}
const InjectionFormFields = ({ organisationId }: Props) => {
	const form = useFormContext();
	const { methodOptions, clinicianOptions } = useInjectionOptions(organisationId);
	return (
		<>
			<div>
				{/* Referrer */}
				<InjectionDiarySaveLabel
					required={true}
					label="Referring Clinician"
					data={{ referring_clinician_id: form.getValues('referring_clinician_id') }}
				/>
				<fc.ComboBox
					autoFocus
					name="referring_clinician_id"
					description="The clinician who requests the injection"
					options={clinicianOptions}
				/>
			</div>
			<div>
				{/* Injector */}
				<InjectionDiarySaveLabel
					label="Injecting Clinician"
					data={{ injecting_clinician_id: form.getValues('injecting_clinician_id') }}
				/>
				<fc.ComboBox
					name="injecting_clinician_id"
					description="The clinician who performs the injection"
					options={clinicianOptions}
					clearable={true}
				/>
			</div>
			<div>
				{/* Method */}
				<InjectionDiarySaveLabel
					label="Injection Method"
					data={{ injection_method_id: form.getValues('injection_method_id') }}
				/>
				<fc.Select
					name="injection_method_id"
					description="How and where injection is performed"
					options={methodOptions}
				/>
			</div>

			<InviteDialog className={cn(buttonVariants({ size: 'md', variant: 'link' }), 'mx-auto flex')} />
		</>
	);
};

export default InjectionFormFields;
