import type { DiaryT } from '@/types/database/diaries';
import Fisher from './Fisher';
import Pain from './Pain';
interface Props {
	diaryType: DiaryT['diary_type'];
}
const DiaryTypeEmblemSwitch = ({ diaryType }: Props) => {
	switch (diaryType?.toLowerCase()) {
		case 'fisher':
			return <Fisher />;
		case 'pain':
			return <Pain />;
		case 'foot':
			return <div>📊</div>;
		default:
			return null;
	}
};

export default DiaryTypeEmblemSwitch;
