import { ScheduleT } from '../../../types/zod/timings';

export const processScheduleOptions = (schedules: ScheduleT[]) => {
    return schedules.map((s) => {
        return {
            label: s.schedule_name ?? 'No Name',
            value: s.schedule_id,
        };
    });
};
