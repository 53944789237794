interface Props {
    fill?: string;
    className?: string;
}

const Warning = ({ fill, className }: Props) => {
    return (
        <svg
            className={`w-full h-full ${className}`}
            width="70"
            height="70"
            viewBox="0 0 70 70"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M56.6508 52.5L35.0001 15L13.3495 52.5L56.6508 52.5ZM39.3303 12.5C37.4058 9.16667 32.5945 9.16667 30.67 12.5L9.01937 50C7.09487 53.3333 9.5005 57.5 13.3495 57.5H56.6508C60.4998 57.5 62.9054 53.3333 60.9809 50L39.3303 12.5Z"
                fill={fill ? fill : 'black'}
            />
            <path
                d="M37.2778 27.291L36.7676 42.9233H33.0566L32.5309 27.291H37.2778ZM32.4072 47.763C32.4072 47.1033 32.634 46.557 33.0876 46.124C33.5514 45.6808 34.1596 45.4591 34.9121 45.4591C35.6749 45.4591 36.2831 45.6808 36.7367 46.124C37.1902 46.557 37.417 47.1033 37.417 47.763C37.417 48.4021 37.1902 48.9433 36.7367 49.3866C36.2831 49.8298 35.6749 50.0514 34.9121 50.0514C34.1596 50.0514 33.5514 49.8298 33.0876 49.3866C32.634 48.9433 32.4072 48.4021 32.4072 47.763Z"
                fill={fill ? fill : 'black'}
            />
        </svg>
    );
};

export default Warning;
