import { cn } from '@/lib/utils';
import * as React from 'react';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {}

export const inputStyle =
	'flex h-12 w-full rounded-md  border border-slate-300 dark:border-slate-500  bg-white px-3  py-2 text-sm ring-offset-white dark:ring-offset-slate-900 file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-slate-400 focus-visible:outline-none dark:border-red-400 focus-visible:ring-2 focus-visible:ring-indigo-300 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 dark:bg-slate-900 dark:border-slate-500 dark:text-slate-100';

const Input = React.forwardRef<HTMLInputElement, InputProps>(({ className, type, ...props }, ref) => {
	return (
		<input
			type={type}
			className={cn(inputStyle, className, type === 'time' ? 'pr-5 inline' : '')}
			ref={ref}
			{...props}
		/>
	);
});
Input.displayName = 'Input';

export { Input };
