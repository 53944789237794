import Select from '@/components/form/Select';
import {
	createUniqueInjectionOptions,
	toFilteredUniqueInjectionOptions,
} from '@/features/injection/utils/injectionOptions';
import type { InjectionLocationT } from '@/types/database/pain';

import { sideOptions } from '../../events/utils/sideOptions';

interface Props {
	formValues: string[];
	injectionLocations: InjectionLocationT[];
}

const LocationForm = (props: Props) => {
	return (
		<>
			<div className="flex flex-col gap-2 md:flex-row">
				<Select
					name="orthopaedic_area"
					label="Anatomic Area"
					options={createUniqueInjectionOptions(props.injectionLocations, 'orthopaedic_area')}
				/>
				<Select name="side" label="Side" options={sideOptions} />
			</div>
			<div className="flex flex-col gap-2 md:flex-row">
				<Select
					name="injection_site"
					label="Injection Site"
					options={toFilteredUniqueInjectionOptions(
						props.injectionLocations,
						'injection_site',
						'orthopaedic_area',
						props.formValues[0] as keyof InjectionLocationT,
					)}
				/>
				<Select
					name="injection_subsite"
					label="Injection Subsite"
					options={toFilteredUniqueInjectionOptions(
						props.injectionLocations,
						'injection_subsite',
						'injection_site',
						props.formValues[1] as keyof InjectionLocationT,
					)}
				/>
			</div>
		</>
	);
};

export default LocationForm;
