import Title from '@/components/common/Title';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { selectOrganisationUsersWithFavouritesByOrganisationIdQueryOptions } from '@/features/admin/api/selectOrganisationsUsersWithFavourites';
import { services as s } from '@/services';
import { useNav } from '@/utils/nav';
import { createFileRoute } from '@tanstack/react-router';
// Main component
const OrganisationPage = () => {
	const { organisationId } = Route.useParams();
	useNav([
		{ label: 'Profile', route: { to: '/profile' } },
		{ label: 'Organisation', route: { to: '/organisation/$organisationId' } },
	]);

	const { organisation } = Route.useLoaderData();

	const clinicians = organisation.users
		?.filter((user) => user.profiles?.roles.includes('CLINICIAN'))
		.map((user) => user.profiles);

	return (
		<section className="lg-width">
			<Title words={organisation.organisation_name} />
			<Card>
				<CardHeader>
					<CardTitle>{organisation.organisation_name} Staff</CardTitle>
				</CardHeader>
				<CardContent>
					<Accordion type="single" collapsible>
						{clinicians.map(({ name, email, user_id, roles, favourite_users }) => (
							<AccordionItem key={user_id} value={user_id}>
								<AccordionTrigger>
									<div className="flex flex-col justify-between">
										<span>
											<strong className="text-slate-800">{name}</strong> ({s.capitaliseSentence(roles.join(', '))})
										</span>{' '}
										<span className="text-slate-600">{email}</span>
									</div>
								</AccordionTrigger>
								<AccordionContent className="pl-4 text-sm">
									<h5>
										{favourite_users.filter((secretary) => secretary.profiles.roles.includes('SECRETARY')).length
											? 'Associated Secretaries:'
											: 'No Associated Secretaries'}
									</h5>
									<ul className="text-slate-600">
										{favourite_users
											.filter((secretary) => secretary.profiles.roles.includes('SECRETARY'))
											?.filter((sec) =>
												sec.profiles.organisations?.map((o) => o.organisation_id).includes(organisationId),
											)
											.map((secretary) => secretary.profiles)
											.map(({ name, email }) => (
												<li key={name}>{email}</li>
											))}
									</ul>
								</AccordionContent>
							</AccordionItem>
						))}
					</Accordion>
				</CardContent>
			</Card>
		</section>
	);
};

export default OrganisationPage;

export const Route = createFileRoute('/_app/organisation/$organisationId/')({
	loader: async ({ context, params }) => {
		const { queryClient } = context;
		const organisationId = params.organisationId;

		const organisation = await queryClient.ensureQueryData(
			selectOrganisationUsersWithFavouritesByOrganisationIdQueryOptions(organisationId),
		);

		return { organisation };
	},
	component: OrganisationPage,
});
