import { z } from 'zod';

const requiredText = z.string().min(1, 'Answer Required');
const requiredNumber = z
	.number()
	.refine((val) => typeof val === 'number' && !Number.isNaN(val), 'Must be a valid number');

const requiredDate = z.date();
const requiredUrl = z.string().url();

const optionalNumber = z.number().optional();
const optionalText = z.string().optional();

export const inputSettings = {
	'long-text': { validation: requiredText, default: '' },
	'short-text': { validation: requiredText, default: '' },
	number: { validation: requiredNumber, default: 0 },
	boolean: { validation: z.boolean(), default: false },
	'yes-no': { validation: z.enum(['yes', 'no']), default: null },
	duration: {
		validation: z.object({ interval: requiredNumber, timeUnit: z.string() }),
		default: { interval: 1, timeUnit: 'day' },
	},
	dropdown: { validation: z.string(), default: '' },
	email: { validation: z.string().email(), default: '' },
	'single-choice': { validation: requiredText, default: '' },
	'multiple-choice': { validation: z.array(requiredText), default: [] },
	'scale-rating': { validation: requiredNumber, default: null },
	'visa-a-8': { validation: requiredNumber, default: null },
	star: { validation: requiredNumber, default: null },
	slider: { validation: requiredNumber, default: null },
	'glucose-slider': { validation: requiredNumber, default: null },
	'pain-slider': { validation: requiredNumber, default: null },
	date: { validation: requiredDate, default: new Date() }, // added
	url: { validation: requiredUrl, default: '' }, // added
	'file-upload': { validation: z.any(), default: null }, // added
	'color-picker': { validation: z.string(), default: '#000000' }, // added
};

// export function getInputSchema(input: { input_key: string; is_optional: boolean }) {
// 	const setting = inputSettings[input.input_key];
// 	if (!setting) {
// 		throw new Error(`Invalid input_key: ${input.input_key}`);
// 	}
// 	return input.is_optional ? setting.validation.optional() : setting.validation;
// }

export function getInputSchema(input: { input_key: string; is_optional: boolean }) {
	let validation: any;
	let defaultValue: any;

	switch (input.input_key) {
		case 'long-text':
		case 'short-text':
		case 'single-choice':
			validation = input.is_optional ? optionalText : requiredText;
			defaultValue = '';
			break;
		case 'number':
		case 'scale-rating':
			validation = input.is_optional ? optionalNumber : requiredNumber;
			break;
		case 'star':
		case 'slider':
		case 'glucose-slider':
		case 'pain-slider':
			validation = input.is_optional ? optionalNumber : requiredNumber;
			defaultValue = 5;
			break;
		case 'boolean':
			validation = input.is_optional ? z.boolean().optional() : z.boolean();
			defaultValue = false;
			break;
		case 'yes-no':
			validation = z.enum(['yes', 'no']);
			defaultValue = null;
			break;
		case 'duration':
			validation = z.object({ interval: requiredNumber, timeUnit: z.string() });
			defaultValue = { interval: 1, timeUnit: 'day' };
			break;
		case 'dropdown':
			validation = z.string();
			defaultValue = '';
			break;
		case 'email':
			validation = z.string().email();
			defaultValue = '';
			break;
		case 'multiple-choice':
			validation = z.array(requiredText);
			defaultValue = [];
			break;
		case 'visa-a-8':
			validation = input.is_optional ? optionalNumber : requiredNumber;
			break;
		default:
			throw new Error(`Invalid input_key: ${input.input_key}`);
	}

	return {
		validation: validation,
		default: defaultValue,
	};
}
