interface Props {
	fill?: string;
}
const Spinner = ({ fill }: Props) => {
	return (
		<svg width="119" fill="none" xmlns="http://www.w3.org/2000/svg" className="pointer-events-none flex animate-spin">
			<circle cx="92" cy="92" r="16" fill={fill ? fill : 'white'} fillOpacity="0.65" />
			<circle cx="105.5" cy="57.5" r="13.5" fill={fill ? fill : 'white'} fillOpacity="0.55" />
			<circle cx="91.5" cy="23.5" r="11.5" fill={fill ? fill : 'white'} fillOpacity="0.45" />
			<circle cx="57" cy="9" r="9" fill={fill ? fill : 'white'} fillOpacity="0.35" />
			<circle cx="23" cy="23" r="9" fill={fill ? fill : 'white'} fillOpacity="0.25" />
			<circle cx="9" cy="57" r="9" fill={fill ? fill : 'white'} fillOpacity="0.25" />
			<circle cx="23" cy="92" r="9" fill={fill ? fill : 'white'} fillOpacity="0.25" />
			<circle cx="57" cy="106" r="9" fill={fill ? fill : 'white'} fillOpacity="0.25" />
		</svg>
	);
};

export default Spinner;
