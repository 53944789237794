// TrackerStartDate.tsx
import DateTimeForm from '@/components/form/DateTimeForm';
import type { StatefulDiaryT } from '@/types/database/diaries';
import useUpdateDiary from '../../api/updateDiary';

interface Props {
	diary: StatefulDiaryT;
}

const DiaryEndDateForm = ({ diary }: Props) => {
	const updateDiary = useUpdateDiary();

	const updateEndDate = async (date: string | null) => {
		await updateDiary.mutate({
			diary_id: diary.diary_id,
			end_date: date,
		});
	};

	return <DateTimeForm initialDate={diary?.end_date} onSubmit={updateEndDate} submitButtonText="Save" />;
};

export default DiaryEndDateForm;
