import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

export type FacingModeT = "user" | "environment";
export type CameraStatus = {
  cameraOpen: boolean;
  delay: number;
  facingMode: FacingModeT;
};

export type State = {
  cameraStatus: CameraStatus;
};

export type Actions = {
  toggleCamera: () => void;
  setDelay: (delay: number) => void;
  setFacingMode: (facingMode: "user" | "environment") => void;
  getCameraAttributes: () => CameraStatus;
};

const initialState: State = {
  cameraStatus: {
    cameraOpen: false,
    delay: 0,
    facingMode: "user",
  },
};

export const useCameraStore = create<State & Actions>()(
  devtools(
    persist(
      (set, get) => ({
        ...initialState,
        toggleCamera: () =>
          set((state) => ({
            cameraStatus: {
              ...state.cameraStatus,
              cameraOpen: !state.cameraStatus.cameraOpen,
            },
          })),
        setDelay: (delay: number) =>
          set((state) => ({
            cameraStatus: {
              ...state.cameraStatus,
              delay,
            },
          })),
        setFacingMode: (facingMode: "user" | "environment") =>
          set((state) => ({
            cameraStatus: {
              ...state.cameraStatus,
              facingMode,
            },
          })),
        getCameraAttributes: () => get().cameraStatus,
      }),
      {
        name: "camera-store",
        storage: createJSONStorage(() => localStorage),
      },
    ),
  ),
);
