import DateTimeInput from '@/components/form/DateTime';
// TrackerReferenceDate.tsx
import Select from '@/components/form/Select';
// components/TrackerDateForm.tsx
import { ui } from '@/components/ui';
import { Label } from '@/components/ui/label';
import useExtractTracker from '@/features/trackers/hooks/useExtractTracker';
import { useTrackerUpdate } from '@/features/trackers/hooks/useTrackerUpdate';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import * as z from 'zod';
import { useAuth } from '../../../utils/auth';
import InviteDialog from '../../invite/components/InviteDialog';
import FavouriteUsersComboBox from '../../profile/components/FavouriteUsersComboBox';

const trackerOverviewFormSchemaZ = z.object({
	start_date: z.string().nullable(),
	reference_date: z.string().nullable(),
	contact_id: z.string().uuid().nullable(),
	form_id: z.string().uuid().nullable(),
});

export type TrackerOverviewFormSchemaT = z.infer<typeof trackerOverviewFormSchemaZ>;

interface Props {
	diaryId: string;
	trackerId: string;
}

const TrackerOverviewForm = ({ diaryId, trackerId }: Props) => {
	const auth = useAuth();
	const tracker = useExtractTracker(diaryId, trackerId);
	const { updateOverview } = useTrackerUpdate(diaryId, trackerId);

	const form = useForm<TrackerOverviewFormSchemaT>({
		resolver: zodResolver(trackerOverviewFormSchemaZ),
	});

	useEffect(() => {
		form.reset({
			start_date: tracker?.start_date,
			reference_date: tracker?.reference_date,
			form_id: tracker?.form_id,
			contact_id: tracker?.contact_id,
		});
	}, [tracker]);

	const handleSubmit: SubmitHandler<TrackerOverviewFormSchemaT> = (formData) => {
		updateOverview(formData);
	};

	return (
		<FormProvider {...form}>
			<ui.Form {...form}>
				<form onSubmit={form.handleSubmit(handleSubmit)} className="form">
					<DateTimeInput
						name="start_date"
						label="Start Date"
						description="Select the date and time for your tracker entry"
					/>
					<DateTimeInput
						name="reference_date"
						label="Reference Date"
						description="Select the date and time for your tracker entry"
					/>

					<Select
						label="Local and/or Secondary Pain"
						description="e.g. Monitor back AND leg pain after a spinal injection."
						name="form_id"
						options={[
							{ label: 'Local Pain Only', value: '289abef6-3ce8-4b5b-b621-d622b5efc975' },
							{ label: 'Local and Secondary Pain', value: 'ad5ae755-bfaf-4797-bf10-71d61a5c7465' },
						]}
					/>
					<div className="">
						<Label>Contact Person</Label>
						<div className="flex flex-col gap-y-2">
							<FavouriteUsersComboBox includeUser={true} placeholder="Contact Person" name="contact_id" />
							<InviteDialog />
						</div>
					</div>
					<ui.Button size="lg" type="submit">
						Save
					</ui.Button>
				</form>
			</ui.Form>
		</FormProvider>
	);
};

export default TrackerOverviewForm;
