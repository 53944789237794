import React from 'react';

const Wait = () => {
    return (
        <svg width="37" height="49" viewBox="0 0 37 49" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.0439 4.6158C14.412 2.42178 16.3202 0.75 18.6189 0.75C20.9383 0.75 22.8602 2.45215 23.2035 4.67545C23.8567 4.32425 24.6037 4.125 25.3973 4.125C27.6812 4.125 29.5797 5.7755 29.9651 7.94883C30.5463 7.66148 31.2009 7.5 31.8932 7.5C34.2994 7.5 36.25 9.45059 36.25 11.8568V37C36.25 43.2132 31.2132 48.25 25 48.25H12C5.7868 48.25 0.75 43.2132 0.75 37V25.0767C0.75 22.5145 2.82704 20.4375 5.3892 20.4375C6.16074 20.4375 6.8883 20.6258 7.52839 20.9591V8.60065C7.52839 6.12882 9.53221 4.125 12.004 4.125C12.7388 4.125 13.4322 4.30205 14.0439 4.6158ZM13.9797 8.60065C13.9797 7.50953 13.0952 6.625 12.004 6.625C10.9129 6.625 10.0284 7.50953 10.0284 8.60065V33.5C10.0284 34.1904 9.46875 34.75 8.77839 34.75C8.08804 34.75 7.52839 34.1904 7.52839 33.5V25.0767C7.52839 23.8953 6.57064 22.9375 5.3892 22.9375C4.20775 22.9375 3.25 23.8953 3.25 25.0767V37C3.25 41.8325 7.16751 45.75 12 45.75H25C29.8325 45.75 33.75 41.8325 33.75 37V11.8568C33.75 10.8313 32.9187 10 31.8932 10C30.8678 10 30.0365 10.8313 30.0365 11.8568V23.9375C30.0365 24.6279 29.4768 25.1875 28.7865 25.1875C28.0961 25.1875 27.5365 24.6279 27.5365 23.9375V8.7642C27.5365 7.58275 26.5787 6.625 25.3973 6.625C24.2158 6.625 23.2581 7.58275 23.2581 8.7642V23.9375C23.2581 24.6279 22.6984 25.1875 22.0081 25.1875C21.3177 25.1875 20.7581 24.6279 20.7581 23.9375V5.3892C20.7581 4.20775 19.8003 3.25 18.6189 3.25C17.4374 3.25 16.4797 4.20775 16.4797 5.3892V23.9375C16.4797 24.6279 15.92 25.1875 15.2297 25.1875C14.5393 25.1875 13.9797 24.6279 13.9797 23.9375V8.60065Z"
                fill="black"
            />
        </svg>
    );
};

export default Wait;
