import { Badge } from '../../../components/ui/badge';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../../components/ui/table';
import { capitaliseSentence } from '../../../services/capitaliseSentence';
import CopyCell from '../../dashboard/features/overview/table/components/CopyCell';
import useFavouriteStaff from '../hooks/useFavouriteStaff';
import FavouriteUserStar from './FavouriteUserStar';

const FavouriteUsers = () => {
	const { faves, nonFaves } = useFavouriteStaff();
	const allUsers = [...(faves || []), ...(nonFaves || [])].filter(Boolean);

	const roleVariantMap = {
		"PATIENT": "default",
		"CLINICIAN": "outline",
		"SECRETARY": "secondary",
		"ADMIN": "destructive"
	}
	return (
		<Table>
			<TableHeader>
				<TableRow>
					<TableHead className="pl-0 text-md">Name</TableHead>
					<TableHead className="text-md">Email</TableHead>
					<TableHead className="text-md">Roles</TableHead>
					<TableHead className="w-[100px] text-md">Favourite</TableHead>
				</TableRow>
			</TableHeader>
			<TableBody>
				{allUsers.map((user) => (
					<TableRow 
						key={user.user_id}
						className="text-md transition-all duration-300"
					>
						<CopyCell className="pl-0" id={`copy-${user.name}`} value={user.name??''} />

						<CopyCell id={`copy-${user.email}`} value={user.email??''} />
						<TableCell>
							<div className="flex flex-wrap gap-1">
								{user.roles?.map((role) => (
									<Badge 
										variant={roleVariantMap[role]} 
										key={role}
									>
										{capitaliseSentence(role)}
									</Badge>
								))}
							</div>
						</TableCell>
						<TableCell>
							<FavouriteUserStar 
								userId={user.user_id as string} 
								favourite={faves?.some(f => f?.user_id === user.user_id) ?? false} 
							/>
						</TableCell>
					</TableRow>
				))}
			</TableBody>
		</Table>
	);
};

export default FavouriteUsers;
