import { commonComponents as cc } from '@/components/common';

import type { ActionKeyT, ActionViewT } from '@/types/database/actions';
import { ActionIcon } from '../../../assets/actions/ActionIcon';
import HistoryCardTitle from './HistoryCardTitle';

interface Props {
	action: ActionViewT;
}
const ActionTitle = (props: Props) => {
	return (
		<HistoryCardTitle
			title={
				<cc.IconDetail icon={ActionIcon[props.action.action_key as ActionKeyT]} words={props.action.action_name} />
			}
			dateTime={props.action.action_time as string}
		>
		</HistoryCardTitle>
	);
};

export default ActionTitle;
