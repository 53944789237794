import { services as s } from '../../../services';

interface Props {
	content: any[];
}

const WrappingIconPoints = (props: Props) => {
	return (
		<div className="flex flex-wrap justify-center gap-12 mt-4">
			{props.content.map((benefit) => (
				<div
					className="flex flex-col justify-center md:justify-start items-center md:mb-8 p-4 md:p-0 pr-12 rounded-xl md:w-[30%]"
					key={`benefit_${s.uuidv4()}`}
				>
					<div className="bg-indigo-100 md:mr-auto p-2 rounded-sm w-fit h-fit text-indigo-500">{benefit.icon}</div>
					<div className="text-center md:text-left">
						<h3 className="mt-4 mb-2 font-semibold text-slate-600 text-xl capitalize">{benefit.title}</h3>
						<p className="text-md text-slate-800">{benefit.description}</p>
					</div>
				</div>
			))}
		</div>
	);
};

export default WrappingIconPoints;
