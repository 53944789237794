import { cn } from '../../../lib/utils';
import type { EntryValueViewT } from '../../../types/database/diaries';
import type { SizeT } from '../../../types/zod/common';
import EntryValueSwitch from './EntryValueSwitch';

interface Props {
	size?: SizeT;
	entryValues: EntryValueViewT[];
}

const findPageInputIndex = (page_index: number, input_index: number) => {
	return input_index + page_index * 100;
};

const sortByPageInputIndex = (entryValues: EntryValueViewT[]) => {
	if (!entryValues) return [];

	const sorted = entryValues.sort(
		(a, b) => findPageInputIndex(a.page_index, a.input_index) - findPageInputIndex(b.page_index, b.input_index),
	);

	return sorted;
};

const EntryCardEntryValues = ({ size, entryValues }: Props) => {
	return (
		<ol className="flex flex-col gap-2">
			{/* In this version, [...entry.entry_values_view] creates a new array that is a copy of entry.entry_values_view, and then the sort function is called on this new array. This way, the original entry.entry_values_view array is not mutated. */}
			{sortByPageInputIndex(entryValues).map((ev) => (
				<li
					key={ev.entry_value_id}
					className={cn('flex flex-row gap-4 font-medium text-slate-800', size === 'sm' ? 'text-sm' : 'text-md')}
				>
					<EntryValueSwitch entryValue={ev} />
				</li>
			))}
		</ol>
	);
};

export default EntryCardEntryValues;
