import {
	ClipboardCheck,
	Clock,
	DollarSign,
	HandIcon,
	HeartHandshake,
	LineChart,
	MedalIcon,
	Microscope,
	Smile,
} from 'lucide-react';

export const problemContent = [
	{
		icon: <Clock />,
		title: 'Wasted Appointments',
		description: 'My waiting list is 3 months and this patient has come in only to tell me they are feeling better...',
	},
	{
		icon: <ClipboardCheck />,
		title: 'Missing Audit Data',
		description: 'I need to do an audit but don’t have any data...',
	},
	{
		icon: <LineChart />,
		title: 'Baseless Decisions',
		description:
			'How can I know if my intervention worked if the patient can’t remember if their pain reduced initially?',
	},
	{
		icon: <Microscope />,
		title: 'What is best for my patients?',
		description:
			'My colleague says I should use drug X not drug Y. I could give it a try but how will I know if it helps my patients?',
	},
	{
		icon: <MedalIcon />,
		title: 'How to Prove New Methods?',
		description: 'I have adopted this great new surgery and want to show how much better it is!',
	},
];
