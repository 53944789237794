import 'react-phone-number-input/style.css';

import { selectProfileByIdQueryOptions } from '@/features/profile/api/selectProfile';
import ProfileWrap from '@/features/profile/layouts/ProfileWrap';
import { useAuth } from '@/utils/auth';
import { useSuspenseQuery } from '@tanstack/react-query';
import { createFileRoute, useNavigate } from '@tanstack/react-router';

import PhoneForm from '@/features/profile/components/PhoneForm';

const Phone = () => {
	const auth = useAuth();
	const { data: profile } = useSuspenseQuery(selectProfileByIdQueryOptions(auth.id));
	const navigate = useNavigate();

	return (
		<ProfileWrap title="Phone">
			<PhoneForm onSuccess={() => navigate({ to: '/profile' })} userId={auth.id} phone={profile?.phone ?? ''} />
		</ProfileWrap>
	);
};

export const Route = createFileRoute('/_app/profile/phone')({
	component: Phone,
});

export default Phone;
