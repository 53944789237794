import type { PageViewT } from '@/types/database/diaries';

export const sortAndGroupInputs = (diary_inputs: PageViewT[]) => {
	// First, sort by page_index and then by order_index
	try {
		const sortedInputs = diary_inputs?.sort((a, b) => {
			if (a.page_index === b.page_index) {
				return a.order_index - b.order_index;
			}
			return a.page_index - b.page_index;
		});

		// Then, group by page_index
		const groupedInputs: PageViewT[][] = [];
		let currentPageIndex = sortedInputs[0]?.page_index;
		let currentPageInputs: PageViewT[] = [];

		for (const input of sortedInputs) {
			if (input.page_index === currentPageIndex) {
				currentPageInputs.push(input);
			} else {
				groupedInputs.push(currentPageInputs);
				currentPageInputs = [input];
				currentPageIndex = input.page_index;
			}
		}

		// Push the last page inputs if any
		if (currentPageInputs.length > 0) {
			groupedInputs.push(currentPageInputs);
		}

		return groupedInputs;
	} catch (error) {
		return [[]];
	}
};
