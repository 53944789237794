import { useCountDiariesQuery } from '@/features/diaries/api/countDiaries';
import { useDashboardDiariesQuery } from '@/features/diaries/api/getDiaries';
import type { DashboardSearchT } from '@/routes/_app/dashboard';
import { useSearch } from '@tanstack/react-router';

export function useRefetchDashboardData() {
	const search = useSearch({ strict: false }) as DashboardSearchT;
	const { refetch: refetchDiaries } = useDashboardDiariesQuery(search);
	const { refetch: refetchCount } = useCountDiariesQuery(search);

	const refetchDashboardData = () => {
		console.log('refetching dashboard data');
		refetchDiaries();
		refetchCount();
	};

	return refetchDashboardData;
}
