const NoKnives = () => {
    return (
        <svg
            className="w-full h-full"
            width="100"
            height="100"
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M66.6538 36.7834L69.3693 40.4494" stroke="black" />
            <path
                d="M29.5652 64.2567L54.2812 45.9484L59.0283 52.3568C59.0312 52.3608 59.0325 52.3648 59.0289 52.3682C58.8992 52.4942 55.974 55.291 47.815 59.2545C39.7478 63.1733 30.2074 64.2091 29.5699 64.2748C29.5598 64.2758 29.557 64.2628 29.5652 64.2567Z"
                stroke="black"
            />
            <path
                d="M54.2811 45.9484L68.1413 35.6815C68.5851 35.3528 69.2113 35.446 69.5401 35.8898L71.3462 38.328C71.6968 38.8015 71.5643 39.4739 71.0602 39.7789L56.3177 48.6978L54.2811 45.9484Z"
                stroke="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M68.8563 70.9777C58.0594 80.6731 41.4381 80.329 31.0546 69.9454C20.671 59.5619 20.3269 42.9406 30.0223 32.1437L68.8563 70.9777ZM71.6339 68.0984L32.9016 29.3661C43.7057 20.3482 59.8019 20.911 69.9454 31.0546C80.089 41.1981 80.6518 57.2943 71.6339 68.0984ZM72.7739 72.7739C60.4723 85.0754 40.5277 85.0754 28.2261 72.7739C15.9246 60.4723 15.9246 40.5276 28.2261 28.2261C40.5277 15.9246 60.4723 15.9246 72.7739 28.2261C85.0754 40.5276 85.0754 60.4723 72.7739 72.7739Z"
                fill="#ffffff"
            />
            <path
                d="M68.8563 70.9777L69.1904 71.3497L69.583 70.9972L69.2099 70.6241L68.8563 70.9777ZM30.0223 32.1437L30.3759 31.7901L30.0028 31.417L29.6503 31.8096L30.0223 32.1437ZM32.9016 29.3661L32.5812 28.9822L32.1612 29.3328L32.548 29.7196L32.9016 29.3661ZM71.6339 68.0984L71.2804 68.4519L71.6672 68.8388L72.0178 68.4188L71.6339 68.0984ZM30.701 70.299C41.2735 80.8714 58.1968 81.2216 69.1904 71.3497L68.5223 70.6056C57.922 80.1245 41.6028 79.7865 31.4081 69.5919L30.701 70.299ZM29.6503 31.8096C19.7783 42.8032 20.1286 59.7265 30.701 70.299L31.4081 69.5919C21.2135 59.3972 20.8755 43.078 30.3944 32.4777L29.6503 31.8096ZM69.2099 70.6241L30.3759 31.7901L29.6688 32.4972L68.5028 71.3312L69.2099 70.6241ZM32.548 29.7196L71.2804 68.4519L71.9875 67.7448L33.2552 29.0125L32.548 29.7196ZM70.299 30.701C59.9708 20.3729 43.5822 19.8 32.5812 28.9822L33.222 29.7499C43.8293 20.8964 59.633 21.4492 69.5919 31.4081L70.299 30.701ZM72.0178 68.4188C81.1999 57.4178 80.6271 41.0292 70.299 30.701L69.5919 31.4081C79.5508 41.367 80.1036 57.1707 71.2501 67.778L72.0178 68.4188ZM27.8726 73.1274C40.3694 85.6242 60.6306 85.6242 73.1274 73.1274L72.4203 72.4203C60.3141 84.5266 40.6859 84.5266 28.5797 72.4203L27.8726 73.1274ZM27.8726 27.8726C15.3758 40.3694 15.3758 60.6306 27.8726 73.1274L28.5797 72.4203C16.4734 60.3141 16.4734 40.6859 28.5797 28.5797L27.8726 27.8726ZM73.1274 27.8726C60.6306 15.3758 40.3694 15.3758 27.8726 27.8726L28.5797 28.5797C40.6859 16.4734 60.3141 16.4734 72.4203 28.5797L73.1274 27.8726ZM73.1274 73.1274C85.6242 60.6306 85.6242 40.3694 73.1274 27.8726L72.4203 28.5797C84.5266 40.6859 84.5266 60.3141 72.4203 72.4203L73.1274 73.1274Z"
                fill="black"
            />
        </svg>
    );
};

export default NoKnives;
