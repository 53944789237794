import React from 'react';

const EmailConfirmed = () => {
    return (
        <svg width="71" height="70" viewBox="0 0 71 70" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="70" height="70" fill="white" fillOpacity="0.01" />
            <rect x="6" y="16" width="57" height="37" rx="4" fill="white" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M58.4893 17.4174H10.7903C9.57295 17.4174 8.58609 18.4042 8.58609 19.6216V49.3784C8.58609 50.5957 9.57295 51.5826 10.7903 51.5826H58.4893C59.7066 51.5826 60.6935 50.5957 60.6935 49.3784V19.6216C60.6935 18.4042 59.7066 17.4174 58.4893 17.4174ZM10.7903 14.1111C7.74693 14.1111 5.27979 16.5782 5.27979 19.6216V49.3784C5.27979 52.4217 7.74692 54.8889 10.7903 54.8889H58.4893C61.5326 54.8889 63.9998 52.4217 63.9998 49.3784V19.6216C63.9998 16.5782 61.5326 14.1111 58.4893 14.1111H10.7903Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.1425 21.2722C12.6918 20.5582 13.7159 20.4246 14.4299 20.9738L34.6398 36.5199L54.8498 20.9738C55.5638 20.4246 56.5879 20.5582 57.1371 21.2722C57.6864 21.9862 57.5528 23.0103 56.8388 23.5595L41.4257 35.4158L56.7332 45.3656C57.4885 45.8565 57.7028 46.8668 57.2118 47.6221C56.7209 48.3774 55.7106 48.5917 54.9553 48.1008L38.6842 37.5246L36.6289 39.1057C35.4562 40.0077 33.8234 40.0077 32.6508 39.1057L30.5956 37.5247L14.3246 48.1008C13.5693 48.5917 12.5591 48.3774 12.0681 47.6221C11.5772 46.8668 11.7915 45.8565 12.5468 45.3656L27.8541 35.4158L12.4409 23.5595C11.7269 23.0103 11.5933 21.9862 12.1425 21.2722Z"
                fill="black"
            />
            <rect width="70" height="70" fill="white" fillOpacity="0.01" />
            <path
                d="M70.0476 46.0238C70.0476 53.7689 63.7689 60.0476 56.0238 60.0476C48.2787 60.0476 42 53.7689 42 46.0238C42 38.2787 48.2787 32 56.0238 32C63.7689 32 70.0476 38.2787 70.0476 46.0238Z"
                fill="#65CBA3"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M56.0238 57.0476C62.1121 57.0476 67.0476 52.1121 67.0476 46.0238C67.0476 39.9355 62.1121 35 56.0238 35C49.9355 35 45 39.9355 45 46.0238C45 52.1121 49.9355 57.0476 56.0238 57.0476ZM56.0238 60.0476C63.7689 60.0476 70.0476 53.7689 70.0476 46.0238C70.0476 38.2787 63.7689 32 56.0238 32C48.2787 32 42 38.2787 42 46.0238C42 53.7689 48.2787 60.0476 56.0238 60.0476Z"
                fill="black"
            />
            <rect
                x="50.689"
                y="46.4763"
                width="6.32143"
                height="2.80952"
                rx="1"
                transform="rotate(45 50.689 46.4763)"
                fill="black"
            />
            <rect
                x="63.9688"
                y="42.1414"
                width="14.8073"
                height="2.80952"
                rx="1"
                transform="rotate(135 63.9688 42.1414)"
                fill="black"
            />
        </svg>
    );
};

export default EmailConfirmed;
