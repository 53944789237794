import { supabaseClient } from '@/lib/supabase';
import { queryOptions, useSuspenseQuery } from '@tanstack/react-query';

export async function selectEntryValuesByFormId(formId: string) {
	const { data, error } = await supabaseClient
		.from('entry_values_view')
		.select('*, inputs(*, pages(*, forms(*)))')
		.eq('inputs.pages.forms.form_id', formId);
	if (error) throw error;
	return data;
}

export const createEntryValuesViewKey = (formId: string) => ['entry-values', { form_id: formId }];

export const entryValuesViewByFormIdQueryOptions = (formId: string) => {
	return queryOptions({
		queryKey: createEntryValuesViewKey(formId),
		queryFn: () => selectEntryValuesByFormId(formId),
	});
};

export const useEntryValuesViewByformId = (formId: string) => {
	return useSuspenseQuery(entryValuesViewByFormIdQueryOptions(formId));
};
