import { components as c } from '@/components';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useNav } from '@/utils/nav';
import { createFileRoute } from '@tanstack/react-router';
import { Mail, QrCode } from 'lucide-react';
import QrInviteForm from '@/features/dub/components/QrInviteForm';
import InviteForm from '@/features/invite/components/InviteForm';
import { validateSignupSearch } from '../_auth/signup';

const InvitePage = () => {
	const search = Route.useSearch();
	useNav([{ route: { to: '/invite' }, label: 'Invite' }]);

	return (
		<div className="md-width">
			<c.cc.Title words="Invite" />
			<Tabs defaultValue="email">
				<TabsList className="flex justify-center mx-auto w-fit">
					<TabsTrigger className="flex items-center gap-2 text-md" value="email">
						<Mail /> Email
					</TabsTrigger>
					<TabsTrigger className="flex items-center gap-2 text-md" value="qr-code">
						<QrCode /> QR Code
					</TabsTrigger>
				</TabsList>
				<TabsContent value="email">
					<InviteForm />
				</TabsContent>
				<TabsContent value="qr-code">
					<QrInviteForm search={search} showFields={{ organisations: true, roles: true }} />
				</TabsContent>
			</Tabs>
		</div>
	);
};


export const Route = createFileRoute('/_app/invite')({
	component: InvitePage,
	validateSearch: validateSignupSearch
});
