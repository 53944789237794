import { ui } from '@/components/ui';
import { Reorder } from 'framer-motion';
import { Edit } from 'lucide-react';

import { inputComponents as ic } from '.';
import { commonComponents as cc } from '../../../components/common';
import type { PageViewT } from '../../../types/database/diaries';
import { useFormStore } from '../hooks/useFormStore';

interface Props {
	pages: PageViewT[];
	inputId: string;
	pageInputId: string;
}
export type AnyInputT = string | string[] | number | number;

const OrderInputs = (props: Props) => {
	const inputs = useFormStore.getState().getFlattenedPageInputs();
	// const [order, setOrder] = React.useState<PageOrPageInputT[]>(inputs);

	const formStore = useFormStore();

	return (
		<div className="mb-4 lg:min-w-sm">
			<div className="flex flex-row justify-between items-center">
				<cc.Subtitle words="Page Order" />
				<ui.Button className="flex mx-0" onClick={() => formStore.upsertPage({ form_id: 123 })} variant="link">
					Add Page
				</ui.Button>
			</div>
			<Reorder.Group
				className="flex flex-col gap-y-2"
				axis="y"
				values={inputs}
				onReorder={formStore.setFlattenedPageInputs}
			>
				{inputs?.map((pi) => (
					<Reorder.Item className="px-2 pb-2" key={pi.input_id} value={pi}>
						{pi.type === 'page' ? (
							<div className="flex flex-row justify-between items-center gap-2 w-fit">
								<h4 className="font-semibold text-md text-slate-600">{pi.page_label ?? 'No Page Label'}</h4>
								<ui.Button className="w-8 h-8" size="icon" variant="ghost">
									<Edit size={20} />
								</ui.Button>
							</div>
						) : (
							<ic.EditInputCard input={pi.inputs} label={pi.page_input_label ?? pi.inputs?.input_label} />
						)}
					</Reorder.Item>
				))}
			</Reorder.Group>
		</div>
	);
};

export default OrderInputs;
