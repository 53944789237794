import type { EntryViewT } from '@/types/database/diaries';
import useExtractTracker from './useExtractTracker';

export function extractEntryById(entriesView: EntryViewT[], entryId: string): EntryViewT | undefined {
	return entriesView?.find((m) => m.entry_id === entryId);
}

const useExtractEntry = (diaryId: string, trackerId: string, entryId: string) => {
	const tracker = useExtractTracker(diaryId, trackerId);
	const entry = extractEntryById(tracker?.entries_view ?? [], entryId);

	return entry;
};

export default useExtractEntry;
