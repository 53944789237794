interface Props {
    fill?: string;
}
const Deleted = ({ fill }: Props) => {
    return (
        <svg
            className="w-full h-full"
            width="50"
            height="50"
            viewBox="0 0 27 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.49219 4.85156H9.28125C9.39727 4.85156 9.49219 4.75664 9.49219 4.64062V4.85156H17.5078V4.64062C17.5078 4.75664 17.6027 4.85156 17.7188 4.85156H17.5078V6.75H19.4062V4.64062C19.4062 3.70986 18.6495 2.95312 17.7188 2.95312H9.28125C8.35049 2.95312 7.59375 3.70986 7.59375 4.64062V6.75H9.49219V4.85156ZM22.7812 6.75H4.21875C3.75205 6.75 3.375 7.12705 3.375 7.59375V8.4375C3.375 8.55352 3.46992 8.64844 3.58594 8.64844H5.17852L5.82979 22.4385C5.87197 23.3376 6.61553 24.0469 7.51465 24.0469H19.4854C20.3871 24.0469 21.128 23.3402 21.1702 22.4385L21.8215 8.64844H23.4141C23.5301 8.64844 23.625 8.55352 23.625 8.4375V7.59375C23.625 7.12705 23.2479 6.75 22.7812 6.75ZM19.2823 22.1484H7.71768L7.07959 8.64844H19.9204L19.2823 22.1484Z"
                fill={fill ? fill : 'currentColor'}
            />
        </svg>
    );
};

export default Deleted;
