import { createFileRoute } from "@tanstack/react-router";
import { commonComponents as cc } from "@/components/common";
import { Card, CardContent } from "../../../components/ui/card";

const PrintQrCode = () => {
    
    const search = Route.useSearch();

    return (
        <section className="md-width">
            <cc.Title words={search.title} description={search.description??''} />
            <Card className='my-8 w-full'>
                <CardContent className="flex justify-center items-center p-8">
                    <img src={search.qrCode} alt="QR Code" />
                </CardContent>
            </Card>
        </section>
    );
};

export type QrCodeSearch = {
    title: string;
    description: string;
    qrCode: string;
};

export const validateQrCodeSearch = (
    search: Record<string, unknown>,
): QrCodeSearch => {
    return {
        title: String(search.title),
        description: String(search.description),
        qrCode: String(search.qrCode),
    };
};

export const Route = createFileRoute("/preview/invite/qr-code")({
    component: PrintQrCode,
    validateSearch: validateQrCodeSearch,
});
