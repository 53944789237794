import { commonComponents as cc } from '@/components/common';
import { ui } from '@/components/ui';
import { eventByIdQueryOptions, useEventQuery } from '@/features/events/api/selectEventById';
import { selectProfileByIdQueryOptions, useProfileByIdQuery } from '@/features/profile/api/selectProfile';
// TODO - Get hospital number onto this form?
import { hooks as h } from '@/hooks';
import { createFileRoute } from '@tanstack/react-router';
const RadiologyReportPage = () => {
	const { profile, event } = Route.useLoaderData();
	h.usePrint(!!profile && !!event);
	// console.log(event);
	return (
		<section className="p-8 flex flex-col gap-4">
			<cc.Title words="Radiology Report" />
			<ui.Card>
				<ui.CardHeader>
					<ui.CardTitle>Patient Details</ui.CardTitle>
				</ui.CardHeader>
				<ui.CardContent className="text-md space-2">
					<p>{profile.name}</p>
					<p>{profile.date_of_birth}</p>
					<p>{profile.email}</p>
					<p>{profile.phone}</p>
				</ui.CardContent>
			</ui.Card>
			<ui.Card>
				<ui.CardHeader>
					<ui.CardTitle>Injection Details</ui.CardTitle>
				</ui.CardHeader>
				<ui.CardContent className="text-md space-2">
					{event?.injections_view?.map((i) => (
						<div key={i.injection_id}>
							{i.injection_details_view?.map((id) => (
								<div key={id.injection_detail_id}>
									<p>{id.injection_location}</p>
									<p>{id.injection_content}</p>
								</div>
							))}
						</div>
					))}
				</ui.CardContent>
			</ui.Card>
		</section>
	);
};

export const Route = createFileRoute('/preview/radiology-report/patient/$patientId/event/$eventId/')({
	component: RadiologyReportPage,
	loader: async ({ context, params }) => {
		const profile = await context?.queryClient.ensureQueryData(selectProfileByIdQueryOptions(params.patientId));
		const event = await context?.queryClient.ensureQueryData(eventByIdQueryOptions(params.eventId));

		return { profile, event };
	},
});
