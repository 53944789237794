import { ui } from '@/components/ui';
import { flattenedInputs as fi } from '@/definitions/inputs';
import { Route as InputInputIdRoute } from '@/routes/_app/builder/diary-templates/$diaryTemplateId/input.$inputId/page.$pageId';
import { InputT } from '@/types/database/diaries';
import { useNavigate } from '@tanstack/react-router';

import InputParameter from './InputParameter';

interface Props {
	input: InputT;
}

const InputSettingsDrawer = ({ input }: Props) => {
	const navigate = useNavigate({ from: InputInputIdRoute.fullPath });

	const setInput = (key: string, val: any) => {
		const newInput = { ...input, [key]: val };
		navigate({ search: { ...input, input: { ...newInput } } });
	};

	return (
		<ui.Card key={input?.input_id} className="">
			<ui.CardHeader className="flex flex-row items-center justify-between pb-2"></ui.CardHeader>
			<ui.CardContent className="flex flex-col gap-4">
				<ui.Select onValueChange={(val) => setInput('input_key', val)} value={input?.input_key}>
					<ui.SelectTrigger>
						<ui.SelectValue placeholder="Select" />
					</ui.SelectTrigger>
					<ui.SelectContent>
						{Object.entries(fi)?.map(([key, val]) => (
							<ui.SelectItem key={key} value={key}>
								<span className="flex flex-row gap-x-2">
									{val.icon}
									{val.name}
								</span>
							</ui.SelectItem>
						))}
					</ui.SelectContent>
				</ui.Select>

				{/* Label */}
				<InputParameter inputTypes={[input?.input_key]} inputType={input?.input_key}>
					<ui.Label>Label</ui.Label>
					<ui.Input value={input?.label} type="text" onChange={(e) => setInput('label', e.target.value)} />
				</InputParameter>

				{/* Max Length */}
				<InputParameter inputTypes={['long-text', 'short-text']} inputType={input?.input_key}>
					<ui.Label>Max Length</ui.Label>
					<ui.Input
						value={input?.max_length}
						type="number"
						onChange={(e) => setInput('max_length', Number(e.target.value))}
					/>
				</InputParameter>

				{/* Min Value */}
				<InputParameter inputTypes={['number', 'slider']} inputType={input?.input_key}>
					<ui.Label>Min Value</ui.Label>
					<ui.Input
						value={input?.min_value}
						type="number"
						onChange={(e) => setInput('min_value', Number(e.target.value))}
					/>
				</InputParameter>

				{/* Max Value */}
				<InputParameter inputTypes={['number', 'slider']} inputType={input?.input_key}>
					<ui.Label>Max Value</ui.Label>
					<ui.Input
						value={input?.max_value}
						type="number"
						onChange={(e) => setInput('max_value', Number(e.target.value))}
					/>
				</InputParameter>

				{/* Show Slider Face */}
				<InputParameter inputTypes={['slider']} inputType={input?.input_key}>
					<div className="flex w-full flex-row items-center justify-between">
						<ui.Label>Show Slider Face</ui.Label>
						<ui.Switch
							color="indigo"
							checked={input?.show_slider_face ?? false}
							onCheckedChange={() => setInput('show_slider_face', !input?.show_slider_face)}
						/>
					</div>
				</InputParameter>
			</ui.CardContent>
			<ui.CardFooter>
				<ui.Button size="lg">Save</ui.Button>
			</ui.CardFooter>
		</ui.Card>
	);
};

export default InputSettingsDrawer;
