interface Props {
    fill?: string;
    stroke: string;
    active?: string;
}

const Results = ({ fill, stroke, active }: Props) => {
    return (
        <svg
            className="w-full h-full"
            width="70"
            height="70"
            viewBox="0 0 69 70"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect x="14" y="15" width="40" height="40" rx="5" stroke={stroke} strokeWidth="3" fill={fill} />
            <rect x="20" y="33" width="7" height="17" rx="1" fill={active ? active : stroke} />
            <rect x="31" y="24" width="7" height="26" rx="1" fill={active ? active : stroke} />
            <rect x="42" y="39" width="7" height="11" rx="1" fill={active ? active : stroke} />
        </svg>
    );
};

export default Results;
