import { supabaseClient } from '@/lib/supabase';
import { queryOptions, useQuery } from '@tanstack/react-query';

export async function getFootDiaries(patientId: string) {
	return await supabaseClient
		.from('foot_diaries')
		.select('*')
		.eq('patient_id', patientId)
		.throwOnError()
		.then((result) => result.data);
}

export const footDiariesQueryOptions = (patientId: string) =>
	queryOptions({
		queryKey: ['footDiaries', { patientId: patientId }],
		queryFn: async () => await getFootDiaries(patientId),
		retry: 3,
	});

export const useFootDiariesQuery = (patientId: string) => {
	return useQuery(footDiariesQueryOptions(patientId));
};
