import { commonComponents as cc } from '@/components/common';
import { diaryTemplatesQueryOptions } from '@/features/builder/api/selectDiaryTemplates';
import { useFavouriteDiariesQuery } from '@/features/builder/api/selectFavouriteDiaries';
import DiaryTemplates from '@/features/templates/components/DiaryTemplates';
import { hooks as h } from '@/hooks';
import { useAuth } from '@/utils/auth';
import { Link, createFileRoute } from '@tanstack/react-router';

import { buttonVariants } from '../../components/ui/button';
import OnboardingStepper from '../../features/onboarding/components/OnboardingStepper';
import { cn } from '../../lib/utils';

const NewDiary = () => {
	const auth = useAuth();

	const { templates } = Route.useLoaderData();
	const { data: favouriteDiaries } = useFavouriteDiariesQuery();

	h.useNav([{ route: { to: '/onboarding/organisation', params: {}, search: {} }, label: 'Organisation' }]);

	return (
		<div className="md-width">
			<cc.Title
				words="Favourite Diary Templates"
				description="When you create a diary you can either use a template like these, on create your own template."
			/>
			<OnboardingStepper currentRoute={Route.to} />

			<DiaryTemplates
				link={true}
				patientId={auth.id}
				templates={templates ?? []}
				createable={false}
				favouriteDiaryTemplates={favouriteDiaries ?? []}
			/>
			<br className="h-4" />
			<Link className={cn(buttonVariants({}))} to="/onboarding/complete">
				Next
			</Link>
		</div>
	);
};

export const Route = createFileRoute('/onboarding/favourite-diary-templates')({
	component: NewDiary,
	errorComponent: () => <p>New Diary Error</p>,
	loader: async ({ context }) => {
		const templates = await context?.queryClient.ensureQueryData(diaryTemplatesQueryOptions());
		return { templates };
	},
});
