import { Control } from 'react-hook-form';

import { ui } from '../ui';

interface Recipient {
	user_id: string;
	recipient_key: 'TO' | 'CC' | 'BCC';
}

interface Props {
	control: Control;
	recipients: Recipient[];
	label: string;
	disabled: boolean;
	type: 'email' | 'text';
}

const Recipients: React.FC<Props> = ({ type = 'email', disabled = true, label, control }) => {
	return (
		<>
			{control._formValues.action_recipients
				.filter((r) => r.recipient_key === label.toUpperCase())
				.map((recipient, index) => (
					<ui.FormField
						key={index}
						control={control}
						name={`action_recipients[${index}].user_id`}
						data-cy={`action_recipients[${index}].user_id`}
						render={({ field }) => (
							<ui.FormItem className="flex items-center space-y-0">
								<ui.FormLabel className="w-24 text-sm">{label}</ui.FormLabel>
								<ui.FormControl>
									<ui.Input
										className="h-11 bg-white text-sm"
										disabled={disabled}
										type={type}
										value={recipient.email}
										// {...field}
									/>
								</ui.FormControl>
								<ui.FormMessage />
							</ui.FormItem>
						)}
					/>
				))}
		</>
	);
};

export default Recipients;
