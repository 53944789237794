import type { Organisation } from '../../../../types/supabaseTypes';

export interface UsersOrganisations {
    user_id: string;
    organisation_id: string;
    role: string;
    organisations: Organisation;
}

export interface TransformedUserOrganisationRole {
    role: string;
    organisation_name: string;
    user_id: string;
    organisation_id: string;
}

export function flattenOrganisations(data: UsersOrganisations[]): TransformedUserOrganisationRole[] | undefined {
    try {
        const organisations = data?.map((org) => {
            return {
                role: org.role,
                organisation_name: org.organisations.organisation_name,
                user_id: org.user_id,
                organisation_id: org.organisations.organisation_id,
            };
        });
        return organisations;
    } catch (error) {
        console.log(error);
    }
}
