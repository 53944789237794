import { diaryByIdQueryOptions, useDiaryByIdQuery } from '@/features/diary/api/getDiaryById';
import { extractEventById } from '@/features/diary/services/extractEventById';
import EditEvent from '@/features/events/components/EditEvent';
import { diariesBreadcrumb, editBreadcrumb } from '@/features/navigation/services/breadcrumbRoutes';
import { hooks as h } from '@/hooks';
import { createFileRoute } from '@tanstack/react-router';
const EditEventPage = () => {
	const { diaryId, eventId } = Route.useParams();
	const { data: diary } = useDiaryByIdQuery(diaryId);
	const event = extractEventById(diary, eventId);

	h.useNav([diariesBreadcrumb, editBreadcrumb]);

	return <EditEvent event={event} />;
};

export const Route = createFileRoute('/_app/diary/$diaryId/edit/event/$eventId/')({
	component: EditEventPage,
	loader: async ({ context, params }) => {
		await context?.queryClient.ensureQueryData(diaryByIdQueryOptions(params.diaryId));
	},
});
