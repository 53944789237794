// TrackerStartDate.tsx
import DateTimeForm from '@/components/form/DateTimeForm';
import useExtractTracker from '@/features/trackers/hooks/useExtractTracker';
import { useTrackerUpdate } from '@/features/trackers/hooks/useTrackerUpdate';

interface Props {
	diaryId: string;
	trackerId: string;
}

const TrackerStartDateForm = ({ diaryId, trackerId }: Props) => {
	const tracker = useExtractTracker(diaryId, trackerId);
	const { updateStartDate } = useTrackerUpdate(diaryId, trackerId);

	return <DateTimeForm initialDate={tracker?.start_date} onSubmit={updateStartDate} submitButtonText="Save" />;
};

export default TrackerStartDateForm;
