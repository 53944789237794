import { commonComponents as cc } from '@/components/common';
import { footDiaryQueryOptions } from '@/features/foot-watch/api/getFootDiary';
import { profileComponents as pc } from '@/features/profile/components';
import { useSuspenseQuery } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import { Suspense } from 'react';

const Edit = () => {
	const { diaryId } = Route.useParams();
	const { data: footDiary } = useSuspenseQuery(footDiaryQueryOptions(diaryId));

	return (
		<section className="mx-auto my-8 max-w-md">
			<cc.Title words="Edit Diary" />
			<Suspense>
				<cc.Group title="Diary Details">
					<pc.ProfileRow
						roles={['patient', 'clinician']}
						label="Name"
						info={footDiary.diary_name}
						detail="name"
						link={true}
						tabIndex={0}
					/>
				</cc.Group>
			</Suspense>
		</section>
	);
};

export const Route = createFileRoute('/_app/foot-watch/$diaryId/edit/diary')({
	component: Edit,
});
