import { createFileRoute } from '@tanstack/react-router';

import { footDiaryQueryOptions } from '../../../features/foot-watch/api/getFootDiary';

export const Route = createFileRoute('/_app/foot-watch/$diaryId')({
	beforeLoad: ({ context }) => {
		return {
			auth: context.auth,
		};
	},
	loader: async ({ context, params }) => {
		context?.queryClient.ensureQueryData(footDiaryQueryOptions(params.diaryId));
	},
});
