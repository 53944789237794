export const Four = () => {
	return (
		<svg
			role="img"
			aria-label="Pain Four"
			width="50"
			height="50"
			viewBox="0 0 50 50"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect x="1" y="1" width="48" height="48" rx="24" fill="white" stroke="black" strokeWidth="2" />
			<rect x="11" y="16" width="8" height="8" rx="4" fill="#0E0E0E" />
			<rect x="31" y="16" width="8" height="8" rx="4" fill="#0E0E0E" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M16.9665 11.7438C17.1082 12.2776 16.7902 12.8252 16.2564 12.9668L16.1599 12.9924C14.1424 13.5277 12.2027 14.3174 10.3871 15.3416C9.90606 15.6129 9.29068 15.4698 8.99999 15.0002V15.0002C8.70929 14.5307 8.85341 13.9126 9.3336 13.6397C11.3173 12.5127 13.4391 11.6451 15.647 11.0593L15.7436 11.0337C16.2774 10.8921 16.8249 11.21 16.9665 11.7438Z"
				fill="black"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M33.0335 11.7436C32.8918 12.2774 33.2098 12.8249 33.7436 12.9666L33.8401 12.9922C35.8576 13.5274 37.7973 14.3171 39.6129 15.3413C40.0939 15.6127 40.7093 15.4696 41 15V15C41.2907 14.5304 41.1466 13.9123 40.6664 13.6395C38.6827 12.5124 36.5609 11.6448 34.353 11.0591L34.2564 11.0334C33.7226 10.8918 33.1751 11.2098 33.0335 11.7436Z"
				fill="black"
			/>
			<path
				d="M28.2727 35.5L32.2149 35.5264C32.855 35.5307 33.4437 35.8776 33.7575 36.4355C33.8674 36.6309 33.7296 36.8731 33.5054 36.8784L28.375 37H22L16.495 36.8777C16.2708 36.8727 16.1328 36.6306 16.2427 36.4351C16.5564 35.8775 17.1449 35.5308 17.7846 35.5268L22 35.5L28.2727 35.5Z"
				fill="black"
				stroke="black"
			/>
		</svg>
	);
};

export default Four;
