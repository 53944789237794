import { colors } from '../colors';

interface Props {
	className: string;
}
const Compass = (props: Props) => {
	return (
		<svg
			role="img"
			className={props.className}
			aria-label="Compass Image"
			viewBox="0 0 72 72"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g id="line-supplement">
				<polyline points="33.2 33.2 48 24 38.8 38.8" />
			</g>
			<g id="color">
				<circle cx="36" cy="36" r="24" fill={colors['yellow-300']} />
				<path fill={colors['amber-300']} d="M53,19A24.0417,24.0417,0,0,1,36,60a24.302,24.302,0,0,1-17-7" />
				<polyline fill="#fff" points="33.2 33.2 24 48 38.8 38.8" />
				<polyline fill={colors['red-400']} points="33.2 33.2 48 24 38.8 38.8" />
			</g>
			<g id="line">
				<circle
					cx="36"
					cy="36"
					r="24"
					fill="none"
					stroke="#000"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
				/>
				<polyline
					fill="none"
					stroke="#000"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
					points="33.2 33.2 24 48 38.8 38.8"
				/>
				<polyline
					fill="none"
					stroke="#000"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
					points="33.2 33.2 48 24 38.8 38.8"
				/>
				<line
					x1="36"
					x2="36"
					y1="21"
					y2="16"
					fill="none"
					stroke="#000"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
				/>
				<line
					x1="36"
					x2="36"
					y1="56"
					y2="51"
					fill="none"
					stroke="#000"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
				/>
				<line
					x1="51"
					x2="56"
					y1="36"
					y2="36"
					fill="none"
					stroke="#000"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
				/>
				<line
					x1="16"
					x2="21"
					y1="36"
					y2="36"
					fill="none"
					stroke="#000"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
				/>
			</g>
		</svg>
	);
};

export default Compass;
