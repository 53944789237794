const Filter = () => {
    return (
        <svg
            className="h-full w-full"
            width="40"
            height="40"
            viewBox="3 3 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M25 8H5L13 17.46V24L17 26V17.46L25 8Z"
                stroke="#111111"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default Filter;
