import { formComponents as fc } from '.';
import { ui } from '../ui';
import type { OptionT } from './Select';

interface Props {
	name: string;
	label: string;
	disabled?: boolean;
	options: OptionT[];
}

const SelectRecipient: React.FC<Props> = ({ name, disabled, label, options }) => {
	return (
		<>
			<ui.FormLabel className="w-24 text-sm">{label}</ui.FormLabel>
			<fc.Select options={options} name={name} disabled={disabled} />
		</>
	);
};

export default SelectRecipient;
