import { cn } from '@/lib/utils';
import { services as s } from '@/services';
import type { SizeT } from '@/types/zod/common';
import type { ReactNode } from 'react';
import { warningColors } from '../../../utils/warningColors';

interface Props {
	title: ReactNode;
	dateTime?: string | null;
	editButton?: ReactNode;
	editable?: boolean;
	hideDate?: boolean;
	size?: SizeT;
}
const HistoryCardTitle = ({ title, dateTime, editButton, editable, size, hideDate }: Props) => {
	return (
		<>
			<div className="flex flex-row justify-between items-center space-y-0">
				<p
					className={cn(
						'flex flex-row items-center gap-2 font-medium',
						size === 'lg' ? 'text-md' : 'text-sm',
						warningColors(!dateTime),
						hideDate && 'hidden',
					)}
				>
					{dateTime ? s.time.formatShortDateTime(dateTime) : 'No Date Specified'}
				</p>
				{editable !== false && (editButton ?? null)}
			</div>
			{title}
		</>
	);
};

export default HistoryCardTitle;
