import { diaryByIdQueryOptions, useDiaryByIdQuery } from '@/features/diary/api/getDiaryById';
import EditDiary from '@/features/diary/edit/components/EditDiary';
import { createFileRoute } from '@tanstack/react-router';

const EditDiaryPage = () => {
	const { diaryId } = Route.useParams();
	const { data: diary } = useDiaryByIdQuery(diaryId);

	if (!diary?.diary_id) return <p>No Diary</p>;

	return <EditDiary diary={diary} />;
};

export const Route = createFileRoute('/_app/diary/$diaryId/edit/')({
	component: EditDiaryPage,
	loader: async ({ context, params }) => {
		const diary = await context?.queryClient.ensureQueryData(diaryByIdQueryOptions(params.diaryId));
		return { diary };
	},
});
