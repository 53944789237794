import { Crosshair, TestTube2 } from 'lucide-react';

import type { MaybeStringT } from '../../../types/zod/common';

interface Props {
	injectionLocation?: MaybeStringT;
	injectionContent?: MaybeStringT;
}
const InjectionDetail = (props: Props) => {
	return (
		<>
			<div>
				<Crosshair size={24} />
				<span>{props.injectionLocation ?? ''}</span>
			</div>
			<div>
				<TestTube2 size={24} />
				<span>{props.injectionContent ?? ''}</span>
			</div>
		</>
	);
};

export default InjectionDetail;
