import { supabaseClient } from '@/lib/supabase';
import { queryOptions, useQuery } from '@tanstack/react-query';

import type { ExtendedFootDiary } from '../../../types/supabaseTypes';

export async function getFootDiary(diaryId: string): Promise<ExtendedFootDiary> {
	return await supabaseClient.functions
		.invoke('get-foot-diary', {
			body: { diaryId: diaryId },
		})
		.then((result) => result.data as ExtendedFootDiary);
}
export const footDiaryQueryOptions = (diaryId: string) =>
	queryOptions({
		queryKey: ['footDiary', { diaryId: diaryId }],
		queryFn: async () => await getFootDiary(diaryId),
		retry: 3,
	});

export const useFootDiaryQuery = (diaryId: string) => {
	return useQuery(footDiaryQueryOptions(diaryId));
};
