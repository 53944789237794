import React from 'react';

const Add = () => {
    return (
        <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="21" cy="21" r="21" fill="#403FAB" />
            <rect x="18" y="9" width="6" height="24" rx="1" fill="white" />
            <rect x="33" y="18" width="6" height="24" rx="1" transform="rotate(90 33 18)" fill="white" />
        </svg>
    );
};

export default Add;
