interface Props {
    stroke?: string;
    strokeWidth?: number;
    size?: number;
}

const Install = ({ stroke, strokeWidth, size }: Props) => {
    return (
        <svg
            className="w-full h-full"
            width={size ? size : '50'}
            height={size ? size : '50'}
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M25 30V10M25 30L18.2 23.3333M25 30L31.8 23.3333M8 33.3333L9.0557 37.475C9.23956 38.1961 9.664 38.8363 10.2616 39.2938C10.8591 39.7513 11.5955 39.9999 12.3537 40H37.6463C38.4045 39.9999 39.1409 39.7513 39.7384 39.2938C40.336 38.8363 40.7604 38.1961 40.9443 37.475L42 33.3333"
                stroke={stroke ? stroke : 'black'}
                strokeWidth={strokeWidth ? strokeWidth : '3'}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default Install;
