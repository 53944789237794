import { supabaseClient } from '@/lib/supabase';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';
import { toast } from 'sonner';
import { useAuth } from '../../../utils/auth';

const deleteDiary = async (diaryId: string) => {
	const { data, error } = await supabaseClient.from('diaries').delete().eq('diary_id', diaryId);
	if (error) throw error;
	return data;
};

export const useDeleteDiaryMutation = (diaryId: string) => {
	const auth = useAuth();
	const queryClient = useQueryClient();
	const navigate = useNavigate();
	return useMutation({
		mutationKey: ['delete-diary'],
		mutationFn: async (diaryId: string) => {
			return await deleteDiary(diaryId);
		},
		onError: (error) => {
			console.error('An error occurred:', error);
			toast.error(`${error.message}`);
		},
		onSuccess: () => {
			toast.success('Diary deleted successfully');
			auth.roles?.isClinician
				? navigate({ to: '/dashboard', params: {}, search: {} })
				: navigate({ to: '/diaries', params: {}, search: {} });
			queryClient.setQueryData(['diary', { diary_id: diaryId }], null);
		},
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey: ['diaries'] });
		},
	});
};
export default useDeleteDiaryMutation;
