import { services as s } from '@/services';

import AnimateInView from '../../../../components/ui/animate-in-view';
import GetInTouch from '../../components/GetInTouch';
import Section from '../../components/Section';
import Instructions from '../assets/foot-watch-instructions.png';
import Protection from '../assets/foot-watch-protection.png';
import Cover from '../assets/foot watch cover.png';
import Flow from '../assets/foot watch flow.png';
import Topfoot from '../assets/foot watch photo.png';
import SoleAndTopfoot from '../assets/foot watch upload.png';
import DfuProblem from './DfuProblem';

const Footwatch = () => {
	return (
		<div>
			<Section row={true}>
				<div className="flex flex-col md:p-0 items-center md:items-start md:justify-start justify-center md:text-left text-center gap-y-4 md:pr-12 px-2">
					<p className="text-cyan-600 text-lg w-fit">Foot Watch App</p>
					<h3 className="font-extrabold text-3xl md:text-4xl leading-12">Diabetic Foot Ulcer Care</h3>
					<p className="text-slate-800 text-xl font-light max-w-[800px] ">
						A Tool for Collaborative Prevention and Management of Diabetic Foot Ulcers
					</p>
				</div>

				<AnimateInView>
					<img className=" rounded-lg" src={Cover} alt="footwatch cover" />
				</AnimateInView>
			</Section>
			<DfuProblem />

			<Section row={true}>
				<div className="flex flex-col md:p-0 items-center md:items-start md:justify-start justify-center md:text-left text-center gap-y-4 md:pr-12 px-2">
					<p className="text-cyan-600 text-lg w-fit">Foot Watch Guide</p>
					<h3 className="font-extrabold text-3xl md:text-4xl leading-12">How Foot Watch Works</h3>
					<p className="text-slate-800 text-xl font-light max-w-[800px] ">
						Patients capture weekly foot images from home for AI analysis or review.
					</p>
				</div>

				<AnimateInView>
					<img className="max-h-md rounded-lg" src={Instructions} alt="footwatch instructions" />
					<p className="pt-4 mx-auto text-center px-4 w-full text-lg text-slate-800 ">Follow the Instructions</p>
				</AnimateInView>

				<AnimateInView>
					<img className="max-h-md rounded-lg" src={SoleAndTopfoot} alt="footwatch sole and top foot" />
					<p className="pt-4 mx-auto text-center px-4 w-full text-lg text-slate-800 ">Upload the Photos</p>
				</AnimateInView>
				<AnimateInView>
					<img className="max-h-md rounded-lg" src={Topfoot} alt="footwatch top foot" />
					<p className="pt-4 mx-auto text-center px-4 w-full text-lg text-slate-800 ">Check the AI Analysis</p>
				</AnimateInView>
			</Section>
			<Section row={true}>
				<div className="flex flex-col md:p-0 items-center md:items-start md:justify-start justify-center md:text-left text-center gap-y-4 md:pr-12 px-2">
					<p className="text-cyan-600 text-lg w-fit">Foot Watch Pathway</p>
					<h3 className="font-extrabold text-3xl md:text-4xl leading-12">Foot Watch Patient Management</h3>
					<p className="text-slate-800 text-xl font-light max-w-[800px] ">
						Foot Watch integrates with convential NICE pathways to act as a second level of defence in between routine
						appointments.
					</p>
				</div>

				<AnimateInView>
					<img className="" src={Flow} alt="footwatch flow" />
				</AnimateInView>
			</Section>
			<Section row={true}>
				<div className="flex flex-col md:p-0 items-center md:items-start md:justify-start justify-center md:text-left text-center gap-y-4 md:pr-12 px-2">
					<p className="text-cyan-600 text-lg w-fit">Increased Monitoring</p>
					<h3 className="font-extrabold text-3xl md:text-4xl leading-12">Early Detection and Intervention</h3>
					<p className="text-slate-800 text-xl font-light max-w-[800px] ">
						Foot Watch integrates with convential NICE pathways to act as a second level of defence in between routine
						appointments.
					</p>
				</div>

				<AnimateInView>
					<img className="" src={Protection} alt="footwatch flow" />
				</AnimateInView>
			</Section>

			<Section>
				<GetInTouch />
			</Section>
		</div>
	);
};

export default Footwatch;
