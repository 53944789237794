import React from 'react';

const ExpandHistory = () => {
    return (
        <svg width="55" height="62" viewBox="0 0 55 62" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 6.73268C0 2.11304 5.00166 -0.773624 9.00167 1.53749L51.0028 25.8048C55.0006 28.1146 55.0005 33.8854 51.0028 36.1952L9.00167 60.4625C5.00167 62.7736 0 59.887 0 55.2673L0 6.73268ZM10 13.6635L10 48.3365L40.0056 31L10 13.6635Z"
                fill="black"
            />
        </svg>
    );
};

export default ExpandHistory;
