import { Card, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';

interface Props {
	title: string;
	description: string;
	caption: string;
}

const TimeBlock = ({ title, description, caption }: Props) => {
	return (
		<Card className="w-full md:w-[24%] md:min-w-[600px] md:min-h-[200px]">
			<CardHeader>
				<CardTitle className="text-lg">
					<p className="font-normal text-cyan-600 mb-2">{caption}</p>
					<h4 className="text-2xl font-semibold text-slate-900">{title}</h4>
				</CardTitle>
				<CardDescription className="text-md text-slate-600">{description}</CardDescription>
			</CardHeader>
		</Card>
	);
};

export default TimeBlock;
