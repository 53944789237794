import { components as c } from '@/components';
import { injectionComponents as ic } from '@/features/injection/components';
import useExtractInjection from '@/features/injection/hooks/useExtractInjection';
import { createFileRoute, useNavigate } from '@tanstack/react-router';

const Injection = () => {
	const { diaryId, injectionId, eventId, organisationId } = Route.useParams();
	const injection = useExtractInjection(diaryId, injectionId);
	const navigate = useNavigate();

	return (
		<>
			<c.cc.Stepper
				steps={[
					{ stage: 'past', words: 'Patient' },
					{ stage: 'past', words: 'Diary' },
					{
						stage: 'current',
						words: 'Event',
						substeps: [
							{
								stage: 'current',
								words: 'Who & When',
							},
							{
								stage: 'future',
								words: 'Where & What',
							},
						],
					},
					{ stage: 'future', words: 'Form' },
				]}
			/>

			<ic.InjectionForm
				diaryId={diaryId}
				eventId={eventId}
				organisationId={organisationId}
				injectionId={injectionId}
				injection={injection}
				onSuccess={() => navigate({ to: './details', params: (prev) => ({ ...prev }), search: {} })}
			/>
		</>
	);
};

export const Route = createFileRoute(
	'/_app/new-diary/patient/$patientId/organisation/$organisationId/diary/$diaryId/event/$eventId/injection/$injectionId/',
)({
	component: Injection,
});
