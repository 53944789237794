import { ui } from '@/components/ui';
import { useUpdateTracker } from '@/features/trackers/api/updateTracker';
import { ExtendedTrackerViewT } from '@/types/database/trackers';
import dayjs from 'dayjs';
import { PlayCircle, StopCircle } from 'lucide-react';

interface Props {
    diaryId: string;
    tracker: ExtendedTrackerViewT;
    stopWords?: string;
    startWords?: string;
}
const PlayPauseTrackerButton = ({ diaryId, tracker, stopWords, startWords }: Props) => {
    const upsertTracker = useUpdateTracker({ diaryId: diaryId });

    return (
        <>
            {tracker?.end_date === null ? (
                <ui.Button
                    size="lg"
                    className="w-full"
                    onClick={() =>
                        upsertTracker.mutate({
                            tracker_id: tracker.tracker_id!,
                            end_date: dayjs().toISOString(),
                        })
                    }
                    variant="destructive-secondary"
                >
                    <StopCircle />
                    {stopWords ?? 'Stop'}
                </ui.Button>
            ) : (
                <ui.Button
                    onClick={() =>
                        upsertTracker.mutate({
                            tracker_id: tracker.tracker_id!,
                            end_date: null,
                        })
                    }
                    size="lg"
                    className="w-full"
                    variant="secondary"
                >
                    <PlayCircle />
                    {startWords ?? 'Restart'}
                </ui.Button>
            )}
        </>
    );
};

export default PlayPauseTrackerButton;
