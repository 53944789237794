export const painWatchFAQs = [
	{
		question: 'What is the aim of the software?',
		answer:
			'Our software aims to inform Patient Initiated Follow-Up (PIFU) through a patient self-reported pain diary. By reducing unnecessary standard 6-8 week follow-ups by 90% post injection, it optimises patient follow-ups, frees clinical time, and addresses waiting list challenges.',
	},
	{
		question: 'How is a follow-up initiated?',
		answer:
			"Follow-ups can  be initiated by clinicians who notice pain spikes or suboptimal treatment. Patients can also trigger follow-ups by commenting on their pain score, indicating to the clinician that they would like a follow-up. These follow-ups can be arranged through the clinician's secretary.",
	},
	{
		question: 'What procedures is this suitable for?',
		answer:
			"While initially designed for orthopaedic injections, Patient Watch is suitable for any procedure or treatment that benefits from an informed understanding of patients' pain fluctuations. Contact us for a custom solution tailored to your specific use case.",
	},
	{
		question: 'How long does it take to learn how to use it?',
		answer:
			'Our onboarding materials, featuring audio-narrated videos, are easy to follow and take approximately 10 minutes to learn how to use the platform. If you encounter any difficulties, our team is always available to provide assistance.',
	},
	{
		question: 'But won’t this take up patient clinic time to use?',
		answer:
			'While the impact on each department and clinician may vary, Patient Watch significantly reduces in-clinic follow-ups by 90%, alleviating the overall departmental burden. We recommend that each clinician dedicates one session per week to using the platform, effectively streamlining service provision.',
	},
	{
		question: 'Do you integrate with our current EPR?',
		answer:
			'Currently, we do not operate any direct integrations. Instead, we generate PDF reports of patient diaries that can be easily added to patient records. This allows for instant deployment without integration, providing immediate access to time and cost savings.',
	},
	{
		question: 'How do you ensure patient compliance?',
		answer:
			'Ensuring patient compliance is challenging due to individual circumstances. However, compliance is generally high, with patients encouraged to engage in more contact and oversight with their clinician. We provide text notifications to remind patients to log their pain, and a doctor action is available to send personalised reminders if automated SMS and email reminders are ignored.',
	},
	{
		question: 'Won’t it take a lot of time to write up the results in the patient notes?',
		answer:
			'No, we create PDF discharge summaries that are instantly downloadable for practitioners and can be sent to the patient at the click of a button. These summaries provide all the necessary information in written and graphic form, easily uploaded to EPR or GP notes like a discharge letter.',
	},
	{
		question: 'What if my patients aren’t good with technology?',
		answer:
			'No problem! Our platform is designed for simplicity, requiring just two clicks for a patient to log their pain. It is accessible on any device without the need for a downloaded app. Patients only need to click on the link emailed to them, opening our platform instantly.',
	},
	{
		question: 'Which departments have benefited?',
		answer:
			'Orthopaedics departments have experienced notable benefits, saving appointments with a remarkable 90% reduction in follow-ups using Patient Watch. Radiology departments have also seen advantages as Patient Watch efficiently collects patient outcome data, empowering radiologists to expedite and enhance their audit processes through downloadable Patient Watch data. Patient Watch plays a crucial role in gathering data to inform which injections yield the best outcomes, facilitating collaboration among all stakeholders involved in therapeutic injections and enabling future data-driven decisions by clinicians.',
	},
];
