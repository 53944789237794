import { commonComponents as cc } from '@/components/common';
import { Route as DiaryDashboardRoute } from '@/routes/_app/dashboard/diary/$diaryId';
import { Route as EditRoute } from '@/routes/_app/diary/$diaryId/edit';
import type { ExtendedProfileT } from '@/types/database/profiles';
import { useIsFetching } from '@tanstack/react-query';
import { Link } from '@tanstack/react-router';
import Spinner from '../../../../components/common/Spinner';
import { createGetDiaryKey } from '../../../diary/api/getDiaryById';

interface Props {
	diaryId: string;
	profile: ExtendedProfileT;
	editing: boolean;
}
const PatientTitle = ({ diaryId, profile, editing }: Props) => {
	return (
		<div className="flex justify-between items-center mb-4 w-full">
			<h4 className="pl-2 font-medium text-xl">{profile?.name ?? 'Unnamed Patient'}</h4>
			<cc.EditButton>
				{editing ? (
					<Link to={DiaryDashboardRoute.to} params={{ diaryId: diaryId }}>
						Done
					</Link>
				) : (
					<Link to={EditRoute.to} params={{ diaryId: diaryId }}>
						Edit
					</Link>
				)}
			</cc.EditButton>
		</div>
	);
};

export default PatientTitle;
