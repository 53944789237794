import { supabaseClient } from '@/lib/supabase';
import { queryOptions, useSuspenseQuery } from '@tanstack/react-query';

export async function selectUsageStatistics() {
	const { data, error } = await supabaseClient
		.from('usage_statistics')
		.select()
		.order('created_at', { ascending: false });

	if (error) {
		console.error('Error fetching data:', error);
		return [];
	}
	return data;
}

export const usageStatisticsQueryKey = ['usage-statistics'];

export const selectUsageStatisticsQueryOptions = () => {
	return queryOptions({
		queryKey: usageStatisticsQueryKey,
		queryFn: () => selectUsageStatistics(),
	});
};

export function useSelectUsageStatistics() {
	const profileQuery = useSuspenseQuery(selectUsageStatisticsQueryOptions());
	return profileQuery;
}
