import { createFileRoute } from '@tanstack/react-router';

import ExplainerSwitch from '@/features/explainers/components/ExplainerSwitch';

const ExplainerPage = () => {
	const { explainerType } = Route.useParams();
	return (
		<div>
			<ExplainerSwitch diaryType={explainerType} />
		</div>
	);
};

export const Route = createFileRoute('/_website/help/explainer/$explainerType')({
	component: ExplainerPage,
});
