import { supabaseClient } from '@/lib/supabase';
import { queryOptions, useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

export async function selectUsersOrganisationsRoles(userId: string) {
	const { data, error } = await supabaseClient.from('users_organisations_roles_view').select('*').eq('user_id', userId);

	if (error) {
		throw error;
	}
	return data;
}

export const createUsersOrganisationsRolesQueryKey = (id: string) => ['users_organisations_roles', { user_id: id }];

export const usersOrganisationsRolesQueryOptions = (userId: string) =>
	queryOptions({
		queryKey: createUsersOrganisationsRolesQueryKey(userId),
		queryFn: async () => selectUsersOrganisationsRoles(userId),
	});

export function useUsersOrganisationsRolesQuery(id: string) {
	const options = usersOrganisationsRolesQueryOptions(id);
	return useQuery(options);
}
import { useAuth } from '@/utils/auth';

export function useSetRoles() {
	const auth = useAuth();
	const { data, refetch, isPending } = useUsersOrganisationsRolesQuery(auth.id);
	const [tries, setTries] = useState<number>(0);
	const retry = () => {
		refetch();
		setTries(tries + 1);
	};

	useEffect(() => {
		auth.roles?.setRoles(data);
	}, [data, auth.roles?.setRoles, tries]);

	return { isPending, retry };
}

export default useUsersOrganisationsRolesQuery;
