import { ui } from '@/components/ui';
import { type Watch, watches } from '@/definitions/diaryTypes';

import type { ExtendedFavouriteDiaryT } from '../../../types/database/diaries';

interface Props {
	favouriteDiaries: ExtendedFavouriteDiaryT[];
}
const FavouriteDiaries = (props: Props) => {
	return (
		<div>
			<ui.Table className="text-lg">
				<ui.TableHeader className="text-slate-700 ">
					<ui.TableRow>
						<ui.TableHead className="pl-0 font-semibold">Favourite Templates</ui.TableHead>
					</ui.TableRow>
				</ui.TableHeader>
				<ui.TableBody>
					{props.favouriteDiaries.map((fd: ExtendedFavouriteDiaryT) => (
						<div key={`${fd.user_id}_${fd.diary_template_id}`}>
							{fd?.diaries?.diary_type ? (
								<ui.TableRow>
									<ui.TableCell className="flex w-fit items-center justify-start gap-x-3 pl-0 font-medium">
										{watches[fd.diaries.diary_type as Watch].icon}
										{fd.diaries.diary_name}
									</ui.TableCell>
								</ui.TableRow>
							) : (
								<p className="pb-2 pt-4">No Diary Type on Favourite Diary</p>
							)}
						</div>
					))}
				</ui.TableBody>
			</ui.Table>
		</div>
	);
};

export default FavouriteDiaries;
