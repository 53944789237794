import Eight from './Eight.js';
import Five from './Five.js';
import Four from './Four.js';
import Nine from './Nine.js';
import One from './One.js';
import Seven from './Seven.js';
import Six from './Six.js';
import Ten from './Ten.js';
import Three from './Three.js';
import Two from './Two.js';
import Zero from './Zero.js';

export const faces = {
	Zero,
	One,
	Two,
	Three,
	Four,
	Five,
	Six,
	Seven,
	Eight,
	Nine,
	Ten,
};
