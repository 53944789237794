import { commonComponents as cc } from '@/components/common';
import { ui } from '@/components/ui';
import useCreateFootDiaryMutation from '@/features/foot-watch/api/createFootDiary';
import { footDiariesQueryOptions } from '@/features/foot-watch/api/getFootDiaries';
import { CheckIfExistingDiary } from '@/features/new-diary/features/CheckIfExistingDiary';
import { zodResolver } from '@hookform/resolvers/zod';
import { useSuspenseQuery } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import { Info } from 'lucide-react';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';

const watchSchema = z.object({
	name: z.string().min(1, 'Name is required'),
	patientId: z.string().uuid(),
});

export type WatchSchemaType = z.infer<typeof watchSchema>;

const NewFootWatchDiary = () => {
	const { auth } = Route.useRouteContext();
	const { patientId } = Route.useParams();

	const { data: footDiaries } = useSuspenseQuery(footDiariesQueryOptions(auth.id));
	const form = useForm<WatchSchemaType>({
		resolver: zodResolver(watchSchema),
		defaultValues: { patientId: patientId },
	});

	const createFootDiary = useCreateFootDiaryMutation();

	const onSubmit: SubmitHandler<WatchSchemaType> = async (formData) => {
		createFootDiary.mutate(formData);
	};

	return (
		<section className="mx-auto my-8 max-w-md">
			{footDiaries && <CheckIfExistingDiary footDiaries={footDiaries} />}
			<cc.Title words="New Foot Watch Diary" />
			<cc.Call
				className="text-md"
				icon={<Info />}
				words="Foot Watch allows people with diabetes to monitor foot health using photos."
			/>
			<ui.Form {...form}>
				<form onSubmit={form.handleSubmit(onSubmit)} className="form">
					{/* Diary Name */}
					<ui.FormField
						control={form.control}
						name="name"
						data-cy="name"
						render={({ field }) => (
							<ui.FormItem className="">
								<ui.FormLabel className="">Diary Name</ui.FormLabel>
								<ui.Input type="text" {...field} />
								<ui.FormMessage />
							</ui.FormItem>
						)}
					/>

					<ui.Button type="submit">Create Diary</ui.Button>
				</form>
			</ui.Form>
		</section>
	);
};

export const Route = createFileRoute('/_app/foot-watch/new-diary/$patientId/$diaryId/')({
	loader: async ({ context }) => {
		const footDiaries = await context?.queryClient.ensureQueryData(footDiariesQueryOptions(context.auth.id));
		return {
			footDiaries: footDiaries,
			auth: context.auth,
		};
	},
	component: NewFootWatchDiary,
	errorComponent: () => <p>Foot Watch Error</p>,
});
