import { icons } from '@/assets';
import { commonComponents as cc } from '@/components/common';
import { ui } from '@/components/ui';
import { useImagesStore } from '@/features/foot-watch/hooks/useImagesStore';
import { photoElements as pe } from '@/features/foot-watch/photos';
import { createFileRoute, Link, useNavigate } from '@tanstack/react-router';

const Review = () => {
	const { diaryId } = Route.useParams();
	const navigate = useNavigate();
	const images = useImagesStore((s) => s.images);
	const resetVariant = useImagesStore((s) => s.resetVariant);
	const getFirstMissingImage = useImagesStore((s) => s.getFirstMissingImage);

	const missingImage = getFirstMissingImage(images);

	const resetAll = () => {
		resetVariant(images, 'LT');
		resetVariant(images, 'LS');
		resetVariant(images, 'RT');
		resetVariant(images, 'RS');
	};

	function navigateCamera(side: 'left' | 'right', angle: 'sole' | 'top') {
		navigate({
			to: '/foot-watch/$diaryId/new/camera',
			params: { diaryId: diaryId },
			search: {
				side: side,
				angle: angle,
				delay: angle === 'sole' ? 10 : 0,
			},
		});
	}
	return (
		<>
			<p className="mb-6 w-full text-center text-xl font-medium">
				Please check the photos show your whole foot in focus before continuing.
			</p>
			{missingImage !== null && (
				<Link
					to="/foot-watch/camera"
					className="mb-4 inline-block w-full rounded-xl border-2 border-red-600 bg-red-100 px-2 py-2 text-left text-lg text-red-950"
				>
					<button onClick={() => navigateCamera(missingImage.side, missingImage.angle)}>
						Incomplete image set, please{' '}
						<span className="inline text-red-600 underline underline-offset-2">click here</span> to add the missing
						photos.
					</button>
				</Link>
			)}
			<pe.ImageGallery
				imageTL={images.LT.base64}
				imageTR={images.RT.base64}
				imageBL={images.LS.base64}
				imageBR={images.RS.base64}
				linkTR={() => navigateCamera('right', 'top')}
				linkTL={() => navigateCamera('left', 'top')}
				linkBR={() => navigateCamera('right', 'sole')}
				linkBL={() => navigateCamera('left', 'sole')}
			/>
			<ui.Button
				variant="ghost"
				onClick={() => {
					resetAll();
					navigate({ to: '/foot-watch/$diaryId/$view', params: { diaryId: diaryId, view: 'new' } });
				}}
			>
				Cancel Check-in
			</ui.Button>
			<cc.FooterButtons
				func1={() => navigate({ to: '/foot-watch/$diaryId/new/upload/message', params: { diaryId: diaryId } })}
				words1="Next"
				disabled1={missingImage !== null}
				icon1={<icons.ForwardArrow />}
			/>
		</>
	);
};
createFileRoute;

export const Route = createFileRoute('/_app/foot-watch/$diaryId/new/upload/')({
	component: Review,
});
