import Title from '../../../components/common/Title';

interface Props {
	title: string;
	description?: string;
	children: React.ReactNode;
}

const ProfileWrap = ({ title, description, children }: Props) => {
	return (
		<section className="my-6 w-full">
			<Title words={title} description={description}></Title>
			<div className="space-y-6">{children}</div>
		</section>
	);
};

export default ProfileWrap;
