import { Clock } from 'lucide-react';
import { cn } from '../../../../lib/utils';

const EntryTimeLabel = ({ label, className }: { label: string; className?: string }) => {
	return (
		<p
			className={cn(
				'text-md mx-auto mb-4 inline-flex w-fit items-center gap-x-4 text-wrap rounded-full border border-indigo-400 dark:border-indigo-100 p-2 text-center font-medium text-indigo-700 dark:text-indigo-300',
				className,
			)}
		>
			<Clock size={32} />
			<span className="inline-flex pr-2 w-fit">Entry for {label}</span>
		</p>
	);
};

export default EntryTimeLabel;
