const NoWifi = () => {
    return (
        <svg
            className="w-full h-full"
            width="512"
            height="529"
            viewBox="0 0 512 529"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="256" cy="273" r="236" fill="white" stroke="#F24E1E" strokeWidth="40" />
            <path
                d="M281.751 388.109C281.751 402.96 269.998 415 255.5 415C241.002 415 229.249 402.96 229.249 388.109C229.249 373.257 241.002 361.217 255.5 361.217C269.998 361.217 281.751 373.257 281.751 388.109Z"
                fill="#333333"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M294.829 337.35C275.464 310.359 235.535 310.359 216.171 337.35C212.561 342.382 205.582 343.515 200.583 339.88C195.585 336.246 194.459 329.221 198.069 324.189C226.346 284.776 284.653 284.776 312.931 324.189C316.541 329.221 315.415 336.246 310.416 339.88C305.418 343.515 298.439 342.382 294.829 337.35Z"
                fill="#333333"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M355.693 291.995C308.272 218.742 202.727 218.742 155.307 291.995C151.859 297.321 144.823 298.793 139.591 295.282C134.36 291.772 132.914 284.608 136.362 279.282C192.749 192.179 318.251 192.179 374.638 279.282C378.086 284.608 376.64 291.772 371.408 295.282C366.177 298.793 359.141 297.321 355.693 291.995Z"
                fill="#333333"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M410.469 238.227C337.506 127.003 173.494 127.003 100.531 238.227C97.069 243.504 89.955 244.995 84.6413 241.557C79.3276 238.119 77.8265 231.053 81.2885 225.776C163.312 100.741 347.688 100.741 429.711 225.776C433.173 231.053 431.672 238.119 426.359 241.557C421.045 244.995 413.931 243.504 410.469 238.227Z"
                fill="#333333"
            />
            <path d="M82 99L430 447" stroke="#F24E1E" strokeWidth="40" />
        </svg>
    );
};

export default NoWifi;
