import { supabaseClient } from '@/lib/supabase';
import type { TrackerViewT } from '@/types/database/trackers';
import { queryOptions, useSuspenseQuery } from '@tanstack/react-query';
import type { PatientInfoT } from '../../../types/database/diaries';

export type TrackerAdminView = TrackerViewT & { diary: { patient_info: PatientInfoT } };

export async function getTrackers(): Promise<TrackerAdminView[]> {
	const { data, error } = await supabaseClient
		.from('trackers_view')
		.select('*, diary:diaries_view(patient_info)')
		.returns<TrackerAdminView[]>();
	if (error) throw error;
	return data;
}

export const tracker_key = ['trackers'];

export const trackersQueryOptions = () =>
	queryOptions({
		queryKey: tracker_key,
		queryFn: () => getTrackers(),
	});

export function useTrackersQuery() {
	return useSuspenseQuery(trackersQueryOptions());
}
