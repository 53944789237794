import DiaryViewTabs from '@/features/diary/components/DiaryViewTabs';
import { createFileRoute } from '@tanstack/react-router';
import { Outlet } from '@tanstack/react-router';

const FootWatch = () => {
	return (
		<section className="md-width">
			<div className="w-md my-6 mx-4">
				<DiaryViewTabs />
			</div>
			<Outlet />
		</section>
	);
};

export const Route = createFileRoute('/_app/foot-watch/$diaryId/_view')({
	component: FootWatch,
});
