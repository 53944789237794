import UploadImage from './UploadImage';

interface Props {
    imageBR: string;
    imageBL: string;
    imageTR: string;
    imageTL: string;
    linkBR?: () => void;
    linkBL?: () => void;
    linkTR?: () => void;
    linkTL?: () => void;
    date?: string;
    imageParam?: string;
    allImageURLs?: Array<string>;
    selectable?: boolean;
    select?: (btn: string) => void;
    selected?: any;
}

const UploadImageGallery = ({ imageBR, imageBL, imageTR, imageTL }: Props) => {
    return (
        <div className="flex-col z-2 flex gap-y-2">
            <div className={`flex w-full justify-center gap-x-2`}>
                <UploadImage image={imageTL} placement="tl" variant="LT" />
                <UploadImage image={imageTR} placement="tr" variant="RT" />
            </div>
            <div className={`flex w-full gap-x-2 `}>
                <UploadImage image={imageBL} placement="bl" variant="LS" />
                <UploadImage image={imageBR} placement="br" variant="RS" />
            </div>
        </div>
    );
};

export default UploadImageGallery;
