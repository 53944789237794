import React from 'react';

const Notification = () => {
    return (
        <svg width="33" height="41" viewBox="0 0 33 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.5018 4.51824C12.5636 2.33402 14.3183 0.573491 16.5001 0.502242V0.5H16.6376H16.8626H17.0001V0.502242C19.182 0.573491 20.9366 2.33402 20.9984 4.51824L22.768 5.29243C26.5539 6.94874 29.0001 10.6891 29.0001 14.8214V22.5681C29.0001 23.4502 29.3203 24.3023 29.9011 24.9661C31.5593 26.8612 32.5668 29.2376 32.7759 31.7469L32.9241 33.5249C33.0351 34.8573 31.9836 36 30.6466 36H22.688L22.1255 37.1999C21.1815 39.2138 19.1581 40.5 16.934 40.5C14.544 40.5 12.4047 39.0175 11.5655 36.7796L11.2731 36H2.85358C1.51659 36 0.465135 34.8573 0.576167 33.5249L0.724332 31.7469C0.933446 29.2376 1.94093 26.8612 3.59908 24.9661C4.17994 24.3023 4.50011 23.4502 4.50011 22.5681V14.8214C4.50011 10.6891 6.94637 6.94874 10.7322 5.29243L12.5018 4.51824ZM14.4945 36C14.9555 36.9124 15.895 37.5 16.934 37.5C17.9671 37.5 18.9094 36.9179 19.3734 36H17.0001H16.5001H14.4945ZM15.5001 4.63751C15.5001 4.00928 16.0094 3.5 16.6376 3.5H16.8626C17.4908 3.5 18.0001 4.00928 18.0001 4.63751C18.0001 5.7574 18.6631 6.77107 19.6891 7.21994L21.5655 8.0409C24.2594 9.21948 26.0001 11.881 26.0001 14.8214V22.5681C26.0001 24.1769 26.584 25.7309 27.6434 26.9417C28.8794 28.3542 29.6304 30.1256 29.7862 31.9961L29.8699 33H17.0001H16.5001H3.63031L3.71397 31.9961C3.86984 30.1256 4.62082 28.3542 5.85681 26.9417C6.91619 25.7309 7.50011 24.1769 7.50011 22.5681V14.8214C7.50011 11.881 9.24078 9.21948 11.9347 8.0409L13.8112 7.21994C14.8372 6.77107 15.5001 5.7574 15.5001 4.63751Z"
                fill="#F24E1E"
            />
        </svg>
    );
};

export default Notification;
