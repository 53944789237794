import { supabaseClient } from '@/lib/supabase';
import type { EntryT } from '@/types/database/diaries';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';

import { createGetDiaryKey } from '../../diary/api/getDiaryById';

export type UpsertEntryT = EntryT & { entry_id: string };

export async function upsertEntry(entry: UpsertEntryT) {
	return await supabaseClient
		.from('entries')
		.upsert(entry)
		.select('*, trackers(diary_id)')
		.throwOnError()
		.single()
		.then((res) => res.data);
}

export const useEntryMutation = () => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationKey: ['entry'],
		mutationFn: async (entry: UpsertEntryT) => {
			return await upsertEntry(entry);
		},
		onError: (error) => {
			toast.error(error.message);
		},
		onSuccess: (data) => {
			return data;
		},
		onSettled: (data) => {
			const diaryId = data?.trackers?.diary_id as string;
			queryClient.invalidateQueries({ queryKey: createGetDiaryKey(diaryId) });
		},
	});
};
