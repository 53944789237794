import type { Control } from 'react-hook-form';

import { ui } from '../ui';

interface Props {
	control: Control;
	name: string;
	label?: string;
	disabled?: boolean;
	tabIndex?: number;
}

const NumberInput: React.FC<Props> = ({ name, label, control, disabled, tabIndex }) => {
	return (
		<ui.FormField
			control={control}
			name={name}
			disabled={disabled}
			data-cy={name}
			render={({ field }) => (
				<ui.FormItem>
					{!!label && <ui.FormLabel>{label}</ui.FormLabel>}
					<ui.FormControl>
						<ui.Input
							type="number"
							tabIndex={tabIndex}
							onChange={(e) => {
								field.onChange(+e.target.value);
							}}
						/>
					</ui.FormControl>
					<ui.FormMessage />
				</ui.FormItem>
			)}
		/>
	);
};

export default NumberInput;
