import FooterButtons from '@/components/common/FooterButtons';
import { Camera, X } from 'lucide-react';
import { ErrorBoundary } from 'react-error-boundary';

import WarningMessage from './WarningMessage';

interface Props {
	image: string;
	delay: number;
	timerRunning: boolean;
	retake: () => void;
	recheck: () => void;
	stopTimer: () => void;
}
const CameraButtons = ({ image, delay, timerRunning, retake, recheck, stopTimer }: Props) => {
	return (
		<ErrorBoundary fallback={<WarningMessage words="Something went with camera buttons" />}>
			{/* Footer Buttons */}
			<div className="fixed bottom-6 left-0 z-50 flex h-20 w-full items-center justify-center">
				{image && (
					<>
						<FooterButtons
							func1={recheck}
							words1="Check Image"
							func2={retake}
							words2="Retake"
							icon2={<Camera size={24} />}
						/>
					</>
				)}
				{!image && (
					<>
						{timerRunning ? (
							<FooterButtons func1={stopTimer} words1="Cancel" icon1={<X size={24} />} />
						) : (
							<FooterButtons
								func1={retake}
								words1={delay === 0 ? 'Take Photo' : 'Start Timer'}
								icon1={<Camera size={24} />}
							/>
						)}
					</>
				)}
			</div>
		</ErrorBoundary>
	);
};

export default CameraButtons;
