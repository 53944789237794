import { supabaseClient } from '@/lib/supabase';
import type { FavouriteUserT } from '@/types/database/profiles';
import { useAuth } from '@/utils/auth';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';

import { createFavouriteUsersQueryKey } from './selectFavouriteUsers';

const deleteFavouriteUser = async (oldUser: FavouriteUserT) => {
	const { error } = await supabaseClient
		.from('favourite_users')
		.delete()
		.eq('favourited_by', oldUser.favourited_by)
		.eq('favourited_user', oldUser.favourited_user);
	if (error) throw error;
};
const useDeleteFavouriteUser = () => {
	const queryClient = useQueryClient();
	const auth = useAuth();
	return useMutation({
		mutationFn: async (userId: string) => deleteFavouriteUser({ favourited_by: auth.id, favourited_user: userId }),
		onError: (error) => {
			toast.error(`${error.message}`);
		},
		onSuccess: () => {
			toast.success('Favourite user removed successfully');
		},
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey: createFavouriteUsersQueryKey(auth.id) });
		},
	});
};
export default useDeleteFavouriteUser;
