import { useProfileQuery } from '@/features/profile/api/selectProfile';
import ProfileWrap from '@/features/profile/layouts/ProfileWrap';
import { createFileRoute } from '@tanstack/react-router';

import NameForm from '@/features/profile/components/NameForm';
import { useAuth } from '../../../utils/auth';

const Name = () => {
	const auth = useAuth();
	const { data: profile } = useProfileQuery();

	return (
		<ProfileWrap title="Name">
			<NameForm
				userId={auth.id}
				firstName={profile?.first_name ?? null}
				lastName={profile?.last_name ?? null}
				title={profile?.title ?? null}
			/>
		</ProfileWrap>
	);
};
export const Route = createFileRoute('/_app/profile/name')({
	component: Name,
});

export default Name;
