import { useEntryValuesStore } from '@/features/inputs/hooks/useEntryValuesStore';
import { services as s } from '@/services';
import type { InputT } from '@/types/database/forms';

import { ui } from '../ui';
import AddOption from './AddOption';

interface Props {
	input: InputT;
}
const SingleChoice = ({ input }: Props) => {
	const entryValue = useEntryValuesStore((state) => state.getEntryValueByInputId(input.input_id));
	const upsertEntryValue = useEntryValuesStore((state) => state.upsertEntryValue);

	return (
		<ui.Card>
			<ui.CardHeader>
				<ui.Label>{input.input_label}</ui.Label>
			</ui.CardHeader>
			<ui.CardContent>
				<ui.RadioGroup
					onValueChange={(val: string) =>
						upsertEntryValue({
							...entryValue,
							input_id: input.input_id,
							text_value: val,
						})
					}
					className="flex flex-col"
				>
					{input.options?.map((o) => (
						<ui.RadioGroupItem value={entryValue?.text_value ?? ''} key={o} className="flex items-center gap-2 p-2">
							<ui.Label className="font-normal capitalize">{s.capitaliseSentence(o, '-')}</ui.Label>
						</ui.RadioGroupItem>
					))}
				</ui.RadioGroup>
			</ui.CardContent>
			<ui.CardFooter className="flex flex-col items-start gap-2 w-full">
				<AddOption input={input} />
			</ui.CardFooter>
		</ui.Card>
	);
};

export default SingleChoice;
