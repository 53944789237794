import { Brain, Camera, Fish, Flower, Footprints, Zap } from 'lucide-react';

type WatchIcon = JSX.Element;

export type WatchDetail = {
	name: string;
	description: string;
	href: WatchHref;
	icon: WatchIcon;
	comingSoon: boolean;
};

export type Watch = 'PAIN' | 'FOOT' | 'FISHER' | 'HEADACHE' | 'PHOTO' | 'GYNAE';
export type WatchHref = 'pain-watch' | 'foot-watch' | 'fisher-watch' | 'headache-watch' | 'photo-diary' | 'gynae-diary';

export type WatchesT = {
	[key in Watch]: WatchDetail;
};

export const watches: WatchesT = {
	PAIN: {
		name: 'Pain Watch',
		description: 'Pain tracking to improve patient pathways following MSK procedures.',
		icon: <Zap strokeWidth={1.5} size={32} />,
		href: 'pain-watch',
		comingSoon: false,
	},
	FOOT: {
		name: 'Foot Watch',
		description:
			'Foot Watch allows people with diabetes to take an active part in monitoring foot health using photos.',
		icon: <Footprints strokeWidth={1.5} size={32} />,
		href: 'foot-watch',
		comingSoon: false,
	},
	FISHER: {
		name: 'Fisher Watch',
		description:
			'Fisher Watch enables fishermen and fisherwomen to work safely by tracking their diabetes while on the water.',
		icon: <Fish strokeWidth={1.5} size={32} />,
		href: 'fisher-watch',
		comingSoon: true,
	},
	HEADACHE: {
		name: 'Headache Watch',
		description:
			'Headache Watch allows people to self-manage their headaches through understanding what triggers headaches and what cures them.',
		icon: <Brain strokeWidth={1.5} size={32} />,
		href: 'headache-watch',
		comingSoon: true,
	},
	PHOTO: {
		name: 'Photo Diary',
		description: '',
		icon: <Camera strokeWidth={1.5} size={32} />,
		href: 'photo-diary',
		comingSoon: true,
	},
	GYNAE: {
		name: 'Gynaecological Diary',
		description: '',
		icon: <Flower strokeWidth={1.5} size={32} />,
		href: 'gynae-diary',
		comingSoon: false,
	},
};

export const showDiaryType = (state: string): React.ReactNode => {
	try {
		const watch = watches[state as Watch];
		return (
			<div className="flex gap-2">
				{watch.icon}
				{watch.name}
			</div>
		);
	} catch (e) {
		console.error(e);
		return '';
	}
};
