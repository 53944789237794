import { ui } from '@/components/ui';
import { Link } from '@tanstack/react-router';
import { AlertCircle } from 'lucide-react';

import { ExtendedFootDiary } from '../../../types/supabaseTypes';

interface Props {
    footDiaries: Partial<ExtendedFootDiary>[];
}
function CheckIfExistingDiary({ footDiaries }: Props) {
    return (
        <ui.Dialog defaultOpen={true}>
            <ui.DialogContent className="sm:max-w-[425px]">
                <ui.DialogHeader>
                    <ui.DialogTitle className="flex w-full items-center justify-start gap-x-2">
                        <AlertCircle /> Active Diary Found
                    </ui.DialogTitle>
                    <ui.DialogDescription className="flex w-full items-center justify-start gap-x-2">
                        You already have an active Foot Diary.
                    </ui.DialogDescription>
                </ui.DialogHeader>
                <ui.DialogFooter>
                    <div className="flex gap-2 w-full justify-start items-center flex-wrap">
                        <ui.Button asChild>
                            <Link
                                to="/foot-watch/$diaryId/$view"
                                params={{ diaryId: footDiaries[0].diary_id!, view: 'new' }}
                            >
                                Go to Diary
                            </Link>
                        </ui.Button>
                        <ui.DialogClose asChild>
                            <ui.Button variant="secondary" asChild>
                                <Link to="/foot-watch/new-diary">Create New Diary</Link>
                            </ui.Button>
                        </ui.DialogClose>
                    </div>
                </ui.DialogFooter>
            </ui.DialogContent>
        </ui.Dialog>
    );
}

export { CheckIfExistingDiary };
export default CheckIfExistingDiary;
