import Camera from "./Camera";
import CameraButtons from "./CameraButtons";
import CameraHeader from "./CameraHeader";
import { FaceT } from "./SelectCamera";
import SelectCamera from "./SelectCamera";
import SelectTimer from "./SelectTimer";
import { TimerT } from "./SelectTimer";
import Timer from "./Timer";

export const cameraComponents = {
  CameraHeader,
  SelectCamera,
  SelectTimer,
  CameraButtons,
  Camera,
  Timer,
};

export type { FaceT, TimerT };
