import { diaryByIdQueryOptions } from '@/features/diary/api/getDiaryById';
import { diariesBreadcrumb, editBreadcrumb } from '@/features/navigation/services/breadcrumbRoutes';
import { hooks as h } from '@/hooks';
import { Outlet, createFileRoute } from '@tanstack/react-router';

const Edit = () => {
	h.useNav([
		diariesBreadcrumb,
		editBreadcrumb,
		{ route: { to: '/diary/$diaryId/edit/tracker/$trackerId' }, label: 'Tracker' },
		{ route: { to: '/diary/$diaryId/edit/tracker/$trackerId/entry/$entryId' }, label: 'Entry' },
	]);

	return <Outlet />;
};

export const Route = createFileRoute('/_app/diary/$diaryId/edit/tracker/$trackerId/entry/$entryId/tsx')({
	component: Edit,
	loader: async ({ context, params }) => {
		const diary = await context?.queryClient.ensureQueryData(diaryByIdQueryOptions(params.diaryId));
		return { diary };
	},
});
