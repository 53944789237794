import { supabaseClient } from '@/lib/supabase';
import type { FormViewT } from '@/types/database/forms';
import { queryOptions, useQuery } from '@tanstack/react-query';

export async function selectFormById(formId: string): Promise<FormViewT> {
	const { data, error } = await supabaseClient.from('forms_view').select().eq('form_id', formId).single();

	if (error) throw error;
	return data as FormViewT;
}

export const selectFormByIdQueryOptions = (formId: string) =>
	queryOptions({
		queryKey: ['form', { form_id: formId }],
		queryFn: async () => selectFormById(formId),
	});

export function useSelectFormById(formId: string) {
	const options = selectFormByIdQueryOptions(formId);
	return useQuery(options);
}
