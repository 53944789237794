import DashboardButtons from './DashboardButtons';
import StateTabs from './StateTabs';
import TableTabs from './TableTabs';
import Search from './table/components/Search';

export const tableComponents = {
	DashboardButtons,
	TableTabs,
	StateTabs,
	Search,
};
