import { commonComponents as cc } from '@/components/common';
import { ui } from '@/components/ui';
import { cn } from '@/lib/utils';
import { services as s } from '@/services';
import { getRandomFromList } from '@/services/getRandomFromList';
import type { InputT } from '@/types/database/diaries';
import { tailwindColors } from '@/types/zod/common';
import { Link } from '@tanstack/react-router';
import { Lock } from 'lucide-react';

import InputType from './InputType';
import type { AnyInputT } from './Inputs';

interface Props {
	input: InputT;
	editable?: boolean;
}

const InputCard = ({ input, editable }: Props) => {
	return (
		<ui.Card key={input.input_id} className="">
			<ui.CardHeader className="flex flex-row items-center justify-between pb-2">
				<InputType inputType={input.input_key} />
				{!editable ? (
					<cc.EditButton>
						<Link
							to="/templates/diary-template/$diaryTemplateId/input/$inputId"
							params={(prev) => ({ ...prev, inputId: input.input_id })}
							search={{
								input: input,
							}}
						>
							Edit
						</Link>
					</cc.EditButton>
				) : (
					<ui.TooltipProvider>
						<ui.Tooltip>
							<ui.TooltipTrigger>
								<ui.Button size="icon" variant="outline">
									<Lock />
								</ui.Button>
							</ui.TooltipTrigger>
							<ui.TooltipContent>Input locked because you are not the template author.</ui.TooltipContent>
						</ui.Tooltip>
					</ui.TooltipProvider>
				)}
			</ui.CardHeader>
			<ui.CardContent className="space-y-2">
				<h4 className="text-lg font-medium text-slate-700">{input.label}</h4>
				<div className="flex flex-row flex-wrap gap-2">
					<InputBadge value={input.min_value} label="Min Value" />
					<InputBadge value={input.max_value} label="Max Value" />
					<InputBadge value={input.max_length} label="Max Length" />
				</div>
				{input.options?.length && (
					<ul className="flew-wrap flex flex-row items-center gap-2">
						{input.options.map((o) => (
							<>
								<li className="flex-col[&_svg]:last-of-type:hidden flex text-slate-800">
									{s.capitaliseSentence(o, '-')}
								</li>
								<p className="text-slate-600 last:hidden">/</p>
							</>
						))}
					</ul>
				)}
			</ui.CardContent>
		</ui.Card>
	);
};

export default InputCard;

interface InputBadgeProps {
	value: AnyInputT | null;
	label: string;
}
const InputBadge = ({ value, label }: InputBadgeProps) => {
	const tailwindColor = getRandomFromList(tailwindColors);

	return (
		<ui.Badge className={cn('capitalize', value === null && 'hidden')} variant="outline" color={tailwindColor}>
			{label} {String(value)}
		</ui.Badge>
	);
};
