import { ui } from '@/components/ui';
import type { ExtendedQuickstartT } from '@/types/database/profiles';
import { Link } from '@tanstack/react-router';
import useOrganisationsQuery from '../../auth/api/useOrganisationsQuery';
import DiaryTypeEmblemSwitch from './DiaryTypeEmblemSwitch';

interface Props {
	quickstarts: ExtendedQuickstartT[];
}
const Quickstarts = (props: Props) => {
	const { data: organisations } = useOrganisationsQuery();
	return (
		<section className="p-4 md:p-8">
			<ul className="gap-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mx-auto">
				{props.quickstarts.map((q) => (
					<li key={q.quickstart_id}>
						<ui.Card>
							<ui.CardHeader>
								<ui.CardTitle>{q.quickstart_name}</ui.CardTitle>
								{q.quickstart_description && <ui.CardDescription>{q.quickstart_description}</ui.CardDescription>}
							</ui.CardHeader>
							<ui.CardContent>
								{organisations.length && (
									<div className="flex flex-wrap gap-2">
										<ui.Badge>{q.role}</ui.Badge>
										<ui.Badge variant="secondary">
											{organisations?.find((o) => o.organisation_id === q.organisation_id)?.organisation_name}
										</ui.Badge>
									</div>
								)}
								{q?.diary_templates?.length && <DiaryTypeEmblemSwitch diaryType={q?.diary_templates[0]?.diary_type} />}
							</ui.CardContent>
							<ui.CardFooter>
								<ui.Button size="lg" asChild>
									<Link to="/quickstart/$quickstartId" params={{ quickstartId: q.quickstart_id }}>
										Next
									</Link>
								</ui.Button>
							</ui.CardFooter>
						</ui.Card>
					</li>
				))}
			</ul>
		</section>
	);
};

export default Quickstarts;
