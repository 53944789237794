import { components as c } from '@/components';
import { diaryByIdQueryOptions } from '@/features/diary/api/getDiaryById';
import SurgeryForm from '@/features/events/features/surgeries/components/SurgeryForm';
import useExtractSurgery from '@/features/events/features/surgeries/hooks/useExtractSurgery';
import { Route as SurgeryCliniciansRoute } from '@/routes/_app/new-diary/patient/$patientId/organisation/$organisationId/diary/$diaryId/event/$eventId/surgery/$surgeryId/surgery-clinicians';
import { createFileRoute, useNavigate } from '@tanstack/react-router';

const SurgeryNamePage = () => {
	const navigate = useNavigate({ from: Route.to });
	const { diaryId, surgeryId, eventId } = Route.useParams();
	const surgery = useExtractSurgery(diaryId, eventId, surgeryId);
	return (
		<>
			<c.cc.Stepper
				steps={[
					{ stage: 'past', words: 'Patient' },
					{ stage: 'past', words: 'Diary' },
					{ stage: 'past', words: 'Form and Schedule' },
					{
						stage: 'current',
						words: 'Intervention',
						substeps: [
							{
								stage: 'current',
								words: 'Surgery Name',
							},
							{
								stage: 'future',
								words: 'Clinicians',
							},
						],
					},
				]}
			/>

			<SurgeryForm
				surgery={surgery}
				diaryId={diaryId}
				eventId={eventId}
				surgeryId={surgeryId}
				navigateToNextPage={() =>
					navigate({
						to: SurgeryCliniciansRoute.to,
					})
				}
			/>
		</>
	);
};

export const Route = createFileRoute(
	'/_app/new-diary/patient/$patientId/organisation/$organisationId/diary/$diaryId/event/$eventId/surgery/$surgeryId/surgery-name',
)({
	loader: async ({ context, params }) => {
		await context?.queryClient.ensureQueryData(diaryByIdQueryOptions(params.diaryId));
	},
	component: SurgeryNamePage,
});
