import { ui } from '@/components/ui';
import { cn } from '@/lib/utils';
import type { SizeT } from '@/types/zod/common';
import type { ReactNode } from 'react';

interface Props {
	className?: string;
	headerContent: ReactNode;
	bodyContent: ReactNode;
	footerContent?: ReactNode;
	size?: SizeT;
	showCard?: boolean;
}

const HistoryCard = ({ className, headerContent, bodyContent, footerContent, size, showCard = true }: Props) => {
	return (
		<ui.Card
			className={cn(
				'border-slate-200 bg-white',
				className,
				showCard === false && 'p-0 rounded-auto bg-transparent border-0',
			)}
		>
			<ui.CardHeader className={cn(size === 'lg' ? 'p-4' : 'p-2', showCard === false && 'px-0')}>
				{headerContent}
			</ui.CardHeader>
			<ui.CardContent className={cn(size === 'lg' ? 'p-4' : 'p-2', showCard === false && 'px-0', 'pt-0')}>
				{bodyContent}
			</ui.CardContent>
			{footerContent && (
				<ui.CardFooter className={cn('border-t border-slate-300 pt-2', showCard === false && 'px-0')}>
					{footerContent}
				</ui.CardFooter>
			)}
		</ui.Card>
	);
};

export default HistoryCard;
