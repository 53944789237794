import { ui } from '@/components/ui';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import type { StatefulDiaryT } from '@/types/database/diaries';
import { Check } from 'lucide-react';

import useMarkRead from '../api/markRead';

interface Props {
	diary: StatefulDiaryT;
}
const ReadButton = ({ diary }: Props) => {
	const { markRead } = useMarkRead();

	return (
		<TooltipProvider>
			<Tooltip>
				<TooltipTrigger asChild>
					<ui.Button onClick={() => markRead(diary)} className="w-fit px-2" variant="ghost" size="sm">
						<Check />
					</ui.Button>
				</TooltipTrigger>
				<TooltipContent>
					<p>Mark as Done</p>
				</TooltipContent>
			</Tooltip>
		</TooltipProvider>
	);
};

export default ReadButton;
