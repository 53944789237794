import { charts as c } from '..';
import { InputKeyT } from '../../../definitions/inputs';
import { EntryValueViewT } from '../../../types/database/diaries';
import { ExtendedTrackerViewT } from '../../../types/database/trackers';

interface Props {
	entryValues: EntryValueViewT[];
	trackers: ExtendedTrackerViewT[];
	inputType: InputKeyT;
}

const TrackerChart = (props: Props) => {
	switch (props.inputType) {
		case 'glucose-slider':
			return <c.gc.GlucoseChart entryValues={props.entryValues} trackers={props.trackers} />;
		case 'pain-slider':
			return <c.pc.PainChart entryValues={props.entryValues} trackers={props.trackers} />;
	}
};

export default TrackerChart;
