import { commonComponents as cc } from '@/components/common';
import { ExtendedInjectionViewT } from '@/types/database/trackers';
import { Link } from '@tanstack/react-router';
import { Crosshair, TestTube2 } from 'lucide-react';

interface Props {
	diaryId: string;
	injection: ExtendedInjectionViewT;
}
const InjectionLink = (props: Props) => {
	return (
		<Link
			to="/diary/$diaryId/edit/event/$eventId/injection/$injectionId"
			params={{
				diaryId: props.diaryId,
				eventId: props.injection.event_id,
				injectionId: props.injection.injection_id,
			}}
			className="flex flex-row items-center justify-between"
		>
			<>
				<ul className="text-md flex flex-col gap-y-2">
					{props.injection?.injection_details_view.length === 0 ? (
						<p className="font-medium text-slate-500">Injection Details Missing</p>
					) : (
						props.injection?.injection_details_view?.map((detail) => {
							return (
								<li className="flex flex-col gap-y-1" key={detail.injection_detail_id}>
									<cc.IconDetail
										icon={<Crosshair size={24} />}
										words={detail.injection_location ?? 'Injection location missing'}
									/>
									<cc.IconDetail
										icon={<TestTube2 size={24} />}
										words={detail.injection_content ?? 'Injection content missing'}
									/>
								</li>
							);
						})
					)}
				</ul>
				<cc.ChevronButton />
			</>
		</Link>
	);
};

export default InjectionLink;
