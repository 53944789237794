interface Props {
    fill: string;
    flatten: boolean;
}

// import './shuffle.css';

const Shuffle = ({ fill, flatten }: Props) => {
    return (
        <svg
            className="h-full w-full transition-all ease-in-out duration-300"
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                className="transition ease duration-700"
                d={
                    flatten
                        ? 'M38.2929 20.2929C38.6834 19.9024 39.3166 19.9024 39.7071 20.2929L43.7072 24.2929C43.8948 24.4804 44.0001 24.7348 44.0001 25C44.0001 25.2652 43.8948 25.5196 43.7072 25.7071L39.7071 29.7071C39.3166 30.0976 38.6834 30.0976 38.2929 29.7071C37.9024 29.3166 37.9024 28.6834 38.2929 28.2929L40.5859 26H7C6.44772 26 6 25.5523 6 25C6 24.4477 6.44772 24 7 24H40.5859L38.2929 21.7071C37.9024 21.3166 37.9024 20.6834 38.2929 20.2929Z'
                        : 'M38.4473 36.8493C38.8273 37.2293 39.4433 37.2293 39.8233 36.8493L43.715 32.9576C44.095 32.5776 44.095 31.9616 43.715 31.5816L39.8233 27.6899C39.4433 27.3099 38.8273 27.3099 38.4473 27.6899C38.0674 28.0698 38.0674 28.6859 38.4473 29.0658L40.6782 31.2966H31.6609C30.3622 31.2966 29.1588 30.6153 28.4906 29.5017L21.8712 18.4694C20.8514 16.7696 19.0146 15.7296 17.0324 15.7296H7.22294C6.6856 15.7296 6.25 16.1652 6.25 16.7026C6.25 17.2399 6.6856 17.6755 7.22294 17.6755H17.0324C18.3311 17.6755 19.5345 18.3569 20.2027 19.4705L26.8221 30.5028C27.8419 32.2025 29.6787 33.2425 31.6609 33.2425H40.6782L38.4473 35.4734C38.0674 35.8533 38.0674 36.4694 38.4473 36.8493Z'
                }
                fill={fill}
            />

            <path
                className="transition ease duration-700"
                fillRule="evenodd"
                clipRule="evenodd"
                d={
                    flatten
                        ? 'M38.2929 20.2929C38.6834 19.9024 39.3166 19.9024 39.7071 20.2929L43.7072 24.2929C43.8948 24.4804 44.0001 24.7348 44.0001 25C44.0001 25.2652 43.8948 25.5196 43.7072 25.7071L39.7071 29.7071C39.3166 30.0976 38.6834 30.0976 38.2929 29.7071C37.9024 29.3166 37.9024 28.6834 38.2929 28.2929L40.5859 26H7C6.44772 26 6 25.5523 6 25C6 24.4477 6.44772 24 7 24H40.5859L38.2929 21.7071C37.9024 21.3166 37.9024 20.6834 38.2929 20.2929Z'
                        : 'M38.4473 12.9012C38.8273 12.5212 39.4433 12.5212 39.8233 12.9012L43.715 16.7929C44.095 17.1729 44.095 17.7889 43.715 18.1689L39.8233 22.0606C39.4433 22.4406 38.8273 22.4406 38.4473 22.0606C38.0674 21.6807 38.0674 21.0646 38.4473 20.6847L40.6782 18.4538H31.6609C30.3622 18.4538 29.1588 19.1352 28.4906 20.2488L26.1687 24.1187L25.0341 22.2276L26.8221 19.2477C27.8419 17.548 29.6787 16.508 31.6609 16.508H40.6782L38.4473 14.2771C38.0674 13.8972 38.0674 13.2811 38.4473 12.9012ZM22.9917 25.6315L20.2027 30.28C19.5345 31.3936 18.3311 32.075 17.0324 32.075H7.22294C6.6856 32.075 6.25 32.5106 6.25 33.0479C6.25 33.5852 6.6856 34.0208 7.22294 34.0208H17.0324C19.0146 34.0208 20.8514 32.9808 21.8712 31.2811L24.1264 27.5226L22.9917 25.6315Z'
                }
                fill={fill}
            />
        </svg>
    );
};

export default Shuffle;
