import type React from 'react';
interface Props {
	children: React.ReactNode;
}
const Draft = ({ children }: Props) => {
	return (
		<div className="py-2 px-4 text-sm rounded-t-md bg-amber-200 border-b border-amber-300">
			<p className="text-amber-900">{children}</p>
		</div>
	);
};

export default Draft;
