import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";

type State = {
  tracker_types: string[];
};

type Actions = {
  addTrackerTypes: (trackerType: string) => void;
  removeTrackerTypes: (trackerType: string) => void;
  getTrackerTypes: () => string[] | undefined;
  reset: () => void;
};

const initialState: State = {
  tracker_types: [], // Initialize with empty array
};

export const useTrackerTypesStore = create<State & Actions>()(
  devtools(
    persist(
      (set, get) => ({
        ...initialState,
        getTrackerTypes: () => {
          return get().tracker_types;
        },
        addTrackerTypes: (trackerType: string) =>
          set((state) => ({
            tracker_types: [...state.tracker_types, trackerType].filter(
              (t) => t !== "", // Remove any empty strings
            ),
          })),
        removeTrackerTypes: (trackerType: string) =>
          set((state) => ({
            tracker_types: state.tracker_types.filter((t) => t !== trackerType),
          })),
        reset: () => set({ tracker_types: [] }),
      }),
      {
        name: "tracker-types-store",
        storage: createJSONStorage(() => localStorage),
      },
    ),
  ),
);
