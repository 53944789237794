import ProfileWrap from '@/features/profile/layouts/ProfileWrap';
import type { ExtendedProfileT } from '@/types/database/profiles';
import { useAuth } from '@/utils/auth';
import { createFileRoute } from '@tanstack/react-router';

import OnboardingStepper from '@/features/onboarding/components/OnboardingStepper';
import { useNavigateToNextOnboardingRoute } from '@/features/onboarding/hooks/useNavigateToNextOnboardingRoute';
import { selectProfileByIdQueryOptions } from '@/features/profile/api/selectProfile';
import ClinicianNumberForm from '@/features/profile/components/ClinicianNumberForm';

const Name = () => {
	const auth = useAuth();
	const { profile } = Route.useLoaderData();
	const nextPage = useNavigateToNextOnboardingRoute('/onboarding/clinician-number');

	return (
		<ProfileWrap title="Onboarding">
			<OnboardingStepper currentRoute={Route.to} />
			<ClinicianNumberForm userId={auth.id} clinicianNumber={profile?.clinician_number ?? ''} onSuccess={nextPage} />
		</ProfileWrap>
	);
};
export const Route = createFileRoute('/onboarding/clinician-number')({
	component: Name,
	loader: async ({ context }) => {
		let profile: ExtendedProfileT | null = null;
		if (context?.auth?.id) {
			profile = await context?.queryClient.ensureQueryData(selectProfileByIdQueryOptions(context.auth.id));
		}
		return { profile };
	},
});

export default Name;
