import { BarChart3, BookMarked, HeartHandshake, HelpCircle, MessageCircle, ShieldCheck, Users } from 'lucide-react';

import FootIcon from '../../foot-watch/assets/FootIcon';
import InjectionIcon from '../../pain-watch/assets/InjectionIcon';

import type { ExternalLink, RouteT } from '@/types/zod/routes';
import type { ReactNode } from 'react';

export interface Page {
	name: string;
	href: RouteT | ExternalLink;
	description?: string;
	icon?: ReactNode;
	external?: boolean;
	draft?: boolean;
}

export interface NavigationItem {
	name: string;
	footerOnly?: boolean;
	pages: Page[];
	draft?: boolean;
}

export const navigationContent: NavigationItem[] = [
	{
		name: 'Use Cases',
		pages: [
			{
				name: 'Pain Injections',
				href: '/use-cases/pain-injections',
				description: 'An automated pain diary for therapeutic injections.',
				icon: <InjectionIcon className="w-11 h-11" />,
			},
			{
				name: 'Diabetic Feet',
				href: '/use-cases/diabetic-feet',
				description: 'An AI powered photo diary for preventing diabetic foot ulcers.',
				icon: <FootIcon className="w-11 h-11" />,
			},
		],
	},
	// { name: 'Security', href: '/security', icon: <ShieldCheck /> },
	// { name: 'Evidence', href: '/evidence', icon: <BarChart3 /> },
	{
		name: 'Learn',
		pages: [
			{
				name: 'Security',
				href: '/security',
				icon: <ShieldCheck strokeWidth={1.75} />,
			},
			{
				name: 'Evidence',
				href: '/evidence',
				icon: <BarChart3 strokeWidth={1.75} />,
			},
			// { name: 'Accessability', href: '/accessability' },
		],
	},

	{
		name: 'Company',
		pages: [
			{
				name: 'About',
				href: '/about',
				icon: <Users strokeWidth={1.75} />,
			},
			{
				name: 'Story',
				href: '/story',
				icon: <BookMarked strokeWidth={1.75} />,
			},
			{
				name: 'Contact',
				href: '/contact',
				icon: <MessageCircle strokeWidth={1.75} />,
			},
		],
	},
	{
		name: 'Legal',
		footerOnly: true,
		pages: [
			{
				name: 'Terms of Use',
				href: 'https://patient-watch.notion.site/Terms-of-Use-816e45ec28e14eedba843dc8f3f74407',
				external: true,
			},
			{
				name: 'Privacy Policy',
				href: 'https://patient-watch.notion.site/Privacy-Policy-50288e6ac2d946bca935fbf5bdc30782?pvs=25',
				external: true,
			},
			{
				name: 'Acceptable Use Policy',
				href: 'https://patient-watch.notion.site/Acceptable-Use-Policy-c103ad34037a42a5a21a6603999f276a?pvs=4',
				external: true,
			},
			// { name: 'Accessability Policy', href: '/support' },
			{
				name: 'Data Collection',
				href: 'https://patient-watch.notion.site/Data-Collected-22aee5597c3f470dab9cabd757c9b5bd?pvs=4',
				external: true,
			},
		],
	},
	{
		name: 'Help',
		pages: [
			{
				name: 'Support',
				href: '/support',
				icon: <HeartHandshake strokeWidth={1.75} />,
			},
			{
				name: 'FAQs',
				href: '/FAQs',
				icon: <HelpCircle strokeWidth={1.75} />,
			},
		],
	},
];
