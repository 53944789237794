// TODO - Add Hospital Number - NON OPTIONAL FOR NHS
import { components as c } from '@/components';
import { useProfileQuery } from '@/features/profile/api/selectProfile';
import { Route as TemplateFormRoute } from '@/routes/_app/new-diary/patient/$patientId/organisation/$organisationId/diary/$diaryId/diary-templates';
import { Route as GuidedInjectionFormRoute } from '@/routes/_app/new-diary/patient/$patientId/organisation/$organisationId/diary/guided/injection-diary/time';

const GuidedDiaryRouteSelect = () => {
	const { data: profile } = useProfileQuery();

	const options = [
		{ value: GuidedInjectionFormRoute.to, label: 'Injection Diary' },
		{ value: TemplateFormRoute.to, label: 'Diary from Templates', locked: !(profile?.is_super_user ?? false) },
	];

	return <c.fc.Select label="Diary" name="diary_route" options={options} />;
};

export default GuidedDiaryRouteSelect;
