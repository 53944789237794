import { formComponents as fc } from '@/components/form';
import { ui } from '@/components/ui';
import { services as s } from '@/services';
import type { ExtendedEntryT } from '@/types/entries';
import { zodResolver } from '@hookform/resolvers/zod';
import dayjs from 'dayjs';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';

import { useUpdateEntry } from '../api/updateEntry';

export const DiarySchemaZ = z.object({
	date: z.coerce.string(),
	time: z.string(),
});

export type DiarySchemaT = z.infer<typeof DiarySchemaZ>;

interface Props {
	diaryId: string;
	entryId: string;
	entry: ExtendedEntryT;
}
const EntryTimeForm = ({ diaryId, entryId, entry }: Props) => {
	const form = useForm<DiarySchemaT>({
		resolver: zodResolver(DiarySchemaZ),
		defaultValues: {
			date: entry?.calculated_entry_time ? dayjs(entry?.calculated_entry_time).format('YYYY-MM-DD') : undefined,
			time: entry?.calculated_entry_time ? dayjs(entry?.calculated_entry_time).format('HH:mm') : undefined,
		},
	});

	const updateEntry = useUpdateEntry();

	const onSubmit: SubmitHandler<DiarySchemaT> = async (formData) => {
		updateEntry.mutate({
			entry_id: entryId,
			entry_time: s.time.formatISO(`${dayjs(formData.date).format('YYYY-MM-DD')} ${formData.time}`),
		});
	};

	return (
		<ui.Form {...form}>
			<form onSubmit={form.handleSubmit(onSubmit)} className="form">
				<fc.Time control={form.control} name="time" label="Entry Time" />
				<fc.Date control={form.control} name="date" label="Entry Date" />
				<div className="my-4 flex flex-col gap-y-4">
					<ui.Button size="lg" type="submit">
						Next
					</ui.Button>
				</div>
			</form>
		</ui.Form>
	);
};

export default EntryTimeForm;
