import { diaryTemplatesQueryOptions } from '@/features/builder/api/selectDiaryTemplates';
import { useDiaryTemplatesQuery } from '@/features/builder/api/selectDiaryTemplates';
import DiaryTemplateCard from '@/features/builder/components/DiaryCompositionCard';
import { createFileRoute } from '@tanstack/react-router';

const Templates = () => {
	const { data: templates } = useDiaryTemplatesQuery();
	return (
		<ul className="flex flex-row flex-wrap p-2">
			{templates?.map((dt) => (
				<li key={dt.diary_template_id} className="flex flex-wrap w-full p-2 md:w-1/2 lg:w-1/3">
					<DiaryTemplateCard diary_template={dt} />
				</li>
			))}
		</ul>
	);
};

export const Route = createFileRoute('/_app/builder/diary-templates/')({
	component: Templates,
	loader: async ({ context }) => {
		return await context?.queryClient.ensureQueryData(diaryTemplatesQueryOptions());
	},
});
