import {
	AlertDialog,
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { Link } from '@tanstack/react-router';
import { useState } from 'react';

import { useAuth } from '../../../utils/auth';

export function MissingOrganisationAlert() {
	const auth = useAuth();
	const [isOpen, setIsOpen] = useState(true);

	if (auth?.roles?.hasRole) {
		return null;
	}

	return (
		<AlertDialog open={isOpen} onOpenChange={setIsOpen}>
			<AlertDialogContent>
				<AlertDialogHeader>
					<AlertDialogTitle>Organisation Missing</AlertDialogTitle>
					<AlertDialogDescription>Please add an organisation to continue</AlertDialogDescription>
				</AlertDialogHeader>
				<AlertDialogFooter>
					<AlertDialogCancel>Close</AlertDialogCancel>
					<AlertDialogAction asChild>
						<Link to="/profile/organisation">Add Organisation</Link>
					</AlertDialogAction>
				</AlertDialogFooter>
			</AlertDialogContent>
		</AlertDialog>
	);
}

export default MissingOrganisationAlert;
