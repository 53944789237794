import { Table, TableBody, TableCaption, TableCell, TableHead, TableRow } from '@/components/ui/table';
import type { FAQI } from '@/features/website/components/FaqBlock';

export const securityFAQs: FAQI[] = [
    {
        question: 'How do we keep data secure?',
        answer: (
            <p>
                Our Patient Watch servers are hosted in UK based ISO27001 certified data-centers. We follow best
                practice guidance from NHS Digital and the UK National Cyber Security Centre (NCSC).
            </p>
        ),
    },
    {
        question: 'Do we sell data?',
        answer: (
            <p>
                No Patient Watch data is sold to or shared with third parties. To learn more, see our{' '}
                <a
                    className="link"
                    href="https://www.notion.so/patient-watch/Acceptable-Use-Policy-92dc806b18724405927eb1c0e4cd9b21?pvs=4"
                    target="_blank"
                >
                    Acceptable Use Policy
                </a>
                .
            </p>
        ),
    },
    {
        question: 'Are we registered with the Medicines and Healthcare products Regulatory Agency (MHRA)?',
        answer: (
            <div>
                Yes, our software is classified as a Class I medical device:
                <div className="w-full max-w-xl border border-slate-300 rounded-sm">
                    <Table>
                        <TableCaption>Organisation Details.</TableCaption>
                        <TableBody>
                            <TableRow>
                                <TableHead className="font-medium">Company Name</TableHead>
                                <TableCell>Patient Watch Ltd</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableHead className="font-medium">Company Number</TableHead>
                                <TableCell>25102</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </div>
                <div className="w-full max-w-xl border border-slate-300 rounded-sm">
                    <Table>
                        <TableCaption>Device Details.</TableCaption>
                        <TableBody>
                            <TableRow>
                                <TableHead className="font-medium">Device Type</TableHead>
                                <TableCell>General Medical Device</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableHead className="font-medium">GDMN Code</TableHead>
                                <TableCell>60065</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableHead className="font-medium">GDMN Description</TableHead>
                                <TableCell>Configurable electronic form web-based application software</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableHead className="font-medium">Risk classification</TableHead>
                                <TableCell>Class I</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableHead className="font-medium">Sterile</TableHead>
                                <TableCell>No</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableHead className="font-medium">Conformity Assessment Type</TableHead>
                                <TableCell>MDD/IVDD/AIMD</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableHead className="font-medium">Declaration of Conformity</TableHead>
                                <TableCell>UKCA</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </div>
            </div>
        ),
    },
    {
        question: 'Are we registered with the Information Commissioners Office?',
        answer: (
            <div>
                Yes, we are registered with the ICO, our details are:
                <div className="w-full max-w-xl border border-slate-300 rounded-sm">
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableHead className="font-medium">Organisation name</TableHead>
                                <TableCell>Patient Watch Ltd</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableHead className="font-medium">Reference</TableHead>
                                <TableCell>ZB489383</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </div>
            </div>
        ),
    },
    {
        question: 'How do we send Text Messages?',
        answer: (
            <p>
                We use Twillio to send SMS messages. You can read the Twillio privacy policy{' '}
                <a className="link" href="https://www.twilio.com/en-us/legal/privacy" target="_blank">
                    here
                </a>
                .
            </p>
        ),
    },
    {
        question: 'How do we ensure that the right person gets the message?',
        answer: (
            <p>
                We use email and SMS verification to ensure all communications go to the correct recipient. However, it
                is impossible to be 100% sure that details added by the patients correspond to who reads the
                communications. Therefore, none of our SMS communications contain sensitive or time-critical
                information.
            </p>
        ),
    },
    {
        question: 'Can patients opt out of notifications?',
        answer: (
            <p>
                Yes. While the notifications help the app perform, to opt out users can simply navigate to their profile
                notification settings and opt out of push, SMS, or email notifications.
            </p>
        ),
    },
];
