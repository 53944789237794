import { colors } from '@/assets/colors';
import { icons } from '@/assets/icons';
import { useEffect, useState } from 'react';

import { useImagesStore } from '../../hooks/useImagesStore';
import { VariationsType } from '../../services/variations';
import useCheckFootMutation from './api/useCheckFootMutation';
import CheckFootModal from './CheckFootModal';

interface Props {
	variation: VariationsType;
	nextPage: () => void;
	retake: () => void;
	qualityCheck: number;
}

const CheckFoot = ({ retake, variation, nextPage, qualityCheck }: Props) => {
	const images = useImagesStore((state) => state.images);
	const updateChecked = useImagesStore((state) => state.updateChecked);
	const checkFoot = useCheckFootMutation();

	function checkFootMutation() {
		checkFoot.mutate({ img: images[variation]?.base64, variation: variation });
	}

	useEffect(() => {
		if (!images[variation]?.checked && !checkFoot.isPending) {
			checkFootMutation();
		}
	}, [qualityCheck, images, variation, checkFoot]);

	const [show, setShow] = useState(true);

	const skip = () => {
		updateChecked(images, variation, true);
		nextPage();
	};

	if (checkFoot.isPending) {
		return (
			<CheckFootModal
				footerFunc={retake}
				footer="Retake"
				footerFunc2={skip}
				footer2="Skip"
				showShowHide={false}
				show={show}
				setShow={setShow}
				title="Checking"
			>
				<icons.JazzySpinner
					fill={colors['indigo-600']}
					className="mx-auto mb-12 mt-6 h-[125px] max-h-[175px] min-h-[125px] w-[125px] min-w-[125px] max-w-[175px] md:h-[125px] md:w-[125px]"
				/>
			</CheckFootModal>
		);
	} else if (checkFoot.isError) {
		return (
			<CheckFootModal
				title="Error Checking the Image"
				description="Cannot check your image right now."
				footerFunc={skip}
				footer="Skip"
				footerFunc2={retake}
				footer2="Retake"
				show={show}
				setShow={setShow}
			>
				<div className="mx-auto max-h-[175px] max-w-[175px] md:h-[125px] md:w-[125px]">
					<icons.NoWifi />
				</div>
			</CheckFootModal>
		);
	} else if (images[variation]?.prediction) {
		if (images[variation]?.prediction.passed === true) {
			return (
				<CheckFootModal
					title="Checks Passed"
					show={show}
					setShow={setShow}
					footerFunc3={() => checkFootMutation()}
					footer3={'Check Again'}
					footer2="Retake"
					footerFunc2={retake}
					footer="Next"
					footerFunc={() => skip()}
				>
					<PassFail
						fail={!images[variation]?.prediction?.lit}
						words={images[variation]?.prediction?.lit ? 'Well Lit' : 'Too Dark'}
					/>
					<PassFail
						fail={images[variation]?.prediction?.quality !== 'Good Quality'}
						words={images[variation]?.prediction?.quality}
					/>
					<PassFail
						fail={images[variation]?.prediction?.distance !== 'Just Right'}
						words={images[variation]?.prediction?.distance}
					/>
					<PassFail
						fail={!images[variation]?.prediction?.centered}
						words={images[variation]?.prediction?.centered ? 'Centered' : 'Not Centered'}
					/>
				</CheckFootModal>
			);
		} else if (images[variation]?.prediction.foot === false) {
			return (
				<CheckFootModal
					title="No Foot in Photo"
					show={show}
					setShow={setShow}
					footerFunc3={checkFootMutation}
					footer3={'Check Again'}
					footerFunc2={() => skip()}
					footer2="Skip"
					footerFunc={retake}
					footer="Retake"
				>
					<div className="mx-auto max-h-[175px] max-w-[175px] md:h-[125px] md:w-[125px]">
						<icons.NoFoot />
					</div>
				</CheckFootModal>
			);
		} else {
			return (
				<CheckFootModal
					title="Issues Detected"
					show={show}
					setShow={setShow}
					footerFunc3={checkFootMutation}
					footer3={'Check Again'}
					footerFunc2={() => skip()}
					footer2="Skip"
					footerFunc={retake}
					footer="Retake"
				>
					<PassFail
						fail={!images[variation]?.prediction?.lit}
						words={images[variation]?.prediction?.lit ? 'Well Lit' : 'Too Dark'}
					/>
					<PassFail
						fail={images[variation]?.prediction?.quality !== 'Good Quality'}
						words={images[variation]?.prediction?.quality}
					/>
					<PassFail
						fail={images[variation]?.prediction?.distance !== 'Just Right'}
						words={images[variation]?.prediction?.distance}
					/>
					<PassFail
						fail={!images[variation]?.prediction?.centered}
						words={images[variation]?.prediction?.centered ? 'Centered' : 'Not Centered'}
					/>
				</CheckFootModal>
			);
		}
	} else if (!window?.navigator?.onLine) {
		return (
			<CheckFootModal
				title="No Connection"
				description="Cannot check your image right now."
				footerFunc={() => skip()}
				footer="Skip"
				footerFunc2={retake}
				footer2="Retake"
				show={show}
				setShow={setShow}
			>
				<div className="mx-auto max-h-[175px] max-w-[175px] md:h-[125px] md:w-[125px]">
					<icons.NoWifi />
				</div>
			</CheckFootModal>
		);
	} else {
		return (
			<CheckFootModal
				title="Whoops something went wrong"
				description="Cannot check your image right now."
				footerFunc={() => skip()}
				footer="Skip"
				footerFunc2={retake}
				footer2="Retake"
				show={show}
				setShow={setShow}
			>
				<div className="mx-auto max-h-[175px] max-w-[175px] md:h-[125px] md:w-[125px]">
					<icons.NoWifi />
				</div>
			</CheckFootModal>
		);
	}
};

export default CheckFoot;

interface PassFailI {
	fail: boolean;
	words: string;
}

const PassFail = ({ fail, words }: PassFailI) => {
	return (
		<div className="flex items-center p-2">
			<div className="mr-4 h-[20px] w-[20px] ">
				{fail ? <icons.Cross bg={colors['red-500']} /> : <icons.GreenTick />}
			</div>
			<p className="text-xl">{words}</p>
		</div>
	);
};
