import { ui } from '@/components/ui';
import EmailUs from '@/features/website/components/EmailUs';
import Section from '@/features/website/components/Section';
import SectionTitle from '@/features/website/components/SectionTitle';
import { useForm as formspree } from '@formspree/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { createFileRoute } from '@tanstack/react-router';
import { useForm } from 'react-hook-form';
import * as z from 'zod';

const FormSchema = z.object({
	name: z
		.string()
		.min(1, {
			message: 'This field has to be filled.',
		})
		.max(30, {
			message: 'Name must not be longer than 30 letters.',
		}),
	product: z
		.string()
		.min(1, {
			message: 'This field has to be filled.',
		})
		.max(30, {
			message: 'Name must not be longer than 30 letters.',
		}),
	organisation: z
		.string()
		.min(1, {
			message: 'This field has to be filled.',
		})
		.max(30, {
			message: 'Name must not be longer than 30 letters.',
		}),
	email: z.string().min(1, { message: 'This field has to be filled.' }).email('This is not a valid email.'),
	message: z
		.string()
		.min(10, {
			message: 'Message must be at least 10 characters.',
		})
		.max(200, {
			message: 'Message must not be longer than 200 characters.',
		}),
});

const Support = () => {
	const form = useForm<z.infer<typeof FormSchema>>({
		resolver: zodResolver(FormSchema),
	});

	const [state, handleSubmit] = formspree('xgejgkjv');

	if (state.succeeded) {
		return (
			<Section>
				<SectionTitle
					left={true}
					title="Thank You for Supporting Patient Watch"
					caption="Message Sent"
					description="Your message has been sent to the Patient Watch Team, we aim to get back to you within 48 hours."
				/>
			</Section>
		);
	}

	function onSubmit(values: z.infer<typeof FormSchema>) {
		handleSubmit(values);
	}

	return (
		<Section>
			<SectionTitle left={true} caption="Support" title="We are here to help" />
			<div className="flex w-full flex-col-reverse lg:flex-row-reverse">
				<EmailUs
					email="support@patient-watch.com"
					description="If you are a customer using one of our products and need help, please contact us with the form or email us."
				></EmailUs>

				<ui.Form {...form}>
					<form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6 text-lg lg:w-2/3 lg:pr-24">
						<ui.FormField
							control={form.control}
							name="name"
							render={({ field }) => (
								<ui.FormItem>
									<ui.FormLabel>Name</ui.FormLabel>
									<ui.FormControl>
										<ui.Input placeholder="e.g. Joe Di Maggio" {...field} />
									</ui.FormControl>
									<ui.FormMessage />
								</ui.FormItem>
							)}
						/>
						<ui.FormField
							control={form.control}
							name="product"
							render={({ field }) => (
								<ui.FormItem>
									<ui.FormLabel>Product</ui.FormLabel>
									<ui.FormControl>
										<ui.Input placeholder="e.g. Pain Watch" {...field} />
									</ui.FormControl>
									<ui.FormMessage />
								</ui.FormItem>
							)}
						/>
						<ui.FormField
							control={form.control}
							name="organisation"
							render={({ field }) => (
								<ui.FormItem>
									<ui.FormLabel>Organisation</ui.FormLabel>
									<ui.FormControl>
										<ui.Input placeholder="e.g. London Hospital" {...field} />
									</ui.FormControl>
									<ui.FormMessage />
								</ui.FormItem>
							)}
						/>
						<ui.FormField
							control={form.control}
							name="email"
							render={({ field }) => (
								<ui.FormItem>
									<ui.FormLabel>Email</ui.FormLabel>
									<ui.FormControl>
										<ui.Input placeholder="e.g. joe@mail.com" {...field} />
									</ui.FormControl>
									<ui.FormMessage />
								</ui.FormItem>
							)}
						/>
						<ui.FormField
							control={form.control}
							name="message"
							render={({ field }) => (
								<ui.FormItem>
									<ui.FormLabel>Message</ui.FormLabel>
									<ui.FormControl>
										<ui.Textarea placeholder="Let us know how we can help" className="resize-none" {...field} />
									</ui.FormControl>
									<ui.FormMessage />
								</ui.FormItem>
							)}
						/>
						<ui.Button type="submit">{state.submitting ? 'Submitting' : 'Submit'}</ui.Button>
					</form>
				</ui.Form>
			</div>
		</Section>
	);
};

export const Route = createFileRoute('/_website/support')({
	component: Support,
});
