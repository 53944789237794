import 'chartjs-adapter-date-fns';

import { Chart as ChartJS, type LegendOptions, registerables } from 'chart.js';
import { Chart } from 'react-chartjs-2';

import type { EntryValueViewT, ExtendedDiaryViewT } from '../../../types/database/diaries';
import { type LineSettingsT, generateData } from '../services/generateData';
import { defaultY, generateOptions } from '../services/generateOptions';

ChartJS.register(...registerables);

interface Props {
	entryValues: EntryValueViewT[];
	trackers: ExtendedDiaryViewT['trackers_view'];
	lineSettings?: LineSettingsT;
	legend?: LegendOptions<'line'>;
}
const y = defaultY;
y.max = 30;

const GlucoseChart = ({ entryValues, trackers, lineSettings, legend }: Props) => {
	const data = generateData({
		entryValues: entryValues,
		inputType: 'glucose-slider',
		trackers: trackers,
		lineSettings: lineSettings ?? {},
	});
	const options = generateOptions({ datasets: data, y: y, legend: legend });
	return <Chart type="line" options={options} className="max-h-[350px] min-h-[250px] w-full max-w-md" data={data} />;
};

export default GlucoseChart;
