import { Button } from '@/components/ui/button';
import type React from 'react';

import SectionTitle from './SectionTitle';

interface Props {
    caption?: string;
    title: React.ReactNode;
    description?: React.ReactNode;
    onClick?: () => void;
}

const HeroContent = ({ caption, title, description, onClick }: Props) => {
    return (
        <div className="flex flex-col w-full my-auto lg:mr-16">
            <SectionTitle caption={caption} left={true} title={title} description={description} />
            {onClick && (
                <Button onClick={onClick} variant="default" size="default" className="mx-auto mt-8 lg:ml-0 lg:mr-auto">
                    Learn More
                </Button>
            )}
        </div>
    );
};

export default HeroContent;
