import { createFileRoute } from '@tanstack/react-router';
import Title from '../../../components/common/Title';

const ClinicianHelpPage = () => {
	return (
		<section className="lg-width">
			<Title words="Help for Clincians" />
			<div className="relative mx-4 p-8 pb-[56.25%] max-w-lg">
				<iframe
					title="Patient Watch Explainer for Clincians"
					src="https://www.loom.com/embed/36aa4a5420e545bab90e39802bc18302?sid=3c74123d-d587-45c0-bc5c-2d5fa0c1a836"
					allowFullScreen
					className="top-0 left-0 absolute w-full h-full"
				/>
			</div>
		</section>
	);
};

export const Route = createFileRoute('/_app/help/clinician')({
	component: ClinicianHelpPage,
});
