import { Send } from 'lucide-react';
import React from 'react';

interface Props {
    description: string;
    email: string;
}

const EmailUs = ({ description, email }: Props) => {
    return (
        <div className="lg:w-1/3 mt-16 items-center text-center px-4 md:text-left md:items-start md:px-0 md:mb-0 flex flex-col text-slate-800 gap-y-4 text-lg">
            <Send size={48} />
            <h3 className="text-xl font-medium">Email us</h3>
            <p className="text-slate-600">{description}</p>
            <a className="text-indigo-600" href={`mailto:${email}`}>
                {email}
            </a>
        </div>
    );
};

export default EmailUs;
