import { colors } from '../colors';

interface Props {
	className?: string;
}
const OkHand = ({ className }: Props) => {
	return (
		<svg
			role="img"
			aria-label="OK Hand Icon"
			className={className}
			id="emoji"
			viewBox="0 0 72 72"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g id="color" />
			<g id="hair" />
			<g id="skin">
				<path
					fill={colors['yellow-300']}
					stroke={colors['yellow-300']}
					strokeMiterlimit="10"
					strokeWidth="1.8"
					d="M56.4257,39.17c0.03-0.18,0.03-0.36,0.03-0.55 c0,0,0.33-21.03,0.3-21.34c-0.19-1.94-1.92-3.36-3.86-3.17c-1.94,0.19-3.36,1.91-3.17,3.86l0.7104,13.2212 c-1.42-5.66-5.6404-21.6412-5.7304-21.8712c-0.68-1.83-2.71-2.76-4.54-2.08c-1.83,0.69-2.75,2.72-2.07,4.55l1.7127,6.7546 L42.3757,28.68v-0.01c-2.6-4.36-10.55-17.72-10.71-17.93c-1.18-1.55-3.4-1.85-4.95-0.67c-1.55,1.17-1.86,3.39-0.68,4.94 l5.3325,9.1368l7.5566,13.0352l-6.2691-7.432c-0.16-0.27-0.36-0.51-0.59-0.72c-0.55-0.51-1.22-0.81-1.92-0.91 c-0.59-0.19-1.22-0.23-1.87-0.11l-10.0796,1.47c-0.32,0-0.63,0.04-0.93,0.12c-1.88,0.51-2.99,2.45-2.48,4.33 c0.51,1.89,2.45,3,4.34,2.48l8.8796-1.3l5.651,7.1064c-1.3254,3.8368-1.2485,6.1236-3.2986,7.1812 c-3.9119-1.3794-7.9247-1.9453-7.9247-1.9453V42.06c0.04-0.31,0.04-0.62,0-0.93c-0.26-1.93-2.03-3.29-3.96-3.03 c-1.94,0.25-3.3,2.03-3.04,3.96v8.14l0.0716,0.2227c0.0875,1.9615,0.7117,3.8226,1.5117,4.4226 c1.1808,1.3968,3.0242,3.1904,4.9271,5.0504c4.3858,3.3314,10.4516,6.0706,15.5078,5.8058 c11.0842,0,20.0698-8.9855,20.0698-20.0698c0-2.2878-0.3798-4.4198-1.0843-6.4559"
				/>
			</g>
			<g id="skin-shadow" />
			<g id="line">
				<path
					fill="none"
					stroke="#000000"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
					d="M15.4577,50.2v-8.14 c-0.26-1.93,1.1-3.71,3.04-3.96c1.93-0.26,3.7,1.1,3.96,3.03c0.04,0.31,0.04,0.62,0,0.93v5.3923"
				/>
				<path
					fill="none"
					stroke="#000000"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
					d="M56.4614,39.1759 c0.7045,2.036,1.0843,4.1681,1.0843,6.4559c0,11.0842-8.9855,20.0698-20.0698,20.0698c-5.0562,0.2648-11.122-2.4744-15.5078-5.8058 c-1.9029-1.86-3.7464-3.6536-4.9271-5.0504c-0.8-0.6-1.4242-2.4611-1.5117-4.4226"
				/>
				<path
					fill="none"
					stroke="#000000"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
					d="M33.681,42.2164 c-1.3254,3.8368-1.2485,6.1236-3.2986,7.1812c-3.9119-1.3794-7.9247-1.9453-7.9247-1.9453"
				/>
				<path
					fill="none"
					stroke="#000000"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
					d="M28.03,35.11l-8.8796,1.3 c-1.89,0.52-3.83-0.59-4.34-2.48c-0.51-1.88,0.6-3.82,2.48-4.33c0.3-0.08,0.61-0.12,0.93-0.12L28.3,28.01 c0.65-0.12,1.28-0.08,1.87,0.11"
				/>
				<line
					x1="33.681"
					x2="28.03"
					y1="42.2164"
					y2="35.11"
					fill="none"
					stroke="#000000"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
				/>
				<path
					fill="none"
					stroke="#000000"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
					d="M30.17,28.12 c0.7,0.1,1.37,0.4,1.92,0.91c0.23,0.21,0.43,0.45,0.59,0.72l6.2691,7.432"
				/>
				<path
					fill="none"
					stroke="#000000"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
					d="M31.3925,24.1468 L26.06,15.01c-1.18-1.55-0.87-3.77,0.68-4.94c1.55-1.18,3.77-0.88,4.95,0.67c0.16,0.21,8.11,13.57,10.71,17.93v0.01"
				/>
				<path
					fill="none"
					stroke="#000000"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
					d="M50.4604,31.1912 C49.0404,25.5312,44.82,9.55,44.73,9.32c-0.68-1.83-2.71-2.76-4.54-2.08c-1.83,0.69-2.75,2.72-2.07,4.55l1.7127,6.7546"
				/>
				<path
					fill="none"
					stroke="#000000"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
					d="M49.75,17.97 c-0.19-1.95,1.23-3.67,3.17-3.86c1.94-0.19,3.67,1.23,3.86,3.17c0.03,0.31-0.3,21.34-0.3,21.34c0,0.19,0,0.37-0.03,0.55"
				/>
				<line
					x1="41.8254"
					x2="45.4061"
					y1="27.7921"
					y2="33.4468"
					fill="none"
					stroke="#000000"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
				/>
			</g>
		</svg>
	);
};

export default OkHand;
