import { supabaseClient } from '@/lib/supabase';
import { queryOptions, useQuery } from '@tanstack/react-query';

export async function selectFormImageById(imageId: string) {
	const { data } = await supabaseClient.storage.from('form_images').getPublicUrl(`${imageId}.png`);
	return data;
}

export const selectFormImageByIdQueryOptions = (imageId: string) =>
	queryOptions({
		queryKey: ['form-image', { image_id: imageId }],
		queryFn: async () => selectFormImageById(imageId),
	});

export function useSelectFormImageById(imageId: string) {
	const options = selectFormImageByIdQueryOptions(imageId);
	return useQuery(options);
}
