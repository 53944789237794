import { supabaseClient } from '@/lib/supabase';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'sonner';

import type { DashboardSearchT } from '@/routes/_app/dashboard';
import type { DashboardSettings } from '@/types/database/profiles';
import { useNavigate } from '@tanstack/react-router';
import { useRefetchDashboardData } from '../features/overview/table/hooks/useRefetchDashboardData';

export async function upsertDashboardSettings(dashboardSettings: DashboardSettings['Insert']) {
	const { data, error } = await supabaseClient
		.from('dashboard_settings')
		.upsert(dashboardSettings)
		.select()
		.maybeSingle();

	if (error) console.error(error);
	return data;
}

export const useUpsertDashboardSettings = () => {
	const refetchDashboardData = useRefetchDashboardData();
	const navigate = useNavigate();
	return useMutation({
		mutationKey: ['entry'],
		mutationFn: async (dashboardSettings: DashboardSettings['Insert']) => {
			return await upsertDashboardSettings(dashboardSettings);
		},
		onError: (error) => {
			toast.error(error.message);
		},
		onSuccess: (data) => {
			toast.info('Dashboard Settings Saved');
			return data;
		},
		onSettled: (data) => {
			navigate({ search: (prev: DashboardSearchT) => ({ ...prev, ...data }) });
			refetchDashboardData();
			window.location.reload();
		},
	});
};
