import type { EntryValueT } from '@/types/database/diaries';
import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

import { updateEntryValues } from './updateEntryValues';

export type UpsertEntryValueT = Partial<EntryValueT> & { entry_id: string; input_id: string };

type State = {
	entry_values: EntryValueT[];
};

type Actions = {
	upsertEntryValue: (entryValue: UpsertEntryValueT) => void;
	upsertEntryValues: (entryValues: UpsertEntryValueT[]) => void;
	getEntryValues: () => EntryValueT[];
	resetEntryValues: () => void;
	getEntryValueByInputId: (input_id: string) => EntryValueT | null;
};

const initialState: State = {
	entry_values: [],
};

export const useEntryValuesStore = create<State & Actions>()(
	devtools(
		persist(
			(set, get) => ({
				...initialState,
				getEntryValues: () => {
					return get().entry_values;
				},
				resetEntryValues: () => {
					set({ ...get(), entry_values: [] });
				},
				getEntryValueByInputId: (input_id: string) => {
					const entryValues = get().entry_values;
					return entryValues.find((ev) => ev.input_id === input_id) ?? null;
				},
				upsertEntryValue: (newVal: UpsertEntryValueT) => {
					set((state) => {
						const updatedEntryValues = updateEntryValues(state.entry_values, newVal);
						return { entry_values: updatedEntryValues };
					});
				},
				upsertEntryValues: (newVals: UpsertEntryValueT[]) => {
					set((state) => {
						let updatedEntryValues = [...state.entry_values];
						newVals.forEach((newVal) => {
							updatedEntryValues = updateEntryValues(updatedEntryValues, newVal);
						});
						return { entry_values: updatedEntryValues };
					});
				},
			}),
			{
				name: 'entry-values-store',
				storage: createJSONStorage(() => localStorage),
			},
		),
	),
);
