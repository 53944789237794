import Title from '@/components/common/Title';
import { formComponents as fc } from '@/components/form';
import { Button } from '@/components/ui/button';
import { Form } from '@/components/ui/form';
import { useProfileQuery } from '@/features/profile/api/selectProfile';
import useUpsertNotificationPreferenceMutation from '@/features/profile/api/upsertNotificationPreferences';
import { useAuth } from '@/utils/auth';
import { zodResolver } from '@hookform/resolvers/zod';
import { createFileRoute } from '@tanstack/react-router';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';
import * as z from 'zod';

// import useOneSignalMutation from '@/pwa/oneSignal/hooks/useOneSignalMutation';
const OneSignalFormSchema = z.object({
	user_id: z.string().uuid(),
	push_allowed: z.boolean(),
	email_allowed: z.boolean(),
	sms_allowed: z.boolean(),
});

function Notifications() {
	const auth = useAuth();
	const { data: profile } = useProfileQuery();

	// const oneSignalMutation = useOneSignalMutation();
	const notificationsMutation = useUpsertNotificationPreferenceMutation(auth.id);

	// const fieldArray = useFieldArray();

	// const push = Object.values(permissions?.push).every((item) => item === true);

	const form = useForm<z.infer<typeof OneSignalFormSchema>>({
		resolver: zodResolver(OneSignalFormSchema),
		defaultValues: {
			user_id: profile?.user_id,
			push_allowed: profile?.push_allowed,
			email_allowed: profile?.email_allowed,
			sms_allowed: profile?.sms_allowed,
		},
	});

	useEffect(() => {
		form.setValue('email_allowed', profile.email_allowed);
		form.setValue('sms_allowed', profile.sms_allowed);
	}, [profile]);

	async function onSubmit(data: z.infer<typeof OneSignalFormSchema>) {
		// Update OneSignal
		navigator.permissions.query({ name: 'notifications' }).then((result) => {
			if (data.push_allowed === true) {
				if (result.state === 'denied') {
					toast.error('To enable push notifications, you need to enable notifications in your app settings.');
					return;
				}
				if (result.state === 'prompt') {
					let newPrompt;
					newPrompt = Notification.requestPermission();
				}
			}
		});

		// Update Supabase Table
		await notificationsMutation.mutate(data);
		// Update OneSignal Permissions
		// await oneSignalMutation.mutate(data);
	}

	return (
		<>
			<Title words="Notifications" description="Reminders are critical to how Patient Watch improves your health." />
			<Form {...form}>
				<form onSubmit={form.handleSubmit(onSubmit)} className="w-full space-y-6">
					<div>
						<h3 className="mb-4 text-lg font-medium">Notifications</h3>
						<div className="space-y-4">
							<fc.Switch name="email_allowed" label="Email" description="Receive emails reminders." />
							<fc.Switch name="sms_allowed" label="SMS" description="Receive text reminders." />
						</div>
					</div>
					<Button size="lg" type="submit" disabled={form.formState.isSubmitting}>
						Save
					</Button>
				</form>
			</Form>
		</>
	);
}

export const Route = createFileRoute('/_app/profile/notifications')({
	component: Notifications,
});

export default Notifications;
