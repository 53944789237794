import type { StatefulTrackerViewT } from '../../../types/database/trackers';
import { useDiaryByIdQuery } from '../../diary/api/getDiaryById';
import { extractTrackerById } from '../../diary/services/extractTrackerById';

const useExtractTracker = (diaryId: string, trackerId: string): StatefulTrackerViewT => {
	const { data: diary } = useDiaryByIdQuery(diaryId);
	return extractTrackerById(diary, trackerId) as StatefulTrackerViewT;
};

export default useExtractTracker;
