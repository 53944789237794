import Title from '@/components/common/Title';
import { selectOrganisationStatisticsQueryOptions } from '@/features/admin/api/selectOrganisationsStatistics';
import OrganisationStatisticsTable from '@/features/admin/components/OrganisationsStatisticsTable';
import { createFileRoute } from '@tanstack/react-router';
import { components as c } from '../../../components';
const OrganisationsAdminPage = () => {
	const { orgStats } = Route.useLoaderData();
	return (
		<div className="lg-width">
			<Title words="Organisations Statistics" />
			<c.ui.Card className="p-4">
				<OrganisationStatisticsTable organisationStatistics={orgStats} />
			</c.ui.Card>
		</div>
	);
};

export const Route = createFileRoute('/_app/admin/organisations-statistics')({
	component: OrganisationsAdminPage,
	loader: async ({ context }) => {
		const orgStats = await context?.queryClient.ensureQueryData(selectOrganisationStatisticsQueryOptions());
		return { orgStats };
	},
});
