import { ui } from '@/components/ui';
import type { TrackerTemplateViewT } from '@/types/database/trackers';
import { useTrackerMutation } from '../api/upsertTracker';
import { trackerTemplateToTracker } from '../utils/mapTrackerTemplateToTracker';

interface Props {
	diaryId: string;
	tracker_template: TrackerTemplateViewT;
}

const CreateTrackerButton = ({ diaryId, tracker_template }: Props) => {
	const upsertTracker = useTrackerMutation({ diaryId: diaryId });
	const createTracker = () => {
		const data = trackerTemplateToTracker(diaryId, tracker_template);
		upsertTracker.mutate(data);
	};

	return (
		<ui.Button variant="default" size="lg" onClick={createTracker}>
			{tracker_template.tracker_template_name}
		</ui.Button>
	);
};

export default CreateTrackerButton;
