import { colors } from '../colors';

interface Props {
	className?: string;
	fill?: string;
}
const Warning = (props: Props) => {
	return (
		<svg
			role="img"
			aria-label="Barrier Icon"
			id="emoji"
			className={props.className}
			viewBox="0 0 72 72"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g id="color">
				<path
					fill="#fff"
					d="m15.35,59.08h-1.779c-.6839,0-1.238-.578-1.238-1.291V22.959c0-.713.5544-1.291,1.238-1.291h1.779c.6839,0,1.238.578,1.238,1.291v34.83c0,.713-.5545,1.291-1.238,1.291Z"
				/>
				<path
					fill="#fff"
					d="m58.46,59.08h-1.779c-.6839,0-1.238-.7131-1.238-1.593V24.517c0-.8796.5544-1.593,1.238-1.593h1.779c.6839,0,1.238.7131,1.238,1.593v32.97c0,.8796-.5545,1.593-1.238,1.593Z"
				/>
				<path fill={colors['yellow-300']} d="m7.45,43.47h7.743l-7.743,7.743v-7.743Z" />
				<path fill={colors['slate-700']} d="m15.19,43.47h12.34l-7.743,7.743-12.34-.0581,7.743-7.6849Z" />
				<path fill={colors['yellow-300']} d="m27.48,43.47h12.34l-7.743,7.743h-12.29l7.693-7.743Z" />
				<path fill={colors['slate-700']} d="m39.82,43.47h12.34l-7.743,7.743h-12.34l7.743-7.743Z" />
				<path fill={colors['yellow-300']} d="m52.16,43.47l11.89-.1839-7.9181,7.9511h-11.7643l7.7924-7.7672Z" />
				<path fill={colors['slate-700']} d="m64.0079,43.3319v7.612h-7.612l7.612-7.612Z" />
				<path fill={colors['yellow-300']} d="m7.95,28.33l7.646-.0337-7.646,7.646v-7.6123Z" />
				<path fill={colors['slate-700']} d="m15.6,28.3h11.94l-7.695,7.646H7.955l7.645-7.646Z" />
				<path fill={colors['yellow-300']} d="m27.48,28.3h12.34l-7.743,7.743h-12.29l7.693-7.743Z" />
				<path fill={colors['slate-700']} d="m39.82,28.3h12.34l-7.743,7.743h-12.34l7.743-7.743Z" />
				<path
					fill={colors['yellow-300']}
					d="m52.16,28.3l10.9084-.0069-.015,1.0493-6.6154,6.6036-12.02.0976,7.742-7.7436Z"
				/>
				<path fill={colors['slate-700']} d="m64.05,28.33v7.612h-7.612l7.612-7.612Z" />
			</g>
			<g id="line">
				<path
					fill="none"
					stroke="#000"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
					d="m12.74,42.72v-6.348"
				/>
				<path
					fill="none"
					stroke="#000"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
					d="m16.92,50.9v6.509c0,.6723-.5448,1.217-1.217,1.217h-1.748c-.672,0-1.217-.5451-1.217-1.217v-6.071"
				/>
				<path
					fill="none"
					stroke="#000"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
					d="m16.92,36.37v6.348"
				/>
				<path
					fill="none"
					stroke="#000"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
					d="m12.74,28.24v-5.907m4.181,0v5.812"
				/>
				<path
					fill="none"
					stroke="#000"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
					d="m55.1,43.29v-6.921"
				/>
				<path
					fill="none"
					stroke="#000"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
					d="m59.28,51.34v6.071c0,.6723-.5448,1.217-1.217,1.217h-1.748c-.672,0-1.217-.5451-1.217-1.217v-6.071"
				/>
				<path
					fill="none"
					stroke="#000"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
					d="m59.28,36.37v5.969"
				/>
				<path
					fill="none"
					stroke="#000"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
					d="m55.1,28.03v-5.891m4.181,0v5.796"
				/>
				<path
					fill="none"
					stroke="#000"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
					d="m7.95,28.33h56.1v7.612H7.95v-7.612Z"
				/>
				<path
					fill="none"
					stroke="#000"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
					d="m7.95,43.29h56.1v7.612H7.95v-7.612Z"
				/>
				<circle
					cx="14.83"
					cy="17.97"
					r="4.594"
					fill="none"
					stroke="#000"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
				/>
				<circle
					cx="57.19"
					cy="17.97"
					r="4.594"
					fill="none"
					stroke="#000"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
				/>
			</g>
			<g id="color-foreground">
				<circle
					cx="14.835"
					cy="17.966"
					r="4.594"
					fill={colors['orange-300']}
					stroke={colors['orange-400']}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
				/>
				<circle
					cx="57.185"
					cy="17.966"
					r="4.594"
					fill={colors['orange-300']}
					stroke={colors['orange-400']}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
				/>
			</g>
		</svg>
	);
};

export default Warning;
