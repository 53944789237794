import { formComponents as fc } from '@/components/form';
import { ui } from '@/components/ui';
import { type SubmitHandler, useForm, z, zodResolver } from '@/utils/form';

import { useAuth } from '../../../../../utils/auth';
import { useAnatomicRegionsQuery } from '../../../api/selectAnatomicRegions';
import { useUpsertSurgeryOption } from '../api/upsertSurgeryOption';

interface Props {
	anatomicRegion?: string;
	onSuccess?: () => void;
}

const SurgerySchemaZ = z.object({
	created_by: z.string(),
	orthopaedic_area: z.string(),
	surgery_name: z.string().optional(),
});
type SurgerySchemaT = z.infer<typeof SurgerySchemaZ>;

const SurgeryOptionForm = ({ anatomicRegion, onSuccess }: Props) => {
	const auth = useAuth();
	const { data: anatomicRegions } = useAnatomicRegionsQuery();
	const surgeryMutation = useUpsertSurgeryOption();

	const form = useForm<SurgerySchemaT>({
		resolver: zodResolver(SurgerySchemaZ),
		defaultValues: {
			created_by: auth.id,
			orthopaedic_area: anatomicRegion,
		},
	});

	const onSubmit: SubmitHandler<SurgerySchemaT> = async (formData) => {
		surgeryMutation.mutate(formData, { onSuccess: onSuccess });
	};

	return (
		<ui.Form {...form}>
			<form onSubmit={form.handleSubmit(onSubmit)} className="form">
				{/* Referrer */}
				<fc.Select
					name="orthopaedic_area"
					label="Anatomic Area"
					options={anatomicRegions?.map((ar) => ({ label: ar.orthopaedic_area, value: ar.orthopaedic_area })) ?? []}
				/>
				<fc.Input name="surgery_name" label="Surgery" />
				<ui.Button size="lg" type="submit">
					Save
				</ui.Button>
			</form>
		</ui.Form>
	);
};

export default SurgeryOptionForm;
