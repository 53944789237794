import { supabaseClient } from '@/lib/supabase';
import { queryOptions, useQuery } from '@tanstack/react-query';
import type { DiaryViewT } from '../../../types/database/diaries';

export async function selectDiariesView() {
	const { data, error } = await supabaseClient.from('diaries_view').select('*').returns<DiaryViewT[]>();
	if (error) throw error;
	return data;
}

export const diariesViewQueryKey = ['diaries_view'];

export const selectDiariesViewQueryOptions = () => {
	return queryOptions({
		queryKey: diariesViewQueryKey,
		queryFn: () => selectDiariesView(),
	});
};

export function useDiariesViewQuery() {
	return useQuery(selectDiariesViewQueryOptions());
}
