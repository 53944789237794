import { commonComponents as cc } from '@/components/common';
import { ui } from '@/components/ui';
import { patientDiariesQueryOptions } from '@/features/diaries/api/patientDiariesQuery';
import DiaryList from '@/features/diaries/components/DiaryList';
import NewDiaryButton from '@/features/diaries/components/NewDiaryButton';
import { useSuspenseQuery } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import { useNav } from '../../utils/nav';

const Diaries = () => {
	useNav([{ label: 'Diaries', route: { to: '/diaries' } }]);

	const { auth } = Route.useRouteContext();
	const { data: diaries } = useSuspenseQuery(patientDiariesQueryOptions(auth.id));

	return (
		<section className="md-width">
			<cc.Title words="Diaries">
				{diaries?.length === 0 ? (
					<ui.Card className="mt-4">
						<ui.CardHeader>
							<ui.CardTitle>No Diaries Yet</ui.CardTitle>
							<ui.CardDescription>To start using Patient Watch, first please create a diary.</ui.CardDescription>
						</ui.CardHeader>
						<ui.CardFooter>
							<NewDiaryButton />
						</ui.CardFooter>
					</ui.Card>
				) : (
					<NewDiaryButton />
				)}
			</cc.Title>

			{diaries?.length > 0 && <DiaryList diaries={diaries} />}
		</section>
	);
};

export const Route = createFileRoute('/_app/diaries')({
	beforeLoad: ({ context }) => {
		return {
			auth: context.auth,
		};
	},
	loader: async ({ context }) => {
		await context?.queryClient.ensureQueryData(patientDiariesQueryOptions(context.auth.id));
	},
	component: Diaries,
});
