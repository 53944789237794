import { supabaseClient } from '@/lib/supabase';
import type { UsersOrganisationsRolesT } from '@/types/database/profiles';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';

import { createProfileQueryKey } from '../../profile/api/selectProfile';
import { createUsersOrganisationsRolesQueryKey } from './selectUsersOrganisationsRoles';

export async function deleteUserOrganisationRole(userOrganisationRole: UsersOrganisationsRolesT) {
	return await supabaseClient.functions.invoke('public-delete-user-organisation-role', { body: userOrganisationRole });
}

export const addOrganisationKey = () => ['delete-organisation'];

export const useDeleteOrganisationMutation = (userId: string) => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationKey: addOrganisationKey(),
		mutationFn: (data: UsersOrganisationsRolesT) => deleteUserOrganisationRole(data),
		onSuccess: () => {
			toast.info('Organisation deleted');
		},
		onError: (error) => {
			toast.error(error.message);
		},
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey: createUsersOrganisationsRolesQueryKey(userId) });
			queryClient.invalidateQueries({ queryKey: createProfileQueryKey(userId) });
		},
	});
};
