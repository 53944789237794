import { useKnockMessagesByUserQuery } from '@/features/knock/api/getKnockMessagesByUser';
import { selectProfileByIdQueryOptions } from '@/features/profile/api/selectProfile';
import Profile from '@/features/profile/components/Profile';
import type { ExtendedProfileT } from '@/types/database/profiles';
import { createFileRoute } from '@tanstack/react-router';

const UserIdPage = () => {
	const { profile } = Route.useLoaderData();

	return (
		<section className="md-width">
			<Profile profile={profile as ExtendedProfileT} />
		</section>
	);
};
export const Route = createFileRoute('/_app/admin/user/$userId')({
	component: UserIdPage,
	loader: async ({ context, params }) => {
		const profile = await context?.queryClient.ensureQueryData(selectProfileByIdQueryOptions(params.userId));
		return { profile };
	},
});
