import { type ButtonSize, type ButtonVariant, buttonVariants } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { Route as NewEventRoute } from '@/routes/_app/new-diary/patient/$patientId/organisation/$organisationId/diary/$diaryId/event/$eventId/event-options';
import type { StatefulDiaryT } from '@/types/database/diaries';
import { Link } from '@tanstack/react-router';
import { Syringe } from 'lucide-react';

interface Props {
	diary: StatefulDiaryT;
	trackerId?: string;
	variant?: ButtonVariant;
	size?: ButtonSize;
	className?: string;
}
const NewEventLink = ({ diary, trackerId, variant, size, className }: Props) => {
	return (
		<Link
			className={cn(buttonVariants({ size: size ?? 'lg', variant: variant ?? 'secondary' }), className)}
			to={NewEventRoute.to}
			params={{ diaryId: diary.diary_id, patientId: diary.patient_id!, organisation_id: diary.organisation_id }}
			search={{ trackerId: trackerId }}
		>
			<Syringe />
			Add Event
		</Link>
	);
};

export default NewEventLink;
