import { commonComponents as cc } from '@/components/common';
import DeleteDiaryButton from '@/features/diary/edit/components/DeleteDiaryButton';
import { EventPreviews } from '@/features/events/components/EventPreview';
import NewEventLink from '@/features/events/components/NewEventLink';
import EditTracker from '@/features/trackers/components/EditTracker';
import NewTrackerLink from '@/features/trackers/components/NewTrackerLink';
import { cn } from '@/lib/utils';
import { services as s } from '@/services';
import type { StatefulDiaryT } from '@/types/database/diaries';
import { useAuth } from '@/utils/auth';
import { Fragment } from 'react';
import DiaryEndDateForm from './DiaryEndDateForm';
import DiaryNameForm from './DiaryNameForm';
interface Props {
	diary: StatefulDiaryT;
}
const EditDiary = ({ diary }: Props) => {
	const auth = useAuth();

	return (
		<section className="flex flex-col">
			<cc.Group title="Diary Details">
				<cc.EditDialog label="Diary Name" info={diary.diary_name}>
					<DiaryNameForm diary={diary} diaryId={diary.diary_id} />
				</cc.EditDialog>
				<cc.EditDialog
					show={auth.isSuperUser}
					label="End Date"
					info={s.time.formatShortDateTime(diary.end_date)}
					noInfo={!diary.end_date}
				>
					<DiaryEndDateForm diary={diary} />
				</cc.EditDialog>
			</cc.Group>
			{diary.trackers_view.map((tv) => (
				<Fragment key={tv.tracker_id}>
					<EditTracker tracker={tv} />
				</Fragment>
			))}
			<cc.Group title="Events">
				<EventPreviews events={diary.events_view} />
			</cc.Group>

			<div className={cn('flex-col gap-4', auth.isSuperUser ? 'flex' : 'hidden')}>
				<NewTrackerLink className="flex-grow px-2" diary={diary} />
				<NewEventLink className="flex-grow px-2" diary={diary} />
				<DeleteDiaryButton diaryId={diary.diary_id} />
			</div>
		</section>
	);
};

export default EditDiary;
