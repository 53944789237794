const TopOfFootDrawing = () => {
    return (
        <svg
            className="w-full h-full"
            width="357"
            height="465"
            viewBox="0 0 357 465"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="297.365"
                y="175.725"
                width="8"
                height="57"
                rx="1"
                transform="rotate(66.067 297.365 175.725)"
                fill="black"
            />
            <circle cx="93.5" cy="49" r="47" fill="white" stroke="black" strokeWidth="4" />
            <path
                d="M51.2035 266.523L50.6084 156.75C50.5372 143.613 53.6754 130.658 59.7503 119.01C63.9204 111.014 72.1902 106 81.208 106H104.251C113.414 106 121.817 111.095 126.054 119.219C132.062 130.739 135.246 143.522 135.342 156.514L136.122 261.645C136.162 267.139 140.628 271.571 146.121 271.571H219.876C231.797 271.571 242.586 278.629 247.363 289.551L300.026 409.986C301.121 412.49 304.023 413.651 306.543 412.593L328.151 403.523C336.195 400.147 345.473 403.658 349.265 411.514C353.244 419.755 349.707 429.657 341.409 433.513L295.206 454.986C285.118 459.674 273.142 455.229 268.556 445.094L215.16 327.102C213.54 323.523 209.977 321.224 206.049 321.224H106.203C75.9434 321.224 51.3675 296.781 51.2035 266.523Z"
                fill="white"
                stroke="black"
                strokeWidth="4"
            />
            <path
                d="M140.523 214.295L81.0188 155.252C68.3238 142.655 77.2442 121 95.1284 121C100.416 121 105.49 123.091 109.242 126.817L158.572 175.795C164.191 181.375 171.79 184.506 179.709 184.506H243.253C253.883 184.506 262.5 193.123 262.5 203.753C262.5 214.383 253.883 223 243.253 223H161.654C153.738 223 146.142 219.871 140.523 214.295Z"
                fill="white"
                stroke="black"
                strokeWidth="4"
            />
            <path d="M167.5 338L194.5 458" stroke="black" strokeWidth="12" strokeLinecap="round" />
            <path d="M71.5 338L44.5 458" stroke="black" strokeWidth="12" strokeLinecap="round" />
            <path
                d="M11 137.5L35.1143 279.703C40.8228 313.367 69.9851 338 104.129 338H189.5"
                stroke="black"
                strokeWidth="20"
                strokeLinecap="round"
            />
        </svg>
    );
};

export default TopOfFootDrawing;
