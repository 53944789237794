import { Link } from '@tanstack/react-router';

import Barrier from '../../assets/openmoji/Barrier';
import { commonComponents as cc } from '../common';
import { ui } from '../ui';

interface Props {
	message: React.ReactNode;
}

const UnauthorizedPage = ({ message }: Props) => {
	return (
		<section className="md-width">
			<cc.Title words="Unauthorized" />
			<Barrier className="mx-auto flex h-60 w-60" />
			<div className="mx-auto flex w-full flex-col items-center justify-center gap-8 p-4 text-center">
				<p className="text-lg"> {message}</p>

				<ui.Button>
					<Link to="/profile">Continue</Link>
				</ui.Button>
			</div>
		</section>
	);
};

export default UnauthorizedPage;
