import React from 'react';

const Empty = () => {
    return (
        <svg width="31" height="32" viewBox="0 0 31 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="0.5" width="31" height="31" rx="8" fill="white" />
        </svg>
    );
};

export default Empty;
