import DeleteTrackerButton from './DeleteTrackerButton';
import EditTracker from './EditTracker';
import ManagementTypeBadges from './ManagementTypeBadges';
import ManagementTypeLinks from './ManagementTypeLinks';
import MissingTrackerManagements from './MissingTrackerManagements';
import TrackerBody from './TrackerBody';
import TrackerCard from './TrackerCard';
import TrackerCheckCard from './TrackerCheckCard';
import TrackerCheckForm from './TrackerCheckForm';
import TrackerDialog from './TrackerDialog';
import TrackerEntries from './TrackerEntries';
import TrackerForm from './TrackerForm';
import TrackerOptionCard from './TrackerOptionCard';
import TrackerPreview from './TrackerPreview';

export const trackerComponents = {
	DeleteTrackerButton,
	EditTracker,
	TrackerEntries,
	ManagementTypeBadges,
	MissingManagementTypes: MissingTrackerManagements,
	ManagementTypeLinks,
	TrackerCheckCard,
	TrackerCheckForm,
	TrackerCard,
	TrackerPreview,
	TrackerDialog,
	TrackerForm,
	TrackerOptionCard,
	TrackerTitle: TrackerPreview,
	TrackerBody,
};
