import { cn } from '@/lib/utils';
import type { EntryValueViewT } from '@/types/database/diaries';

interface Props {
	entryValue: EntryValueViewT;
	size?: 'sm' | 'md';
}

const EntryValueSwitch = ({ entryValue, size }: Props) => {
	if (!entryValue.value) return null;

	switch (entryValue.input_key) {
		case 'long-text':
			return (
				<div className="border-slate-300 p-2 pt-1 border rounded-md w-full">
					<span className="min-w-16 font-medium text-slate-500 dark:text-slate-300">{entryValue.input_label}</span>
					<p className='dark:text-slate-200'>{entryValue.text_value ?? 'None'}</p>
				</div>
			);
		case 'duration':
			return (
				<div className="border-slate-300 p-2 pt-1 border rounded-md w-full">
					<span className="min-w-16 font-medium text-slate-500">{entryValue.input_label}</span>
					<p>
						{entryValue.number_value} {entryValue.duration_time_unit ?? ''}
					</p>
				</div>
			);
		case 'slider':
			return (
				<div>
					<span className="min-w-16 text-slate-700">{entryValue.input_label}</span>
					<div style={{ width: '120px' }} className="relative bg-slate-300 rounded-full h-2">
						<div
							style={{ width: `${String(Number(entryValue.number_value) * 12) + 'px'}` }}
							className="top-0 left-0 absolute bg-indigo-600 rounded-full h-full"
						/>
					</div>
					<p>{String(entryValue.number_value)}</p>
				</div>
			);
		case 'multiple-choice':
			return (
				<div>
					<span className="min-w-16 text-slate-700">{entryValue.input_label}</span>
					<ul>
						{entryValue?.text_array?.map((item) => (
							<li key={item}>{item}</li>
						))}
					</ul>
				</div>
			);
		case 'photo':
			return <img src={entryValue.object_id as string} alt={entryValue.input_label ?? 'Uploaded Image'} />;
		case 'foot-photos':
			return <p>FOOT PHOTOS</p>;

		// Custom Inputs
		case 'pain-slider':
			return (
				<div className="flex flex-col gap-2">
					<p className={cn('dark:text-slate-200', size === 'sm' ? 'text-md' : 'text-xl')}>
						{entryValue.input_label} {String(entryValue.number_value)}/10
					</p>
					<div style={{ width: '120px' }} className="relative bg-slate-300 rounded-full h-2">
						<div
							style={{ width: `${String(Number(entryValue.number_value) * 12) + 'px'}` }}
							className="top-0 left-0 absolute bg-indigo-600 rounded-full h-full"
						/>
					</div>
				</div>
			);

		case 'glucose-slider':
			return (
				<div className="flex flex-col gap-2">
					<p className={size === 'sm' ? 'text-md' : 'text-xl'}>Glucose {String(entryValue.number_value)} </p>
					<div style={{ width: '120px' }} className="relative bg-slate-300 rounded-full h-2">
						<div
							style={{ width: `${String((Number(entryValue.number_value) * 120) / 30) + 'px'}` }}
							className={cn(
								'absolute left-0 top-0 h-full rounded-full bg-lime-500',
								Number(entryValue.number_value) > 20 && 'bg-red-500',
								Number(entryValue.number_value) < 5 && 'bg-red-500',
							)}
						/>
					</div>
				</div>
			);
		case 'yes-no':
			return (
				<div className="border-slate-300 p-2 pt-1 border rounded-md w-full">
					<span className="min-w-16 font-medium text-slate-500">{entryValue.input_label}</span>
					<p className="capitalize">{entryValue.text_value ?? 'None'}</p>
				</div>
			);
		default:
			return (
				<div className="border-slate-300 p-2 pt-1 border rounded-md w-full">
					<span className="min-w-16 font-medium text-slate-500">{entryValue.input_label}</span>
					<p>{entryValue.text_value ?? 'None'}</p>
				</div>
			);
	}
};

export default EntryValueSwitch;
