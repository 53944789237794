import { useMutation } from '@tanstack/react-query';
import { toast } from 'sonner';

import { supabaseClient } from '@/lib/supabase';
import useInvalidateDiaries from '../../diary/api/invalidateDiaries';
import type { InjectionDetailSchemaT } from '../components/InjectionDetailsForm';
import { deleteInjectionDetails } from './deleteInjectionDetails';

type InjectionDetailInsert = InjectionDetails['Insert'] & {injection_id:string, injection_detail_id:string};
import type { InjectionDetails } from '@/types/database/events';

export async function upsertInjectionDetail(injectionDetail: InjectionDetailInsert[]) {
	return await supabaseClient
		.from('injection_details')
		.upsert(injectionDetail)
		.select()
		.throwOnError()
		.single()
		.then((data) => data.data);
}

export const useReplaceInjectionDetails = ({ diaryId }: { diaryId: string }) => {
	const invalidateDiaries = useInvalidateDiaries();

	return useMutation({
		mutationFn: async (injectionDetail: InjectionDetailSchemaT) => {
			await deleteInjectionDetails(injectionDetail.injection_id);
			// @ts-expect-error - injection_details is not a property of the mutation response
			return await upsertInjectionDetail(injectionDetail.injection_details);
		},
		onError: (error) => {
			toast.error(error.message);
		},
		onSuccess: (mutation) => {
			toast.success('Injection Details Saved');
			// @ts-expect-error - onSuccess is not a property of the mutation response
			mutation?.onSuccess && mutation.onSuccess();
		},
		onSettled: () => {
			invalidateDiaries(diaryId);
		},
	});
};
