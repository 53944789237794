import { commonComponents as cc } from '@/components/common';
import { cn } from '@/lib/utils';
import type { SizeT } from '@/types/zod/common';
import { Link } from '@tanstack/react-router';
import { Calendar } from 'lucide-react';

import type { EntryViewT } from '@/types/database/diaries';
import EntryCardEntryValues from './EntryCardEntryValues';
import HistoryCard from './HistoryCard';
import HistoryCardTitle from './HistoryCardTitle';

interface Props {
	entry: EntryViewT;
	size?: SizeT;
	className?: string;
	editable?: boolean;
}
const EntryCard = ({ entry, size, className, editable }: Props) => {
	const headerContent = (
		<HistoryCardTitle
			title={<cc.IconDetail icon={<Calendar size={24} />} words={entry.timing_label ?? 'No Timing Label'} />}
			dateTime={entry.calculated_entry_time as string}
			size={size}
			editable={editable}
			editButton={
				<cc.EditButton>
					<Link
						to="/diary/$diaryId/edit/tracker/$trackerId/entry/$entryId"
						params={{
							diaryId: entry.diary_id as string,
							trackerId: entry.tracker_id as string,
							entryId: entry.entry_id as string,
						}}
					>
						Edit
					</Link>
				</cc.EditButton>
			}
		/>
	);

	const bodyContent = <EntryCardEntryValues size={size} entryValues={entry.entry_values} />;

	return (
		<HistoryCard
			size={size}
			className={cn('border-slate-300 bg-white', className)}
			headerContent={headerContent}
			bodyContent={bodyContent}
		/>
	);
};

export default EntryCard;
