const NoFoot = () => {
    return (
        <svg
            className="w-full h-full"
            width="512"
            height="512"
            viewBox="0 0 512 512"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="256" cy="256" r="236" fill="white" stroke="#F24E1E" strokeWidth="40" />
            <ellipse cx="195.811" cy="118.481" rx="23.4528" ry="35.1792" fill="black" fillOpacity="0.8" />
            <ellipse cx="240.585" cy="106.755" rx="14.9245" ry="19.1887" fill="black" fillOpacity="0.8" />
            <ellipse cx="274.698" cy="118.481" rx="12.7925" ry="15.9906" fill="black" fillOpacity="0.8" />
            <ellipse cx="303.481" cy="140.868" rx="11.7264" ry="14.9245" fill="black" fillOpacity="0.8" />
            <ellipse cx="325.868" cy="171.783" rx="12.7925" ry="13.8585" fill="black" fillOpacity="0.8" />
            <path
                d="M269.368 153.66C249.114 148.33 213.934 153.66 199.01 165.387C184.085 177.113 183.671 196.592 190.482 212.293C200.655 235.745 238.453 222.598 258.708 229.349C271.5 233.613 271.5 254.934 271.5 266.66C271.5 320.943 230.162 355.142 219.265 379.66C208.367 404.179 215 437.227 250.18 437.227C285.359 437.227 276.831 358.34 282.161 324.226C286.492 296.51 324.802 244.274 324.802 221.887C324.802 203.764 308.092 196.919 296.019 182.443C288.177 173.04 289.623 158.991 269.368 153.66Z"
                fill="black"
                fillOpacity="0.8"
            />
            <path d="M82 82L430 430" stroke="#F24E1E" strokeWidth="40" />
        </svg>
    );
};

export default NoFoot;
