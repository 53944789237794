import { actions as a } from '@/assets';
import { ReactNode } from 'react';

export type ActionKey = 'BOOK_APPOINTMENT' | 'BOOK_PROCEDURE' | 'REMIND_PATIENT_LOG' | 'DISCONTINUE_DIARY';

export type Action = {
    name: string;
    definition: string;
    icon: ReactNode;
    email: boolean;
};

type ActionsObject = Record<ActionKey, Action>;

const actions: ActionsObject = {
    BOOK_APPOINTMENT: {
        name: 'Book Follow Up',
        definition:
            'Sends an email to encouraging the patient to schedule a follow-up with your secretary then puts the patient into "Passively Monitoring".',
        icon: <a.Calendar />,
        email: true,
    },
    BOOK_PROCEDURE: {
        name: 'Book Procedure',
        definition:
            'Sends an email to encouraging the patient to schedule a procedure with your secretary then puts the patient into "Passively Monitoring".',
        icon: <a.Injection />,
        email: true,
    },
    REMIND_PATIENT_LOG: {
        name: 'Remind a patient',
        definition: 'Sends an email to the patient reminding them to log their pain.',
        icon: <a.Email />,
        email: true,
    },
    DISCONTINUE_DIARY: {
        name: 'Discontinue Diary',
        definition:
            'Sends an email to the patient with an attached PDF record of the diary. Marks the diary as finish preventing the patient entering further logs.',
        icon: <a.FinishFlag />,
        email: true,
    },
};

export { actions };
