import ErrorPage from '@/components/pages/ErrorPage';
import { Outlet, createFileRoute } from '@tanstack/react-router';

const DiaryPage = () => {
	return <Outlet />;
};

export const Route = createFileRoute('/_app/diary')({
	component: DiaryPage,
	errorComponent: ({ error, reset }) => <ErrorPage title="Diary Error" error={error} reset={reset} />,
});
