import React from 'react';

function Logout() {
    return (
        <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6 2H33.4286C33.9809 2 34.4286 2.44772 34.4286 3V14.8034M6 2V35.8005C6 36.2272 6.27073 36.6069 6.67413 36.7459L23.4923 42.543C24.1417 42.7668 24.8182 42.2844 24.8182 41.5976V36.5136M6 2L24.1441 8.25406C24.5474 8.39311 24.8182 8.77279 24.8182 9.19947V36.5136M24.8182 36.5136H33.4286C33.9809 36.5136 34.4286 36.0659 34.4286 35.5136V23.6549M28.0857 19.4445H42M42 19.4445L38 14.8034M42 19.4445L38 23.8977"
                stroke="black"
                strokeWidth="1.75"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default Logout;
