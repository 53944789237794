import { useState } from 'react';

interface Props {
    image1: string;
    image2: string;
    image3: string;
    image4: string;
    link1?: () => void;
    link2?: () => void;
    link3?: () => void;
    link4?: () => void;
}

const Gallery = ({ image1, image2, image3, image4, link1, link2, link3, link4 }: Props) => {
    const [loaded1, setLoaded1] = useState<boolean>(false);
    const [loaded2, setLoaded2] = useState<boolean>(false);
    const [loaded3, setLoaded3] = useState<boolean>(false);
    const [loaded4, setLoaded4] = useState<boolean>(false);
    return (
        <>
            {[loaded1, loaded2, , loaded3, loaded4].includes(false) && <h1>Loading...</h1>}
            <div className={`flex-col z-2 ${loaded1 && loaded2 && loaded3 && loaded4 ? '' : 'hidden'}`}>
                <div className={`flex w-full ${loaded3 && loaded4 ? '' : 'hidden'}`}>
                    <img
                        className={`${
                            loaded4 ? '' : 'hidden'
                        } w-[47vw] z-0 object-cover reflect mr-[1vw] ml-0 rounded-xl`}
                        src={image3}
                        alt="Latest Photo"
                        onClick={link3}
                        onLoad={() => setLoaded3(true)}
                    ></img>
                    <img
                        className={`${
                            loaded3 ? '' : 'hidden'
                        }w-[47vw] z-0 object-cover reflect ml-[1vw] mr-0 rounded-xl`}
                        src={image4}
                        alt="Latest Photo"
                        onClick={link4}
                        onLoad={() => setLoaded4(true)}
                    ></img>
                </div>
                <div className={`flex w-full pt-[2vw] ${loaded1 && loaded2 ? '' : 'hidden'}`}>
                    <img
                        className={`${loaded1 ? '' : 'hidden'} w-[47vw] z-0 object-cover mr-[1vw] ml-0 rounded-xl`}
                        src={image1}
                        onClick={link1}
                        alt="Latest Photo"
                        onLoad={() => setLoaded1(true)}
                    ></img>
                    <img
                        className={`${loaded2 ? '' : 'hidden'} w-[47vw] z-0 object-cover ml-[1vw] mr-0 rounded-xl`}
                        src={image2}
                        onClick={link2}
                        alt="Latest Photo"
                        onLoad={() => setLoaded2(true)}
                    ></img>
                </div>
            </div>
        </>
    );
};

export default Gallery;
