import { templatesQueryKey } from '@/features/builder/api/selectDiaryTemplateById';
import { supabaseClient } from '@/lib/supabase';
import type { DiaryInputT } from '@/types/database/diaries';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';

export async function insertDiaryInput(input: DiaryInputT) {
	return await supabaseClient
		.from('diary_inputs')
		.insert(input)
		.select()
		.throwOnError()
		.single()
		.then((res) => res.data);
}

export const useInsertDiaryInputMutation = () => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationKey: ['input'],
		mutationFn: async (input: DiaryInputT) => {
			return await insertDiaryInput(input);
		},
		onError: () => {
			toast.error('Error creating input');
		},
		onSuccess: (data) => {
			return data;
		},
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey: templatesQueryKey });
		},
	});
};
