import { faces as f } from '@/assets/faces';

export const sliderFace = (value: number) => {
	if (value === 0) {
		return { message: 'No pain at all', icon: <f.Zero /> };
	}
	if (value === 1) {
		return { message: 'Almost no pain', icon: <f.One /> };
	}
	if (value === 2) {
		return { message: 'Very little pain', icon: <f.Two /> };
	}
	if (value === 3) {
		return { message: 'Little pain', icon: <f.Three /> };
	}
	if (value === 4) {
		return { message: 'Some pain', icon: <f.Four /> };
	}
	if (value === 5) {
		return { message: 'More pain', icon: <f.Five /> };
	}
	if (value === 6) {
		return { message: 'Considerable pain', icon: <f.Six /> };
	}
	if (value === 7) {
		return { message: 'A lot of pain', icon: <f.Seven /> };
	}
	if (value === 8) {
		return { message: 'Loads of pain', icon: <f.Eight /> };
	}
	if (value === 9) {
		return { message: 'Awful pain', icon: <f.Nine /> };
	}
	if (value === 10) {
		return { message: 'Worst possible pain', icon: <f.Ten /> };
	}
};
