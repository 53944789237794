import { useInjectionContentsQuery } from '../api/selectInjectionContents';
import { useInjectionLocationsQuery } from '../api/selectInjectionLocations';

const useInjectionDetailsOptions = () => {
	const { data: injectionLocations } = useInjectionLocationsQuery();
	const { data: injectionContents } = useInjectionContentsQuery();

	return { injectionLocations, injectionContents };
};

export default useInjectionDetailsOptions;
