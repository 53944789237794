import { commonComponents as cc } from '@/components/common';
import { ui } from '@/components/ui';
import { buttonVariants } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import usePreferencesStore from '../hooks/usePreferencesStore';
import UpdatePreferences from './UpdatePreferences';

const PreferencesCard = () => {
	const preferences = usePreferencesStore((state) => state.preferences);

	const displayChannelLabel = (channel: boolean | undefined) => {
		if (channel === true) return 'Allowed';
		if (channel === false) return 'Not Allowed';
		return 'Information Missing';
	};

	const noPreferences = Object.keys(preferences).length === 0;

	return (
		<ui.Card className="mb-4">
			<ui.CardHeader className="flex flex-row justify-between items-center pb-0 w-full">
				<ui.CardTitle className={cn('text-xl', noPreferences && 'text-amber-600')}>
					Notification Preferences
					{noPreferences && 'Missing'}
				</ui.CardTitle>
			</ui.CardHeader>
			<ui.CardContent className="pb-0">
				<UpdatePreferences />
			</ui.CardContent>
		</ui.Card>
	);
};

export default PreferencesCard;
