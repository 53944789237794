import AllCharts from '@/features/charts/components/AllCharts';
import { diaryByIdQueryOptions, useDiaryByIdQuery } from '@/features/diary/api/getDiaryById';

import { hooks as h } from '@/hooks';
import { createFileRoute } from '@tanstack/react-router';
import { extractEntryValues } from '../../../../../features/entries/hooks/useExtractEntryValues';

const Chart = () => {
	const { diaryId } = Route.useParams();

	const { data: diary } = useDiaryByIdQuery(diaryId);

	h.useNav([
		{ label: 'Diaries', route: { to: '/diaries' } },
		{
			route: { to: '/diary/$diaryId/home/charts' },
			label: 'Charts',
			options: [
				{ route: { to: '/diary/$diaryId/home/new' }, label: 'New' },
				{ route: { to: '/diary/$diaryId/home/history' }, label: 'History' },
				{ route: { to: '/diary/$diaryId/home/charts' }, label: 'Charts' },
			],
		},
	]);

	return <AllCharts showAddEntryButton={true} diary={diary} />;
};

export const Route = createFileRoute('/_app/diary/$diaryId/home/charts')({
	component: Chart,
	loader: async ({ context, params }) => {
		const diary = await context?.queryClient.ensureQueryData(diaryByIdQueryOptions(params.diaryId));
		const entryValues = extractEntryValues(diary);

		return { diary, entryValues };
	},
});
