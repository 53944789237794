import { colors } from '@/assets/colors';
import { icons } from '@/assets/icons';
import { ui } from '@/components/ui';
import { useRouter } from '@tanstack/react-router';

import { useImagesStore } from '../../hooks/useImagesStore';
import { VariationsType } from '../../services/variations';

interface Props {
    variation: VariationsType;
}

const CameraHeader = ({ variation }: Props) => {
    const [images, resetVariant] = useImagesStore((state) => [state.images, state.resetVariant]);

    const router = useRouter();
    const onBack = () => router.history.back();

    return (
        <div className="fixed z-[70] top-0 left-0 flex h-[100px] lg:mx-[5vw] px-4 w-full lg:w-[90vw] items-center justify-between">
            <ui.Button
                className="h-[50px] w-[50px] max-h-[50px] max-w-[50px] p-0 mr-auto ml-0 bg-opacity-60"
                onClick={onBack}
                variant="outline"
            >
                <icons.BackArrow fill={colors['gray-800']} />
            </ui.Button>
            <div className="flex w-fit items-center relative z-50 justify-between gap-x-2">
                {images[variation].base64 && (
                    <ui.Button
                        className="h-[50px] w-[50px] max-h-[50px] max-w-[50px] p-0  bg-opacity-60"
                        onClick={() => resetVariant(images, variation)}
                        variant="outline"
                    >
                        <icons.CrossThin fill={colors['gray-800']} />
                    </ui.Button>
                )}
            </div>
        </div>
    );
};

export default CameraHeader;
