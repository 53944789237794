import usePatientReportUrl from "./usePatientReportUrl";
import { useDub } from "@/features/dub/api/useDub";

export function useDubPatientReport({
  patientId,
  diaryId,
  search = {},
}: {
  patientId?: string;
  diaryId?: string;
  search?: Record<string, string | undefined>;
}) {
  if (!patientId || !diaryId) return null;
  const url = usePatientReportUrl({ patientId, diaryId, search });
  const dub = useDub(url);
  return dub;
}
