import { Lock, Shield, TrafficCone } from 'lucide-react';

export const content = [
    {
        title: 'Authentication and Authorization',
        bullets: [
            'JWT for secure authentication.',
            'Salted & hashed passwords.',
            'MFA for privileged users.',
            'Different access levels for roles.',
            'OAuth 2.0 Protocols.',
        ],
        icon: <Lock />,
    },
    // {
    //     title: 'Authorization',
    //     bullets: [
    //         'Different access levels for roles.',
    //         'Least Privilege Access for care team.',
    //         'Edit Roles Permissions for diaries.',
    //     ],
    //     icon: <Key />,
    // },
    // {
    //     title: 'Data Collection',
    //     bullets: [
    //         'Only necessary data collected.',
    //         'Varied storage based on user roles.',
    //         'Cookies for secure authentication.',
    //     ],
    //     icon: <Database />,
    // },
    {
        title: 'Network Configuration',
        bullets: [
            'Exclusive use of HTTPS.',
            'SSL for data transmission.',
            'Cross Origin Restriction policies.',
            'Firewalls and intrusion detection.',
            '99.99% uptime.',
        ],
        icon: <Shield />,
    },
    {
        title: 'Vulnerability Tracker',
        bullets: [
            'Rate limited servers.',
            'Front and backend input validation',
            'Man-in-the-middle attack mitigation.',
            'Regular vulnerability assessments.',
            'AES-256 encryption.',
        ],
        icon: <TrafficCone />,
    },
    // {
    //     title: 'Communication',
    //     bullets: [
    //         'Anonymized clinician email protection.',
    //         'Email verification for identity confirmation.',
    //         'SMS reminders with opt-out options.',
    //     ],
    //     icon: <MailCheck />,
    // },
];
