import { ui } from '@/components/ui';
import { useAuth } from '@/utils/auth';

import { useUpdateSuperUserMutation } from '../api/updateSuperUser';

interface Props {
	isSuperUser: boolean;
}
const SuperUserSwitch = ({ isSuperUser }: Props) => {
	const auth = useAuth();

	const updateSuperUser = useUpdateSuperUserMutation();
	return (
		<div className="flex w-full items-center justify-between text-lg">
			<ui.Label>Super User</ui.Label>
			<ui.Switch
				color="indigo"
				checked={isSuperUser}
				onCheckedChange={(val) => updateSuperUser.mutate({ value: val, user_id: auth.id })}
			/>
		</div>
	);
};

export default SuperUserSwitch;
