import { supabaseClient } from '@/lib/supabase';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'sonner';

const resendEmailSignup = async (email) => {
	const { error } = await supabaseClient.auth.resend({
		type: 'signup',
		email: email,
	});
	if (error) throw error;
};

const useResendEmailSignup = () => {
	return useMutation({
		mutationFn: (email: string) => resendEmailSignup(email),
		onError: (error) => {
			toast.error(error.message);
		},
		onSuccess: () => {
			toast.success('Email Verification Sent');
		},
	});
};

export default useResendEmailSignup;
