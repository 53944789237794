import { supabaseClient } from '@/lib/supabase';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';

import type { InsertScheduleT } from '../types/templateSchema';
import { templatesQueryKey } from './selectSchedules';

export async function insertSchedule(timings: InsertScheduleT) {
	return await supabaseClient
		.from('schedules')
		.insert(timings)
		.select()
		.throwOnError()
		.then((res) => res.data);
}

export const useInsertScheduleMutation = () => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationKey: ['schedule'],
		mutationFn: async (template: InsertScheduleT) => {
			return await insertSchedule(template);
		},
		onError: () => {
			toast.error('Error creating diary log');
		},
		onSuccess: (data) => {
			return data;
		},
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey: templatesQueryKey });
		},
	});
};
