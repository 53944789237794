import { ui } from '@/components/ui';
import { services as s } from '@/services';
import { ExtendedEventViewT } from '@/types/database/events';
import { Link } from '@tanstack/react-router';

import { injectionComponents as ic } from '.';

interface Props {
	event: ExtendedEventViewT;
}
const InjectionsGroup = ({ event }: Props) => {
	const newUuid = s.uuidv4();
	return (
		<>
			<ic.InjectionLinks event={event} />

			<ui.Button asChild size="lg" variant="link">
				<Link
					to="/diary/$diaryId/edit/event/$eventId/injection/$injectionId"
					params={{ diaryId: event.diary_id, eventId: event.event_id, injectionId: newUuid }}
				>
					Add Injection
				</Link>
			</ui.Button>
		</>
	);
};

export default InjectionsGroup;
