// TrackerReferenceDate.tsx
import DateTimeForm from '@/components/form/DateTimeForm';
import useExtractTracker from '@/features/trackers/hooks/useExtractTracker';
import { useTrackerUpdate } from '@/features/trackers/hooks/useTrackerUpdate';
import Description from '../../../components/common/Description';
interface Props {
	diaryId: string;
	trackerId: string;
}

const TrackerReferenceDateForm = ({ diaryId, trackerId }: Props) => {
	const tracker = useExtractTracker(diaryId, trackerId);
	const { updateReferenceDate } = useTrackerUpdate(diaryId, trackerId);

	return (
		<>
			<Description className="text-left pl-0">The date of the surgery, injection, or medication change. </Description>
			<DateTimeForm initialDate={tracker?.reference_date} onSubmit={updateReferenceDate} submitButtonText="Save" />
		</>
	);
};

export default TrackerReferenceDateForm;
