import { supabaseClient } from '@/lib/supabase';
import { queryOptions, useSuspenseQuery } from '@tanstack/react-query';

export async function selectInjectionEventsWithTracker() {
	const { data, error } = await supabaseClient.from('events_view').select(
		`*,
		injections_view(*,
			injection_details_view(*)
		)`,
	);
	if (error) throw error;
	return data?.filter((event) => event.trackers?.length > 0);
}

export const injectionEventsByTrackerQueryOptions = () => {
	return queryOptions({
		queryKey: ['injection-events-by-tracker'],
		queryFn: selectInjectionEventsWithTracker,
	});
};

export function useInjectionEventsByTrackerQuery() {
	return useSuspenseQuery(injectionEventsByTrackerQueryOptions());
}
