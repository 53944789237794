import { supabaseClient } from '@/lib/supabase';
import { queryOptions, useQuery } from '@tanstack/react-query';

export async function selectUsersStatistics() {
	const { data, error } = await supabaseClient.from('users_statistics').select('*');
	if (error) throw error;
	return data;
}

export const usersStatisticsQueryKey = ['users-statistics'];

export const selectUsersStatisticsQueryOptions = () => {
	return queryOptions({
		queryKey: usersStatisticsQueryKey,
		queryFn: () => selectUsersStatistics(),
	});
};

export function useSelectUsersStatistics() {
	const profileQuery = useQuery(selectUsersStatisticsQueryOptions());
	return profileQuery;
}
