import { colors } from "../colors";

const colorMap = {
  red: colors["red-400"],
  orange: colors["orange-400"],
  yellow: colors["amber-400"],
  green: colors["emerald-400"],
  black: colors["gray-700"],
};

export type ColorMapType = typeof colorMap;

interface Props {
  className?: string;
  color?: keyof ColorMapType;
  stroke?: string;
}

const Flag = ({ className, color, stroke }: Props) => {
  return (
    <svg
      role="img"
      aria-label="Flag"
      className={className}
      width="70"
      height="70"
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.4286 9.80011L13.6431 12.4035V39.7171L20.4286 37.1137C25.1809 35.2904 30.4606 35.5739 35.0002 37.8962C39.5398 40.2185 44.8195 40.502 49.5718 38.6787L56.3574 36.0753V8.7617L49.5718 11.3651C44.8195 13.1884 39.5398 12.9049 35.0002 10.5826C30.4606 8.26033 25.1809 7.97681 20.4286 9.80011Z"
        fill={color ? colorMap[color] : "transparent"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.2088 12.3822C30.1361 10.3014 25.3994 10.0473 21.1358 11.681L15.5385 13.8258V37.0817L19.8934 35.413C25.0782 33.4263 30.8384 33.7352 35.7912 36.2656C39.8639 38.3464 44.6006 38.6004 48.8642 36.9667L54.4615 34.8219V11.5661L50.1066 13.2348C44.9218 15.2215 39.1616 14.9126 34.2088 12.3822ZM15.5385 40.9432L21.1358 38.7984C25.3994 37.1647 30.1361 37.4188 34.2088 39.4996C39.1616 42.03 44.9218 42.3389 50.1066 40.3522L56.852 37.7675C57.5425 37.5029 58 36.8284 58 36.0748V8.95736C58 8.36443 57.7155 7.80919 57.2385 7.47146C56.7616 7.13373 56.1529 7.05645 55.6096 7.26464L48.8642 9.84934C44.6007 11.483 39.8639 11.229 35.7912 9.14822C30.8384 6.61781 25.0782 6.30888 19.8934 8.29559L15.5385 9.96432V8.95736C15.5385 7.95893 14.7463 7.14953 13.7692 7.14953C12.7921 7.14953 12 7.95893 12 8.95736V63.1922C12 64.1906 12.7921 65 13.7692 65C14.7463 65 15.5385 64.1906 15.5385 63.1922V40.9432Z"
        fill={stroke ? stroke : "black"}
      />
    </svg>
  );
};

export default Flag;
