import { cn } from '@/lib/utils';
import { OTPInput, OTPInputContext } from 'input-otp';
import { Dot } from 'lucide-react';
import * as React from 'react';

('use client');

const InputOTP = React.forwardRef<React.ElementRef<typeof OTPInput>, React.ComponentPropsWithoutRef<typeof OTPInput>>(
	({ className, containerClassName, ...props }, ref) => (
		<OTPInput
			ref={ref}
			containerClassName={cn(
				'flex justify-center mx-auto items-center gap-2 has-[:disabled]:opacity-50',
				containerClassName,
			)}
			className={cn('disabled:cursor-not-allowed', className)}
			{...props}
		/>
	),
);
InputOTP.displayName = 'InputOTP';

const InputOTPGroup = React.forwardRef<React.ElementRef<'div'>, React.ComponentPropsWithoutRef<'div'>>(
	({ className, ...props }, ref) => <div ref={ref} className={cn('flex items-center', className)} {...props} />,
);
InputOTPGroup.displayName = 'InputOTPGroup';

const InputOTPSlot = React.forwardRef<
	React.ElementRef<'div'>,
	React.ComponentPropsWithoutRef<'div'> & { index: number }
>(({ index, className, ...props }, ref) => {
	const inputOTPContext = React.useContext(OTPInputContext);
	const { char, hasFakeCaret, isActive } = inputOTPContext.slots[index];

	return (
		<div
			ref={ref}
			className={cn(
				'relative flex mx-auto h-[14vw] w-[14vw] max-w-[80px] max-h-[80px] [font-size:_clamp(15px,6vw,40px)] md:text-3xl md:h-16 md:w-16 items-center justify-center border-y border-r bg-white border-slate-300  transition-all first:rounded-l-md first:border-l last:rounded-r-md dark:border-slate-800',
				isActive &&
					'z-10 ring-2 ring-indigo-600 ring-offset-white dark:ring-offset-slate-900 dark:ring-indigo-300 dark:ring-offset-slate-950',
				className,
			)}
			{...props}
		>
			{char}
			{hasFakeCaret && (
				<div className="absolute inset-0 flex justify-center items-center pointer-events-none">
					<div className="bg-slate-950 dark:bg-slate-50 w-px h-[6vw] animate-caret-blink duration-1000" />
				</div>
			)}
		</div>
	);
});
InputOTPSlot.displayName = 'InputOTPSlot';

const InputOTPSeparator = React.forwardRef<React.ElementRef<'div'>, React.ComponentPropsWithoutRef<'div'>>(
	({ ...props }, ref) => (
		<div ref={ref} role="separator" {...props}>
			<Dot />
		</div>
	),
);
InputOTPSeparator.displayName = 'InputOTPSeparator';

export { InputOTP, InputOTPGroup, InputOTPSlot, InputOTPSeparator };
