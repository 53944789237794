const Nine = () => {
	return (
		<svg
			role="img"
			aria-label="Pain Nine"
			width="50"
			height="50"
			viewBox="0 0 50 50"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect x="1" y="1" width="48" height="48" rx="24" fill="white" stroke="black" strokeWidth="2" />
			<path
				d="M18.1483 35.805L16.8756 37.0568C16.2463 37.6758 15.7816 38.4422 15.5236 39.2863C15.3096 39.9868 15.9921 40.6285 16.6781 40.3716L18.1041 39.8376C19.9365 39.1515 21.8772 38.8 23.8338 38.8L25.5724 38.8C27.6208 38.8 29.6546 39.1432 31.5896 39.8153L33.3291 40.4196C34.0235 40.6608 34.6979 40.0114 34.4831 39.3084C34.2208 38.4497 33.7481 37.6702 33.108 37.0406L31.8517 35.805C30.0242 34.0074 27.5634 33 25 33C22.4366 33 19.9758 34.0074 18.1483 35.805Z"
				fill="black"
				stroke="black"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M6.20367 16.8176C6.05836 16.2847 6.3725 15.735 6.90532 15.5897L7.18756 15.5127C9.99644 14.7466 12.4717 13.0923 14.2512 10.8105C14.5909 10.375 15.2089 10.2481 15.6684 10.5544V10.5544C16.128 10.8608 16.2547 11.484 15.9192 11.9227C13.8687 14.6048 10.9887 16.5491 7.7138 17.4422L7.43156 17.5192C6.89873 17.6645 6.34899 17.3504 6.20367 16.8176Z"
				fill="black"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M44.2968 16.8176C44.4421 16.2847 44.128 15.735 43.5952 15.5897L43.3129 15.5127C40.504 14.7466 38.0288 13.0923 36.2492 10.8105C35.9096 10.375 35.2916 10.2481 34.8321 10.5544V10.5544C34.3725 10.8608 34.2458 11.484 34.5813 11.9227C36.6318 14.6048 39.5118 16.5491 42.7867 17.4422L43.0689 17.5192C43.6018 17.6645 44.1515 17.3504 44.2968 16.8176Z"
				fill="black"
			/>
			<path
				d="M11.1655 21.9922C10.4407 20.7367 10.8708 19.1312 12.1263 18.4064L15.3739 16.5314C16.6295 15.8065 18.2349 16.2367 18.9598 17.4922V17.4922C20.1679 19.5847 19.4509 22.2604 17.3584 23.4685L17.1419 23.5935C15.0494 24.8017 12.3737 24.0847 11.1655 21.9922V21.9922Z"
				fill="#0E0E0E"
			/>
			<path
				d="M31.7925 17.4923C32.5174 16.2368 34.1228 15.8066 35.3783 16.5315L38.6259 18.4065C39.8814 19.1314 40.3116 20.7368 39.5867 21.9923V21.9923C38.3786 24.0848 35.7029 24.8018 33.6103 23.5937L33.3938 23.4687C31.3013 22.2605 30.5844 19.5848 31.7925 17.4923V17.4923Z"
				fill="#0E0E0E"
			/>
			<path
				d="M11.5267 28.5955L12.4863 26.0366C12.491 26.0239 12.509 26.0239 12.5137 26.0366L13.4733 28.5955C13.7282 29.2751 13.2258 30 12.5 30C11.7742 30 11.2718 29.2751 11.5267 28.5955Z"
				stroke="black"
				strokeWidth="2"
				strokeLinecap="round"
			/>
		</svg>
	);
};

export default Nine;
