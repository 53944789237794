import ActiveMonitoring from "./ActiveMonitoring";
import BeenAWhile from "./BeenAWhile";
import ClipboardWarning from "./ClipboardWarning";
import ClockQuestion from "./ClockQuestion";
import DiaryQuestion from "./DiaryQuestion";
import DiaryTick from "./DiaryTick";
import DiaryWarning from "./DiaryWarning";
import EmailConfirmed from "./EmailConfirmed";
import Empty from "./Empty";
import FinishFlag from "./FinishFlag";
import Flag from "./Flag";
import MIA from "./MIA";

export const stateIcons = {
  ActiveMonitoring,
  DiaryTick,
  Flag,
  ClockQuestion,
  DiaryQuestion,
  EmailConfirmed,
  DiaryWarning,
  ClipboardWarning,
  Empty,
  BeenAWhile,
  FinishFlag,
  MIA,
};

export default stateIcons;
