import { selectProfileByIdQueryOptions } from "@/features/profile/api/selectProfile";
import ClinicianNumberForm from "@/features/profile/components/ClinicianNumberForm";
import ProfileWrap from "@/features/profile/layouts/ProfileWrap";
import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";

const ClinicianNumber = () => {
  const { auth } = Route.useRouteContext();
  const { data: profile } = useSuspenseQuery(
    selectProfileByIdQueryOptions(auth.id),
  );

  return (
    <ProfileWrap title="HCPC Number">
      <ClinicianNumberForm
        userId={profile.user_id}
        clinicianNumber={profile.clinician_number}
      />
    </ProfileWrap>
  );
};

export const Route = createFileRoute("/_app/profile/clinician-number")({
  component: ClinicianNumber,
});

export default ClinicianNumber;
