import { ActionIcon } from '@/assets/actions/ActionIcon';
import { ui } from '@/components/ui';
import { cn } from '@/lib/utils';
import type { ActionDefinitionT } from '@/types/database/actions';
import { Info } from 'lucide-react';

interface Props {
	action: ActionDefinitionT;
}

const TakeActionTitle = (props: Props) => {
	return (
		<div
			className={cn(
				'flex flex-wrap gap-2 text-md text-slate-800 dark:text-slate-200 rounded-md px-2 h-8 md:mt-0 md:flex-row md:items-center',
				props.action?.to_state ? 'justify-between' : 'justify-center',
			)}
		>
			<ui.TooltipProvider>
				<ui.Tooltip>
					<ui.TooltipTrigger type="button" className="flex flex-row items-center gap-2">
						<h3 className="flex justify-center items-center gap-x-2 w-fit [&_svg]:w-6 [&_svg]:h-6 text-md">
							{ActionIcon[props.action?.action_key]}
							{props.action?.action_name}
						</h3>
						<Info size={20} strokeWidth={1.75} />
					</ui.TooltipTrigger>
					<ui.TooltipContent>
						<h3 className="flex justify-center items-center gap-x-2 w-fit [&_svg]:w-6 [&_svg]:h-6">
							{ActionIcon[props.action?.action_key]}
							{props.action?.action_name}
						</h3>
						<p className="text-slate-600 text-sm">{props.action.definition}</p>
					</ui.TooltipContent>
				</ui.Tooltip>
			</ui.TooltipProvider>
		</div>
	);
};

export default TakeActionTitle;
