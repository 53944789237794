import { colors } from '@/assets/colors';
import { icons } from '@/assets/icons';
import { ui } from '@/components/ui';

import { useCameraStore } from '../hooks/useCameraStore';
import { usePhotosStore } from '../hooks/usePhotoStore';

const CameraHeader = () => {
    const photos = usePhotosStore();
    const currentPhotoId = usePhotosStore((state) => state.currentPhotoId);
    const camera = useCameraStore();

    return (
        <div className="fixed z-[70] top-0 left-0 flex h-[100px] lg:mx-[5vw] px-4 w-full lg:w-[90vw] items-center justify-between">
            <ui.Button
                className="h-[50px] w-[50px] max-h-[50px] max-w-[50px] p-0 mr-auto ml-0 bg-opacity-60"
                onClick={camera.toggleCamera}
                variant="outline"
            >
                <icons.BackArrow fill={colors['gray-800']} />
            </ui.Button>
            <div className="relative z-50 flex items-center justify-between w-fit gap-x-2">
                {currentPhotoId && (
                    <ui.Button
                        className="h-[50px] w-[50px] max-h-[50px] max-w-[50px] p-0  bg-opacity-60"
                        onClick={() => photos.removePhoto(currentPhotoId)}
                        variant="outline"
                    >
                        <icons.CrossThin fill={colors['gray-800']} />
                    </ui.Button>
                )}
            </div>
        </div>
    );
};

export default CameraHeader;
