import { useFormContext } from 'react-hook-form';

import { ui } from '../ui';

interface Props {
	name: string;
	placeholder?: string;
	className?: string;
	label?: string;
}

const Textarea: React.FC<Props> = ({ name, placeholder, className, label }) => {
	const { control } = useFormContext();
	return (
		<ui.FormField
			control={control}
			name={name}
			data-cy={name}
			render={({ field }) => (
				<ui.FormItem>
					{label && <ui.FormLabel>{label}</ui.FormLabel>}
					<ui.FormControl>
						<ui.Textarea placeholder={placeholder} className={className} {...field} />
					</ui.FormControl>
					<ui.FormMessage />
				</ui.FormItem>
			)}
		/>
	);
};

export default Textarea;
