import { shallow } from 'zustand/shallow';

import { useImagesStore } from '../hooks/useImagesStore';
import { compressImage } from '../upload/services/compressImage';
import LazyImage from './LazyImage';

interface Props {
    placement: 'tl' | 'tr' | 'bl' | 'br';
    image: string;
    variant: 'LT' | 'LS' | 'RT' | 'RS';
}

const variantMap = {
    LS: 'Left Sole',
    RS: 'Right Sole',
    LT: 'Left Top',
    RT: 'Right Top',
};

const UploadImage = ({ image, placement, variant }: Props) => {
    const [images, updateImage, resetVariant] = useImagesStore(
        (state) => [state.images, state.updateImage, state.resetVariant],
        shallow,
    );

    const updateCompressedImage = async (file: File) => {
        if (file) {
            console.log(file.size);
            const compressed = await compressImage(URL.createObjectURL(file), 'compressed');
            console.log(compressed);
            // @ts-ignore
            updateImage(images, variant, URL.createObjectURL(compressed));
        }
    };
    return (
        <div className="relative border border-slate-300 rounded-sm overflow-hidden w-full">
            <LazyImage image={image} fullScreen={false} setShowDate={() => {}} imageSrc="" placement={placement} />
            <label
                className=" h-full rounded-b-sm absolute top-[40%] text-center px-4 text-3xl text-slate-900 w-full z-5  "
                htmlFor={'picture_' + variant}
            >
                {variantMap[variant]}
            </label>
            <input
                className="bg-slate-50 h-full bg-opacity-10 rounded-b-sm absolute bottom-0 w-full z-10"
                id={'picture_' + variant}
                type="file"
                onChange={(e) => {
                    if (e?.target?.files) {
                        updateCompressedImage(e?.target?.files[0]);
                    }
                }}
            />
        </div>
    );
};

export default UploadImage;
