import { commonComponents as cc } from '@/components/common';
import { schedulesQueryOptions } from '@/features/schedules/api/selectSchedules';
import TrackerForm from '@/features/trackers/components/TrackerForm';
import { createFileRoute } from '@tanstack/react-router';

const NewTracker = () => {
	const { diaryId, trackerId } = Route.useParams();
	const { schedules } = Route.useLoaderData();

	return (
		<section>
			<cc.Title words="New Tracker" />
			<TrackerForm schedules={schedules} trackerId={trackerId} diaryId={diaryId} />
		</section>
	);
};

export const Route = createFileRoute('/_app/diary/$diaryId/new/tracker/$trackerId/custom')({
	component: NewTracker,
	loader: async ({ context }) => {
		const schedules = await context?.queryClient.ensureQueryData(schedulesQueryOptions());
		return { schedules };
	},
});
