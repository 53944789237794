import React from 'react';

const AddPatientBtn = () => {
    return (
        <svg width="831" height="831" viewBox="0 0 831 831" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="831" height="831" rx="162" fill="#403FAB" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M572.853 223.233C572.853 295.998 511.742 356.467 434.501 356.467C357.26 356.467 296.149 295.998 296.149 223.233C296.149 209.642 298.282 196.479 302.253 184.06L317.532 186.358C342.512 190.114 367.738 192 392.999 192H419.395C464.597 204.906 511.485 211.5 558.723 211.5H572.32C572.673 215.37 572.853 219.283 572.853 223.233ZM319.762 171.524L307.794 169.725C310.579 163.621 313.827 157.749 317.496 152.154L352.607 167.759C359.626 170.879 366.708 173.835 373.845 176.628C355.742 175.923 337.687 174.22 319.762 171.524ZM358.699 154.052L326.647 139.807C335.341 129.378 345.639 120.22 357.201 112.697C419.547 141.724 487.187 157.594 555.899 159.339C562.432 170.832 567.255 183.331 570.052 196.5H558.723C489.813 196.5 421.67 182.039 358.699 154.052ZM434.501 90C412.508 90 391.822 94.9026 373.482 103.596C427.941 127.477 486.31 141.153 545.68 143.958C520.612 111.357 480.405 90 434.501 90ZM600.853 223.233C600.853 312.28 526.375 384.467 434.501 384.467C342.628 384.467 268.149 312.28 268.149 223.233C268.149 134.187 342.628 62 434.501 62C526.375 62 600.853 134.187 600.853 223.233ZM561.553 474.758C522.762 466.936 479.855 462.533 434.5 462.533C342.38 462.533 260.358 480.694 202.409 508.788C142.53 537.818 117 572.555 117 602.033C117 631.512 142.53 666.249 202.409 695.279C260.358 723.372 342.38 741.533 434.5 741.533C466.125 741.533 496.559 739.393 525.238 735.445C536.253 744.524 548.572 752.078 561.871 757.784C522.457 765.366 479.483 769.533 434.5 769.533C243.686 769.533 89 694.541 89 602.033C89 509.526 243.686 434.533 434.5 434.533C502.21 434.533 565.371 443.976 618.685 460.294C598.37 461.524 579.089 466.583 561.553 474.758Z"
                fill="white"
            />
            <path
                d="M731 612.447V615C731 673.542 683.542 721 625 721H622.447C565.315 721 519 674.685 519 617.553C519 557.601 567.601 509 627.553 509C684.685 509 731 555.315 731 612.447Z"
                stroke="white"
                strokeWidth="20"
            />
            <rect x="563" y="603" width="124" height="24" rx="12" fill="white" />
            <rect x="637" y="553" width="124" height="24" rx="12" transform="rotate(90 637 553)" fill="white" />
        </svg>
    );
};

export default AddPatientBtn;
