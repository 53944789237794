import { ui } from '@/components/ui';
import { type Watch, watches } from '@/definitions/diaryTypes';
import Draft from '@/features/templates/components/Draft';
import { hideTemplate } from '@/features/templates/utils/hideTemplate';
import { cn } from '@/lib/utils';
import type { DiaryTemplateT } from '@/types/database/diaries';
import { useAuth } from '@/utils/auth';
import dayjs from 'dayjs';
import { toast } from 'sonner';
import TemplateStar from '../../builder/components/TemplateStar';
import DiaryBadges from '../../diaries/components/DiaryBadges';
import useInsertDiaryMutation from '../../diary/api/insertDiary';

interface Props {
	template: DiaryTemplateT;
	diaryId: string;
	patientId: string;
	organisationId: string;
	favourite: boolean;
	createable: boolean;
}
const DiaryTemplateCard = ({ template, patientId, organisationId, diaryId, favourite, createable }: Props) => {
	const auth = useAuth();
	const insertDiary = useInsertDiaryMutation();

	const createDiary = () => {
		if (!template) {
			toast.error('No template');
		}
		const data = {
			created_by: auth.id,
			patient_id: patientId,
			organisation_id: organisationId,
			diary_id: diaryId,
			diary_type: template.diary_type,
			diary_name: template.diary_template_name,
			diary_template_id: template.diary_template_id,
			start_date: dayjs().toISOString(),
		};

		insertDiary.mutate(data);
	};

	return (
		<ui.Card key={template.diary_template_id} className={cn(hideTemplate(auth, template) && 'hidden')}>
			{template?.is_draft && <Draft>Draft Diary Template</Draft>}

			<ui.CardHeader className="flex flex-row items-center justify-between pb-2">
				{template?.diary_type && (
					<div className="flex items-center gap-x-2 text-slate-800 dark:text-slate-200">
						{watches[template.diary_type as Watch]?.icon}
						<h3 className="text-lg font-medium">{watches[template.diary_type as Watch]?.name}</h3>
					</div>
				)}
				<TemplateStar templateId={template.diary_template_id} favourite={favourite} />
			</ui.CardHeader>
			<ui.CardContent className="space-y-2">
				<h4 className="text-xl font-medium text-slate-700 dark:text-slate-300">{template.diary_template_name}</h4>
				<DiaryBadges template={template} />
				<p className="text-sm font-normal text-slate-950 dark:text-slate-50">{template.diary_template_description}</p>
			</ui.CardContent>
			<ui.CardFooter className="gap-2">
				{createable && (
					<ui.Button size="md" onClick={() => createDiary()} type="button">
						Select
					</ui.Button>
				)}
			</ui.CardFooter>
		</ui.Card>
	);
};

export default DiaryTemplateCard;
