import { cn } from '@/lib/utils';
import type { UuidT } from '@/types/zod/auth';
import type { SizeT } from '@/types/zod/common';

import EventCard from '../../events/components/EventCard';
import ActionCard from './ActionCard';
import EntryCard from './EntryCard';

interface Props {
	diaryId: UuidT;
	diaryHistory: any[];
	editable?: boolean;
	addable?: boolean;
	size?: SizeT;
}

const History = (props: Props) => {
	return (
		<div className={cn('flex flex-col-reverse gap-y-4', props.size === 'sm' && 'gap-y-2')}>
			{props.diaryHistory?.map((log) => {
				switch (log.type) {
					case 'entry':
						return <EntryCard editable={props.editable} entry={log} size={props.size} />;
					case 'action':
						return <ActionCard action={log} size={props.size} />;
					case 'event':
						return <EventCard addable={props.addable} event={log} />;
				}
			})}
		</div>
	);
};

export default History;
