import { supabaseClient } from '@/lib/supabase';
import type { EntryT } from '@/types/database/diaries';
import type { NotificationPreferenceT } from '@/types/database/profiles';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';
import { toast } from 'sonner';

import { createProfileQueryKey } from './selectProfile';

export async function upsertNotificationPreferences(entry: NotificationPreferenceT) {
	return await supabaseClient
		.from('notification_preferences')
		.upsert(entry)
		.select()
		.throwOnError()
		.single()
		.then((res) => res.data);
}

export const useUpsertNotificationPreferenceMutation = (patientId: string) => {
	const queryClient = useQueryClient();
	const navigate = useNavigate();
	return useMutation({
		mutationKey: ['notification-preferences', { patient_id: patientId }],
		mutationFn: async (entry: EntryT) => {
			return await upsertNotificationPreferences(entry);
		},
		onError: (error) => {
			toast.error(error.message);
		},
		onSuccess: () => {
			toast.info('Updated notification preferences');
			navigate({ to: '/profile' });
		},
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey: createProfileQueryKey(patientId) });
		},
	});
};

export default useUpsertNotificationPreferenceMutation;
