const Two = () => {
	return (
		<svg
			role="img"
			aria-label="Pain Two"
			width="50"
			height="50"
			viewBox="0 0 50 50"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect x="1" y="1" width="48" height="48" rx="24" fill="white" stroke="black" strokeWidth="2" />
			<rect x="11" y="16" width="8" height="8" rx="4" fill="#0E0E0E" />
			<rect x="31" y="16" width="8" height="8" rx="4" fill="#0E0E0E" />
			<path
				d="M33.1009 36.7474L35.2215 35.4693C35.73 35.1627 36.141 34.718 36.4065 34.1869C36.6186 33.7627 36.2011 33.2983 35.7568 33.4641L32.6157 34.6368C30.5552 35.406 28.3736 35.8 26.1743 35.8H24.458C22.1691 35.8 19.8966 35.4138 17.7362 34.6577L14.2372 33.433C13.7867 33.2753 13.3727 33.7455 13.5862 34.1724C13.8565 34.713 14.2748 35.1656 14.7924 35.4776L16.8991 36.7474C19.3442 38.2212 22.1451 39 25 39C27.8549 39 30.6558 38.2212 33.1009 36.7474Z"
				fill="black"
				stroke="black"
			/>
		</svg>
	);
};

export default Two;
