// TODO - Add Hospital Number - NON OPTIONAL FOR NHS
import { components as c } from '@/components';
import usePatientsByOrganisation from '@/features/new-diary/hooks/usePatientsByOrganisation';
import { services as s } from '@/services';
import { useAuth } from '@/utils/auth';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate } from '@tanstack/react-router';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

const FormSchema = z.object({
	patient: z.string().uuid('Required'),
});

import type { NewDiaryRouteSearchT } from '@/routes/_app/new-diary/patient/select';

const SelectPatientForm = (props: NewDiaryRouteSearchT) => {
	const auth = useAuth();
	const navigate = useNavigate();

	const form = useForm<z.infer<typeof FormSchema>>({
		resolver: zodResolver(FormSchema),
		defaultValues: {
			patient: auth.roles?.isPatientOnly ? auth.id : '',
		},
	});

	function onSubmit(data: z.infer<typeof FormSchema>) {
		navigate({
			to: props.diary_route,
			params: {
				patientId: data.patient,
				organisationId: props.organisation.organisation_id,
				diaryId: s.uuidv4(),
			},
		});
	}

	const { patientsByOrganisation } = usePatientsByOrganisation();

	return (
		<c.ui.Form {...form}>
			<form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
				<c.fc.ComboBox
					label="Patient"
					name="patient"
					disabled={auth.roles?.isPatientOnly}
					options={patientsByOrganisation(props.organisation.organisation_id)}
					description="Only patients within the selected organisation will be shown."
				/>
				<div className="flex flex-col gap-y-2">
					<c.ui.Button size="lg" type="submit">
						Next
					</c.ui.Button>
				</div>
			</form>
		</c.ui.Form>
	);
};

export default SelectPatientForm;
