import { commonComponents as e } from '@/components/common';
import FooterButtons from '@/components/common/FooterButtons';
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'sonner';
import * as z from 'zod';

const formSchema = z.object({
	name: z.string().min(1, 'Name is required'),
	email: z.string().email('Invalid email').min(1, 'Email is required'),
	day: z.number().gte(1, 'Day is required').lte(31, 'Max 31 days in a month'),
	month: z.number().gte(1, 'Month is required').lte(12, 'Only 12 months in a year'),
	year: z.number().gte(1900, "You're not that old!").lte(2005, 'Over 18s only!'),
});

interface newPatientI {
	name: string;
	email: string;
	dob: Date;
}

const NewPatient = () => {
	type FormSchemaType = z.infer<typeof formSchema>;

	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			name: '',
			email: '',
		},
	});

	const addNewPatient = async (formData: newPatientI) => {
		const { data, error } = await supabase
			.from('patients') //table name
			.upsert({
				name: formData.name,
				email: formData.email,
			})
			.select()
			.single();
		if (error) {
			toast.error('Error adding patient, please refresh and again.');
			throw error;
		}
		return data;
	};

	const mutation = useMutation({
		mutationFn: addNewPatient,
	});

	const onSubmit: SubmitHandler<FormSchemaType> = async (formData) => {
		const date = new Date(formData.year, formData.month - 1, formData.day);
		if (date instanceof Date) {
			const newPatientData = { name: formData.name, email: formData.email, dob: date };
			mutation.mutate(newPatientData);
		} else {
			toast.error(`Invalid date`);
		}
	};

	return (
		<section className="mx-auto my-6 w-full max-w-[500px] px-2">
			<e.Title words="New Patient" />
			<Form {...form}>
				<form onSubmit={form.handleSubmit(onSubmit)} className="form">
					<FormField
						control={form.control}
						name="name"
						render={({ field }) => (
							<FormItem>
								<FormLabel>Username</FormLabel>
								<FormControl>
									<Input type="text" placeholder="John Major" {...field} />
								</FormControl>
								<FormDescription>Use the patient's first and last name.</FormDescription>
								<FormMessage />
							</FormItem>
						)}
					/>
					<FormField
						control={form.control}
						name="email"
						render={({ field }) => (
							<FormItem>
								<FormLabel>Email</FormLabel>
								<FormControl>
									<Input type="email" placeholder="name@company.com" {...field} />
								</FormControl>
								<FormDescription>The patient must sign-up with the email.</FormDescription>
								<FormMessage />
							</FormItem>
						)}
					/>
					<div className="flex flex-col gap-y-3">
						<FormLabel>Date of Birth</FormLabel>
						<div className="flex  gap-x-2">
							<div className="flex flex-[1_1_0]">
								<FormField
									control={form.control}
									name="day"
									render={({ field }) => (
										<FormItem>
											<FormControl>
												<Input
													// onChange={(event) => field.onChange(event.target.valueAsNumber)}
													type="number"
													placeholder="DD"
													{...field}
													onChange={(event) =>
														event.target.value.length < 3 && field.onChange(+event.target.valueAsNumber)
													}
												/>
											</FormControl>
											<FormMessage />
										</FormItem>
									)}
								/>
							</div>
							<div className="flex flex-[1_1_0]">
								<FormField
									control={form.control}
									name="month"
									render={({ field }) => (
										<FormItem>
											<FormControl>
												<Input
													type="number"
													placeholder="MM"
													{...field}
													onChange={(event) => {
														event.target.value.length < 3 && field.onChange(+event.target.valueAsNumber);
													}}
												/>
											</FormControl>
											<FormMessage />
										</FormItem>
									)}
								/>
							</div>
							<div className="flex flex-[2_1_0]">
								<FormField
									control={form.control}
									name="year"
									render={({ field }) => (
										<FormItem>
											<FormControl>
												<Input
													type="number"
													placeholder="YYYY"
													{...field}
													onChange={(event) =>
														event.target.value.length < 5 && field.onChange(+event.target.valueAsNumber)
													}
												/>
											</FormControl>
											<FormMessage />
										</FormItem>
									)}
								/>
							</div>
						</div>
						<FormDescription>Please use DD MM YYYY format.</FormDescription>
					</div>
					<FooterButtons
						// disabled1={form.isDirty}
						words1="Next"
						createFileRoute
						type1="submit"
						loading1={mutation.isPending}
					/>
					crecreateFileRouteute
					{/* <Button type="submit">Submit</Button> */}
				</form>
			</Form>
		</section>
	);
};

export const Route = createFileRoute(
	'/_app/diary/$diaryId/new/tracker/$trackerId/entry/$entryId/page/$pageIndex/foot-photos/new-patient',
)({
	component: NewPatient,
});
