import { supabaseClient } from '@/lib/supabase';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';

import type { Tracker } from '@/types/database/trackers';
import { createGetDiaryKey } from '../../diary/api/getDiaryById';

export async function upsertTracker(tracker: Tracker['Insert']) {
	const { data, error } = await supabaseClient
		.from('trackers')
		.upsert(tracker)
		.select('*, diaries(patient_id)')
		.single();
	if (error) throw error;
	return data;
}

interface TrackerMutation {
	diaryId: string;
}
export const useTrackerMutation = ({ diaryId }: TrackerMutation) => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationKey: ['tracker'],
		mutationFn: (tracker: Tracker['Insert']) => {
			return upsertTracker(tracker);
		},
		onError: () => {
			toast.error('Error saving tracker');
		},
		onSuccess: () => {
			toast.success('Tracker saved');
		},
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey: createGetDiaryKey(diaryId) });
		},
	});
};
