import { flattenedInputs as fi } from '@/definitions/inputs';
import type { InputKeyT } from '../../../types/database/forms';

interface Props {
	inputType: InputKeyT;
}
const InputType = ({ inputType }: Props) => {
	return (
		<>
			{inputType && (
				<div className="flex items-center gap-x-2 text-slate-800">
					{fi[inputType]?.icon}
					<h3 className="font-normal text-lg">{fi[inputType]?.name}</h3>
				</div>
			)}
		</>
	);
};

export default InputType;
