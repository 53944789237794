const NoImages = () => {
    return (
        <svg
            className="w-full h-full"
            width="70"
            height="70"
            viewBox="0 0 70 70"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M54 17.5H16C14.067 17.5 12.5 19.067 12.5 21V49C12.5 50.933 14.067 52.5 16 52.5H54C55.933 52.5 57.5 50.933 57.5 49V21C57.5 19.067 55.933 17.5 54 17.5ZM16 16C13.2386 16 11 18.2386 11 21V49C11 51.7614 13.2386 54 16 54H54C56.7614 54 59 51.7614 59 49V21C59 18.2386 56.7614 16 54 16H16Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M28.0078 32.25C28.2444 32.2525 28.466 32.3665 28.6056 32.5575L37.5728 44.8284L44.9539 36.986C45.1032 36.8273 45.3141 36.7415 45.5317 36.7507C45.7494 36.7599 45.9523 36.8633 46.0876 37.034L57.5876 51.534L56.4124 52.466L45.4515 38.6458L38.0462 46.514C37.8933 46.6764 37.6761 46.7624 37.4535 46.7486C37.231 46.7347 37.0261 46.6226 36.8945 46.4425L27.987 34.2533L14.0962 52.455L12.9038 51.545L27.4038 32.545C27.5473 32.3569 27.7712 32.2476 28.0078 32.25Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21 29.5C22.933 29.5 24.5 27.933 24.5 26C24.5 24.067 22.933 22.5 21 22.5C19.067 22.5 17.5 24.067 17.5 26C17.5 27.933 19.067 29.5 21 29.5ZM21 31C23.7614 31 26 28.7614 26 26C26 23.2386 23.7614 21 21 21C18.2386 21 16 23.2386 16 26C16 28.7614 18.2386 31 21 31Z"
                fill="black"
            />
            <path
                d="M67 21C67 27.6274 61.6274 33 55 33C48.3726 33 43 27.6274 43 21C43 14.3726 48.3726 9 55 9C61.6274 9 67 14.3726 67 21Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M59.0639 27.8924C57.8729 28.5961 56.4836 29 55 29C50.5817 29 47 25.4183 47 21C47 19.5164 47.4039 18.1271 48.1076 16.9361L59.0639 27.8924ZM61.8924 25.0639L50.9361 14.1076C52.1271 13.4039 53.5164 13 55 13C59.4183 13 63 16.5817 63 21C63 22.4836 62.5961 23.8729 61.8924 25.0639ZM67 21C67 27.6274 61.6274 33 55 33C48.3726 33 43 27.6274 43 21C43 14.3726 48.3726 9 55 9C61.6274 9 67 14.3726 67 21Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M42.5 21C42.5 14.0964 48.0964 8.5 55 8.5C61.9036 8.5 67.5 14.0964 67.5 21C67.5 27.9036 61.9036 33.5 55 33.5C48.0964 33.5 42.5 27.9036 42.5 21ZM55 9.5C48.6487 9.5 43.5 14.6487 43.5 21C43.5 27.3513 48.6487 32.5 55 32.5C61.3513 32.5 66.5 27.3513 66.5 21C66.5 14.6487 61.3513 9.5 55 9.5ZM50.6817 13.6772C51.9477 12.9291 53.4245 12.5 55 12.5C59.6944 12.5 63.5 16.3056 63.5 21C63.5 22.5755 63.0709 24.0524 62.3228 25.3183C62.2442 25.4514 62.1085 25.5406 61.9551 25.56C61.8018 25.5794 61.6481 25.5268 61.5388 25.4175L50.5825 14.4612C50.4732 14.3519 50.4206 14.1982 50.44 14.0449C50.4594 13.8915 50.5486 13.7558 50.6817 13.6772ZM51.7664 14.2308L61.7692 24.2336C62.2376 23.2549 62.5 22.1586 62.5 21C62.5 16.8579 59.1421 13.5 55 13.5C53.8414 13.5 52.7451 13.7624 51.7664 14.2308ZM48.0449 16.44C48.1982 16.4206 48.3519 16.4732 48.4612 16.5825L59.4175 27.5388C59.5268 27.6481 59.5794 27.8018 59.56 27.9551C59.5406 28.1085 59.4514 28.2442 59.3183 28.3228C58.0524 29.0709 56.5755 29.5 55 29.5C50.3056 29.5 46.5 25.6944 46.5 21C46.5 19.4245 46.9291 17.9477 47.6772 16.6817C47.7558 16.5486 47.8915 16.4594 48.0449 16.44ZM48.2308 17.7664C47.7624 18.7451 47.5 19.8414 47.5 21C47.5 25.1421 50.8579 28.5 55 28.5C56.1586 28.5 57.2549 28.2376 58.2336 27.7692L48.2308 17.7664Z"
                fill="black"
            />
        </svg>
    );
};

export default NoImages;
