import { components as c } from '@/components';
import { RequiredMarker } from '../../../components/form/RequiredMarker';
import { useAuth } from '../../../utils/auth';
import { type InjectionDiaryDefaultT, useInjectionDiaryDefaultMutation } from '../api/upsertInjectionDiaryDefaults';
interface Props {
	label: string;
	data: InjectionDiaryDefaultT;
	required?:boolean;
}
const InjectionDiarySaveLabel = ({ label, data,required }: Props) => {
	const auth = useAuth();
	const saveDefault = useInjectionDiaryDefaultMutation();
	return (
		<div className="w-full items-center justify-between flex">
			<c.ui.Label>{label}{required&&
				<RequiredMarker/>}</c.ui.Label>
			<c.ui.Button
				onClick={() => saveDefault.mutate({ ...data, user_id: auth.id })}
				size="sm"
				type="button"
				variant="link"
				className="my-0 h-fit px-0 py-0"
			>
				Save as Default
			</c.ui.Button>
		</div>
	);
};

export default InjectionDiarySaveLabel;
