import { cn } from '../../../lib/utils';
import Section from '../components/Section';
import SectionTitle from '../components/SectionTitle';
import { whyContent } from './content/solutionContent';

const Why = () => {
	return (
		<Section>
			<SectionTitle
				caption="Solutions"
				title="How Patient Watch Helps"
				description={
					<span>
						By empowering patients, we not only improve patient care but provide value to businesses and clinicians
						alike.
					</span>
				}
			/>
			<ul className="flex lg:flex-row flex-col gap-12 md:gap-16 lg:gap-24">
				{whyContent.map((why, groupIndex) => (
					<li className="px-4 md:px-0" key={'why_' + String(groupIndex)}>
						<h3 className="mb-4 font-semibold text-slate-700 dark:text-slate-200 text-xl capitalize">{why.name}</h3>
						<ul className="flex flex-col gap-y-6">
							{why.points.map((p, index) => (
								<li
									className="flex items-center gap-x-4 text-sm dark:text-slate-400"
									key={'why_point_' + String(index)}
								>
									<span
										className={cn(
											'mt-1 h-10 w-10 rounded-sm  p-2',
											groupIndex === 0 && 'bg-indigo-200 text-indigo-600 dark:bg-indigo-400 dark:text-indigo-800',
											groupIndex === 1 && 'bg-sky-200 text-sky-600 dark:bg-sky-400 dark:text-sky-800',
											groupIndex === 2 && 'bg-blue-200 text-blue-600 dark:bg-blue-400 dark:text-blue-800',
										)}
									>
										{p.icon}
									</span>
									{p.text}
								</li>
							))}
						</ul>
					</li>
				))}
			</ul>
		</Section>
	);
};

export default Why;
