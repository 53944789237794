import { createFileRoute, Link } from '@tanstack/react-router';
import React from 'react';

import { ui } from '../../components/ui';

const Test = () => {
    return (
        <div className="flex flex-row flex-wrap items-center justify-center p-16">
            <ui.Button asChild>
                <Link
                    to="/signup"
                    search={{
                        role: 'PATIENT',
                        favourite_diary_templates: ['38705a0c-1000-4234-a260-ec2c7265a67a'],
                        organisation: {
                            organisation_id: 'aabbf84d-77e9-4beb-9d08-212158608aa7',
                            organisation_key: 'FISHER2024',
                        },
                    }}
                >
                    Fisher's Signup
                </Link>
            </ui.Button>
        </div>
    );
};

export const Route = createFileRoute('/_auth/test')({
    component: Test,
});
