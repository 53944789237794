import { supabaseClient } from '@/lib/supabase';
import { queryOptions, useQuery } from '@tanstack/react-query';

function getInjectionContents() {
	return supabaseClient
		.from('injection_contents')
		.select('*')
		.throwOnError()
		.then((result) => result.data);
}

export const injection_contents_key = ['injection-contents'];

export const injectionContentsQueryOptions = () => {
	return queryOptions({
		queryKey: injection_contents_key,
		queryFn: async () => getInjectionContents(),
	});
};

export function useInjectionContentsQuery() {
	return useQuery(injectionContentsQueryOptions());
}
