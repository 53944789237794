import { ui } from '@/components/ui';

import ModalWrap from '../../../../components/modal/ModalWrap';

interface ModalI {
    title: string;
    description?: string;
    footer?: string;
    footerFunc?: any;
    footer2?: string;
    footerFunc2?: any;
    footer3?: string;
    footerFunc3?: any;
    children?: React.ReactNode | undefined;
    show?: boolean;
    showShowHide?: boolean;
    setShow: (value: boolean) => void;
}

const CheckFootModal = ({
    children,
    show,
    setShow,
    title,
    description,
    footer,
    footerFunc,
    footer2,
    footerFunc2,
    footer3,
    footerFunc3,
    showShowHide,
}: ModalI) => {
    return (
        // <ErrorBoundary fallback={<WarningMessage words="Error checking the photos" />}>
        <ModalWrap
            cardStyle={show ? 'max-w-[500px] mx-auto px-4' : 'bg-opacity-0 shadow-transparent max-w-[500px]'}
            backdropStyle={
                show ? 'backdrop-blur-[4px]' : 'backdrop-blur-none bg-[rgba(0,0,0,0)] bg-opacity-0 pointer-events-none'
            }
            showModal={show === undefined ? false : true}
            closeModal={() => setShow(false)}
            hideClose={true}
            noBlur={true}
        >
            <div className={`py-6 pt-2 w-full flex flex-col justify-center items-center`}>
                {setShow !== undefined && (
                    <div
                        onClick={() => setShow(!show)}
                        className={`cursor-pointer px-4 items-center w-fit ml-auto justify-end flex font-medium text-indigo-600 py-2 ${
                            !show && 'bg-white shadow-md rounded-lg pointer-events-auto'
                        }`}
                    >
                        {showShowHide === false ? '' : show ? 'Close Popup' : 'Open Popup'}
                    </div>
                )}

                <div className={show ? 'w-full' : 'opacity-0'}>
                    <div className="mb-6 space-y-2">
                        <h3 className="text-center w-full font-semibold text-indigo-800 text-2xl px-4">{title}</h3>
                        {description && <p className="text-center w-full font-normal text-lg p2-4">{description}</p>}
                    </div>
                    {children}
                    <div className="w-full pt-4 px-4 items-center justify-center">
                        {footer3 && (
                            <ui.Button
                                variant="link"
                                className="flex mx-auto my-0 mb-4"
                                size="lg"
                                onClick={footerFunc3}
                            >
                                {footer3}
                            </ui.Button>
                        )}
                        {footer !== undefined && (
                            <div className="flex justify-center my-auto gap-x-2 items-center">
                                {footer2 && (
                                    <ui.Button className="flex-1" variant="secondary" size="lg" onClick={footerFunc2}>
                                        {footer2}
                                    </ui.Button>
                                )}
                                <ui.Button size="lg" onClick={footerFunc}>
                                    {footer}
                                </ui.Button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </ModalWrap>
        // </ErrorBoundary>
    );
};

export default CheckFootModal;
