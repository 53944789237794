import type { UsersOrganisationsRolesViewT } from '@/types/database/profiles';

const filterPatientsByOrganisationId = (patients: any[], organisationId?: string) => {
	return (
		patients?.filter((patient) =>
			patient.profiles?.organisations?.some(
				(org: UsersOrganisationsRolesViewT) =>
					org?.organisation_id === organisationId && org.role?.toLowerCase() === 'patient',
			),
		) ?? []
	);
};

const mapToOptions = (patients: any[]) => {
	return patients?.map((patient) => {
		return { label: patient.profiles?.name, value: patient.patient_id };
	});
};

export const filterMapOptions = (patients: any[] | null, organisationId?: string) => {
	if (!patients || !organisationId) return [];
	const filtered = filterPatientsByOrganisationId(patients ?? [], organisationId);
	const opts = mapToOptions(filtered) ?? [];
	return opts;
};
