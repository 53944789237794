import Title from '@/components/common/Title';
import { Button } from '@/components/ui/button';
import { authQueryOptions } from '@/features/auth/api/useAuthQuery';
import AuthWrap from '@/features/auth/components/AuthWrap';
import { useAuth } from '@/utils/auth';
import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { UserCheck2, UserX2 } from 'lucide-react';

const AuthenticationComplete = () => {
	const auth = useAuth();
	const navigate = useNavigate();

	const next = () => {
		if (auth.isAuthenticated) {
			navigate({ to: '/profile' });
		} else {
			navigate({ to: '/login' });
		}
	};

	return (
		<AuthWrap>
			<div className="space-y-8 text-slate-900 dark:text-slate-100">
				{auth.isAuthenticated ? (
					<>
						<Title words="Authentication Complete" description="You are now logged in, click next to continue." />
						<UserCheck2 size={48} className="flex mx-auto w-fit icon-xl" />
					</>
				) : (
					<>
						<Title words="Authentication Failed" description="Link invalid, please try again." />
						<UserX2 size={48} className="flex mx-auto my-8 w-fit icon-xl" />
					</>
				)}
				<Button data-testid="authentication-complete-button" autoFocus size="lg" onClick={() => next()}>
					Next
				</Button>
			</div>
		</AuthWrap>
	);
};

export const Route = createFileRoute('/_auth/authentication-complete')({
	component: AuthenticationComplete,
	beforeLoad: async ({ context }) => {
		await context?.queryClient.ensureQueryData(authQueryOptions());
		return context;
	},
});

export default AuthenticationComplete;
