import React from 'react';

import { cn } from '../../../lib/utils';

interface Props {
	inputType: string;
	inputTypes: string[];
	children: React.ReactNode;
}
const InputParameter = (props: Props) => {
	return (
		<div className={cn('flex flex-col gap-2', props.inputTypes.includes(props.inputType) ? 'flex' : 'hidden')}>
			{props.children}
		</div>
	);
};

export default InputParameter;
