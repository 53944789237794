import { ui } from '@/components/ui';

import { useTheme } from '../../../hooks/useTheme';

const DarkModeSwitch = () => {
	const { theme, setTheme } = useTheme();

	return (
		<div className="flex w-full items-center justify-between text-lg">
			<ui.Label>Dark Mode</ui.Label>
			<ui.Switch
				color="indigo"
				checked={theme === 'dark'}
				onCheckedChange={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
			/>
		</div>
	);
};

export default DarkModeSwitch;
