import { interfaceComponents as ic } from '@/features/dashboard/features/interface/components';
import { diaryByIdQueryOptions, useDiaryByIdQuery } from '@/features/diary/api/getDiaryById';
import { selectProfileByIdQueryOptions } from '@/features/profile/api/selectProfile';
import { hooks as h } from '@/hooks';
import type { ActionDefinitionT } from '@/types/database/actions';
import { createFileRoute } from '@tanstack/react-router';
import { type ExtendedProfileT, ProfileT } from '../../../../../types/database/profiles';

const Action = () => {
	const { diaryId, actionId } = Route.useParams();
	const { profile } = Route.useLoaderData();
	const { data: diary } = useDiaryByIdQuery(diaryId);
	const { action } = Route.useSearch();

	h.useNav([
		{ label: 'Dashboard', route: { to: '/dashboard' } },
		{ label: diary.diary_name ?? 'Diary', route: { to: '/dashboard/diary/$diaryId', params: { diaryId: diaryId } } },
		{
			label: action?.action_name ?? 'Action',
			route: { to: '/dashboard/diary/$diaryId/action/$actionId', params: { diaryId: diaryId, actionId: actionId } },
		},
	]);

	return (
		<ic.ActionEmail
			profile={profile as ExtendedProfileT}
			diary={diary}
			state={diary.diary_status}
			action={action}
			actionId={actionId}
		/>
	);
};

type ActionSchema = {
	action: ActionDefinitionT;
};

export const Route = createFileRoute('/_app/dashboard/diary/$diaryId/action/$actionId')({
	validateSearch: (search: Record<string, unknown>): ActionSchema => {
		// validate and parse the search params into a typed state
		return {
			action: search.action as ActionDefinitionT,
		};
	},
	component: Action,
	loader: async ({ context, params }) => {
		const profile = (await context?.queryClient.ensureQueryData(
			selectProfileByIdQueryOptions(context.auth.id),
		)) as ExtendedProfileT;
		const diary = await context?.queryClient.ensureQueryData(diaryByIdQueryOptions(params.diaryId));

		return { profile: profile, diary: diary };
	},
});
