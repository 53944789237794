import { commonComponents as cc } from '@/components/common';
import { schedulesQueryOptions } from '@/features/schedules/api/selectSchedules';
import { timeComponents as tc } from '@/features/schedules/components';
import { createFileRoute } from '@tanstack/react-router';

const Templates = () => {
	const { templates } = Route.useLoaderData();
	return (
		<section className="my-8">
			<cc.Title words="Schedules" />
			<div className="flex flex-row flex-wrap mx-auto max-w-lg">
				{templates?.map((t) => (
					<div className="p-2 w-full md:w-1/2 lg:w-1/3" key={t.schedule_id}>
						<tc.ScheduleCard schedule={t} />
					</div>
				))}
			</div>
		</section>
	);
};

export const Route = createFileRoute('/_app/builder/schedules')({
	component: Templates,
	loader: async ({ context }) => {
		const templates = await context?.queryClient.ensureQueryData(schedulesQueryOptions());
		return { templates };
	},
});
