interface Props {
	onClick: () => void;
	icon: React.ReactNode;
	words: string;
	active: boolean;
}
import { cn } from '@/lib/utils';

const Tab = ({ onClick, icon, words, active }: Props) => {
	return (
		<button
			onClick={onClick}
			onMouseEnter={onClick}
			className={cn(
				'text-sm md:text-md mb-2 mr-2 h-fit p-2 md:p-3 rounded-md border border-slate-300 flex gap-x-2 items-center justify-center',
				active ? 'bg-white text-slate-900 font-medium shadow-md' : 'bg-slate-100 text-slate-500 shadow-sm',
			)}
		>
			<div className={cn('[&>svg]:h-6 [&>svg]:w-6', !active && 'opacity-50')}>{icon}</div>
			<p>{words}</p>
		</button>
	);
};

export default Tab;
