import { supabaseClient } from '@/lib/supabase';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';
import { toast } from 'sonner';

import { useAuth } from '../../../utils/auth';
import type { LoginSchemaType } from '../types/loginSchema';

const login = async (formData: LoginSchemaType) => {
	const { data, error } = await supabaseClient.auth.signInWithPassword({
		email: formData.email,
		password: formData.password,
	});
	if (error) throw error;
	if (data) return data;
};

const usePasswordLoginMutation = () => {
	// const auth = useAuth();
	const auth = useAuth();
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	return useMutation({
		mutationKey: ['login'],
		mutationFn: async (formData: LoginSchemaType) => {
			return login(formData);
		},
		onError: (error) => {
			console.error(error);
			toast.error(error.message);
		},
		onSuccess: (data, mutation) => {
			if (data?.user?.id) {
				toast.info('Successfully logged in');
				auth.login(data.user.id);
				mutation?.onSuccess && mutation?.onSuccess();
			} else if (!data?.user) {
				toast.error('Email or password incorrect');
			} else {
				toast('Unknown login error');
			}
			queryClient.invalidateQueries({ queryKey: ['user'] });
		},
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey: ['profile'] });
		},
	});
};

export default usePasswordLoginMutation;
