import { cn } from '../../lib/utils';
import { usePwaInstallStatusStore } from '../../pwa/pwaInstallStatus';
import { Button } from '../ui/button';

type ButtonTypeT = 'submit' | 'button';

interface Props {
	func1?: () => void;
	func2?: () => void;
	words1: string;
	icon1?: React.ReactNode;
	words2?: string;
	icon2?: React.ReactNode;
	iconSwap2?: boolean;
	disabled1?: boolean;
	disabled2?: boolean;
	type1?: ButtonTypeT;
	type2?: ButtonTypeT;
}

const FooterButtons = ({ func1, func2, words1, words2, disabled1, disabled2, icon1, icon2, type1, type2 }: Props) => {
	const installed = usePwaInstallStatusStore((s) => s.installed);

	return (
		<>
			<div
				className={cn(
					'flex w-full max-w-md mx-auto flex-row-reverse items-center justify-center pt-2',
					installed
						? 'fixed bottom-0 left-0 z-20 h-[94px] justify-around rounded-t-2xl bg-slate-50 px-2 drop-shadow-md md:bottom-[20px] md:left-[calc(50vw-275px)] md:rounded-3xl md:bg-transparent md:drop-shadow-none'
						: 'mb-8 flex gap-x-4',
				)}
			>
				{func2 && words2 ? (
					<>
						<Button
							data-testid="footer-primary-button"
							className="w-2/3"
							size="lg"
							disabled={disabled1}
							onClick={func1}
							type={type1 ?? 'submit'}
						>
							{words1}
							{!!icon1 && icon1}
						</Button>
						<Button
							data-testid="footer-secondary-button"
							size="lg"
							className="w-1/3"
							disabled={disabled2}
							onClick={func2}
							variant="secondary"
							type={type2 ?? 'button'}
						>
							{words2}
							{!!icon2 && icon2}
						</Button>
					</>
				) : (
					<Button size="lg" disabled={disabled1} onClick={func1}>
						{words1}
						{!!icon1 && icon1}
					</Button>
				)}
			</div>
		</>
	);
};

export default FooterButtons;
