import Knock from '@knocklabs/client';
import { createFileRoute } from '@tanstack/react-router';
const knockPublicApiKey: string = import.meta.env.KNOCK_PUBLIC_API_KEY;

import UpdatePreferences from '@/features/knock/components/UpdatePreferences';

const PreferencesPage = () => {
	return <UpdatePreferences />;
};

export const Route = createFileRoute('/_app/profile/preferences')({
	component: PreferencesPage,
	loader: ({ context }) => {
		const knockClient = new Knock(knockPublicApiKey as string);
		knockClient.authenticate(context.auth.id);
		return { knockClient };
	},
});
