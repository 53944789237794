import { InputKeyT } from '@/types/database/forms';
import {
	CheckSquare,
	CircleDot,
	Droplet,
	File,
	Footprints,
	Hash,
	Image,
	SlidersHorizontal,
	Smile,
	Star,
	Text,
	TextCursorInput,
} from 'lucide-react';
import { z } from 'zod';

export type InputT = {
	icon: JSX.Element;
	name: string;
};

type GroupedInputT = InputT & { group: InputGroupKey };

type InputGroup = Record<InputGroupKey, Partial<Input>>;
type Input = Partial<Record<InputKeyT, InputT>>;
export const InputTypeZ = z
	.enum([
		'long-text',
		'slider',
		'short-text',
		'number',
		'star',
		'file',
		'image',
		'multiple-choice',
		'single-choice',
		'pain-slider',
		'glucose-slider',
		'foot-photos',
	])
	.catch('long-text');

export const InputGroupZ = z.enum(['custom', 'text', 'number', 'rating', 'upload', 'select']).catch('text');
export type InputGroupKey = z.infer<typeof InputGroupZ>;
export const inputs: InputGroup = {
	custom: {
		'pain-slider': {
			icon: <Smile />,
			name: 'Pain Slider',
		},
		'glucose-slider': { icon: <Droplet />, name: 'Glucose Slider' },
		'foot-photos': {
			icon: <Footprints />,
			name: 'Foot Photos',
		},
	},
	text: {
		'long-text': {
			icon: <Text />,
			name: 'Long Text',
		},
		'short-text': {
			icon: <TextCursorInput />,
			name: 'Short Text',
		},
	},
	select: {
		'multiple-choice': {
			icon: <CheckSquare />,
			name: 'Multiple Choice',
		},
		'single-choice': {
			icon: <CircleDot />,
			name: 'Single Choice',
		},
	},
	number: {
		number: {
			icon: <Hash />,
			name: 'Number',
		},
		slider: {
			icon: <SlidersHorizontal />,
			name: 'Slider',
		},
	},
	rating: {
		star: {
			icon: <Star />,
			name: 'Star Rating',
		},
	},
	upload: {
		file: {
			icon: <File />,
			name: 'File Upload',
		},
		image: {
			icon: <Image />,
			name: 'Image Upload',
		},
	},
};

export const flattenInputs = (inputs: InputGroup) => {
	const flatInputs: GroupedInputT | {} = {};
	for (const group in inputs) {
		for (const key in inputs[group as InputGroupKey]) {
			flatInputs[key] = {
				...inputs[group as InputGroupKey][key],
				key: key,
				group: group,
			};
		}
	}
	return flatInputs;
};

export const flattenedInputs = flattenInputs(inputs);
