import { components as c } from '@/components';
import InjectionDiarySaveLabel from '@/features/injection/components/InjectionDiarySaveLabel';
import InviteDialog from '@/features/invite/components/InviteDialog';
import FavouriteUsersComboBox from '@/features/profile/components/FavouriteUsersComboBox';
import { createFileRoute } from '@tanstack/react-router';
import { useFormContext } from 'react-hook-form';

const InjectionTimePage = () => {
	const form = useFormContext();
	const { organisationId } = Route.useParams();
	return (
		<>
			<div className="">
				<InjectionDiarySaveLabel
					required={true}
					label="Local and/or Secondary Pain"
					data={{ form_id: form.getValues('form_id') }}
				/>
				<c.ui.FormDescription>e.g. Monitor back AND leg pain after a spinal injection.</c.ui.FormDescription>
				<c.fc.Select
					name="form_id"
					options={[
						{ label: 'Local Pain Only', value: '289abef6-3ce8-4b5b-b621-d622b5efc975' },
						{ label: 'Local and Secondary Pain', value: 'ad5ae755-bfaf-4797-bf10-71d61a5c7465' },
					]}
				/>
			</div>
			<div className="">
				<c.fc.Date
					description="When did the medication change, injection, or surgery occur? If you don't know, just leave it blank."
					name="injection_time"
					label="Injection Time"
				/>
			</div>
			<div className="">
				<c.ui.Label>Hospital Number</c.ui.Label>
				<c.fc.Input name="hospital_number" />
			</div>

			<div className="">
				<InjectionDiarySaveLabel
					required={false}
					label="Contact Person"
					data={{ contact_id: form.getValues('contact_id') }}
				/>
				<c.ui.FormDescription>A person who can be contacted by the patient for clinical advice.</c.ui.FormDescription>
				<div className="flex flex-col gap-y-2">
					<FavouriteUsersComboBox
						includeUser={true}
						placeholder="Contact Person"
						name="contact_id"
						organisationId={organisationId}
					/>
					<InviteDialog />
				</div>
			</div>
		</>
	);
};

export const Route = createFileRoute(
	'/_app/new-diary/patient/$patientId/organisation/$organisationId/diary/guided/injection-diary/time',
)({
	component: InjectionTimePage,
});
