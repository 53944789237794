import { Card, CardHeader } from '@/components/ui/card';
import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from '@/components/ui/carousel';
import { services as s } from '@/services';
import React from 'react';

import { InputKeyT } from '../../../definitions/inputs';
import { EntryValueViewT } from '../../../types/database/diaries';
import { ExtendedTrackerViewT } from '../../../types/database/trackers';
import { separateByTrackerId } from '../services/generateData';
import TrackerChart from './TrackerChart';

interface ChartCarouselProps {
	entryValues: EntryValueViewT[]; // Replace 'any' with the appropriate type for the chart
	trackersView: ExtendedTrackerViewT[]; // Replace 'any[]' with the appropriate type for the trackers_view
	inputType: InputKeyT;
}
const ChartCarousel: React.FC<ChartCarouselProps> = (props) => {
	return (
		<Carousel className="max-h-[600px] w-full max-w-md">
			<CarouselContent>
				{separateByTrackerId(props.entryValues).map((evs, index) => (
					<CarouselItem key={index}>
						<Card className="max-h-[600px] min-h-[450px] p-4">
							<div className="mx-auto my-2 flex w-fit flex-row items-center justify-between gap-4 text-lg font-medium text-slate-700">
								<CarouselPrevious className="relative bottom-auto left-auto right-auto top-auto flex translate-x-0 translate-y-0" />
								<div className="mx-auto flex flex-col items-center justify-center text-center">
									<h3 className="">
										{props.trackersView.find((t) => t.tracker_id === evs[0].tracker_id)?.tracker_name}
									</h3>
									<p className="text-sm font-normal text-slate-900">
										{s.time.formatDateTime(
											props.trackersView.find((t) => t.tracker_id === evs[0].tracker_id)?.start_date,
										)}
									</p>
								</div>
								<CarouselNext className="relative bottom-auto left-auto right-auto top-auto flex translate-x-0 translate-y-0 items-center justify-center p-0" />
							</div>
							<CardHeader></CardHeader>

							<TrackerChart
								inputType={props.inputType}
								entryValues={evs}
								trackers={props.trackersView.filter((t) => t.tracker_id === evs.tracker_id)}
							/>
						</Card>
					</CarouselItem>
				))}
			</CarouselContent>
		</Carousel>
	);
};

export default ChartCarousel;
