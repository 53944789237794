import { Control } from 'react-hook-form';

import { ui } from '../ui';

interface Props {
	control: Control;
	name: string;
	label: string;
}

interface Props {
	label: string;
	value: string;
	options: string[];
	setValue: (val: string) => void;
	description?: string;
}
const Dropdown: React.FC<Props> = (props: Props) => {
	return (
		<>
			<ui.Label>{props.label}</ui.Label>
			<ui.Select onValueChange={(val) => props.setValue(val)} defaultValue={props.value}>
				<ui.SelectTrigger>
					<ui.SelectValue />
				</ui.SelectTrigger>
				<ui.SelectContent>
					{props.options.map((o) => (
						<ui.SelectItem value={o}>{o}</ui.SelectItem>
					))}
				</ui.SelectContent>
			</ui.Select>
			<p className="text-sm font-normal text-slate-700">{props.description}</p>
		</>
	);
};

export default Dropdown;
