interface Props {
    fill: string;
    className?: string;
}
const Cross = ({ fill, className }: Props) => {
    return (
        <svg
            className={className}
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M35.0607 17.0607C35.6464 16.4749 35.6464 15.5251 35.0607 14.9393C34.4749 14.3536 33.5251 14.3536 32.9393 14.9393L24.5 23.3787L16.0607 14.9393C15.4749 14.3536 14.5251 14.3536 13.9393 14.9393C13.3536 15.5251 13.3536 16.4749 13.9393 17.0607L22.3787 25.5L13.9393 33.9393C13.3536 34.5251 13.3536 35.4749 13.9393 36.0607C14.5251 36.6464 15.4749 36.6464 16.0607 36.0607L24.5 27.6213L32.9393 36.0607C33.5251 36.6464 34.4749 36.6464 35.0607 36.0607C35.6464 35.4749 35.6464 34.5251 35.0607 33.9393L26.6213 25.5L35.0607 17.0607Z"
                fill={fill}
            />
        </svg>
    );
};

export default Cross;
