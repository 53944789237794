import { selectFormByIdQueryOptions } from '@/features/forms/api/selectFormById';
import FormPage from '@/features/pages/components/FormPage';
import { createFileRoute } from '@tanstack/react-router';

// import useHandleNewEntry from '@/features/diary/entries/hooks/useHandleNewEntry';
const Page = () => {
	const { entryId, pageIndex } = Route.useParams();
	const { form } = Route.useLoaderData();
	// const { data: diary } = useDiaryByIdQuery(diaryId);
	// const { handleNewEntry } = useHandleNewEntry(diaryId);

	return (
		<section className="md-width">
			<FormPage
				pageIndex={Number(pageIndex)}
				form={form}
				entryId={entryId}
				route={Route}
				page={form?.pages.find((p) => p.page_index === Number(pageIndex))}
			/>
		</section>
	);
};

export const Route = createFileRoute('/tracker/$trackerId/form/$formId/entry/$entryId/page/$pageIndex/')({
	component: Page,
	loader: async ({ context, params }) => {
		const form = await context?.queryClient.ensureQueryData(selectFormByIdQueryOptions(params.formId));
		return { form };
	},
});
