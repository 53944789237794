import Select from '@/components/form/Select';
import {
	createUniqueInjectionOptions,
	toFilteredUniqueInjectionOptions,
} from '@/features/injection/utils/injectionOptions';
import type { InjectionContentT } from '@/types/database/pain';

interface Props {
	formValues: string[];
	injectionContents: InjectionContentT[];
}

const InjectionContentForm = (props: Props) => {
	return (
		<>
			<Select
				name="injection_substance"
				label="Injection Substance"
				options={createUniqueInjectionOptions(props.injectionContents, 'injection_substance')}
			/>
			<div className="flex flex-col gap-2 md:flex-row">
				<Select
					name="dose"
					label="Dose"
					options={toFilteredUniqueInjectionOptions(
						props.injectionContents,
						'dose',
						'injection_substance',
						props.formValues[2] as keyof InjectionContentT,
					)}
				/>
				<Select
					name="unit"
					label="Unit"
					options={toFilteredUniqueInjectionOptions(
						props.injectionContents,
						'unit',
						'dose',
						props.formValues[3] as keyof InjectionContentT,
					)}
				/>
			</div>
		</>
	);
};

export default InjectionContentForm;
