import { useFormContext } from 'react-hook-form';

import { ui } from '../ui';

interface Props {
	name?: string;
}

const Lastname: React.FC<Props> = ({ name = 'last_name' }) => {
	const { control } = useFormContext();

	return (
		<ui.FormField
			control={control}
			name={name}
			render={({ field }) => (
				<ui.FormItem>
					<ui.FormLabel>Last Name</ui.FormLabel>
					<ui.FormControl>
						<ui.Input type="text" placeholder="e.g. Holmes" {...field} />
					</ui.FormControl>
					<ui.FormMessage />
				</ui.FormItem>
			)}
		/>
	);
};

export default Lastname;
