import { commonComponents as cc } from '@/components/common';
import { ui } from '@/components/ui';
import { buttonVariants } from '@/components/ui/button';
import { injectionEventsByTrackerQueryOptions } from '@/features/events/api/selectInjectionEventsWithTracker';
import { cn } from '@/lib/utils';
import { Route as RadiologyReportRoute } from '@/routes/preview/radiology-report/patient/$patientId/event/$eventId/index';
import { services as s } from '@/services';
import { Link, createFileRoute } from '@tanstack/react-router';
import EditEvent from '../../../features/events/components/EditEvent';
import EventCard from '../../../features/events/components/EventCard';
const EventsPage = () => {
	const { events } = Route.useLoaderData();

	return (
		<ul className="flex flex-wrap gap-y-4 px-2 py-4">
			<cc.Title words="Events" />
			{events?.map((e) => (
				<li className="mx-auto max-w-sm" key={e.event_id}>
					<EventCard event={e} />
				</li>
			))}
		</ul>
	);
};

export const Route = createFileRoute('/_app/admin/events')({
	component: EventsPage,
	loader: async ({ context }) => {
		const events = await context?.queryClient.ensureQueryData(injectionEventsByTrackerQueryOptions());
		return { events };
	},
});
