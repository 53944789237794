import { colors } from '@/assets/colors';
import type { EntryViewT, StatefulDiaryT } from '@/types/database/diaries';
import type { ChartDataset, DatasetController, Point } from 'chart.js';
import dayjs from 'dayjs';

import { combineTrackerEntries } from '../../diary/services/flattenEntriesView';

const lineColors = [colors['blue-300'], colors['cyan-300'], colors['indigo-300'], colors['sky-300']];

const trackerColors = [colors['blue-500'], colors['cyan-500'], colors['indigo-500'], colors['sky-500']];

const graphSettings = (index: number) => {
	return {
		fill: false,
		borderColor: lineColors[index],
		backgroundColor: colors['slate-50'],
		tension: 0.4,
		pointRadius: 5,
		pointBorderWidth: 2,
		type: 'line' as const,
	};
};

export const sortEntries = (entries: EntryViewT[]) => {
	return entries?.sort((a, b) => {
		return dayjs(a.calculated_entry_time).unix() - dayjs(b.calculated_entry_time).unix();
	});
};

const extractDatasets = (data: EntryViewT[]) => {
	const datasets: ChartDataset[] = [];
	data.forEach((entry, i) => {
		const focusEntryValue = entry.entry_values_view.find((ev) => ev.is_focus);
		if (focusEntryValue) {
			const dateTime = entry.calculated_entry_time as string;
			const dataPoint: Point = {
				x: new Date(dateTime),
				y: focusEntryValue.number_value as number,
			};
			if (!datasets.length) {
				datasets.push({
					label: focusEntryValue?.label ?? '',
					data: [dataPoint],
					...graphSettings(i),
				});
			} else {
				datasets[0].data.push(dataPoint);
			}
		}
	});
	return datasets;
};

export const extractTrackerData = (trackers: StatefulDiaryT['trackers_view']) => {
	return trackers?.map((t, i) => {
		return {
			label: t.tracker_name ?? `Tracker ${i + 1}`,
			data: [{ x: t.reference_date, y: 100 }],
			maxBarThickness: 3,
			backgroundColor: trackerColors[i],
		};
	});
};
export const extractGraphData = (diary: StatefulDiaryT): DatasetController => {
	const datasets = extractDatasets(sortEntries(combineTrackerEntries(diary))).concat(
		extractTrackerData(diary?.trackers_view),
	);

	const data = {
		labels: [],
		datasets: datasets,
	};

	return data;
};
