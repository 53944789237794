import { supabaseClient } from '@/lib/supabase';
import { queryOptions, useQuery } from '@tanstack/react-query';

export async function getSession() {
	const { data, error } = await supabaseClient.auth.getSession();
	if (error) throw error;
	return data;
}

export const authQueryOptions = () => {
	return queryOptions({
		queryKey: ['session'],
		queryFn: () => getSession(),
	});
};

export function useGetSession() {
	const query = useQuery(authQueryOptions());
	return query;
}
