export const generalFAQs = [
	{
		question: 'Do I have to pay for Patient Watch?',
		answer:
			"Patient Watch has a 60 day money back guarantee meaning if you decide it is not for you, it won't have cost you a thing. Please contact our team at partnerships@patient-watch.com to get set up.",
	},
	{
		question: 'Is there a free trial period?',
		answer:
			'Yes, absolutely. For clinicians wanting to use the platform, you are more than welcome to sign-up for our free 90 day trial. To do this, simply email partnerships@patient-watch.com and ask for a registration code.',
	},
	{
		question: 'Is Patient Watch currently used in the NHS?',
		answer:
			'Yes, Patient Watch is actively employed in the orthopaedic, radiology and diabetes departments at Royal Surrey hospital and in community care.',
	},
	{
		question: 'Is Patient Watch for primary or secondary care?',
		answer:
			'Patient Watch services are available only to both primary and secondary care to be used for patients in hospitals or in the community.',
	},
	{
		question: 'Can I use Patient Watch even if my doctor is not signed up?',
		answer:
			'Yes, for some types of diary you can use Patient Watch without a clinician. For example, Foot Watch can be used well without a clinician. However, all of our products are designed to foster collaborative care and therefore work best if both the patient and the clinician is registered.',
	},

	{
		question: 'How is service provision monitored?',
		answer:
			'We actively collaborate with our partners through Quality Improvement Projects (QIPs) to continuously optimise the solution. Additionally, we gather service usage and outcome data, aligning with our fundamental value of evidence-based practices.',
	},
];
