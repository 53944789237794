const CameraTick = () => {
    return (
        <svg
            className="w-full h-full"
            width="100"
            height="100"
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M33.5 43.5V59.5C33.5 61.1569 34.8431 62.5 36.5 62.5H63C64.6569 62.5 66 61.1569 66 59.5V43.5C66 41.8431 64.6569 40.5 63 40.5H60.1468C59.1211 40.5 58.1665 39.976 57.6159 39.1106L55.8841 36.3894C55.3335 35.524 54.3789 35 53.3532 35H46.1468C45.1211 35 44.1665 35.524 43.6159 36.3894L41.8841 39.1106C41.3335 39.976 40.3789 40.5 39.3532 40.5H36.5C34.8431 40.5 33.5 41.8431 33.5 43.5Z"
                stroke="black"
            />
            <circle cx="50" cy="50" r="8.5" stroke="black" />
            <circle cx="50" cy="50" r="4.5" stroke="black" />
            <circle cx="64" cy="60" r="6.5" fill="white" stroke="black" />
            <rect
                x="61.2764"
                y="60"
                width="3"
                height="1.33333"
                rx="0.666667"
                transform="rotate(45 61.2764 60)"
                fill="black"
            />
            <rect
                x="67.5786"
                y="57.9428"
                width="7.02717"
                height="1.33333"
                rx="0.666667"
                transform="rotate(135 67.5786 57.9428)"
                fill="black"
            />
            <path d="M36 43H39" stroke="black" strokeLinecap="round" />
            <path d="M36 45H39" stroke="black" strokeLinecap="round" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M49.5 77C64.1355 77 76 65.1355 76 50.5C76 35.8645 64.1355 24 49.5 24C34.8645 24 23 35.8645 23 50.5C23 65.1355 34.8645 77 49.5 77ZM49.5 82C66.897 82 81 67.897 81 50.5C81 33.103 66.897 19 49.5 19C32.103 19 18 33.103 18 50.5C18 67.897 32.103 82 49.5 82Z"
                stroke="black"
            />
        </svg>
    );
};

export default CameraTick;
