import { Button } from '@/components/ui/button';
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandSeparator,
} from '@/components/ui/command';
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { cn } from '@/lib/utils';
import { ChevronsUpDown, Lock } from 'lucide-react';
import React from 'react';

import { useFormContext } from 'react-hook-form';
import { ScrollArea, ScrollBar } from '../../../components/ui/scroll-area';
import type { ProfileT } from '../../../types/database/profiles';
import { useProfileQuery } from '../api/selectProfile';
import useFavouriteStaff from '../hooks/useFavouriteStaff';
import FavouriteUserStar from './FavouriteUserStar';

export type PatientOptionT = {
	label: string;
	value: string;
};

interface Props {
	label?: string;
	includeUser?: boolean;
	placeholder?: string;
	name: string;
	disabled?: boolean;
	organisationId?: string;
}
function FavouriteUsersComboBox({ disabled, label, name, placeholder, organisationId, includeUser = false }: Props) {
	const { data: profile } = useProfileQuery();
	const [open, setOpen] = React.useState(false);
	const { all, faves, nonFaves } = useFavouriteStaff();
	const form = useFormContext();

	const filterByOrganisation = (profiles: ProfileT[], organisationId?: string) => {
		if (!organisationId) return profiles;
		return profiles.filter((profile) => profile?.organisations?.map((o) => o.organisation_id).includes(organisationId));
	};

	return (
		<FormField
			control={form.control}
			name={name}
			render={({ field }) => (
				<FormItem className="flex w-full flex-col items-start justify-start">
					{label && <FormLabel>{label}</FormLabel>}
					<Popover open={open} onOpenChange={setOpen}>
						<PopoverTrigger className="w-full" disabled={disabled} asChild>
							<FormControl>
								<Button
									variant="outline"
									role="combobox"
									size="sm"
									className={cn(
										'h-12 w-full justify-between rounded-md bg-white bg-opacity-100 px-3 text-sm hover:bg-slate-100',
										!field.value && 'text-slate-500',
									)}
								>
									{field.value
										? all?.concat([{ ...profile }])?.find((o) => o?.user_id === field?.value)?.name
										: placeholder || 'Select user'}
									{disabled ? (
										<Lock className="ml-2 h-4 w-4 shrink-0 opacity-50" />
									) : (
										<ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
									)}
								</Button>
							</FormControl>
						</PopoverTrigger>
						<PopoverContent className="w-full p-0">
							<ScrollArea className="max-h-[300px]">
								<Command>
									<CommandInput placeholder="Search..." className="h-9" />
									<CommandEmpty>No users found.</CommandEmpty>
									<ScrollArea className="h-50">
										{includeUser && !!profile && (
											<CommandGroup>
												<CommandItem
													className="w-full py-2"
													value={profile.user_id as string}
													onSelect={() => {
														form.setValue(name, profile.user_id);
														setOpen(false);
													}}
												>
													{profile.name}
												</CommandItem>
											</CommandGroup>
										)}
										<CommandSeparator />
										<CommandGroup>
											{filterByOrganisation(faves, organisationId)?.map((o) => (
												<div key={o.user_id} className="flex">
													<FavouriteUserStar userId={o.user_id} favourite={true} />

													<CommandItem
														className="w-full"
														value={o.user_id}
														key={o.user_id}
														onSelect={() => {
															form.setValue(name, o.user_id);
															setOpen(false);
														}}
													>
														{o.name}
													</CommandItem>
												</div>
											))}
										</CommandGroup>
										<CommandSeparator />
										<CommandGroup>
											{filterByOrganisation(nonFaves, organisationId)?.map((o) => (
												<div key={o.user_id} className="flex w-full">
													<FavouriteUserStar userId={o.user_id} favourite={false} />
													<CommandItem
														className="w-full"
														value={o.user_id}
														key={o.user_id}
														onSelect={() => {
															form.setValue(name, o.user_id);
															setOpen(false);
														}}
													>
														{o.name}
													</CommandItem>
												</div>
											))}
										</CommandGroup>
										<ScrollBar />
									</ScrollArea>
								</Command>
							</ScrollArea>
						</PopoverContent>
					</Popover>
					<FormMessage />
				</FormItem>
			)}
		/>
	);
}

export default FavouriteUsersComboBox;
