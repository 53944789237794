import { diaryByIdQueryOptions } from '@/features/diary/api/getDiaryById';
import { diariesBreadcrumb } from '@/features/navigation/services/breadcrumbRoutes';
import { hooks as h } from '@/hooks';
import { Outlet, createFileRoute } from '@tanstack/react-router';

const NewPage = () => {
	const { diaryId } = Route.useParams();

	h.useNav([
		diariesBreadcrumb,
		{
			route: { to: '/diary/$diaryId/home/new' },
			label: 'New',
			options: [
				{ route: { to: '/diary/$diaryId/home/new', params: { diaryId } }, label: 'New' },
				{ route: { to: '/diary/$diaryId/home/history', params: { diaryId } }, label: 'History' },
				{ route: { to: '/diary/$diaryId/home/charts', params: { diaryId } }, label: 'Charts' },
			],
		},
		{
			route: {
				to: '/diary/$diaryId/new/tracker/$trackerId/entry/$entryId/page/$pageIndex',
				params: { pageIndex: 1 },
			},
			label: 'New Entry',
		},
	]);

	return <Outlet />;
};

export const Route = createFileRoute('/_app/diary/$diaryId/new/tracker/$trackerId/entry/$entryId/page')({
	component: NewPage,
	loader: ({ context, params }) => {
		context?.queryClient.ensureQueryData(diaryByIdQueryOptions(params.diaryId));
	},
});
