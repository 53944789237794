import BackArrow from './BackArrow';

const ForwardArrow = () => {
    return (
        <div className="ml-[2px] w-8 h-8 p-[6px] flex items-center justify-center rotate-180">
            <BackArrow fill="white" />
        </div>
    );
};

export default ForwardArrow;
