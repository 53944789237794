import { icons } from '@/assets/icons';
import { cn } from '@/lib/utils';
import type { ToT } from '@/types/zod/routes';
import { useNavigate } from '@tanstack/react-router';
import { Lock, Pencil } from 'lucide-react';
import { toast } from 'sonner';
import { warningColors } from '../../utils/warningColors';
import { buttonVariants } from '../ui/button';

interface Props {
	label: string;
	noInfo?: boolean;
	info: string | React.ReactNode;
	help?: () => void;
	locked?: boolean;
	tabIndex?: number;
	show: boolean;
	backRoute?: ToT;
	route: ToT;
	description?: string;
}

const EditRow = ({ label, info, help, locked, tabIndex, show, route, noInfo, description }: Props) => {
	const navigate = useNavigate();

	const boolmap = (val: boolean) => {
		if (val === true) {
			return 'Yes';
		}
		if (val === false) {
			return 'No';
		}
	};

	if (!show) {
		return <></>;
	}

	const missingInfo = (!info || !!noInfo) ?? false;

	return (
		<button
			type="button"
			onClick={() => (locked ? toast.error('Profile attribute is locked') : navigate(route))}
			tabIndex={tabIndex}
			disabled={locked}
			className={cn('my-2 flex w-full items-center justify-between', locked ? 'cursor-not-allowed' : 'cursor-pointer')}
		>
			<div className="">
				<p className={cn('my-1 w-full p-0 text-left text-sm font-semibold', warningColors(missingInfo))}>
					{label}
					{help && (
						<button type="button" onClick={help}>
							<icons.Question />
						</button>
					)}
				</p>
				{description && <p className="text-sm text-slate-600 text-left">{description}</p>}

				<p className={cn('my-1 w-full p-0 text-left text-lg', warningColors(missingInfo))}>
					{info === null || info === undefined || info === ''
						? 'Information Missing'
						: typeof info === 'boolean'
							? boolmap(info)
							: info}
				</p>
			</div>
			<div
				className={cn(
					buttonVariants({ variant: locked ? 'ghost' : 'secondary', size: 'icon' }),
					'rounded-full p-0 text-slate-800',
				)}
				tabIndex={-1}
			>
				{locked ? <Lock /> : <Pencil />}
			</div>
		</button>
	);
};

export default EditRow;
