import { ui } from '@/components/ui';
import { createFileRoute } from '@tanstack/react-router';
import { Calendar, Clipboard, Copy, Flag, Pencil, Plus } from 'lucide-react';

const DiaryModel = () => {
    return (
        <div className="m-4 ">
            <ui.Card className="flex flex-col gap-2">
                <ui.CardHeader>
                    <div>
                        <ui.CardTitle className="flex flex-row items-center justify-between gap-2">
                            Diary
                            <div className="flex flex-row gap-2">
                                <ui.Button className="w-8 h-8" size="icon" variant="ghost">
                                    <Pencil size={24} />
                                </ui.Button>
                                <ui.Button className="w-8 h-8" size="icon" variant="ghost">
                                    <Copy size={24} />
                                </ui.Button>
                            </div>
                        </ui.CardTitle>
                    </div>
                </ui.CardHeader>
                <hr />
                <ui.CardContent>
                    <div className="flex flex-col gap-2 rounded-lg ">
                        <div>
                            <div className="flex flex-row gap-2">
                                <div className="w-full">
                                    <ui.Button className="w-full px-auto" variant="outline">
                                        <Clipboard />
                                        Form
                                    </ui.Button>
                                    <p>Slider</p>
                                    <p>Slider</p>
                                    <p>Slider</p>
                                </div>
                                <div className="w-full">
                                    <ui.Button className="w-full px-auto bg-slate-50" variant="outline">
                                        <Flag />
                                        Rules
                                    </ui.Button>
                                    <p>Slider</p>
                                    <p>Slider</p>
                                    <p>Slider</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </ui.CardContent>
                <hr />

                <ui.CardFooter>
                    <div className="flex flex-col w-full gap-2">
                        <ui.Button variant="outline">
                            <Calendar />
                            Schedule
                        </ui.Button>
                        <ui.Button variant="secondary">
                            <Plus />
                            Add Schedule
                        </ui.Button>
                    </div>
                </ui.CardFooter>
            </ui.Card>
        </div>
    );
};

export const Route = createFileRoute('/_app/builder copy/diary/$diaryId/')({
    component: DiaryModel,
});
