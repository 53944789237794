import React, { useState, useRef, useEffect } from 'react';
import { Button } from '@/components/ui/button';
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import SectionTitle from './SectionTitle';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { Input } from '@/components/ui/input';
import { Mail } from 'lucide-react';
const FormSchema = z.object({
    email: z.string().min(1, { message: 'This field has to be filled.' }).email('This is not a valid email.'),
});

import { useForm as formspree } from '@formspree/react';

const GetInTouch = () => {
    const form = useForm<z.infer<typeof FormSchema>>({
        resolver: zodResolver(FormSchema),
    });

    const [state, handleSubmit] = formspree('xpzgvdgq');

    function onSubmit(values: z.infer<typeof FormSchema>) {
        handleSubmit(values);
    }

    return (
        <div className="md:px-24 mx-auto flex flex-col items-center justify-center">
            <SectionTitle title="Get In Touch" description="Understand how Patient Watch can help your organisation." />
            <div className="w-full flex items-center justify-center flex-col gap-y-2 mt-6">
                {state.succeeded ? (
                    <div>Success</div>
                ) : (
                    <div className="flex flex-col gap-y-2">
                        <Form {...form}>
                            <form
                                onSubmit={form.handleSubmit(onSubmit)}
                                className="flex w-full mx-auto items-center justify-center gap-x-2"
                            >
                                <FormField
                                    control={form.control}
                                    name="email"
                                    render={({ field }) => (
                                        <FormItem className="w-full max-w-md">
                                            <FormControl className="w-full">
                                                <Input className="w-full" placeholder="Enter your email" {...field} />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />

                                <Button className="h-12 px-6 w-fit" type="submit">
                                    {state.submitting ? 'Submitting' : 'Submit'}
                                </Button>
                            </form>
                        </Form>
                        <p className="mt-2 text-center">
                            By entering your email you agree to our{' '}
                            <a
                                className="text-indigo-600 underline-offset-4 hover:underline"
                                target="_blank"
                                href="https://patient-watch.notion.site/Terms-of-Use-816e45ec28e14eedba843dc8f3f74407"
                            >
                                Terms of Use
                            </a>{' '}
                            and{' '}
                            <a
                                className="text-indigo-600 underline-offset-4 hover:underline"
                                target="_blank"
                                href="https://patient-watch.notion.site/Privacy-Policy-50288e6ac2d946bca935fbf5bdc30782?pvs=25"
                            >
                                Privacy Policy
                            </a>
                            .
                        </p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default GetInTouch;
