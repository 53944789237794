import Title from '@/components/common/Title';
import JoinOrganisationForm from '@/features/organisations/components/JoinOrganisationForm';
import { hooks as h } from '@/hooks';
import { createFileRoute } from '@tanstack/react-router';
function NewOrganisation() {
	h.useNav([
		{ label: 'Profile', route: { to: '/profile' } },
		{ label: 'Organisation', route: { to: '/profile/join-organisation' } },
	]);
	return (
		<>
			<Title
				words="Organisation"
				description={'Adding an organisation will enable data sharing between you and the organisation.'}
			/>
			<JoinOrganisationForm isOnboarding={false} />
		</>
	);
}

export const Route = createFileRoute('/_app/profile/organisation')({
	component: NewOrganisation,
});

export default NewOrganisation;
