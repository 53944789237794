import { diaryByIdQueryOptions, useDiaryByIdQuery } from '@/features/diary/api/getDiaryById';
import NewEntrySwitch from '@/features/diary/new/components/NewCardSwitch';
import NoActiveTrackerButton from '@/features/trackers/components/NoActiveTrackerButton';
import { hooks as h } from '@/hooks';
import type { StatefulTrackerViewT, TrackerTemplateViewT } from '@/types/database/trackers';
import { CatchBoundary, createFileRoute } from '@tanstack/react-router';
import dayjs from 'dayjs';

const NewEntry = () => {
	const { diaryId } = Route.useParams();
	const { data: diary } = useDiaryByIdQuery(diaryId);

	h.useNav([
		{ label: 'Diaries', route: { to: '/diaries' } },
		{
			route: { to: '/diary/$diaryId/home/new' },
			label: 'New',
			options: [
				{ route: { to: '/diary/$diaryId/home/new' }, label: 'New' },
				{ route: { to: '/diary/$diaryId/home/history' }, label: 'History' },
				{ route: { to: '/diary/$diaryId/home/charts' }, label: 'Charts' },
			],
		},
	]);

	return (
		<>
			<NoActiveTrackerButton
				trackers={diary.trackers_view}
				diaryId={diaryId}
				trackerTemplates={diary?.diary_templates?.tracker_templates_view as TrackerTemplateViewT[]}
			/>

			<ul className="flex flex-col gap-4">
				{diary?.trackers_view
					.sort((a, b) => {
						return dayjs(b.start_date).unix() - dayjs(a.start_date).unix();
					})
					.map((t) => (
						<li key={t.tracker_id}>
							<CatchBoundary getResetKey={() => 'reset'} errorComponent={<p>Error at diary entry switch</p>}>
								<NewEntrySwitch tracker={t as StatefulTrackerViewT} />
							</CatchBoundary>
						</li>
					))}
			</ul>
		</>
	);
};

export default NewEntry;

export const Route = createFileRoute('/_app/diary/$diaryId/home/new')({
	component: NewEntry,
	loader: async ({ context, params }) => {
		const diary = await context?.queryClient.ensureQueryData(diaryByIdQueryOptions(params.diaryId));
		return { diary };
	},
});
