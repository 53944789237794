import React from 'react';

const DashboardBox = () => {
    return (
        <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M55.4421 39.6632H42.5579C41.0231 39.6632 39.7789 40.8555 39.7789 42.3263V54.6737C39.7789 56.1445 41.0231 57.3368 42.5579 57.3368H55.4421C56.9769 57.3368 58.2211 56.1445 58.2211 54.6737V42.3263C58.2211 40.8555 56.9769 39.6632 55.4421 39.6632ZM42.5579 37C39.4884 37 37 39.3847 37 42.3263V54.6737C37 57.6153 39.4884 60 42.5579 60H55.4421C58.5116 60 61 57.6153 61 54.6737V42.3263C61 39.3847 58.5116 37 55.4421 37H42.5579Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M55.4421 11.6632H42.5579C41.0231 11.6632 39.7789 12.8555 39.7789 14.3263V26.6737C39.7789 28.1445 41.0231 29.3368 42.5579 29.3368H55.4421C56.9769 29.3368 58.2211 28.1445 58.2211 26.6737V14.3263C58.2211 12.8555 56.9769 11.6632 55.4421 11.6632ZM42.5579 9C39.4884 9 37 11.3847 37 14.3263V26.6737C37 29.6153 39.4884 32 42.5579 32H55.4421C58.5116 32 61 29.6153 61 26.6737V14.3263C61 11.3847 58.5116 9 55.4421 9H42.5579Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M27.4421 39.6632H14.5579C13.0231 39.6632 11.7789 40.8555 11.7789 42.3263V54.6737C11.7789 56.1445 13.0231 57.3368 14.5579 57.3368H27.4421C28.9769 57.3368 30.2211 56.1445 30.2211 54.6737V42.3263C30.2211 40.8555 28.9769 39.6632 27.4421 39.6632ZM14.5579 37C11.4884 37 9 39.3847 9 42.3263V54.6737C9 57.6153 11.4884 60 14.5579 60H27.4421C30.5116 60 33 57.6153 33 54.6737V42.3263C33 39.3847 30.5116 37 27.4421 37H14.5579Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M27.4421 11.6632H14.5579C13.0231 11.6632 11.7789 12.8555 11.7789 14.3263V26.6737C11.7789 28.1445 13.0231 29.3368 14.5579 29.3368H27.4421C28.9769 29.3368 30.2211 28.1445 30.2211 26.6737V14.3263C30.2211 12.8555 28.9769 11.6632 27.4421 11.6632ZM14.5579 9C11.4884 9 9 11.3847 9 14.3263V26.6737C9 29.6153 11.4884 32 14.5579 32H27.4421C30.5116 32 33 29.6153 33 26.6737V14.3263C33 11.3847 30.5116 9 27.4421 9H14.5579Z"
                fill="black"
            />
        </svg>
    );
};

export default DashboardBox;
