import FaqBlock from '@/features/website/components/FaqBlock';
import Section from '@/features/website/components/Section';
import { generalFAQs } from '@/features/website/landing/content/generalFaqs';
import { painWatchFAQs } from '@/features/website/pain-watch/content/painWatchFaqs';
import { securityFAQs } from '@/features/website/security/content/securityFaqs';
import { createFileRoute } from '@tanstack/react-router';

const FAQs = () => {
	return (
		<>
			<Section title="General">
				<FaqBlock FAQs={generalFAQs} />
			</Section>
			<Section title="Security">
				<FaqBlock FAQs={securityFAQs} />
			</Section>
			<Section title="Pain Watch">
				<FaqBlock FAQs={painWatchFAQs} />
			</Section>
		</>
	);
};

export const Route = createFileRoute('/_website/faqs')({
	component: FAQs,
});

export default FAQs;
