import type { InputT } from '@/types/database/forms';
import * as z from 'zod';

import { getInputSchema } from './inputSettings';

// Function to generate the validation schema
export function createValidationSchema(inputsFromDatabase: InputT[]) {
	return z.object(
		inputsFromDatabase?.reduce((acc, { input_id, input_key, is_optional }) => {
			const { validation } = getInputSchema({ input_key, is_optional });
			return {
				...acc,
				[input_id as string]: validation,
			};
		}, {}),
	);
}
