import { supabaseClient } from '@/lib/supabase';
import { useAuth } from '@/utils/auth';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';
import { toast } from 'sonner';
import { useNav } from '../../../utils/nav';
import useAuthQuery from './useAuthQuery';

export async function logout() {
	const { error } = await supabaseClient.auth.signOut();
	if (error) throw error;
}

export const useLogoutMutation = () => {
	const nav = useNav();
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const { refetch } = useAuthQuery();
	const auth = useAuth();

	return useMutation({
		mutationFn: () => {
			auth.logout();
			nav.reset();
			return logout();
		},
		onError: (error) => {
			toast.error(error.message);
		},
		onSuccess: () => {
			navigate({ to: '/authenticate' });
			toast.success('Successfully logged out');
			queryClient.setQueryData(['user'], {});
		},
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey: ['user'] });
			refetch();
		},
	});
};
