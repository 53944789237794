import { ui } from '@/components/ui';
import type { RouteT } from '@/types/zod/routes';
import { Link } from '@tanstack/react-router';
import { useEffect, useState } from 'react';

type TabOptions = 'new' | 'history' | 'charts';
const DiaryViewTabs = () => {
	const [tab, setTab] = useState<TabOptions>('new');

	const getCurrentTab = () => {
		if (location.pathname.split('/').includes('charts')) {
			return 'charts';
		}
		if (location.pathname.split('/').includes('history')) {
			return 'history';
		}
		return 'new';
	};

	useEffect(() => {
		setTab(getCurrentTab());
	}, [location.pathname]);

	return (
		<ui.Tabs value={tab}>
			<ui.TabsList className="h-16 w-full rounded-md p-[5px]">
				<Tab value="new" label="Add" to="/diary/$diaryId/home/new" />
				<Tab value="history" label="History" to="/diary/$diaryId/home/history" />
				<Tab value="charts" label="charts" to="/diary/$diaryId/home/charts" />
			</ui.TabsList>
			<ui.TabsContent
				tabIndex={-1}
				className="w-full px-4 text-center text-lg text-slate-700 dark:text-slate-200"
				value="new"
			>
				Here you can add new diary entries when they are due.
			</ui.TabsContent>
			<ui.TabsContent
				tabIndex={-1}
				className="w-full px-4 text-center text-lg text-slate-700 dark:text-slate-200"
				value="history"
			>
				Here you can see the your diary entries, events and clinician actions.
			</ui.TabsContent>
			<ui.TabsContent
				tabIndex={-1}
				className="w-full px-4 text-center text-lg text-slate-700 dark:text-slate-200"
				value="charts"
			>
				Here is a graphical overview of your progress.
			</ui.TabsContent>
		</ui.Tabs>
	);
};

export default DiaryViewTabs;

interface TabProps {
	value: string;
	label: React.ReactNode;
	to: RouteT;
}

const Tab = (props: TabProps) => {
	return (
		<ui.TabsTrigger asChild className="h-full w-full rounded-md text-lg" value={props.value}>
			<Link className="capitalize" to={props.to} params={(prev) => ({ ...prev })}>
				{props.label}
			</Link>
		</ui.TabsTrigger>
	);
};
