import { ui } from '@/components/ui';
import {
	DateOfBirthDialog,
	EmailDialog,
	NameDialog,
	PhoneDialog,
} from '@/features/profile/components/EditProfileDialogs';
import type { ProfileT } from '@/types/database/profiles';

interface Props {
	profile: ProfileT;
	editable?: boolean;
}
const PatientCard = ({ profile, editable = true }: Props) => {
	if (!profile) {
		return (
			<ui.Card>
				<ui.CardHeader>
					<ui.Skeleton className="w-1/2" />
				</ui.CardHeader>
				<ui.CardContent>
					<ui.Skeleton className="w-3/4" />
					<ui.Skeleton className="w-3/4" />
					<ui.Skeleton className="w-1/2" />
				</ui.CardContent>
			</ui.Card>
		);
	}
	return (
		<ui.Card className="">
			<ui.CardHeader>
				<ui.CardTitle className="text-xl">Patient Details</ui.CardTitle>
			</ui.CardHeader>
			<ui.CardContent className="">
				<NameDialog editable={editable} profile={profile} />
				<DateOfBirthDialog editable={editable} profile={profile} />
				<EmailDialog editable={editable} profile={profile} locked={true} />
				<PhoneDialog editable={editable} profile={profile} locked={true} />
				{/* <NhsNumberDialog profile={profile} /> */}
			</ui.CardContent>
		</ui.Card>
	);
};

export default PatientCard;
