import { supabaseClient } from '@/lib/supabase';
import { queryOptions, useSuspenseQuery } from '@tanstack/react-query';

export async function selectPatients() {
	const { data, error } = await supabaseClient.from('patients').select('*, profiles!patients_patient_id_fkey(*)');
	if (error) throw error;
	return data;
}

export const selectPatientsQueryKey = ['patients'];

export const patientsQueryOptions = () => {
	return queryOptions({
		queryKey: selectPatientsQueryKey,
		queryFn: () => selectPatients(),
	});
};

export const usePatientsQuery = () => {
	return useSuspenseQuery(patientsQueryOptions());
};
