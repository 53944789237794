import { supabaseClient } from '@/lib/supabase';
import type { Surgery } from '@/types/database/events';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';

import { createGetDiaryKey } from '../../../../diary/api/getDiaryById';

export async function updateSurgery(surgeryData: Surgery['Update'] & { surgery_id: string }) {
	const { data, error } = await supabaseClient
		.from('surgeries')
		.update(surgeryData)
		.eq('surgery_id', surgeryData.surgery_id)
		.select()
		.single();
	if (error) throw error;
	return data;
}

interface SurgeryMutation {
	diaryId: string;
	surgeryId: string;
}

export const useUpdateSurgeryMutation = ({ diaryId, surgeryId }: SurgeryMutation) => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationKey: ['update-surgery', { surgery_id: surgeryId }],
		mutationFn: async (data: Surgery['Update']) => {
			return await updateSurgery({ ...data, surgery_id: surgeryId });
		},
		onError: () => {
			toast.error('Error creating new surgery');
		},
		onSuccess: (mutation) => {
			toast.success('Surgery saved');

			if (mutation?.onSuccess) {
				mutation.onSuccess();
			}
		},
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey: createGetDiaryKey(diaryId) });
			queryClient.invalidateQueries({ queryKey: ['diaries'] });
		},
	});
};
