import { supabaseClient } from '@/lib/supabase';
import type { RoleT } from '@/types/zod/auth';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';
import { toast } from 'sonner';

import { useAuth } from '../../../utils/auth';
import { createProfileQueryKey } from '../../profile/api/selectProfile';
import { createUsersOrganisationsRolesQueryKey, useSetRoles } from './selectUsersOrganisationsRoles';

interface AddOrganisationT {
	organisation_code: string;
	role: RoleT;
}
export async function addOrganisation(data: AddOrganisationT) {
	try {
		const res = await supabaseClient.functions.invoke('public-add-user-organisation-role', {
			body: data,
		});
		if (res.data) {
			return res.data;
		}
		throw new Error('Wrong organisation code');
	} catch (error) {
		console.error('Error adding organisation:', error);
		throw error;
	}
}

export const addOrganisationKey = () => ['join-organisation'];

export const useAddOrganisationMutation = (isOnboarding?: boolean) => {
	const { retry } = useSetRoles();

	const auth = useAuth();
	const navigate = useNavigate({ from: '/onboarding/organisation' });
	const queryClient = useQueryClient();
	return useMutation({
		mutationKey: addOrganisationKey(),
		mutationFn: (data: AddOrganisationT) => addOrganisation(data),
		onSuccess: (data) => {
			console.info(`Adding role ${JSON.stringify(data)}`);
			auth.roles?.addRole(data);
			toast.info('Organisation added');
			setTimeout(() => {
				retry();
			}, 200);
			if (isOnboarding) {
				setTimeout(() => {
					navigate({ to: '/onboarding/name', search: {}, params: {} });
				}, 500);
			} else {
				navigate({ to: '/profile', search: {}, params: {} });
			}
		},
		onError: (error) => {
			toast.error(error.message);
		},
		onSettled: (data) => {
			queryClient.invalidateQueries({ queryKey: createUsersOrganisationsRolesQueryKey(data.user_id) });
			queryClient.invalidateQueries({ queryKey: createProfileQueryKey(data.user_id) });
			queryClient.invalidateQueries({ queryKey: ['user'] });
		},
	});
};
