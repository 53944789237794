import { useAuth } from '@/utils/auth';
import { useNavigate } from '@tanstack/react-router';
import { clinicianSteps, patientSteps, routes, secretarySteps } from '../components/OnboardingStepper';

function getNextOnboardingRoute(
	currentRoute: string,
	userType: 'patient' | 'clinician' | 'secretary',
): string | undefined {
	const steps = userType === 'patient' ? patientSteps : userType === 'clinician' ? clinicianSteps : secretarySteps;
	const currentIndex = steps.findIndex((step) => step.route?.to === currentRoute);
	if (currentIndex < steps.length - 1) {
		return steps[currentIndex + 1]?.route?.to ?? routes.profile;
	}
	return undefined;
}

export const useNavigateToNextOnboardingRoute = (currentRoute: string) => {
	const auth = useAuth();
	const navigate = useNavigate();

	const nextRoute = getNextOnboardingRoute(
		currentRoute,
		auth.roles?.isPatient ? 'patient' : auth.roles?.isClinician ? 'clinician' : 'secretary',
	);
	return () => navigate({ to: nextRoute });
};
