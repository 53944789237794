import { z } from 'zod';

// Tailwind Colors
export const tailwindColors = [
	'pink',
	'rose',
	'fuchsia',
	'purple',
	'violet',
	'indigo',
	'blue',
	'sky',
	'cyan',
	'teal',
	'emerald',
	'green',
	'lime',
	'yellow',
	'amber',
	'orange',
	'red',
	'gray',
];

// Unknown String
export type MaybeStringT = string | undefined | null;

// Size
export const SizeZ = z.enum(['xs', 'sm', 'md', 'lg', 'xl']);
export type SizeT = z.infer<typeof SizeZ>;

// Side
export const SideZ = z.enum(['Left', 'Right', 'Middle', 'Both', 'N/A']);
export type SideT = z.infer<typeof SideZ>;

// Diary Types
export const DiaryTypeZ = z.enum(['PAIN', 'FOOT', 'FISHER', 'DISCHARGE']);
export type DiaryTypeT = z.infer<typeof DiaryTypeZ>;

export const DateStringZ = z.union([z.string(), z.date()]);

export type VerifyT = {
	token: string;
};
