interface Props {
	color: string;
}
const ActiveMonitoring = ({ color }: Props) => {
	return (
		<svg width="35" height="41" viewBox="0 0 35 41" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.726 4.49605C12.793 2.27772 14.6124 0.5 16.847 0.5H16.8708H17.4H17.4239C19.6585 0.5 21.4778 2.27772 21.5449 4.49605L23.4428 5.28038C27.4212 6.92447 30.0161 10.8046 30.0161 15.1093V22.519C30.0161 23.4139 30.3551 24.2779 30.964 24.9353C32.7167 26.8274 33.7935 29.251 34.0203 31.8211L34.1721 33.5422C34.2886 34.8632 33.2477 36 31.9216 36H23.3424L22.7864 37.1204C21.7591 39.1906 19.6475 40.5 17.3364 40.5C14.8428 40.5 12.6017 38.9784 11.6816 36.6608L11.4193 36H2.34925C1.02315 36 -0.01778 34.8632 0.0987585 33.5422L0.2506 31.8211C0.47734 29.251 1.55416 26.8274 3.30682 24.9353C3.91578 24.2779 4.25474 23.4139 4.25474 22.519V15.1093C4.25474 10.8046 6.84969 6.92447 10.8281 5.28038L12.726 4.49605ZM14.6901 36C15.2405 36.9201 16.2404 37.5 17.3364 37.5C18.4265 37.5 19.429 36.9253 19.9827 36H17.4H16.8708H14.6901ZM16.847 3.5H16.8708H17.4H17.4239C18.0441 3.5 18.5468 4.00275 18.5468 4.62293C18.5468 5.7609 19.2328 6.78664 20.2845 7.22127L22.297 8.05295C25.1532 9.23327 27.0161 12.0189 27.0161 15.1093V22.519C27.0161 24.1711 27.6413 25.7629 28.7632 26.974C30.0645 28.3788 30.8637 30.1783 31.0319 32.0847L31.1126 33H17.4H16.8708H3.15825L3.23899 32.0847C3.40719 30.1783 4.20638 28.3788 5.50766 26.974C6.62957 25.7629 7.25474 24.1711 7.25474 22.519V15.1093C7.25474 12.0189 9.1177 9.23327 11.9738 8.05295L13.9864 7.22127C15.0381 6.78664 15.7241 5.7609 15.7241 4.62293C15.7241 4.00275 16.2268 3.5 16.847 3.5Z"
				fill={color ?? currentColor}
			/>
		</svg>
	);
};

export default ActiveMonitoring;
