import FootIcon from '../../foot-watch/assets/FootIcon';
import InjectionIcon from '../../pain-watch/assets/InjectionIcon';

import type { Watch } from '../../../definitions/diaryTypes';

interface Props {
	watch?: Watch;
}
const WatchBadge = ({ watch }: Props) => {
	return (
		<>
			{watch && (
				<div className="flex w-fit items-center  justify-center gap-x-1 rounded-full text-sm text-slate-600">
					{watch === 'FOOT' && (
						<>
							<p>Foot Watch</p>
							<FootIcon />
						</>
					)}
					{watch === 'PAIN' && (
						<>
							<p>Pain Watch</p>
							<InjectionIcon />
						</>
					)}
				</div>
			)}
		</>
	);
};

export default WatchBadge;
