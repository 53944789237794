import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';

export interface FAQI {
    question: string;
    answer: string | React.ReactNode;
}
interface Props {
    FAQs: FAQI[];
}

const FaqBlock = ({ FAQs }: Props) => {
    return (
        <>
            {FAQs.map((FAQ) => {
                return (
                    <Accordion key={FAQ.question} className="w-full px-2 text-lg" type="single" collapsible>
                        <AccordionItem value="item-1">
                            <AccordionTrigger>{FAQ.question}</AccordionTrigger>
                            <AccordionContent className="flex flex-col text-md gap-y-4">{FAQ.answer}</AccordionContent>
                        </AccordionItem>
                    </Accordion>
                );
            })}
        </>
    );
};

export default FaqBlock;
