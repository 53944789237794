interface Props {
	strokeUp?: string;
	strokeDown?: string;
	className?: string;
}

const SortArrows = ({ strokeUp, strokeDown, className }: Props) => {
	return (
		<svg
			className={className}
			width="27"
			height="26"
			viewBox="0 0 27 26"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M6 1V25M6 25L1 19.8852M6 25L11 19.8852"
				stroke={strokeDown ? strokeDown : '#9CA3AF'}
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M21 25V1M21 1L26 6.11475M21 1L16 6.11475"
				stroke={strokeUp ? strokeUp : '#9CA3AF'}
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default SortArrows;
