const Five = () => {
	return (
		<svg
			role="img"
			aria-label="Pain Five"
			width="50"
			height="50"
			viewBox="0 0 50 50"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect x="1" y="1" width="48" height="48" rx="24" fill="white" stroke="black" strokeWidth="2" />
			<rect x="11" y="16" width="8" height="8" rx="4" fill="#0E0E0E" />
			<rect x="31" y="16" width="8" height="8" rx="4" fill="#0E0E0E" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M16.4929 11.7438C16.6345 12.2776 16.3166 12.8252 15.7828 12.9668L15.6862 12.9924C13.6688 13.5277 11.729 14.3174 9.91346 15.3416C9.43243 15.6129 8.81705 15.4698 8.52635 15.0002V15.0002C8.23566 14.5307 8.37978 13.9126 8.85997 13.6397C10.8437 12.5127 12.9655 11.6451 15.1734 11.0593L15.2699 11.0337C15.8037 10.8921 16.3513 11.21 16.4929 11.7438Z"
				fill="black"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M32.5598 11.7436C32.4182 12.2774 32.7361 12.8249 33.2699 12.9666L33.3665 12.9922C35.384 13.5274 37.3237 14.3171 39.1393 15.3413C39.6203 15.6127 40.2357 15.4696 40.5264 15V15C40.8171 14.5304 40.673 13.9123 40.1928 13.6395C38.2091 12.5124 36.0873 11.6448 33.8794 11.0591L33.7828 11.0334C33.249 10.8918 32.7014 11.2098 32.5598 11.7436Z"
				fill="black"
			/>
			<path
				d="M18.8148 35.9753L17.5177 36.4096C17.0236 36.575 16.6085 36.9181 16.3531 37.3723C16.2098 37.627 16.423 37.9348 16.7118 37.8903L19.2233 37.5034C20.8221 37.257 22.4374 37.1333 24.0551 37.1333L25.4882 37.1333C27.1606 37.1333 28.8308 37.2533 30.486 37.4924L33.2917 37.8977C33.581 37.9395 33.7925 37.6311 33.6492 37.3763C33.3922 36.9196 32.9749 36.5745 32.478 36.4081L31.1852 35.9753C29.2563 35.3293 27.2355 35 25.2013 35L24.7987 35C22.7645 35 20.7437 35.3293 18.8148 35.9753Z"
				fill="black"
				stroke="black"
			/>
		</svg>
	);
};

export default Five;
