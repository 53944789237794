import { supabaseClient } from '@/lib/supabase';
import { queryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

export async function selectCliniciansProfiles() {
	const { data, error } = await supabaseClient.from('profiles').select().contains('roles', ['CLINICIAN']);
	if (error) throw error;
	return data;
}

export const selectCliniciansProfilesQueryOptions = () =>
	queryOptions({
		queryKey: ['clinician-profiles'],
		queryFn: async () => selectCliniciansProfiles(),
	});

export function useSelectProfiles() {
	const options = selectCliniciansProfilesQueryOptions();
	return useQuery(options);
}
