import { commonComponents as cc } from '@/components/common';
import { selectProfileByIdQueryOptions } from '@/features/profile/api/selectProfile';
import NhsNumberForm from '@/features/profile/components/NhsNumberForm';
import { createFileRoute } from '@tanstack/react-router';

const Name = () => {
	const profile = Route.useLoaderData();

	return (
		<section className="mx-auto my-6 w-full max-w-[500px] px-2">
			<cc.Title words="NHS Number" />
			<NhsNumberForm userId={profile?.user_id ?? ''} nhsNumber={profile?.nhs_number} />
		</section>
	);
};

export const Route = createFileRoute('/_app/profile/nhs-number')({
	component: Name,
	loader: async ({ context }) => {
		return context?.queryClient.ensureQueryData(selectProfileByIdQueryOptions(context.auth.id));
	},
});

export default Name;
