import type { EntryViewT } from '@/types/database/diaries';

import useExtractEntry from './useExtractEntry';

export function extractEntryValueById(entry: EntryViewT, entryValueId: string) {
	return entry.entry_values?.find((evv) => evv.entry_value_id === entryValueId);
}

const useExtractEntryValue = (diaryId: string, trackerId: string, entryId: string, entryValueId: string) => {
	const entry = useExtractEntry(diaryId, trackerId, entryId);
	if (!entry) return;
	return extractEntryValueById(entry, entryValueId);
};

export default useExtractEntryValue;
