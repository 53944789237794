import { ui } from '../../../components/ui';
import { DiaryTemplateT } from '../../../types/database/diaries';

interface Props {
    template: DiaryTemplateT;
}

const DiaryBadges = ({ template }: Props) => {
    return (
        <div className="flex flex-wrap mt-2 gap-x-1">
            {template?.has_pain_scores && <ui.Badge color="pink">Pain Scores</ui.Badge>}
            {template?.has_blood_sugar && <ui.Badge color="indigo">Blood Sugar</ui.Badge>}
            {template?.has_discharge && <ui.Badge color="teal">Discharge</ui.Badge>}
            {template?.has_mood_scores && <ui.Badge color="fuscia">Mood Scores</ui.Badge>}
            {template?.has_photos && <ui.Badge color="sky">Photos</ui.Badge>}
            {template?.has_foot_photos && <ui.Badge color="emerald">Foot Photos</ui.Badge>}
        </div>
    );
};

export default DiaryBadges;
