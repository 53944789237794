import { components as c } from '@/components';
import AuthWrap from '@/features/auth/components/AuthWrap';
import { createFileRoute } from '@tanstack/react-router';
import ForgotPasswordForm from '../../features/auth/components/ForgotPasswordForm';

const ForgotPassword = () => {
	return (
		<AuthWrap>
			<c.cc.Title words="Forgot Password" />
			<ForgotPasswordForm />
		</AuthWrap>
	);
};

export const Route = createFileRoute('/_auth/forgot-password')({
	component: ForgotPassword,
});
