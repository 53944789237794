import ErrorPage from '@/components/pages/ErrorPage';
import { selectProfileByIdQueryOptions } from '@/features/profile/api/selectProfile';
import { hooks as h } from '@/hooks';
import { Outlet, createFileRoute } from '@tanstack/react-router';

const Profile = () => {
	h.useNav([{ route: { to: '/profile' }, label: 'Profile' }]);

	return (
		<section className="lg-width">
			<Outlet />
		</section>
	);
};

export const Route = createFileRoute('/_app/profile')({
	loader: async (opts) => {
		opts.context?.queryClient.ensureQueryData(selectProfileByIdQueryOptions(opts.context.auth.id));
	},
	component: Profile,
	errorComponent: ({ error, reset }) => <ErrorPage title="Profile Error" error={error} reset={reset} />,
});
