import { useQueryClient } from '@tanstack/react-query';
import { createGetDiaryKey } from './getDiaryById';

const useInvalidateDiaries = () => {
	const queryClient = useQueryClient();
	const invalidateDiaries = (diaryId?: string) => {
		queryClient.invalidateQueries({ queryKey: ['diaries'] });
		diaryId && queryClient.invalidateQueries({ queryKey: createGetDiaryKey(diaryId) });
	};
	return invalidateDiaries;
};

export default useInvalidateDiaries;
