import { ClipboardCheck, Clock, DollarSign, HandIcon, HeartHandshake, LineChart, MedalIcon, Microscope, Smile } from 'lucide-react';

export const whyContent = [
    {
        name: 'patients',
        points: [
            {
                icon: <HeartHandshake />,
                text: <p>Enable your clinicians to look after you even when you leave hospital.</p>,
            },
            {
                icon: <Clock />,
                text: <p>Save your time by only seeing the doctor when you need to.</p>,
            },
            {
                icon: <LineChart />,
                text: <p>Track your care to know what helps you feel better.</p>,
            },
        ],
    },
    {
        name: 'clinicians',
        points: [
            {
                icon: <Smile />,
                text: <p>Better care means happier, healthier patients.</p>,
            },
            {
                icon: <HandIcon />,
                text: <p>Fewer unnecessary appointments means shorter waiting times.</p>,
            },
            {
                icon: <ClipboardCheck />,
                text: <p>Facilitate patient-collected data for improved research and accelerated audits.</p>,
            },
        ],
    },
    {
        name: 'organisations',
        points: [
            {
                icon: <MedalIcon />,
                text: <p>Gather empirical evidence to validate your product.</p>,
            },
            {
                icon: <DollarSign />,
                text: <p>Reduce per patient expenditure by avoiding unnecessary follow-ups.</p>,
            },
            {
                icon: <Microscope />,
                text: <p>Gather data to make discoveries which improve healthcare pathways.</p>,
            },
        ],
    },
];
