import { ui } from '../../../components/ui';
import { changeFont } from '../../../services/changeFont';

const SelectFont = () => {
    return (
        <ui.Select onValueChange={(val) => changeFont(val)}>
            <ui.SelectTrigger>
                <ui.SelectValue placeholder="Select a font" />
            </ui.SelectTrigger>
            <ui.SelectContent>
                <ui.SelectGroup>
                    <ui.SelectLabel>Fruits</ui.SelectLabel>
                    <ui.SelectItem className="font-open" value="Open Sans, sans-serif">
                        Open Sans
                    </ui.SelectItem>
                    <ui.SelectItem className="font-inter" value="Inter, sans-serif">
                        Inter
                    </ui.SelectItem>
                    <ui.SelectItem className="font-inclusive" value="Inclusive Sans, sans-serif">
                        Inclusive Sans
                    </ui.SelectItem>
                    <ui.SelectItem className="font-georgia" value="Georgia, serif">
                        Georgia
                    </ui.SelectItem>
                    <ui.SelectItem className="font-times-new-roman" value="Times New Roman, serif">
                        Times New Roman
                    </ui.SelectItem>
                </ui.SelectGroup>
            </ui.SelectContent>
        </ui.Select>
    );
};

export default SelectFont;
