import { ui } from '@/components/ui';
import { Route as NewRoute } from '@/routes/_app/diary/$diaryId/home/new';
import { Link } from '@tanstack/react-router';

import type { ExtendedDiaryViewT } from '../../../types/database/diaries';

interface Props {
	diary: ExtendedDiaryViewT;
}
const NoHistory = ({ diary }: Props) => {
	return (
		<>
			{diary.trackers_view.flatMap((tv) => tv.entries_view).length === 0 && (
				<ui.Card className="mb-4">
					<ui.CardHeader>
						<ui.CardTitle>Not Entries Yet</ui.CardTitle>
						<ui.CardDescription>To view history please add an entry.</ui.CardDescription>
					</ui.CardHeader>
					<ui.CardFooter>
						<ui.Button asChild>
							<Link to={NewRoute.to} params={(prev) => ({ ...prev })}>
								{diary.trackers_view.filter((tv) => tv.end_date === null).length > 0 ? 'Add Entry' : 'Add Form'}
							</Link>
						</ui.Button>
					</ui.CardFooter>
				</ui.Card>
			)}
		</>
	);
};

export default NoHistory;
