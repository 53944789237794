import { supabaseClient } from '@/lib/supabase';
import type { Injection } from '@/types/database/events';
import type { UuidT } from '@/types/zod/auth';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';

import { createGetDiaryKey } from '../../diary/api/getDiaryById';
import useInvalidateDiaries from '../../diary/api/invalidateDiaries';

export async function upsertInjection(injection: Injection['Insert']) {
	const { data, error } = await supabaseClient.from('injections').upsert(injection).select().single();
	if (error) throw error;
	return data;
}

interface InjectionMutation {
	diaryId: UuidT;
}

export const useInjectionMutation = ({ diaryId }: InjectionMutation) => {
	const invalidateDiaries = useInvalidateDiaries();
	return useMutation({
		mutationKey: ['injection'],
		mutationFn: async (data: Injection['Insert']) => {
			return await upsertInjection(data);
		},
		onError: (error) => {
			toast.error(error.message);
		},
		onSuccess: (mutation) => {
			toast.success('Diary injection saved');
			// The reason to go for a new UUID is that on this page, old injections can be seen and edited

			if (mutation?.onSuccess) {
				mutation.onSuccess();
			}
		},
		onSettled: () => {
			invalidateDiaries(diaryId);
		},
	});
};
