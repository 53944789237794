import { commonComponents as cc } from '@/components/common';
import { ui } from '@/components/ui';
import { ExtendedInjectionT, InjectionDetailViewT } from '@/types/database/trackers';
import { Link } from '@tanstack/react-router';
import { Crosshair, TestTube2 } from 'lucide-react';

interface Props {
	injection: ExtendedInjectionT;
	injectionDetail: InjectionDetailViewT;
	index: number;
}
const InjectionDetailCard = (props: Props) => {
	return (
		<ui.Card>
			<ui.CardHeader className="flex flex-row items-center justify-between">
				<h3>Injection Detail {props.index}</h3>
				<cc.EditButton>
					<Link
						to="/diary/$diaryId/tracker/$trackerId/injection/$injectionId/injection-detail/$injectionDetailId"
						params={(prev) => ({
							...prev,
							trackerId: props.injection.tracker_id,
							injectionId: props.injection.injection_id,
							injectionDetailId: props.injectionDetail.injection_detail_id,
						})}
					>
						Edit
					</Link>
				</cc.EditButton>
			</ui.CardHeader>
			<ui.CardContent>
				<cc.IconDetail icon={<Crosshair size={24} />} words={props.injectionDetail.injection_location} />
				<cc.IconDetail icon={<TestTube2 size={24} />} words={props.injectionDetail.injection_content} />
			</ui.CardContent>
		</ui.Card>
	);
};

export default InjectionDetailCard;
