import { ui } from '@/components/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import { type SubmitHandler, useForm } from 'react-hook-form';

import { formComponents as fc } from '../../../components/form';
import type { ScheduleT } from '../../../types/zod/timings';
import { processScheduleOptions } from '../../schedules/services/processScheduleOptions';
import { useTrackerMutation } from '../api/upsertTracker';
import { type TrackerSchemaT, TrackerSchemaZ } from '../types/trackerSchema';
import TrackerTypeCheckboxes from './TrackerTypeCheckboxes';
import UsersCombobox from './UsersCombobox';

interface Props {
	diaryId: string;
	trackerId: string;
	schedules: ScheduleT[];
}

const TrackerForm = (props: Props) => {
	const upsertTracker = useTrackerMutation({
		diaryId: props.diaryId,
	});

	const form = useForm<TrackerSchemaT>({
		resolver: zodResolver(TrackerSchemaZ),
		defaultValues: {
			diary_id: props.diaryId,
			tracker_id: props.trackerId,
		},
	});

	const onSubmit: SubmitHandler<TrackerSchemaT> = async (formData) => {
		upsertTracker.mutate(formData);
	};

	return (
		<ui.Form {...form}>
			<form onSubmit={form.handleSubmit(onSubmit)} className="form">
				<ui.FormField
					control={form.control}
					name="diary_id"
					render={() => (
						<ui.FormItem className="space-y-3">
							<ui.FormMessage />
						</ui.FormItem>
					)}
				/>
				<ui.FormField
					control={form.control}
					name="tracker_id"
					render={() => (
						<ui.FormItem className="space-y-3">
							<ui.FormMessage />
						</ui.FormItem>
					)}
				/>
				<fc.Date
					control={form.control}
					label="Start Date/Intervention Date"
					name="start_date"
					description="When will the patient have the surgery/injection or begin the medication."
				/>
				<fc.Select
					label="Schedule"
					control={form.control}
					name="schedule_id"
					options={processScheduleOptions(props.schedules)}
				/>
				<UsersCombobox attribute="contact_id" form={form} />
				<TrackerTypeCheckboxes />
				<ui.FormField
					control={form.control}
					name="follow_up_method"
					render={({ field }) => (
						<ui.FormItem className="space-y-3">
							<ui.FormControl>
								<ui.RadioGroup
									onValueChange={field.onChange}
									defaultValue={field.value}
									className="flex flex-col space-y-1"
								>
									<ui.FormLabel>Follow Up Method</ui.FormLabel>
									<div className="flex flex-col gap-y-2 border-slate-300 bg-slate-50 p-4 border rounded-md">
										<ui.FormItem className="flex items-center space-x-3 space-y-0">
											<ui.FormControl>
												<ui.RadioGroupItem value="pifu" />
											</ui.FormControl>
											<ui.FormLabel className="font-normal">Patient Initiated Follow Up (PIFU)</ui.FormLabel>
										</ui.FormItem>
										<ui.FormItem className="flex items-center space-x-3 space-y-0">
											<ui.FormControl>
												<ui.RadioGroupItem value="routine" />
											</ui.FormControl>
											<ui.FormLabel className="font-normal">Scheduled Routine Follow Up</ui.FormLabel>
										</ui.FormItem>
										<ui.FormItem className="flex items-center space-x-3 space-y-0">
											<ui.FormControl>
												<ui.RadioGroupItem value="none" />
											</ui.FormControl>
											<ui.FormLabel className="font-normal">No Follow Up</ui.FormLabel>
										</ui.FormItem>
									</div>
								</ui.RadioGroup>
							</ui.FormControl>
							<ui.FormMessage />
						</ui.FormItem>
					)}
				/>
				<ui.Button
					onClick={() => {
						console.log(form.getValues());
					}}
					type="submit"
				>
					Next
				</ui.Button>
			</form>
		</ui.Form>
	);
};

export default TrackerForm;
