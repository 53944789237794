import { supabaseClient } from '@/lib/supabase';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';

import type { Diary } from '@/types/database/diaries';
import useInvalidateDiaries from './invalidateDiaries';

export const updateDiary = async (diary: Diary['Update']) => {
	const { data, error } = await supabaseClient
		.from('diaries')
		.update(diary)
		.eq('diary_id', diary.diary_id ?? '')
		.select()
		.single();
	if (error) throw error;
	return data;
};

export const useUpdateDiary = () => {
	const queryClient = useQueryClient();
	const invalidateDiaries = useInvalidateDiaries();
	return useMutation({
		mutationFn: async (data: Diary['Update']) => {
			return await updateDiary(data);
		},
		onError: (error) => {
			console.error('An error occurred:', error);
			toast.error(`${error.message}`);
		},
		onSuccess: (data: Diary['Row']) => {
			toast.success('Diary updated successfully');
			queryClient.setQueryData(['diary', { diaryId: data.diary_id }], data);
		},
		onSettled: (data) => {
			invalidateDiaries(data?.diary_id as string);
		},
	});
};
export default useUpdateDiary;
