import { supabaseClient } from '@/lib/supabase';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';
import { ui } from '../../../components/ui';
import { createProfileQueryKey } from '../../profile/api/selectProfile';
import type { ImpersonateSchemaT } from '../components/ImpersonateUserDialog';

const impersonate = async (formData: ImpersonateSchemaT) => {
	const { data, error } = await supabaseClient.functions.invoke('admin-impersonate', {
		body: { ...formData },
	});
	if (error) throw error;
	return data;
};

export const useImpersonateMutation = () => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationKey: ['impersonate'],
		mutationFn: (formData: ImpersonateSchemaT) => impersonate(formData),
		onError: () => {
			toast.error('Error impersonating user');
		},
		onSuccess: (data, mutation) => {
			toast.info('Magic Link Generated', {
				action: (
					<ui.Button size="sm" className="ml-auto" onClick={() => window.open(data.properties.action_link, '_blank')}>
						Open
					</ui.Button>
				),
			});
			mutation?.onSuccess?.();
		},
		onSettled: (data) => {
			// FIXME Invalidate didnt refetch so force with refetch
			queryClient.refetchQueries({ queryKey: ['user'] });
			queryClient.refetchQueries({ queryKey: createProfileQueryKey(data.user.id) });
		},
	});
};
