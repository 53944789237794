import React from 'react';

const usePrint = (readyToPrint: boolean) => {
	React.useEffect(() => {
		if (readyToPrint) {
			setTimeout(() => {
				window.print();
			}, 500);
		}
	}, [readyToPrint]);
};

export default usePrint;
