import { Control } from 'react-hook-form';

import { ui } from '../ui';

interface Props {
    control: Control;
}

const PIFU = (props: Props) => {
    return (
        <ui.FormField
            control={props.control}
            name="pifu"
            render={({ field }) => (
                <ui.FormItem className="p-4 bg-white border rounded-md border-slate-200 ">
                    <div className="flex flex-col items-start justify-start space-y-1">
                        <div className="flex items-center justify-center mb-2 gap-x-2">
                            <ui.FormControl>
                                <ui.Checkbox checked={field.value} onCheckedChange={field.onChange} />
                            </ui.FormControl>
                            <ui.FormLabel>Use PIFU Pathway</ui.FormLabel>
                        </div>
                        <ui.FormDescription className="text-sm leading-6">
                            If using{' '}
                            <ui.Button
                                asChild
                                variant="link"
                                className="inline-block p-0 m-0 text-sm font-normal h-fit"
                            >
                                <a
                                    target="_blank"
                                    href="https://www.england.nhs.uk/outpatient-transformation-programme/patient-initiated-follow-up-giving-patients-greater-control-over-their-hospital-follow-up-care/"
                                >
                                    Patient Initiated Follow Up (PIFU)
                                </a>
                            </ui.Button>{' '}
                            do not schedule a follow-up appointment. Instead, Patient Watch will alert you when the
                            patient may need to be seen. page.
                        </ui.FormDescription>
                    </div>
                </ui.FormItem>
            )}
        />
    );
};

export default PIFU;
