import { ActionIcon } from '@/assets/actions/ActionIcon';
import { buttonVariants } from '@/components/ui/button';
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { cn } from '@/lib/utils';
import { Route as DashboardDiaryRoute } from '@/routes/_app/dashboard/diary/$diaryId';
import { services as s } from '@/services';
import type { ActionKeyT } from '@/types/database/actions';
import { useNavigate } from '@tanstack/react-router';

interface ActionOptionT {
	action_key: ActionKeyT;
	action_name: string;
	email_end: string;
	internal_states: string[];
}

interface Props {
	actionOptions: ActionOptionT[];
}

const style = 'flex flex-row gap-x-2 [&_svg]:w-[28px] [&_svg]:h-[28px]';

const ActionDropdown = (props: Props) => {
	const navigate = useNavigate({ from: DashboardDiaryRoute.fullPath });
	return (
		<DropdownMenu>
			<DropdownMenuTrigger data-testid="take-action-button" className={cn(buttonVariants({ size: 'sm' }))}>
				Take Action
			</DropdownMenuTrigger>
			<DropdownMenuContent className="min-w-sm" align="start">
				<DropdownMenuLabel>Take Action</DropdownMenuLabel>
				<DropdownMenuSeparator />
				{props.actionOptions?.map((action: ActionOptionT) => (
					<DropdownMenuItem
						key={action.action_key}
						className="ml-auto w-full"
						onClick={() => {
							navigate({
								to: '/dashboard/diary/$diaryId/action/$actionId',
								params: (prev) => ({ ...prev, actionId: s.uuidv4() }),
								search: (prev) => ({ ...prev, action: action }),
							});
						}}
					>
						<div className={cn(style, 'stroke-sm')}>
							{ActionIcon[action.action_key]}
							<p>{action.action_name}</p>
						</div>
					</DropdownMenuItem>
				))}
			</DropdownMenuContent>
		</DropdownMenu>
	);
};

export default ActionDropdown;
