import React from "react";
import { useAuth } from "../../../utils/auth";
import usePreferencesStore from "./usePreferencesStore";

const useInitializeKnock = () => {
  const auth = useAuth();

  const knockClient = usePreferencesStore((state) => state.knockClient);
  const setPreferences = usePreferencesStore((state) => state.setPreferences);
  //We load the current user's preferences from Knock, and set them to local preferences

  const initializeKnock = (id: string) => {
    try {
      knockClient.authenticate(id);

      async function fetchPreferences() {
        const newPreferences = await knockClient.user.getPreferences();
        setPreferences(newPreferences);
      }
      fetchPreferences();
    } catch (e) {
      console.error(e);
    }
  };
  React.useEffect(() => {
    initializeKnock(auth.id);
  }, [initializeKnock, auth.id]);
};

export default useInitializeKnock;
