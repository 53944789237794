import React from 'react';

const Triangle = () => {
    return (
        <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.43301 9.25C6.24056 9.58333 5.75944 9.58333 5.56699 9.25L1.23686 1.75C1.04441 1.41667 1.28497 1 1.66987 1L10.3301 1C10.715 1 10.9556 1.41667 10.7631 1.75L6.43301 9.25Z"
                stroke="black"
            />
        </svg>
    );
};

export default Triangle;
