import { toast } from 'sonner';

import { useAuth } from '../../../../../utils/auth';
import useInsertActionMutation from '../../interface/api/insertAction';

import type { StatefulDiaryT } from '@/types/database/diaries';
const useMarkRead = () => {
	const auth = useAuth();
	const insertAction = useInsertActionMutation();

	const markRead = (diary: StatefulDiaryT) => {
		if (diary.is_read) {
			toast.warning('Diary already read');
			return;
		}

		const action = diary?.allowed_actions?.find((a) => a?.action_key === 'MARK_READ');
		insertAction.mutate({
			diary_id: diary.diary_id,
			action_key: 'MARK_READ',
			body: action?.email_words as string,
			subject: 'Diary Reviewed',
			from_user_id: auth.id,
		});
	};
	return { markRead };
};
export default useMarkRead;
