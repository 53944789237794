import { UuidZ } from '@/types/zod/auth';
import { z } from 'zod';

import { SideZ } from '@/types/zod/common';

export const InjectionSchemaZ = z.object({
	event_id: UuidZ,
	injection_id: UuidZ,
	referring_clinician_id: z.string().uuid('Referring clinician is required'),
	injection_method_id: z.string().uuid('Injection method is required').nullable().optional(),
	injecting_clinician_id: z.union([z.string(), z.null()]).optional(),
});

export type InjectionSchemaT = z.infer<typeof InjectionSchemaZ>;

export const injectionDetailSchema = z.object({
	injection_detail_id: UuidZ,
	injection_id: UuidZ,
	side: SideZ,
	orthopaedic_area: z.string(),
	injection_site: z.string(),
	injection_subsite: z.string(),
	injection_substance: z.string(),
	dose: z.string(),
	unit: z.string(),
});

export type InjectionDetailSchemaType = z.infer<typeof injectionDetailSchema>;
