import type { StatefulDiaryT } from '@/types/database/diaries';

import MarkReadButtons from '../../read-unread/components/MarkReadButtons';
import ActionDropdown from './ActionDropdown';
import DownloadButton from './DownloadButton';
import MoreDropdown from './MoreDropdown';

interface Props {
	diary: StatefulDiaryT;
}
const InterfaceButtons = ({ diary }: Props) => {
	return (
		<div className="mt-2 flex items-center justify-between">
			<div className="flex flex-row gap-2">
				<ActionDropdown actionOptions={diary.allowed_actions} />
				<MoreDropdown diary={diary} />
			</div>
			<div className="flex items-center">
				<DownloadButton patientId={diary.patient_id} diaryId={diary.diary_id} />
				<MarkReadButtons diary={diary} />
			</div>
		</div>
	);
};

export default InterfaceButtons;
