import ProfileWrap from '@/features/profile/layouts/ProfileWrap';
import type { ExtendedProfileT } from '@/types/database/profiles';
import { useAuth } from '@/utils/auth';
import { createFileRoute } from '@tanstack/react-router';

import { selectProfileByIdQueryOptions } from '@/features/profile/api/selectProfile';
import NameForm from '@/features/profile/components/NameForm';
import OnboardingStepper from '../../features/onboarding/components/OnboardingStepper';
import { useNavigateToNextOnboardingRoute } from '../../features/onboarding/hooks/useNavigateToNextOnboardingRoute';

const Name = () => {
	const auth = useAuth();
	const { profile } = Route.useLoaderData();

	const nextPage = useNavigateToNextOnboardingRoute('/onboarding/name');

	return (
		<ProfileWrap title="Onboarding">
			<OnboardingStepper currentRoute={Route.to} />
			<NameForm
				userId={auth.id}
				title={profile?.title ?? ''}
				firstName={profile?.first_name ?? ''}
				lastName={profile?.last_name ?? ''}
				autoFocus={true}
				onSuccess={nextPage}
			/>
		</ProfileWrap>
	);
};
export const Route = createFileRoute('/onboarding/name')({
	component: Name,
	loader: async ({ context }) => {
		let profile: ExtendedProfileT | null = null;
		if (context?.auth?.id) {
			profile = await context?.queryClient.ensureQueryData(selectProfileByIdQueryOptions(context.auth.id));
		}
		return { profile };
	},
});

export default Name;
