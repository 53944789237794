import { ui } from '@/components/ui';
import { ChevronsUpDown, User2 } from 'lucide-react';
import _ from 'underscore';
import SelectStaffForm from './SelectStaffForm';

import type { StatefulDiaryT } from '@/types/database/diaries';
import { Link } from '@tanstack/react-router';
interface Props {
	diary: StatefulDiaryT;
}

const ShareDiaryDialog = ({ diary }: Props) => {
	return (
		<ui.DialogContent className="max-w-md">
			<ui.DialogHeader>
				<ui.DialogTitle>Share Diary</ui.DialogTitle>
			</ui.DialogHeader>

			<p className="text-sm dark:text-slate-200">
				Can't see the user?{' '}
				<span>
					<Link className="link" to="/invite">
						Invite them
					</Link>
				</span>{' '}
				then share the diary.
			</p>
			<hr className="" />

			<SelectStaffForm diaryId={diary.diary_id} />

			<ui.Collapsible defaultOpen={true}>
				<ui.CollapsibleTrigger className="flex flex-row w-full justify-between items-center text-md text-slate-700">
					Shared With
					<ui.Button tabIndex={-1} size="icon" variant="ghost" type="button" className="">
						<ChevronsUpDown />
					</ui.Button>
				</ui.CollapsibleTrigger>
				{_.unique(diary?.diary_access as any[])
					?.filter((da) => da.capacity === 'direct_access')
					?.map((user) => (
						<ui.CollapsibleContent key={user.user_id} className="flex gap-2 text-md text-slate-800 pl-2">
							<User2 />
							{user.name}
						</ui.CollapsibleContent>
					))}
			</ui.Collapsible>
		</ui.DialogContent>
	);
};

export default ShareDiaryDialog;
