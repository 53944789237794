import { supabaseClient } from '@/lib/supabase';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'sonner';

interface DoppioT {
	url: string;
}
export async function invokeDoppio(data: DoppioT) {
	try {
		const res = await supabaseClient.functions.invoke('services-doppio', {
			body: data,
		});
		if (res.data) {
			return res.data;
		}
	} catch (error) {
		console.error('Error printing to PDF:', error);
		throw error;
	}
}

export const doppioKey = () => ['doppio'];

export const useDoppioMutation = () => {
	return useMutation({
		mutationKey: doppioKey(),
		mutationFn: async (data: DoppioT) => await invokeDoppio(data),
		onSuccess: (data) => {
			toast.info('Download Success');
			window.open(data.documentUrl);
			return data;
		},
		onError: (error) => {
			toast.error(error.message);
		},
	});
};
