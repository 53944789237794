interface Props {
	words: string;
	description?: string;
	children?: React.ReactNode;
}

const Title = ({ words, description, children }: Props) => {
	return (
		<div className="flex flex-col mb-4 px-4 w-full">
			<h1 className="flex justify-center items-center m-auto w-10/12 font-bold text-3xl text-center dark:text-slate-50 leading-12">
				{words}
			</h1>
			{description && <p className="mt-4 text-center text-lg dark:text-slate-300">{description}</p>}
			{children}
		</div>
	);
};

export default Title;
