import { commonComponents as cc } from '@/components/common';

import useDeleteTrackerMutation from '../api/deleteTracker';

interface Props {
	trackerId: string;
	variant?: 'destructive' | 'secondary-destructive';
}

const DeleteTrackerButton = (props: Props) => {
	const deleteTracker = useDeleteTrackerMutation();
	return <cc.DeleteButton deleteFunction={() => deleteTracker.mutate(props.trackerId)} variant={props.variant} />;
};

export default DeleteTrackerButton;
