import { supabaseClient } from '@/lib/supabase';
import { queryOptions, useSuspenseQuery } from '@tanstack/react-query';
import { isUuid } from '../../../services/isUuid';
export async function selectOrganisationById(id?: string) {
	if (!id || !isUuid(id)) {
		return undefined;
	}
	const { data, error } = await supabaseClient
		.from('organisations')
		.select('organisation_id, organisation_name')
		.eq('organisation_id', id)
		.single();
	if (error) throw error;
	return data;
}

export const organisationByIdQueryOptions = (id?: string) => {
	return queryOptions({
		queryKey: ['organisation', { id: id || 'no-id' }],
		queryFn: async () => {
			if (!id || !isUuid(id)) {
				return null;
			}
			return selectOrganisationById(id);
		},
		enabled: true,
	});
};

function useSelectOrganisationById(id?: string) {
	return useSuspenseQuery(organisationByIdQueryOptions(id));
}

export default useSelectOrganisationById;
