import { ui } from '@/components/ui';
import { type TrackerAdminView, trackersQueryOptions } from '@/features/trackers/api/selectTrackers';
import TrackerAdminCard from '@/features/trackers/components/TrackerAdminCard';
import { createFileRoute } from '@tanstack/react-router';
import fuzzy from 'fuzzy';
import React from 'react';
import { z } from 'zod';

const TrackerTemplatesPage = () => {
	const { trackers } = Route.useLoaderData();

	const [patientEmail, setPatientEmail] = React.useState<string>();

	const [matches, setMatches] = React.useState<TrackerAdminView[]>(trackers);

	React.useEffect(() => {
		let filtered = trackers;
		const options = {
			pre: '<',
			post: '>',
			extract: (el) => String(el?.diary?.patient_info?.patient_email),
		};
		if (patientEmail) {
			const extracted = fuzzy.filter(patientEmail, trackers, options);
			filtered = extracted?.map((el) => el.original);
		}

		setMatches(filtered);
	}, [patientEmail, trackers]);

	return (
		<section>
			<section className="flex flex-row items-center mt-8 px-6">
				<ui.Input onChange={(e) => setPatientEmail(e.target.value)} type="email" placeholder="Patient Email" />
			</section>
			<ul className="flex lg:flex-row flex-col flex-wrap p-4">
				{matches?.map((t) => (
					<React.Fragment key={t.tracker_id}>
						<TrackerAdminCard t={t} />
					</React.Fragment>
				))}
			</ul>
		</section>
	);
};
const TrackersSearchZ = z.object({
	hasDiary: z.boolean().default(false),
});

export const Route = createFileRoute('/_app/admin/trackers')({
	component: TrackerTemplatesPage,
	validateSearch: (search) => TrackersSearchZ.parse(search),

	loader: async ({ context }) => {
		const trackers = await context?.queryClient.ensureQueryData(trackersQueryOptions());
		return { trackers };
	},
});
