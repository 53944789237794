import 'react-lazy-load-image-component/src/effects/blur.css';

import { icons } from '@/assets/icons';
import leftFoot from '@/assets/left-foot.png';
import rightFoot from '@/assets/right-foot.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';

interface Props {
    fullScreen: boolean;
    setShowDate: (arg0: boolean) => void;
    image: string;
    imageSrc: string;
    onClick?: () => void;
    placement: 'tr' | 'tl' | 'br' | 'bl';
    selectable?: boolean;
    selected?: boolean;
}

type roundedCornerType = {
    tr: string;
    tl: string;
    br: string;
    bl: string;
};
const roundedCorner: roundedCornerType = {
    tr: 'rounded-tr-xl',
    tl: 'rounded-tl-xl',
    br: 'rounded-br-xl',
    bl: 'rounded-bl-xl',
};

const LazyImage = ({ fullScreen, setShowDate, image, onClick, imageSrc, placement, selectable, selected }: Props) => {
    return (
        <div className="relative  flex flex-1">
            <LazyLoadImage
                alt={'Image'}
                effect="blur"
                src={image}
                placeholderSrc={placement === 'tl' || placement === 'bl' ? leftFoot : rightFoot}
                className={`
                ${
                    fullScreen
                        ? `${placement === 'bl' && ''} fixed top-0 h-full w-full  left-0 z-30 object-cover 
                 
                      `
                        : `${placement === 'bl' && ''} w-full h-full z-0 object-cover ml-0 shadow-sm ${
                              roundedCorner[placement as keyof roundedCornerType]
                          }
                    ${onClick && 'cursor-pointer'}
                    ${selectable && 'border-4 cursor-pointer'}
                    ${
                        selectable && selected
                            ? 'border-indigo-600 opacity-50 ring-indigo-600 bg-indigo-600'
                            : ' border-slate-200'
                    }`
                }
                ${placement === 'bl' && ''}
                ${placement === 'br' && ''}
                `}
                onClick={onClick}
                afterLoad={() => {
                    setShowDate(true);
                }}
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = rightFoot;
                }}
            />
            {selectable && selected && (
                <div
                    onClick={onClick}
                    className="absolute top-5 right-5 h-[30px] w-[30px] flex items-center justify-center"
                >
                    <icons.BlueRoundTick />
                </div>
            )}
            {selectable && !selected && (
                <div
                    onClick={onClick}
                    className="absolute top-5 right-5 h-[30px] w-[30px] flex items-center justify-center rounded-full border-4 border-slate-200"
                ></div>
            )}
        </div>
    );
};

export default LazyImage;
