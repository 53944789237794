import React from 'react';

const Edit = () => {
    return (
        <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="21" cy="21" r="21" fill="#F24E1E" />
            <path
                d="M11.7419 25.4029L10.3962 31.2871C10.2261 32.0308 10.9178 32.6833 11.6503 32.4702L17.2166 30.8508C17.3782 30.8038 17.5253 30.7167 17.6444 30.5977L29.1653 19.0768C29.5558 18.6862 29.5558 18.0531 29.1653 17.6626L24.9227 13.4199C24.5321 13.0294 23.899 13.0294 23.5084 13.4199L12.0096 24.9187C11.8766 25.0517 11.7838 25.2195 11.7419 25.4029Z"
                fill="white"
            />
            <rect
                x="31.22"
                y="17.0221"
                width="8"
                height="3.34493"
                rx="1"
                transform="rotate(-135 31.22 17.0221)"
                fill="white"
            />
        </svg>
    );
};

export default Edit;
