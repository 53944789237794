import { supabaseClient } from '@/lib/supabase';
import { queryOptions, useQuery } from '@tanstack/react-query';

export async function selectSurgeryOptions() {
	const { data, error } = await supabaseClient.from('surgery_options').select();
	if (error) throw error;
	return data;
}

export const surgeryOptionsQueryKey = ['surgery_options'];

export const selectSurgeryOptionsQueryOptions = () => {
	return queryOptions({
		queryKey: surgeryOptionsQueryKey,
		queryFn: () => selectSurgeryOptions(),
	});
};

export function useSelectSurgeryOptions() {
	const surgeryoptionsQuery = useQuery(selectSurgeryOptionsQueryOptions());
	return surgeryoptionsQuery;
}

export function useSurgeryOptions() {
	const { data } = useSelectSurgeryOptions();
	return data?.map((s) => ({ label: s.surgery_name, value: s.surgery_option_id }));
}
