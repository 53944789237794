import type { StateKeyT } from "@/types/database/diaries";

import { stateIcons as si } from "./state-icons";

interface StateIconT {
  icon: React.ReactNode;
  states: StateKeyT[];
}

export const stateIcons: StateIconT[] = [
  {
    icon: <si.Flag color="red" />,
    states: ["NEVER_WORKED", "HIGH", "LOW"],
  },
  {
    icon: <si.Flag color="amber" />,
    states: ["PAIN_SPIKE"],
  },
  {
    icon: <si.Flag color="green" />,
    states: ["READY_TO_REVIEW", "PAIN_REDUCED"],
  },
  {
    icon: <si.DiaryTick />,
    states: ["NORMAL", "ACTIVE"],
  },
  {
    icon: <si.Flag color="black" />,
    states: ["DISCARDED"],
  },
  {
    icon: <si.FinishFlag />,
    states: ["COMPLETED"],
  },
  {
    icon: <si.DiaryTick />,
    states: ["NORMAL", "ACTIVE"],
  },
  {
    icon: <si.MIA />,
    states: ["LATE_TO_LOG", "LATE_TO_START"],
  },
  {
    icon: <si.EmailConfirmed />,
    states: ["PENDING"],
  },
  {
    icon: <si.ClipboardWarning />,
    states: ["EMPTY_DIARY"],
  },
];

export const findStateIcon = (state: StateKeyT) => {
  const icon = stateIcons.find((i) => i.states.includes(state))?.icon;
  return icon ? icon : <si.Empty />;
};
