import { Link } from '@tanstack/react-router';

import MainIcon from '../../navigation/components/MainIcon';
import { navigationContent } from '../../navigation/website/navigation-content';
import Section from './Section';

import dayjs from 'dayjs';
import type { Auth } from '../../../utils/auth';
import type { NavigationItem, Page } from '../../navigation/website/navigation-content';
interface Props {
	auth: Auth;
}

const Footer = ({ auth }: Props) => {
	return (
		<footer>
			<Section className="flex flex-col justify-center items-enter !border-y-0 mx-auto lg:mb-4 lg:pb-4 text-center x-4">
				<div className="flex md:flex-row flex-col gap-y-16 md:gap-x-16 md:gap-y-0 w-full">
					<div className="flex flex-col md:justify-start items-center md:items-start min-w-[250px] text-center">
						<MainIcon showText={true} />
						{/* <div className="flex gap-x-1 my-4">
                            {socials.map((social) => {
                                return (
                                    <Link
                                        to={social.to}
                                        target="_blank"
                                        className={cn(
                                            buttonVariants({ variant: 'ghost', className: 'text-white px-2' }),
                                        )}
                                    >
                                        {social.icon}
                                    </Link>
                                );
                            })}
                        </div> */}
					</div>
					<div className="flex md:flex-row flex-col flex-wrap justify-between md:pr-2 w-full">
						{navigationContent
							?.filter((list: NavigationItem) => !list.draft)
							?.map((title) => {
								return (
									<div
										key={title.name}
										className="flex-col md:justify-start md:items-start mb-8 md:w-1/4 flextext-lg text-center md:text-left"
									>
										{/* Use md:w-1/4 for medium screens, adjust the fraction based on your layout */}
										<h4 className="mb-2 font-medium text-cyan-600 text-lg dark:text-cyan-500">
											<strong>{title.name}</strong>
										</h4>
										<ul className="flex flex-col gap-y-2 text-md">
											{title?.pages?.map((page: Page) => {
												return (
													<li className="text-slate-800 dark:text-slate-200" key={page.name}>
														{page?.external ? (
															<a target="_blank" rel="noopener noreferrer" href={page.href}>
																{page.name}
															</a>
														) : (
															<Link to={page.href}>{page.name}</Link>
														)}
													</li>
												);
											})}
										</ul>
									</div>
								);
							})}
					</div>
				</div>
			</Section>
			<Section className="lg:my-0 lg:py-0 h-12">
				<p className="mx-auto md:mx-0">© Patient Watch Ltd {dayjs().get('year')}</p>
			</Section>
		</footer>
	);
};

export default Footer;
