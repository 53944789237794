import { supabaseClient } from '@/lib/supabase';
import { useAuth } from '@/utils/auth';
import { queryOptions, useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';

export async function getAuth() {
	const { data, error } = await supabaseClient.auth.getUser();
	if (error) throw error;
	return data.user;
}

export const authQueryOptions = () => {
	return queryOptions({
		queryKey: ['user'],
		queryFn: () => getAuth(),
		retry: 3,
		staleTime: 3000,
	});
};

function useAuthQuery() {
	const query = useQuery(authQueryOptions());
	const auth = useAuth();

	const { data: user } = query;

	useEffect(() => {
		user?.id ? auth.login(user?.id) : auth.logout();
	}, [user, auth.login, auth.logout]);

	return query;
}

export function useGetAuth() {
	const auth = useAuth();
	const { data: user } = useAuthQuery();
	user?.id ? auth.login(user?.id) : auth.logout();
	user?.id ? console.log('login') : console.log('logout');
	return user;
}

export default useAuthQuery;
