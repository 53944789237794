import React from 'react';

const Question = () => {
    return (
        <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="21.5" cy="21.5" r="17.5" fill="white" />
            <path
                d="M21 0C9.40313 0 0 9.40313 0 21C0 32.5969 9.40313 42 21 42C32.5969 42 42 32.5969 42 21C42 9.40313 32.5969 0 21 0ZM21 33.1875C19.9641 33.1875 19.125 32.3484 19.125 31.3125C19.125 30.2766 19.9641 29.4375 21 29.4375C22.0359 29.4375 22.875 30.2766 22.875 31.3125C22.875 32.3484 22.0359 33.1875 21 33.1875ZM23.9484 22.8984C23.5239 23.0624 23.1587 23.3504 22.9003 23.725C22.6419 24.0996 22.5024 24.5434 22.5 24.9984V26.0625C22.5 26.2688 22.3313 26.4375 22.125 26.4375H19.875C19.6687 26.4375 19.5 26.2688 19.5 26.0625V25.0547C19.5 23.9719 19.8141 22.9031 20.4328 22.0125C21.0375 21.1406 21.8812 20.475 22.875 20.0953C24.4688 19.4813 25.5 18.1453 25.5 16.6875C25.5 14.6203 23.4797 12.9375 21 12.9375C18.5203 12.9375 16.5 14.6203 16.5 16.6875V17.0438C16.5 17.25 16.3313 17.4188 16.125 17.4188H13.875C13.6687 17.4188 13.5 17.25 13.5 17.0438V16.6875C13.5 14.8453 14.3062 13.125 15.7687 11.8453C17.175 10.6125 19.0312 9.9375 21 9.9375C22.9688 9.9375 24.825 10.6172 26.2312 11.8453C27.6937 13.125 28.5 14.8453 28.5 16.6875C28.5 19.3969 26.7141 21.8344 23.9484 22.8984Z"
                fill="#403FAB"
            />
        </svg>
    );
};

export default Question;
