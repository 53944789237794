import { useDubPatientReport } from "@/features/previews/hooks/useDubPatientReportUrl";

interface EmailBuilderParams {
    toName: string;
    start: string;
    end: string;
    fromName: string;
    diaryId?: string;
    patientId?: string;
}

export function useEmailBuilder(data: EmailBuilderParams) {
    
    const dubUrl = useDubPatientReport({
        patientId: data.patientId,
        diaryId: data.diaryId,
    });

    return `Dear ${data.toName},

${data.start ?? ""}

${data.end ?? ""}

Kind regards,
${data.fromName}

${
        dubUrl?.data?.shortLink
            ? `To see a report of the progress, please visit: ${dubUrl.data.shortLink}`
            : ""
    }`;
}
