// TODO - Add a 'notifiy user' checkbox which sends the user an email
// through a webhook to let them know the diary has been shared with them

import { components as c } from '@/components';
import { useAuth } from '@/utils/auth';
import { z } from '@/utils/form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useInsertDiaryAccess } from '../api/insertDiaryAccess.ts';
import useSelectStaffOptions from '../hooks/useSelectStaffOptions';

const ShareSelectFormZ = z.object({
	diary_id: z.string().uuid(),
	user_id: z.string().uuid(),
});

interface Props {
	diaryId: string;
}

const SelectStaffForm = ({ diaryId }: Props) => {
	const auth = useAuth();

	const form = useForm<z.infer<typeof ShareSelectFormZ>>({
		resolver: zodResolver(ShareSelectFormZ),
		defaultValues: {
			diary_id: diaryId,
		},
	});

	const insertDiaryAccess = useInsertDiaryAccess();

	function onSubmit(data: z.infer<typeof ShareSelectFormZ>) {
		insertDiaryAccess.mutate(data);
	}

	const staffOptions = useSelectStaffOptions();

	return (
		<c.ui.Form {...form}>
			<form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6 w-full">
				<c.fc.ComboBox label="Share With" name="user_id" disabled={auth.roles?.isPatientOnly} options={staffOptions} />
				<c.ui.Button onClick={() => console.log(form.getValues())} size="lg" type="submit">
					Next
				</c.ui.Button>
			</form>
		</c.ui.Form>
	);
};

export default SelectStaffForm;
