import { ui } from '@/components/ui';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { useAuth } from '@/utils/auth';
import { Link } from '@tanstack/react-router';

import type { ExtendedProfileT } from '@/types/database/profiles';
import React from 'react';
import { useProfileQuery } from '../../profile/api/selectProfile';
import { filterAppNavigation } from '../services/filterAppNavigation';
import type { PageT } from './navigation-content';
import { navigationContent } from './navigation-content';

const MobileNavigation = () => {
	const auth = useAuth();
	const { data: profile } = useProfileQuery();

	return (
		<>
			<ui.SheetHeader className="mt-8">
				<ui.SheetTitle className="px-3 font-medium text-left text-slate-700">Patient Watch</ui.SheetTitle>
			</ui.SheetHeader>
			<div className="flex flex-col justify-center items-center gap-y-2 my-4 w-full">
				{filterAppNavigation(navigationContent, auth.roles?.roles, profile as ExtendedProfileT)?.map((page: PageT) => (
					<React.Fragment key={page.href}>
						<ui.SheetClose asChild>
							<Button
								data-testid={`${page.href}-button`}
								asChild
								className="flex justify-start items-center gap-x-3 mx-auto px-3 py-8 w-full font-medium text-2xl"
								variant={page.variant}
							>
								<Link className="[&_svg]:w-9 [&_svg]:h-9" to={page.href}>
									{page.icon}
									{page.name}
								</Link>
							</Button>
						</ui.SheetClose>
					</React.Fragment>
				))}
				<ui.SheetFooter className="mt-4">
					<ui.SheetDescription className="text-left">
						Confused or need help?{' '}
						<Link
							target="_blank"
							className={cn('inline-block text-indigo-600 underline underline-offset-4')}
							to="/support"
						>
							Contact support
						</Link>
						.
					</ui.SheetDescription>
				</ui.SheetFooter>
			</div>
		</>
	);
};

export default MobileNavigation;
