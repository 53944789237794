import { services as s } from '@/services';
import { ExtendedProfileT, RoleKeyT } from '@/types/database/profiles';

import { PageT } from '../app/navigation-content';

export const filterAppNavigation = (navigationContent: PageT[], roles: RoleKeyT[], profile: ExtendedProfileT) => {
	const filtered = navigationContent
		.filter((page) => s.hasCommonElements(page.roles, roles))
		.filter((page) => !(page?.super_user_only === true && profile?.is_super_user === false));
	return filtered;
};
