import Call from '@/components/common/Call';
import type { ExtendedTrackerViewT } from '@/types/database/trackers';
import { Link } from '@tanstack/react-router';
import { Hammer, Syringe } from 'lucide-react';

import { checkIfMissingManagement } from '../utils/checkIfMissingManagement';

interface Props {
	tracker: ExtendedTrackerViewT;
}

interface ManagementIconT {
	[key: string]: React.ReactNode;
}

const ManagementIcon: ManagementIconT = {
	injection: <Syringe strokeWidth={1.75} size={28} />,
	surgery: <Hammer strokeWidth={1.75} size={28} />,
};
const MissingTrackerManagements = ({ tracker }: Props) => {
	if (!tracker.management_types?.length) return <></>;
	return (
		<ul className="flex flex-col gap-y-2">
			{tracker.events_view?.management_types?.map((mt) => (
				<li className="flex" key={mt}>
					{checkIfMissingManagement(mt as string, tracker) && (
						<Call
							variant="warning"
							size="sm"
							icon={ManagementIcon[mt as keyof ManagementIconT]}
							words={`Missing ${mt} details`}
						>
							<Link
								to="/diary/$diaryId/tracker/$trackerId/edit"
								params={{ trackerId: tracker.tracker_id, diaryId: tracker.diary_id }}
								className="underline underline-offset-2"
							>
								Add Details
							</Link>
						</Call>
					)}
				</li>
			))}
		</ul>
	);
};

export default MissingTrackerManagements;
