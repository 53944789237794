import Section from "@/features/website/components/Section";
import { useState } from "react";
import { ErrorBoundary } from "react-error-boundary";

import AnimateInView from "../../../components/ui/animate-in-view.tsx";
import HeroContent from "../components/HeroContent.tsx";
import DiaryRecord from "./DiaryRecord.tsx";
import Tab from "./components/Tab.tsx";
import { exampleDiaries } from "./content/exampleDiaries.ts";
import Flag from "@/assets/states/Flag.tsx";

const DiaryDemo = () => {
  const [diaryIndex, setDiaryIndex] = useState<number>(0);

  const getDiary = (diaryIndex) => {
    try {
      return exampleDiaries[diaryIndex];
    } catch (e) {
      console.error(e);
      return {};
    }
  };

  getDiary(diaryIndex);

  return (
    <ErrorBoundary
      fallback={
        <p className="justify-center items-center p-4  rounded-md text-lg text-slate-800 mx-auto flex w-full text-center">
          Diary Demo Error
        </p>
      }
    >
      <Section row={true}>
        <HeroContent
          caption="Track Symptoms"
          title="Only review patient diaries when necessary"
          description="Everyone knows clinician time is precious. Our algorithms only flag diaries that may require clinical review - leading to supercharged PIFU."
        />
        <AnimateInView>
          <div className="w-full">
            <div className="w-full">
              <div className="flex flex-wrap w-full mb-2">
                <Tab
                  active={diaryIndex === 0}
                  onClick={() => setDiaryIndex(0)}
                  icon={<Flag color="green" />}
                  words="Pain Reduced"
                />
                <Tab
                  active={diaryIndex === 1}
                  onClick={() => setDiaryIndex(1)}
                  icon={<Flag color="amber" />}
                  words="Pain Spike"
                />
                <Tab
                  active={diaryIndex === 2}
                  onClick={() => setDiaryIndex(2)}
                  icon={<Flag color="red" />}
                  words="Never Worked"
                />
              </div>
              <div className="w-full max-w-[600px] flex-1">
                <DiaryRecord diary={exampleDiaries[diaryIndex]} />
              </div>
            </div>
          </div>
        </AnimateInView>
      </Section>
    </ErrorBoundary>
  );
};

export default DiaryDemo;
