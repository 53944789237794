import { formComponents as fc } from '@/components/form';
import { ui } from '@/components/ui';
import useUserMutation from '@/features/profile/api/useUserMutation';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { type SubmitHandler, useForm } from 'react-hook-form';
import * as z from 'zod';

const formSchema = z.object({
	email: z.string().email(),
});

interface Props {
	userId: string;
	email: string | null;
}

const EmailForm = ({ userId, email }: Props) => {
	type FormSchemaType = z.infer<typeof formSchema>;

	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			email: email ?? '',
		},
	});

	useEffect(() => {
		form.setValue('email', email ?? '');
	}, [email]);

	const profileMutation = useUserMutation(userId);

	const onSubmit: SubmitHandler<FormSchemaType> = async (formData) => {
		await profileMutation.mutate(formData);
	};

	return (
		<ui.Form {...form}>
			<form onSubmit={form.handleSubmit(onSubmit)} className="form">
				<fc.Email />
				<fc.SaveButton />
			</form>
		</ui.Form>
	);
};

export default EmailForm;
