import { findStateIcon } from '@/assets/states/StateIcon';
import { ui } from '@/components/ui';
import { findLatestEntry } from '@/features/diary/services/flattenEntriesView';
import { cn } from '@/lib/utils';
import type { StatefulDiaryT } from '@/types/database/diaries';
import dayjs from 'dayjs';

interface Props {
	diary: StatefulDiaryT;
}

const StateCard = (props: Props) => {
	const state = props.diary.state;
	return (
		<ui.Card
			className={cn(
				['NEVER_WORKED', 'HIGH', 'LOW'].includes(props.diary.diary_status) ? 'border-red-200 bg-red-50' : '',
				['EMPTY_DIARY'].includes(props.diary.diary_status) ? 'border-amber-200 bg-amber-50' : '',
				['PAIN_REDUCED', 'READY_TO_REVIEW'].includes(props.diary.diary_status) ? 'border-green-200 bg-green-50' : '',
			)}
		>
			<ui.CardHeader className="flex flex-row justify-between items-start">
				<div className="flex flex-row gap-x-2 space-y-0 [&_svg]:w-[40px] [&_svg]:h-[40px]">
					{findStateIcon(state.state_key)}
					<h2 className="mt-0 w-fit font-medium text-slate-700 text-xl dark:text-slate-200">{state.name}</h2>
				</div>
			</ui.CardHeader>
			<ui.CardContent className="flex flex-col gap-2">
				<div className="flex flex-row justify-between items-center mt-0 w-full">
					<h3 className="text-md text-slate-900 dark:text-slate-100">{props.diary.diary_name}</h3>
					<p className="font-medium text-slate-600 text-sm dark:text-slate-300">
						{props.diary.start_date ? dayjs(props.diary.start_date).format('DD MMM YYYY') : 'No Start Date'} -{' '}
						{props.diary.end_date ? dayjs(props.diary.end_date).format('DD MMM YYYY') : 'Now'}
					</p>
				</div>

				{findLatestEntry(props.diary) && <p>Last entry {dayjs(findLatestEntry(props.diary)?.entry_time).fromNow()}</p>}
			</ui.CardContent>
		</ui.Card>
	);
};

export default StateCard;
