interface Props {
    active?: boolean;
    fill?: string;
    className?: string;
}

const Learn = ({ active, fill, className }: Props) => {
    return (
        <svg
            className={`w-full h-full p-1 ${className}`}
            width="48"
            height="42"
            viewBox="0 0 48 42"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            {active ? (
                <>
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M23.2061 0.212274C23.698 -0.0707581 24.3019 -0.0707581 24.7938 0.212274L47.1937 13.1011C47.6922 13.388 47.9999 13.9218 47.9999 14.5C47.9999 15.0781 47.6922 15.6119 47.1937 15.8988L24.7938 28.7876C24.3019 29.0707 23.698 29.0707 23.2061 28.7876L0.806175 15.8988C0.307657 15.6119 0 15.0781 0 14.5C0 13.9218 0.307657 13.388 0.806175 13.1011L23.2061 0.212274ZM4.82489 14.5L23.9999 25.5332L43.175 14.5L23.9999 3.4667L4.82489 14.5Z"
                        fill={fill ? fill : 'black'}
                    />
                    <path d="M23.7215 2L2 14.5L23.7215 27L46 14.5L23.7215 2Z" fill={fill ? fill : 'black'} />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M46.3999 13C47.2836 13 47.9999 13.7213 47.9999 14.6111V32.3333C47.9999 33.2231 47.2836 33.9444 46.3999 33.9444C45.5162 33.9444 44.7999 33.2231 44.7999 32.3333V14.6111C44.7999 13.7213 45.5162 13 46.3999 13ZM9.6 17.8333C10.4836 17.8333 11.2 18.5546 11.2 19.4444V31.3853L22.4 37.6507V27.5C22.4 26.6102 23.1163 25.8889 24 25.8889C24.8836 25.8889 25.6 26.6102 25.6 27.5V37.6507L36.7999 31.3853V19.4444C36.7999 18.5546 37.5163 17.8333 38.3999 17.8333C39.2836 17.8333 39.9999 18.5546 39.9999 19.4444V32.3333C39.9999 32.9184 39.6849 33.4575 39.1769 33.7416L24.8024 41.783C24.5695 41.9192 24.2993 41.9979 24.011 41.9999C24.0037 41.9999 23.9963 41.9999 23.9889 41.9999C23.7006 41.9979 23.4304 41.9192 23.1975 41.783L8.82297 33.7416C8.31503 33.4575 8 32.9184 8 32.3333V19.4444C8 18.5546 8.71634 17.8333 9.6 17.8333Z"
                        fill={fill ? fill : 'black'}
                    />
                </>
            ) : (
                <>
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M23.2061 0.712274C23.698 0.429242 24.3019 0.429242 24.7938 0.712274L47.1937 13.6011C47.6922 13.888 47.9999 14.4218 47.9999 15C47.9999 15.5781 47.6922 16.1119 47.1937 16.3988L24.7938 29.2876C24.3019 29.5707 23.698 29.5707 23.2061 29.2876L0.806175 16.3988C0.307657 16.1119 0 15.5781 0 15C0 14.4218 0.307657 13.888 0.806175 13.6011L23.2061 0.712274ZM4.82489 15L23.9999 26.0332L43.175 15L23.9999 3.9667L4.82489 15Z"
                        fill={fill ? fill : 'black'}
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M46.3999 13.5C47.2836 13.5 47.9999 14.2213 47.9999 15.1111V32.8333C47.9999 33.7231 47.2836 34.4444 46.3999 34.4444C45.5162 34.4444 44.7999 33.7231 44.7999 32.8333V15.1111C44.7999 14.2213 45.5162 13.5 46.3999 13.5ZM9.6 18.3333C10.4836 18.3333 11.2 19.0546 11.2 19.9444V31.8853L22.4 38.1507V28C22.4 27.1102 23.1163 26.3889 24 26.3889C24.8836 26.3889 25.6 27.1102 25.6 28V38.1507L36.7999 31.8853V19.9444C36.7999 19.0546 37.5163 18.3333 38.3999 18.3333C39.2836 18.3333 39.9999 19.0546 39.9999 19.9444V32.8333C39.9999 33.4184 39.6849 33.9575 39.1769 34.2416L24.8024 42.283C24.5695 42.4192 24.2993 42.4979 24.011 42.4999C24.0037 42.4999 23.9963 42.4999 23.9889 42.4999C23.7006 42.4979 23.4304 42.4192 23.1975 42.283L8.82297 34.2416C8.31503 33.9575 8 33.4184 8 32.8333V19.9444C8 19.0546 8.71634 18.3333 9.6 18.3333Z"
                        fill={fill ? fill : 'black'}
                    />
                </>
            )}
        </svg>
    );
};

export default Learn;
