import { Call } from '@/components/ui/call';
import AuthWrap from '@/features/auth/components/AuthWrap';
import UpdatePassword from '@/features/auth/components/UpdatePassword';
import { useAuth } from '@/utils/auth';
import { createFileRoute } from '@tanstack/react-router';
import { Link } from '@tanstack/react-router';

const ChangePasswordAuthPage = () => {
	const auth = useAuth();
	return (
		<AuthWrap>
			{!auth.isAuthenticated && (
				<Call variant="warning">
					<p>You are not logged-in, to reset your password please go to:</p>
					<Link className="link" to="/forgot-password">
						Forgot Password
					</Link>
				</Call>
			)}
			<UpdatePassword />
		</AuthWrap>
	);
};

export const Route = createFileRoute('/_auth/change-password')({
	component: ChangePasswordAuthPage,
});
