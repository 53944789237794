import EditRow from '../../../components/common/EditRow';
import SelectTitle from '../../../components/form/SelectTitle';
import UsersTemplates from '../../builder/components/FavouriteDiaries';
import OrganisationsRolesTable from '../../organisations/components/OrganisationsRolesTable';
import CompleteProfile from './CompleteProfile';
import FavouriteUsers from './FavouriteUsers';
import ProfileProgress from './ProfileProgress';
import SelectFont from './SelectFont';

export const profileComponents = {
	ProfileProgress,
	ProfileRow: EditRow,
	SelectTitle,
	OrganisationsRoles: OrganisationsRolesTable,
	UsersWatches: UsersTemplates,
	CompleteProfile,
	FavouriteUsers,
	SelectFont,
};
