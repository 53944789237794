import { supabaseClient } from '@/lib/supabase';
import { useMutation } from '@tanstack/react-query';
import { useNavigate, useSearch } from '@tanstack/react-router';
import { toast } from 'sonner';

interface Props {
	footLogId: string;
	userId: string;
}
const createFootEntry = async (footLogId: string, userId: string) => {
	return await supabaseClient
		.from('foot_entries')
		.insert({
			foot_log_id: footLogId,
			user_id: userId,
		})
		.select()
		.throwOnError()
		.single()
		.then((result) => result.data);
};

const useCreateFootEntryMutation = () => {
	const { imageLabels } = useSearch({ strict: false });
	const navigate = useNavigate();
	return useMutation({
		mutationKey: ['createFootEntry'],
		mutationFn: async (data: Props) => {
			return createFootEntry(data.footLogId, data.userId);
		},
		onError: (error) => {
			console.log(error);
			toast.error(error.message);
		},
		onSuccess: (data) => {
			toast.success('Successfully created image label');
			navigate({ search: { imageLabels: Number.parseInt(imageLabels) + 1 } });
			return data;
		},
	});
};
export { useCreateFootEntryMutation };
export default useCreateFootEntryMutation;
