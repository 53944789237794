import { supabaseClient } from '@/lib/supabase';
import { type UseQueryResult, queryOptions, useQuery } from '@tanstack/react-query';

import type { ExtendedProfileT } from '../../../types/database/profiles';
import { useAuth } from '../../../utils/auth';

export async function getProfile(id: string): Promise<ExtendedProfileT> {
	const { data, error } = await supabaseClient
		.from('profiles')
		.select('*, favourite_diary_templates(*, diary_templates(*)), users_organisations_roles_view(*)')
		.eq('user_id', id)
		.returns<ExtendedProfileT>()
		.single();
	if (error) throw error;
	return data;
}

export const createProfileQueryKey = (id: string) => ['profile', { user_id: id }];

export const selectProfileByIdQueryOptions = (userId: string) => {
	return queryOptions({
		queryKey: createProfileQueryKey(userId),
		queryFn: () => getProfile(userId),
	});
};

export function useProfileQuery(): UseQueryResult<ExtendedProfileT, Error> {
	const auth = useAuth();
	const profileQuery = useQuery(selectProfileByIdQueryOptions(auth.id));
	auth.setIsSuperUser(profileQuery?.data?.is_super_user ?? false);
	return profileQuery;
}

export function useProfileByIdQuery(userId: string) {
	const profileQuery = useQuery(selectProfileByIdQueryOptions(userId));
	return profileQuery;
}
