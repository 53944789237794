import useSupabase from '@/config/useSupabase';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';
import { toast } from 'sonner';

import { RoleSchemaType } from '../types/roleSchema';

const useSetRoleMutation = () => {
    const navigate = useNavigate();
    const client = useSupabase();
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async (formData: RoleSchemaType) => {
            const { error } = await client.auth.updateUser({
                data: { role: formData.role },
            });

            if (error) {
                throw error;
            }
        },
        onError: (error) => {
            console.log(error);
            toast.error('Failed to update role');
        },
        onSuccess: () => {
            toast.success('Sign-up successful');
            navigate({ to: '/profile' });
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ['user'] });
        },
    });
};

export default useSetRoleMutation;
