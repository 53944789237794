import { Button } from '../ui/button';

const SaveButton = () => {
	return (
		<Button size="lg" type="submit">
			Save
		</Button>
	);
};

export default SaveButton;
