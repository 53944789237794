import { v4 as uuidv4 } from "uuid";
import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";

export type PhotoT = {
  id: string;
  label: string;
  img: string;
  checked?: boolean;
};

export type State = {
  photos: PhotoT[];
  currentPhotoId: string | null;
};

export type Actions = {
  upsertPhoto: (photo: PhotoT) => void;
  addPhotos: (photos: Omit<PhotoT, "id">[]) => void;
  removePhoto: (id: string) => void;
  getPhoto: (id: string) => PhotoT | undefined;
  getPhotos: () => PhotoT[];
  resetPhoto: (id: string) => void;
  setCurrentPhotoId: (id: string | null) => void;
  getCurrentPhoto: () => PhotoT | undefined;
};

const initialState: State = {
  photos: [],
  currentPhotoId: null,
};

export const usePhotosStore = create<State & Actions>()(
  devtools(
    persist(
      (set, get) => ({
        ...initialState,
        getPhoto: (id: string) => {
          const photos = get().photos;
          return photos.find((photo) => photo.id === id);
        },
        getPhotos: () => {
          return get().photos;
        },
        upsertPhoto: (photo: PhotoT) =>
          set((state) => ({
            photos: state.photos.some((p) => p.id === photo.id)
              ? state.photos.map((p) => (p.id === photo.id ? photo : p))
              : [...state.photos, photo],
          })),
        addPhotos: (photos: Omit<PhotoT, "id">[]) =>
          set((state) => ({
            photos: [
              ...state.photos,
              ...photos.map((photo) => ({ ...photo, id: uuidv4() })),
            ],
          })),
        removePhoto: (id: string) =>
          set((state) => ({
            photos: state.photos.filter((photo) => photo.id !== id),
          })),
        resetPhoto: (id: string) => {
          const photos = get().photos;
          const photoToReset = photos.find((photo) => photo.id === id);
          if (photoToReset) {
            set((state) => ({
              photos: state.photos.map((photo) =>
                photo.id === id
                  ? { ...photoToReset, img: "", checked: false }
                  : photo
              ),
            }));
          }
        },
        setCurrentPhotoId: (id: string | null) =>
          set((state) => ({ ...state, currentPhotoId: id })),
        getCurrentPhoto: () => {
          const currentPhotoId = get().currentPhotoId;
          return currentPhotoId
            ? get().photos.find((photo) => photo.id === currentPhotoId)
            : undefined;
        },
      }),
      {
        name: "photos-store",
        storage: createJSONStorage(() => localStorage),
      },
    ),
  ),
);
