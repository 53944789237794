import { colors } from '@/assets/colors';
import {
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, Legend, PointElement, LineElement, Title, Tooltip);

interface Data {
    labels: string[];
    datasets: {
        label: string;
        data: number[];
        fill: boolean;
        borderColor: string;
        backgroundColor: string;
        tension: number;
    }[];
}
interface Props {
    data: Data;
}

const options = (data: any) => {
    return {
        responsive: true,
        maintainAspectRatio: false,
        spanGaps: true,
        plugins: {
            tooltip: {
                enabled: true,
                padding: 10,
                displayColors: false,
                backgroundColor: 'rgba(253,253,253,1)',
                titleColor: 'black',
                bodyColor: 'black',
                borderColor: 'rgba(190,190,190,1)',
                borderWidth: 1,
                titleFont: { size: 14 }, // Adjust this value as needed
                bodyFont: { size: 16 }, // Adjust this value as needed
            },
            legend: {
                display: data.datasets.length > 1 ? true : false,
                position: 'top' as const,
                labels: {
                    usePointStyle: true,
                    useBorderRadius: true,
                    color: colors['slate-800'],
                    padding: 10,
                    font: {
                        size: 15, // Add your font here to change the font of your legend label
                    },
                },
            },
        },

        scales: {
            x: {
                grid: {
                    display: true,
                },
                border: {
                    display: false, // This hides the axis lines but allows grid lines
                },

                ticks: {
                    padding: 2,
                    backdropPadding: 2,
                    color: colors['slate-600'],
                    font: {
                        // family: 'Inter', // Add your font here to change the font of your y axis
                        size: 14,
                        weight: 500,
                    },
                },
            },
            y: {
                type: 'linear',
                min: 0,
                max: 10,
                offset: true, // prevents top spots being cut off
                axis: {
                    display: false,
                },
                border: {
                    display: false, // This hides the axis lines but allows grid lines
                },
                grid: {
                    // display: false,
                    drawBorder: true, // <-- this removes y-axis line
                    lineWidth: 1,
                    color: colors['slate-200'],
                },
                ticks: {
                    // suggestedMin: 0,
                    // suggestedMax: 5,
                    padding: 16,
                    stepSize: 2,
                    // backdropPadding: 100,
                    backdropColor: 'rgba(255,255,255,1)',
                    color: colors['slate-600'],
                    font: {
                        size: 18,
                        weight: 400,
                    },
                    major: {
                        enable: true,
                    },
                },
            },
        },
    };
};

const LineChart = ({ data }: Props) => {
    return <Line className="w-full min-h-[250px]" options={options(data)} data={data} />;
};

export default LineChart;
