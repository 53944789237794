import { useMutation } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';
import { toast } from 'sonner';

import { supabaseClient } from '../../../lib/supabase';
import useAuthQuery from '../../auth/api/useAuthQuery';

import type { FootDiary } from '../../../types/supabaseTypes';

interface CreateFootDiary {
	name: string;
	patientId: string;
}
// Define the mutation function
const createFootDiary = async (name: string, patientId: string) => {
	return supabaseClient
		.from('foot_diaries')
		.insert({ foot_diary_name: name, patient_id: patientId })
		.select()
		.throwOnError()
		.single()
		.then((result) => result.data);
};

export const useCreateFootDiaryMutation = () => {
	const { data: auth } = useAuthQuery();
	const navigate = useNavigate();
	return useMutation({
		mutationFn: async (data: CreateFootDiary) => createFootDiary(data.name, data.patientId),
		onError: (error) => {
			console.error('An error occurred:', error);
			toast.error(`${error.message}`);
		},
		onSuccess: (data: FootDiary) => {
			toast.success('Foot diary created successfully');
			if (auth?.role === 'patient') {
				navigate({
					to: '/patient/diary/$diaryId/foot-watch/$view',
					params: { diaryId: data.foot_diary_id, view: 'new' },
				});
			} else if (auth?.role === 'clinician') {
				navigate({
					to: '/clinician/diary/$diaryId/foot-watch/$view',
					params: { diaryId: data.foot_diary_id, view: 'new' },
				});
			}
		},
	});
};
export default useCreateFootDiaryMutation;
