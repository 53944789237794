const PainExplainer = () => {
	return (
		<section className="flex flex-col gap-y-4 md-width text-sm text-slate-800">
			<h1 className="text-2xl">Why Tracking Your Pain Matters</h1>
			<p>
				Steroid injections are a common treatment, but how long they work can vary from person to person. This can make
				it difficult for doctors to schedule the right follow-up appointment to assess your progress.
			</p>
			<h2 className="text-lg">Better Decisions for Your Care</h2>
			<p>
				Steroid injections act in two ways: they aim to reduce pain, but also act as a diagnostic tool to pinpoint the
				source of the problem. Tracking your pain after an injection helps doctors understand:
			</p>
			<ul>
				<li>
					<strong>Did the injection work?</strong> Knowing your pain levels over time helps doctors see if the injection
					hit the right spot and eased your pain.
				</li>
				<li>
					<strong>How long did it work?</strong> This information helps doctors decide if you might need another
					injection or a different treatment plan altogether.
				</li>
			</ul>
			<h2 className="text-lg">Tailored Care, Just for You</h2>
			<p>
				By tracking your pain, you become an active partner in your care. The information you provide allows doctors to
				tailor your follow-up appointments to your specific needs, not a one-size-fits-all schedule.
			</p>
			<h2 className="text-lg">Empowering You</h2>
			<p>
				Tracking your pain gives you valuable insights into your own recovery. You'll have a clearer picture of how
				you're feeling, reducing anxiety and allowing you to take a more active role in managing your health.
			</p>
		</section>
	);
};

export default PainExplainer;
