import { useAuth } from '@/utils/auth';
import { useParams } from '@tanstack/react-router';
import { useMemo, useState } from 'react';

import { useDiaryByIdQuery } from '../../diary/api/getDiaryById';

const useIsMyDiary = () => {
	const { diaryId } = useParams({ strict: false });
	const { data: diary, isFetched } = useDiaryByIdQuery(diaryId);

	const [isMyDiary, setIsMyDiary] = useState<boolean | undefined>(undefined);

	const auth = useAuth();

	const checkIfIsMyDiary = () => {
		return auth?.id === diary?.patient_id;
	};

	useMemo(() => {
		const myDiary = checkIfIsMyDiary();
		setIsMyDiary(myDiary);
	}, [auth.id, diaryId, isFetched]);

	return { isMyDiary, checkIfIsMyDiary };
};

export default useIsMyDiary;
