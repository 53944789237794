import { supabaseClient } from '@/lib/supabase';
import { queryOptions, useQuery } from '@tanstack/react-query';
import type { ScheduleViewT } from '../../../types/database/forms';

export async function selectScheduleById(scheduleId: string): Promise<ScheduleViewT> {
	const { data, error } = await supabaseClient
		.from('schedules_view')
		.select('*')
		.eq('schedule_id', scheduleId)
		.returns<ScheduleViewT>()
		.single();
	if (error) throw error;
	return data;
}

export const scheduleQueryKey = (scheduleId: string) => ['schedule', scheduleId];

export const selectScheduleByIdQueryOptions = (scheduleId: string) => {
	return queryOptions({
		queryKey: scheduleQueryKey(scheduleId),
		queryFn: () => selectScheduleById(scheduleId),
	});
};

export const useScheduleByIdQuery = (scheduleId: string) => {
	const options = selectScheduleByIdQueryOptions(scheduleId);
	const query = useQuery(options);
	return query;
};
