import React from 'react';

const Padlock = () => {
    return (
        <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9 12.1C8.65749 12.0961 8.32198 12.1949 8.03862 12.383C7.75527 12.5712 7.53767 12.8397 7.41511 13.1525C7.29256 13.4652 7.27095 13.8072 7.35317 14.1323C7.43539 14.4574 7.6175 14.7501 7.875 14.971V16.5C7.875 16.7917 7.99353 17.0715 8.2045 17.2778C8.41548 17.4841 8.70163 17.6 9 17.6C9.29837 17.6 9.58452 17.4841 9.7955 17.2778C10.0065 17.0715 10.125 16.7917 10.125 16.5V14.971C10.3825 14.7501 10.5646 14.4574 10.6468 14.1323C10.7291 13.8072 10.7074 13.4652 10.5849 13.1525C10.4623 12.8397 10.2447 12.5712 9.96138 12.383C9.67802 12.1949 9.34251 12.0961 9 12.1ZM14.625 7.7V5.5C14.625 4.04131 14.0324 2.64236 12.9775 1.61091C11.9226 0.579463 10.4918 0 9 0C7.50816 0 6.07742 0.579463 5.02252 1.61091C3.96763 2.64236 3.375 4.04131 3.375 5.5V7.7C2.47989 7.7 1.62145 8.04768 0.988515 8.66655C0.355579 9.28542 0 10.1248 0 11V18.7C0 19.5752 0.355579 20.4146 0.988515 21.0335C1.62145 21.6523 2.47989 22 3.375 22H14.625C15.5201 22 16.3786 21.6523 17.0115 21.0335C17.6444 20.4146 18 19.5752 18 18.7V11C18 10.1248 17.6444 9.28542 17.0115 8.66655C16.3786 8.04768 15.5201 7.7 14.625 7.7ZM5.625 5.5C5.625 4.62479 5.98058 3.78542 6.61351 3.16655C7.24645 2.54768 8.10489 2.2 9 2.2C9.89511 2.2 10.7536 2.54768 11.3865 3.16655C12.0194 3.78542 12.375 4.62479 12.375 5.5V7.7H5.625V5.5ZM15.75 18.7C15.75 18.9917 15.6315 19.2715 15.4205 19.4778C15.2095 19.6841 14.9234 19.8 14.625 19.8H3.375C3.07663 19.8 2.79048 19.6841 2.5795 19.4778C2.36853 19.2715 2.25 18.9917 2.25 18.7V11C2.25 10.7083 2.36853 10.4285 2.5795 10.2222C2.79048 10.0159 3.07663 9.9 3.375 9.9H14.625C14.9234 9.9 15.2095 10.0159 15.4205 10.2222C15.6315 10.4285 15.75 10.7083 15.75 11V18.7Z"
                fill="black"
            />
        </svg>
    );
};

export default Padlock;
