import { useAuth } from '@/utils/auth';
import { useNavigate } from '@tanstack/react-router';
import { useEffect } from 'react';
import { toast } from 'sonner';

const useSkipToProfile = () => {
	const auth = useAuth();
	const navigate = useNavigate();

	useEffect(() => {
		if (auth.isAuthenticated) {
			toast.info('User Authenticated');
			navigate({ to: '/onboarding/organisation' });
		}
	}, [auth, navigate]);
};

export default useSkipToProfile;
