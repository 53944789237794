import React from 'react';

const Refresh = () => {
    return (
        <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="21" cy="21" r="21" fill="#403FAB" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.2104 6.63989C13.918 6.73133 14.4137 7.35082 14.3175 8.02357L13.9258 10.7633C16.0074 9.53311 18.4256 8.87742 20.9063 8.89403L21.4155 8.89744C28.5857 8.94544 34.3718 14.4851 34.3718 21.302V21.9951C34.3718 28.8461 28.5299 34.4 21.3236 34.4H20.6771C13.4708 34.4 7.62891 28.8461 7.62891 21.9951V21.1569C7.62891 20.478 8.20783 19.9276 8.92197 19.9276C9.63611 19.9276 10.215 20.478 10.215 21.1569V21.9951C10.215 27.4883 14.899 31.9413 20.6771 31.9413H21.3236C27.1016 31.9413 31.7856 27.4883 31.7856 21.9951V21.302C31.7856 15.8362 27.1464 11.3945 21.3973 11.356L20.8881 11.3526C19.0095 11.34 17.1758 11.8083 15.5718 12.6912L18.3347 12.9487C19.0454 13.015 19.5651 13.6164 19.4954 14.2921C19.4257 14.9678 18.7931 15.4619 18.0823 15.3956L12.0872 14.8369C11.7376 14.8043 11.4169 14.6378 11.1991 14.3758C10.9812 14.1138 10.8848 13.7788 10.9321 13.4478L11.755 7.69243C11.8512 7.01969 12.5028 6.54845 13.2104 6.63989Z"
                fill="white"
            />
        </svg>
    );
};

export default Refresh;
