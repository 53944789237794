import { supabaseClient } from '@/lib/supabase';
import type { TrackerEvent } from '@/types/database/events';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'sonner';

export async function insertTrackerEvent(event: TrackerEvent['Insert']) {
	const { data, error } = await supabaseClient
		.from('tracker_events')
		.insert(event)
		.select('*, trackers(*),events(*))')
		.single();
	if (error) throw error;
	return data;
}

export const useInsertTrackerEvent = () => {
	return useMutation({
		mutationKey: ['tracker-event'],
		mutationFn: (event: TrackerEvent['Insert']) => insertTrackerEvent(event),
		onError: (err) => {
			console.error(err.message);
			toast.error(`Error linking event and tracker. ${err.message}`);
		},
		onSuccess: () => {
			toast.success('Event and tracker linked');
		},
	});
};
