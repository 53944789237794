import React from 'react';

const Home = () => {
    return (
        <svg width="57" height="47" viewBox="0 0 57 47" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M28.3744 0C28.8688 0 29.3429 0.194015 29.6924 0.539363L52.3337 22.907C54.0951 24.6472 52.8476 27.6225 50.3566 27.6225H47.9466V43.4528H55.2047C56.1962 43.4528 57 44.2469 57 45.2264C57 46.2059 56.1962 47 55.2047 47H1.79528C0.803772 47 0 46.2059 0 45.2264C0 44.2469 0.803772 43.4528 1.79528 43.4528H8.80217V27.6225H6.39219C3.90119 27.6225 2.65369 24.6472 4.4151 22.907L27.0563 0.539363C27.4059 0.194015 27.88 0 28.3744 0ZM44.2186 43.2752H35.3647V29.9244C35.3647 28.9074 34.5301 28.0829 33.5006 28.0829H23.7145C22.6851 28.0829 21.8505 28.9074 21.8505 29.9244V43.2752H12.5302V25.781C12.5302 24.764 11.6957 23.9395 10.6662 23.9395H8.64226L28.3744 4.44577L48.1065 23.9395H46.0826C45.0531 23.9395 44.2186 24.764 44.2186 25.781V43.2752ZM31.6366 43.2752H25.5786V31.7659H31.6366V43.2752Z"
                fill="black"
            />
        </svg>
    );
};

export default Home;
