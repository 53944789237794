export type EntryT = {
  entry_time: string;
  entry_reason: string;
  entry_td: number;
  value: number;
  entry_td_friendly: string;
  entry_td_friendly_long: string;
};

export type DiaryT = {
  patient_hospital_number: string;
  doc_created_date: string;
  pat_created_date: string;
  site_name: string;
  area_name: string;
  inj_side: string;
  inj_date: string;
  patient_name: string;
  patient_email: string;
  doctor_name: string;
  doctor_gmc: string;
  doctor_sec_email: string;
  hospital_name: string;
  entry_is_overdue: boolean;
  grouped_state: string;
  current_state: string;
  internal_state: string;
  entries: EntryT[];
};

const exampleDiaries: DiaryT[] = [
  {
    patient_hospital_number: "12345678",
    doc_created_date: "2022-06-18T13:41:44.686Z",
    pat_created_date: "2022-06-18T13:41:44.686Z",
    site_name: "Flexor Hallucis Longus at posterior ankle",
    area_name: "Ankle",
    inj_side: "Left",
    inj_date: "2022-06-18T13:41:44.686Z",
    patient_name: "James Gonzalez",
    patient_email: "james.gonzalez@mail.com",
    doctor_name: "Amelia Campbell",
    doctor_gmc: "3721410",
    doctor_sec_email: "secretary@nhs.net",
    hospital_name: "St Mary's Hospital",
    entry_is_overdue: true,
    grouped_state: "ACTIVE",
    current_state: "PAIN_REDUCED",
    internal_state: "EMPTY_DIARY",
    entries: [
      {
        entry_time: "2022-06-06T13:41:44.686Z",
        entry_reason: "STD_JOURNEY",
        entry_td: 0,
        value: 9,
        entry_td_friendly: "Pre",
        entry_td_friendly_long: "Immediate Pre Injection",
      },
      {
        entry_time: "2022-06-08T13:41:44.686Z",
        entry_reason: "STD_JOURNEY",
        entry_td: 0,
        value: 9,
        entry_td_friendly: "Post",
        entry_td_friendly_long: "Immediately Post Injection",
      },
      {
        entry_time: "2022-06-08T13:41:44.686Z",
        entry_reason: "STD_JOURNEY",
        entry_td: 0,
        value: 9,
        entry_td_friendly: "4h",
        entry_td_friendly_long: "4h Post Injection",
      },
      {
        entry_time: "2022-06-09T13:41:44.686Z",
        entry_reason: "STD_JOURNEY",
        entry_td: 0,
        value: 8,
        entry_td_friendly: "24h",
        entry_td_friendly_long: "24h Post Injection",
      },
      {
        entry_time: "2022-06-10T13:41:44.686Z",
        entry_reason: "STD_JOURNEY",
        entry_td: 0,
        value: 5,
        entry_td_friendly: "48h",
        entry_td_friendly_long: "48h Post Injection",
      },
      {
        entry_time: "2022-06-14T13:41:44.686Z",
        entry_reason: "STD_JOURNEY",
        entry_td: 0,
        value: 2,
        entry_td_friendly: "1 week",
        entry_td_friendly_long: "1 week Post Injection",
      },
      {
        entry_time: "2022-06-21T13:41:44.686Z",
        entry_reason: "STD_JOURNEY",
        entry_td: 0,
        value: 2,
        entry_td_friendly: "2 weeks",
        entry_td_friendly_long: "2 weeks Post Injection",
      },
      {
        entry_time: "2022-07-02T13:41:44.686Z",
        entry_reason: "STD_JOURNEY",
        entry_td: 0,
        value: 5,
        entry_td_friendly: "4 weeks",
        entry_td_friendly_long: "4 weeks Post Injection",
      },
      {
        entry_time: "2022-07-16T13:41:44.686Z",
        entry_reason: "STD_JOURNEY",
        entry_td: 0,
        value: 3,
        entry_td_friendly: "6 weeks",
        entry_td_friendly_long: "6 weeks Post Injection",
      },
      {
        entry_time: "2022-07-30T13:41:44.686Z",
        entry_reason: "STD_JOURNEY",
        entry_td: 0,
        value: 2,
        entry_td_friendly: "8 week",
        entry_td_friendly_long: "8 week Post Injection",
      },
      {
        entry_time: "2022-08-13T13:41:44.686Z",
        entry_reason: "STD_JOURNEY",
        entry_td: 0,
        value: 3,
        entry_td_friendly: "10 weeks",
        entry_td_friendly_long: "10 weeks Post Injection",
      },
      {
        entry_time: "2022-08-27T13:41:44.686Z",
        entry_reason: "STD_JOURNEY",
        entry_td: 0,
        value: 2,
        entry_td_friendly: "12 weeks",
        entry_td_friendly_long: "12 weeks Post Injection",
      },
      {
        entry_time: "2022-09-10T13:41:44.686Z",
        entry_reason: "STD_JOURNEY",
        entry_td: 0,
        value: 2,
        entry_td_friendly: "14 weeks",
        entry_td_friendly_long: "14 weeks Post Injection",
      },
      {
        entry_time: "2022-09-24T13:41:44.686Z",
        entry_reason: "STD_JOURNEY",
        entry_td: 0,
        value: 2,
        entry_td_friendly: "16 weeks",
        entry_td_friendly_long: "16 weeks Post Injection",
      },
    ],
  },

  {
    patient_hospital_number: "12345678",
    doc_created_date: "2022-06-18T13:41:44.686Z",
    pat_created_date: "2022-06-18T13:41:44.686Z",
    site_name: "Flexor Hallucis Longus at posterior ankle",
    area_name: "Ankle",
    inj_side: "Left",
    inj_date: "2022-06-18T13:41:44.686Z",
    patient_name: "James Gonzalez",
    patient_email: "james.gonzalez@mail.com",
    doctor_name: "Amelia Campbell",
    doctor_gmc: "3721410",
    doctor_sec_email: "secretary@nhs.net",
    hospital_name: "St Mary's Hospital",
    entry_is_overdue: true,
    grouped_state: "ACTIVE",
    current_state: "PAIN_SPIKE",
    internal_state: "EMPTY_DIARY",
    entries: [
      {
        entry_time: "2022-06-06T13:41:44.686Z",
        entry_reason: "STD_JOURNEY",
        entry_td: 0,
        value: 8,
        entry_td_friendly: "Pre",
        entry_td_friendly_long: "Immediate Pre Injection",
      },
      {
        entry_time: "2022-06-08T13:41:44.686Z",
        entry_reason: "STD_JOURNEY",
        entry_td: 0,
        value: 9,
        entry_td_friendly: "Post",
        entry_td_friendly_long: "Immediately Post Injection",
      },
      {
        entry_time: "2022-06-08T13:41:44.686Z",
        entry_reason: "STD_JOURNEY",
        entry_td: 0,
        value: 9,
        entry_td_friendly: "4h",
        entry_td_friendly_long: "4h Post Injection",
      },
      {
        entry_time: "2022-06-09T13:41:44.686Z",
        entry_reason: "STD_JOURNEY",
        entry_td: 0,
        value: 4,
        entry_td_friendly: "24h",
        entry_td_friendly_long: "24h Post Injection",
      },
      {
        entry_time: "2022-06-10T13:41:44.686Z",
        entry_reason: "STD_JOURNEY",
        entry_td: 0,
        value: 3,
        entry_td_friendly: "48h",
        entry_td_friendly_long: "48h Post Injection",
      },
      {
        entry_time: "2022-06-14T13:41:44.686Z",
        entry_reason: "STD_JOURNEY",
        entry_td: 0,
        value: 2,
        entry_td_friendly: "1 week",
        entry_td_friendly_long: "1 week Post Injection",
      },
      {
        entry_time: "2022-06-21T13:41:44.686Z",
        entry_reason: "STD_JOURNEY",
        entry_td: 0,
        value: 4,
        entry_td_friendly: "2 weeks",
        entry_td_friendly_long: "2 weeks Post Injection",
      },
      {
        entry_time: "2022-07-02T13:41:44.686Z",
        entry_reason: "STD_JOURNEY",
        entry_td: 0,
        value: 7,
        entry_td_friendly: "4 weeks",
        entry_td_friendly_long: "4 weeks Post Injection",
      },
      {
        entry_time: "2022-07-16T13:41:44.686Z",
        entry_reason: "STD_JOURNEY",
        entry_td: 0,
        value: 8,
        entry_td_friendly: "6 weeks",
        entry_td_friendly_long: "6 weeks Post Injection",
      },
    ],
  },

  {
    patient_hospital_number: "12345678",
    doc_created_date: "2022-06-18T13:41:44.686Z",
    pat_created_date: "2022-06-18T13:41:44.686Z",
    site_name: "Flexor Hallucis Longus at posterior ankle",
    area_name: "Ankle",
    inj_side: "Left",
    inj_date: "2022-06-18T13:41:44.686Z",
    patient_name: "James Gonzalez",
    patient_email: "james.gonzalez@mail.com",
    doctor_name: "Amelia Campbell",
    doctor_gmc: "3721410",
    doctor_sec_email: "secretary@nhs.net",
    hospital_name: "St Mary's Hospital",
    entry_is_overdue: true,
    grouped_state: "ACTIVE",
    current_state: "NEVER_WORKED",
    internal_state: "EMPTY_DIARY",
    entries: [
      {
        entry_time: "2022-06-06T13:41:44.686Z",
        entry_reason: "STD_JOURNEY",
        entry_td: 0,
        value: 8,
        entry_td_friendly: "Pre",
        entry_td_friendly_long: "Immediate Pre Injection",
      },
      {
        entry_time: "2022-06-08T13:41:44.686Z",
        entry_reason: "STD_JOURNEY",
        entry_td: 0,
        value: 9,
        entry_td_friendly: "Post",
        entry_td_friendly_long: "Immediately Post Injection",
      },
      {
        entry_time: "2022-06-08T13:41:44.686Z",
        entry_reason: "STD_JOURNEY",
        entry_td: 0,
        value: 9,
        entry_td_friendly: "4h",
        entry_td_friendly_long: "4h Post Injection",
      },
      {
        entry_time: "2022-06-09T13:41:44.686Z",
        entry_reason: "STD_JOURNEY",
        entry_td: 0,
        value: 8,
        entry_td_friendly: "24h",
        entry_td_friendly_long: "24h Post Injection",
      },
      {
        entry_time: "2022-06-10T13:41:44.686Z",
        entry_reason: "STD_JOURNEY",
        entry_td: 0,
        value: 8,
        entry_td_friendly: "48h",
        entry_td_friendly_long: "48h Post Injection",
      },
    ],
  },
];

export { exampleDiaries };
