import { ui } from '@/components/ui';
import { useDiariesViewQuery } from '@/features/diaries/api/selectDiariesView';
import { time } from '@/services/time';
import type { TabKeyT } from '@/types/database/diaries';
import { Link } from '@tanstack/react-router';
import dayjs from 'dayjs';
import fuzzy from 'fuzzy';
import { Search as SearchIcon } from 'lucide-react';
import React, { useEffect, useState } from 'react';

interface SearchableTypes {
	patient_name: string;
	patient_email: string;
	hospital_numbers: string;
	diary_id: string;
	diary_name: string;
	start_date: string;
	date_of_birth: string;
	tab: TabKeyT;
}

const Search = () => {
	const [open, setOpen] = useState<boolean>(false);
	const { data: diaries } = useDiariesViewQuery();
	const [field, setField] = React.useState<keyof SearchableTypes>('patient_name');
	const [search, setSearch] = React.useState('');
	const [matches, setMatches] = React.useState<SearchableTypes[]>([]);

	useEffect(() => {
		if (!open) {
			return;
		}
		const selectedDiaryInfo = diaries?.map((d) => {
			return {
				patient_name: d?.patient_info?.patient_name,
				patient_email: d?.patient_info?.patient_email,
				date_of_birth: dayjs(d?.patient_info?.date_of_birth).format('DD/MM/YYYY'),
				hospital_numbers: d?.hospital_numbers?.join(', '),
				diary_id: d?.diary_id,
				diary_name: d?.diary_name,
				start_date: d?.start_date,
				tab: d?.tab,
			};
		}) as SearchableTypes[];
		const options = {
			pre: '<',
			post: '>',
			extract: (el) => String(el[field]),
		};

		const results = fuzzy.filter(search, selectedDiaryInfo, options);

		setMatches(results?.map((el) => el.original));
	}, [search, field, diaries, open]);

	return (
		<ui.Dialog onOpenChange={() => setOpen(!open)} open={open}>
			<ui.Button asChild variant="outline" size="sm">
				<ui.DialogTrigger onClick={() => setOpen(!open)} className="w-full md:w-fit">
					<SearchIcon />
					<span className="mr-auto">Search</span>
				</ui.DialogTrigger>
			</ui.Button>

			<ui.DialogContent className="flex flex-col h-[70vh] overflow-y-scroll">
				<ui.DialogHeader>
					<ui.DialogTitle>Search for a patient</ui.DialogTitle>
				</ui.DialogHeader>
				<div className="flex flex-row gap-x-2">
					<ui.Select onValueChange={(e) => setField(e as keyof SearchableTypes)} defaultValue="patient_name">
						<ui.SelectTrigger className="w-[180px]">
							<ui.SelectValue />
						</ui.SelectTrigger>
						<ui.SelectContent>
							<ui.SelectItem value="patient_name">Name</ui.SelectItem>
							<ui.SelectItem value="patient_email">Email</ui.SelectItem>
							<ui.SelectItem value="date_of_birth">Date of Birth</ui.SelectItem>
							<ui.SelectItem value="hospital_numbers">Hospital Number</ui.SelectItem>
						</ui.SelectContent>
					</ui.Select>
					<ui.Input
						onChange={(e) => setSearch(e.target.value)}
						placeholder={field === 'date_of_birth' ? 'DD/MM/YYYY' : 'Search'}
					/>
				</div>
				{search ? (
					matches?.map((match) => (
						<ui.DialogClose key={match.diary_id} className="p-0" asChild>
							<Link
								className="hover:bg-slate-100 dark:hover:bg-slate-700 p-4 rounded-md w-full text-sm"
								to="/dashboard/diary/$diaryId"
								params={{ diaryId: match.diary_id }}
								search={{ tab: match.tab }}
							>
								<div className="flex justify-between items-start w-full dark:focus-within::text-slate-800">
									<div>
										<h3 className="font-bold text-lg text-slate-800 dark:text-slate-100">{match.patient_name}</h3>
										<p className="text-md dark:text-slate-300">Date of Birth: {match.date_of_birth ?? 'Not Defined'}</p>
									</div>
									<p className="dark:text-slate-300">Hospital Number: {match.hospital_numbers ?? 'Not Defined'}</p>
								</div>

								<p className="dark:text-slate-200">Diary Name: {match.diary_name}</p>
								<p className="dark:text-slate-200">Start Date: {time.formatDate(match.start_date)}</p>
							</Link>
						</ui.DialogClose>
					))
				) : (
					<p>No Search Results</p>
				)}
			</ui.DialogContent>
		</ui.Dialog>
	);
};

export default Search;
