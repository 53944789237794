import AnimateInView from '../../../components/ui/animate-in-view';
import PDFReport from '../assets/PDFReport';
import HeroContent from '../components/HeroContent';
import Section from '../components/Section';

const Report = () => {
    return (
        <Section row={true}>
            <HeroContent
                caption="Downloadable Results"
                title="Generate Patient Reports"
                description="PDF exports of the final patient report alleviates all integration difficulties."
            ></HeroContent>
            <AnimateInView className="h-fit w-full [&_img]:border [&_img]:border-gray-300 [&_img]:h-[500px] [&_img]:mx-auto">
                <PDFReport />
            </AnimateInView>
        </Section>
    );
};

export default Report;
