import { z } from "zod";

import { DiaryTypeZ } from "../../../types/zod/common";

export const NewDiarySchemaZ = z.object({
  diary_type: DiaryTypeZ,
  patient_id: z.string().uuid({ message: "Invalid Patient" }),
  start_date: z.date(),
  diary_id: z.string().uuid({ message: "Invalid Diary Id" }),
  diary_name: z.string().optional(),
});

export type NewDiarySchemaT = z.infer<typeof NewDiarySchemaZ>;
