interface Props {
	solid?: boolean;
	icon?: 'tick' | 'cross' | 'question';
	iconFill?: string;
	fill?: string;
	className?: string;
}
const Shield = ({ icon, solid, fill, className, iconFill }: Props) => {
	return (
		<svg
			className={className}
			width="43"
			height="46"
			viewBox="0 0 43 46"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			{!icon && !solid && (
				<>
					<path
						d="M3.0309 20.1063L2.09937 11.8778C2.04497 11.3973 2.39841 10.9668 2.88033 10.9266L3.56359 10.8697C10.2405 10.3133 16.4655 7.04141 20.7547 1.8943C21.1349 1.43814 21.8448 1.44334 22.2094 1.91205C26.2173 7.06507 32.2063 10.2965 38.7137 10.8171L40.1166 10.9293C40.5997 10.968 40.9548 11.399 40.9003 11.8806L39.9691 20.1063C38.7575 30.8091 31.7349 39.9681 21.7134 43.9159C21.5763 43.97 21.4237 43.97 21.2866 43.9159C11.2651 39.9681 4.24254 30.8091 3.0309 20.1063Z"
						stroke={fill ? fill : 'black'}
						strokeWidth="3"
					/>
				</>
			)}
			{icon === 'tick' && solid && (
				<>
					<path
						d="M3.0309 20.1063L2.09937 11.8778C2.04497 11.3973 2.39841 10.9668 2.88033 10.9266L3.56359 10.8697C10.2405 10.3133 16.4655 7.04141 20.7547 1.8943C21.1349 1.43814 21.8448 1.44334 22.2094 1.91205C26.2173 7.06507 32.2063 10.2965 38.7137 10.8171L40.1166 10.9293C40.5997 10.968 40.9548 11.399 40.9003 11.8806L39.9691 20.1063C38.7575 30.8091 31.7349 39.9681 21.7134 43.9159C21.5763 43.97 21.4237 43.97 21.2866 43.9159C11.2651 39.9681 4.24254 30.8091 3.0309 20.1063Z"
						fill={fill ? fill : '#4F46E5'}
						stroke={fill ? fill : '#4F46E5'}
						strokeWidth="3"
					/>
					<path
						d="M14.7584 24.2416L19.5103 29.324L30.5618 17.9202"
						stroke={iconFill ? iconFill : 'white'}
						strokeWidth="3"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</>
			)}
			{icon === 'tick' && !solid && (
				<>
					<path
						d="M3.0309 20.1063L2.09937 11.8778C2.04497 11.3973 2.39841 10.9668 2.88033 10.9266L3.56359 10.8697C10.2405 10.3133 16.4655 7.04141 20.7547 1.8943C21.1349 1.43814 21.8448 1.44334 22.2094 1.91205C26.2173 7.06507 32.2063 10.2965 38.7137 10.8171L40.1166 10.9293C40.5997 10.968 40.9548 11.399 40.9003 11.8806L39.9691 20.1063C38.7575 30.8091 31.7349 39.9681 21.7134 43.9159C21.5763 43.97 21.4237 43.97 21.2866 43.9159C11.2651 39.9681 4.24254 30.8091 3.0309 20.1063Z"
						stroke={fill ? fill : '#4F46E5'}
						strokeWidth="3"
					/>
					<path
						d="M14.7584 24.2416L19.5103 29.324L30.5618 17.9202"
						stroke={fill ? fill : '#4F46E5'}
						strokeWidth="3"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</>
			)}
			{icon === 'cross' && !solid && (
				<>
					<path
						d="M3.0309 20.1063L2.09937 11.8778C2.04497 11.3973 2.39841 10.9668 2.88033 10.9266L3.56359 10.8697C10.2405 10.3133 16.4655 7.04141 20.7547 1.8943C21.1349 1.43814 21.8448 1.44334 22.2094 1.91205C26.2173 7.06507 32.2063 10.2965 38.7137 10.8171L40.1166 10.9293C40.5997 10.968 40.9548 11.399 40.9003 11.8806L39.9691 20.1063C38.7575 30.8091 31.7349 39.9681 21.7134 43.9159C21.5763 43.97 21.4237 43.97 21.2866 43.9159C11.2651 39.9681 4.24254 30.8091 3.0309 20.1063Z"
						stroke={fill ? fill : '#F56940'}
						strokeWidth="3"
					/>
					<path d="M16 18L26.5 28.5" stroke={fill ? fill : '#F56940'} strokeWidth="3" strokeLinecap="round" />
					<path d="M26.5 18L16 28.5" stroke={fill ? fill : '#F56940'} strokeWidth="3" strokeLinecap="round" />
				</>
			)}
			{icon === 'cross' && solid && (
				<>
					<path
						d="M3.0309 20.1063L2.09937 11.8778C2.04497 11.3973 2.39841 10.9668 2.88033 10.9266L3.56359 10.8697C10.2405 10.3133 16.4655 7.04141 20.7547 1.8943C21.1349 1.43814 21.8448 1.44334 22.2094 1.91205C26.2173 7.06507 32.2063 10.2965 38.7137 10.8171L40.1166 10.9293C40.5997 10.968 40.9548 11.399 40.9003 11.8806L39.9691 20.1063C38.7575 30.8091 31.7349 39.9681 21.7134 43.9159C21.5763 43.97 21.4237 43.97 21.2866 43.9159C11.2651 39.9681 4.24254 30.8091 3.0309 20.1063Z"
						fill={fill ? fill : '#F56940'}
						stroke={fill ? fill : '#F56940'}
						strokeWidth="3"
					/>
					<path d="M16 17L26.5 27.5" stroke={iconFill ? iconFill : 'white'} strokeWidth="3" strokeLinecap="round" />
					<path d="M26.5 17L16 27.5" stroke={iconFill ? iconFill : 'white'} strokeWidth="3" strokeLinecap="round" />
				</>
			)}
			{icon === 'question' && !solid && (
				<>
					<path
						d="M3.0309 20.1063L2.09937 11.8778C2.04497 11.3973 2.39841 10.9668 2.88033 10.9266L3.56359 10.8697C10.2405 10.3133 16.4655 7.04141 20.7547 1.8943C21.1349 1.43814 21.8448 1.44334 22.2094 1.91205C26.2173 7.06507 32.2063 10.2965 38.7137 10.8171L40.1166 10.9293C40.5997 10.968 40.9548 11.399 40.9003 11.8806L39.9691 20.1063C38.7575 30.8091 31.7349 39.9681 21.7134 43.9159C21.5763 43.97 21.4237 43.97 21.2866 43.9159C11.2651 39.9681 4.24254 30.8091 3.0309 20.1063Z"
						stroke={fill ? fill : 'black'}
						strokeWidth="3"
					/>
					<path
						d="M21.5 32C20.7403 32 20.125 31.3504 20.125 30.5484C20.125 29.7464 20.7403 29.0968 21.5 29.0968C22.2597 29.0968 22.875 29.7464 22.875 30.5484C22.875 31.3504 22.2597 32 21.5 32ZM23.6622 24.0343C23.3509 24.1612 23.083 24.3842 22.8935 24.6742C22.7041 24.9642 22.6018 25.3078 22.6 25.6601V26.4839C22.6 26.6435 22.4763 26.7742 22.325 26.7742H20.675C20.5238 26.7742 20.4 26.6435 20.4 26.4839V25.7036C20.4 24.8653 20.6303 24.0379 21.0841 23.3484C21.5275 22.6734 22.1463 22.1581 22.875 21.8641C24.0438 21.3887 24.8 20.3544 24.8 19.2258C24.8 17.6254 23.3184 16.3226 21.5 16.3226C19.6816 16.3226 18.2 17.6254 18.2 19.2258V19.5016C18.2 19.6613 18.0762 19.7919 17.925 19.7919H16.275C16.1238 19.7919 16 19.6613 16 19.5016V19.2258C16 17.7996 16.5913 16.4677 17.6638 15.477C18.695 14.5226 20.0563 14 21.5 14C22.9438 14 24.305 14.5262 25.3362 15.477C26.4087 16.4677 27 17.7996 27 19.2258C27 21.3234 25.6903 23.2105 23.6622 24.0343Z"
						fill={fill ? fill : 'black'}
					/>
				</>
			)}
			{icon === 'question' && solid && (
				<>
					<path
						d="M3.0309 20.1063L2.09937 11.8778C2.04497 11.3973 2.39841 10.9668 2.88033 10.9266L3.56359 10.8697C10.2405 10.3133 16.4655 7.04141 20.7547 1.8943C21.1349 1.43814 21.8448 1.44334 22.2094 1.91205C26.2173 7.06507 32.2063 10.2965 38.7137 10.8171L40.1166 10.9293C40.5997 10.968 40.9548 11.399 40.9003 11.8806L39.9691 20.1063C38.7575 30.8091 31.7349 39.9681 21.7134 43.9159C21.5763 43.97 21.4237 43.97 21.2866 43.9159C11.2651 39.9681 4.24254 30.8091 3.0309 20.1063Z"
						fill={fill ? fill : 'black'}
						stroke={fill ? fill : 'black'}
						strokeWidth="3"
					/>
					<path
						d="M21.5 31C20.7403 31 20.125 30.3504 20.125 29.5484C20.125 28.7464 20.7403 28.0968 21.5 28.0968C22.2597 28.0968 22.875 28.7464 22.875 29.5484C22.875 30.3504 22.2597 31 21.5 31ZM23.6622 23.0343C23.3509 23.1612 23.083 23.3842 22.8935 23.6742C22.7041 23.9642 22.6018 24.3078 22.6 24.6601V25.4839C22.6 25.6435 22.4763 25.7742 22.325 25.7742H20.675C20.5238 25.7742 20.4 25.6435 20.4 25.4839V24.7036C20.4 23.8653 20.6303 23.0379 21.0841 22.3484C21.5275 21.6734 22.1463 21.1581 22.875 20.8641C24.0438 20.3887 24.8 19.3544 24.8 18.2258C24.8 16.6254 23.3184 15.3226 21.5 15.3226C19.6816 15.3226 18.2 16.6254 18.2 18.2258V18.5016C18.2 18.6613 18.0762 18.7919 17.925 18.7919H16.275C16.1238 18.7919 16 18.6613 16 18.5016V18.2258C16 16.7996 16.5913 15.4677 17.6638 14.477C18.695 13.5226 20.0563 13 21.5 13C22.9438 13 24.305 13.5262 25.3362 14.477C26.4087 15.4677 27 16.7996 27 18.2258C27 20.3234 25.6903 22.2105 23.6622 23.0343Z"
						fill={iconFill ? iconFill : 'white'}
					/>
				</>
			)}
		</svg>
	);
};

export default Shield;
