import { CheckCircle } from 'lucide-react';

interface Props {
	canMarkComplete: boolean; // Flag to indicate if the patient can mark the entry as complete
}

const ReadyToReview = ({ canMarkComplete }: Props) => {
	return (
		<div className="my-4 mt-0 flex flex-col items-center justify-center">
			<CheckCircle size={60} strokeWidth={1.75} className="animate-bounce" />
			<p className="mt-4 text-center text-lg font-semibold text-gray-700">
				{canMarkComplete
					? 'You have completed your entry. Feel free to mark it as complete or wait for your doctor to review it.'
					: 'You have completed your entry. Please wait for your doctor to review it.'}
			</p>
		</div>
	);
};

export default ReadyToReview;
