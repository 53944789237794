import { ui } from '@/components/ui';
import { Route as EditTrackerRoute } from '@/routes/_app/diary/$diaryId/edit/tracker/$trackerId';
import { Link } from '@tanstack/react-router';
import { BadgePlus, Pencil } from 'lucide-react';

import type { StatefulTrackerViewT } from '../../../types/database/trackers';
import NewEntryLink from '../../diary/new/components/NewEntryLink';
import PlayPauseTrackerButton from './PlayPauseTrackerButton';

interface Props {
	diaryid: string;
	tracker: StatefulTrackerViewT;
	stopWords?: string;
	startWords?: string;
	showNextEntryButton?: boolean;
	showEditButton?: boolean;
}
const TrackerButtons = ({ diaryid, tracker, stopWords, startWords, showNextEntryButton, showEditButton }: Props) => {
	return (
		<>
			{showNextEntryButton && (
				<NewEntryLink
					is_locked={tracker?.next_entry?.is_locked}
					trackerId={tracker?.tracker_id as string}
					diaryId={diaryid}
				>
					<ui.Button tabIndex={-1} disabled={tracker?.next_entry?.is_locked} size="lg" variant="default">
						Next
					</ui.Button>
				</NewEntryLink>
			)}

			{tracker.is_pausable && (
				<PlayPauseTrackerButton stopWords={stopWords} startWords={startWords} tracker={tracker} diaryId={diaryid} />
			)}
			{showEditButton && (
				<ui.Button variant="secondary" size="lg" asChild>
					<Link
						to={EditTrackerRoute.to}
						params={{ diaryId: tracker.diary_id as string, trackerId: tracker.tracker_id }}
						className="flex flex-row items-center justify-center gap-2"
					>
						<Pencil />
						Edit
					</Link>
				</ui.Button>
			)}
		</>
	);
};

export default TrackerButtons;
