import BOFAS from '../documents/Cat_Flood_BOFAS.pdf';
import EFORT from '../documents/EFORT_Pain_Diary_Abstract.pdf';
import EFASPoster from '../documents/eposter_efascongress_2022.pdf';
import OPAs from '../documents/Saving_OPAs_after_Injections_in_MSK.pdf';

import type { Watch } from '@/definitions/diaryTypes';
interface Prize {
	name: string;
	href: string;
}
export interface EvidenceItem {
	title: string;
	heading?: string;
	description: string;
	publisher: string;
	href: string;
	date: string;
	watch: Watch;
	participants: number;
	prize?: Prize;
	headline?: boolean;
}
export const content: EvidenceItem[] = [
	{
		title: "Wasting Everyone's Time (v1)",
		description:
			'This study of 108 orthopaedic surgeons shows that routine follow-up appointments after injection are widely used, but are wasteful and inefficient.',
		publisher: 'EFAS',
		href: EFASPoster,
		participants: 108,
		date: '20 March 2022',
		watch: 'PAIN',
	},
	{
		title: "Wasting Everyone's Time (v2)",
		heading: '85% of surgeons schedule unnecessary routine follow-up.',
		description:
			'This prize winning poster shows how 85% of surgeons schedule routine follow-up appointments after injections despite these appointments being unnecessary.',
		publisher: 'BOFAS',
		href: BOFAS,
		participants: 271,
		date: '10 March 2023',
		prize: {
			name: '2nd Prize for Best Poster',
			href: 'https://www.bofas.org.uk/clinician/meetings/past-congresses/bofas-2023/bofas-2023-scientific-papers',
		},
		headline: true,
		watch: 'PAIN',
	},
	{
		heading: '3000+ injections at RSCH NHS Trust each year',
		title: 'Ultrasound Guided Injections in Muscloskeletal Care',
		description:
			'This retrospective study of PACs data, showed the Royal Surrey did 3420 joint injections in 2019. This excluded OPD and theatre injections.',
		publisher: 'Internal Audit',
		participants: 3420,
		date: '20 March 2021',
		href: OPAs,
		headline: true,
		watch: 'PAIN',
	},
	{
		publisher: 'EFORT',
		title: 'Efficient Follow-Up After Orthopaedic Injections.',
		heading: 'Only 9% of patients in this study benefitted from routine follow-up.',
		description:
			'The number of saved appointments for a surgeon was calculated by comparing patient pain diary results to patient appointments over 3 years.',
		date: '15 May 2022',
		participants: 104,
		href: EFORT,
		headline: true,
		watch: 'PAIN',
	},
];
