import { Table, TableBody, TableCaption, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';

function OrganisationStatisticsTable({ organisationStatistics }) {
	return (
		<Table>
			<TableCaption>A list of organisations statistics.</TableCaption>
			<TableHeader>
				<TableRow>
					<TableHead className="w-[100px]">Organisation</TableHead>
					<TableHead>Number of Diaries</TableHead>
					<TableHead>Number of Patients</TableHead>
					<TableHead>Number of Doctor</TableHead>
					<TableHead>Number of Secretaries</TableHead>
				</TableRow>
			</TableHeader>
			<TableBody>
				{organisationStatistics?.map((os) => (
					<TableRow key={os.organisation_id}>
						<TableCell>{os.organisation_name}</TableCell>
						<TableCell>{os.number_of_diaries}</TableCell>
						<TableCell>{os.number_of_patients}</TableCell>
						<TableCell>{os.number_of_clinicians}</TableCell>
						<TableCell>{os.number_of_secretaries}</TableCell>
					</TableRow>
				))}
			</TableBody>
		</Table>
	);
}
export default OrganisationStatisticsTable;
