import { ui } from '../ui';

interface Props {
	label: string;
	maxLength?: number;
	description?: string;
	value: string;
	setValue: (text: string) => void;
}
const Longtext = (props: Props) => {
	return (
		<>
			<ui.Label>{props.label}</ui.Label>
			<ui.Input
				autoComplete="email"
				type="email"
				placeholder="name@domain.com"
				value={props.value}
				onChange={(e) => props.setValue(e.target.value)}
				maxLength={props.maxLength}
			/>
			<p className="text-sm font-normal text-slate-700">{props.description}</p>
		</>
	);
};

export default Longtext;
