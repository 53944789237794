import { supabaseClient } from '@/lib/supabase';
import { queryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

export async function selectProfiles() {
	const { data, error } = await supabaseClient.from('profiles').select('*');
	if (error) throw error;
	return data;
}

export const selectProfilesQueryOptions = () =>
	queryOptions({
		queryKey: ['profiles'],
		queryFn: async () => selectProfiles(),
	});

export function useSelectProfiles() {
	const options = selectProfilesQueryOptions();
	return useQuery(options);
}
