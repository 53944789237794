interface Props {
	className?: string;
}
const Logo = ({ className }: Props) => {
	return (
		<svg
			className={`h-full w-full ${className}`}
			width="78"
			height="166"
			viewBox="0 0 78 166"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M22 16.5C22 25.6127 17.0751 33 11 33C4.92487 33 0 25.6127 0 16.5C0 7.3873 4.92487 0 11 0C17.0751 0 22 7.3873 22 16.5Z"
				fill="black"
				fillOpacity="0.3"
			/>
			<path d="M22 16.5C22 25.6127 17.0751 33 11 33C4.92487 33 0 25.6127 0 16.5C0 7.3873 4.92487 0 11 0C17.0751 0 22 7.3873 22 16.5Z" />
			<path
				d="M39 11C39 15.9706 35.866 20 32 20C28.134 20 25 15.9706 25 11C25 6.02944 28.134 2 32 2C35.866 2 39 6.02944 39 11Z"
				fill="black"
				fillOpacity="0.3"
			/>
			<path d="M39 11C39 15.9706 35.866 20 32 20C28.134 20 25 15.9706 25 11C25 6.02944 28.134 2 32 2C35.866 2 39 6.02944 39 11Z" />
			<path
				d="M54 16.5C54 20.6421 51.3137 24 48 24C44.6863 24 42 20.6421 42 16.5C42 12.3579 44.6863 9 48 9C51.3137 9 54 12.3579 54 16.5Z"
				fill="black"
				fillOpacity="0.3"
			/>
			<path d="M54 16.5C54 20.6421 51.3137 24 48 24C44.6863 24 42 20.6421 42 16.5C42 12.3579 44.6863 9 48 9C51.3137 9 54 12.3579 54 16.5Z" />
			<path
				d="M67 27C67 30.866 64.5376 34 61.5 34C58.4624 34 56 30.866 56 27C56 23.134 58.4624 20 61.5 20C64.5376 20 67 23.134 67 27Z"
				fill="black"
				fillOpacity="0.3"
			/>
			<path d="M67 27C67 30.866 64.5376 34 61.5 34C58.4624 34 56 30.866 56 27C56 23.134 58.4624 20 61.5 20C64.5376 20 67 23.134 67 27Z" />
			<path
				d="M78 41.5C78 45.0899 75.3137 48 72 48C68.6863 48 66 45.0899 66 41.5C66 37.9101 68.6863 35 72 35C75.3137 35 78 37.9101 78 41.5Z"
				fill="black"
				fillOpacity="0.3"
			/>
			<path d="M78 41.5C78 45.0899 75.3137 48 72 48C68.6863 48 66 45.0899 66 41.5C66 37.9101 68.6863 35 72 35C75.3137 35 78 37.9101 78 41.5Z" />
			<path
				d="M45.5002 33C36.0002 30.5001 19.5 33 12.5001 38.5C5.50015 44.0001 5.30588 53.1358 8.50021 60.5C13.2716 71.5 31.0002 65.3334 40.5002 68.5C46.5002 70.5 46.5002 80.5 46.5002 86C46.5002 111.46 27.1114 127.5 22.0002 139C16.8891 150.5 20.0002 166 36.5002 166C53.0002 166 49.0002 129 51.5002 113C53.5314 100 71.5002 75.5 71.5002 65C71.5002 56.5 63.6626 53.2896 58.0002 46.5C54.3221 42.0897 55.0002 35.5 45.5002 33Z"
				fill="black"
				fillOpacity="0.3"
			/>
			<path
				d="M63.1325 62.8958L16.0531 48.2151L35.7961 150.474L63.1325 62.8958Z"
				stroke="#5554C3"
				strokeWidth="3"
				strokeLinejoin="round"
			/>
			<circle cx="35.2991" cy="149.308" r="5.0623" fill="#5554C3" />
			<circle cx="63.1325" cy="62.3894" r="5.0623" fill="#5554C3" />
			<circle cx="16.0623" cy="48.0623" r="5.0623" fill="#5554C3" />
		</svg>
	);
};

export default Logo;
