import { ui } from '@/components/ui';
import type { BuilderT } from '@/definitions/builder';
import { Link, createFileRoute } from '@tanstack/react-router';

import { builderContent as bc } from '@/definitions/builder';
import { cn } from '@/lib/utils';
import Title from '../../../components/common/Title';

const Templates = () => {
	return (
		<section className="flex flex-col gap-4 lg:p-8 md:p-4 p-2">
			<Title
				words="Builder"
				description="Create new templates for diaries, trackers, forms and templates to alter how users interact with Patient Watch."
			/>
			<ui.Card className="bg-indigo-100 pt-4">
				<div className="flex flex-row">
					<ui.CardHeader className="flex flex-col pt-0">
						<div className="flex flex-row items-center gap-2">
							{bc.diaries.icon}
							<ui.CardTitle>{bc.diaries.title}</ui.CardTitle>
						</div>
						<ui.CardDescription className="pb-2">{bc.diaries.description}</ui.CardDescription>

						<div className="flex flex-row gap-2">
							<ui.Button size="md" className="w-fit" asChild>
								<Link to={bc.diaries.templates}>Open</Link>
							</ui.Button>
						</div>
					</ui.CardHeader>
				</div>
				<ui.CardContent>
					<BuilderCard className="bg-sky-50" builder={bc.trackers}>
						<BuilderCard builder={bc.forms} />
						<BuilderCard builder={bc.schedules} />
					</BuilderCard>
				</ui.CardContent>
			</ui.Card>
			<BuilderCard builder={bc.quickstarts} />
		</section>
	);
};

export const Route = createFileRoute('/_app/builder/')({
	component: Templates,
});
interface Props {
	icon: React.ReactNode;
	words: string;
}

interface Props {
	builder: BuilderT;
	className?: string;
	children?: React.ReactNode;
}
const BuilderCard = ({ builder, children, className }: Props) => {
	return (
		<ui.Card className={cn('flex w-full flex-col md:flex-row pt-4', className)}>
			<ui.CardHeader className="flex h-full pt-0">
				<div className="flex flex-row items-center gap-2">
					{builder.icon}
					<ui.CardTitle>{builder.title}</ui.CardTitle>
				</div>
				<ui.CardDescription className="pb-2">{builder.description}</ui.CardDescription>

				<div className="flex flex-row gap-2">
					<ui.Button size="md" className=" w-fit" variant="default" asChild>
						<Link className="" to={builder.link}>
							Open
						</Link>
					</ui.Button>
					{builder.templates && (
						<ui.Button size="md" className=" w-fit" variant="secondary" asChild>
							<Link className="" to={builder.templates}>
								Templates
							</Link>
						</ui.Button>
					)}
				</div>
			</ui.CardHeader>

			<ui.CardContent className={cn('flex flex-col md:flex-row gap-4')}>{children}</ui.CardContent>
		</ui.Card>
	);
};
