import { useFormContext } from 'react-hook-form';
import { ui } from '../ui';

interface Props {
	name: string;
	tabIndex?: number;
	label?: string;
}

const Time: React.FC<Props> = ({ name, tabIndex, label }) => {
	const { control } = useFormContext();
	return (
		<ui.FormField
			control={control}
			name={name}
			data-cy={name}
			render={({ field }) => (
				<ui.FormItem className="w-full">
					{label && <ui.FormLabel>{label}</ui.FormLabel>}
					<ui.FormControl className="w-full">
						<ui.Input className="w-full" tabIndex={tabIndex} autoComplete="time" type="time" {...field} />
					</ui.FormControl>

					<ui.FormMessage />
				</ui.FormItem>
			)}
		/>
	);
};

export default Time;
