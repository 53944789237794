import { supabaseClient } from '@/lib/supabase';
import type { StateDefinitionT } from '@/types/database/diaries';
import { queryOptions, useSuspenseQuery } from '@tanstack/react-query';

export async function selectStates(): Promise<StateDefinitionT[]> {
	const { data, error } = await supabaseClient.from('state_definitions').select('*');
	if (error) throw error;
	return data;
}

export const createStatesQueryKey = () => ['state_definitions'];

export const stateDefinitionsQueryOptions = () => {
	return queryOptions({
		queryKey: createStatesQueryKey(),
		queryFn: () => selectStates(),
	});
};

export function useStateDefinitionsQuery() {
	return useSuspenseQuery(stateDefinitionsQueryOptions());
}
