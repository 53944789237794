import { supabaseClient } from '@/lib/supabase';
import { type QueryKey, useSuspenseQuery } from '@tanstack/react-query';

export async function getPatients() {
	const { data, error } = await supabaseClient
		.from('patients')
		.select('name, email, date_of_birth, nhs_number, id, uuid');
	if (error) throw error;
	return data;
}

const key: QueryKey = ['patients'];

function usePatientsQuery() {
	return useSuspenseQuery({
		queryKey: key,
		queryFn: async () => getPatients(),
	});
}

export default usePatientsQuery;
