import type { ActionDefinitionT } from "@/types/database/actions";
import type { StatefulDiaryT, StateKeyT } from "@/types/database/diaries";
import type { ProfileT } from "@/types/database/profiles";
import { zodResolver } from "@hookform/resolvers/zod";
import { type SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";

import useTakeAction from "../api/useTakeAction";
import { emailBuilder } from "../services/emailBuilder";
import { useEmailBuilder } from "../services/useEmailBuilder";

const ActionDetailSchemaZ = z.object({
	diary_status: z.string(),
	diary_id: z.string().uuid(),
	action_id: z.string().uuid(),
	from_email: z.string().email(),
	to_email: z.string(),
	cc_email: z.string(),
	action_key: z.string(),
	body: z.string(),
	subject: z.string().min(1, "Subject is required").max(
		100,
		"Subject is too long",
	),
});

export type ActionDetailSchemaT = z.infer<typeof ActionDetailSchemaZ>;
interface Props {
	actionId: string;
	action: ActionDefinitionT;
	state: StateKeyT;
	diary: StatefulDiaryT;
	profile: ProfileT;
}

const useActionEmailHook = (props: Props) => {
	const takeAction = useTakeAction();
	const body = useEmailBuilder({
		toName: props.diary.patient_info?.patient_name ?? "Patient",
		start: props.diary.state.email_words,
		end: props.action?.email_words ?? "",
		fromName: props.profile.name ?? "Your Patient Watch Doctor",
		diaryId: props.action?.action_key === "DISCHARGE"
			? props.diary.diary_id
			: undefined,
		patientId: props.action?.action_key === "DISCHARGE"
			? props.diary.patient_id
			: undefined,
	});

	const form = useForm<ActionDetailSchemaT>({
		resolver: zodResolver(ActionDetailSchemaZ),
		defaultValues: {
			diary_status: props.state,
			action_id: props.actionId,
			from_email: "clinician@patient-watch.com",
			action_key: props.action?.action_key,
			diary_id: props.diary.diary_id,
			subject: `Patient Watch ${props.action?.action_name}`,
			body: body,
			cc_email: props.diary.trackers_view[0]?.contact_email ?? "",
			to_email: props.diary.patient_info?.patient_email ?? "",
		},
	});

	const onSubmit: SubmitHandler<ActionDetailSchemaT> = async (formData) => {
		takeAction.mutate(formData);
	};

	return { onSubmit, form };
};

export default useActionEmailHook;
