import { services as s } from '@/services';
import type React from 'react';

type CardGridProps<T> = {
	items: T[];
	renderItem: (item: T) => React.ReactNode;
};

const CardGrid = <T,>({ items, renderItem }: CardGridProps<T>) => {
	return (
		<ul className="flex flex-col flex-wrap my-4 lg:flex-row">
			{items.map((item) => (
				<li key={s.uuidv4()} className="p-2 lg:w-1/3">
					{renderItem(item)}
				</li>
			))}
		</ul>
	);
};

export default CardGrid;
