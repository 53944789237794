import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import FootIcon from '@/features/foot-watch/assets/FootIcon';
import { Calendar, ExternalLink, UserCircle2 } from 'lucide-react';

import WatchBadge from './WatchBadge';

import type { Watch } from '@/definitions/diaryTypes';
interface Props {
	title: string;
	date: string;
	publisher: string;
	description: string;
	href: string;
	participants: number;
	watch: Watch;
}

const HeadlineStudy = ({ title, date, publisher, description, href, participants, watch }: Props) => {
	return (
		<Card className="flex h-full w-full flex-auto flex-col">
			<CardHeader className="flex flex-col">
				<h4 className="pb-1 text-xl font-bold text-slate-950">{publisher}</h4>
				<WatchBadge watch={watch} />
			</CardHeader>
			<CardContent className="flex pt-2 flex-col gap-y-8 p-6 border-t border-slate-200">
				<div className="flex flex-col gap-y-2 pt-2 text-sm text-slate-600">
					<div className="flex gap-x-2">
						<UserCircle2 />
						<p>{participants} Participants</p>
					</div>
					<div className="flex gap-x-2">
						<Calendar />
						<p>{date}</p>
					</div>
				</div>
				<CardTitle className="text-2xl leading-10">{title}</CardTitle>
				<CardDescription className="text-lg">{description}</CardDescription>
			</CardContent>
			<CardFooter className="mt-auto">
				<a href={href} rel="noopener noreferrer" target="_blank">
					<Button variant="secondary">
						Learn More
						<ExternalLink className="ml-2 h-5 w-5" />
					</Button>
				</a>
			</CardFooter>
		</Card>
	);
};

export default HeadlineStudy;
