import { createFileRoute } from '@tanstack/react-router';
import Title from '../../../components/common/Title';

const PatientHelpPage = () => {
	return (
		<section className="lg-width">
			<Title words="Help for Patients" />
			<div className="relative mx-4 p-8 pb-[56.25%] max-w-lg">
				<iframe
					title="Patient Watch Explainer for Patients"
					src="https://www.loom.com/embed/43564392bdea41e59930e0d7b59510b3?sid=4c20adbe-561c-45c8-b568-1c33870ce1ed"
					allowFullScreen
					className="top-0 left-0 absolute w-full h-full"
				/>
			</div>
		</section>
	);
};

export const Route = createFileRoute('/_app/help/patient')({
	component: PatientHelpPage,
});
