import { commonComponents as cc } from '@/components/common';

import { useDeleteUser } from '../api/deleteUserMutation';

const DeleteUserButton = () => {
	const deleteUser = useDeleteUser();
	return (
		<cc.DeleteButton
			words="Delete Account"
			variant="destructive-secondary"
			deleteFunction={() => deleteUser.mutate()}
		/>
	);
};

export default DeleteUserButton;
