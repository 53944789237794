import { ClipboardCheck, Clock, DollarSign, Star } from 'lucide-react';

import AnimateInView from '../../../components/ui/animate-in-view';
import Section from '../components/Section';
import CheckTheMaths from '../evidence/CheckTheMaths';

const SocialProof = () => {
	return (
		<Section row={true}>
			<div className="flex flex-col md:p-0 items-center md:items-start md:justify-start justify-center md:text-left text-center gap-y-4 md:pr-12 px-2">
				<p className="text-cyan-600 text-lg w-fit">Trusted by the NHS</p>
				<h3 className="font-extrabold text-3xl md:text-4xl leading-12">
					Trusted by NHS surgeons to save 91% of orthopaedic follow-up appointments.
				</h3>
				<p className="text-slate-800 text-xl font-light max-w-[800px] ">
					Over <strong className="font-bold">500 000</strong> injections are given annually in the UK. Surgeons
					routinely follow up almost every injection. With Patient Watch, <strong className="font-bold">9 in 10</strong>{' '}
					of these appointments could be avoided.
				</p>
				<CheckTheMaths />
			</div>
			<div className="w-full">
				{/* Group 1 */}
				<div className="flex flex-col md:flex-row gap-x-4">
					{/* Stat 1 */}
					<AnimateInView>
						<div className="flex flex-col gap-y-2 w-full p-2 md:p-0 items-center md:items-start md:justify-start justify-center md:text-left text-center pt-2 px-4">
							<span className="py-2 flex mt-2 text-cyan-600">
								<DollarSign size={40} />
							</span>
							<h4 className="text-xl text-slate-800 font-extrabold">Save NHS £75M+</h4>
							<p className="text-md text-slate-600">
								{<p className="">Projected to save the NHS £75 million in wasted follow-ups alone.</p>}
							</p>
						</div>
					</AnimateInView>

					{/* Stat 2 */}
					<AnimateInView>
						<div className="flex flex-col gap-y-2 w-full p-2 md:p-0 items-center md:items-start md:justify-start justify-center md:text-left text-center pt-2 px-4">
							<span className="py-2 flex mt-2 text-cyan-600">
								<ClipboardCheck size={40} />
							</span>
							<h4 className="text-xl text-slate-800 font-extrabold">80% Completed Forms</h4>
							<p className="text-md text-slate-600">Most patients successfully complete their diary. </p>
						</div>
					</AnimateInView>
				</div>
				{/* Group 2 */}
				<div className="flex flex-col md:flex-row gap-x-4">
					{/* Stat 3 */}
					<AnimateInView>
						<div className="flex flex-col gap-y-2 w-full p-2 md:p-0 items-center md:items-start md:justify-start justify-center md:text-left text-center pt-2 px-4">
							<span className="py-2 flex mt-2 text-cyan-600">
								<Star size={40} />
							</span>
							<h4 className="text-xl text-slate-800 font-extrabold">4.5/5 Recommend</h4>
							<p className="text-md text-slate-600">4.5 out of 5 patients would use Patient Watch again.</p>
						</div>
					</AnimateInView>

					{/* Stat 4 */}
					<AnimateInView>
						<div className="flex flex-col gap-y-2 w-full p-2 md:p-0 items-center md:items-start md:justify-start justify-center md:text-left text-center pt-2 px-4">
							<span className="py-2 flex mt-2 text-cyan-600">
								<Clock size={40} />
							</span>
							<h4 className="text-xl text-slate-800 font-extrabold">Free Doctor's Time</h4>
							<p className="text-md text-slate-600">Doctors save up to 2hrs per week for other practice.</p>
						</div>
					</AnimateInView>
				</div>
			</div>
		</Section>
	);
};

export default SocialProof;
