import { colors } from '@/assets/colors';
import type { EntryValueViewT, ExtendedDiaryViewT } from '@/types/database/diaries';
import type { ChartConfiguration, ChartDataset } from 'chart.js';

import { lineColors } from './chartColours';
import { extractTrackerData } from './extractGraphData';

export type LineSettingsT = Partial<ChartDataset<'line'>>;

export const defaultLineSettings: LineSettingsT = {
	fill: false,
	borderColor: colors['sky-300'],
	backgroundColor: colors['slate-50'],
	tension: 0.4,
	pointRadius: 5,
	pointBorderWidth: 2,
	type: 'line',
};

export const graphSettings = (index: number, lineSettings: LineSettingsT = {}): LineSettingsT => {
	return {
		...defaultLineSettings,
		borderColor: lineColors[index],
		...lineSettings,
	};
};

const extractSingleDataset = (data: EntryValueViewT[]) => {
	return data.map((ev) => ({
		x: ev.calculated_entry_time,
		y: ev.number_value,
	}));
};

const filterEntryValuesByInputType = (data: EntryValueViewT[], inputType: string) => {
	return data?.filter((ev) => ev.input_key === inputType);
};

function separateByInputId(entries: EntryValueViewT[]): EntryValueViewT[][] {
	return Object.values(
		entries.reduce(
			(result, entry) => {
				if (!result[entry.input_id]) {
					result[entry.input_id] = [];
				}
				result[entry.input_id].push(entry);
				return result;
			},
			{} as Record<string, EntryValueViewT[]>,
		),
	);
}

export function separateByTrackerId(entries: EntryValueViewT[]): EntryValueViewT[][] {
	const separated = Object.values(
		entries.reduce(
			(result, entry) => {
				if (!result[entry.tracker_id]) {
					result[entry.tracker_id] = [];
				}
				result[entry.tracker_id].push(entry);
				return result;
			},
			{} as Record<string, EntryValueViewT[]>,
		),
	);
	return separated;
}

export const extractAllDatasetsByInputType = (
	data: EntryValueViewT[],
	inputType: string,
	lineSettings?: LineSettingsT,
) => {
	const filtered = filterEntryValuesByInputType(data, inputType);
	const separated = separateByInputId(filtered);
	const allData: { label: string; data: { x: Date; y: number | null }[] }[] = [];
	separated.forEach((s, i) => {
		const xyData = extractSingleDataset(s);
		allData.push({
			label: s[0]?.input_label ?? '',
			data: xyData,
			...graphSettings(i, lineSettings),
		});
	});

	return { labels: separated.map((s) => s[0].label), datasets: allData };
};

export interface GenerateDataT {
	entryValues: EntryValueViewT[];
	inputType: string;
	trackers?: ExtendedDiaryViewT['trackers_view'];
	lineSettings?: LineSettingsT;
}

export const generateData = ({ entryValues, inputType, trackers, lineSettings }: GenerateDataT): ChartDataset[] => {
	let datasets: ChartConfiguration = { labels: [], datasets: [] };

	datasets = extractAllDatasetsByInputType(entryValues, inputType, lineSettings);

	if (!trackers?.length) {
		return datasets;
	}

	const trackerData = extractTrackerData(trackers);
	const trackerNames = trackers.map((t) => t.tracker_name);
	const labelsWithTrackers = datasets.labels.concat(trackerNames);
	const dataAndTrackers = datasets.datasets.concat(trackerData);
	return { labels: labelsWithTrackers, datasets: dataAndTrackers };
};
