import FlipCamera from '@/assets/icons/FlipCamera';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { useNavigate, useParams, useSearch } from '@tanstack/react-router';

export type FaceT = 'user' | 'environment';

const SelectFace = () => {
    const navigate = useNavigate();
    const { diaryId } = useParams({ strict: false });

    const { angle, side, delay } = useSearch({
        strict: false,
    });

    return (
        <div className="mx-auto w-full my-2 h-12">
            <Select
                defaultValue={angle}
                onValueChange={(value) =>
                    navigate({
                        to: '/foot-watch/$diaryId/new/camera',
                        params: { diaryId: diaryId },
                        search: { angle: value, side: side, delay: delay },
                    })
                }
            >
                <SelectTrigger className="w-full [&>span]:flex [&>span]:gap-x-2 [&>span]:flex-row [&>span]:items-center">
                    <SelectValue />
                </SelectTrigger>
                <SelectContent>
                    <SelectItem
                        className="w-full bg-white [&>*]:flex [&>*]:gap-x-2 [&>*]:flex-row [&>*]:items-center"
                        value="top"
                    >
                        <FlipCamera strokeWidth="2.5" stroke="black" className="h-8 w-8 px-1 lg:h-8 lg:w-8" />
                        Top
                    </SelectItem>
                    <SelectItem
                        className="flex bg-white [&>*]:flex [&>*]:gap-x-2 [&>*]:flex-row [&>*]:items-center"
                        value="sole"
                    >
                        <FlipCamera strokeWidth="2.5" stroke="black" className="h-8 w-8 px-1 lg:h-8 lg:w-8" />
                        Sole
                    </SelectItem>
                </SelectContent>
            </Select>
        </div>
    );
};

export default SelectFace;
