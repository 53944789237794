import { supabaseClient } from '@/lib/supabase';
import type { ProcessedScheduleT, ScheduleViewT } from '@/types/database/forms';
import { queryOptions, useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { toast } from 'sonner';
export async function processSchedule(schedule: ScheduleViewT, referenceDate: string) {
	const { data, error } = await supabaseClient.functions.invoke('public-process-schedule', {
		body: { schedule, referenceDate },
	});

	if (error) {
		const errorMessage = `Error processing schedule. ${error.message}`;
		console.error(errorMessage);
		toast.error(errorMessage);
		return [];
	}

	return data as ProcessedScheduleT[];
}

export const processScheduleQueryOptions = (schedule: ScheduleViewT, referenceDate: string) => {
	return queryOptions({
		queryKey: ['process-schedule', schedule.schedule_id, referenceDate],
		queryFn: () => processSchedule(schedule, referenceDate),
	});
};

export const processScheduleFromNowQueryOptions = (schedule: ScheduleViewT) => {
	const now = dayjs().toISOString();
	return queryOptions({
		queryKey: ['process-schedule', schedule.schedule_id, now],
		queryFn: () => processSchedule(schedule, now),
	});
};

export const useProcessScheduleQuery = (schedule: ScheduleViewT, referenceDate: string) => {
	return useQuery(processScheduleQueryOptions(schedule, referenceDate));
};

export const useProcessScheduleFromNowQuery = (schedule: ScheduleViewT) => {
	return useQuery(processScheduleFromNowQueryOptions(schedule));
};
