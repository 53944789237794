import { cn } from '@/lib/utils';
import { CheckCircle2 } from 'lucide-react';
import type React from 'react';

export type BulletColors = 'pink' | 'indigo' | 'amber';
interface Props {
	title: string;
	bullets: string[];
	icon?: React.ReactNode;
	color?: BulletColors;
}

const FeatureBullets = ({ title, bullets, icon, color }: Props) => {
	return (
		<div className="flex flex-col flex-grow gap-y-2 px-4 md:px-0">
			{icon && (
				<span
					className={cn(
						'w-fit h-fit p-2 md:p-4 rounded-md',
						color === 'pink' && 'bg-pink-100 text-pink-400',
						color === 'indigo' && 'bg-indigo-100 text-indigo-400',
						color === 'amber' && 'bg-amber-100 text-amber-400',
					)}
				>
					{icon}
				</span>
			)}
			<h4 className="font-semibold text-lg text-slate-900 dark:text-slate-100">{title}</h4>
			<ul className="flex flex-col gap-y-2">
				{bullets.map((bullet) => (
					<li
						key={bullet}
						className="inline-flex justify-start items-center gap-x-2 mt-2 text-md text-slate-700 dark:text-slate-200"
					>
						<span
							className={cn(
								color === 'pink' && ' text-pink-600',
								color === 'indigo' && ' text-indigo-600',
								color === 'amber' && ' text-amber-600',
							)}
						>
							<CheckCircle2 size={20} />
						</span>
						{bullet}
					</li>
				))}
			</ul>
		</div>
	);
};

export default FeatureBullets;
