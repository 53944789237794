import { components as c } from '@/components';

import EventForm from '@/features/events/components/EventForm';
import { z } from '@/utils/form';
import { createFileRoute } from '@tanstack/react-router';

const EventOptions = () => {
	const { trackerId } = Route.useSearch();
	const { patientId, organisationId, diaryId, eventId } = Route.useParams();

	return (
		<>
			<c.cc.Title words="Intervention" />
			<c.cc.Stepper
				steps={[
					{ stage: 'past', words: 'Patient' },
					{ stage: 'past', words: 'Diary' },
					{ stage: 'past', words: 'Form and Schedule' },
					{ stage: 'current', words: 'Intervention' },
				]}
			/>
			<EventForm
				patientId={patientId}
				organisationId={organisationId}
				diaryId={diaryId}
				eventId={eventId}
				trackerId={trackerId}
			/>
		</>
	);
};

const EventOptionsSearchSchemaZ = z.object({
	trackerId: z.string().uuid().optional(),
});

export const Route = createFileRoute(
	'/_app/new-diary/patient/$patientId/organisation/$organisationId/diary/$diaryId/event/$eventId/event-options',
)({
	validateSearch: (search) => EventOptionsSearchSchemaZ.parse(search),
	component: EventOptions,
});
