import { supabaseClient } from '@/lib/supabase';
import { queryOptions } from '@tanstack/react-query';

export async function selectTrackerTypes() {
	return await supabaseClient
		.from('tracker_types')
		.select('*')
		.throwOnError()
		.then((res) => res.data);
}

export const trackerTypesQueryOptions = () => {
	return queryOptions({
		queryKey: ['tracker-types'],
		queryFn: async () => await selectTrackerTypes(),
	});
};
