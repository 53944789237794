import { Button } from '@/components/ui/button';
import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from '@/components/ui/dialog';
import { useState } from 'react';

import { useUpdateInput } from '../../features/forms/api/upsertInput';
import { InputT } from '../../types/database/forms';
import { ui } from '../ui';

function AddOption(props: { input: InputT }) {
	const updateInput = useUpdateInput();
	const [newOption, setNewOption] = useState<string>('');
	return (
		<Dialog>
			<DialogTrigger asChild>
				<Button size="sm" variant="secondary">
					Add Option
				</Button>
			</DialogTrigger>
			<DialogContent className="sm:max-w-[425px]">
				<DialogHeader>
					<DialogTitle>Add Option</DialogTitle>
				</DialogHeader>
				<ui.Label className="">New Option</ui.Label>
				<ui.Input value={newOption} onChange={(e) => setNewOption(e.target.value)} />
				<DialogFooter>
					<DialogClose>
						<ui.Button
							size="default"
							onClick={() => {
								updateInput.mutate({ ...props.input, options: [...(props.input.options ?? []), newOption] });
								setNewOption('');
							}}
						>
							Add
						</ui.Button>
					</DialogClose>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
}

export default AddOption;
