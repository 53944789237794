import { supabaseClient } from '@/lib/supabase';
import type { EntryValue } from '@/types/database/diaries';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';

import { createGetDiaryKey } from '../../diary/api/getDiaryById';
import { useEntryValuesStore } from '../../inputs/hooks/useEntryValuesStore';

export async function upsertEntryValues(entryValues: EntryValue['Update'][]) {
	return await supabaseClient
		.from('entry_values')
		.upsert(entryValues)
		.select()
		.throwOnError()
		.then((res) => res.data);
}

export const useEntryValuesMutation = (diaryId?: string) => {
	const queryClient = useQueryClient();
	const entryValues = useEntryValuesStore();
	return useMutation({
		mutationKey: ['entry-values'],
		mutationFn: async (entryValues: EntryValue['Update'][]) => {
			return await upsertEntryValues(entryValues);
		},
		onError: () => {
			toast.error('Error creating diary log');
		},
		onSuccess: (data) => {
			entryValues.resetEntryValues();
			toast.info('Entry saved');
			return data;
		},
		onSettled: () => {
			if (diaryId) queryClient.invalidateQueries({ queryKey: createGetDiaryKey(diaryId) });
		},
	});
};
