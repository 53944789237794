import Star from '../form/Star';
import Dropdown from './Dropdown';
import Duration from './Duration';
import Email from './Email';
import Longtext from './Longtext';
import MultipleChoice from './MultipleChoice';
import NumberInput from './Number';
import Photo from './Photo';
import ScaleRating from './ScaleRating';
import SingleChoice from './SingleChoice';
import Slider from './Slider';
import YesNo from './YesNo';
import { customInputs as ci } from './custom';

export const inputs = {
	Email,
	Longtext,
	ScaleRating,
	Number: NumberInput,
	Dropdown,
	Slider,
	MultipleChoice,
	Photo,
	Duration,
	SingleChoice,
	YesNo,
	Star,
	ci,
};
