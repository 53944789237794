import { supabaseClient } from '@/lib/supabase';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'sonner';

import { useAuth } from '../../../utils/auth';

export type InjectionDiaryDefaultT = any;

export async function upsertInjectionDiaryDefault(req: InjectionDiaryDefaultT) {
	const { data, error } = await supabaseClient.from('injection_diary_defaults').upsert(req).select().single();
	if (error) throw error;
	return data;
}

export const useInjectionDiaryDefaultMutation = () => {
	const auth = useAuth();
	return useMutation({
		mutationKey: ['injection-diary-default', { user_id: auth.id }],
		mutationFn: async (data: InjectionDiaryDefaultT) => {
			return await upsertInjectionDiaryDefault(data);
		},
		onError: () => {
			toast.error('Error saving injection diary default');
		},
		onSuccess: () => {
			toast.success('Injection diary default saved');
		},
	});
};
