import { formComponents as fc } from '@/components/form';
import { ui } from '@/components/ui';

import type { SurgeryViewT } from '@/types/database/events';
import { type SubmitHandler, useForm, z, zodResolver } from '@/utils/form';
import { useClinicianOptions } from '../../../../auth/api/cliniciansByOrganisationQuery';
import { useDiaryByIdQuery } from '../../../../diary/api/getDiaryById';
import { useUpdateSurgeryMutation } from '../api/updateSurgery';

interface Props {
	diaryId: string;
	eventId: string;
	surgeryId: string;
	surgery?: SurgeryViewT;
	navigateToNextPage?: () => void;
}

const SurgerySchemaZ = z.object({
	referring_clinician_id: z.string().uuid().optional().nullable(),
	operating_clinician_id: z.string().uuid().optional().nullable(),
});
type SurgerySchemaT = z.infer<typeof SurgerySchemaZ>;

const SurgeryCliniciansForm = ({ surgery, diaryId, surgeryId, navigateToNextPage }: Props) => {
	const { data: diary } = useDiaryByIdQuery(diaryId);
	const surgeryMutation = useUpdateSurgeryMutation({ diaryId: diaryId, surgeryId: surgeryId });
	const clinicianOptions = useClinicianOptions(diary.organisation_id as string);

	const form = useForm<SurgerySchemaT>({
		resolver: zodResolver(SurgerySchemaZ),
		defaultValues: {
			referring_clinician_id: surgery?.referring_clinician_id ?? undefined,
			operating_clinician_id: surgery?.operating_clinician_id ?? undefined,
		},
	});

	const onSubmit: SubmitHandler<SurgerySchemaT> = async (formData) => {
		surgeryMutation.mutate(
			{ ...formData, surgery_id: surgeryId },
			{
				onSuccess: () => {
					!!navigateToNextPage && navigateToNextPage();
				},
			},
		);
	};

	return (
		<ui.Form {...form}>
			<form onSubmit={form.handleSubmit(onSubmit)} className="form">
				<fc.Select
					label="Referring Clinician"
					name="referring_clinician_id"
					description="The clinician who requests the surgery"
					options={clinicianOptions}
					required={true}
				/>
				<fc.Select
					autoFocus
					label="Operating Clinician"
					name="operating_clinician_id"
					description="The clinician who performs the surgery"
					options={clinicianOptions}
				/>
				<ui.Button size="lg" type="submit">
					Next
				</ui.Button>
			</form>
		</ui.Form>
	);
};

export default SurgeryCliniciansForm;
