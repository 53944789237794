import { useRouter } from "@tanstack/react-router";
import useSessionQuery from "../../auth/api/useSessionQuery";
import {Route as PreviewPatientReportRoute } from "@/routes/preview/patient/$patientId/diary/$diaryId";

export interface PatientReportUrlOptions {
  patientId: string;
  diaryId: string;
  search?: Record<string, string | undefined>;
}

 function usePatientReportUrl({
  patientId,
  diaryId,
  search = {},
}: PatientReportUrlOptions) {
  const { data: session } = useSessionQuery();
  const router = useRouter();

  const searchWithToken = {
    ...search,
    accessToken: session?.access_token,
    refreshToken: session?.refresh_token,
  };

  const location = router.buildLocation({
    to: PreviewPatientReportRoute.to,
    params: { patientId, diaryId },
    search: searchWithToken,
  });
  
  return new URL(location.href, window.location.origin).toString();
}

export default usePatientReportUrl 