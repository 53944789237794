import { ui } from '@/components/ui';
import { getManagements } from '@/definitions/managements';

interface Props {
	managementTypes: string[];
}
const ManagementTypeBadges = (props: Props) => {
	return (
		<ul>
			{props.managementTypes?.map((mt) => (
				<li key={mt}>
					<ui.Badge variant="outline" className="text-sm">
						{getManagements(24, 1.75).find((m) => m.path === mt)?.icon}
						{getManagements(24, 1.75).find((m) => m.path === mt)?.name}
					</ui.Badge>
				</li>
			))}
		</ul>
	);
};

export default ManagementTypeBadges;
