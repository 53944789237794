import EditButton from "@/components/common/EditButton";
import { useIsFetching } from "@tanstack/react-query";
import { Link } from "@tanstack/react-router";
import Spinner from "../../../../../components/common/Spinner";
import { createGetDiaryKey } from "../../../../diary/api/getDiaryById";
import { Copy } from "../../../../../components/common/Copy";
interface Props {
	diaryId: string;
	patientName: string;
	isEditing: boolean;
}
const DiaryTitle = ({ diaryId, patientName, isEditing }: Props) => {
	const isFetching = useIsFetching({ queryKey: createGetDiaryKey(diaryId) });

	return (
		<div className="flex flex-row justify-between items-center pl-1">
			<div className="flex justify-center items-center gap-x-2">
				<h2 className="text-lg text-slate-800 dark:text-slate-200">
					<Copy id={`copy-${patientName}`} value={patientName}>
						{patientName}
					</Copy>
				</h2>
				{isFetching ? <Spinner /> : <></>}
			</div>
			<EditButton>
				{isEditing
					? (
						<Link
							to="/dashboard/diary/$diaryId"
							params={{ diaryId }}
							search={(prev) => ({ ...prev })}
						>
							Done
						</Link>
					)
					: (
						<Link
							to="/dashboard/diary/$diaryId/edit"
							params={{ diaryId }}
							search={(prev) => ({ ...prev })}
						>
							Edit
						</Link>
					)}
			</EditButton>
		</div>
	);
};

export default DiaryTitle;
