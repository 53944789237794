interface Props {
    strokeWidth?: string;
    stroke?: string;
    backgroundColor?: string;
    rotate?: number;
}
const TriangleArrow = ({ strokeWidth, stroke, backgroundColor, rotate }: Props) => {
    const rotateAmount = `rotate-[${rotate}deg]`;
    return (
        <svg
            className={`${rotateAmount} w-full h-full`}
            width="70"
            height="70"
            viewBox="0 0 70 70"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="70" height="70" rx="35" fill={backgroundColor ? backgroundColor : '#D9D9D9'} />
            <path
                d="M27 18L48.0541 35.226C48.5431 35.6261 48.5431 36.3739 48.0541 36.774L27 54"
                stroke={stroke ? stroke : 'black'}
                strokeWidth={strokeWidth ? strokeWidth : '5'}
                strokeLinecap="round"
            />
        </svg>
    );
};

export default TriangleArrow;
