import type { ManipulateType } from 'dayjs';
import { z } from 'zod';

import type { Database } from '@/types/database/database.ts';

export type ScheduleT = Database['public']['Tables']['schedules']['Row'];
export type TimingT = Database['public']['Tables']['timings']['Row'];
export type TimeKeyT = Database['public']['Enums']['time_keys'];
export type ScheduleViewT = ScheduleT & { timings: TimingT[] };

const second = 1000;
const minute = 1000 * 60;
const hour = minute * 60;
const day = hour * 24;
const week = day * 7;
const month = day * 30;

export type { ManipulateType };

export const timeUnits = {
	second: second,
	minute: minute,
	hour: hour,
	day: day,
	week: week,
	month: month,
};

export const TimeUnitZ = z.enum(timeUnits);

// export type TimeUnitT = z.infer<typeof TimeUnitZ>;

export const TimingZ = z.object({
	time_unit: TimeUnitZ,
	interval: z.number().positive(),
	timing_id: z.string(),
	repeats: z.number().positive(),
	label: z.string(),
	is_infinite: z.boolean(),
	is_autoname: z.boolean(),
});

export type EntryT = Partial<TimingT> & { timestamp?: number };
