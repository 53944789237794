import IconDetail from '@/components/common/IconDetail';
import { ui } from '@/components/ui';
import type { ExtendedEventViewT } from '@/types/database/events';
import type { SizeT } from '@/types/zod/common';
import { Cross } from 'lucide-react';
import { buttonVariants } from '../../../components/ui/button';
import { cn } from '../../../lib/utils';
import { useAuth } from '../../../utils/auth';
import HistoryCard from '../../diary/history/HistoryCard';
import HistoryCardTitle from '../../diary/history/HistoryCardTitle';
import InjectionDialog from '../../injection/components/InjectionDialog';
import SurgeryDialog from '../features/surgeries/components/SurgeryDialog';
import SelectEventForm from './SelectEventForm';

interface Props {
	event: ExtendedEventViewT;
	trackerId?: string;
	size?: SizeT;
	showCard?: boolean;
	addable?: boolean;
}
const EventCard = ({ event, trackerId, size, addable = true, showCard = true }: Props) => {
	const auth = useAuth();
	const headerContent = (
		<HistoryCardTitle
			hideDate={false}
			title={<IconDetail icon={<Cross />} words={event.event_name ?? 'Intervention'} />}
			dateTime={event.event_time}
		/>
	);

	const bodyContent = (
		<>
			{event.injections_view?.map((i) => (
				<InjectionDialog
					trackerId={trackerId}
					key={i.injection_id}
					organisationId={event.organisation_id as string}
					injection={i}
					injectionId={i.injection_id}
					eventId={event.event_id}
					diaryId={event.diary_id}
				/>
			))}
			{event.surgeries_view?.map((s) => (
				<SurgeryDialog
					trackerId={trackerId}
					key={s.surgery_id}
					organisationId={event.organisation_id as string}
					surgery={s}
					surgeryId={s.surgery_id}
					eventId={event.event_id}
					diaryId={event.diary_id}
				/>
			))}
		</>
	);

	const footerContent = auth?.roles?.isPatientOnly ? (
		<></>
	) : (
		<ui.Dialog>
			<ui.DialogTrigger className={cn(buttonVariants({ size: 'sm', variant: 'link' }), 'mx-auto')}>
				Add Intervention
			</ui.DialogTrigger>
			<ui.DialogContent>
				<SelectEventForm
					eventId={event.event_id}
					diaryId={event.diary_id}
					organisationId={event.organisation_id as string}
				/>
			</ui.DialogContent>
		</ui.Dialog>
	);

	return (
		<HistoryCard
			size={size}
			className="border-sky-300 bg-sky-100"
			showCard={showCard}
			headerContent={headerContent}
			bodyContent={bodyContent}
			footerContent={addable ? footerContent : undefined}
		/>
	);
};

export default EventCard;
