import { commonComponents as cc } from '@/components/common';
import { injectionApi as ia } from '@/features/injection/api';
import { injectionComponents as ic } from '@/features/injection/components';
import useExtractInjection from '@/features/injection/hooks/useExtractInjection';
import useExtractInjectionDetail from '@/features/injection/hooks/useExtractInjectionDetail';
import { createFileRoute } from '@tanstack/react-router';

const InjectionDetail = () => {
	const { diaryId, injectionDetailId, injectionId, trackerId } = Route.useParams();
	const { injectionLocations, injectionContents } = Route.useLoaderData();
	const injection = useExtractInjection(diaryId, injectionId);

	const injectionDetail = useExtractInjectionDetail(diaryId, injectionId, injectionDetailId);

	return (
		<>
			<cc.Stepper
				steps={[
					{ stage: 'past', words: 'Patient' },
					{ stage: 'past', words: 'Diary' },
					{ stage: 'past', words: 'Tracker' },
					{
						stage: 'current',
						words: 'Event',
						substeps: [
							{
								stage: 'past',
								words: 'Who & When',
							},
							{
								stage: 'current',
								words: 'Where & What',
							},
						],
					},
				]}
			/>
			<ic.InjectionDetailsForm
				diaryId={diaryId}
				trackerId={trackerId}
				injectionId={injectionId}
				injectionDetailId={injectionDetailId}
				injectionLocations={injectionLocations!}
				injectionContents={injectionContents!}
				injection={injection!}
				injectionDetail={injectionDetail!}
			/>
		</>
	);
};
export const Route = createFileRoute(
	'/_app/new-diary/patient/$patientId/organisation/$organisationId/diary/$diaryId/event/$eventId/injection/$injectionId/injection-detail/$injectionDetailId',
)({
	component: InjectionDetail,
	loader: async ({ context, params }) => {
		const injection = await context?.queryClient.ensureQueryData(ia.injectionQueryOptions(params.injectionId));
		const injectionLocations = await context?.queryClient.ensureQueryData(ia.injectionLocationsQueryOptions());
		const injectionContents = await context?.queryClient.ensureQueryData(ia.injectionContentsQueryOptions());

		return { injectionLocations, injectionContents, injection };
	},
});
