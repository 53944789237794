import { columns } from '@/features/dashboard/features/overview/table/components/Columns';
import type { DashboardSearchT, PaginationT } from '@/routes/_app/dashboard';
import type { StatefulDiaryT } from '@/types/database/diaries';
import { useNavigate } from '@tanstack/react-router';
import {
	type ColumnFiltersState,
	type SortingState,
	type VisibilityState,
	getCoreRowModel,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	useReactTable,
} from '@tanstack/react-table';
import * as React from 'react';

interface Props {
	diaries: StatefulDiaryT[];
	pageCount: number;
	pagination: PaginationT; // Ensure this prop is passed correctly
}
function useDiariesTable({ diaries, pagination, pageCount }: Props) {
	// @ts-ignore
	const navigate = useNavigate();

	const [sorting, setSorting] = React.useState<SortingState>([]);
	const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
	const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>({});
	const table = useReactTable({
		data: diaries,
		columns,
		onSortingChange: setSorting,
		onColumnFiltersChange: setColumnFilters,
		getCoreRowModel: getCoreRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		onColumnVisibilityChange: setColumnVisibility,
		state: {
			sorting,
			columnFilters,
			pagination: pagination,
			columnVisibility,
		},
		pageCount: pageCount,
		manualPagination: true,
	});

	const setPageIndex = (pageIndex: number) => {
		navigate({
			search: (prev: DashboardSearchT) =>
				({
					...prev,
					pagination: {
						...prev.pagination,
						pageIndex: pageIndex,
					},
				}) as DashboardSearchT,
		});
	};

	const nextPage = () => {
		const currentPage = table.getState().pagination.pageIndex;
		setPageIndex(currentPage + 1);
		table.nextPage();
	};

	const prevPage = () => {
		const currentPage = table.getState().pagination.pageIndex;
		setPageIndex(currentPage - 1);
		table.previousPage();
	};

	React.useEffect(() => {
		navigate({ search: (prev) => ({ ...prev, sorting: table.getState().sorting }) });
	}, [table.getState, navigate]);

	const [isFinalPage, setIsFinalPage] = React.useState<boolean>(false);

	React.useEffect(() => {
		const totalPages = Math.ceil(pageCount / pagination.pageSize);
		const pageIndex = pagination.pageIndex;
		// Minus one from total pages as index starts at 0
		setIsFinalPage(pageIndex >= totalPages - 1);
	}, [pageCount, pagination]);

	return { table, prevPage, nextPage, isFinalPage };
}

export default useDiariesTable;
