import type { ExtendedEventViewT } from '@/types/database/events';

import EventSwitch from './EventSwitch';

interface Props {
	event: ExtendedEventViewT;
}

const EventSwitches = ({ event }: Props) => {
	return (
		<ul className="mb-2">
			{event.required_events?.map((mt) => (
				<li key={mt}>
					<EventSwitch event={event} eventType={mt} />
				</li>
			))}
		</ul>
	);
};

export default EventSwitches;
