import { icons } from '@/assets';

const ProductTitle = () => {
    return (
        <section className="section-content landing">
            <div className="img-row title">
                <icons.Dashboard />
                <h2>Our Product</h2>
            </div>
        </section>
    );
};

export default ProductTitle;
