import { formComponents as fc } from "@/components/form";
import { ui } from "@/components/ui";
import usePasswordSignupMutation from "@/features/auth/hooks/usePasswordSignupMutation";
import {
  type SignupSchemaType,
  signupSchema,
} from "@/features/auth/types/signupSchema";
import type { SignupSearch } from "@/routes/_auth/signup";
import { zodResolver } from "@hookform/resolvers/zod";
import { type SubmitHandler, useForm } from "react-hook-form";
import ConsentCheckbox from "./ConsentCheckbox";

interface Props {
  search: SignupSearch;
  signupFunction: (arg?: SignupSchemaType) => void;
  buttonWords?: string;
}

const SignupForm = ({ search, buttonWords, signupFunction }: Props) => {
  const form = useForm<SignupSchemaType>({
    resolver: zodResolver(signupSchema),
    defaultValues: {
      email: search.email ?? "",
      password: "",
      metadata: search,
    },
  });

  const signupMutation = usePasswordSignupMutation();

  const onSubmit: SubmitHandler<SignupSchemaType> = async (formData) => {
    await signupFunction(formData);
  };

  return (
    <ui.Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="form">
        <fc.Email autoFocus={true} tabIndex={0} />

        <fc.Password
          showForgotPassword={false}
          tabIndex={0}
          showPassword={true}
          showRequirements={true}
        />

        {/* Terms and Conditions */}
        <ConsentCheckbox />

        <ui.Button
          tabIndex={0}
          className="!w-full"
          size="lg"
          type="submit"
          disabled={signupMutation.isPending}
        >
          {buttonWords ?? "Next"}
        </ui.Button>
      </form>
    </ui.Form>
  );
};

export default SignupForm;
