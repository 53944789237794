import PatientWatchTM from '../components/common/PatientWatchTM';
import type { BreadcrumbItemT } from '../features/navigation/components/Breadcrumb';
import type { RouteT } from '../types/zod/routes';

export type NavT = {
	backRoute: BreadcrumbItemT;
	breadcrumbRoutes: BreadcrumbItemT[];
	setNav: (items: BreadcrumbItemT[]) => void;
	reset: () => void;
};

export function getPenultimateEntry(arr: BreadcrumbItemT[]): BreadcrumbItemT {
	const homeCrumb = { label: 'Home', route: { to: '/', params: {}, search: {} } } as BreadcrumbItemT;
	try {
		if (!arr.length) {
			return homeCrumb;
		}
		if (arr.length < 2) {
			return arr[0] as BreadcrumbItemT;
		}
		return arr[arr.length - 2] as BreadcrumbItemT;
	} catch (e) {
		console.error(e);
		return homeCrumb;
	}
}

export const homeRoute = {
	label: <PatientWatchTM />,
	route: { to: '/' as RouteT, params: {}, search: {} },
};

export const nav: NavT = {
	backRoute: homeRoute,
	breadcrumbRoutes: [homeRoute],
	setNav: (items: BreadcrumbItemT[]) => {
		nav.breadcrumbRoutes = items;
		nav.backRoute = getPenultimateEntry(items);
	},
	reset: () => {
		nav.breadcrumbRoutes = [homeRoute];
		nav.backRoute = homeRoute;
	},
};

import React from 'react';

export const NavContext = React.createContext<NavT>({
	backRoute: homeRoute,
	breadcrumbRoutes: [homeRoute],
	setNav: (items: BreadcrumbItemT[]) => {},
	reset: () => {},
});

export function NavProvider({ children }: { children: React.ReactNode }) {
	const [backRoute, setBackRoute] = React.useState<BreadcrumbItemT>(homeRoute);
	const [breadcrumbRoutes, setBreadcrumbRoutes] = React.useState<BreadcrumbItemT[]>([homeRoute]);

	const setNav = React.useCallback((items: BreadcrumbItemT[]) => {
		setBreadcrumbRoutes(items);
		setBackRoute(getPenultimateEntry(items));
	}, []);

	const reset = React.useCallback(() => {
		setBreadcrumbRoutes([homeRoute]);
		setBackRoute(homeRoute);
	}, []);

	return <NavContext.Provider value={{ backRoute, breadcrumbRoutes, setNav, reset }}>{children}</NavContext.Provider>;
}

export function useNav(path?: BreadcrumbItemT[]) {
	const context = React.useContext(NavContext);

	if (!context) {
		throw new Error('useNav must be used within a NavProvider');
	}

	const updateNav = () => {
		if (path) {
			context.setNav(path);
		} else {
			context.reset();
		}
	};
	React.useEffect(() => {
		updateNav();
	}, []);

	return context;
}
