import Cedar from './assets/cedar_transparent.png';
import Imperial from './assets/imperial_logo.b9b7f9af.png';
import Surrey from './assets/NHS.png';
import HJE from './assets/st-johns-and-elizabeths.png';
import ImageReel from './ImageReel';

const partners = [
    { alt: 'Imperial Logo', src: Imperial },
    { alt: 'Cedar Center Logo', src: Cedar },
    { alt: "St John and St Elizabeth's Logo", src: HJE },
    { alt: 'Royal Surrey NHS Trust Logo', src: Surrey },
];

const PartnersReel = () => {
    return <ImageReel items={partners} />;
};

export default PartnersReel;
