import type { EntryValueViewT } from '@/types/database/diaries';

interface Props {
	entryValue: EntryValueViewT;
}

const SimpleEntryValueSwitch = ({ entryValue }: Props) => {
	if (!entryValue.value) return null;

	switch (entryValue.input_key) {
		case 'multiple-choice':
			return (
				<ul>
					{entryValue?.text_array?.map((item) => (
						<li key={item}>{item}</li>
					))}
				</ul>
			);
		case 'photo':
			return <img src={entryValue?.object_id ?? undefined} alt="Photo" />;
		case 'foot-photos':
			return <p>FOOT PHOTOS</p>;
		case 'duration':
			return (
				<p>
					{String(entryValue.number_value)} {entryValue.duration_time_unit}
				</p>
			);
		case 'yes-no':
			return <p className="capitalize">{String(entryValue.value)}</p>;
		default:
			return <p>{String(entryValue.value)}</p>;
	}
};

export default SimpleEntryValueSwitch;
