import { FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { z } from 'zod';

import { ui } from '../../../../components/ui';
import useOrganisationsQuery from './useOrganisationsQuery';

export const OrganisationCheckboxesSchema = z.array(z.string()).refine((value) => value.some((item) => item), {
    message: 'You have to select at least one item.',
});

interface Props {
    form: any;
    name: string;
}
const OrganisationCheckboxes = ({ name, form }: Props) => {
    const { data: organisations, isLoading } = useOrganisationsQuery();

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <FormField
            control={form.control}
            name={name}
            render={() => (
                <FormItem>
                    <div className="mb-4">
                        <FormLabel className="">Organisations</FormLabel>
                        <FormDescription>Select the organisations you want to share your diary with.</FormDescription>
                    </div>
                    {organisations?.map((organisation) => (
                        <FormField
                            key={organisation.organisation_id}
                            control={form.control}
                            name={name}
                            render={({ field }) => {
                                return (
                                    <FormItem className="flex flex-row items-center justify-start p-4 bg-white border border-slate-300 rounded-md space-x-3 space-y-0">
                                        <FormControl>
                                            <ui.Checkbox
                                                key={organisation.organisation_id}
                                                checked={field.value?.includes(organisation.organisation_id)}
                                                onCheckedChange={(checked) => {
                                                    return checked
                                                        ? field.onChange([...field.value, organisation.organisation_id])
                                                        : field.onChange(
                                                              field.value?.filter(
                                                                  (value: string) =>
                                                                      value !== organisation.organisation_id,
                                                              ),
                                                          );
                                                }}
                                            />
                                        </FormControl>
                                        <FormLabel className="text-sm font-normal">
                                            {organisation.organisation_name}
                                        </FormLabel>
                                    </FormItem>
                                );
                            }}
                        />
                    ))}
                    <FormMessage />
                </FormItem>
            )}
        />
    );
};

export { OrganisationCheckboxes };
