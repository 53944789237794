import { useFormContext } from 'react-hook-form';

import { ui } from '../ui';

interface Props {
	name?: string;
	label: string;
	disabled?: boolean;
	type: 'email' | 'text';
}

const Recipient: React.FC<Props> = ({ name, type, disabled, label }) => {
	const { control } = useFormContext();
	return (
		<ui.FormField
			control={control}
			name={name ?? 'email'}
			data-cy={name}
			render={({ field }) => (
				<ui.FormItem className="flex items-center space-y-0">
					<ui.FormLabel className="w-24 text-sm">{label}</ui.FormLabel>
					<ui.FormControl>
						<ui.Input
							className="h-11 bg-white text-sm"
							disabled={disabled === undefined ? true : disabled}
							type={type ?? 'email'}
							{...field}
						/>
					</ui.FormControl>
					<ui.FormMessage />
				</ui.FormItem>
			)}
		/>
	);
};

export default Recipient;
