import { z } from "zod";

import { Database } from "../database/database";
import { ExtendedFavouriteDiaryT } from "../database/diaries";

export type UserT = Database["public"]["Tables"]["users"]["Row"];
export type UsersOrganisationsRolesT =
  Database["public"]["Tables"]["users_organisations_roles"]["Row"];
export type PatientT = Database["public"]["Tables"]["patients"]["Row"];
export type OrganisationT =
  Database["public"]["Tables"]["organisations"]["Row"];
export type ProfileT = Database["public"]["Views"]["profiles"]["Row"];
export type ClinicianT = Database["public"]["Tables"]["clinicians"]["Row"];
export type ExtendedPatientT = PatientT & { profiles: ProfileT };

export type ExtendedProfileT = ProfileT & {
  favourite_diary_templates: ExtendedFavouriteDiaryT[];
  users_organisations_roles: UsersOrganisationsRolesT[];
};

export const TitleZ = z.enum(["Mr", "Mrs", "Ms", "Miss", "Dr", "Prof"]);
export type TitleT = z.infer<typeof TitleZ>;

export const FirstNameZ = z.string().min(1, "First Name is required");
export type FirstNameT = z.infer<typeof FirstNameZ>;

export const LastNameZ = z.string().min(1, "Last Name is required");
export type LastNameT = z.infer<typeof LastNameZ>;
