import { diaryByIdQueryOptions, useDiaryByIdQuery } from '@/features/diary/api/getDiaryById';
import NoHistory from '@/features/diary/components/NoHistory';
import History from '@/features/diary/history/History';
import { collateDiaryHistory } from '@/features/diary/services/collateDiaryHistory';
import { hooks as h } from '@/hooks';
import { createFileRoute } from '@tanstack/react-router';

const HistoryPage = () => {
	const { diaryId } = Route.useParams();
	const { data: diary } = useDiaryByIdQuery(diaryId);
	h.useNav([
		{ label: 'Diaries', route: { to: '/diaries' } },
		{
			route: { to: '/diary/$diaryId/home/history' },
			label: 'History',
			options: [
				{ route: { to: '/diary/$diaryId/home/new' }, label: 'New' },
				{ route: { to: '/diary/$diaryId/home/history' }, label: 'History' },
				{ route: { to: '/diary/$diaryId/home/charts' }, label: 'Charts' },
			],
		},
	]);

	return (
		<>
			<NoHistory diary={diary} />
			<History size="sm" editable={true} diaryId={diaryId} diaryHistory={collateDiaryHistory(diary)} />
		</>
	);
};

export const Route = createFileRoute('/_app/diary/$diaryId/home/history')({
	component: HistoryPage,
	loader: async ({ context, params }) => {
		await context?.queryClient.ensureQueryData(diaryByIdQueryOptions(params.diaryId));
	},
});
