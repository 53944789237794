import { TimerT } from '@/features/foot-watch/components/camera';
import Camera from '@/features/foot-watch/components/camera/Camera';
import useNextFootWatchPage from '@/features/foot-watch/hooks/useNextFootWatchPage';
import { VariationsType } from '@/features/foot-watch/services/variations';
import { capitaliseSentence } from '@/services/capitaliseSentence';
import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

export const CameraRoute = () => {
    const { angle, side, delay } = Route.useSearch();
    const { diaryId } = Route.useParams();
    const { variation, label } = Route.useRouteContext();

    const { navigateNext } = useNextFootWatchPage(diaryId, 'camera', angle, side);

    return (
        <Camera
            nextPage={navigateNext}
            variation={variation}
            side={side}
            angle={angle}
            label={label}
            delay={delay as TimerT}
        />
    );
};

export const AngleZ = z.enum(['top', 'sole']).catch('top');

const cameraSearchSchema = z.object({
    side: z.enum(['left', 'right']).catch('left'),
    angle: AngleZ,
    delay: z.number().min(0).max(10).catch(0),
});

export const Route = createFileRoute('/_app/foot-watch/$diaryId/new/camera')({
    component: CameraRoute,
    validateSearch: cameraSearchSchema,
    beforeLoad: ({ search }) => {
        const label: string = capitaliseSentence(search.side + ' ' + search.angle);
        const variation = (search.side[0].toUpperCase() + search.angle[0].toUpperCase()) as VariationsType;
        return {
            side: search.side,
            angle: search.angle,
            delay: search.delay,
            variation: variation,
            label: label,
        };
    },
});
