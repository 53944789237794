const Adjust = () => {
    return (
        <svg
            className="w-full h-full"
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M11 17H39" stroke="black" strokeWidth="2.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M11 25H39" stroke="black" strokeWidth="2.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M11 33H39" stroke="black" strokeWidth="2.25" strokeLinecap="round" strokeLinejoin="round" />
            <circle cx="20" cy="17" r="3" fill="white" stroke="black" strokeWidth="1.5" />
            <circle cx="31" cy="25" r="3" fill="white" stroke="black" strokeWidth="1.5" />
            <circle cx="21" cy="33" r="3" fill="white" stroke="black" strokeWidth="1.5" />
        </svg>
    );
};

export default Adjust;
