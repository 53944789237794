import { supabaseClient } from '@/lib/supabase';
import { queryOptions, useQuery } from '@tanstack/react-query';

export async function selectInjection(injectionId: string) {
	return await supabaseClient
		.from('injections_view')
		.select('*, injection_details_view(*)')
		.eq('injection_id', injectionId)
		.throwOnError()
		.maybeSingle()
		.then((result) => result.data);
}

export const injectionQueryKey = (injectionId: string) => ['injection', { injection_id: injectionId }];

export const injectionQueryOptions = (injectionId: string) => {
	return queryOptions({
		queryKey: injectionQueryKey(injectionId),
		queryFn: () => selectInjection(injectionId),
	});
};

export const useInjectionQuery = (injectionId: string) => {
	const options = injectionQueryOptions(injectionId);
	const query = useQuery(options);
	return query;
};
