import { createFileRoute } from '@tanstack/react-router';

interface Props {
    progress: number;
}
const Uploading = ({ progress }: Props) => {
    return (
        <div
            className={`h-[40vh] w-[40vh] mt-12 flex top-0 left-0 border-[20px] rotate-45 items-center justify-center  font-semibold rounded-full z-50 mx-auto transition duration-400 ease-in
${progress >= 1 && 'border-t-indigo-600'}
${progress >= 2 && 'border-r-indigo-600'}
${progress >= 3 && 'border-b-indigo-600'}
${progress >= 4 && 'border-indigo-600'}`}
        >
            <p className="-rotate-45 text-indigo-600 text-6xl">{progress * 25}%</p>
        </div>
    );
    createFileRoute;
};
export const Route = createFileRoute('/_app/diary/$diaryId/new/tracker/$trackerId/entry/$entryId/page/$pageIndex/foot-photos/upload/uploading')({
    component: Uploading,
});
