import { commonComponents as cc } from '@/components/common';
import exampleFeet from '@/features/foot-watch/assets/example-4-photos.png';
import { createFileRoute, useNavigate } from '@tanstack/react-router';

const StartFootCheck = () => {
	const { diaryId } = Route.useParams();
	const navigate = useNavigate();
	return (
		<>
			<cc.Title
				words="Add New Photos"
				description="Follow the instructions to take photos of the top and bottom of each foot."
			/>

			<div className="mx-auto w-full flex-col px-2">
				<div className="mb-8 flex w-full items-center justify-center lg:mb-0">
					<img src={exampleFeet} alt="Example Check-in Photos" width="400" height="600" />
				</div>

				<cc.FooterButtons
					func2={() => navigate({ to: '../add-photos', params: { diaryId: diaryId } })}
					words2="Upload"
					func1={() =>
						navigate({
							to: '../instructions/$angle/$side',
							params: { diaryId: diaryId, side: 'left', angle: 'top' },
						})
					}
					words1="Use Camera"
				/>
			</div>
		</>
	);
};

export default StartFootCheck;

export const Route = createFileRoute(
	'/_app/diary/$diaryId/new/tracker/$trackerId/entry/$entryId/page/$pageIndex/foot-photos/start',
)({
	component: StartFootCheck,
});
