import { useEntryValuesStore } from '@/features/inputs/hooks/useEntryValuesStore';
import type { InputT } from '@/types/database/forms';

import { cn } from '../../lib/utils';
import { ui } from '../ui';

interface Props {
	input: InputT;
}
const ScaleRating = ({ input }: Props) => {
	const entryValue = useEntryValuesStore((state) => state.getEntryValueByInputId(input.input_id));
	const upsertEntryValue = useEntryValuesStore((state) => state.upsertEntryValue);

	const handleOptionChange = (value: number) => {
		upsertEntryValue({
			...entryValue,
			input_id: input.input_id,
			number_value: value,
		});
	};

	return (
		<ui.Card>
			<ui.CardHeader>
				<ui.Label>{input.input_label}</ui.Label>
			</ui.CardHeader>
			<ui.CardContent>
				<ul className="flex flex-row overflow-x-scroll">
					{input.scale_options?.map((option) => (
						<li key={`scale-rating-${option.value}`} className="flex flex-col">
							<button
								type="button"
								key={option.value}
								onClick={() => handleOptionChange(option.value)}
								className={cn(
									'm-1 rounded-md px-4 py-2 text-lg text-white transition-all duration-300 ease-in-out',
									entryValue?.number_value === option.value ? 'bg-indigo-600' : 'bg-slate-300',
								)}
							>
								{option.value}
							</button>
							<p className="ml-1 w-8 text-center text-slate-600 overflow-x-show">{option.label}</p>
						</li>
					))}
				</ul>
			</ui.CardContent>
		</ui.Card>
	);
};

export default ScaleRating;
