import Title from '@/components/common/Title';
import { Form } from '@/components/ui/form';
import DubQrCode from '@/features/dub/components/DubQrCode';
import SelectRole from '@/features/invite/components/SelectRole';
import SelectFromMyOrganisationIds from '@/features/organisations/components/SelectFromMyOrganisationIds';
import useOrganisationQuery from '@/features/profile/api/useOrganisationQuery';
import { createFileRoute } from '@tanstack/react-router';
import { useRouter } from '@tanstack/react-router';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

const OrganisationQuickstartQrCodePage = () => {
	const { organisationId } = Route.useParams();
	const [url, setUrl] = useState<string>('/signup');

	const form = useForm({ defaultValues: { role: 'PATIENT', organisation_id: organisationId } });

	const role = form.watch('role');

	const router = useRouter();

	useEffect(() => {
		const newUrl = router.buildLocation({
			to: '/signup',
			search: { role: role.toUpperCase(), organisation: { organisationId: organisationId } },
		});
		setUrl(newUrl.href);
	}, [role, organisationId]);

	return (
		<section className="md-width">
			<Title words="Invite with QR Code" />
			<Form {...form}>
				<form className="space-y-4 mb-8">
					<SelectRole />
					<SelectFromMyOrganisationIds />
				</form>
			</Form>
			<DubQrCode url={window.location.origin + url} />
		</section>
	);
};

export const Route = createFileRoute('/_app/organisation/$organisationId/qr-code')({
	component: OrganisationQuickstartQrCodePage,
});
