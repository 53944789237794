import { ActionKeyT } from '@/types/database/actions';
import { Bell, Calendar, Check, Flag, Hammer, Mail, Rewind, Trash } from 'lucide-react';

export type ActionsT = {
	[K in ActionKeyT]: React.ReactNode;
};

export const ActionIcon: ActionsT = {
	REINSTATE: <Rewind size={20} strokeWidth={1.5} />,
	REMIND: <Bell size={20} strokeWidth={1.5} />,
	BOOK_FOLLOW_UP: <Calendar size={20} strokeWidth={1.5} />,
	BOOK_PROCEDURE: <Hammer size={20} strokeWidth={1.5} />,
	DISCHARGE: <Flag size={20} strokeWidth={1.5} />,
	DISCARD: <Trash size={20} strokeWidth={1.5} />,
	CUSTOM: <Mail size={20} strokeWidth={1.5} />,
	MARK_READ: <Check size={20} strokeWidth={1.5} />,
};
