import { formComponents as fc } from '@/components/form';
import { ui } from '@/components/ui';
import { buttonVariants } from '@/components/ui/button';
import { interfaceComponents as ic } from '@/features/dashboard/features/interface/components';
import { cn } from '@/lib/utils';
import type { ActionDefinitionT } from '@/types/database/actions';
import type { StateKeyT, StatefulDiaryT } from '@/types/database/diaries';
import type { ProfileT } from '@/types/database/profiles';
import { Link } from '@tanstack/react-router';
import { ArrowLeft, X } from 'lucide-react';
import { Send } from 'lucide-react';
import { toast } from 'sonner';

import type { DashboardSearchT } from '@/routes/_app/dashboard';

import useTakeAction from '../api/useTakeAction';
import useActionEmailHook from '../hooks/useActionEmailHook';

export interface Props {
	actionId: string;
	action: ActionDefinitionT;
	state: StateKeyT;
	diary: StatefulDiaryT;
	profile: ProfileT;
}

const ActionEmail = (props: Props) => {
	const takeAction = useTakeAction();

	const { form, onSubmit } = useActionEmailHook(props);

	const emailOptions = props.diary.diary_access?.map((sw) => ({ value: sw.email, label: sw.email }));

	return (
		<section className="mt-2 lg:pl-2">
			<ui.Form {...form}>
				<form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4 text-lg">
					<div className="flex justify-between items-center gap-1 my-2 w-full">
						<Link
							className={cn(buttonVariants({ size: 'sm', variant: 'outline' }), 'rounded-md px-2')}
							to="/dashboard/diary/$diaryId"
							params={{ diaryId: props.diary.diary_id }}
							search={(prev) => ({ ...prev }) as DashboardSearchT}
						>
							<ArrowLeft />
							Back
						</Link>
						<ic.ActionTitle action={props.action} />
						<ui.Button
							disabled={takeAction.isPending}
							onClick={() => {
								if (Object.keys(form.formState.errors).length) {
									toast.error('Please fill in all fields');
								}
							}}
							size="sm"
							type="submit"
						>
							<Send />
							Send
						</ui.Button>
					</div>
					<div>
						<fc.Recipient type="email" name="from_email" label="From" />
						<hr className="border-slate-300 my-1" />
						<div className="flex justify-between items-center gap-1">
							<fc.SelectRecipient options={emailOptions} name="cc_email" label="Cc" />
							<ui.Button
								type="button"
								onClick={() => form.setValue('cc_email', '')}
								variant="ghost"
								size="icon"
								className="rounded-sm"
							>
								<X />
							</ui.Button>
						</div>
						<hr className="border-slate-300 my-1" />
						<div className="flex justify-between items-center gap-1">
							<fc.Recipient type="text" disabled={true} name="to_email" label="To" />
							<ui.Button
								type="button"
								onClick={() => form.setValue('to_email', '')}
								variant="ghost"
								size="icon"
								className="rounded-sm"
							>
								<X />
							</ui.Button>
						</div>
						<hr className="border-slate-300 my-1" />
						<fc.Recipient type="text" disabled={false} name="subject" label="Subject" />
					</div>

					<fc.Textarea name="body" placeholder="Email Body" className="h-full min-h-[400px]" />
				</form>
			</ui.Form>
		</section>
	);
};

export default ActionEmail;
