import { ui } from "@/components/ui";
import { useEntryValuesMutation } from "@/features/entries/api/upsertEntryValues";
import useExtractEntryValue from "@/features/entries/hooks/useExtractEntryValue";
import InputSwitch from "@/features/inputs/components/InputSwitch";
import { createDefaultValues } from "@/features/pages/utils/createDefaultValues";
import { createValidationSchema } from "@/features/pages/utils/createValidationSchema";
import { formatEntryValues } from "@/features/pages/utils/formatEntryValues";
import { Route as EditRoute } from "@/routes/_app/diary/$diaryId/edit/tracker/$trackerId/entry/$entryId";
import type { InputT } from "@/types/database/forms";
import { type SubmitHandler, useForm, type z, zodResolver } from "@/utils/form";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import type { EntryValue } from "@/types/database/diaries";
const EditEntryValue = () => {
  const { diaryId, trackerId, entryId, entryValueId } = Route.useParams();
  const navigate = useNavigate();
  const entryValue = useExtractEntryValue(
    diaryId,
    trackerId,
    entryId,
    entryValueId,
  );
  const upsertEntryValue = useEntryValuesMutation(diaryId);

  const SchemaZ = createValidationSchema([entryValue.inputs as InputT]);
  type SchemaT = z.infer<typeof SchemaZ>;
  const defaults = createDefaultValues([entryValue.inputs as InputT]);
  const form = useForm<z.infer<typeof SchemaZ>>({
    resolver: zodResolver(SchemaZ),
    defaultValues: defaults,
  });

  const onSubmit: SubmitHandler<SchemaT> = async (formData) => {
    const formatted = formatEntryValues(formData, [entryValue.inputs], entryId);
    const formattedEntryValues = formatted.map((f) => ({
      ...f,
      entry_value_id: entryValueId,
    }));
    upsertEntryValue.mutate(formattedEntryValues as EntryValue["Update"][]);

    navigate({ to: EditRoute.to, params: { diaryId, trackerId, entryId } });
  };

  return (
    <div className="flex flex-col gap-4">
      <ui.Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="form">
          <InputSwitch
            input={entryValue?.inputs}
            pageInputId={entryValue?.input_id}
          />
          <ui.Button size="lg">Save</ui.Button>
        </form>
      </ui.Form>
    </div>
  );
};

export const Route = createFileRoute(
  "/_app/diary/$diaryId/edit/tracker/$trackerId/entry/$entryId/entry-value/$entryValueId/",
)({
  component: EditEntryValue,
});
