export async function compressImage(base64: string, fileName: string): Promise<File> {
    return new Promise<File>((resolve, reject) => {
        const img = new Image();
        img.src = base64;
        img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            let width = img.width;
            let height = img.height;
            const MAX_WIDTH = 1200;
            const MAX_HEIGHT = 1200;
            const MAX_SIZE_MB = 1; // Maximum image size in megabytes

            // Calculate the maximum dimensions while keeping the aspect ratio
            if (width > height) {
                if (width > MAX_WIDTH) {
                    height *= MAX_WIDTH / width;
                    width = MAX_WIDTH;
                }
            } else {
                if (height > MAX_HEIGHT) {
                    width *= MAX_HEIGHT / height;
                    height = MAX_HEIGHT;
                }
            }

            // Set canvas dimensions
            canvas.width = width;
            canvas.height = height;

            // Draw the resized image
            ctx!.drawImage(img, 0, 0, width, height);

            // Convert the image to PNG with a target file size of 1MB
            let quality = 0.9; // Initial quality value
            let compressed = canvas.toDataURL('image/jpeg', quality);

            while (compressed.length / 1024 / 1024 > MAX_SIZE_MB) {
                // Reduce the quality if the image size is still too large
                quality -= 0.1;
                compressed = canvas.toDataURL('image/jpeg', quality);
            }

            // Convert the base64 data to a Blob and then to a File
            const byteCharacters = window.atob(compressed.split(',')[1]);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'image/jpeg' });
            const file = new File([blob], fileName, { type: 'image/jpeg' });
            resolve(file);
        };
        img.onerror = reject;
    });
}
