import { supabaseClient } from '@/lib/supabase';
import { queryOptions, useQuery } from '@tanstack/react-query';

export async function selectOrganisationStatistics() {
	const { data, error } = await supabaseClient.from('organisations_statistics').select('*');
	if (error) throw error;
	return data;
}

export const organisationStatisticsQueryKey = ['organisation-statistics'];

export const selectOrganisationStatisticsQueryOptions = () => {
	return queryOptions({
		queryKey: organisationStatisticsQueryKey,
		queryFn: () => selectOrganisationStatistics(),
	});
};

export function useSelectOrganisationStatistics() {
	const profileQuery = useQuery(selectOrganisationStatisticsQueryOptions());
	return profileQuery;
}
