import type { OptionT } from '@/components/form/Select';
import { useClinicianOptions } from '../../auth/api/cliniciansByOrganisationQuery';
import { useInjectionMethodsQuery } from '../api/selectInjectionMethods';

const useInjectionOptions = (organisationId: string) => {
	const clinicianOptions = useClinicianOptions(organisationId);

	const { data: methods, error: methodsError } = useInjectionMethodsQuery();
	if (methodsError) throw methodsError;

	const methodOptions = methods?.map((m) => {
		return { label: m.injection_method, value: m.injection_method_id };
	}) as OptionT[];
	return { clinicianOptions, methodOptions };
};

export default useInjectionOptions;
