import { Control } from 'react-hook-form';

import { ui } from '../ui';

interface Props {
	control: Control;
	name: string;
	label: string;
}

export type YesNoT = 'yes' | 'no' | undefined;

interface Props {
	label: string;
	value: YesNoT;
	setValue: (val: YesNoT) => void;
	description?: string;
}
const YesNo: React.FC<Props> = (props: Props) => {
	return (
		<ui.Card>
			<ui.CardHeader>
				<ui.Label>{props.label}</ui.Label>
			</ui.CardHeader>
			<ui.CardContent>
				<ui.RadioGroup
					onValueChange={(val) => props.setValue(val as YesNoT)}
					defaultValue={props.value}
					className="flex flex-col space-y-1"
				>
					<div className="mx-auto flex items-center space-x-3 space-y-0 rounded-md bg-slate-100 p-4">
						<ui.RadioGroupItem value="yes"></ui.RadioGroupItem>
						<ui.Label>Yes</ui.Label>
						<ui.RadioGroupItem value="no"></ui.RadioGroupItem>
						<ui.Label>No</ui.Label>
					</div>
				</ui.RadioGroup>
				<p className="text-sm font-normal text-slate-700">{props.description}</p>
			</ui.CardContent>
		</ui.Card>
	);
};

export default YesNo;
