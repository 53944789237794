import React from 'react';

const Dashboard = () => {
    return (
        <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="1" y="1" width="15.2727" height="15.2727" rx="3" stroke="black" strokeWidth="2" />
            <rect x="1" y="21.7273" width="15.2727" height="15.2727" rx="3" stroke="black" strokeWidth="2" />
            <rect x="21.7273" y="1" width="15.2727" height="15.2727" rx="3" stroke="black" strokeWidth="2" />
            <rect x="21.7273" y="21.7273" width="15.2727" height="15.2727" rx="3" stroke="black" strokeWidth="2" />
        </svg>
    );
};

export default Dashboard;
