import { ui } from '@/components/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';

import { commonComponents as cc } from '../../../components/common';
import useUpdatePasswordMutation from '../api/useUpdatePasswordMutation';
import { PasswordZ } from '../types/signupSchema';
import AuthWrap from './AuthWrap';

const UpdatePassword = () => {
	const updatePasswordMutation = useUpdatePasswordMutation();

	const formSchema = z
		.object({
			password: PasswordZ,
			confirm: z.string(),
		})
		.refine((data) => data.password === data.confirm, {
			message: "Passwords don't match",
			path: ['confirm'],
		});

	type FormSchemaType = z.infer<typeof formSchema>;

	const form = useForm<FormSchemaType>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			password: '',
			confirm: '',
		},
	});

	const onSubmit: SubmitHandler<FormSchemaType> = async (formData) => {
		await updatePasswordMutation.mutate(formData.password);
	};

	return (
		<AuthWrap>
			<>
				<cc.Title words="Choose Password" />
				<ui.Form {...form}>
					<form onSubmit={form.handleSubmit(onSubmit)} className="form">
						{/* Password */}
						<ui.FormField
							control={form.control}
							name="password"
							render={({ field }) => (
								<ui.FormItem>
									<ui.FormLabel>New Password</ui.FormLabel>
									<ui.FormControl>
										<ui.Input type="password" placeholder="••••••••" {...field} />
									</ui.FormControl>
									<ui.FormDescription>
										Must be more than 8 characters and include: a capital letter and a number.
									</ui.FormDescription>
									<ui.FormMessage />
								</ui.FormItem>
							)}
						/>

						{/* Confirm Password */}
						<ui.FormField
							control={form.control}
							name="confirm"
							render={({ field }) => (
								<ui.FormItem>
									<ui.FormLabel>Confirm Password</ui.FormLabel>
									<ui.FormControl>
										<ui.Input type="password" placeholder="••••••••" {...field} />
									</ui.FormControl>
									<ui.FormMessage />
								</ui.FormItem>
							)}
						/>

						<ui.Button type="submit" disabled={updatePasswordMutation.isPending}>
							Save
						</ui.Button>
					</form>
				</ui.Form>
			</>
		</AuthWrap>
	);
};

export default UpdatePassword;
