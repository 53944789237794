import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";

import { VariationsType } from "../services/variations";
import { nextImage } from "./nextImage";

export type ImageT = {
  side: "left" | "right";
  angle: "top" | "sole";
  base64: string;
  checked: boolean;
  prediction: any;
  file: File | undefined;
  URL: string;
};

export type ImagesT = {
  RS: ImageT;
  RT: ImageT;
  LS: ImageT;
  LT: ImageT;
};

export type State = {
  images: ImagesT;
};

export const variationToURL = {
  LT: "left-top",
  RT: "right-top",
  LS: "left-sole",
  RS: "right-sole",
};

const flip = (obj: any) =>
  Object.fromEntries(Object.entries(obj).map(([k, v]) => [v, k]));

export const URLtoVariation = flip(variationToURL);

export type Action = {
  updateImages: (images: State["images"]) => void;
  updateImage: (
    images: State["images"],
    variation: VariationsType,
    image: string,
  ) => void;
  updateChecked: (
    images: State["images"],
    variation: VariationsType,
    checked: boolean,
  ) => void;
  updatePrediction: (
    images: State["images"],
    variation: VariationsType,
    checked: any,
  ) => void;
  resetVariant: (images: State["images"], variation: VariationsType) => void;
  allImagesChecked: (images: State["images"]) => boolean;
  getFirstMissingImage: (images: State["images"]) => ImageT | null;
};

export const noImages = {
  LT: {
    base64: "",
    checked: false,
    prediction: undefined,
    file: undefined,
    URL: variationToURL.LT,
    side: "left",
    angle: "top",
  },
  RT: {
    base64: "",
    checked: false,
    prediction: undefined,
    file: undefined,
    URL: variationToURL.RT,
    side: "right",
    angle: "top",
  },
  LS: {
    base64: "",
    checked: false,
    prediction: undefined,
    file: undefined,
    URL: variationToURL.LS,
    side: "left",
    angle: "sole",
  },
  RS: {
    base64: "",
    checked: false,
    prediction: undefined,
    file: undefined,
    URL: variationToURL.RS,
    side: "right",
    angle: "sole",
  },
};

export function updateImagesObject<T extends keyof ImagesT>(
  originalImages: ImagesT,
  variation: T,
  key: keyof ImageT,
  value: any,
) {
  const updatedVariation = { ...originalImages };
  updatedVariation[variation] = {
    ...updatedVariation[variation],
    [key]: value,
  };
  return updatedVariation;
}

function resetVariant(images: ImagesT, key: VariationsType) {
  images[key] = {
    base64: "",
    checked: false,
    prediction: undefined,
    file: undefined,
    URL: images[key].URL,
    side: images[key].side,
    angle: images[key].angle,
  };
  return images;
}

export const checkIfAllAttributes = (
  images: ImagesT,
  attribute: keyof ImageT,
) => {
  for (const variation in images) {
    if (!images[variation as keyof ImagesT][attribute]) {
      return true;
    }
  }
  return false;
};

export const getFirstMissingAttributeLink = (
  images: ImagesT,
  attribute: keyof ImageT,
): string => {
  for (const variation in images) {
    if (!images[variation as keyof ImagesT][attribute]) {
      return images[variation as keyof ImagesT]["URL"];
    }
  }
  return "";
};

// Create your store, which includes both state and (optionally) actions
export const useImagesStore = create<State & Action>()(
  devtools(
    persist(
      (set) => ({
        images: noImages,
        updateImages: (images) => set(() => ({ images: images })),
        updateImage: (
          images: ImagesT,
          variation: VariationsType,
          image: string,
        ) =>
          set(() => ({
            images: updateImagesObject(images, variation, "base64", image),
          })),
        updateChecked: (
          images: ImagesT,
          variation: VariationsType,
          checked: boolean,
        ) =>
          set(() => ({
            images: updateImagesObject(images, variation, "checked", checked),
          })),
        updatePrediction: (
          images: ImagesT,
          variation: VariationsType,
          prediction: any,
        ) =>
          set(() => ({
            images: updateImagesObject(
              images,
              variation,
              "prediction",
              prediction,
            ),
          })),
        resetVariant: (images, variation) =>
          set(() => ({ images: resetVariant(images, variation) })),
        nextImage: (images: ImagesT, path: string) => nextImage(images, path),
        allImagesChecked: (images: ImagesT) => {
          return Object.values(images).every((image) => image.checked);
        },
        getFirstMissingImage: (images: ImagesT) => {
          Object.values(images).forEach((image) => {
            if (!image.base64) {
              return image;
            }
          });
          return null;
        },
      }),
      {
        name: "current-images",
        storage: createJSONStorage(() => localStorage),
      },
    ),
  ),
);
