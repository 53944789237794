import { supabaseClient } from '@/lib/supabase';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'sonner';

interface Props {
	diaryId: string;
}
const createFootLog = async (diaryId: string) => {
	return await supabaseClient
		.from('foot_logs')
		.insert({
			diary_id: diaryId,
		})
		.select()
		.throwOnError()
		.single()
		.then((result) => result.data);
};

const useCreateFootLogMutation = () => {
	return useMutation({
		mutationKey: ['createFootLog'],
		mutationFn: async (data: Props) => {
			return createFootLog(data.diaryId);
		},
		onError: (error) => {
			console.log(error);
			toast.error(error.message);
		},
		onSuccess: (data) => {
			toast.success('Successfully created foot log');
			return data;
		},
	});
};
export { useCreateFootLogMutation };
export default useCreateFootLogMutation;
