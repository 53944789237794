import { supabaseClient } from '@/lib/supabase';
import { useAuth } from '@/utils/auth';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';

import { createProfileQueryKey } from '../../profile/api/selectProfile';

export async function updateSuperUser(value: boolean, user_id: string) {
	return await supabaseClient.from('users').update({ is_super_user: value }).eq('user_id', user_id);
}

type UpdateSuperUserT = { value: boolean; user_id: string };
export const useUpdateSuperUserMutation = () => {
	const queryClient = useQueryClient();
	const auth = useAuth();
	return useMutation({
		mutationFn: async (data: UpdateSuperUserT) => updateSuperUser(data.value, data.user_id),
		onError: (error) => {
			toast.error(error.message);
		},
		onSuccess: () => {
			toast.success('Super user status updated');
		},
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey: createProfileQueryKey(auth.id) });
		},
	});
};
