// TrackerStartDate.tsx
import DateTimeForm from '@/components/form/DateTimeForm';
import useExtractTracker from '@/features/trackers/hooks/useExtractTracker';
import { useTrackerUpdate } from '@/features/trackers/hooks/useTrackerUpdate';
import { createFileRoute } from '@tanstack/react-router';

const TrackerStartDate = () => {
	const { diaryId, trackerId } = Route.useParams();
	const tracker = useExtractTracker(diaryId, trackerId);
	const { updateStartDate } = useTrackerUpdate(diaryId, trackerId);

	return <DateTimeForm initialDate={tracker?.start_date} onSubmit={updateStartDate} submitButtonText="Save" />;
};

export const Route = createFileRoute('/_app/diary/$diaryId/edit/tracker/$trackerId/start-date')({
	component: TrackerStartDate,
});
