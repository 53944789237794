import { formComponents as fc } from '@/components/form';
import { ui } from '@/components/ui';
import InjectionDiarySaveLabel from '@/features/injection/components/InjectionDiarySaveLabel';
import InviteDialog from '@/features/invite/components/InviteDialog';
import FavouriteUsersComboBox from '@/features/profile/components/FavouriteUsersComboBox';
import { useUpdateTracker } from '@/features/trackers/api/updateTracker';
import useExtractTracker from '@/features/trackers/hooks/useExtractTracker';
import { type SubmitHandler, useForm, zodResolver } from '@/utils/form';
import * as z from 'zod';

const formSchema = z.object({
	contact_id: z.string().uuid(),
	tracker_id: z.string().uuid(),
});

interface Props {
	diaryId: string;
	trackerId: string;
}

const TrackerContactPersonForm = ({ diaryId, trackerId }: Props) => {
	const tracker = useExtractTracker(diaryId, trackerId);
	const updateTracker = useUpdateTracker({ diaryId: diaryId });

	type FormSchemaType = z.infer<typeof formSchema>;

	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			tracker_id: tracker?.tracker_id,
			contact_id: tracker?.contact_id ?? undefined,
		},
	});

	const onSubmit: SubmitHandler<FormSchemaType> = async (formData) => {
		updateTracker.mutate(formData);
	};

	return (
		<ui.Form {...form}>
			<form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-y-8 text-lg">
				<div className="">
					<InjectionDiarySaveLabel label="Contact Person" data={{ contact_id: form.getValues(['contact_id'][0]) }} />
					<div className="flex flex-col gap-y-2">
						<FavouriteUsersComboBox placeholder="Contact Person" name="contact_id" />

						<InviteDialog />
					</div>
				</div>
				<fc.SaveButton />
			</form>
		</ui.Form>
	);
};

export default TrackerContactPersonForm;
