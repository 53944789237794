import { supabaseClient } from '@/lib/supabase';
import type { ExtendedFavouriteDiaryTemplateT } from '@/types/database/diaries';
import { useAuth } from '@/utils/auth';
import { queryOptions, useQuery } from '@tanstack/react-query';
export async function selectFavouriteDiaries(userId: string) {
	const { data, error } = await supabaseClient
		.from('favourite_diary_templates')
		.select('*, diary_templates(*)')
		.eq('user_id', userId);
	if (error) throw error;
	return data as ExtendedFavouriteDiaryTemplateT[];
}

export const favourite_diary_templates_key = (id: string) => ['favourite-templates', { user_id: id }];

export const favouriteDiariesQueryOptions = (userId: string) => {
	return queryOptions({
		queryKey: favourite_diary_templates_key(userId),
		queryFn: async () => {
			return await selectFavouriteDiaries(userId);
		},
	});
};

export const useFavouriteDiariesQuery = () => {
	const auth = useAuth();
	const options = favouriteDiariesQueryOptions(auth.id);
	const query = useQuery(options);
	return query;
};
