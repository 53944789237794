import { useState } from 'react';
import { useRouter } from '@tanstack/react-router';

export function useSignupUrl(defaultSearch?: Record<string, any>) {
  const router = useRouter();
  const defaultUrl = router.buildLocation({
    to: '/signup',
    search: defaultSearch ?? {},
  }).href;

  const [url, setUrl] = useState<string>(defaultUrl);

  const updateUrl = (search?: Record<string, any>) => {
    const newUrl = router.buildLocation({
      to: '/signup',
      search: search ?? {},
    });
    setUrl(newUrl.href);
    return newUrl.href;
  };


  return {
    url,
    updateUrl,
  };
}