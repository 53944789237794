interface Props {
    fill: string;
    className?: string;
}

const BackArrow = ({ fill, className }: Props) => {
    return (
        <svg
            className={className}
            width="29"
            height="24"
            viewBox="0 0 29 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.93934 13.0607C0.353553 12.4749 0.353553 11.5251 0.93934 10.9394L10.4853 1.39341C11.0711 0.807624 12.0208 0.807624 12.6066 1.39341C13.1924 1.9792 13.1924 2.92895 12.6066 3.51473L5.62132 10.5H27C27.8284 10.5 28.5 11.1716 28.5 12C28.5 12.8284 27.8284 13.5 27 13.5H5.62132L12.6066 20.4853C13.1924 21.0711 13.1924 22.0208 12.6066 22.6066C12.0208 23.1924 11.0711 23.1924 10.4853 22.6066L0.93934 13.0607Z"
                fill={fill}
            />
        </svg>
    );
};

export default BackArrow;
