interface Props {
	fill?: string;
	className: string;
	strokeWidth?: number;
}

const Tick = ({ fill, className, strokeWidth }: Props) => {
	return (
		<svg
			className={className}
			width="20"
			height="15"
			viewBox="0 0 20 15"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M2.16534 8.24164L6.91724 13.324L17.9688 1.92025"
				stroke={fill ? fill : 'black'}
				strokeWidth={strokeWidth ? strokeWidth : 3}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default Tick;
