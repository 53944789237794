import Title from '@/components/common/Title';
import { organisationsQueryOptions } from '@/features/auth/api/useOrganisationsQuery';
import JoinOrganisationForm from '@/features/organisations/components/JoinOrganisationForm';
import { hooks as h } from '@/hooks';
import { createFileRoute } from '@tanstack/react-router';
import { useNavigateToNextOnboardingRoute } from '../../features/onboarding/hooks/useNavigateToNextOnboardingRoute';
import useSkipOrganisation from '../../features/onboarding/hooks/useSkipOrganisation';

function NewOrganisation() {
	useSkipOrganisation();
	h.useNav([{ route: { to: '/onboarding/organisation', params: {}, search: {} }, label: 'Organisation' }]);

	return (
		<>
			<Title
				words="Organisation"
				description={'Adding an organisation will enable data sharing between you and the organisation.'}
			/>
			<JoinOrganisationForm isOnboarding={true} />
		</>
	);
}

export const Route = createFileRoute('/onboarding/organisation')({
	component: NewOrganisation,
	loader: async ({ context }) => {
		const orgs = await context?.queryClient.ensureQueryData(organisationsQueryOptions());
		if (!orgs) {
			throw new Error('Organisations data is undefined');
		}
		const selectOrgansationOptions = orgs?.map((org) => ({
			label: org.organisation_name,
			value: org.organisation_id,
		}));
		return {
			selectOrgansationOptions: selectOrgansationOptions,
			auth: context.auth,
		};
	},
});

export default NewOrganisation;
