import EntryTimeForm from '@/features/entries/components/EntryTimeForm';
import useExtractEntry from '@/features/entries/hooks/useExtractEntry';
import { createFileRoute } from '@tanstack/react-router';

const EndDate = () => {
	const { diaryId, trackerId, entryId } = Route.useParams();
	const entry = useExtractEntry(diaryId, trackerId, entryId);

	return (
		<div>
			<EntryTimeForm entry={entry} diaryId={diaryId} entryId={entryId} />
		</div>
	);
};

export const Route = createFileRoute('/_app/diary/$diaryId/edit/tracker/$trackerId/entry/$entryId/entry-time')({
	component: EndDate,
});
