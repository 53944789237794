import { formComponents as fc } from '@/components/form';
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import useClinicianMutation from '@/features/profile/api/updateClinician';
import { type SubmitHandler, useForm, zodResolver } from '@/utils/form';
import * as z from 'zod';

const formSchema = z.object({
	clinician_id: z.string().uuid(),
	clinician_number: z.string().min(6, 'Not long enough').max(8, 'Too long'),
});

interface Props {
	userId: string;
	clinicianNumber: string | null;
	onSuccess?: () => void;
}

const ClinicianNumberForm = ({ userId, clinicianNumber, onSuccess }: Props) => {
	type FormSchemaType = z.infer<typeof formSchema>;

	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			clinician_id: userId ?? '',
			clinician_number: clinicianNumber ?? undefined,
		},
	});

	const clinicianMutation = useClinicianMutation(userId, 'clinician-number');

	const onSubmit: SubmitHandler<FormSchemaType> = async (formData) => {
		clinicianMutation.mutate(formData, { onSuccess: onSuccess });
	};

	return (
		<Form {...form}>
			<form onSubmit={form.handleSubmit(onSubmit)} className="form">
				<fc.Input
					label="Clinician Number"
					description="Please enter your HCPC or GMC number."
					name="clinician_number"
				/>
				<fc.SaveButton />
			</form>
		</Form>
	);
};

export default ClinicianNumberForm;
