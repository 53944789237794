import { supabaseClient } from '@/lib/supabase';
import type { DashboardSearchT } from '@/routes/_app/dashboard';
import type { DiariesCountT } from '@/types/database/diaries';
import { type Auth, useAuth } from '@/utils/auth';
import { queryOptions, useSuspenseQuery } from '@tanstack/react-query';
import { toast } from 'sonner';
import { prepDiariesData } from './utils';

export async function countDiaries(body): Promise<DiariesCountT> {
	const { data, error } = await supabaseClient.functions.invoke('public-count-diaries', {
		body: body,
	});

	if (error) {
		const errorMessage = `Error counting diaries. ${error.message}`;
		console.error(errorMessage);
		toast.error(errorMessage);
	}

	return data;
}

export const createDashboardDiariesCountQueryKey = ['dashboard', 'diaries-count'];

export const countDiariesQueryOptions = (settings: DashboardSearchT, auth: Auth) => {
	const data = prepDiariesData(settings, auth);

	return queryOptions({
		queryKey: createDashboardDiariesCountQueryKey,
		queryFn: () => countDiaries(data),
	});
};
export const useCountDiariesQuery = (settings: DashboardSearchT) => {
	const auth = useAuth();
	return useSuspenseQuery(countDiariesQueryOptions(settings, auth));
};
