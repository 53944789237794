import { ui } from '@/components/ui';
import { Route as DiaryRoute } from '@/routes/_app/admin/diary/$diaryId/index';
import { Route as FormRoute } from '@/routes/_app/admin/form/$formId/results';
import { Route as TrackerFormPage } from '@/routes/tracker/$trackerId/form/$formId';
import { Route as TrackerResults } from '@/routes/tracker/$trackerId/results';
import { services as s } from '@/services';
import { Link } from '@tanstack/react-router';
import { Book, Clipboard, Clock } from 'lucide-react';
import { useTriggerEntryReminders } from '../../knock/api/triggerEntryReminders';
import type { TrackerAdminView } from '../api/selectTrackers';
import TrackerLinkItem from './TrackerLinkItem';
import { TrackerReminderStatusForm } from './TrackerReminderStatusForm';

interface Props {
	t: TrackerAdminView;
}

const TrackerAdminCard = ({ t }: Props) => {
	const { mutate: triggerEntryReminders } = useTriggerEntryReminders();

	return (
		<li key={t.tracker_id} className="p-2 lg:w-1/3">
			<ui.Card>
				<ui.CardHeader>
					<ui.CardTitle>{t.tracker_name}</ui.CardTitle>
					<ui.CardDescription>{t.tracker_id}</ui.CardDescription>
				</ui.CardHeader>
				<ui.CardContent>
					<div className="flex flex-col gap-2 pb-2">
						<div>
							<ui.Label>Patient</ui.Label>
							<p>Name: {t.diary?.patient_info?.patient_name}</p>
							<p>Email: {t.diary?.patient_info?.patient_email}</p>
						</div>
						<div>
							<ui.Label>Contact Person</ui.Label>
							<p>Name: {t.contact_name}</p>
							<p>Email: {t.contact_email}</p>
						</div>
					</div>
					<ui.Separator />

					{t?.diary_id && (
						<TrackerLinkItem
							to={DiaryRoute.to}
							params={{ diaryId: t?.diary_id }}
							icon={<Book />}
							text={t.diary_name ?? 'Diary'}
						/>
					)}
					{t.form_id && (
						<TrackerLinkItem
							to={FormRoute.to}
							params={{ formId: t.form_id as string }}
							icon={<Clipboard />}
							text={t.form?.form_name ?? 'Form'}
						/>
					)}
					{t.schedule_id && (
						<TrackerLinkItem
							to="/builder/schedule/$scheduleId"
							params={{ scheduleId: t.schedule_id }}
							icon={<Clock className="w-4 h-4" />}
							text={t.schedule_name ?? 'Schedule'}
						/>
					)}
					<ui.Separator />
					<div className="flex flex-col gap-2 pt-4">
						<h3 className="text-md text-slate-700 dark:text-slate-300">Reminder Status</h3>
						<p>Next Entry: {s.time.formatDateTime(t.next_entry_due_at)}</p>
						<TrackerReminderStatusForm tracker={t} />
						<ui.Button
							className="px-0"
							variant="destructive-link"
							size="sm"
							onClick={() =>
								triggerEntryReminders({
									patient_id: t.patient_id,
									tracker_id: t.tracker_id,
									next_entry: t.next_entry,
								})
							}
						>
							Trigger Entry Reminders
						</ui.Button>
					</div>
				</ui.CardContent>
				<ui.CardFooter className="flex flex-wrap gap-2 border-slate-200 dark:border-slate-800 pt-4 border-t">
					<ui.Button size="sm" asChild>
						<Link to={TrackerFormPage.to} params={{ trackerId: t.tracker_id, formId: t.form_id as string }}>
							Open
						</Link>
					</ui.Button>
					<ui.Button variant="secondary" size="sm" asChild>
						<Link to={TrackerResults.to} params={{ trackerId: t.tracker_id }}>
							Results
						</Link>
					</ui.Button>
				</ui.CardFooter>
			</ui.Card>
		</li>
	);
};

export default TrackerAdminCard;
