import { commonComponents as e } from '@/components/common';
import { Outlet, createFileRoute } from '@tanstack/react-router';

const Upload = () => {
	// const sendMessage = async (checkinID: number) => {
	//     const messageData = {
	//         check_in_id: checkinID,
	//         organisation_id: 1,
	//         words: message,
	//     };
	//     const role = auth.role! as 'patient' | 'clinician';
	//     messageData[role] = auth.id!;

	//     const { data, error } = await supabaseClient.from('messages').insert([messageData]).select();

	//     return data;
	// };

	return (
		<div className="mx-2">
			<e.Title words="Upload" />
			<Outlet />
		</div>
	);
};

export const Route = createFileRoute('/_app/foot-watch/$diaryId/new/upload')({
	component: Upload,
});
