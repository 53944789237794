import { supabaseClient } from '@/lib/supabase';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';

import type { FavouriteDiaryT } from '../../../types/database/diaries';
import { useAuth } from '../../../utils/auth';
import { createProfileQueryKey } from '../../profile/api/selectProfile';
import { favourite_diary_templates_key } from './selectFavouriteDiaries';

export async function favouriteDiary(data: FavouriteDiaryT) {
	const { data: diary, error } = await supabaseClient
		.from('favourite_diary_templates')
		.upsert(data)
		.select('*, diary_templates(*)')
		.single();

	if (error) {
		throw error;
	}
	return diary;
}

export async function unfavouriteTemplate(data: FavouriteDiaryT) {
	const { data: diary, error } = await supabaseClient
		.from('favourite_diary_templates')
		.delete()
		.eq('user_id', data.user_id)
		.eq('diary_template_id', data.diary_template_id)
		.select('*')
		.single();

	if (error) {
		throw error;
	}
	return diary;
}

export function useFavouriteTemplatesMutation() {
	const auth = useAuth();
	const userId = auth.id as string;
	const queryClient = useQueryClient();
	const queryKey = favourite_diary_templates_key(userId);

	return useMutation({
		mutationFn: (data: FavouriteDiaryT) => favouriteDiary(data),
		onMutate: async (data: FavouriteDiaryT) => {
			// Cancel any outgoing refetches (so they don't overwrite our optimistic update)
			await queryClient.cancelQueries({ queryKey: queryKey });
			// Snapshot the previous value
			const previousTemplates = queryClient.getQueryData(queryKey) as FavouriteDiaryT[];
			const newTemplates = previousTemplates?.concat([
				{ user_id: data.user_id, diary_template_id: data.diary_template_id },
			]);

			// Optimistically update to the new value
			queryClient.setQueryData(queryKey, newTemplates);
			// Return the snapshotted value
			return { previousTemplates };
		},
		onSuccess: async (data) => {
			if (data?.diary_templates) {
				toast.success(`Added ${data.diary_templates.diary_template_name} to favourites`);
			} else {
				toast.error('Error favouriting diary type');
			}
		},
		onError: (error) => {
			toast.error(error.message);
		},
		onSettled: () => {
			// Invalidate to make sure last image label updates...
			queryClient.invalidateQueries({ queryKey: createProfileQueryKey(userId) });
			queryClient.invalidateQueries({ queryKey: queryKey });
		},
	});
}

export function useUnfavouriteTemplateMutation() {
	const auth = useAuth();
	const queryClient = useQueryClient();
	const queryKey = favourite_diary_templates_key(auth.id!);

	return useMutation({
		mutationFn: (data: FavouriteDiaryT) => unfavouriteTemplate(data),
		onMutate: async (data) => {
			// Cancel any outgoing refetches (so they don't overwrite our optimistic update)
			await queryClient.cancelQueries({ queryKey: queryKey });
			// Snapshot the previous value
			const previousTemplates = queryClient.getQueryData(queryKey) as FavouriteDiaryT[];
			const newTemplates = previousTemplates?.filter(
				(w: FavouriteDiaryT) => w.diary_template_id !== data.diary_template_id && w.user_id !== data.user_id,
			);
			// Optimistically update to the new value
			queryClient.setQueryData(queryKey, newTemplates);
			// Return the snapshotted value
			return { previousTemplates };
		},
		onSuccess: async () => {
			toast.success(`Removed favourite template`);
		},
		onError: (error) => {
			toast.error(error.message);
		},
		onSettled: () => {
			// Invalidate to make sure last image label updates...
			queryClient.invalidateQueries({ queryKey: createProfileQueryKey(auth.id!) });
			queryClient.invalidateQueries({ queryKey: queryKey });
		},
	});
}
