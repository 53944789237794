const Six = () => {
	return (
		<svg
			role="img"
			aria-label="Pain Six"
			width="50"
			height="50"
			viewBox="0 0 50 50"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect x="1" y="1" width="48" height="48" rx="24" fill="white" stroke="black" strokeWidth="2" />
			<rect x="11" y="16" width="8" height="8" rx="4" fill="#0E0E0E" />
			<rect x="31" y="16" width="8" height="8" rx="4" fill="#0E0E0E" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8.50708 14.1067C8.36546 13.5729 8.68339 13.0253 9.21721 12.8837L9.31378 12.8581C11.3312 12.3229 13.271 11.5331 15.0865 10.5089C15.5676 10.2376 16.1829 10.3807 16.4736 10.8503V10.8503C16.7643 11.3199 16.6202 11.938 16.14 12.2108C14.1563 13.3378 12.0345 14.2055 9.82665 14.7912L9.73008 14.8168C9.19626 14.9585 8.64871 14.6405 8.50708 14.1067Z"
				fill="black"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M41.4929 14.1065C41.6345 13.5727 41.3166 13.0251 40.7828 12.8835L40.6862 12.8579C38.6688 12.3226 36.729 11.5329 34.9135 10.5087C34.4324 10.2373 33.8171 10.3804 33.5264 10.85V10.85C33.2357 11.3196 33.3798 11.9377 33.86 12.2105C35.8437 13.3376 37.9655 14.2052 40.1734 14.791L40.2699 14.8166C40.8037 14.9582 41.3513 14.6403 41.4929 14.1065Z"
				fill="black"
			/>
			<path
				d="M18.8148 35.9753L17.5177 36.4096C17.0236 36.575 16.6085 36.9181 16.3531 37.3723C16.2098 37.627 16.423 37.9348 16.7118 37.8903L19.2233 37.5034C20.8221 37.257 22.4374 37.1333 24.0551 37.1333L25.4882 37.1333C27.1606 37.1333 28.8308 37.2533 30.486 37.4924L33.2917 37.8977C33.581 37.9395 33.7925 37.6311 33.6492 37.3763C33.3922 36.9196 32.9749 36.5745 32.478 36.4081L31.1852 35.9753C29.2563 35.3293 27.2355 35 25.2013 35L24.7987 35C22.7645 35 20.7437 35.3293 18.8148 35.9753Z"
				fill="black"
				stroke="black"
			/>
		</svg>
	);
};

export default Six;
