import { ui } from '@/components/ui';
import { Route as NewRoute } from '@/routes/_app/diary/$diaryId/home/new';
import { Link } from '@tanstack/react-router';

import type { ExtendedDiaryViewT } from '@/types/database/diaries';

interface Props {
	diary: ExtendedDiaryViewT;
	showAddEntryButton?: boolean;
}
const NoChartData = ({ diary, showAddEntryButton }: Props) => {
	return (
		<ui.Card>
			<ui.CardHeader>
				<ui.CardTitle>Not Enough Data Yet</ui.CardTitle>
				<ui.CardDescription>To view charts there must be at least two entries.</ui.CardDescription>
			</ui.CardHeader>
			{showAddEntryButton && (
				<ui.CardFooter>
					<ui.Button asChild>
						<Link to={NewRoute.to} params={(prev) => ({ ...prev })}>
							{diary.trackers_view.filter((tv) => tv.end_date === null).length > 0 ? 'Add Entry' : 'Add Form'}
						</Link>
					</ui.Button>
				</ui.CardFooter>
			)}
		</ui.Card>
	);
};

export default NoChartData;
