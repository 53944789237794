import ErrorPage from "@/components/pages/ErrorPage";
import Thetis from "@/features/navigation/assets/Thetis";
import { supabaseClient } from "@/lib/supabase";
import { useAuth } from "@/utils/auth";
import { z } from "@/utils/form";
import { createFileRoute, Outlet } from "@tanstack/react-router";
import Title from "../components/common/Title";
import LoginForm from "../features/auth/components/LoginForm";
import { useTheme } from "../hooks/useTheme";
import dayjs from 'dayjs';

function PreviewPage() {
	const auth = useAuth();

	const { removeBackground } = useTheme();

	removeBackground();

	return (
		<div className="flex flex-col gap-y-8 px-4 p-8 lg:p-16 min-h-screen">
			<div className="flex justify-between items-center gap-x-2 w-full">
				<div className="flex items-center gap-2">
					<Thetis />
					<h1 className="text-xl">Patient Watch</h1>
				</div>
			</div>
			{auth.isAuthenticated
				? (
					<>
						<Outlet />
					</>
				)
				: (
					<>
						<Title
							words="Print Page"
							description="To print the page, please first login."
						/>
						<LoginForm onSuccess={() => window.location.reload()} />
					</>
				)}
			<footer className="border-gray-200 dark:border-gray-800 mt-auto pt-8 border-t">
				<div className="flex flex-col gap-2 text-gray-600 text-sm dark:text-gray-400">
					<div>
						For help and support please contact guy@patient-watch.com
					</div>
					<div id="doppio-ready">
						© Patient Watch Ltd {dayjs().get("year")}
					</div>
				</div>
			</footer>
		</div>
	);
}

const previewSchema = z.object({
	accessToken: z.string().optional(),
	refreshToken: z.string().optional(),
});

export type PreviewSearchT = z.infer<typeof previewSchema>;

export const Route = createFileRoute("/preview")({
	component: PreviewPage,
	errorComponent: ({ error, reset }) => (
		<ErrorPage title="Preview Error" error={error as Error} reset={reset} />
	),
	validateSearch: (search) => previewSchema.parse(search),
	loaderDeps: ({ search: { accessToken, refreshToken } }) => ({
		accessToken,
		refreshToken,
	}),
	loader: async ({ deps: { accessToken, refreshToken } }) => {
		await supabaseClient.auth.setSession({
			access_token: String(accessToken),
			refresh_token: String(refreshToken),
		});
	},
});
