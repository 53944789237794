interface Props {
	fill: string;
	stroke: string;
}
const InviteUser = ({ fill, stroke }: Props) => {
	return (
		<svg
			className="h-full w-full"
			width="70"
			height="70"
			viewBox="0 0 70 70"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M54 29H68" stroke={stroke} strokeWidth="2.5" strokeLinecap="round" />
			<path d="M61 22L61 36" stroke={stroke} strokeWidth="2.5" strokeLinecap="round" />
			<path
				d="M58 48.5C58 51.4013 55.7723 54.2619 51.6474 56.456C47.5661 58.6269 41.8588 60 35.5 60C29.1412 60 23.4339 58.6269 19.3526 56.456C15.2277 54.2619 13 51.4013 13 48.5C13 45.5987 15.2277 42.7381 19.3526 40.544C23.4339 38.3731 29.1412 37 35.5 37C41.8588 37 47.5661 38.3731 51.6474 40.544C55.7723 42.7381 58 45.5987 58 48.5Z"
				stroke={stroke}
				strokeWidth="2.5"
			/>
			<circle cx="35.5" cy="22.5" r="10.5" stroke={stroke} strokeWidth="2.5" />
		</svg>
	);
};

export default InviteUser;
