import { TimingT } from '../../../types/zod/timings';

export const mapTimesToTimings = (times: TimingT[], scheduleId: string): TimingT[] => {
    console.log(times);
    return times?.map((t) => {
        return {
            ...t,
            schedule_id: scheduleId,
        };
    });
};
