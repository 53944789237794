import { ui } from '@/components/ui';
import { useImagesStore } from '@/features/foot-watch/hooks/useImagesStore';
import { useUpload } from '@/features/foot-watch/upload/api/useUpload';
import { zodResolver } from '@hookform/resolvers/zod';
import { createFileRoute } from '@tanstack/react-router';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';
import { z } from 'zod';

const FormSchema = z.object({
    comment: z.optional(
        z.string().max(160, {
            message: 'Comments must not be longer than 30 characters.',
        }),
    ),
});

const Message = () => {
    const { auth } = Route.useRouteContext();
    const { diaryId } = Route.useParams();
    // Get the selected patient (in case it is a clinician checking in)
    const images = useImagesStore((state) => state.images);

    const form = useForm<z.infer<typeof FormSchema>>({
        resolver: zodResolver(FormSchema),
    });

    const upload = useUpload();

    async function onSubmit(data: z.infer<typeof FormSchema>) {
        toast.info('You submitted the following comment:\n' + data.comment);
        await upload(auth.id!, diaryId, images);
    }

    return (
        <div className="my-2 gap-y-4 flex flex-col">
            <p className="text-lg  w-full">
                {auth?.role === 'patient'
                    ? 'Enter your comments for the clinician who would review your photos.'
                    : 'Enter any comments related the patients photos.'}
            </p>
            <i className="text-lg  w-full">Please note that during this research study, no photos will be reviewed.</i>
            <ui.Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
                    <ui.FormField
                        control={form.control}
                        name="comment"
                        render={({ field }) => (
                            <ui.FormItem>
                                <ui.Label htmlFor="comment" className="form-label">
                                    Comments
                                </ui.Label>
                                <ui.FormControl>
                                    <ui.Textarea
                                        id="comment"
                                        rows={6}
                                        className="form-textarea"
                                        placeholder="Write any commments about the photo here."
                                        {...field}
                                    ></ui.Textarea>
                                </ui.FormControl>
                                <ui.FormMessage />
                            </ui.FormItem>
                        )}
                    />
                    <ui.Button type="submit">Submit</ui.Button>
                </form>
            </ui.Form>
        </div>
    );
};

export const Route = createFileRoute('/_app/diary/$diaryId/new/tracker/$trackerId/entry/$entryId/page/$pageIndex/foot-photos/upload/message')({
    component: Message,
});
