import { Button } from "@/components/ui/button";
import type { SignupSearch } from "@/routes/_auth/signup";
import React from "react";
import { useForm } from "react-hook-form";
import { Form } from "../../../components/ui/form";
import SelectRole from "../../invite/components/SelectRole";
import DubQrCode from "./DubQrCode";
import { useSignupUrl } from '../../auth/hooks/useSignupUrl';
import SelectFromMyOrganisations from '../../organisations/components/SelectFromMyOrganisations';

interface Props {
	search: SignupSearch;
	showFields: {
		organisations: boolean;
		roles: boolean;
	};
}

const QrInviteForm = ({ search, showFields }: Props) => {
	const [showQrCode, setShowQrCode] = React.useState(false);
	  const { url, updateUrl } = useSignupUrl();

	const form = useForm({
		defaultValues: {
			role: search.role,
			organisation: JSON.stringify({
				organisation_id: search.organisation?.organisation_id,
				organisation_name: '',
			}),
		},
	});

	const role = form.watch("role");
	const organisation = JSON.parse(form.watch("organisation"));

	const title = React.useMemo(() => {
		return search.quickstart?.quickstart_name ?? 
			`Signup as a ${role?.toLowerCase()} in ${(organisation)?.organisation_name}`;
	}, [search.quickstart?.quickstart_name, role, organisation]);

	const description = React.useMemo(() => {
		return search.quickstart?.quickstart_description??'';
	}, [search.quickstart?.quickstart_description]);

	const onSubmit = (data: any) => {
		updateUrl({ ...search, ...data });
		setShowQrCode(true);
	};

	return (
		<>
			{showQrCode
				? (
					<div className="space-y-6 mt-4 mb-6">
						<DubQrCode title={title} description={description} url={window.location.origin + url} />
						<Button
							onClick={() => setShowQrCode(false)}
							variant="outline"
							>
							Back
						</Button>
					
					</div>
				)
				: (
					<Form {...form}>
						<form
							onSubmit={form.handleSubmit(onSubmit)}
							className="space-y-6 mt-4 mb-6"
						>
							{showFields?.roles && <SelectRole />}
							{showFields?.organisations && (
								<SelectFromMyOrganisations />
							)}
							<Button type="submit">Generate QR Code</Button>
						</form>
					</Form>
				)}
		</>
	);
};

export default QrInviteForm;
