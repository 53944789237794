import EmojiContent from '@/features/website/components/EmojiLink';
import FaqBlock from '@/features/website/components/FaqBlock';
import Hero from '@/features/website/components/Hero';
import Section from '@/features/website/components/Section';
import Actions from '@/features/website/landing/Actions';
import LaptopAndPhone from '@/features/website/landing/assets/laptop-and-phone-patient-watch.png';
import { generalFAQs } from '@/features/website/landing/content/generalFaqs';
import DiaryDemo from '@/features/website/landing/DiaryDemo';
import PartnersReel from '@/features/website/landing/PartnersReel';
import Report from '@/features/website/landing/Report';
import Why from '@/features/website/landing/Solution';

import Problem from './Problem';

export const landingComponents = {
	EmojiContent,
	FaqBlock,
	Hero,
	Section,
	Actions,
	LaptopAndPhone,
	generalFAQs,
	DiaryDemo,
	Partners: PartnersReel,
	Report,
	Why,
	Problem
};
