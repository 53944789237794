import { Reorder } from 'framer-motion';
import { useState } from 'react';

function ScheduleBuilder() {
    const [times, setTimes] = useState([
        {
            timestamp: 360000,
            label: '1 Hour',
        },
        {
            timestamp: 360000,
            label: '1 Hour',
        },
        {
            timestamp: 360000,
            label: '1 Hour',
        },
        {
            timestamp: 360000,
            label: '1 Hour',
        },
    ]);
    const [items, setItems] = useState([0, 1, 2, 3]);

    return (
        <Reorder.Group axis="y" values={items} onReorder={setItems}>
            {items.map((item) => (
                <Reorder.Item key={item} value={item}>
                    {item}
                </Reorder.Item>
            ))}
        </Reorder.Group>
    );
}

export default ScheduleBuilder;
