interface Props {
    stroke?: string;
}
const Download = ({ stroke }: Props) => {
    return (
        <svg
            className="w-full h-full"
            width="42"
            height="42"
            viewBox="0 0 42 42"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21 9C21.6627 9 22.2 9.54365 22.2 10.2143V21.8542L24.9515 19.0699C25.4201 18.5957 26.1799 18.5957 26.6485 19.0699C27.1172 19.5441 27.1172 20.313 26.6485 20.7872L21.8485 25.6443C21.3799 26.1186 20.6201 26.1186 20.1515 25.6443L15.3515 20.7872C14.8828 20.313 14.8828 19.5441 15.3515 19.0699C15.8201 18.5957 16.5799 18.5957 17.0485 19.0699L19.8 21.8542V10.2143C19.8 9.54365 20.3373 9 21 9Z"
                fill={stroke ? stroke : 'black'}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.69305 26.1049C9.28724 25.8382 9.98786 26.0976 10.2579 26.6842L11.7769 29.9829C11.9686 30.3994 12.3893 30.6668 12.8527 30.6668H29.1473C29.6107 30.6668 30.0314 30.3994 30.2231 29.9829L31.7421 26.6842C32.0121 26.0976 32.7128 25.8382 33.3069 26.1049C33.9011 26.3715 34.1639 27.0631 33.8938 27.6497L32.3749 30.9484C31.7995 32.1978 30.5376 33 29.1473 33H12.8527C11.4624 33 10.2005 32.1978 9.62515 30.9484L8.10622 27.6497C7.83613 27.0631 8.09887 26.3715 8.69305 26.1049Z"
                fill={stroke ? stroke : 'black'}
            />
        </svg>
    );
};

export default Download;
