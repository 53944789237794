import type { DiaryTypeT } from '@/types/zod/common';
import PainExplainer from './PainExplainer';
interface Props {
	diaryType: DiaryTypeT;
}
const ExplainerSwitch = ({ diaryType }: Props) => {
	switch (diaryType) {
		case 'PAIN':
			return <PainExplainer />;
		default:
			return <PainExplainer />;
	}
};

export default ExplainerSwitch;
