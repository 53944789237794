import { ui } from '@/components/ui';
import DiaryTitle from '@/features/dashboard/features/interface/components/DiaryTitle';
import PatientCard from '@/features/dashboard/features/patient/PatientCard';
import { diaryByIdQueryOptions, useDiaryByIdQuery } from '@/features/diary/api/getDiaryById';
import EditDiary from '@/features/diary/edit/components/EditDiary';
import { useProfileByIdQuery } from '@/features/profile/api/selectProfile';
import { Link, createFileRoute } from '@tanstack/react-router';
import { ArrowLeft, X } from 'lucide-react';

const EditDiaryDashboardPage = () => {
	const { diaryId } = Route.useParams();
	const { data: diary } = useDiaryByIdQuery(diaryId);
	const { data: profile } = useProfileByIdQuery(diary?.patient_id);
	return (
		<section className="flex flex-col h-full">
			<ui.Button asChild variant="outline" size="md" className="mx-0 mt-2">
				<Link
					data-testid="back-to-diary-button"
					to="/dashboard/diary/$diaryId"
					diaryId={{ diaryId: diaryId }}
					search={(prev) => ({ ...prev })}
				>
					<ArrowLeft />
					Back to Diary
				</Link>
			</ui.Button>
			<div className="flex flex-col gap-2 mt-3 px-1 pb-8 h-full overflow-y-scroll">
				<DiaryTitle patientName={profile?.name as string} isEditing={true} diaryId={diaryId} />
				<PatientCard profile={profile} editable={true} />
				<EditDiary diary={diary} />
			</div>
		</section>
	);
};
export const Route = createFileRoute('/_app/dashboard/diary/$diaryId/edit')({
	component: EditDiaryDashboardPage,
	loader: async ({ context, params }) => {
		const diary = await context?.queryClient.ensureQueryData(diaryByIdQueryOptions(params.diaryId));
		return { diary: diary };
	},
});
