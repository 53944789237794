import { supabaseClient } from '@/lib/supabase';
import type { DiaryAccess } from '@/types/database/diaries';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'sonner';

export async function insertDiaryAccess(diaryAccess: DiaryAccess['Insert']) {
	const { data, error } = await supabaseClient.from('diary_access').insert(diaryAccess).select('*').single();
	if (error) throw error;
	return data;
}

const diary_access_mutation_key = ['diary_access'];

export const useInsertDiaryAccess = () => {
	return useMutation({
		mutationKey: diary_access_mutation_key,
		mutationFn: (diaryAccess: DiaryAccess['Insert']) => insertDiaryAccess(diaryAccess),
		onError: () => {
			toast.error('Failed to save diary access');
		},
		onSuccess: () => {
			toast.success('Diary access saved');
		},
	});
};
