interface Props {
	className: string;
	stroke?: string;
}

const Maximize = ({ className, stroke }: Props) => {
	return (
		<svg
			className={className}
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M13.7273 1H17.5C18.0523 1 18.5 1.44772 18.5 2V5.5"
				stroke={stroke ? stroke : 'black'}
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<path
				d="M5.77271 1H1.99998C1.44769 1 0.999978 1.44772 0.999978 2V5.5"
				stroke={stroke ? stroke : 'black'}
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<path
				d="M13.7273 18.5H17.5C18.0523 18.5 18.5 18.0523 18.5 17.5V14"
				stroke={stroke ? stroke : 'black'}
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<path
				d="M5.77271 18.5H1.99998C1.44769 18.5 0.999978 18.0523 0.999978 17.5V14"
				stroke={stroke ? stroke : 'black'}
				strokeWidth="2"
				strokeLinecap="round"
			/>
		</svg>
	);
};

export default Maximize;
