const ACTION_EMAIL_TEMPLATES = {
    BOOK_APPOINTMENT:
        'I would like to see you at another appointment. Please contact my secretary to arrange this mustafa.amin@nhs.net. Meanwhile, please continue with your Patient-Watch diary.',
    BOOK_PROCEDURE:
        'I would like to arrange further treatment. Please contact my secretary mustafa.amin@nhs.net. Meanwhile, please continue with your Patient-Watch diary.',
    REINSTATE_FROM_DISCONTINUED:
        'I would like you to begin logging your pain scores again using Patient-Watch. Please let me know if you have any questions mustafa.amin@nhs.net.',
    DISCONTINUE_DIARY:
        'Your Patient-Watch diary is complete. No further action is recommended at this stage. However, if you need further help or advice, please contact my secretary mustafa.amin@nhs.net.',
    REMIND_PATIENT_LOG:
        'Please will you start using the Patient-Watch diary again? This is so that I can monitor your pain scores and provide you with the best possible care.',
    REMIND_PATIENT_REG:
        'Please could you sign up to Patient-Watch when you have a moment? Then I can begin tracking your pain levels after you have had your injection.',
    ASK_PATIENT:
        'I just wanted to confirm whether you have managed to arrange a date for your injection. Please contact my secretary if you are having any difficulties.',
};

const STATE_EMAIL_TEMPLATES = {
    NEVER_WORKED: 'I can see that your pain was never reduced by the injection.',
    PAIN_SPIKE: 'I can see that your pain has returned following your injection.',
    PAIN_REDUCED: 'I can see that your pain has remained low following your injection.',
    MIA: 'I can see you have stopped logging your pain scores.',
};

export { ACTION_EMAIL_TEMPLATES };

export { STATE_EMAIL_TEMPLATES };
