import { ui } from '@/components/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import { type SubmitHandler, useForm } from 'react-hook-form';

import { formComponents as fc } from '@/components/form';
import usePasswordLoginMutation from '@/features/auth/hooks/usePasswordLoginMutation';
import { type LoginSchemaType, loginSchema } from '@/features/auth/types/loginSchema';

interface Props {
	email?: string;
	onSuccess?: () => void;
}
const LoginForm = (props: Props) => {
	const form = useForm<LoginSchemaType>({
		resolver: zodResolver(loginSchema),
		defaultValues: {
			email: props.email ?? '',
			password: '',
		},
	});

	const loginMutation = usePasswordLoginMutation();

	const onSubmit: SubmitHandler<LoginSchemaType> = async (formData) => {
		await loginMutation.mutate(formData, { onSuccess: props.onSuccess });
	};

	return (
		<ui.Form {...form}>
			<form onSubmit={form.handleSubmit(onSubmit)} className="form">
				{/* Email */}
				<fc.Email tabIndex={0} />

				<fc.Password tabIndex={0} showPassword={true} showRequirements={true} />

				<ui.Button tabIndex={0} size="lg" type="submit" disabled={loginMutation.isPending}>
					Next
				</ui.Button>
			</form>
		</ui.Form>
	);
};

export default LoginForm;
