import { supabaseClient } from '@/lib/supabase';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';

import { createRedirectUrl } from '../utils/createRedirectUrl';

const resetPassword = async (email: string) => {
	const url = createRedirectUrl('/change-password');
	const { data, error } = await supabaseClient.auth.resetPasswordForEmail(email, {
		redirectTo: url,
	});
	if (error) throw error;
	return data;
};

interface ResetPasswordT {
	email: string;
}
const useForgotPasswordMutation = (showModal: (show: boolean) => void) => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: async (data: ResetPasswordT) => {
			return resetPassword(data.email);
		},
		onError: (error) => {
			toast.error(error.message);
		},
		onSuccess: () => {
			toast.success('Email reset sent');
			showModal(true);
		},
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey: ['profile'] });
			queryClient.invalidateQueries({ queryKey: ['user'] });
		},
	});
};

export default useForgotPasswordMutation;
