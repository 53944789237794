import { commonComponents as cc } from '@/components/common';
import AuthWrap from '@/features/auth/components/AuthWrap';
import useResendEmailSignup from '@/features/auth/hooks/useResendEmailSignup';
import useSkipToProfile from '@/features/auth/hooks/useSkipToProfile';
import useVerifyEmailOtpMutation from '@/features/auth/hooks/useVerifyEmailOtpMutation';
import type { VerifyT } from '@/types/zod/common';
import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';
import MagicOrOtp from '../../features/auth/components/MagicOrOtp';

const VerifyEmail = () => {
	useSkipToProfile();

	const { email } = Route.useSearch();
	const resend = useResendEmailSignup();
	const verifyEmailOtpMutation = useVerifyEmailOtpMutation();

	const verifyEmail = async (formData: VerifyT) => {
		await verifyEmailOtpMutation.mutate({ ...formData, email: email });
	};

	return (
		<AuthWrap>
			<cc.Title words="Enter Confirmation Code" />
			<MagicOrOtp email={email} resendFn={() => resend.mutate(email)} submitFn={verifyEmail} />
		</AuthWrap>
	);
};

const verifyEmailSearchSchema = z.object({
	email: z.string().email(),
});

export const Route = createFileRoute('/_auth/verify')({
	component: VerifyEmail,
	validateSearch: (search) => verifyEmailSearchSchema.parse(search),
	beforeLoad: async ({ context: { auth } }) => {
		return auth;
	},
});
