import { DiaryTemplateT, FavouriteDiaryT } from '../../../types/database/diaries';

export function extractFavouriteDiaries(list1: DiaryTemplateT[], list2: FavouriteDiaryT[]) {
    try {
        const list2Ids = list2?.map((item) => item.diary_template_id);

        const matching = list1?.filter((item) => list2Ids?.includes(item.diary_template_id));
        const notMatching = list1?.filter((item) => !list2Ids?.includes(item.diary_template_id));

        return { matching, notMatching };
    } catch (e) {
        console.log(e);
        return { matching: [], notMatching: [] };
    }
}
