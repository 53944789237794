import { selectProfileByIdQueryOptions } from '@/features/profile/api/selectProfile';
import ProfileWrap from '@/features/profile/layouts/ProfileWrap';
import { useAuth } from '@/utils/auth';
import { useSuspenseQuery } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import { useNavigate } from '@tanstack/react-router';
import DateOfBirthForm from '../../../features/profile/components/DateOfBirthForm';

const DateOfBirth = () => {
	const auth = useAuth();
	const { data: profile } = useSuspenseQuery(selectProfileByIdQueryOptions(auth.id));
	const navigate = useNavigate();
	return (
		<ProfileWrap title="Date of Birth">
			<DateOfBirthForm
				onSuccess={() => navigate({ to: '/profile' })}
				userId={auth.id}
				dateOfBirth={profile.date_of_birth}
			/>
		</ProfileWrap>
	);
};
export const Route = createFileRoute('/_app/profile/date-of-birth')({
	component: DateOfBirth,
});

export default DateOfBirth;
