import type { StatefulDiaryT } from '@/types/database/diaries';

import { AlertCircle } from 'lucide-react';
import Call from '../../../components/common/Call';
import { useAuth } from '../../../utils/auth';
import { hideTemplate } from '../utils/hideTemplate';
import TrackerTemplateCard from './TrackerTemplateCard';

interface Props {
	diary: StatefulDiaryT;
	trackerId: string;
	onSuccess: () => void;
}

const DiaryTrackersTemplates = ({ diary, onSuccess, trackerId }: Props) => {
	const auth = useAuth();
	const templates = diary?.diary_templates?.tracker_templates_view.filter((ttv) => !hideTemplate(auth, ttv));
	return (
		<div>
			{templates?.length ? (
				<ul className="flex flex-col gap-y-4">
					{templates
						?.sort((a, b) => (a.tracker_template_name || '').localeCompare(b.tracker_template_name || ''))
						.map((tt) => (
							<li key={tt.tracker_template_id}>
								<TrackerTemplateCard
									trackerId={trackerId}
									diaryId={diary.diary_id}
									onSuccess={onSuccess}
									tracker_template={tt}
								/>
							</li>
						))}
				</ul>
			) : (
				<Call icon={<AlertCircle />} words="No Trackers">
					No Tracker Templates available for this Diary Template
				</Call>
			)}
		</div>
	);
};

export default DiaryTrackersTemplates;
