import { ui } from '@/components/ui';
import type { DashboardSearchT } from '@/routes/_app/dashboard';
import { Route as DashbordRoute } from '@/routes/_app/dashboard/diary/$diaryId';
import type { DiariesCountT, TabKeyT } from '@/types/database/diaries';
import { useNavigate } from '@tanstack/react-router';

interface Props {
	tab: TabKeyT;
	diariesCount: DiariesCountT;
}
const StateTabs = ({ tab, diariesCount }: Props) => {
	const navigate = useNavigate({ from: DashbordRoute.fullPath });
	return (
		<ui.Tabs
			value={tab ?? 'active'}
			onValueChange={(val) =>
				navigate({
					search: (prev) =>
						({ ...prev, tab: val, pagination: { ...prev.pagination, pageIndex: 0 } }) as DashboardSearchT,
				})
			}
		>
			<ui.TabsList className="gap-x-1 mr-1 h-fit">
				<ui.TabsTrigger className="p-2" value="active">
					Active
					<span className="flex justify-center items-center bg-indigo-300 bg-opacity-50 ml-2 rounded-full w-6 h-6 text-center text-xs">
						{diariesCount?.active}
					</span>
				</ui.TabsTrigger>
				<ui.TabsTrigger className="p-2" value="pending">
					Pending{' '}
					<span className="flex justify-center items-center bg-indigo-300 bg-opacity-50 ml-2 rounded-full w-6 h-6 text-center text-xs">
						{diariesCount?.pending}
					</span>
				</ui.TabsTrigger>
				<ui.TabsTrigger className="p-2" value="inactive">
					Inactive{' '}
					<span className="flex justify-center items-center bg-indigo-300 bg-opacity-50 ml-2 rounded-full w-6 h-6 text-center text-xs">
						{diariesCount?.inactive}
					</span>
				</ui.TabsTrigger>
			</ui.TabsList>
		</ui.Tabs>
	);
};

export default StateTabs;
