import { supabaseClient } from '@/lib/supabase';
import { queryOptions, useSuspenseQuery } from '@tanstack/react-query';

export async function selectQuickstartById(quickstartId: string) {
	const { data, error } = await supabaseClient
		.from('quickstarts_view')
		.select('*')
		.eq('quickstart_id', quickstartId)
		.single();
	if (error) throw error;
	return data;
}

export const quickstart_by_id_query_key = (quickstartId: string) => ['quickstart', { quickstart_id: quickstartId }];

export const quickstartByIdQueryOptions = (quickstartId: string) => {
	return queryOptions({
		queryKey: quickstart_by_id_query_key(quickstartId),
		queryFn: () => selectQuickstartById(quickstartId),
	});
};

export function useQuickstartByIdQuery(quickstartId: string) {
	return useSuspenseQuery(quickstartByIdQueryOptions(quickstartId));
}
