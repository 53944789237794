import { Link } from '@tanstack/react-router';
import { Clipboard } from 'lucide-react';

import { ui } from '@/components/ui';
import type { DiaryTemplateT } from '@/types/database/diaries';

interface Props {
	diary_template: DiaryTemplateT;
}
const DiaryTemplateCard = ({ diary_template: dt }: Props) => {
	return (
		<ui.Card className="w-full">
			<ui.CardHeader>
				<ui.CardTitle>{dt.diary_template_name}</ui.CardTitle>
				<ui.CardDescription>{dt.diary_template_description}</ui.CardDescription>
			</ui.CardHeader>
			<ui.CardContent>
				<ui.Card>
					<ui.CardHeader className="flex flex-row items-center gap-2 font-medium text-lg text-slate-800">
						<Clipboard />
						{dt.form ? dt.form.form_name : 'No Form'}
					</ui.CardHeader>
				</ui.Card>
			</ui.CardContent>
			<ui.CardFooter>
				<ui.Button asChild>
					<Link to="/builder/diary-templates/$diaryTemplateId" params={{ diaryTemplateId: dt.diary_template_id }}>
						Open
					</Link>
				</ui.Button>
			</ui.CardFooter>
		</ui.Card>
	);
};

export default DiaryTemplateCard;
