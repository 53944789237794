interface Props {
    fill?: string;
    stroke?: string;
    strokeWidth?: string;
}

const Copy = ({ fill, stroke, strokeWidth }: Props) => {
    return (
        <svg
            className="h-full w-full"
            width="70"
            height="70"
            viewBox="0 0 70 70"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="24"
                y="12"
                width="34"
                height="34"
                rx="3"
                fill={fill ? fill : 'white'}
                stroke={stroke ? stroke : 'black'}
                strokeWidth={strokeWidth ? strokeWidth : '4'}
            />
            <rect
                x="12"
                y="24"
                width="34"
                height="34"
                rx="3"
                fill={fill ? fill : 'white'}
                stroke={stroke ? stroke : 'black'}
                strokeWidth={strokeWidth ? strokeWidth : '4'}
            />
        </svg>
    );
};

export default Copy;
