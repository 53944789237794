import { createFileRoute } from '@tanstack/react-router';
import StartFootCheck from '../new/start';

export const Route = createFileRoute('/_app/foot-watch/$diaryId/_view/new')({
    component: () => (
        <section className="max-w-md mx-auto">
            <StartFootCheck />
        </section>
    ),
});
