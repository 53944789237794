const AppleAddToHomescreen = () => {
    return (
        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M35 12H15C13.3431 12 12 13.3431 12 15V35C12 36.6569 13.3431 38 15 38H35C36.6569 38 38 36.6569 38 35V15C38 13.3431 36.6569 12 35 12ZM15 10C12.2386 10 10 12.2386 10 15V35C10 37.7614 12.2386 40 15 40H35C37.7614 40 40 37.7614 40 35V15C40 12.2386 37.7614 10 35 10H15Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M25 16C25.5523 16 26 16.4477 26 17V33C26 33.5523 25.5523 34 25 34C24.4477 34 24 33.5523 24 33V17C24 16.4477 24.4477 16 25 16Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M34 25C34 25.5523 33.5523 26 33 26L17 26C16.4477 26 16 25.5523 16 25C16 24.4477 16.4477 24 17 24L33 24C33.5523 24 34 24.4477 34 25Z"
                fill="black"
            />
        </svg>
    );
};

export default AppleAddToHomescreen;
