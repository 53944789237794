import DubQrCode from '@/features/dub/components/DubQrCode';
import { quickstartByIdQueryOptions } from '@/features/quickstart/api/selectQuickstartById';
import { createFileRoute } from '@tanstack/react-router';
import { useRouter } from '@tanstack/react-router';

const Fisher = () => {
	const { quickstart } = Route.useLoaderData();
	const router = useRouter();

	const url = router.buildLocation({
		to: '/signup',
		search: {
			role: quickstart?.role ?? undefined,
			favourite_diary_templates: quickstart?.favourite_diary_templates?.map((dt) => dt.diary_template_id),
			organisation: {
				organisation_id: quickstart.organisation_id,
			},
			quickstart: quickstart,
		},
	}).href;

	return (
		<div className="flex flex-row flex-wrap justify-center items-center p-4 md:p-8 lg:p-16">
			<DubQrCode
				description={quickstart.quickstart_description}
				title={quickstart.quickstart_name}
				url={window.location.origin + url}
			/>
		</div>
	);
};

export const Route = createFileRoute('/_auth/quickstart/$quickstartId')({
	component: Fisher,
	loader: async ({ context, params }) => {
		const { quickstartId } = params;
		const quickstart = await context.queryClient.ensureQueryData(quickstartByIdQueryOptions(quickstartId));
		return { quickstart };
	},
});
