import { hooks as h } from '@/hooks';
import { Outlet, createFileRoute } from '@tanstack/react-router';

const New = () => {
	h.useNav([{ route: { to: Route.to }, label: 'New Diary' }]);

	return (
		<section className="lg-width">
			<Outlet />
		</section>
	);
};

export const Route = createFileRoute('/_app/new-diary')({
	component: New,
});
