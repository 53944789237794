import type { EntryValueT } from '@/types/database/entries';
import React from 'react';
import { toast } from 'sonner';

import type { UpsertEntryT } from '../api/upsertEntry';
import { useEntryMutation } from '../api/upsertEntry';
import { useEntryValuesMutation } from '../api/upsertEntryValues';

const useHandleNewEntry = (diaryId?: string) => {
	const upsertEntry = useEntryMutation();
	const upsertEntryValues = useEntryValuesMutation(diaryId);

	React.useEffect(() => {
		if (upsertEntryValues.isSuccess) {
			upsertEntryValues.reset();
			toast.info('Entry saved');
		}
	}, [upsertEntryValues]);

	const handleNewEntry = async (entry: UpsertEntryT, entryValues: EntryValueT[]) => {
		upsertEntry.mutateAsync(entry).then(async () => {
			await upsertEntryValues.mutate(entryValues);
		});
	};

	return { handleNewEntry };
};

export default useHandleNewEntry;
