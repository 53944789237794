import React from 'react';

const Equals = () => {
    return (
        <svg width="20" height="11" viewBox="0 0 20 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="20" y="11" width="20" height="3" transform="rotate(-180 20 11)" fill="black" />
            <rect x="20" y="3" width="20" height="3" transform="rotate(180 20 3)" fill="black" />
        </svg>
    );
};

export default Equals;
