import { formComponents as fc } from '@/components/form';
import { ui } from '@/components/ui';
import type { ExtendedDiaryViewT } from '@/types/database/diaries';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate, useRouteContext } from '@tanstack/react-router';
import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';
import type { SubmitHandler } from 'react-hook-form';
import type { DiaryTypeT } from '../../../types/zod/common';

import useInsertDiaryMutation from '../../diary/api/insertDiary';
import useHandleNewDiary from '../hooks/useHandleNewDiary';
import type { NewDiarySchemaT } from '../types/newDiarySchema';
import { NewDiarySchemaZ } from '../types/newDiarySchema';

interface Props {
	diaryId: string;
	patientId?: string;
	template?: ExtendedDiaryViewT;
}
const NewDiaryForm = ({ diaryId, patientId, template }: Props) => {
	const navigate = useNavigate();
	const nav = useN;
	const insertDiary = useInsertDiaryMutation();

	const form = useForm<NewDiarySchemaT>({
		resolver: zodResolver(NewDiarySchemaZ),
		defaultValues: {
			patient_id: patientId,
			diary_id: diaryId,
			diary_type: template?.diary_type as DiaryTypeT,
			diary_name: template?.diary_template_name ?? '',
			diary_template_id: template?.diary_template_id ?? '',
			start_date: dayjs().toDate(),
		},
	});

	const onSubmit: SubmitHandler<NewDiarySchemaT> = async (formData) => {
		insertDiary.mutate(formData);
	};

	return (
		<ui.Form {...form}>
			<form onSubmit={form.handleSubmit(onSubmit)} className="form">
				{/* Diary Name */}
				<ui.FormField
					control={form.control}
					name="diary_name"
					data-cy="diary_name"
					render={({ field }) => (
						<ui.FormItem className="">
							<ui.FormLabel className="">Diary Name</ui.FormLabel>
							<ui.Input placeholder="e.g. Pain in Ankle Bone" type="text" {...field} />
							<ui.FormMessage />
						</ui.FormItem>
					)}
				/>

				<fc.Date label="Start Date" name="start_date" />

				<div className="my-4 flex flex-col gap-y-4">
					<ui.Button
						onClick={() => {
							console.log(form.formState.errors);
							console.log(form.getValues());
						}}
						size="lg"
						type="submit"
					>
						Next
					</ui.Button>
					<ui.Button onClick={() => navigate(nav.backRoute.route)} size="lg" variant="secondary" type="submit">
						Back
					</ui.Button>
				</div>
			</form>
		</ui.Form>
	);
};

export default NewDiaryForm;
