import { components as c } from '@/components';
import { Route as EditEventRoute } from '@/routes/_app/diary/$diaryId/edit/event/$eventId';
import type { ExtendedEventViewT } from '@/types/database/events';
import { Link } from '@tanstack/react-router';

import EventCard from './EventCard';

interface Props {
	event: ExtendedEventViewT;
	openable?: boolean;
}

const EventPreview = ({ event, openable }: Props) => {
	return (
		<div className="flex flex-col gap-y-2">
			<div className="flex flex-row justify-between items-center w-full text-md">
				{openable && (
					<c.cc.EditButton>
						<Link to={EditEventRoute.to} params={{ diaryId: event.diary_id, eventId: event.event_id }}>
							Open
						</Link>
					</c.cc.EditButton>
				)}
			</div>
			{/* FIXME - Events can have many trackers and this is a quick fix to allow multiple trackers */}
			<EventCard showCard={false} trackerId={event?.trackers?.length && event.trackers[0]?.tracker_id} event={event} />
		</div>
	);
};

export default EventPreview;

export const EventPreviews = ({ events, openable }: { events: ExtendedEventViewT[]; openable?: boolean }) => (
	<ul className="flex flex-col-reverse">
		{events?.map((e) => (
			<li key={e.event_id} className="space-y-2">
				<EventPreview openable={openable} event={e} />
			</li>
		))}
	</ul>
);
