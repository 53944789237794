import { cn } from '../../lib/utils';

interface Props {
    fill?: string;
    stroke?: string;
    strokeWidth?: string;
    className?: string;
}

const Camera = ({ fill, stroke, strokeWidth, className }: Props) => {
    return (
        <svg
            className={cn('h-full w-full', className)}
            width="70"
            height="70"
            viewBox="0 0 70 70"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M23.3095 22.1034H15C13.8954 22.1034 13 22.9989 13 24.1034V52C13 53.1046 13.8954 54 15 54H55C56.1046 54 57 53.1046 57 52V24.1034C57 22.9989 56.1046 22.1034 55 22.1034H46.0708C45.5303 22.1034 45.0129 21.8847 44.6363 21.497L40.8567 17.6064C40.4801 17.2187 39.9626 17 39.4222 17H29.9581C29.4176 17 28.9002 17.2187 28.5236 17.6064L24.744 21.497C24.3674 21.8847 23.85 22.1034 23.3095 22.1034Z"
                stroke={stroke ? 'currentColor' : '#5554C3'}
                strokeWidth={strokeWidth ? strokeWidth : '3'}
            />
            <circle
                cx="35"
                cy="37"
                r="9.75"
                stroke={stroke ? 'currentColor' : '#5554C3'}
                fill={fill ? fill : 'rbga(0, 0, 0, 0)'}
                strokeWidth={strokeWidth ? strokeWidth : '3'}
            />
        </svg>
    );
};

export default Camera;
