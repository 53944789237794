import { StatefulTrackerViewT } from '@/types/database/trackers';

import { trackerComponents as tc } from '.';

interface Props {
	tracker: StatefulTrackerViewT;
}
const TrackerBody = ({ tracker }: Props) => {
	return (
		<div className="flex flex-col gap-y-4">
			<tc.ManagementTypeLinks
				trackerId={tracker.tracker_id!}
				injections={tracker.injections_view}
				managementTypes={tracker.management_types}
			/>
		</div>
	);
};

export default TrackerBody;
