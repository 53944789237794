import { selectProfileByIdQueryOptions } from '@/features/profile/api/selectProfile';
import { Outlet, createFileRoute } from '@tanstack/react-router';

import Title from '@/components/common/Title';

export const Route = createFileRoute('/_app/clinician/diaries')({
	beforeLoad: async ({ context }) => {
		const profile = await context?.queryClient.ensureQueryData(selectProfileByIdQueryOptions(context.auth.id));
		return {
			profile: profile,
			auth: context.auth,
		};
	},
	component: () => (
		<section className="mx-auto my-8 max-w-md">
			<Title words="Admin Diaries" />
			<Outlet />
		</section>
	),
	errorComponent: () => <p>Profile Error</p>,
});
