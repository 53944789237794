import { ui } from '@/components/ui';
import { useSelectFormById } from '@/features/forms/api/selectFormById';
import { inputComponents as ic } from '@/features/inputs/components';
import { hooks as h } from '@/hooks';
import { createFileRoute, Outlet } from '@tanstack/react-router';

const InputInputId = () => {
	const { inputId, pageInputId, formId } = Route.useParams();
	const { data: form } = useSelectFormById(formId);

	h.useNav([
		{
			label: 'Builder',
			route: { to: '/builder' },
		},
		{
			label: 'Forms',
			route: { to: '/builder/form/templates' },
		},
	]);

	return (
		<div>
			<div className="flex w-full items-center justify-between gap-4 px-2 py-4">
				<ui.Input className="text-xl" value={form?.form_name} />
				{/* <tc.AddInputDialog formId={formId} /> */}
			</div>

			<div className="flex flex-col lg:flex-row">
				<ui.ResizablePanelGroup direction="horizontal">
					<ui.ResizablePanel>
						<Outlet />
					</ui.ResizablePanel>
					<ui.ResizableHandle className="mx-2" />
					<ui.ResizablePanel>
						<ic.OrderInputs inputId={inputId} pageInputId={pageInputId} pages={form?.pages} />
					</ui.ResizablePanel>
				</ui.ResizablePanelGroup>
			</div>
		</div>
	);
};

export const Route = createFileRoute('/_app/builder/tracker-templates/$formId/page-input/$pageInputId/input/$inputId')({
	component: InputInputId,
});
