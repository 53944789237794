import FaqBlock from '@/features/website/components/FaqBlock';
import Hero from '@/features/website/components/Hero';
import Section from '@/features/website/components/Section';
import GDPR from '@/features/website/security/Gdpr';
import Technical from '@/features/website/security/Technical';
import { securityFAQs } from '@/features/website/security/content/securityFaqs';
import { createFileRoute } from '@tanstack/react-router';
import { useRef } from 'react';

// import SecurityTestimonial from "@/website/security/SecurityTestimonial";
const Security = () => {
	const firstContentRef = useRef<HTMLDivElement>(null);
	return (
		<>
			<Section>
				<Hero
					title="We make sure your data stays yours"
					description={
						<>
							Patient Watch is GDPR compliant and DPIA approved by NHS organisations. Dealing with patient data is
							always scary so we are constantly working to make trusting us easier.
						</>
					}
					caption="Security"
				/>
			</Section>

			<div ref={firstContentRef} />
			<Section>
				<Technical />
			</Section>

			{/* <SecurityTestimonial /> */}
			<GDPR />
			<Section>
				<FaqBlock FAQs={securityFAQs} />
			</Section>
		</>
	);
};

export const Route = createFileRoute('/_website/security')({
	component: Security,
});

export default Security;
