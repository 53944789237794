import type { StepT } from '@/components/common/Stepper';
import type { PageViewT } from '@/types/database/diaries';
import type { RouteT } from '@/types/zod/routes';

export const stepperizeDiaryPages = (pages: PageViewT[], pageIndex: number, to: RouteT): StepT[] => {
	const sortedByPage = pages?.sort((a, b) => {
		return a.page_index - b.page_index;
	});

	const stepperized = sortedByPage?.map((p) => {
		return {
			words: p.page_label ?? 'Page' + p.page_index,
			stage: p.page_index === pageIndex ? 'current' : p.page_index < pageIndex ? 'past' : 'future',
			route: {
				to: to,
				params: { pageIndex: p.page_index },
			},
		};
	});

	return stepperized as StepT[];
};
