import { defaultLineSettings } from '@/features/charts/services/generateData';
import type { ChartDataset } from 'chart.js';
import {  Line } from 'react-chartjs-2';
import { generateOptions } from '@/features/charts/services/generateOptions';
import { colors } from '../../../assets/colors';
type UserStat = any & { created_at: string; users: number };

const UsersSparkChart = ({
	usersStats,
}: {
	usersStats:UserStat[];
}) => {
	const data = usersStats.map((stat) => ({
		x: stat.created_at,
		y: stat.users,
	}));
	const patients = usersStats.map((stat) => ({
		x: stat.created_at,
		y: stat.patients,
	}));
		const clinicians = usersStats.map((stat) => ({
		x: stat.created_at,
		y: stat.clinicians,
		}));
		const secretaries = usersStats.map((stat) => ({
		x: stat.created_at,
		y: stat.secretaries,
	}));

	const datasets = {
		labels: ['Users','Patients','Clinicians','Secretaries'],
		datasets: [
			{
				label: 'Users',
				data: data,
				...defaultLineSettings,
				pointRadius: 4,
				pointBorderWidth: 2,
				tension: 0.4,
				borderWidth: 3,
				borderColor: colors['blue-300'],
			},
				{
				label: 'Patients',
				data: patients,
				...defaultLineSettings,
				pointRadius: 4,
				pointBorderWidth: 2,
				tension: 0.4,
					borderWidth: 3,
				borderColor: colors['purple-300'],
				
			},
					{
				label: 'Clinicians',
				data: clinicians,
				...defaultLineSettings,
				pointRadius: 4,
				pointBorderWidth: 2,
				tension: 0.4,
						borderWidth: 3,
				borderColor: colors['zinc-300'],
				
			},
						{
				label: 'Secretaries',
				data: secretaries,
				...defaultLineSettings,
				pointRadius: 4,
				pointBorderWidth: 2,
				tension: 0.4,
							borderWidth: 3,
				borderColor: colors['cyan-300'],
				
			},
		],
	};
	const usersOverTime = usersStats.map((cs) => cs.users);
	// Find how many diaries created by the clinician
	const max = Math.max(...usersOverTime);
	// Roundup to nearest 10
	const maxY = Math.ceil(max / 10) * 10;

	const penultimate = usersOverTime[usersOverTime.length - 2];
	const increase = penultimate ? max - penultimate : undefined;
	const percentage = penultimate ? Math.round((increase / penultimate) * 100) : undefined;

	const options = generateOptions({
		datasets: datasets as ChartDataset,
		x: {
			display: false,
		},
		y: { min: 0, max: maxY, display: false },
		legend: {
			display: false,
		},
	});

	return (
		<div className="flex flex-col justify-between items-between w-full h-full">
			<div>
				<b className="font-bold text-2xl">{max} Users</b>
				<div className="flex justify-start gap-4">
					<span className="text-sm">+{increase} Users</span>
					<span className="text-sm">+{percentage}%</span>
				</div>
			</div>
			<div className="flex w-full h-24">
				<Line className="h-10" options={options} data={datasets} />
			</div>
		</div>
	);
};

export default UsersSparkChart;