interface Props {
    active?: boolean;
    className?: string;
}

const Edit = ({ className, active }: Props) => {
    return (
        <svg
            className={className}
            width="27"
            height="27"
            viewBox="0 0 27 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_1719_5545)">
                <path
                    d="M25.4024 6.24022L20.9999 1.81522C20.7089 1.52577 20.3152 1.36328 19.9049 1.36328C19.4945 1.36328 19.1008 1.52577 18.8099 1.81522L3.20236 17.4002L1.77736 23.5502C1.7282 23.775 1.72989 24.008 1.78229 24.2321C1.8347 24.4562 1.9365 24.6657 2.08026 24.8454C2.22402 25.0251 2.4061 25.1704 2.61322 25.2707C2.82033 25.371 3.04724 25.4238 3.27736 25.4252C3.38459 25.4361 3.49263 25.4361 3.59986 25.4252L9.81736 24.0002L25.4024 8.43022C25.6918 8.1393 25.8543 7.74561 25.8543 7.33522C25.8543 6.92483 25.6918 6.53114 25.4024 6.24022V6.24022ZM9.06736 22.6502L3.23986 23.8727L4.56736 18.1577L16.2449 6.52522L20.7449 11.0252L9.06736 22.6502ZM21.7499 9.93772L17.2499 5.43772L19.8599 2.84272L24.2849 7.34272L21.7499 9.93772Z"
                    fill="black"
                />
            </g>
            {active && (
                <>
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M22.8546 21.7945C22.5638 22.0894 22.0906 22.091 21.7977 21.798L5.53423 5.53458C5.2413 5.24165 5.23956 4.76512 5.53035 4.47023C5.82114 4.17533 6.29435 4.17375 6.58728 4.46669L22.8507 20.7301C23.1437 21.0231 23.1454 21.4996 22.8546 21.7945Z"
                        fill="white"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M21.794 22.8544C21.5011 23.1473 21.0262 23.1473 20.7333 22.8544L4.46989 6.59098C4.17699 6.29809 4.17699 5.82321 4.46989 5.53032C4.76278 5.23743 5.23765 5.23743 5.53055 5.53032L21.794 21.7938C22.0869 22.0867 22.0869 22.5615 21.794 22.8544Z"
                        fill="black"
                    />
                </>
            )}

            <defs>
                <clipPath id="clip0_1719_5545">
                    <rect width="27" height="27" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default Edit;
