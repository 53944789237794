import { injectionApi as ia } from '@/features/injection/api';
import InjectionDetailsFormFields from '@/features/injection/components/InjectionDetailsFormFields';
import type { InjectionLocationT } from '@/types/database/pain';
import type { InjectionContentT } from '@/types/database/pain';
import { createFileRoute } from '@tanstack/react-router';
import { useFormContext } from 'react-hook-form';

const InjectionTime = () => {
	const form = useFormContext();
	const { injectionLocations, injectionContents } = Route.useLoaderData();

	return (
		<InjectionDetailsFormFields
			injectionLocations={injectionLocations}
			injectionContents={injectionContents}
			injectionId={form.getValues(['injection_id'])[0]}
		/>
	);
};

export const Route = createFileRoute(
	'/_app/new-diary/patient/$patientId/organisation/$organisationId/diary/guided/injection-diary/details',
)({
	component: InjectionTime,
	loader: async ({ context }) => {
		const injectionLocations = (await context?.queryClient.ensureQueryData(
			ia.injectionLocationsQueryOptions(),
		)) as InjectionLocationT[];
		const injectionContents = (await context?.queryClient.ensureQueryData(
			ia.injectionContentsQueryOptions(),
		)) as InjectionContentT[];

		return { injectionLocations, injectionContents };
	},
});
