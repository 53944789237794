import { Control, useFormContext } from 'react-hook-form';

import { ui } from '../ui';

interface Props {
	control: Control;
	name: string;
	label: string;
}

const YesNo: React.FC<Props> = ({ name, label }) => {
	const { control } = useFormContext();
	return (
		<ui.FormField
			control={control}
			name={name}
			render={({ field }) => (
				<ui.FormItem className="space-y-3">
					<ui.FormLabel>{label}</ui.FormLabel>
					<ui.FormControl>
						<ui.RadioGroup
							onValueChange={field.onChange}
							defaultValue={field.value}
							className="mx-auto flex w-fit items-center space-x-3 space-y-0 rounded-md bg-slate-100 p-4"
						>
							<ui.FormItem className="flex items-center space-x-3 space-y-0">
								<ui.FormControl>
									<ui.RadioGroupItem value="yes" />
								</ui.FormControl>
								<ui.FormLabel className="font-normal">Yes</ui.FormLabel>
							</ui.FormItem>
							<ui.FormItem className="flex items-center space-x-3 space-y-0">
								<ui.FormControl>
									<ui.RadioGroupItem value="no" />
								</ui.FormControl>
								<ui.FormLabel className="font-normal">No</ui.FormLabel>
							</ui.FormItem>
						</ui.RadioGroup>
					</ui.FormControl>
					<ui.FormMessage />
				</ui.FormItem>
			)}
		/>
	);
};

export default YesNo;
