import { supabaseClient } from '@/lib/supabase';
import { queryOptions, useQuery } from '@tanstack/react-query';

export async function selectOrganisationUsersWithFavouritesByOrganisationId(organisationId: string) {
	const { data, error } = await supabaseClient
		.from('organisations')
		.select(
			`
			*,
			users:users_organisations_roles(
                profiles!users_organisations_roles_user_id_fkey(
                user_id,
                name,
                email,
                clinician_number,
                roles,
                favourite_users:favourite_users!favourite_profiles_favourited_by_fkey(
                    profiles!favourite_profiles_favourited_user_fkey(user_id,name,email,clinician_number,roles, organisations)
                    )
                )
            )
        )
			`,
		)
		.eq('organisation_id', organisationId)
		.single();
	if (error) throw error;

	return data;
}

export const organisationUsersWithFavouritesQueryKey = (organisationId: string) => [
	'organisation-users-with-favourites',
	organisationId,
];

export const selectOrganisationUsersWithFavouritesByOrganisationIdQueryOptions = (organisationId: string) => {
	return queryOptions({
		queryKey: organisationUsersWithFavouritesQueryKey(organisationId),
		queryFn: () => selectOrganisationUsersWithFavouritesByOrganisationId(organisationId),
	});
};

export function useSelectOrganisationUsersWithFavourites(organisationId: string) {
	const query = useQuery(selectOrganisationUsersWithFavouritesByOrganisationIdQueryOptions(organisationId));
	return query;
}
