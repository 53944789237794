import { type SubmitHandler, useForm, z, zodResolver } from "@/utils/form";
import { Link } from "lucide-react";
import React from "react";
import Call from "../../../components/common/Call";
import { formComponents as fc } from "../../../components/form";
import type { OptionT } from "../../../components/form/Select";
import { ui } from "../../../components/ui";
import { buttonVariants } from "../../../components/ui/button";
import { cn } from "../../../lib/utils";
import { useProfileQuery } from "../../profile/api/selectProfile";
import { useSelectProfiles } from "../../profile/api/selectProfiles";
import { useImpersonateMutation } from "../api/useImpersonateMutation";
import { Copy } from "../../../components/common/Copy";
const ImpersonateSchemaZ = z.object({
	user_email: z.string().email(),
	admin_email: z.string().email(),
});

export type ImpersonateSchemaT = z.infer<typeof ImpersonateSchemaZ>;

const ImpersonateUserDialog = () => {
	const [impersonateLink, setImpersonateLink] = React.useState<any>(
		undefined,
	);
	const { data: profile } = useProfileQuery();
	const { data: profiles } = useSelectProfiles();

	const { mutate: impersonate } = useImpersonateMutation({});

	const form = useForm<ImpersonateSchemaT>({
		resolver: zodResolver(ImpersonateSchemaZ),
		defaultValues: {
			admin_email: profile?.email ?? "",
		},
	});

	const onSubmit: SubmitHandler<ImpersonateSchemaT> = async (formData) => {
		await impersonate(formData, {
			onSuccess: (res) => {
				console.info(res.properties.action_link);
				setImpersonateLink(res.properties.action_link);
			},
		});
	};

	return (
		<ui.Dialog>
			<ui.DialogTrigger
				className={cn(buttonVariants({ size: "md" }), "")}
			>
				Impersonate User
			</ui.DialogTrigger>
			<ui.DialogContent>
				<ui.DialogHeader>
					<ui.DialogTitle className="text-slate-900 dark:text-slate-100">
						Impersonate
					</ui.DialogTitle>
				</ui.DialogHeader>
				<ui.ScrollArea className="md:p-2 lg:p-4 max-h-[80vh]">
					{impersonateLink && (
						<Call
							icon={<Link />}
							words={
								<Copy
									id={impersonateLink}
									value={impersonateLink}
								>
									Impersonate User
								</Copy>
							}
						/>
					)}
					<ui.Form {...form}>
						<form
							className="form"
							onSubmit={form.handleSubmit(onSubmit)}
						>
							<fc.Input label="Admin Email" name="admin_email" />
							<fc.ComboBox
								label="User Email"
								name="user_email"
								options={profiles?.map((p) => ({
									value: p.email as string,
									label: p.email as string,
								})) as OptionT[]}
							/>
							<ui.Button>Next</ui.Button>
						</form>
					</ui.Form>
					<ui.ScrollBar />
				</ui.ScrollArea>
			</ui.DialogContent>
		</ui.Dialog>
	);
};

export default ImpersonateUserDialog;
