import { supabaseClient } from '@/lib/supabase';
import type { ClinicianT } from '@/types/database/profiles';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';

import { createProfileQueryKey } from './selectProfile';

type PartialClinician = Partial<ClinicianT> & { clinician_id: string };

export async function updateClinician(clinicianId: string, partialClinician: PartialClinician) {
	const { data, error } = await supabaseClient
		.from('clinicians')
		.update(partialClinician)
		.eq('clinician_id', clinicianId)
		.select()
		.single();
	if (error) throw error;
	return data;
}

function useClinicianMutation() {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: (data: PartialClinician) => updateClinician(data.clinician_id, data),
		onSuccess: async (mutation) => {
			toast.success('Saved');
			mutation?.onSuccess?.();
		},
		onError: (error) => {
			toast.error(error.message);
		},
		onSettled: (data) => {
			queryClient.invalidateQueries({ queryKey: createProfileQueryKey(data?.clinician_id ?? '') });
		},
	});
}

export default useClinicianMutation;
