import { create } from 'zustand';

type pwaInstallStatusStore = {
    installed: boolean;
    setInstalled: (mode: boolean) => void;
};

function getDisplayMode() {
    let displayMode = 'browser';
    const mqStandAlone = '(display-mode: standalone)';
    if (navigator.standalone || window.matchMedia(mqStandAlone).matches) {
        displayMode = 'standalone';
    }
    return displayMode;
}

export const usePwaInstallStatusStore = create<pwaInstallStatusStore>((set) => ({
    installed: getDisplayMode() === 'browser' ? false : true,
    setInstalled: (mode) => set(() => ({ installed: mode })),
}));
