import { components as c } from '@/components';
import { ui } from '@/components/ui';
import { selectFormsQueryOptions } from '@/features/forms/api/selectForms';
import { Link, createFileRoute } from '@tanstack/react-router';
const FormTemplatesPage = () => {
	const { forms } = Route.useLoaderData();

	return (
		<section>
			<c.cc.Title words="Form Templates" description="Forms for users to fill out." />
			<ul className="flex lg:flex-row flex-col flex-wrap p-4">
				{forms?.map((f) => (
					<li key={f.form_id} className="p-2 lg:w-1/3">
						<ui.Card>
							<ui.CardHeader>
								<ui.CardTitle>{f.form_name}</ui.CardTitle>
								<ui.CardDescription>{f.form_description}</ui.CardDescription>
							</ui.CardHeader>
							<ui.CardContent>
								{f.pages.map((p) => (
									<div>
										{p.page_name}
										{p.inputs?.map((pi) => (
											<p>{pi.label}</p>
										))}
									</div>
								))}
							</ui.CardContent>
							<ui.CardFooter>
								<ui.Button asChild>
									<Link
										to="/builder/form/$formId/page-input/$pageInputId/input/$inputId/page/$pageIndex"
										params={{
											formId: f.form_id,
											pageInputId: f.pages[0]?.inputs[0]?.input_id,
											inputId: f.pages[0]?.inputs[0]?.input_id,
											pageIndex: 1,
										}}
									>
										Open
									</Link>
								</ui.Button>
							</ui.CardFooter>
						</ui.Card>
					</li>
				))}
			</ul>
		</section>
	);
};

export const Route = createFileRoute('/_app/builder/tracker-templates/')({
	component: FormTemplatesPage,
	loader: async ({ context }) => {
		const forms = await context?.queryClient.ensureQueryData(selectFormsQueryOptions());
		return { forms };
	},
});
