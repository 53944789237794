import { ui } from '@/components/ui';
import NewEntryLink from '@/features/diary/new/components/NewEntryLink';
import { trackerComponents as tc } from '@/features/trackers/components';
import PlayPauseTrackerButton from '@/features/trackers/components/PlayPauseTrackerButton';
import useExtractTracker from '@/features/trackers/hooks/useExtractTracker';
import { useAuth } from '@/utils/auth';
import { createFileRoute } from '@tanstack/react-router';

const EditTracker = () => {
	const auth = useAuth();
	const { diaryId, trackerId } = Route.useParams();
	const tracker = useExtractTracker(diaryId, trackerId);

	return (
		<section>
			<tc.EditTracker tracker={tracker} />
			{/* <tc.TrackerEntries tracker={tracker} /> */}
			<div className="mt-4 flex flex-col gap-4">
				{tracker?.next_entry?.is_locked === false && (
					<ui.Button size="lg" variant="secondary" disabled={tracker?.end_date !== null}>
						<NewEntryLink diaryId={diaryId} trackerId={trackerId} />
					</ui.Button>
				)}
				{tracker.is_pausable && <PlayPauseTrackerButton diaryId={diaryId} tracker={tracker} />}
				{auth.isSuperUser && <tc.DeleteTrackerButton variant="destructive" trackerId={trackerId} />}
			</div>
		</section>
	);
};

export const Route = createFileRoute('/_app/diary/$diaryId/edit/tracker/$trackerId/')({
	component: EditTracker,
});
