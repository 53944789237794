import IconDetail from '@/components/common/IconDetail';
import { ui } from '@/components/ui';
import { useDiaryByIdQuery } from '@/features/diary/api/getDiaryById';
import TrackerButtons from '@/features/trackers/components/TrackerButtons';
import { services as s } from '@/services';
import type { StatefulTrackerViewT } from '@/types/database/trackers';
import { useParams } from '@tanstack/react-router';
import { Calendar } from 'lucide-react';

import NewStateSwitch from '../NewStateSwitch';

interface Props {
	tracker: StatefulTrackerViewT;
}
const NewFisherEntryCard = ({ tracker }: Props) => {
	const { diaryId } = useParams({ strict: false });
	const { data: diary } = useDiaryByIdQuery(diaryId);
	return (
		<ui.Card className="flex flex-col items-center justify-center gap-y-4">
			<ui.CardHeader className="mx-auto flex w-full flex-col items-center justify-center gap-2 text-center">
				<ui.CardTitle>{tracker.end_date === null ? 'Out Fishing' : 'Finished Fishing'}</ui.CardTitle>
				<IconDetail icon={<Calendar />} words={s.time.formatDate(tracker.start_date)} />
			</ui.CardHeader>
			<ui.CardContent className="flex flex-col items-center justify-center">
				<NewStateSwitch diary={diary} tracker={tracker} />
			</ui.CardContent>
			<ui.CardFooter className="flex w-full flex-col gap-2">
				<TrackerButtons
					showNextEntryButton={tracker?.next_entry?.is_locked === false && !!tracker?.next_entry?.due_at}
					stopWords="Stop Fishing"
					startWords="Restart Fishing"
					diaryid={diaryId}
					tracker={tracker}
				/>
			</ui.CardFooter>
		</ui.Card>
	);
};

export default NewFisherEntryCard;
