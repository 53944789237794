import { supabaseClient } from '@/lib/supabase';
import { queryOptions, useQuery } from '@tanstack/react-query';

import type { ClinicianStatisticT } from '@/types/database/profiles';
import type { ProfileT } from '@/types/database/profiles';

export type ClinicianStatisticWithProfileT = ClinicianStatisticT & { profile: ProfileT };
export async function selectCliniciansStatistics(): Promise<ClinicianStatisticWithProfileT[]> {
	const { data, error } = await supabaseClient.from('clinician_statistics').select('*, clinicians(*,profiles(*))');

	if (error) {
		console.error('Error fetching data:', error);
		return [];
	}
	if (data) {
		const flattenedData = data?.map((stat) => {
			const profile = stat.clinicians?.profiles || {};
			return {
				...stat,
				profile,
			};
		});

		return flattenedData as unknown as ClinicianStatisticWithProfileT[];
	}
	return [];
}

export const cliniciansStatisticsQueryKey = ['clinicians-statistics'];

export const selectCliniciansStatisticsQueryOptions = () => {
	return queryOptions({
		queryKey: cliniciansStatisticsQueryKey,
		queryFn: () => selectCliniciansStatistics(),
	});
};

export function useSelectCliniciansStatistics() {
	const profileQuery = useQuery(selectCliniciansStatisticsQueryOptions());
	return profileQuery;
}
