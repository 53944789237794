import { diaryByIdQueryOptions, useDiaryByIdQuery } from '@/features/diary/api/getDiaryById';
import NotYourDiaryCall from '@/features/diary/components/NotYourDiaryCall';
import MissingTrackerReferenceDate from '@/features/trackers/components/MissingTrackerReferenceDate';
import { Outlet, createFileRoute } from '@tanstack/react-router';

const DiaryId = () => {
	const { diaryId } = Route.useParams();
	const { data: diary } = useDiaryByIdQuery(diaryId);

	return (
		<div className="md-width">
			<NotYourDiaryCall organisationName={diary.organisation_name} patientName={diary.patient_info.patient_name} />
			<MissingTrackerReferenceDate diary={diary} />
			<Outlet />
		</div>
	);
};
export const Route = createFileRoute('/_app/diary/$diaryId')({
	beforeLoad: ({ context }) => {
		return {
			auth: context.auth,
		};
	},
	loader: async ({ context, params }) => {
		const diary = await context?.queryClient.ensureQueryData(diaryByIdQueryOptions(params.diaryId));
		return { diary };
	},
	component: DiaryId,
});
