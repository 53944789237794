export const capitaliseSentence = (sentence: string, delimiter = ' ') => {
	if (!sentence) {
		return '';
	}
	const words = sentence.toLowerCase().split(delimiter);
	const capitalisedWords = words.map((word) => {
		const capitalisedWord = word.charAt(0).toUpperCase() + word.slice(1);
		return capitalisedWord;
	});
	return capitalisedWords.join(' ');
};

export const toSnakeCase = (sentence: string) => {
	return sentence.toLowerCase().replace(/ /g, '_');
};

export const toKebabCase = (sentence: string) => {
	return sentence.toLowerCase().replace(/ /g, '-');
};
