import { ui } from '@/components/ui';
import { diaryByIdQueryOptions } from '@/features/diary/api/getDiaryById';
import { hooks as h } from '@/hooks';
import { Link, createFileRoute } from '@tanstack/react-router';

const NewEntry = () => {
	h.useNav([{ route: { to: '/diaries' }, label: 'Diaries' }]);

	return (
		<ui.Card>
			<ui.CardHeader>
				<ui.CardTitle>Diary</ui.CardTitle>
			</ui.CardHeader>
			<ui.CardFooter>
				<ui.Button asChild>
					<Link to="/diary/$diaryId/home/new" params={(prev) => ({ ...prev })}>
						Go to Diary
					</Link>
				</ui.Button>
			</ui.CardFooter>
		</ui.Card>
	);
};

export default NewEntry;

export const Route = createFileRoute('/_app/diary/$diaryId/')({
	component: NewEntry,
	loader: async ({ context, params }) => {
		const diary = await context?.queryClient.ensureQueryData(diaryByIdQueryOptions(params.diaryId));
		return { diary };
	},
});
