import { diaryByIdQueryOptions } from '@/features/diary/api/getDiaryById';
import useExtractTracker from '@/features/trackers/hooks/useExtractTracker';
import { hooks as h } from '@/hooks';
import { Outlet, createFileRoute } from '@tanstack/react-router';
import { diariesBreadcrumb, editBreadcrumb } from '../../../../../../features/navigation/services/breadcrumbRoutes';

const Edit = () => {
	const { diaryId, trackerId } = Route.useParams();
	const tracker = useExtractTracker(diaryId, trackerId);
	h.useNav([
		diariesBreadcrumb,
		editBreadcrumb,
		{ route: { to: '/diary/$diaryId/edit/tracker/$trackerId' }, label: tracker?.tracker_name ?? 'Tracker' },
	]);

	return (
		<section>
			<Outlet />
		</section>
	);
};

export const Route = createFileRoute('/_app/diary/$diaryId/edit/tracker/$trackerId')({
	component: Edit,
	loader: async ({ context, params }) => {
		const diary = await context?.queryClient.ensureQueryData(diaryByIdQueryOptions(params.diaryId));
		return { diary };
	},
});
