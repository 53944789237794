import { supabaseClient } from '@/lib/supabase';
import { Route as EditDiaryRoute } from '@/routes/_app/diary/$diaryId/edit';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';
import { toast } from 'sonner';

const deleteEvent = async (eventId: string) => {
	return supabaseClient.from('events').delete().eq('event_id', eventId).throwOnError();
};

export const useDeleteEventMutation = (diaryId: string) => {
	const navigate = useNavigate();
	return useMutation({
		mutationKey: ['delete-event'],
		mutationFn: async (eventId: string) => {
			return await deleteEvent(eventId);
		},
		onError: (error) => {
			toast.error(`${error.message}`);
		},
		onSuccess: () => {
			toast.success('Event deleted successfully');
			navigate({ to: EditDiaryRoute.to, params: { diaryId: diaryId } });
		},
	});
};
export default useDeleteEventMutation;
