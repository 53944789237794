import FooterButtons from '@/components/common/FooterButtons';
import { Camera, X } from 'lucide-react';

interface Props {
	image: string;
	delay: number;
	timerRunning: boolean;
	retake: () => void;
	func1: () => void;
	words1: string;
	stopTimer: () => void;
}
const CameraButtons = ({ image, words1, delay, timerRunning, retake, func1, stopTimer }: Props) => {
	return (
		<div className="fixed bottom-6 left-0 z-50 flex h-20 w-full items-center justify-center">
			{image && (
				<>
					<FooterButtons func1={func1} words1={words1} func2={retake} words2="Retake" icon2={<Camera size={24} />} />
				</>
			)}
			{!image && (
				<>
					{timerRunning ? (
						<FooterButtons func1={stopTimer} words1="Cancel" icon1={<X size={24} />} />
					) : (
						<FooterButtons
							func1={retake}
							words1={delay === 0 ? 'Take Photo' : 'Start Timer'}
							icon1={<Camera size={24} />}
						/>
					)}
				</>
			)}
		</div>
	);
};

export default CameraButtons;
