import { formComponents as fc } from '@/components/form';
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import usePatientMutation from '@/features/profile/api/usePatientMutation';
import { zodResolver } from '@hookform/resolvers/zod';
import dayjs from 'dayjs';
import { type SubmitHandler, useForm } from 'react-hook-form';
import * as z from 'zod';
const formSchema = z.object({
	day: z.number().gte(1, 'Day is required').lte(31, 'Max 31 days in a month'),
	month: z.number().gte(1, 'Month is required').lte(12, 'Only 12 months in a year'),
	year: z.number().gte(1900, "You're not that old!").lte(2005, 'Over 18s only!'),
});

interface Props {
	userId: string;
	dateOfBirth: string | null;
	onSuccess?: () => void;
}

const DateOfBirthForm = (props: Props) => {
	type FormSchemaType = z.infer<typeof formSchema>;

	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			day: dayjs(props.dateOfBirth).date(),
			month: dayjs(props.dateOfBirth).month() + 1,
			year: dayjs(props.dateOfBirth).year(),
		},
	});

	const patientMutation = usePatientMutation(props.userId);

	const onSubmit: SubmitHandler<FormSchemaType> = async (formData) => {
		const date_of_birth = new Date(formData.year, formData.month - 1, formData.day + 1);
		await patientMutation.mutate({ date_of_birth: dayjs(date_of_birth).toISOString() }, { onSuccess: props.onSuccess });
	};

	return (
		<Form {...form}>
			<form onSubmit={form.handleSubmit(onSubmit)} className="form">
				<div className="flex flex-col gap-y-3">
					<FormLabel>Date of Birth</FormLabel>
					<div className="flex  gap-x-2">
						<div className="flex flex-[1_1_0]">
							<FormField
								control={form.control}
								name="day"
								render={({ field }) => (
									<FormItem>
										<FormControl>
											<Input
												// onChange={(event) => field.onChange(event.target.valueAsNumber)}
												type="number"
												placeholder="DD"
												{...field}
												onChange={(event) =>
													event.target.value.length < 3 && field.onChange(+event.target.valueAsNumber)
												}
											/>
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>
						</div>
						<div className="flex flex-[1_1_0]">
							<FormField
								control={form.control}
								name="month"
								render={({ field }) => (
									<FormItem>
										<FormControl>
											<Input
												type="number"
												placeholder="MM"
												{...field}
												onChange={(event) => {
													event.target.value.length < 3 && field.onChange(+event.target.valueAsNumber);
												}}
											/>
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>
						</div>
						<div className="flex flex-[2_1_0]">
							<FormField
								control={form.control}
								name="year"
								render={({ field }) => (
									<FormItem>
										<FormControl>
											<Input
												type="number"
												placeholder="YYYY"
												{...field}
												onChange={(event) =>
													event.target.value.length < 5 && field.onChange(+event.target.valueAsNumber)
												}
											/>
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>
						</div>
					</div>
					<FormDescription>Please use DD MM YYYY format.</FormDescription>
				</div>
				<fc.SaveButton />
			</form>
		</Form>
	);
};

export default DateOfBirthForm;
