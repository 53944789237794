import ProfileWrap from '@/features/profile/layouts/ProfileWrap';
import type { ExtendedProfileT } from '@/types/database/profiles';
import { useAuth } from '@/utils/auth';
import { createFileRoute } from '@tanstack/react-router';

import { selectProfileByIdQueryOptions } from '@/features/profile/api/selectProfile';
import OnboardingStepper from '../../features/onboarding/components/OnboardingStepper';
import { useNavigateToNextOnboardingRoute } from '../../features/onboarding/hooks/useNavigateToNextOnboardingRoute';
import DateOfBirthForm from '../../features/profile/components/DateOfBirthForm';

const Name = () => {
	const auth = useAuth();
	const { profile } = Route.useLoaderData();
	const nextPage = useNavigateToNextOnboardingRoute('/onboarding/date-of-birth');

	return (
		<ProfileWrap title="Onboarding">
			<OnboardingStepper currentRoute={Route.to} />
			<DateOfBirthForm userId={auth.id} dateOfBirth={profile?.date_of_birth ?? ''} onSuccess={nextPage} />
		</ProfileWrap>
	);
};
export const Route = createFileRoute('/onboarding/date-of-birth')({
	component: Name,
	loader: async ({ context }) => {
		let profile: ExtendedProfileT | null = null;
		if (context?.auth?.id) {
			profile = await context?.queryClient.ensureQueryData(selectProfileByIdQueryOptions(context.auth.id));
		}
		return { profile };
	},
});

export default Name;
