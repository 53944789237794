import type { OptionT } from '@/components/form/Select';
import type { InjectionContentT, InjectionLocationT } from '@/types/database/pain';
import arraySort from 'array-sort';
import type { SurgeryOptionT } from '../../../types/database/events';

type InjectionInfo = InjectionContentT | InjectionLocationT;

export const removeDuplicateOptions = (options: OptionT[], isNumeric?: boolean) => {
	const uniOpts = options?.filter((v, i, a) => a.findIndex((t) => t.label === v.label && t.value === v.value) === i);

	if (isNumeric) {
		const numeric = uniOpts?.sort((a, b) => Number(a.value) - Number(b.value));
		return numeric;
	}
	return arraySort(uniOpts, 'value');
};

export const createUniqueInjectionOptions = (
	injectionLocations: InjectionInfo[],
	key: string,
	isNumeric?: boolean,
): OptionT[] => {
	const opts = injectionLocations?.map((location) => ({
		label: String(location[key]),
		value: String(location[key]),
	}));

	return removeDuplicateOptions(opts, isNumeric);
};

export const toFilteredUniqueInjectionOptions = (
	injectionLocations: InjectionInfo[],
	key: string,
	filterKey: string,
	filterValue: string,
	isNumeric?: boolean,
): OptionT[] => {
	const filteredInjectionLocations = injectionLocations?.filter(
		(loc) => String(loc[filterKey]) === String(filterValue),
	);
	const filteredUnqiue = createUniqueInjectionOptions(filteredInjectionLocations, key, isNumeric);
	// Return unfiltered if the function doesn't work

	return filteredUnqiue?.length > 0 ? filteredUnqiue : createUniqueInjectionOptions(injectionLocations, key);
};
export const filterSurgeryOptions = (
	surgeryOptions: SurgeryOptionT[],
	filterKey: string,
	filterValue: string,
): SurgeryOptionT[] => {
	const surOpts = filterValue
		? surgeryOptions?.filter((loc) => String(loc[filterKey]) === String(filterValue))
		: surgeryOptions;
	const opts = surOpts?.map((so) => ({ value: so.surgery_option_id, label: so.surgery_name }));
	return removeDuplicateOptions(opts);
};
