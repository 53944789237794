import type { InputT } from '@/types/database/forms';

import type { UpsertEntryValueT } from '../../inputs/hooks/useEntryValuesStore';

interface FormValue {
	[key: string]: string;
}

export const formatEntryValues = (formValues: FormValue[], inputs: InputT[], entryId: string): UpsertEntryValueT[] => {
	return Object.entries(formValues).flatMap(([id, value]) => {
		const input = inputs?.find((input) => input.input_id === id);
		if (!input?.input_key) return;

		if (['number', 'slider', 'pain-slider', 'glucose-slider'].includes(input.input_key)) {
			return { input_id: id, number_value: Number(value), entry_id: entryId };
		}
		if (['multiple-choice'].includes(input.input_key)) {
			return { input_id: id, entry_id: entryId, text_array: Array.isArray(value) ? [...value] : [value] };
		}
		if (['duration'].includes(input.input_key)) {
			return { input_id: id, entry_id: entryId, time_unit: value.timeUnit, number_value: Number(value.interval) };
		}
		return { input_id: id, entry_id: entryId, text_value: String(value) };
	}) as UpsertEntryValueT[];
};
