import { ui } from '@/components/ui';
import { services as s } from '@/services';
import { Link } from '@tanstack/react-router';
import { AlertTriangle, ChevronRight } from 'lucide-react';

interface Props {
	diaryId: string;
	eventId: string;
}

const MissingInjectionsLink = (props: Props) => {
	return (
		<Link
			to="/diary/$diaryId/edit/event/$eventId/injection/$injectionId"
			params={{ diaryId: props.diaryId, eventId: props.eventId, injectionId: s.uuidv4() }}
			className="flex w-full items-center justify-between"
		>
			<p className="flex items-center gap-2 text-sm text-amber-700 underline underline-offset-2">
				<AlertTriangle size={24} className=""></AlertTriangle>
				Add Missing Injection Details
			</p>
			<ui.Button asChild size="icon" variant="outline" className="h-8 w-8 rounded-full p-0 pl-[2px]">
				<ChevronRight size={24} />
			</ui.Button>
		</Link>
	);
};

export default MissingInjectionsLink;
