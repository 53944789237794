import { ui } from '@/components/ui';
import type { ExtendedDiaryInputT } from '@/types/database/diaries';
import React from 'react';

import { sortAndGroupInputs } from '../services/sortAndGroupInputs';
import InputCard from './InputCard';
import InputSwitch from './InputSwitch';

interface Props {
	diaryInputs: ExtendedDiaryInputT[];
	inputId?: string;
	building?: boolean;
	editable?: boolean;
	is_preview?: boolean;
}

export type AnyInputT = string | string[] | number | number;

const Inputs = (props: Props) => {
	const [value, setValue] = React.useState<AnyInputT>('');
	return (
		<ui.Accordion className="" defaultValue="0" type="single" collapsible>
			{sortAndGroupInputs(props?.diaryInputs)?.map((page, index) => (
				<ui.AccordionItem key={JSON.stringify(page)} value={String(index)}>
					<ui.AccordionTrigger className="text-xl [&_svg]:h-6 [&_svg]:w-6">Page {index + 1}</ui.AccordionTrigger>
					<ui.AccordionContent>
						<ul className="mb-4 flex flex-col gap-4 ">
							{page?.map((template) => {
								return (
									<li key={template.input_id}>
										{props.building ? (
											<InputCard input={template.inputs} editable={props.editable} />
										) : (
											<InputSwitch
												is_preview={props.is_preview}
												value={value}
												setValue={setValue}
												input={template.inputs}
											/>
										)}
									</li>
								);
							})}
						</ul>
					</ui.AccordionContent>
				</ui.AccordionItem>
			))}
		</ui.Accordion>
	);
};

export default Inputs;
