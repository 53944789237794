interface Props {
    fill?: string;
    className?: string;
}
const Send = ({ fill, className }: Props) => {
    return (
        <svg
            className={className}
            width="21"
            height="23"
            viewBox="0 0 21 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.0082 18.8369L14.9017 20.5762C16.0975 21.1103 17.4753 20.3703 17.6903 19.0784L19.0599 10.8487L20.5082 2.14655C20.6466 1.31445 19.7561 0.695279 19.0243 1.11488L1.31853 11.2675C-0.0974342 12.0794 0.00297379 14.1541 1.49073 14.8255L4.11577 16.0101L16.9795 4.8933L6.86371 17.3652C6.79146 17.4543 6.75204 17.5655 6.75204 17.6802V21.037C6.75204 21.9601 7.8961 22.3903 8.50431 21.6959L11.0082 18.8369Z"
                fill={fill ? fill : 'white'}
            />
        </svg>
    );
};

export default Send;
