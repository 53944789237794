import { supabaseClient } from '@/lib/supabase';
import { queryOptions, useSuspenseQuery } from '@tanstack/react-query';

export async function selectTemplates() {
	return await supabaseClient
		.from('diary_templates')
		.select(
			`
*,
tracker_templates (
  *,
  forms (
    *,
    pages (
      *,
      inputs (
        *
      )
    )
  ),
  schedules!public_tracker_templates_schedule_id_fkey (*,
      timings(*)
    )
)`,
		)
		.throwOnError()
		.then((res) => res.data);
}

export const templatesQueryKey = ['diary-templates'];

export const diaryTemplatesQueryOptions = () => {
	return queryOptions({
		queryKey: templatesQueryKey,
		queryFn: () => selectTemplates(),
	});
};

export const useDiaryTemplatesQuery = () => {
	const options = diaryTemplatesQueryOptions();
	const query = useSuspenseQuery(options);
	return query;
};
