import { selectPatientsQueryKey } from '@/features//new-diary/api/selectPatients';
import { createFavouriteUsersQueryKey } from '@/features//profile/api/selectFavouriteUsers';
import { selectStaffQueryKey } from '@/features//profile/api/selectStaff';
import type { InviteSchemaT } from '@/features/invite/components/InviteForm';
import { supabaseClient } from '@/lib/supabase';
import { useAuth } from '@/utils/auth';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';
import { cliniciansByOrganisationQueryKey } from '../../auth/api/cliniciansByOrganisationQuery';
const inviteEmail = async (formData: InviteSchemaT) => {
	const { data, error } = await supabaseClient.functions.invoke('public-invite', {
		body: { ...formData },
	});
	if (error) throw error;
	return data;
};

interface Props {
	onSuccess?: () => void;
}

export const useInviteMutation = ({ onSuccess }: Props) => {
	const auth = useAuth();
	const queryClient = useQueryClient();
	return useMutation({
		mutationKey: ['invite'],
		mutationFn: (formData: InviteSchemaT) => inviteEmail(formData),
		onError: (e) => {
			toast.error(e.message);
		},
		onSuccess: () => {
			toast.info('User Invited');
			!!onSuccess && onSuccess();
		},
		onSettled: (data) => {
			// FIXME Invalidate didnt refetch so force with refetch
			queryClient.refetchQueries({ queryKey: selectStaffQueryKey });
			queryClient.refetchQueries({ queryKey: selectPatientsQueryKey });
			queryClient.refetchQueries({ queryKey: cliniciansByOrganisationQueryKey(data.organiastion_id) });
			queryClient.refetchQueries({ queryKey: createFavouriteUsersQueryKey(auth.id) });
		},
	});
};
