import { components as c } from '@/components';
import { diaryByIdQueryOptions } from '@/features/diary/api/getDiaryById';
import SurgeryCliniciansForm from '@/features/events/features/surgeries/components/SurgeryCliniciansForm';
import { Route as DashboardDiaryRoute } from '@/routes/_app/dashboard/diary/$diaryId';
import { createFileRoute, useNavigate } from '@tanstack/react-router';

const SurgeryCliniciansPage = () => {
	const navigate = useNavigate();
	const { diaryId, surgeryId, patientId, organisationId, eventId } = Route.useParams();
	return (
		<>
			<c.cc.Stepper
				steps={[
					{ stage: 'past', words: 'Patient' },
					{ stage: 'past', words: 'Diary' },
					{ stage: 'past', words: 'Form and Schedule' },
					{
						stage: 'current',
						words: 'Intervention',
						substeps: [
							{
								stage: 'past',
								words: 'Surgery Name',
							},
							{
								stage: 'current',
								words: 'Clinicians',
							},
						],
					},
				]}
			/>

			<SurgeryCliniciansForm
				diaryId={diaryId}
				eventId={eventId}
				surgeryId={surgeryId}
				navigateToNextPage={() =>
					navigate({
						to: DashboardDiaryRoute.to,
						params: {
							diaryId: diaryId,
						},
						search: { tab: 'pending' },
					})
				}
			/>
		</>
	);
};

export const Route = createFileRoute(
	'/_app/new-diary/patient/$patientId/organisation/$organisationId/diary/$diaryId/event/$eventId/surgery/$surgeryId copy',
)({
	loader: async ({ context, params }) => {
		await context?.queryClient.ensureQueryData(diaryByIdQueryOptions(params.diaryId));
	},
	component: SurgeryCliniciansPage,
});