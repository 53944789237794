import { ui } from '@/components/ui';
import { Route as DashbordRoute } from '@/routes/_app/dashboard/diary/$diaryId';
import { useNavigate, useSearch } from '@tanstack/react-router';
import { Rows3, Table2 } from 'lucide-react';

const TableTabs = () => {
	const { style } = useSearch({
		strict: false,
	});
	const navigate = useNavigate({ from: DashbordRoute.fullPath });
	return (
		<ui.Tabs defaultValue={style ?? 'table'}>
			<ui.TabsList className="gap-x-1 mr-1 h-fit">
				<ui.TabsTrigger
					onClick={() => {
						navigate({
							search: (prev) => ({ ...prev, style: 'table' }),
						});
					}}
					className="gap-2 p-2"
					value="table"
				>
					<Table2 />
					Table
				</ui.TabsTrigger>
				<ui.TabsTrigger
					onClick={() => {
						navigate({
							search: (prev) => ({ ...prev, style: 'stack' }),
						});
					}}
					className="gap-2 p-2"
					value="stack"
				>
					<Rows3 />
					Stacked
				</ui.TabsTrigger>
			</ui.TabsList>
		</ui.Tabs>
	);
};

export default TableTabs;
