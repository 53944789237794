import { ui } from '@/components/ui';
import { Info } from 'lucide-react';

import { managements, TrackerKeyT } from '../../../definitions/managements';
import { cn } from '../../../lib/utils';
import { useTrackerTypesStore } from '../hooks/useTrackerTypesStore';

const TrackerTypeCheckboxes = () => {
    const trackersStore = useTrackerTypesStore();

    const handleCheck = (trackerType: TrackerKeyT) => {
        if (trackersStore.tracker_types.includes(trackerType)) {
            trackersStore.removeTrackerTypes(trackerType);
        } else {
            trackersStore.addTrackerTypes(trackerType);
        }
    };

    const handleNoneCheck = () => {
        trackersStore.reset();
    };

    return (
        <div>
            <ui.Label>Intervention to Track</ui.Label>
            <p
                className={cn(
                    'flex p-2 my-2 text-sm text-indigo-800 border border-indigo-800 rounded-md gap-x-2',
                    trackersStore.tracker_types.length > 1 ? 'flex' : 'hidden',
                )}
            >
                <Info size={32} />
                <span>
                    If you wish to track multiple interventions, it is usually better to use separate trackers or
                    separate diaries.
                </span>
            </p>
            <ul className="flex flex-col p-4 pt-2 gap-y-2">
                {managements.map((t) => (
                    <li key={t.path} className="flex gap-x-2">
                        <ui.Checkbox
                            checked={trackersStore.tracker_types.includes(t.path)}
                            onCheckedChange={() => handleCheck(t.path)}
                        />
                        <ui.Label className="font-normal capitalize text-slate-700">{t.path.toLowerCase()}</ui.Label>
                    </li>
                ))}
                <li className="flex gap-x-2">
                    <ui.Checkbox checked={trackersStore.tracker_types.length === 0} onCheckedChange={handleNoneCheck} />
                    <ui.Label className="font-normal capitalize text-slate-700">None</ui.Label>
                </li>
            </ul>
        </div>
    );
};

export default TrackerTypeCheckboxes;
