import { hasCommonElements } from '@/services/hasCommonElements';

import { useSelectProfiles } from '../../profile/api/selectProfiles';

import type { OptionT } from '@/components/form/Select';

const useSelectStaffOptions = (): OptionT[] => {
	const { data: profiles } = useSelectProfiles();
	if (!profiles?.length) return [];
	return profiles
		?.filter((p) => hasCommonElements(p.roles ?? [], ['CLINICIAN', 'SECRETARY']))
		?.map((p) => ({ value: p.user_id as string, label: p.name as string }));
};

export default useSelectStaffOptions;
