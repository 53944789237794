import { supabaseClient } from '@/lib/supabase';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';

import { createGetDiaryKey } from '../../diary/api/getDiaryById';

export type EntryT = {
	entry_id: string;
};

export async function deleteEntry(entry: EntryT) {
	const { data, error } = await supabaseClient.from('entries').delete().eq('entry_id', entry.entry_id);
	if (error) throw error;
	return data;
}

export const useDeleteEntryMutation = (diaryId: string) => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationKey: ['entry'],
		mutationFn: async (entry: EntryT) => {
			return await deleteEntry(entry);
		},
		onError: (error) => {
			toast.error(error.message);
		},
		onSuccess: (data, mutation) => {
			toast.info('Entry deleted');
			mutation?.onSuccess && mutation.onSuccess();
			return data;
		},
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey: createGetDiaryKey(diaryId) });
		},
	});
};
