import { ExtendedInjectionT } from '@/types/database/trackers';
import { UuidT } from '@/types/zod/auth';

import InjectionDetailCard from './InjectionDetailCard';

interface Props {
	injectionDetailId: UuidT;
	injection: ExtendedInjectionT;
}
const ExistingInjections = ({ injection, injectionDetailId }: Props) => {
	return (
		<ul>
			{injection?.injection_details_view?.length > 0 &&
				injection.injection_details_view
					.filter((details) => details.injection_detail_id !== injectionDetailId)
					?.map((detail, i) => {
						return (
							<li key={detail.injection_detail_id}>
								<InjectionDetailCard index={i + 1} injection={injection} injectionDetail={detail} />
							</li>
						);
					})}
		</ul>
	);
};

export default ExistingInjections;
