export const getMostRecent = (arr: any[]) => {
    if (arr?.length > 0) {
        return arr?.reduce((acc, curr) => (new Date(acc?.created_at) > new Date(curr?.created_at) ? acc : curr));
    } else {
        return;
    }
};

export const allReviews = (check_ins: any[]) => {
    let all: any[] = [];
    check_ins?.forEach((check_in) => {
        all.push(...check_in?.check_in_reviews);
        all.push(...check_in?.label_reviews);
    });
    return all;
};
