import { schedulesQueryOptions } from '@/features/schedules/api/selectSchedules';
import { timeComponents as tc } from '@/features/schedules/components';
import { createFileRoute } from '@tanstack/react-router';

const Templates = () => {
	const { templates } = Route.useLoaderData();
	return (
		<div className="flex flex-row flex-wrap">
			{templates?.map((t) => (
				<tc.ScheduleCard schedule={t} />
			))}
		</div>
	);
};

export const Route = createFileRoute('/_app/builder/schedule/templates')({
	component: Templates,
	loader: async ({ context }) => {
		const templates = await context?.queryClient.ensureQueryData(schedulesQueryOptions());
		return { templates };
	},
});
