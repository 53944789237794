import SaveTemplateDialog from './SaveTemplateDialog';
import SaveTimingButtons from './SaveTimingButtons';
import ScheduleBuilder from './ScheduleBuilder';
import ScheduleCard from './ScheduleCard';
import TimeTooltip from './TimeTooltip';
import TimingPreview from './TimingPreview';
import TimingTable from './TimingTable';

export const timeComponents = {
	ScheduleBuilder,
	ScheduleCard,
	TimeTooltip,
	SaveTemplateDialog,
	SaveTimingButtons,
	TimingPreview,
	TimingTable,
};
