import { ui } from '@/components/ui';
import { InputOTP, InputOTPGroup, InputOTPSeparator, InputOTPSlot } from '@/components/ui/input-otp';
import useVerifyEmailOtpMutation from '@/features/auth/hooks/useVerifyEmailOtpMutation';
import type { VerifySchemaWithoutEmailT } from '@/features/auth/types/verifySchema';
import { VerifySchemaWithoutEmailZ } from '@/features/auth/types/verifySchema';
import type { VerifyT } from '@/types/zod/common';
import { zodResolver } from '@hookform/resolvers/zod';
import { type SubmitHandler, useForm } from 'react-hook-form';

interface Props {
	submitFn: (data: VerifyT) => void;
	resendFn: () => void;
}
const OtpForm = (props: Props) => {
	const verifyEmailOtpMutation = useVerifyEmailOtpMutation();

	const form = useForm<VerifySchemaWithoutEmailT>({
		resolver: zodResolver(VerifySchemaWithoutEmailZ),
		defaultValues: {
			token: '',
		},
	});

	const onSubmit: SubmitHandler<VerifySchemaWithoutEmailT> = async (formData) => {
		await props.submitFn(formData);
	};
	return (
		<ui.Form {...form}>
			<form onSubmit={form.handleSubmit(onSubmit)} className="form">
				{/* Email */}
				<ui.FormField
					control={form.control}
					name="token"
					data-cy="token"
					render={({ field }) => (
						<ui.FormItem className="mx-auto flex w-fit flex-col items-center justify-center">
							<ui.FormControl>
								<InputOTP {...field} tabIndex={0} maxLength={6}>
									<InputOTPGroup>
										<InputOTPSlot index={0} />
										<InputOTPSlot index={1} />
										<InputOTPSlot index={2} />
										<InputOTPSlot index={3} />
										<InputOTPSlot index={4} />
										<InputOTPSlot index={5} />
									</InputOTPGroup>
								</InputOTP>
							</ui.FormControl>
							<ui.FormMessage />
						</ui.FormItem>
					)}
				/>
				{!!props.resendFn && (
					<ui.Button className="mt-4" size="lg" variant="link" onClick={props.resendFn}>
						Resend
					</ui.Button>
				)}
				<ui.Button tabIndex={0} className="w-full" type="submit" disabled={verifyEmailOtpMutation.isPending}>
					Submit
				</ui.Button>
			</form>
		</ui.Form>
	);
};

export default OtpForm;
