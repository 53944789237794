import { ChevronDown } from 'lucide-react';
import { useState } from 'react';
import { cn } from '../../lib/utils';
import { ui } from '../ui';
import { buttonVariants } from '../ui/button';
import CheckboxList from './CheckboxList';
import type { OptionT } from './Select';

interface Props {
	name: string;
	label?: string;
	trigger: React.ReactNode;
	options: OptionT[];
}
const MultiSelect = ({ name, trigger, options, label }: Props) => {
	const [open, setOpen] = useState<boolean>(false);
	return (
		<>
			<ui.Popover open={open}>
				<ui.PopoverTrigger
					onClick={() => setOpen(!open)}
					className={cn(
						buttonVariants({ size: 'md', variant: 'outline' }),
						'w-full items-center text-sm justify-between px-3',
					)}
				>
					{trigger}
					<ChevronDown size={16} />
				</ui.PopoverTrigger>
				<ui.PopoverContent className="p-0">
					<ui.ScrollArea className="h-[300px]">
						<CheckboxList label={label} name={name} options={options} />
						<ui.ScrollBar />
					</ui.ScrollArea>

					<ui.Button onClick={() => setOpen(false)} variant="outline" size="sm" className="m-4">
						Close
					</ui.Button>
				</ui.PopoverContent>
			</ui.Popover>
		</>
	);
};

export default MultiSelect;
