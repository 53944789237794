import { components as c } from "@/components";
import Title from "@/components/common/Title";
import { selectCliniciansStatisticsQueryOptions } from "@/features/admin/api/selectCliniciansStatistics";
import CreatedDiairesSparkChart from "@/features/charts/clinician-statistics/CreatedDiairesSparkChart";
import DiariesByTabSparkChart from "@/features/charts/clinician-statistics/DiariesByTabSparkChart";
import { selectCliniciansProfilesQueryOptions } from "@/features/profile/api/selectClinicians";
import { createFileRoute } from "@tanstack/react-router";
import { AreaChart, TrendingUp } from "lucide-react";
import { sortBy } from "underscore";

const CliniciansStatisticsPage = () => {
    const { cliniciansStats, cliniciansProfiles } = Route.useLoaderData();

    const filterSortStatsByClinicianId = (clinicianId: string) => {
        return sortBy(cliniciansStats, "created_at").filter((cs) =>
            cs.clinician_id === clinicianId
        );
    };

    return (
        <div className="lg-width">
            <Title words="Clinicians Statistics" />
            <div className="flex md:flex-row flex-col md:flex-wrap gap-4">
                {cliniciansProfiles.map((uc) => (
                    <c.ui.Card
                        className="mx-auto w-full md:w-[400px]"
                        key={uc?.user_id}
                    >
                        <c.ui.CardHeader>{uc?.name}</c.ui.CardHeader>
                        <c.ui.CardContent className="pb-0 h-60">
                            <c.ui.Tabs defaultValue="trends">
                                <c.ui.TabsList>
                                    <c.ui.TabsTrigger
                                        className="flex gap-x-2"
                                        value="trends"
                                    >
                                        <TrendingUp /> Trends
                                    </c.ui.TabsTrigger>
                                    <c.ui.TabsTrigger
                                        className="flex gap-x-2"
                                        value="spread"
                                    >
                                        <AreaChart /> Spread
                                    </c.ui.TabsTrigger>
                                </c.ui.TabsList>
                                <c.ui.TabsContent value="trends">
                                    <CreatedDiairesSparkChart
                                        cliniciansStats={sortBy(
                                            cliniciansStats,
                                            "created_at",
                                        ).filter(
                                            (cs) =>
                                                cs.clinician_id === uc.user_id,
                                        )}
                                    />
                                </c.ui.TabsContent>
                                <c.ui.TabsContent value="spread">
                                    <DiariesByTabSparkChart
                                        diaryBreakdown={filterSortStatsByClinicianId(
                                            uc.user_id ?? "",
                                        )}
                                    />
                                </c.ui.TabsContent>
                            </c.ui.Tabs>
                        </c.ui.CardContent>
                    </c.ui.Card>
                ))}
            </div>
        </div>
    );
};

export const Route = createFileRoute("/_app/admin/clinicians/statistics")({
    component: CliniciansStatisticsPage,
    loader: async ({ context }) => {
        const cliniciansStats = await context?.queryClient.ensureQueryData(
            selectCliniciansStatisticsQueryOptions(),
        );
        const cliniciansProfiles = await context?.queryClient.ensureQueryData(
            selectCliniciansProfilesQueryOptions(),
        );

        return { cliniciansStats, cliniciansProfiles };
    },
});
