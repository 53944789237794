import { Outlet, createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/_app/builder')({
	component: () => <Outlet />,
	beforeLoad: ({ context }) => {
		if (!context?.auth?.roles?.isAdmin) {
			throw redirect({
				to: '/login',
			});
		}

		return context;
	},
});
