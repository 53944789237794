import { ui } from '@/components/ui';
import ChartCarousel from '@/features/charts/components/ChartCarousel';
import OverallChart from '@/features/charts/components/OverallChart';
import { processGraphEntryValues } from '@/features/charts/utils/groupByInputId';
import { combineTrackerEntries } from '@/features/diary/services/flattenEntriesView';
import { services as s } from '@/services';
import type { EntryValueViewT, StatefulDiaryT } from '@/types/database/diaries';
import NoChartData from '../../diary/components/NoChart';
import useExtractEntryValues from '../../entries/hooks/useExtractEntryValues';

interface Props {
	diary: StatefulDiaryT;
	showAddEntryButton?: boolean;
}
const AllCharts = ({ diary, showAddEntryButton }: Props) => {
	// const { data: entryValues } = useEntryValuesViewByDiaryId(diary.diary_id);

	const entryValues = useExtractEntryValues(diary.diary_id);

	if (!combineTrackerEntries(diary)?.length || combineTrackerEntries(diary)?.length < 2)
		return <NoChartData showAddEntryButton={showAddEntryButton} diary={diary} />;

	return (
		<>
			<ul className="space-4">
				{processGraphEntryValues(entryValues as EntryValueViewT[]).map((evs) => (
					<li key={s.uuidv4()}>
						<ui.Card className="p-4">
							<ui.CardHeader>
								<ui.CardTitle>Overall Statistics</ui.CardTitle>
							</ui.CardHeader>
							<ui.CardContent className="flex flex-col justify-center items-center p-0 w-full">
								<OverallChart entryValues={evs} inputType={evs[0].input_key} />
							</ui.CardContent>
						</ui.Card>
					</li>
				))}
			</ul>
			{diary?.trackers_view.filter((t) => t.entries_view.length > 2)?.length > 1 && (
				<ul className="space-4">
					{processGraphEntryValues(entryValues as EntryValueViewT[]).map((evs) => (
						<li key={s.uuidv4()}>
							<ChartCarousel entryValues={evs} trackersView={diary.trackers_view} inputType={evs[0].input_key} />
						</li>
					))}
				</ul>
			)}
		</>
	);
};

export default AllCharts;
