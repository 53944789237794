import { ui } from '@/components/ui';
import { StatefulTrackerViewT } from '@/types/database/trackers';

import EntryCard from '../../diary/history/EntryCard';

interface Props {
	tracker: StatefulTrackerViewT;
}
const TrackerEntries = ({ tracker }: Props) => {
	return (
		<ul className="flex flex-col-reverse gap-2">
			{tracker?.entries_view.map((ev) => (
				<li key={ev.entry_id}>
					<ui.Card className="p-2">
						<EntryCard entry={ev} editable={true} />
					</ui.Card>
				</li>
			))}
		</ul>
	);
};

export default TrackerEntries;
