import React from 'react';

const Search = () => {
    return (
        <svg width="35" height="33" viewBox="0 0 35 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="20" cy="14.5" r="13" stroke="black" strokeWidth="3" />
            <path d="M9.5 23.5L2 31" stroke="black" strokeWidth="3" strokeLinecap="round" />
        </svg>
    );
};

export default Search;
