import { ui } from '@/components/ui';
import { TrackerT } from '@/definitions/managements';

import { cn } from '../../../lib/utils';

interface Props {
    tracker?: TrackerT;
    checked: boolean;
    children: React.ReactNode;
    onClick: (checked: any) => void;
}

const TrackerCheckCard = ({ tracker, checked, onClick, children }: Props) => {
    if (!tracker) {
        return null;
    }
    return (
        <ui.Card
            onClick={onClick}
            className={cn(
                'w-full cursor-pointer',
                checked ? 'ring-indigo-600 ring-2 -ring-offset-2' : 'border-slate-300',
            )}
            key={tracker.path}
        >
            <ui.CardHeader className="flex flex-row items-start justify-between">
                <ui.CardTitle className="flex flex-row items-center text-slate-700 gap-x-2">
                    {tracker.icon}
                    <p>{tracker.name}</p>
                </ui.CardTitle>
                {children}
            </ui.CardHeader>
            <ui.CardContent>
                <ui.CardDescription>{tracker.description}</ui.CardDescription>
            </ui.CardContent>
        </ui.Card>
    );
};

export default TrackerCheckCard;
