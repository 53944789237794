interface Props {
    className?: string;
    fill?: string;
}

const ChevronRight = ({ className, fill }: Props) => {
    return (
        <svg
            width={30}
            height={30}
            className={className}
            viewBox={`0 0 30 29`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.961204 0.917921C1.55882 0.344209 2.50837 0.363587 3.08208 0.961204L15.0821 13.4612C15.6393 14.0416 15.6393 14.9584 15.0821 15.5388L3.08208 28.0388C2.50837 28.6364 1.55882 28.6558 0.961204 28.0821C0.363587 27.5084 0.344209 26.5588 0.917921 25.9612L11.9207 14.5L0.917921 3.0388C0.344209 2.44118 0.363587 1.49163 0.961204 0.917921Z"
                fill={fill ? fill : '#b7b7b7'}
            />
        </svg>
    );
};

export default ChevronRight;
