import { supabaseClient } from '@/lib/supabase';
import { queryOptions, useQuery } from '@tanstack/react-query';
import type { InjectionDiaryDefault } from '../../../types/database/diaries';

export async function selectInjectionDiaryDefaults(userId: string): Promise<InjectionDiaryDefault['Row']> {
	const { data, error } = await supabaseClient
		.from('injection_diary_defaults')
		.select('*')
		.eq('user_id', userId)
		.single();
	if (error) throw error;
	return data;
}

export const injectionQueryKey = (userId: string) => ['injection-diary-details', { user_id: userId }];

export const injectionDiaryDefaultsQueryOptions = (userId: string) => {
	return queryOptions({
		queryKey: injectionQueryKey(userId),
		queryFn: () => selectInjectionDiaryDefaults(userId),
	});
};

export const useInjectionDiaryDefaultsQuery = (userId: string) => {
	const options = injectionDiaryDefaultsQueryOptions(userId);
	const query = useQuery(options);
	return query;
};
