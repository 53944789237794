import { supabaseClient } from '@/lib/supabase';
import { useMutation } from '@tanstack/react-query';

import { useImagesStore } from '../../../hooks/useImagesStore';
import type { VariationsType } from '../../../services/variations';

const checkFoot = async (image: string) => {
	const { data, error } = await supabaseClient.functions.invoke('foot-checker', {
		body: { image: image },
	});
	if (error) {
		throw error;
	}
	return data.message.content;
};

export type CheckFootMutationT = {
	img: string;
	variation: VariationsType;
};

function useCheckFootMutation() {
	const images = useImagesStore((state) => state.images);
	const updatePrediction = useImagesStore((state) => state.updatePrediction);
	const updateChecked = useImagesStore((state) => state.updateChecked);

	return useMutation({
		mutationFn: async (data: CheckFootMutationT) => {
			const imageCheck = await checkFoot(data.img);
			return { result: imageCheck, variation: data.variation };
		},
		onError: (error) => {
			console.log(error);
		},
		onSuccess: (data) => {
			let prediction;
			try {
				prediction = JSON.parse(data.result);
			} catch (error) {
				console.log(error);
			}
			console.log(prediction ?? data.result);
			updatePrediction(images, data.variation, prediction ?? data.result);
		},
		onSettled: (data) => {
			if (data) {
				updateChecked(images, data.variation, true);
			}
		},
	});
}

export default useCheckFootMutation;
