const One = () => {
	return (
		<svg
			role="img"
			aria-label="Pain One"
			width="50"
			height="50"
			viewBox="0 0 50 50"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect x="1" y="1" width="48" height="48" rx="24" fill="white" stroke="black" strokeWidth="2" />
			<rect x="11" y="16" width="8" height="8" rx="4" fill="#0E0E0E" />
			<rect x="31" y="16" width="8" height="8" rx="4" fill="#0E0E0E" />
			<path
				d="M36.5223 31H13.4777C12.6616 31 12 31.6616 12 32.4777C12 35.3185 13.3612 37.9872 15.6608 39.6551L16.446 40.2246C18.9333 42.0286 21.9273 43 25 43C28.0727 43 31.0667 42.0286 33.554 40.2246L34.3392 39.6551C36.6388 37.9872 38 35.3185 38 32.4777C38 31.6616 37.3384 31 36.5223 31Z"
				fill="black"
				stroke="black"
			/>
			<path
				d="M29.8978 40H20.0736C19.491 40 19.2885 40.7731 19.788 41.0728C19.9279 41.1567 20.0799 41.2213 20.2375 41.2633L21.0227 41.4727C22.3353 41.8227 23.6879 42 25.0464 42C26.5828 42 28.1108 41.7733 29.581 41.3271L29.8354 41.25C29.9447 41.2168 30.0495 41.1703 30.1475 41.1115L30.1974 41.0816C30.7038 40.7777 30.4883 40 29.8978 40Z"
				fill="white"
			/>
			<rect x="13" y="32" width="24" height="2" rx="1" fill="white" />
		</svg>
	);
};

export default One;
