import { ui } from '@/components/ui';
import {
	NavigationMenuContent,
	NavigationMenuItem,
	NavigationMenuLink,
	NavigationMenuList,
	NavigationMenuTrigger,
	navigationMenuTriggerStyle,
} from '@/components/ui/navigation-menu';
import { cn } from '@/lib/utils';
import { useAuth } from '@/utils/auth';
import { Link } from '@tanstack/react-router';
import React from 'react';

import { type NavigationItem, type Page, navigationContent } from './navigation-content';

function DesktopNavigation() {
	const auth = useAuth();
	// const navigate = useNavigate();
	return (
		<>
			<NavigationMenuList>
				{navigationContent
					?.filter((list) => !list.footerOnly)
					?.map((title: NavigationItem) => {
						return (
							<div key={title.name}>
								{title?.pages?.length > 0 ? (
									<NavigationMenuItem>
										<NavigationMenuTrigger className="bg-transparent dark:text-slate-200">
											{title?.name}
										</NavigationMenuTrigger>
										<NavigationMenuContent className="w-full">
											<ul className="flex flex-col justify-start items-start p-3 w-full min-w-[325px]">
												{title?.pages?.map((subPage: Page) => (
													<React.Fragment key={subPage.href}>
														<NavigationMenuItem className="justify-start items-start w-full">
															<Link to={subPage.href} params={{}}>
																<NavigationMenuLink
																	className={cn(
																		navigationMenuTriggerStyle(),
																		'flex h-fit w-full items-center justify-start',
																	)}
																>
																	<div className="flex items-center gap-x-2 mr-auto w-full text-slate-900 dark:text-slate-100">
																		<span className="m-1">{subPage.icon && subPage.icon}</span>
																		<div>
																			<p className="font-medium text-md dark:text-slate-200">{subPage?.name}</p>
																			<p className="text-slate-600 text-sm dark:text-slate-300">
																				{subPage?.description}
																			</p>
																		</div>
																	</div>
																</NavigationMenuLink>
															</Link>
														</NavigationMenuItem>
													</React.Fragment>
												))}
											</ul>
										</NavigationMenuContent>
									</NavigationMenuItem>
								) : (
									<></>
								)}
							</div>
						);
					})}
			</NavigationMenuList>
			<div className="lg:flex justify-center items-center hidden ml-4">
				{auth.isAuthenticated ? (
					<ui.Button asChild className="min-w-[90px]" size="sm">
						<Link to="/profile">Sign in</Link>
					</ui.Button>
				) : (
					<ui.Button variant="secondary" size="sm" asChild className="w-full min-w-[90px]">
						<Link className="w-full" to="/authenticate">
							Sign up / Sign in
						</Link>
					</ui.Button>
				)}
			</div>
		</>
	);
}

export default DesktopNavigation;
