import { supabaseClient } from '@/lib/supabase';
import { queryOptions, useQuery } from '@tanstack/react-query';

import type { DashboardSearchT } from '@/routes/_app/dashboard';
import type { DashboardSettings } from '@/types/database/profiles';
import { useAuth } from '@/utils/auth';
export type DashboardSettingsT = DashboardSettings['Row'] & { dashboard_settings: DashboardSearchT };
export async function selectDashboardSettingsByUserId(id: string): Promise<DashboardSettingsT> {
	const { data, error } = await supabaseClient.from('dashboard_settings').select('*').eq('user_id', id).maybeSingle();
	if (error) {
		console.error(error);
	}
	return data as DashboardSettingsT;
}

export const createDashboardSettingsQueryKey = (id: string) => ['dashboard_settings', { user_id: id }];

export const selectDashboardSettingsByIdQueryOptions = (userId: string) => {
	return queryOptions({
		queryKey: createDashboardSettingsQueryKey(userId),
		queryFn: () => selectDashboardSettingsByUserId(userId),
	});
};

export function useDashboardSettingsQuery() {
	const auth = useAuth();
	return useQuery(selectDashboardSettingsByIdQueryOptions(auth.id));
}
