import { formComponents as fc } from '@/components/form';
import { ui } from '@/components/ui';
import SelectRole from '@/features/invite/components/SelectRole';
import { useSelectProfiles } from '@/features/profile/api/selectProfiles';
import { services } from '@/services';
import type { ProfileT } from '@/types/database/profiles';
import { createFileRoute } from '@tanstack/react-router';
import React from 'react';
import { useForm } from 'react-hook-form';
import GroupedFields from '../../../components/form/GroupedFields';
const InvitedUsersPage = () => {
	const { data: profiles, isLoading } = useSelectProfiles();
	const [sortedProfiles, setSortedProfiles] = React.useState<ProfileT[]>();

	if (isLoading) return <div>Loading...</div>;

	const form = useForm<{ role: string; invited_by_id: string }>({
		defaultValues: {
			role: 'clinician',
		},
	});

	const role = form.watch('role');
	const invitedByUserId = form.watch('invited_by_id') as string | null;

	React.useMemo(() => {
		const sortedProfiles = profiles?.sort(
			(a, b) => new Date(b.confirmed_at ?? '2000-01-01').getTime() - new Date(a.confirmed_at ?? '2000-01-01').getTime(),
		);
		let filteredProfiles = sortedProfiles;
		if (role) {
			filteredProfiles = filteredProfiles?.filter((profile) => profile.roles?.includes(role));
		}
		if (invitedByUserId) {
			filteredProfiles = filteredProfiles?.filter((profile) => profile.invited_by === invitedByUserId);
		}
		setSortedProfiles(filteredProfiles as ProfileT[]);
	}, [profiles, role, invitedByUserId]);

	return (
		<section className="flex flex-col gap-4 xl-width">
			<ui.Form {...form}>
				<form className="form">
					<GroupedFields>
						<SelectRole />
						<fc.ComboBox
							label="Invited By"
							name="invited_by_id"
							options={profiles?.map((p) => ({ label: p.name as string, value: p.user_id as string })) ?? []}
						/>
					</GroupedFields>
				</form>
			</ui.Form>
			<ui.Card>
				<ui.CardHeader>
					<ui.CardTitle>Invited Users</ui.CardTitle>
				</ui.CardHeader>
				<ui.CardContent>
					<ui.Table>
						<ui.TableHeader>
							<ui.TableRow>
								<ui.TableHead>Confirmed At</ui.TableHead>
								<ui.TableHead>Email</ui.TableHead>
								<ui.TableHead>Name</ui.TableHead>
								<ui.TableHead>Invited By</ui.TableHead>
							</ui.TableRow>
						</ui.TableHeader>
						<ui.TableBody>
							{sortedProfiles?.map((profile) => (
								<ui.TableRow key={profile.user_id}>
									<ui.TableCell>
										{profile.confirmed_at ? services.time.formatDate(profile.confirmed_at) : 'Not confirmed'}
									</ui.TableCell>
									<ui.TableCell>{profile.email}</ui.TableCell>
									<ui.TableCell>{profile.name}</ui.TableCell>
									<ui.TableCell>{profile.invited_by_name}</ui.TableCell>
									<ui.TableCell>{profile.organisations?.map((org) => org.organisation_name).join(', ')}</ui.TableCell>
								</ui.TableRow>
							))}
						</ui.TableBody>
					</ui.Table>
				</ui.CardContent>
			</ui.Card>
		</section>
	);
};

export const Route = createFileRoute('/_app/admin/invited-users')({
	component: InvitedUsersPage,
});
