import { icons } from '@/assets/icons';
import { cameraComponents as cac } from '@/features/foot-watch/components/camera';
import WarningMessage from '@/features/foot-watch/components/camera/WarningMessage';
import { ImagesT } from '@/features/foot-watch/hooks/useImagesStore';
import { useImagesStore } from '@/features/foot-watch/hooks/useImagesStore';
import { useNavigate } from '@tanstack/react-router';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import Webcam from 'react-webcam';
import { shallow } from 'zustand/shallow';

import CameraButtons from './CameraButtons';

import type { TimerT } from '@/features/foot-watch/components/camera';
export type SideT = 'left' | 'right';
export type AngleT = 'top' | 'sole';

export type Props = {
    nextPage: () => void;
    variation: keyof ImagesT;
    side: SideT;
    angle: AngleT;
    label: string;
    delay: TimerT;
};

const Camera = ({ nextPage, variation, side, angle, label, delay }: Props) => {
    const navigate = useNavigate();
    const [images, updateImage, resetVariant] = useImagesStore(
        (state) => [state.images, state.updateImage, state.resetVariant],
        shallow,
    );

    const [qualityCheck, setQualityCheck] = useState(0);

    function recheck() {
        setQualityCheck(qualityCheck + 1);
    }

    const [timerRunning, setTimerRunning] = useState<boolean>(false);

    function startTimer() {
        setTimerRunning(true);
    }
    function stopTimer() {
        setTimerRunning(false);
    }

    const retake = () => {
        resetVariant(images, variation);
        startTimer();
    };

    useEffect(() => {
        stopTimer();
    }, [variation, angle, delay]);

    useEffect(() => {
        if (images[variation]?.base64) {
            stopTimer();
        }
    }, [images, variation]);

    const webcamRef = useRef(null);

    const capture = useCallback(() => {
        stopTimer();

        const imageSrc = webcamRef?.current?.getScreenshot();

        if (imageSrc) {
            updateImage(images, variation, imageSrc);
        }
    }, [webcamRef]);

    return (
        <section
            id={variation}
            className="absolute left-0 top-0 h-[100vh] max-h-[100vh] overflow-hidden w-[100vw] flex-col items-center justify-between"
        >
            <ErrorBoundary fallback={<WarningMessage words="Something went with camera permissions" />}>
                <cac.CameraPermissions />
            </ErrorBoundary>

            <ErrorBoundary fallback={<WarningMessage words="Something went with camera header" />}>
                <cac.CameraHeader variation={variation} />
            </ErrorBoundary>

            {/* <ErrorBoundary fallback={<WarningMessage words="Something went wrong when checking the image" />}> */}
            {images[variation]?.base64 && (
                <cac.CheckFoot
                    nextPage={nextPage}
                    qualityCheck={qualityCheck}
                    variation={variation}
                    retake={retake}
                    face={angle === 'top' ? 'environment' : 'user'}
                    nextStage={() =>
                        navigate({ to: `/foot-watch/instructions/${angle}/$side`, params: { side: side } })
                    }
                />
            )}
            {/* </ErrorBoundary> */}

            {/* Title */}
            <div className="z-50 w-full overflow-hidden absolute top-[10vh] justify-center align-center flex">
                <h1 className="z-40 mx-auto text-4xl font-medium text-white">{label}</h1>
            </div>

            {timerRunning && <cac.Timer runFunction={capture} initialSeconds={delay} />}

            {/* Taking photo black flash effect */}
            {/* {takingPhoto && <div className={`absolute bg-black top-0 h-full w-full  left-0 z-40`}></div>} */}

            <ErrorBoundary fallback={<WarningMessage words="Something went with webcam or images" />}>
                {images[variation]?.base64 ? (
                    // If Image Show Image
                    <img
                        className={`absolute top-0 h-full w-full left-0 z-[35] object-cover `}
                        src={images[variation]?.base64}
                        alt="Latest Photo"
                    ></img>
                ) : (
                    <>
                        <Webcam
                            className={`absolute top-0 h-full w-full left-0 z-[35]  object-cover`}
                            audio={false}
                            mirrored={angle === 'sole'}
                            ref={webcamRef}
                            screenshotQuality={angle === 'sole' ? 0.92 : 0.76}
                            screenshotFormat="image/webp"
                            videoConstraints={{
                                width: 900,
                                facingMode: angle === 'top' ? 'environment' : 'user',
                            }}
                            forceScreenshotSourceSize={false}
                        ></Webcam>
                        <div
                            className={`absolute top-0 left-0 w-[50vw] h-[66vh] mt-[14vh] mx-[25vw] z-50  ${
                                side === 'left' && 'reflect'
                            }`}
                        >
                            <icons.Footprint fill="#ECECEC" opacity="0.3" />
                        </div>
                    </>
                )}
            </ErrorBoundary>

            {/* Black Drop incase nothing above is rendered  */}
            <div className="absolute top-0 left-0 w-[100vw] h-[100vh] bg-black z-30"></div>

            <ErrorBoundary fallback={<WarningMessage words="Something went wrong with camera settings" />}>
                {/* Camera Settings */}
                {!images[variation]?.base64 && (
                    <div className="fixed bottom-[100px] flex items-center justify-center mx-auto px-4 z-50 left-0 w-full ">
                        <div className="w-full max-w-[400px] mx-auto gap-x-4 flex h-20 items-center justify-center">
                            <cac.SelectCamera />
                            <cac.SelectTimer />
                        </div>
                    </div>
                )}
            </ErrorBoundary>

            <CameraButtons
                delay={delay}
                stopTimer={stopTimer}
                retake={retake}
                image={images[variation].base64}
                recheck={recheck}
                timerRunning={timerRunning}
            />
        </section>
    );
};

export default Camera;
