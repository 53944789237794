import { commonComponents as cc } from '@/components/common';
import AuthWrap from '@/features/auth/components/AuthWrap';
import OtpForm from '@/features/auth/components/OtpForm';
import useVerifyEmailOtpMutation from '@/features/auth/hooks/useVerifyEmailOtpMutation';
import type { VerifyT } from '@/types/zod/common';
import { createFileRoute } from '@tanstack/react-router';
import { Mail } from 'lucide-react';
import { z } from 'zod';

const VerifyEmail = () => {
	const { email } = Route.useSearch();
	const verifyEmailOtpMutation = useVerifyEmailOtpMutation('');

	const verifyEmail = async (formData: VerifyT) => {
		await verifyEmailOtpMutation.mutate({ ...formData, email: email });
	};

	return (
		<AuthWrap>
			<cc.Title words="Enter Confirmation Code"></cc.Title>
			<p className="mb-6 w-full  px-8 text-center text-xl"></p>
			<cc.Call words="Verify you Email" icon={<Mail size={32} />}>
				{`Please enter the code sent to ${email}.\n\nDon't forget to check your junk!`}
			</cc.Call>
			<OtpForm submitFn={verifyEmail} />
		</AuthWrap>
	);
};

const verifyEmailSearchSchema = z.object({
	email: z.string().email(),
});

export const Route = createFileRoute('/_auth/verify copy')({
	component: VerifyEmail,
	validateSearch: (search) => verifyEmailSearchSchema.parse(search),
	beforeLoad: async ({ context: { auth } }) => {
		return auth;
	},
});
