import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Label } from '@/components/ui/label';
import { cn } from '@/lib/utils';
import { services as s } from '@/services';
import { Star as StarIcon } from 'lucide-react';
import { useFormContext } from 'react-hook-form';

import type { InputT } from '../../types/database/forms';

interface Props {
	input: InputT;
	name: string;
	label: string;
}

const Star = ({ input, name, label }: Props) => {
	const { control } = useFormContext();

	return (
		<FormField
			control={control}
			name={name}
			render={({ field }) => (
				<FormItem>
					<FormLabel>
						<Label>{label}</Label>
					</FormLabel>
					<FormControl>
						<ul className="flex flex-row flex-wrap">
							{(input.scale_options as any[])?.map((option: { label: string; value: number }, index: number) => (
								<li key={`scale-rating-option_${s.uuidv4()}`} className="flex flex-col">
									<button
										type="button"
										onClick={() => field.onChange(option.value)}
										className={cn(
											'm-1 rounded-md px-1 pb-2 text-lg text-white transition-all duration-100',
											field.value > option.value - 1 ? 'fill-amber-400 text-amber-600' : 'text-slate-300',
										)}
									>
										<StarIcon fill={field.value > option.value - 1 ? '' : 'transparent'} size={32} />
									</button>
									<p className="overflow-x-show ml-1 w-8">{option.label}</p>
								</li>
							))}
						</ul>
					</FormControl>
					<FormMessage />
				</FormItem>
			)}
		/>
	);
};

export default Star;
