import { Button } from '@/components/ui/button';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { ui } from '../ui';
import { Input } from '../ui/input';
import DateInput from './DateInput';
import GroupedFields from './GroupedFields';

interface DateTimeInputProps {
	name: string;
	label?: string;
	description?: string;
	disabled?: boolean;
}

const DateTimeInput: React.FC<DateTimeInputProps> = ({ name, label, description, disabled }) => {
	const { control, setValue } = useFormContext();
	const [date, setDate] = useState<Date | null | undefined>(undefined);
	const [time, setTime] = useState<string | undefined>(undefined);

	const dateTime = useWatch({
		control,
		name,
	});

	const updateDateTime = (newDate?: Date | null, newTime?: string) => {
		if (newDate && newTime) {
			setValue(
				name,
				dayjs(newDate)
					.set('hour', Number.parseInt(newTime.split(':')[0] as string))
					.set('minute', Number.parseInt(newTime.split(':')[1] as string))
					.toISOString(),
			);
		} else if (newDate) {
			setValue(name, dayjs(newDate).startOf('day').toISOString());
		} else if (newTime) {
			setValue(
				name,
				dayjs()
					.startOf('day')
					.set('hour', Number.parseInt(newTime.split(':')[0] as string))
					.set('minute', Number.parseInt(newTime.split(':')[1] as string))
					.toISOString(),
			);
		} else {
			setValue(name, null);
		}
	};

	useEffect(() => {
		if (dateTime) {
			setDate(dayjs(dateTime).toDate());
			setTime(dayjs(dateTime).format('HH:mm'));
		}
	}, [dateTime]);

	useEffect(() => {
		updateDateTime(date, time);
	}, [date, time, updateDateTime]);

	return (
		<div>
			{label && <ui.FormLabel className="block text-sm font-medium text-gray-700">{label}</ui.FormLabel>}
			{description && <ui.FormDescription className="mt-1 text-sm text-gray-500">{description}</ui.FormDescription>}
			<GroupedFields>
				<DateInput value={date as Date} onChange={(newDate) => setDate(newDate)} disabled={disabled} />
				<Input
					className="w-full"
					autoComplete="time"
					type="time"
					value={time}
					onChange={(e) => setTime(e.target.value)}
					disabled={disabled}
				/>
			</GroupedFields>
			<Button
				size="sm"
				variant="link"
				onClick={() => {
					setDate(undefined);
					setTime('');
					updateDateTime(null, undefined);
				}}
				disabled={disabled}
			>
				Clear Date and Time
			</Button>
		</div>
	);
};

export default DateTimeInput;
