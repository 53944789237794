import { supabaseClient } from '@/lib/supabase';
import { queryOptions, useSuspenseQuery } from '@tanstack/react-query';

export async function dub(url: string): Promise<unknown & { shortLink: string, qrCode: string }> {
	console.log(url);
	const { data, error } = await supabaseClient.functions.invoke('services-dub', {
		body: JSON.stringify({ url }),
	});
	if (error) {
		console.error(error);
		throw error;
	}
	return data;
}

export const dubQueryOptions = (url: string) =>
	queryOptions({
		queryKey: ['upsert-dub', { url }],
		queryFn: () => dub(url),
	});

export function useDub(url: string) {
	const options = dubQueryOptions(url);
	return useSuspenseQuery(options);
}
