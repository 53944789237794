import { CheckSquare, Square } from 'lucide-react';
interface Props {
	isRead: boolean;
}
const ReadUnreadIcons = ({ isRead }: Props) => {
	return (
		<div className="pl-6">
			<p className="sr-only">{isRead ? 'Read' : 'Unread'}</p>
			{isRead ? <CheckSquare /> : <Square />}
		</div>
	);
};

export default ReadUnreadIcons;
