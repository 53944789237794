import { components as c } from '@/components';
import { Route as details } from '@/routes/_app/new-diary/patient/$patientId/organisation/$organisationId/diary/guided/injection-diary/details';
import { Route as injection } from '@/routes/_app/new-diary/patient/$patientId/organisation/$organisationId/diary/guided/injection-diary/injection';
import { Route as time } from '@/routes/_app/new-diary/patient/$patientId/organisation/$organisationId/diary/guided/injection-diary/time';

interface Props {
	page: number;
}

const NewInjectionStepper = ({ page }: Props) => {
	return (
		<c.cc.Stepper
			steps={[
				{ stage: page === 0 ? 'current' : 'past', words: 'Patient Instructions', route: time },
				{
					stage: page === 1 ? 'current' : page === 2 ? 'past' : 'future',
					words: 'Injection Procedure',
					route: injection,
				},
				{ stage: page === 2 ? 'current' : 'future', words: 'Injection Details', route: details },
			]}
		/>
	);
};

export default NewInjectionStepper;
