import { diaryByIdQueryOptions } from '@/features/diary/api/getDiaryById';
import SurgeryForm from '@/features/events/features/surgeries/components/SurgeryForm';
import { Route as EditEventRoute } from '@/routes/_app/diary/$diaryId/edit/event/$eventId';
import { createFileRoute, useNavigate } from '@tanstack/react-router';

const Surgery = () => {
	const { diaryId, surgeryId, eventId } = Route.useParams();
	const navigate = useNavigate();
	return (
		<>
			<SurgeryForm
				diaryId={diaryId}
				eventId={eventId}
				surgeryId={surgeryId}
				navigateTo={() =>
					navigate({
						to: EditEventRoute.to,
						params: { diaryId: diaryId, eventId: eventId },
					})
				}
			/>
		</>
	);
};

export const Route = createFileRoute('/_app/diary/$diaryId/edit/event/$eventId/surgery/$surgeryId')({
	loader: async ({ context, params }) => {
		await context?.queryClient.ensureQueryData(diaryByIdQueryOptions(params.diaryId));
	},
	component: Surgery,
});
