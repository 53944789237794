import {
    CheckSquare,
    ChevronsDown,
    FileLock,
    FileX2,
    ClipboardCheck,
    MapPin,
    FileBadge,
    HeartHandshake,
} from 'lucide-react';
export const content = [
    {
        icon: <CheckSquare />,
        title: 'Explicit Consent Mechanism',
        description: 'User-friendly consent forms obtain explicit consent before collecting data.',
    },
    {
        icon: <ChevronsDown />,
        title: 'Data Minimization',
        description: (
            <p>
                Our{' '}
                <a className="link" href="https://www.notion.so/Data-Collected-8cf0e3c40598483394cb5029cb4bf0fb?pvs=21">
                    data collection policy
                </a>{' '}
                shows how we collect only data necessary.
            </p>
        ),
    },
    {
        icon: <FileLock />,
        title: 'Data Encryption',
        description: 'All data transfers are encrypted for confidentiality during transmission.',
    },
    // {
    //     icon: <FolderKey />,
    //     title: 'Access Controls',
    //     description: 'Role-based access controls are based on user roles.',
    // },
    {
        icon: <FileX2 />,
        title: 'Data Portability and Erasure',
        description: 'Patients portability and erasure requests are processed in 48 hours.',
    },
    // {
    //     icon: '',
    //     title: 'Data Breach Response Plan',
    //     description:
    //         'Establish a comprehensive response plan (detailed in our [DCB0129](https://docs.google.com/spreadsheets/d/1-yn9lTsA0xCPB1ZXcE7BHCffKXcn3M8FhIk0MyhX7e4/edit#gid=311514443)), ensuring transparency and compliance.',
    // },
    {
        icon: <ClipboardCheck />,
        title: 'Regular Audits and Assessments',
        description: 'Quarterly internal audits are completed for continuous improvement.',
    },
    // {
    //     icon: '',
    //     title: 'Data Protection Impact Assessments (DPIA)',
    //     description: 'Conduct DPIAs for new functionalities or changes to assess and mitigate risks.',
    // },
    {
        icon: <HeartHandshake />,
        title: 'Data Processing Agreements',
        description: (
            <p>
                Our{' '}
                <a
                    className="link"
                    href="https://www.notion.so/Acceptable-Use-Policy-92dc806b18724405927eb1c0e4cd9b21?pvs=21"
                >
                    Acceptable Use Policy
                </a>{' '}
                ensures third-party vendors are GDPR compliant.
            </p>
        ),
    },
    // {
    //     icon: <GraduationCap />,
    //     title: 'User Education and Training',
    //     description: 'Provide training on GDPR compliance and data protection best practices.',
    // },
    // {
    //     icon: '',
    //     title: 'Incident Response Team',
    //     description: 'Maintain a dedicated team for swift response to security incidents.',
    // },
    // {
    //     icon: '',
    //     title: 'Regular Security Audits and Penetration Testing',
    //     description: 'Conduct audits and testing to assess system resilience against cybersecurity threats.',
    // },
    {
        icon: <FileBadge />,
        title: 'Transparent Privacy Policies',
        description: (
            <p>
                Maintain an accessible{' '}
                <a className="link" href="https://www.notion.so/Privacy-Policy-57a2f888c1d64351a3606ef948e9b025?pvs=21">
                    Privacy Policy
                </a>{' '}
                which is regularly updated.
            </p>
        ),
    },
    {
        icon: <MapPin />,
        title: 'UK Based Data Storage',
        description: 'Implement controls to store patient data securely within the UK.',
    },
];
