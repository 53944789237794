interface Props {
    fill: string;
    stroke: string;
}

const Invite = ({ fill, stroke }: Props) => {
    return (
        <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16 32L31.5613 17.2577C33.4898 15.4308 36.5102 15.4308 38.4387 17.2577L54 32"
                stroke={stroke}
                strokeWidth="1.5"
                strokeLinejoin="round"
            />
            <path d="M22 20V50H47V20H22Z" fill={fill} stroke={stroke} strokeWidth="1.5" strokeLinejoin="round" />
            <path
                d="M16 32V54H54V32L35 46L16 32Z"
                fill="white"
                stroke={stroke}
                strokeWidth="1.5"
                strokeLinejoin="round"
            />
            <path
                d="M32.104 39.5577L16 51V54H54V51L37.896 39.5577C36.1619 38.3256 33.8381 38.3256 32.104 39.5577Z"
                fill="white"
                stroke={stroke}
                strokeWidth="1.5"
                strokeLinejoin="round"
            />
            <rect x="34" y="25" width="2" height="10" rx="0.5" fill="white" />
            <rect x="40" y="29" width="2" height="10" rx="0.5" transform="rotate(90 40 29)" fill="white" />
        </svg>
    );
};

export default Invite;
