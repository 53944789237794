import { quickstartsQueryOptions, useQuickstartsQuery } from '@/features/quickstart/api/selectQuickstarts';
import Quickstarts from '@/features/quickstart/components/Quickstarts';
import { createFileRoute } from '@tanstack/react-router';

const QuickstartPage = () => {
	const { data: quickstarts } = useQuickstartsQuery();
	return (
		<div>
			<Quickstarts quickstarts={quickstarts} />
		</div>
	);
};

export const Route = createFileRoute('/_app/builder/quickstarts')({
	component: QuickstartPage,
	loader: async ({ context }) => {
		await context?.queryClient.ensureQueryData(quickstartsQueryOptions());
	},
});
