import React from 'react';

const Info = () => {
    return (
        <svg width="27" height="29" viewBox="0 0 27 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.6722 0.997565C17.7553 1.30588 20.631 2.69095 22.7942 4.90938C25.1508 7.30335 26.544 10.4808 26.7084 13.836C26.8728 17.1913 25.7967 20.4897 23.6854 23.1025C21.7435 25.5162 19.0185 27.1746 15.9824 27.7904C12.9463 28.4061 9.79044 27.9403 7.06166 26.4738C4.32702 24.9755 2.1922 22.5806 1.01666 19.6925C-0.164137 16.7896 -0.321303 13.5711 0.571035 10.5669C1.46146 7.57439 3.35707 4.98076 5.93791 3.22375C8.49705 1.47789 11.5896 0.689673 14.6722 0.997565ZM15.5867 25.8925C18.1884 25.3648 20.5248 23.9465 22.1935 21.8819C24.0005 19.6362 24.9198 16.8051 24.7769 13.9263C24.634 11.0475 23.4389 8.32136 21.4185 6.26563C19.568 4.37659 17.1124 3.19802 14.4809 2.93594C11.8495 2.67386 9.2097 3.34494 7.02291 4.83188C5.37688 5.96608 4.04768 7.50175 3.16123 9.29341C2.27478 11.0851 1.86043 13.0734 1.95745 15.07C2.05447 17.0666 2.65963 19.0054 3.71561 20.7026C4.77159 22.3999 6.24342 23.7995 7.99166 24.7688C10.3134 26.0218 13.0015 26.4195 15.5867 25.8925ZM12.3607 10.625H14.7826V8.6875H12.3607V10.625ZM14.7826 12.5625V20.3125H12.3607V12.5625H14.7826Z"
                fill="#403FAB"
            />
        </svg>
    );
};

export default Info;
