interface Props {
    fill?: string;
    className?: string;
}
const WhiteTick = ({ fill, className }: Props) => {
    return (
        <svg
            className={`${className}`}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="3.82837"
                y="12"
                width="9"
                height="4"
                rx="1"
                transform="rotate(45 3.82837 12)"
                fill={fill ? fill : 'white'}
            />
            <rect
                x="22.7354"
                y="5.82837"
                width="21.0815"
                height="4"
                rx="1"
                transform="rotate(135 22.7354 5.82837)"
                fill={fill ? fill : 'white'}
            />
        </svg>
    );
};

export default WhiteTick;
