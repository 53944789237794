import React from 'react';

const InvitePatient = () => {
    return (
        <svg width="652" height="708" viewBox="0 0 652 708" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M483.853 161.233C483.853 233.998 422.742 294.467 345.501 294.467C268.26 294.467 207.149 233.998 207.149 161.233C207.149 147.642 209.282 134.479 213.253 122.06L228.532 124.358C253.512 128.114 278.738 130 303.999 130H330.395C375.597 142.906 422.485 149.5 469.723 149.5H483.32C483.673 153.37 483.853 157.283 483.853 161.233ZM230.762 109.524L218.794 107.725C221.579 101.621 224.827 95.7494 228.496 90.1542L263.607 105.759C270.626 108.879 277.708 111.835 284.845 114.628C266.742 113.923 248.687 112.22 230.762 109.524ZM269.699 92.0523L237.647 77.8067C246.341 67.3778 256.639 58.2201 268.201 50.6966C330.547 79.7241 398.187 95.5937 466.899 97.3385C473.432 108.832 478.255 121.331 481.052 134.5H469.723C400.813 134.5 332.67 120.039 269.699 92.0523ZM345.501 28C323.508 28 302.822 32.9026 284.482 41.5957C338.941 65.4767 397.31 79.1531 456.68 81.9584C431.612 49.3566 391.405 28 345.501 28ZM511.853 161.233C511.853 250.28 437.375 322.467 345.501 322.467C253.628 322.467 179.149 250.28 179.149 161.233C179.149 72.1866 253.628 0 345.501 0C437.375 0 511.853 72.1866 511.853 161.233ZM472.553 412.758C433.762 404.936 390.855 400.533 345.5 400.533C253.38 400.533 171.358 418.694 113.409 446.788C53.5301 475.818 28 510.555 28 540.033C28 569.512 53.5301 604.249 113.409 633.279C171.358 661.372 253.38 679.533 345.5 679.533C377.125 679.533 407.559 677.393 436.238 673.445C447.253 682.524 459.572 690.078 472.871 695.784C433.457 703.366 390.483 707.533 345.5 707.533C154.686 707.533 0 632.541 0 540.033C0 447.526 154.686 372.533 345.5 372.533C413.21 372.533 476.371 381.976 529.685 398.294C509.37 399.524 490.089 404.583 472.553 412.758Z"
                fill="black"
            />
            <path
                d="M642 550.447V553C642 611.542 594.542 659 536 659H533.447C476.315 659 430 612.685 430 555.553C430 495.601 478.601 447 538.553 447C595.685 447 642 493.315 642 550.447Z"
                stroke="black"
                strokeWidth="20"
            />
            <rect x="474" y="541" width="124" height="24" rx="12" fill="black" />
            <rect x="548" y="491" width="124" height="24" rx="12" transform="rotate(90 548 491)" fill="black" />
        </svg>
    );
};

export default InvitePatient;
