import { Fish } from 'lucide-react';

const Fisher = () => {
	return (
		<div className="mx-auto  bg-gradient-to-br  from-sky-200 to-blue-200 h-40 w-40 rounded-xl flex items-center justify-center">
			<div className="animate-swim text-indigo-800">
				<Fish size={60} strokeWidth={1.75} />
			</div>
		</div>
	);
};

export default Fisher;
