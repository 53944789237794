import { Zap } from 'lucide-react';

const Pain = () => {
	return (
		<div className="mx-auto  bg-gradient-to-br  from-yellow-200 to-amber-200 h-40 w-40 rounded-xl flex items-center justify-center">
			<div className="animate-minibounce text-amber-800">
				<Zap size={60} strokeWidth={1.75} />
			</div>
		</div>
	);
};

export default Pain;
