import dayjs, { type ManipulateType } from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';

dayjs.extend(calendar);
dayjs.extend(customParseFormat);
dayjs.extend(utc);

// Extend dayjs with the relativeTime plugin
dayjs.extend(relativeTime);

const formatRelativeTime = (number: number, unit: ManipulateType) => {
	const time = dayjs().subtract(number, unit);

	if (number >= 0) {
		return time.fromNow();
	}
	return time.toNow();
};

const toNow = (date: string | undefined | null) => dayjs(date).toNow();

function formatDateTime(date: string | undefined | null) {
	if (!date) {
		return 'Date not set';
	}
	const dateTime = dayjs(date);
	return `${dateTime.format('ddd, DD MMM YYYY, HH:mm')} ${dateTime.format('Z')}`;
}

function formatShortDateTime(date: string | undefined | null) {
	if (!date) {
		return 'Date not set';
	}
	const dateTime = dayjs(date);
	return `${dateTime.format('DD MMM, YYYY, HH:mm')}`;
}

function formatDate(date: string | undefined | null) {
	if (!date) {
		return 'Date not set';
	}
	return dayjs(date).format('DD MMM YYYY');
}

function formatSupabaseDate(date?: string | undefined | null) {
	if (!date) {
		return dayjs().utc().format('YYYY-MM-DD HH:mm:ss.SSS[Z]');
	}
	return dayjs(date).utc().format('YYYY-MM-DD HH:mm:ss.SSS[Z]');
}

function formatISO(date: string | undefined | null) {
	if (!date) {
		return null;
	}
	return dayjs(date).toISOString();
}

const formatRange = (start: string | undefined | null, end: string | undefined | null) => {
	if (!start) {
		return 'Date not set';
	}
	if (!end) {
		return `${formatDate(start)} to now`;
	}
	return `${formatDate(start)} to ${formatDate(end)}`;
};

const findEarliest = (times: any[], selector: string) => {
	try {
		return times.reduce((a: any, b: any) => (dayjs(a[selector]).isAfter(b[selector]) ? a : b));
	} catch (e) {
		console.error(e);
		return null;
	}
};

const composeDateTime = (date?: string, time?: string) => {
	if (!date && !time) return null;
	if (!date) return null;
	if (!time) return formatISO(`${dayjs(date).format('YYYY-MM-DD')} 12:00`);
	return formatISO(`${dayjs(date).format('YYYY-MM-DD')} ${time}`);
};

export const time = {
	formatRelativeTime,
	formatDateTime,
	formatShortDateTime,
	formatDate,
	formatISO,
	formatSupabaseDate,
	toNow,
	formatRange,
	findEarliest,
	composeDateTime,
};
