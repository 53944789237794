import { InfiniteMovingCards } from '../../../components/ui/infinite-moving-cards';

interface Props {
    items: { alt: string; src: string }[];
    direction?: 'left' | 'right';
}
const ImageReel = (props: Props) => {
    return (
        <>
            <div className="relative flex flex-col justify-center items-center md:hidden dark:bg-grid-white/[0.05] py-2 rounded-md max-w-[96vw] antialiased overflow-hidden">
                <InfiniteMovingCards
                    items={props.items.map((i) => (
                        <li key={i.alt}>
                            <img key={i.alt} className="max-w-[160px] max-h-[80px]" src={i.src} alt={i.alt} />
                        </li>
                    ))}
                    direction={props.direction ?? 'left'}
                    speed="normal"
                />
            </div>
            <ul className="md:flex flex-wrap justify-around md:justify-center items-center gap-16 hidden mx-auto px-4 lg:px-16 py-8 lg:py-4 rounded-xl">
                {props.items.map((i) => (
                    <li key={i.alt}>
                        <img key={i.alt} className="max-w-[160px] max-h-[80px]" src={i.src} alt={i.alt} />
                    </li>
                ))}
            </ul>
        </>
    );
};

export default ImageReel;
