const ClockQuestion = () => {
	return (
		<svg
			aria-label="Clock with Question Mark Icon"
			role="img"
			width="71"
			height="70"
			viewBox="0 0 71 70"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M65.1212 33.5606C65.1212 50.4388 51.4388 64.1212 34.5606 64.1212C17.6824 64.1212 4 50.4388 4 33.5606C4 16.6824 17.6824 3 34.5606 3C51.4388 3 65.1212 16.6824 65.1212 33.5606Z"
				fill="white"
				stroke="black"
				strokeWidth="2"
			/>
			<path
				d="M62.9419 33.5606C62.9419 49.2352 50.2352 61.9419 34.5606 61.9419C18.886 61.9419 6.17925 49.2352 6.17925 33.5606C6.17925 17.886 18.886 5.17925 34.5606 5.17925C50.2352 5.17925 62.9419 17.886 62.9419 33.5606ZM34.5606 65.2462C52.0601 65.2462 66.2462 51.0601 66.2462 33.5606C66.2462 16.0611 52.0601 1.875 34.5606 1.875C17.0611 1.875 2.875 16.0611 2.875 33.5606C2.875 51.0601 17.0611 65.2462 34.5606 65.2462Z"
				fill="black"
				stroke="black"
				strokeWidth="0.25"
			/>
			<path
				d="M24.38 16.2533L25.9876 15.5113C33.0726 12.2413 41.4744 13.4527 47.3991 18.531V18.531C56.9676 26.7326 56.5354 41.851 46.4951 49.4678V49.4678C40.4856 54.0267 32.3311 54.7421 25.6492 51.2421V51.2421C19.2359 47.8827 15.2173 41.2412 15.2173 34.0013V31.5245M17.2535 24.398C17.2535 24.398 18.2715 22.3618 19.2896 21.3437"
				stroke="black"
				strokeWidth="3"
				strokeLinecap="round"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M34.5605 18.7983C35.4039 18.7983 36.0876 19.4821 36.0876 20.3255V34.1344L28.5832 42.7108C28.0278 43.3456 27.063 43.4099 26.4283 42.8545C25.7935 42.2991 25.7292 41.3343 26.2846 40.6996L33.0333 32.9868V20.3255C33.0333 19.4821 33.7171 18.7983 34.5605 18.7983Z"
				fill="black"
			/>
			<circle cx="56.0238" cy="54.9762" r="12.5238" fill="#F6AE2D" stroke="black" strokeWidth="3" />
			<path
				d="M55.88 59.8793C55.4378 59.8793 55.0615 60.0302 54.7497 60.3329C54.4375 60.6356 54.2816 61.0032 54.2816 61.4341C54.2816 61.922 54.4394 62.3042 54.7545 62.5826C55.0708 62.8608 55.4378 63 55.8588 63C56.287 63 56.66 62.8628 56.9793 62.5876C57.2986 62.3132 57.4573 61.929 57.4573 61.4344C57.4573 61.0032 57.305 60.6358 57.0003 60.3332C56.6948 60.0302 56.3218 59.8793 55.88 59.8793V59.8793ZM60.3875 49.166C59.9824 48.4986 59.4022 47.9635 58.6619 47.5776C57.927 47.1935 57.0706 47 56.1151 47C55.0872 47 54.1725 47.2327 53.3973 47.6923C52.6206 48.1517 52.022 48.7416 51.6155 49.4469C51.2062 50.1545 51 50.8658 51 51.5616C51 51.934 51.1421 52.2807 51.4224 52.5924C51.7041 52.9058 52.0547 53.0647 52.464 53.0647C53.1607 53.0647 53.6382 52.6198 53.8806 51.7498C54.1014 51.0282 54.3734 50.4791 54.6881 50.115C54.9843 49.7721 55.4646 49.5986 56.1153 49.5986C56.6766 49.5986 57.1193 49.7691 57.4707 50.12C57.8167 50.4669 57.9849 50.8808 57.9849 51.3842C57.987 51.6279 57.9303 51.868 57.8204 52.0807C57.7036 52.3079 57.5583 52.5163 57.3885 52.7001C57.2086 52.895 56.9119 53.1892 56.5064 53.5731C56.0339 54.0217 55.6526 54.4159 55.3741 54.7411C55.0797 55.0892 54.8432 55.4901 54.6751 55.9258C54.4996 56.3767 54.4108 56.9135 54.4108 57.5221C54.4108 58.0422 54.5446 58.4449 54.8092 58.7193C55.071 58.9918 55.397 59.13 55.7788 59.13C56.5087 59.13 56.9671 58.6978 57.1032 57.8895C57.1738 57.5534 57.2252 57.3184 57.26 57.1842C57.2916 57.0627 57.3373 56.9383 57.3961 56.8138C57.4531 56.6933 57.5436 56.5561 57.6647 56.4051C57.7913 56.2474 57.9636 56.0612 58.1738 55.8535C58.9602 55.0922 59.5148 54.5406 59.8226 54.2142C60.14 53.877 60.4168 53.4734 60.6464 53.013C60.8807 52.5406 61 51.986 61 51.3639C60.9995 50.5756 60.7933 49.8368 60.3861 49.1667"
				fill="black"
			/>
		</svg>
	);
};

export default ClockQuestion;
