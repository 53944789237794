import Call from '@/components/common/Call';
import { ui } from '@/components/ui';
import { Route as ReferenceDateRoute } from '@/routes/_app/diary/$diaryId/edit/tracker/$trackerId/reference-date';
import type { StatefulDiaryT } from '@/types/database/diaries';
import { Link } from '@tanstack/react-router';
import { Info } from 'lucide-react';
import { useEffect, useState } from 'react';
import TrackerReferenceDateForm from './TrackerReferenceDateForm';

interface Props {
	diary: StatefulDiaryT;
}
const MissingTrackerReferenceDate = ({ diary }: Props) => {
	const [trackerId, setTrackerId] = useState<string>('');
	const [missingTracker, setMissingTracker] = useState<boolean>(false);
	useEffect(() => {
		const missing = diary.trackers_view?.find((tv) => tv?.reference_date === null);
		setMissingTracker(!!missing);
		setTrackerId(missing?.tracker_id as string);
	}, [diary]);
	return (
		<>
			{missingTracker && (
				<Call className="mb-4" variant="warning" icon={<Info />} words="Date Missing">
					Intervention date missing for this diary, please add this date as soon as possible.
					<ui.Dialog>
						<ui.DialogTrigger asChild>
							<ui.Button className="bg-amber-600 hover:bg-amber-700" size="sm">
								Add Date
							</ui.Button>
						</ui.DialogTrigger>
						<ui.DialogContent>
							<ui.DialogHeader>
								<ui.DialogTitle>Reference Date</ui.DialogTitle>
							</ui.DialogHeader>
							<TrackerReferenceDateForm diaryId={diary.diary_id} trackerId={trackerId} />
						</ui.DialogContent>
					</ui.Dialog>
					{/* <ui.Button className="bg-amber-600 hover:bg-amber-700" size="sm" asChild>
						<Link
							to={ReferenceDateRoute.to}
							params={{
								diaryId: diary.diary_id as string,
								trackerId: missingTracker.tracker_id,
							}}
						>
							Add Date
						</Link>
					</ui.Button> */}
				</Call>
			)}
		</>
	);
};

export default MissingTrackerReferenceDate;
