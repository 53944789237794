import { useNavigate } from '@tanstack/react-router';

import { useImagesStore } from './useImagesStore';

const useNextFootWatchPage = (
    diaryId: string,
    page: 'camera' | 'instruction',
    angle: 'sole' | 'top',
    side: 'left' | 'right',
) => {
    const navigate = useNavigate();
    const images = useImagesStore((state) => state.images);
    const allImagesChecked = useImagesStore((state) => state.allImagesChecked);

    const newSide = side === 'left' ? 'right' : 'left';
    const newAngle = angle === 'sole' ? 'top' : 'sole';

    const navigateNext = () => {
        console.log('nav-next');
        const checked = allImagesChecked(images);
        if (checked) {
            navigate({
                to: '/diary/$diaryId/home/new/entry/$entryId/page/$pageIndex/foot-photos/upload',
                params: (prev) => ({ ...prev }),
            });
        } else if (page === 'camera') {
            // If camera page, the next page will be instructions for a different side or angle
            if (side === 'left') {
                // If left side, go to instructions for the same angle but the right side
                navigate({
                    to: `/diary/$diaryId/home/new/entry/$entryId/page/$pageIndex/foot-photos/instructions/$angle/$side`,
                    params: (prev) => ({ ...prev, diaryId: diaryId, side: newSide, angle: angle }),
                });
            } else if (side === 'right') {
                // If right side, go to instructions for the other side and other angle
                navigate({
                    to: `/diary/$diaryId/home/new/entry/$entryId/page/$pageIndex/foot-photos/instructions/$angle/$side`,
                    params: (prev) => ({ ...prev, side: newSide, angle: newAngle }),
                });
            }
        } else {
            navigate({
                to: `/diary/$diaryId/home/new/entry/$entryId/page/$pageIndex/foot-photos/camera`,
                params: (prev) => ({ ...prev }),
                search: { side: side, angle: angle, delay: angle === 'sole' ? 10 : 0 },
            });
        }
    };
    return { navigateNext };
};

export default useNextFootWatchPage;
