import { ui } from '@/components/ui';
import { buttonVariants } from '@/components/ui/button';
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import ShareDiaryDialog from '@/features/sharing/components/ShareDiaryDialog';
import { cn } from '@/lib/utils';
import { Route as NewTrackerRoute } from '@/routes/_app/new-diary/patient/$patientId/organisation/$organisationId/diary/$diaryId/tracker/$trackerId/tracker-templates';
import { Route as PrintDiaryRoute } from '@/routes/preview/patient/$patientId/diary/$diaryId';
import { services as s } from '@/services';
import type { StatefulDiaryT } from '@/types/database/diaries';
import { Link } from '@tanstack/react-router';
import { Clipboard, Printer, Users2 } from 'lucide-react';
interface Props {
	diary: StatefulDiaryT;
}
const MoreDropdown = ({ diary }: Props) => {
	return (
		<ui.Dialog>
			<DropdownMenu>
				<DropdownMenuTrigger className={cn(buttonVariants({ size: 'sm', variant: 'secondary' }))}>
					More
				</DropdownMenuTrigger>
				<DropdownMenuContent className="min-w-sm" align="start">
					<DropdownMenuLabel>More Options</DropdownMenuLabel>
					<DropdownMenuSeparator />
					<DropdownMenuItem asChild className="text-md flex gap-2">
						<Link
							to={NewTrackerRoute.to}
							params={{
								diaryId: diary.diary_id,
								patientId: diary.patient_id as string,
								organisationId: diary.organisation_id as string,
								trackerId: s.uuidv4(),
							}}
						>
							<Clipboard size={24} strokeWidth={1.5} />
							<span>Add to Diary</span>
						</Link>
					</DropdownMenuItem>
					<ui.DialogTrigger asChild>
						<DropdownMenuItem className="text-md flex gap-2">
							<Users2 size={24} strokeWidth={1.5} />
							<span>Share</span>
						</DropdownMenuItem>
					</ui.DialogTrigger>
					<DropdownMenuItem className="text-md flex gap-2" asChild>
						<Link to={PrintDiaryRoute.to} params={{ diaryId: diary.diary_id }} target="_blank">
							<Printer size={24} strokeWidth={1.5} />
							<span>Print</span>
						</Link>
					</DropdownMenuItem>
					<div className="p-2 rounded-md cursor-not-allowed text-slate-500 text-md flex flex-row items-center justify-start">
						<Clipboard size={24} strokeWidth={1.5} />
						<span className="pl-2">New Form (Coming Soon)</span>
					</div>
				</DropdownMenuContent>
			</DropdownMenu>
			<ShareDiaryDialog diary={diary} />
		</ui.Dialog>
	);
};

export default MoreDropdown;
