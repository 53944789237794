import {
	Breadcrumb,
	BreadcrumbItem,
	BreadcrumbLink,
	BreadcrumbList,
	BreadcrumbSeparator,
} from '@/components/ui/breadcrumb';
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Separator } from '@/components/ui/separator';
import { services as s } from '@/services';
import type { ToT } from '@/types/zod/routes';
import { useNav } from '@/utils/nav';
import { ChevronDownIcon } from '@radix-ui/react-icons';
import { useNavigate } from '@tanstack/react-router';
import React from 'react';

export interface BreadcrumbItemT {
	label: React.ReactNode;
	route: ToT;
	options?: Array<{
		label: string;
		route: ToT;
	}>;
}

interface Props {
	backOnly?: boolean;
}

const NavigationBreadcrumb = ({ backOnly }: Props) => {
	const navigate = useNavigate();
	const nav = useNav();

	const penultimateBreadcrumb = () => {
		try {
			const breadcrumbs = nav?.breadcrumbRoutes;
			if (!breadcrumbs) return null;

			if (breadcrumbs.length > 1) {
				return breadcrumbs[breadcrumbs.length - 2];
			}
			if (breadcrumbs.length === 1) {
				return breadcrumbs[0];
			}
			return null;
		} catch (e) {
			console.error(e);
			return null;
		}
	};

	return (
		<>
			<Separator className="h-8 dark:bg-slate-200" orientation="vertical" />

			<Breadcrumb>
				<BreadcrumbList className="capitalize text-slate-800 dark:text-slate-200">
					{backOnly ? (
						<BreadcrumbItem>
							<BreadcrumbLink className="text-lg" onClick={() => navigate(penultimateBreadcrumb()?.route)}>
								{penultimateBreadcrumb()?.label}
							</BreadcrumbLink>
						</BreadcrumbItem>
					) : (
						nav.breadcrumbRoutes.map((item, index) => (
							<React.Fragment key={`breadcrumb_item_${s.uuidv4()}`}>
								{item?.options?.length ? (
									<BreadcrumbItem>
										<DropdownMenu>
											<DropdownMenuTrigger className="flex items-center gap-1">
												{item?.label}
												<ChevronDownIcon />
											</DropdownMenuTrigger>
											<DropdownMenuContent align="start">
												{item.options?.map((option) => (
													<React.Fragment key={`breadcrumb_option_${option.label}`}>
														<DropdownMenuItem onClick={() => navigate(option.route)}>{option.label}</DropdownMenuItem>
													</React.Fragment>
												))}
											</DropdownMenuContent>
										</DropdownMenu>
									</BreadcrumbItem>
								) : (
									<BreadcrumbItem>
										<BreadcrumbLink onClick={() => navigate(item?.route)}>{item?.label}</BreadcrumbLink>
									</BreadcrumbItem>
								)}
								{index !== nav.breadcrumbRoutes?.length - 1 && <BreadcrumbSeparator />}
							</React.Fragment>
						))
					)}
				</BreadcrumbList>
			</Breadcrumb>
		</>
	);
};

export default NavigationBreadcrumb;
