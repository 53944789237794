const Zero = () => {
	return (
		<svg
			role="img"
			aria-label="Zero Pain"
			width="50"
			height="50"
			viewBox="0 0 50 50"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect x="1" y="1" width="48" height="48" rx="24" fill="white" stroke="black" strokeWidth="2" />
			<rect x="11" y="16" width="8" height="8" rx="4" fill="#0E0E0E" />
			<rect x="31" y="16" width="8" height="8" rx="4" fill="#0E0E0E" />
			<path
				d="M38.2675 29H11.7325C10.7757 29 10 29.7757 10 30.7325C10 34.0437 11.5775 37.1569 14.2477 39.115L15.1469 39.7744C18.0046 41.8701 21.4562 43 25 43C28.5438 43 31.9954 41.8701 34.8531 39.7744L35.7523 39.115C38.4225 37.1569 40 34.0437 40 30.7325C40 29.7757 39.2243 29 38.2675 29Z"
				fill="black"
				stroke="black"
			/>
			<path
				d="M30.4936 39H19.4555C18.648 39 18.2947 40.0196 18.9291 40.5192L19.078 40.6365C19.2155 40.7447 19.37 40.8295 19.5352 40.8873L20.503 41.2261C21.9667 41.7383 23.5063 42 25.0571 42C26.804 42 28.5349 41.668 30.1579 41.0216L30.5424 40.8684C30.6564 40.8231 30.7632 40.7615 30.8596 40.6856L31.0309 40.5507C31.6785 40.0407 31.3179 39 30.4936 39Z"
				fill="white"
			/>
			<rect x="11" y="30" width="28" height="3" rx="1.5" fill="white" />
		</svg>
	);
};

export default Zero;
