import { icons } from '@/assets/icons';
interface Props {
    words: string;
}

const WarningMessage = ({ words }: Props) => {
    return (
        <div className="z-[60] left-[2vw] w-[96vw]  lg:left-[10vw] lg:w-[80vw] top-20 lg:top-2 fixed px-2  flex items-center justify-center">
            <div
                className={`p-2 flex items-center justify-center rounded-xl w-full max-w-[600px]  mx-auto border-2 transition-all duration-200 border-red-600 bg-red-100 opacity-1`}
                aria-live="assertive"
            >
                <div className="h-[50px] w-[50px]">
                    <icons.WarningRed />
                </div>
                <b className={`mx-4 font-medium text-lg w-full  text-red-600`}>{words}</b>
            </div>
        </div>
    );
};

export default WarningMessage;
