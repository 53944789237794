import Section from '@/features/website/components/Section';

import AnimateInView from '../../../components/ui/animate-in-view.tsx';
import HeroContent from '../components/HeroContent.tsx';
import diaryEntryMobile from './assets/patient_app.png';

const PatientFlow = () => {
	return (
		<>
			<Section row={true}>
				<div className="w-full my-auto">
					<HeroContent caption="Patient App" title="Simple for Patients" />
					<div className="flex flex-col text-md gap-y-4 items-center justify-center max-w-[600px] mr-auto">
						<p>
							The patient experience is designed to be as simple as possible. Each diary is created in three simple
							steps:
						</p>

						<div className="space-y-4 px-8 py-4">
							<p className="flex gap-x-2">
								<strong>1 </strong>
								Accepts the doctor’s invitation and completes their personal details.
							</p>
							<p className="flex gap-x-2">
								<strong>2 </strong>
								Systematic email and SMS reminders when to log.
							</p>

							<p className="flex gap-x-2">
								<strong className="">3 </strong>
								Reports their pain on a scale from 0-10.
							</p>
						</div>
					</div>
				</div>

				<AnimateInView>
					<img className="max-h-[500px] mx-auto" src={diaryEntryMobile} alt="Diary Entry Mobile" />
				</AnimateInView>
			</Section>
		</>
	);
};

export default PatientFlow;
