import { DateStringZ } from '@/types/zod/common';
import { z } from 'zod';

export const TrackerSchemaZ = z.object({
	diary_id: z.string().uuid('Diary ID not a valid UUID'),
	tracker_id: z.string().uuid('Tracker ID not a valid UUID'),
	schedule_id: z.string().uuid('Schedule ID not a valid UUID'),
	form_id: z.string().uuid('Form ID not a valid UUID'),
	contact_id: z.string().uuid().optional(),
	start_date: DateStringZ,
	end_date: z.string().nullable(),
});

export type TrackerSchemaT = z.infer<typeof TrackerSchemaZ>;

export const UpdateTrackerSchemaZ = z.object({
	tracker_id: z.string().uuid('Tracker ID not a valid UUID'),
	diary_id: z.string().uuid('Diary ID not a valid UUID'),
	schedule_id: z.string().uuid('Schedule ID not a valid UUID'),
	form_id: z.string().uuid('Form ID not a valid UUID'),
	contact_id: z.string().uuid().optional(),
	start_date: DateStringZ.optional(),
	end_date: DateStringZ.nullable().optional(),
});

export type UpdateTrackerSchemaT = z.infer<typeof TrackerSchemaZ>;
