import { Card, CardContent, CardDescription, CardFooter, CardHeader } from '@/components/ui/card';
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Label } from '@/components/ui/label';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import { cn } from '@/lib/utils';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

interface Props {
	name: string;
	label: string;
}

const options = [
	{ label: 'A', value: 'a', text: 'If you have no pain while undertaking Achilles tendon loading sports' },
	{
		label: 'B',
		value: 'b',
		text: 'If you have pain while undertaking Achilles tendon loading sports but it does not stop you from completing the activity',
	},
	{ label: 'C', value: 'c', text: 'If you have pain that stops you from completing Achilles tendon loading sports' },
];

const scaleOptions = [
	{ label: '0', value: '0' },
	{ label: '1-10', value: '1' },
	{ label: '11-20', value: '2' },
	{ label: '21-30', value: '3' },
	{ label: '>30', value: '4' },
];

const pointsMap = {
	a: [0, 7, 14, 21, 30],
	b: [0, 4, 10, 14, 20],
	c: [0, 2, 5, 7, 10],
};

const VisaA8 = ({ name }: Props) => {
	const { control } = useFormContext();
	const [selectedOption, setSelectedOption] = useState<'a' | 'b' | 'c' | null>(null);

	return (
		<Card>
			<CardContent className="p-4">
				<FormField
					control={control}
					name={name}
					render={({ field }) => (
						<FormItem className="space-y-6">
							<FormLabel>
								<Label>8. Please complete EITHER A, B or C in this question.</Label>
							</FormLabel>
							<FormControl>
								<RadioGroup
									onValueChange={(value: 'a' | 'b' | 'c') => {
										setSelectedOption(value);
										field.onChange(null);
									}}
									value={selectedOption || undefined}
									className="space-y-4"
								>
									{options.map((option) => (
										<div
											key={option.value}
											className={cn(
												'flex flex-col items-start p-4 border rounded-lg',
												selectedOption === option.value ? 'bg-indigo-100' : 'bg-slate-100 text-slate-700',
											)}
										>
											<div className="flex flex-row justify-start items-center gap-x-3">
												<RadioGroupItem value={option.value} id={`option-${option.value}`} className="mt-1" />
												<div className="flex-grow">
													<Label htmlFor={`option-${option.value}`} className="font-medium">
														{option.label}. {option.text}
													</Label>
												</div>
											</div>
											{selectedOption === option.value && (
												<div className="mt-4">
													<Label className="block mb-2">Select duration:</Label>
													<ul className="flex flex-col justify-start items-start gap-y-2">
														{scaleOptions.map((scaleOption, index) => (
															<li
																key={`scale-option-${scaleOption.value}`}
																className="flex flex-row justify-start items-center gap-x-4"
															>
																<button
																	type="button"
																	onClick={() => field.onChange(pointsMap[selectedOption][index])}
																	className={cn(
																		'rounded-md h-12 w-20 px-2 text-lg transition-all duration-300 ease-in-out',
																		'focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2',
																		field.value === pointsMap[selectedOption][index]
																			? 'bg-indigo-600 text-white'
																			: 'bg-slate-200 text-slate-700 hover:bg-slate-300',
																	)}
																>
																	{pointsMap[selectedOption][index]}
																</button>
																<span className="w-full text-left text-md text-slate-600">
																	{scaleOption.label} mins
																</span>
															</li>
														))}
													</ul>
												</div>
											)}
										</div>
									))}
								</RadioGroup>
							</FormControl>
							<FormMessage />
						</FormItem>
					)}
				/>
			</CardContent>
		</Card>
	);
};

export default VisaA8;
