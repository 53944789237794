import Select from './Select';

const titleOptions = [
	{ label: 'Mr', value: 'Mr' },
	{ label: 'Mrs', value: 'Mrs' },
	{ label: 'Ms', value: 'Ms' },
	{ label: 'Miss', value: 'Miss' },
	{ label: 'Dr', value: 'Dr' },
	{ label: 'Prof', value: 'Prof' },
];

interface Props {
	autoFocus?: boolean;
}

const SelectTitle = ({ autoFocus }: Props) => {
	return <Select autoFocus={autoFocus} name="title" label="Title" options={titleOptions} />;
};

export default SelectTitle;
