import type { ExtendedInjectionViewT, StatefulTrackerViewT } from '../../../types/database/trackers';
import InjectionLinks from '../../injection/components/InjectionLinks';

interface Props {
	trackerId: string;
	managementTypes: StatefulTrackerViewT['management_types'];
	injections: ExtendedInjectionViewT[] | null;
}
const ManagementTypeLinks = (props: Props) => {
	return (
		<ul className="mb-2">
			{props.managementTypes?.map((mt) => (
				<li key={mt}>
					{mt === 'injection' && <InjectionLinks trackerId={props.trackerId} injections={props.injections} />}
				</li>
			))}
		</ul>
	);
};

export default ManagementTypeLinks;
