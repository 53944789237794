import { components as c } from '@/components';
import { Route as EditSurgeryRoute } from '@/routes/_app/diary/$diaryId/edit/event/$eventId/surgery/$surgeryId';
import type { ExtendedEventViewT, ManagementTypeT } from '@/types/database/events';
import { Link } from '@tanstack/react-router';

import InjectionDialog from '../../injection/components/InjectionDialog';

interface Props {
	event: ExtendedEventViewT;
	eventType: ManagementTypeT;
}
const EventSwitch = ({ event, eventType }: Props) => {
	switch (eventType) {
		case 'injection':
			return event.injections_view?.map((i) => (
				<InjectionDialog
					organisationId={event.organisation_id}
					key={i.injection_id}
					injection={i}
					injectionId={i.injection_id}
					eventId={event.event_id}
					diaryId={event.diary_id}
				/>
			));
		// return <InjectionLinks event={event} />;
		// return <InjectionLinks event={event} />;
		case 'medication':
			return <p className="text-lg text-slate-800">Medication Change</p>;
		case 'surgery':
			return event.surgeries.length > 0 ? (
				event.surgeries?.map((s) => (
					<Link
						key={s.surgery_id}
						className="flex  items-center justify-between"
						to={EditSurgeryRoute.to}
						params={{ diaryId: event.diary_id, eventId: event.event_id, surgeryId: s.surgery_id }}
					>
						<div>
							<h4 className="text-lg text-slate-700">Surgery</h4>
							<p className="text-lg text-slate-800">
								{event.surgeries?.map((s) => s.surgery_name).join(', ') ?? 'Surgery'}
							</p>
						</div>
						<c.cc.ChevronButton />
					</Link>
				))
			) : (
				<></>
			);
		default:
			return <p className="text-lg text-slate-800">No Event Type</p>;
	}
};

export default EventSwitch;
