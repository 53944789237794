import { ui } from '@/components/ui';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';
import { Link, useRouteContext } from '@tanstack/react-router';

import { useAuth } from '../../../utils/auth';
import { type NavigationItem, type Page, navigationContent } from './navigation-content';

const MobileNavigation = () => {
	const auth = useAuth();
	const context = useRouteContext({ strict: false });
	return (
		<>
			<ui.SheetHeader className="mt-8">
				<ui.SheetTitle className="text-left">Patient Watch</ui.SheetTitle>
				<ui.SheetDescription className="text-left">
					Patient Watch empowers patients to monitor their own health to allow clinicians to make better decisions.
				</ui.SheetDescription>
			</ui.SheetHeader>
			<Accordion type="single" collapsible>
				{navigationContent
					?.filter((list: NavigationItem) => !list.footerOnly && !list.draft)
					?.map((heading: NavigationItem) => {
						return (
							<AccordionItem value={heading.name}>
								<AccordionTrigger className="text-lg">{heading?.name}</AccordionTrigger>
								{heading?.pages?.map((page: Page) => (
									<ui.SheetClose asChild>
										<AccordionContent key={page.href}>
											<a className="mb-1 flex items-center gap-x-2 text-lg" href={page.href}>
												{page.icon}
												{page.name}
											</a>
										</AccordionContent>
									</ui.SheetClose>
								))}
							</AccordionItem>
						);
					})}
			</Accordion>

			<div className="mt-8 flex flex-col gap-y-4 lg:hidden">
				{auth.isAuthenticated ? (
					<ui.SheetClose asChild>
						<Link to="/profile">
							<ui.Button size="lg">Sign in</ui.Button>
						</Link>
					</ui.SheetClose>
				) : (
					<ui.SheetClose asChild>
						<ui.Button asChild className="w-full" variant="default">
							<Link className="w-full" to="/authenticate">
								Sign up / Sign in
							</Link>
						</ui.Button>
					</ui.SheetClose>
				)}
			</div>
		</>
	);
};

export default MobileNavigation;
