import { useSelectUsageStatistics } from "../api/selectUsageStatistics";
import { ui } from "../../../components/ui";
import DiariesByTabSparkChart from '../../charts/clinician-statistics/DiariesByTabSparkChart';
import UsersSparkChart from '../../charts/clinician-statistics/UsersSparkChart';

const UsageStatistics = () => {
  const { data: usageStatistics } = useSelectUsageStatistics();

  return (
    <div className='flex md:flex-row flex-col gap-4 min-w-sm'>
      <ui.Card className='flex flex-grow p-4 h-60'>
          <UsersSparkChart usersStats={usageStatistics}/>
      </ui.Card>
        <ui.Card className='flex flex-grow p-4 h-60'>
          <DiariesByTabSparkChart diaryBreakdown={usageStatistics}/>
      </ui.Card>
    </div>
  );
};

export default UsageStatistics;
