const Three = () => {
	return (
		<svg
			role="img"
			aria-label="Pain Three"
			width="50"
			height="50"
			viewBox="0 0 50 50"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect x="1" y="1" width="48" height="48" rx="24" fill="white" stroke="black" strokeWidth="2" />
			<rect x="11" y="16" width="8" height="8" rx="4" fill="#0E0E0E" />
			<rect x="31" y="16" width="8" height="8" rx="4" fill="#0E0E0E" />
			<path
				d="M18.8148 37.0247L17.5177 36.5904C17.0236 36.4249 16.6085 36.0819 16.3531 35.6277C16.2098 35.373 16.423 35.0652 16.7118 35.1097L19.2233 35.4966C20.8221 35.743 22.4374 35.8667 24.0551 35.8667L25.4882 35.8667C27.1606 35.8667 28.8308 35.7467 30.486 35.5076L33.2917 35.1023C33.581 35.0605 33.7925 35.3689 33.6492 35.6237C33.3922 36.0804 32.9749 36.4255 32.478 36.5919L31.1852 37.0247C29.2563 37.6707 27.2355 38 25.2013 38L24.7987 38C22.7645 38 20.7437 37.6707 18.8148 37.0247Z"
				fill="black"
				stroke="black"
			/>
		</svg>
	);
};

export default Three;
