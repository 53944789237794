import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { HelpCircle, X } from 'lucide-react';

import BOFAS from './documents/Cat_Flood_BOFAS.pdf';
import EFORT from './documents/EFORT_Pain_Diary_Abstract.pdf';
import OPAs from './documents/Saving_OPAs_after_Injections_in_MSK.pdf';

const CheckTheMaths = () => {
	return (
		<Popover>
			<PopoverTrigger className="p-2 flex items-center justify-center md:mr-auto text-indigo-600">
				<HelpCircle className="w-6 h-6" />
				<p className="ml-2 text-xl">Check the Maths</p>
			</PopoverTrigger>
			<PopoverContent className="text-lg flex flex-col gap-y-2">
				<h5 className="text-slate-800 font-semibold">Statistics:</h5>
				<ol className="py-0">
					<li>
						<a href={OPAs} target="_blank" className="text-indigo-600 underline" rel="noreferrer">
							594 000 therapeutic injections a year
						</a>
					</li>
					<li>
						<a href={BOFAS} target="_blank" className="text-indigo-600 underline" rel="noreferrer">
							85% of therapeutic injections receive routine follow-up
						</a>
					</li>

					<li>
						<a
							href="https://www.porthosp.nhs.uk/patients_and_visitors/appointments.html"
							target="_blank"
							className="text-indigo-600 underline"
							rel="noreferrer"
						>
							Each follow-up appointment costs £160
						</a>
					</li>
					<li>
						<a href={EFORT} target="_blank" className="text-indigo-600 underline" rel="noreferrer">
							Pain Watch saves 91% of routine follow-ups
						</a>
					</li>
				</ol>
				<div className="h-[1.5px] bg-slate-300 w-full mb-4" />
				<div className="font-bold flex flex-wrap items-center justify-center">
					<p>576 000</p>
					<X className="wc-4 h-4" />
					<p>85%</p>
					<X className="wc-4 h-4" />
					<p>£160</p>
					<X className="wc-4 h-4" />
					<p>91%</p>
					<p className="px-2">=</p>
					<p>£73.5 million</p>
				</div>
			</PopoverContent>
		</Popover>
	);
};

export default CheckTheMaths;
