import { Link } from "@tanstack/react-router";
import { ui } from "@/components/ui";
import { ToT } from '@/types/zod/routes';

interface CardProps {
	title: string;
	description: string;
	to?: ToT;
	variant?: 'default' | 'destructive' | 'outline' | 'secondary' | 'ghost';
	button?: React.ReactNode;
}

const AdminPageCardLink = ({ title, description, variant, to, button }: CardProps) => {
	return (
		<ui.Card className="w-full">
			<ui.CardHeader>
				<ui.CardTitle>{title}</ui.CardTitle>
				<ui.CardDescription>{description}</ui.CardDescription>
			</ui.CardHeader>
			<ui.CardFooter>
				{to && (
					<ui.Button variant={variant ?? 'secondary'} asChild size="md">
						<Link to={to.to}>Next</Link>
					</ui.Button>
				)}
				{button && button}
			</ui.CardFooter>
		</ui.Card>
	);
};

export default AdminPageCardLink;
