import type { ExtendedProfileT } from '@/types/database/profiles';

import { useProfileQuery } from '../../features/profile/api/selectProfile';
import type { OptionT } from '../form/Select';

const useOrganisationOptions = () => {
	const { data: profile } = useProfileQuery();
	const organisationObjOptions = extractOrganisationObjectsFromProfile(profile);
	const organisationIdOptions = extractOrganisationIdsFromProfile(profile);
	if (profile) return { organisationObjOptions, organisationIdOptions };
	return { organisationObjOptions: [], organisationIdOptions: [] };
};
export default useOrganisationOptions;

export function extractOrganisationObjectsFromProfile(profile: ExtendedProfileT): OptionT[] {
	return (
		// Create a Set of unique organisation IDs
		profile?.organisations
			?.filter((o, i, arr) => arr.findIndex((t) => t.organisation_id === o.organisation_id) === i)
			// Map the filtered array to the format expected by the Select component
			?.map((o) => {
				return {
					label: o.organisation_name,
					value: JSON.stringify({
						organisation_id: o.organisation_id,
						organisation_name: o.organisation_name,
					}),
				};
			}) as OptionT[]
	);
}

export function extractOrganisationIdsFromProfile(profile: ExtendedProfileT): OptionT[] {
	return profile?.organisations
		?.filter((o, i, arr) => arr.findIndex((t) => t.organisation_id === o.organisation_id) === i)
		?.map((o) => {
			return {
				label: o.organisation_name,
				value: o.organisation_id,
			};
		}) as OptionT[];
}
