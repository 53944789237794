import type { StatefulDiaryT } from '@/types/database/diaries';
import DiaryOverviewCard from './DiaryOverviewCard';

interface DiaryListProps {
	diaries: StatefulDiaryT[];
}

const DiaryList: React.FC<DiaryListProps> = ({ diaries }) => {
	const toDo = diaries.filter((d) => d.end_date === null).filter((d) => d.tracker_due);
	const upcoming = diaries.filter((d) => d.end_date === null).filter((d) => !d.tracker_due);
	const completed = diaries.filter((d) => d.end_date !== null);
	return (
		<div>
			<div className="mb-8">
				<h2 className="text-lg font-bold text-slate-900 dark:text-slate-100 mb-2">
					{toDo.length} Diary(s) with Items To Do
				</h2>
				{toDo.map((diary) => (
					<DiaryOverviewCard key={diary.diary_id} diary={diary} />
				))}
			</div>
			<div className="mb-8">
				<h2 className="text-lg font-bold text-slate-900 dark:text-slate-100 mb-2">Up to Date Diaries</h2>
				{upcoming.map((diary) => (
					<DiaryOverviewCard key={diary.diary_id} diary={diary} />
				))}
			</div>
			<div className="mb-8">
				<h2 className="text-lg font-bold text-slate-900 dark:text-slate-100 mb-2">Completed Diaries</h2>
				{completed.map((diary) => (
					<DiaryOverviewCard key={diary.diary_id} diary={diary} />
				))}
			</div>
		</div>
	);
};

export default DiaryList;
