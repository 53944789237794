import { commonComponents as cc } from '@/components/common';
import { ui } from '@/components/ui';
import { Route as EditRoute } from '@/routes/_app/diary/$diaryId/edit';
import type { StatefulDiaryT } from '@/types/database/diaries';
import { Link } from '@tanstack/react-router';
import dayjs from 'dayjs';

interface Props {
	diary: StatefulDiaryT;
	editable?: boolean;
}
const DiaryCard = ({ diary, editable = true }: Props) => {
	return (
		<ui.Card className="">
			<ui.CardHeader>
				<div className="flex w-full flex-col px-2">
					<div className="flex justify-between">
						<div>
							<h4 className="text-lg font-normal text-slate-800">{diary.diary_name}</h4>
							<h5 className="text-sm font-medium text-slate-600">
								{diary.start_date ? dayjs(diary.start_date).format('DD MMM YYYY') : 'No Start Date'} -{' '}
								{diary.end_date ? dayjs(diary.end_date).format('DD MMM YYYY') : 'Now'}
							</h5>
						</div>
						{editable && (
							<cc.EditButton>
								<Link to={EditRoute.to} params={{ diaryId: diary.diary_id }}>
									Edit
								</Link>
							</cc.EditButton>
						)}
					</div>
				</div>
			</ui.CardHeader>
			{/* <p>
				<HelpCircle />
				<WhyTrackInjectionPain />
			</p> */}
		</ui.Card>
	);
};

export default DiaryCard;
