import PatientFlow from './PatientFlow';
import DoctorFlow from './DoctorFlow';
import Benefits from './Benefits';
import ProductTitle from './ProductTitle';

export const productElements = {
    ProductTitle,
    PatientFlow,
    DoctorFlow,
    Benefits,
};
