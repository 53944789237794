import { ui } from '@/components/ui';
import type { VerifyT } from '@/types/zod/common';
import { Mail, MousePointerClick } from 'lucide-react';
import { useState } from 'react';
import Call from '../../../components/common/Call';
import Or from '../../../components/common/Or';
import OtpForm from './OtpForm';
type MagicOrOtp = 'magic' | 'otp';
interface Props {
	email: string;
	resendFn: () => void;
	submitFn: (data: VerifyT) => void;
}
const MagicOrOtp = (props: Props) => {
	const [magicOrOtp, setMagicOrOtp] = useState<MagicOrOtp>('magic');

	return (
		<>
			{magicOrOtp === 'magic' ? (
				<>
					<Call
						words={`We sent an email to ${props.email}, please click the button in the message to confirm your account.`}
						icon={<MousePointerClick />}
					/>
					<ui.Button className="mt-4" size="lg" variant="link" onClick={props.resendFn}>
						Resend
					</ui.Button>
					<Or className="mt-2 mb-4" />
					<ui.Button onClick={() => setMagicOrOtp('otp')} variant="secondary">
						Use One Time Code
					</ui.Button>
				</>
			) : (
				<>
					<Call words="Verify you Email" icon={<Mail size={32} />}>
						{`Please enter the code sent to ${props.email}.\n\nDon't forget to check your junk!`}
					</Call>
					<br />
					<OtpForm resendFn={props.resendFn} submitFn={props.submitFn} />
					<Or className="mt-2 mb-4" />
					<ui.Button onClick={() => setMagicOrOtp('magic')} variant="secondary">
						Use Authentication Link
					</ui.Button>
				</>
			)}
		</>
	);
};

export default MagicOrOtp;
