import type { RoleT } from '../types/zod/auth';

interface Role {
	value: RoleT;
	label: string;
}

const roleOptions: Role[] = [
	{ value: 'PATIENT', label: 'Patient' },
	{ value: 'CLINICIAN', label: 'Clinician' },
	{ value: 'SECRETARY', label: 'Secretary' },
];

export { roleOptions };
