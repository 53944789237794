import { ui } from '@/components/ui';
import InjectionFormTabs from '@/features/injection/components/InjectionFormTabs';
import { services as s } from '@/services';
import { ArrowRight, Clock } from 'lucide-react';
import React from 'react';
import Call from '../../../components/common/Call';
import Subtitle from '../../../components/common/Subtitle';
import SurgeryFormTabs from '../features/surgeries/components/SurgeryFormTabs';
const FormTypes = {
	SURGERY: 'SURGERY',
	INJECTION: 'INJECTION',
	MEDICATION: 'MEDICATION',
};

interface Props {
	diaryId: string;
	organisationId: string;
	eventId: string;
	trackerId?: string;
	initialForm?: keyof typeof FormTypes;
	// Add any additional props here if needed
}

const SelectEventForm: React.FC<Props> = (props) => {
	const [currentForm, setCurrentForm] = React.useState<Props['initialForm']>(props.initialForm);

	React.useEffect(() => {
		setCurrentForm(props.initialForm);
	}, [props.initialForm]);

	switch (currentForm) {
		case FormTypes.SURGERY:
			return (
				<SurgeryFormTabs
					trackerId={props.trackerId}
					diaryId={props.diaryId}
					eventId={props.eventId}
					surgeryId={s.uuidv4()}
					organisationId={props.organisationId}
				/>
			);
		case FormTypes.INJECTION:
			return (
				<InjectionFormTabs
					trackerId={props.trackerId}
					diaryId={props.diaryId}
					eventId={props.eventId}
					injectionId={s.uuidv4()}
					organisationId={props.organisationId}
				/>
			);
		case FormTypes.MEDICATION:
			return (
				<div className="my-8">
					<Call variant="info" icon={<Clock />} words="Medications Coming Soon" />
				</div>
			);
		default:
			return (
				<div className="flex flex-col  gap-4 p-4">
					<Subtitle words="Select Intervention" />
					{[
						{ label: 'Surgery', value: FormTypes.SURGERY },
						{ label: 'Injection', value: FormTypes.INJECTION },
						{ label: 'Medication', value: FormTypes.MEDICATION },
					].map((option) => (
						<ui.Button
							key={option.label}
							className="w-full flex flex-row items-center justify-between"
							size="md"
							variant="outline"
							type="button"
							onClick={() => setCurrentForm(option.value as keyof typeof FormTypes)}
						>
							<span>{option.label}</span>
							<ArrowRight />
						</ui.Button>
					))}
				</div>
			);
	}
};

export default SelectEventForm;
