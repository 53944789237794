import type { ActionT, EntryViewT, StatefulDiaryT } from '../../../types/database/diaries';
import type { EventViewT, ExtendedEventViewT } from '../../../types/database/events';
import type { ExtendedTrackerViewT } from '../../../types/database/trackers';
import { combineTrackerEntries } from './flattenEntriesView';

type ActionEventT = ActionT | EntryViewT | ExtendedEventViewT;
type LogT = ActionEventT & { date: string; log_id: string; type: string };
export function collateDiaryHistory(diary: StatefulDiaryT) {
	// Flatten the diary into a single array

	const calculateEventDate = (event: EventViewT, trackers: ExtendedTrackerViewT[]) => {
		if (event.trackers?.length === 1) {
			const trackerId = event.trackers[0].tracker_id;
			return trackers.find((t) => t.tracker_id === trackerId)?.reference_date;
		}
	};

	const actions: LogT[] = diary.actions_view?.length
		? diary.actions_view.map((action: ActionT) => ({
				...action,
				type: 'action',
				log_id: action.action_id,
				date: action.action_time,
			}))
		: [];
	const entries = combineTrackerEntries(diary)?.length
		? combineTrackerEntries(diary).map((item: EntryViewT) => ({
				...item,
				type: 'entry',
				log_id: item.entry_id,
				date: item.calculated_entry_time,
			}))
		: [];

	const events = diary.events_view?.length
		? diary.events_view.map((event: ExtendedEventViewT) => ({
				...event,
				type: 'event',
				log_id: event.event_id,
				date: calculateEventDate(event, diary.trackers_view),
			}))
		: [];
	const flattenedDiary = [...actions, ...entries, ...events];

	// Sort the array
	const sortedDiary = flattenedDiary.sort((a, b) => {
		const aDate = new Date(a.date);
		const bDate = new Date(b.date);
		return aDate - bDate;
	});

	return sortedDiary;
}
