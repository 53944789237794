import { ui } from '@/components/ui';
import { inputs } from '@/definitions/inputs';
import { services as s } from '@/services';
import { zodResolver } from '@hookform/resolvers/zod';
import { Link, useNavigate } from '@tanstack/react-router';
import { useState } from 'react';
import { type SubmitHandler, useForm } from 'react-hook-form';

import { type DiaryInputT, DiaryInputZ } from '../../builder/types/diaryInputSchema';
import { useInsertDiaryInputMutation } from '../api/insertDiaryInput';
import { useInsertInputMutation } from '../api/insertInput';

interface Props {
	diaryTemplateId: string;
	page_index: number;
	order_index: number;
	page_id: string;
}
const InputsTitle = (props: Props) => {
	const [newInput, setNewInput] = useState<InputT>(null);
	const navigate = useNavigate();
	const form = useForm<DiaryInputT>({
		resolver: zodResolver(DiaryInputZ),
		defaultValues: {
			diary_id: props.diaryTemplateId,
			label: '',
			order_index: 0,
			page_index: 0,
			page_id: props.page_id,
		},
	});

	const insertInput = useInsertInputMutation();
	const insertDiaryInput = useInsertDiaryInputMutation();

	const onSubmit: SubmitHandler<DiaryInputT> = async (formData) => {
		const newUuid = s.uuidv4();
		const data = { ...formData, input_id: newUuid };
		await insertInput.mutate(newInput);
		await insertDiaryInput.mutate(data);

		navigate({
			to: '/templates/diary-template/$diaryTemplateId/input/$inputId',
			params: (prev) => ({ ...prev, inputId: newUuid }),
		});
	};

	return (
		<ui.Dialog>
			<ui.DialogTrigger asChild>
				<ui.Button size="sm" variant="secondary">
					Add Input
				</ui.Button>
			</ui.DialogTrigger>
			<ui.DialogContent>
				<ui.Form {...form}>
					<form onSubmit={form.handleSubmit(onSubmit)} className="form">
						<ui.DialogHeader>
							<ui.DialogTitle>New Input</ui.DialogTitle>
							<ui.DialogDescription>Select an input template or add a custom input to your form.</ui.DialogDescription>
						</ui.DialogHeader>

						<ui.ScrollArea className="h-full max-h-[70vh]">
							<ul className="flex flex-wrap gap-y-8">
								{Object.entries(inputs).map(([groupKey, group]) => (
									<li className="flex w-full flex-col pr-4 md:w-1/2 lg:w-1/3" key={groupKey}>
										<h4 className="font-semibold capitalize text-slate-600">{groupKey}</h4>
										<ul className="mb-4 mt-2 flex flex-col gap-2">
											{Object.entries(group).map(([inputKey, input]) => (
												<li key={inputKey}>
													<Link
														to="/templates/diary-template/$diaryTemplateId/input/$inputId"
														params={{ diaryTemplateId: props.diaryId, inputId: inputKey }}
														search={{ input }}
														className="my-1 flex items-center justify-start gap-2"
													>
														<span className="w-fit rounded-sm bg-indigo-200 p-1 text-indigo-800">{input.icon}</span>
														{input.name}
													</Link>
												</li>
											))}
										</ul>
									</li>
								))}
							</ul>
							<ui.ScrollBar orientation="vertical" />
						</ui.ScrollArea>
						<ui.DialogFooter></ui.DialogFooter>
					</form>
				</ui.Form>
			</ui.DialogContent>
		</ui.Dialog>
	);
};

export default InputsTitle;
