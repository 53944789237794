import { useEffect } from 'react';
import { toast } from 'sonner';

const CameraPermissions = () => {
    useEffect(() => {
        try {
            navigator.permissions.query({ name: 'camera' as PermissionName }).then((res) => {
                if (res.state !== 'granted') {
                    toast.error('No Access to Camera');
                }
            });
        } catch (e) {
            console.error(e);
        }
    }, []);

    return <></>;
};
export default CameraPermissions;
