import CloseButton from './CloseButton';

interface Props {
    showModal: boolean;
    closeModal: () => void;
    children: React.ReactNode;
    backdropStyle?: string;
    wrapperStyle?: string;
    cardStyle?: string;
    hideClose?: boolean;
    noBlur?: boolean;
    fullScreen?: boolean;
}
const ModalWrap = ({
    showModal,
    children,
    backdropStyle,
    cardStyle,
    closeModal,
    wrapperStyle,
    hideClose,
    noBlur,
    fullScreen,
}: Props) => {
    return (
        <div
            tabIndex={-1}
            className={`${showModal ? 'block' : 'hidden'}
            md:inset-0 fixed top-0 left-0 right-0 bottom-0 z-[200] flex p-4 overflow-x-hidden overflow-y-auto 
            h-[calc(100vh-1px)] ${noBlur ? 'backdrop-blur-xs' : 'bg-slate-500 backdrop-blur-md bg-opacity-50'}
            ${backdropStyle}`}
        >
            <div
                className={`relative w-full max-h-full m-auto ${
                    fullScreen ? 'h-[85vh] w-[85vw] max-w-[85vw] max-h-[85vh]' : 'w-fit max-w-[600px]'
                } ${wrapperStyle}`}
            >
                <div className={`relative bg-white rounded-xl shadow } ${cardStyle}`}>
                    <CloseButton close={closeModal} modal={true} hide={hideClose} />
                    {children}
                </div>
            </div>
        </div>
    );
};

export default ModalWrap;
