import { supabaseClient } from '@/lib/supabase';
import type { ExtendedEventViewT } from '@/types/database/events';
import { queryOptions, useSuspenseQuery } from '@tanstack/react-query';

export async function selectEventById(id: string): Promise<ExtendedEventViewT> {
	const { data, error } = await supabaseClient
		.from('events_view')
		.select(
			`*,
			injections_view(*,
                injection_details_view(*)
            )`,
		)
		.eq('event_id', id)
		.single();
	if (error) throw error;
	return data;
}

export const event_key = (id: string) => ['event', { event_id: id }];

export const eventByIdQueryOptions = (eventId: string) => {
	return queryOptions({
		queryKey: event_key(eventId),
		queryFn: () => selectEventById(eventId),
	});
};

export function useEventQuery(eventId: string) {
	return useSuspenseQuery(eventByIdQueryOptions(eventId));
}
