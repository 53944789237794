import { formComponents as fc } from '@/components/form';
import useOrganisationsQuery from '@/features/auth/api/useOrganisationsQuery';

const OrganisationsComboBox = () => {
	const { data: organisations } = useOrganisationsQuery();

	return (
		<fc.ComboBox
			name="organisation_id"
			label="Organisation"
			options={organisations.map((o) => {
				return { value: o.organisation_id, label: o.organisation_name };
			})}
		/>
	);
};

export default OrganisationsComboBox;
