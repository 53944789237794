interface Props {
    bg?: string;
    fill?: string;
    className?: string;
}

const Cross = ({ bg, fill, className }: Props) => {
    return (
        <svg
            className={`w-full h-full ${className}`}
            width="31"
            height="31"
            viewBox="0 0 31 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="15.5" cy="15.5" r="15.5" fill={bg ? bg : 'rbga(0,0,0,0)'} />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.9039 23.565C21.2944 23.9555 21.9276 23.9555 22.3181 23.565L23.565 22.3181C23.9556 21.9275 23.9556 21.2944 23.565 20.9039L18.5901 15.9289L23.565 10.954C23.9555 10.5635 23.9555 9.93035 23.565 9.53983L22.3181 8.29291C21.9275 7.90239 21.2944 7.90239 20.9039 8.29291L15.929 13.2678L10.9541 8.29289C10.5636 7.90237 9.93039 7.90237 9.53987 8.29289L8.29295 9.53981C7.90242 9.93034 7.90242 10.5635 8.29295 10.954L13.2678 15.9289L8.29289 20.9039C7.90237 21.2944 7.90237 21.9276 8.29289 22.3181L9.53981 23.565C9.93034 23.9555 10.5635 23.9555 10.954 23.565L15.929 18.5901L20.9039 23.565Z"
                fill={fill ? fill : 'white'}
            />
        </svg>
    );
};

export default Cross;
