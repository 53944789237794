import { supabaseClient } from '@/lib/supabase';
import { useQuery } from '@tanstack/react-query';

import { useAuth } from '../../../utils/auth';

const selectFavouriteUsers = async (authId: string) => {
	const { data, error } = await supabaseClient
		.from('favourite_users')
		.select('*, profiles!favourite_profiles_favourited_user_fkey(*)')
		.eq('favourited_by', authId);
	if (error) throw error;
	return data;
};

export const createFavouriteUsersQueryKey = (authId: string) => ['favourite_users', { favourited_by: authId }];

export const useSelectFavouriteUsers = () => {
	const auth = useAuth();
	return useQuery({
		queryKey: createFavouriteUsersQueryKey(auth.id),
		queryFn: () => selectFavouriteUsers(auth.id),
	});
};
