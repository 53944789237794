import { supabaseClient } from '@/lib/supabase';
import { queryOptions, useQuery } from '@tanstack/react-query';

export async function getSession() {
	const { data, error } = await supabaseClient.auth.getSession();
	if (error) throw error;
	return data.session;
}

export const sessionQueryOptions = () => {
	return queryOptions({
		queryKey: ['session'],
		queryFn: () => getSession(),
		retry: 3,
		staleTime: 3000,
	});
};

function useSessionQuery() {
	return useQuery(sessionQueryOptions());
}

export default useSessionQuery;
