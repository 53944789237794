import React from 'react';

const Patient = () => {
    return (
        <svg width="691" height="708" viewBox="0 0 691 708" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M345.501 294.467C422.742 294.467 483.853 233.998 483.853 161.233C483.853 157.283 483.673 153.37 483.32 149.5H469.723C422.485 149.5 375.597 142.906 330.395 130H303.999C278.738 130 253.512 128.114 228.532 124.358L213.253 122.06C209.282 134.479 207.149 147.642 207.149 161.233C207.149 233.998 268.26 294.467 345.501 294.467ZM218.794 107.725L230.762 109.524C248.687 112.22 266.742 113.923 284.845 114.628C277.708 111.835 270.626 108.879 263.607 105.759L228.496 90.1542C224.827 95.7494 221.579 101.621 218.794 107.725ZM237.647 77.8067L269.699 92.0523C332.67 120.039 400.813 134.5 469.723 134.5H481.052C478.255 121.331 473.432 108.832 466.899 97.3385C398.187 95.5937 330.547 79.7241 268.201 50.6966C256.639 58.2201 246.341 67.3778 237.647 77.8067ZM284.482 41.5957C302.822 32.9026 323.508 28 345.501 28C391.405 28 431.612 49.3566 456.68 81.9584C397.31 79.1531 338.941 65.4767 284.482 41.5957ZM345.501 322.467C437.375 322.467 511.853 250.28 511.853 161.233C511.853 72.1866 437.375 0 345.501 0C253.628 0 179.149 72.1866 179.149 161.233C179.149 250.28 253.628 322.467 345.501 322.467ZM577.591 633.279C637.47 604.249 663 569.512 663 540.033C663 510.555 637.47 475.818 577.591 446.788C519.642 418.694 437.62 400.533 345.5 400.533C253.38 400.533 171.358 418.694 113.409 446.788C53.5301 475.818 28 510.555 28 540.033C28 569.512 53.5301 604.249 113.409 633.279C171.358 661.372 253.38 679.533 345.5 679.533C437.62 679.533 519.642 661.372 577.591 633.279ZM345.5 707.533C536.314 707.533 691 632.541 691 540.033C691 447.526 536.314 372.533 345.5 372.533C154.686 372.533 0 447.526 0 540.033C0 632.541 154.686 707.533 345.5 707.533Z"
                fill="black"
            />
        </svg>
    );
};

export default Patient;
