import { Link, useRouteContext } from '@tanstack/react-router';

import { commonComponents as cc } from '@/components/common';
import { ui } from '@/components/ui';
import { useLogoutMutation } from '@/features/auth/api/useLogoutMutation';
import Navigation from '@/features/navigation/components/Navigation';
import Compass from '../../assets/openmoji/Compass';
import { useAuth } from '../../utils/auth';

const NotFoundPage = () => {
	const auth = useAuth();
	const logout = useLogoutMutation();
	return (
		<section className="">
			<Navigation app={false} />
			<br className="h-8" />
			<cc.Title words="Whoops, this page doesn't exist" />
			<Compass className="page-icon" />
			<div className="max-w-md mx-auto flex w-full flex-col items-center justify-center gap-4 p-4 text-center">
				<p className="text-md text-slate-800">
					Please let us know what happened at{' '}
					<a className="text-indigo-600" href="mailto:support@patient-watch.com">
						support@patient-watch.com
					</a>
				</p>
				{auth.isAuthenticated ? (
					<ui.Button asChild className="">
						<Link to="/profile">Profile</Link>
					</ui.Button>
				) : (
					<ui.Button asChild>
						<Link onClick={() => logout.mutate()} to="/">
							Home
						</Link>
					</ui.Button>
				)}
			</div>
		</section>
	);
};

export default NotFoundPage;
