import { ui } from '@/components/ui';
import type { FormViewT } from '@/types/database/forms';
import { Book, Clipboard } from 'lucide-react';
import IconDetail from '../../../components/common/IconDetail';

interface Props {
	form: FormViewT;
	link: React.ReactNode;
	link2?: React.ReactNode;
}

const FormCard = ({ form, link, link2 }: Props) => {
	return (
		<ui.Card>
			<ui.CardHeader>
				<ui.CardTitle>{form.form_name}</ui.CardTitle>
				<ui.CardDescription>{form.form_description}</ui.CardDescription>
			</ui.CardHeader>
			<ui.CardContent>
				<IconDetail icon={<Book />} words={`Pages ${form.pages.length}`} />
				<IconDetail
					icon={<Clipboard />}
					words={`Questions ${form.pages.reduce((total, page) => total + page.inputs?.length, 0)}`}
				/>
			</ui.CardContent>
			<ui.CardFooter className="flex gap-2">
				<ui.Button size="sm" asChild>
					{link}
				</ui.Button>
				{link2 && (
					<ui.Button size="sm" variant="secondary" asChild>
						{link2}
					</ui.Button>
				)}
			</ui.CardFooter>
		</ui.Card>
	);
};

export default FormCard;
