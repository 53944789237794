import { diaryByIdQueryOptions } from '@/features/diary/api/getDiaryById';
import NewDiaryForm from '@/features/new-diary/features/NewDiaryForm';
import { UuidZ } from '@/types/zod/auth';
import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

const NewPainWatchDiary = () => {
	const { diaryId } = Route.useParams();
	const { diary } = Route.useLoaderData();
	const { patientId } = Route.useSearch();

	return <NewDiaryForm patientId={patientId} diaryId={diaryId} diary={diary} />;
};

const DiarySearchSchemaZ = z.object({
	patientId: UuidZ,
});

export type DiarySearchSchemaT = z.infer<typeof DiarySearchSchemaZ>;

export const Route = createFileRoute('/_app/diary/$diaryId/edit/details')({
	component: NewPainWatchDiary,
	validateSearch: (search) => DiarySearchSchemaZ.parse(search),

	loader: async ({ context, params }) => {
		const diary = await context?.queryClient.ensureQueryData(diaryByIdQueryOptions(params.diaryId));
		return { diary };
	},
	errorComponent: () => <p>Pain Watch Error</p>,
});
