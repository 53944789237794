import { commonComponents as cc } from '@/components/common';
import { Route as EditRoute } from '@/routes/_app/diary/$diaryId/edit';
import type { ExtendedDiaryViewT } from '@/types/database/diaries';
import { Link } from '@tanstack/react-router';

interface Props {
	diary: ExtendedDiaryViewT;
	editing: boolean;
}
const DiaryTitle = ({ diary, editing }: Props) => {
	return (
		<div className="flex justify-between items-center mb-4 w-full">
			<h4 className="pl-2 font-medium text-slate-800 text-xl dark:text-slate-200">
				{diary?.diary_name ?? 'Unnamed Diary'}
			</h4>
			<cc.EditButton asChild={true}>
				{editing ? (
					<Link to={'/diary/$diaryId/home/new'} params={{ diaryId: diary.diary_id }}>
						Done
					</Link>
				) : (
					<Link to={EditRoute.to} params={{ diaryId: diary.diary_id }}>
						Edit
					</Link>
				)}
			</cc.EditButton>
		</div>
	);
};

export default DiaryTitle;
