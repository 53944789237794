import { supabaseClient } from '@/lib/supabase';
import { queryOptions, useSuspenseQuery } from '@tanstack/react-query';

export async function selectQuickstarts() {
	const { data, error } = await supabaseClient.from('quickstarts_view').select('*');
	if (error) throw error;
	return data;
}

export const quickstart_query_key = ['quickstarts'];

export const quickstartsQueryOptions = () => {
	return queryOptions({
		queryKey: quickstart_query_key,
		queryFn: () => selectQuickstarts(),
	});
};

export function useQuickstartsQuery() {
	return useSuspenseQuery(quickstartsQueryOptions());
}
