import { findStateIcon } from '../../assets/states/StateIcon';
import { useStateDefinitionsQuery } from '../../features/dashboard/features/interface/api/selectStates';

export const useFindState = () => {
	const { data: states } = useStateDefinitionsQuery();

	const findState = (stateKey: string) => {
		try {
			const allStates = states.map((s) => {
				return { ...s, icon: findStateIcon(s.state_key) };
			});
			return allStates.find((s) => s.state_key === stateKey);
		} catch (e) {
			console.error(e);
		}
	};
	return { findState };
};

export default useStateDefinitionsQuery;
