const AppleShare = () => {
    return (
        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 18.543C10 15.9549 12.098 13.8569 14.686 13.8569H20.093C20.6903 13.8569 21.1744 14.3411 21.1744 14.9383C21.1744 15.5356 20.6903 16.0197 20.093 16.0197H14.686C13.2925 16.0197 12.1628 17.1494 12.1628 18.543V40.1709C12.1628 41.5644 13.2925 42.6941 14.686 42.6941H36.314C37.7075 42.6941 38.8372 41.5644 38.8372 40.1709V18.543C38.8372 17.1494 37.7075 16.0197 36.314 16.0197H30.907C30.3097 16.0197 29.8256 15.5356 29.8256 14.9383C29.8256 14.3411 30.3097 13.8569 30.907 13.8569H36.314C38.902 13.8569 41 15.9549 41 18.543V40.1709C41 42.7589 38.902 44.8569 36.314 44.8569H14.686C12.098 44.8569 10 42.7589 10 40.1709V18.543Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.717 5.31788C25.1494 4.89404 25.8504 4.89404 26.2828 5.31788L31.8185 10.7444C32.2509 11.1683 32.2509 11.8555 31.8185 12.2793C31.3861 12.7031 30.6851 12.7031 30.2528 12.2793L26.6071 8.70549V29.6004C26.6071 30.1998 26.1114 30.6857 25.4999 30.6857C24.8885 30.6857 24.3928 30.1998 24.3928 29.6004V8.70549L20.7471 12.2793C20.3147 12.7031 19.6137 12.7031 19.1813 12.2793C18.749 11.8555 18.749 11.1683 19.1813 10.7444L24.717 5.31788Z"
                fill="black"
            />
        </svg>
    );
};

export default AppleShare;
