import type { DiaryTemplateT } from '@/types/database/diaries';
import type { TrackerTemplateT } from '@/types/database/trackers';
import type { Auth } from '@/utils/auth';

export const hideTemplate = (auth: Auth, template: DiaryTemplateT | TrackerTemplateT) => {
	if (auth.roles?.isAdmin) return false;
	if (template.is_private || template.is_draft) {
		if (auth.id === template.author) return false;
		return true;
	}
	return false;
};
