import Description from '@/components/common/Description';
import { cn } from '@/lib/utils';
import type { ExtendedDiaryViewT } from '@/types/database/diaries';
import type { StatefulTrackerViewT } from '@/types/database/trackers';
import { BellRing } from 'lucide-react';
import EntryTimeLabel from '../EntryTimeLabel';
import NewEntryLink from '../NewEntryLink';

interface Props {
	tracker: StatefulTrackerViewT;
	diary: ExtendedDiaryViewT;
}

const EntryDue = ({ tracker, diary }: Props) => {
	return (
		<>
			<NewEntryLink is_locked={false} diaryId={diary.diary_id} trackerId={tracker.tracker_id as string}>
				<EntryTimeLabel label={tracker?.next_entry?.label as string} />
			</NewEntryLink>

			<div className={cn('animate-big-wiggle my-4')}>
				<BellRing size={60} strokeWidth={1.75} />
			</div>
			<Description>
				You have{' '}
				<i className="underline underline-offset-2">
					{tracker.due_entries?.length} due {tracker.due_entries?.length === 1 ? 'entry' : 'entries'}
				</i>
				. Please use the button below to submit your latest pain scores.
			</Description>
		</>
	);
};

export default EntryDue;
