import type { StatefulDiaryT } from '@/types/database/diaries';
import ReadButton from './ReadButton';
import UnreadButton from './UnreadButton';

interface Props {
	diary: StatefulDiaryT;
}

const MarkReadButtons = ({ diary }: Props) => {
	return <>{!diary.is_read ? <ReadButton diary={diary} /> : <></>}</>;
};

export default MarkReadButtons;
