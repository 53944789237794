import { usePatientsQuery } from '../api/selectPatients';
import { filterMapOptions } from '../utils/filterPatientsByOrganisation';

const usePatientsByOrganisation = () => {
	const { data: patients } = usePatientsQuery();
	const getPatientsByOrganisation = (organisationId?: string) => {
		return organisationId && patients ? filterMapOptions(patients, organisationId) : [];
	};
	return { patientsByOrganisation: getPatientsByOrganisation };
};

export default usePatientsByOrganisation;
