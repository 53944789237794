import HeroContent from '@/features/website/components/HeroContent';
import Section from '@/features/website/components/Section';
import { useState } from 'react';

import { exampleDiaries } from './content/exampleDiaries';
import ExampleEmail from './ExampleEmail';

const Actions = () => {
    const [action, setAction] = useState('BOOK_APPOINTMENT');

    return (
        <Section row={true}>
            <HeroContent
                caption="Manage Diaries"
                title="Take automated actions"
                description="Our automated actions allow patients to be managed efficiently. The actions include email templates which can be customised for personalised care."
            />

            <div className="w-full transition-all duration-200">
                <ExampleEmail diary={exampleDiaries[0]} action={action} setAction={setAction} />
            </div>
        </Section>
    );
};

export default Actions;
