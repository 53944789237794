import FeatureBullets from '@/features/website/components/FeatureBullets';
import SectionTitle from '@/features/website/components/SectionTitle';

import AnimateInView from '../../../components/ui/animate-in-view';
import { content } from './content/technicalContentShort';

import type { BulletColors } from '@/features/website/components/FeatureBullets';
const Technical = () => {
	const colors: BulletColors[] = ['indigo', 'pink', 'amber'];

	return (
		<>
			<SectionTitle
				title="Technical Expertise"
				description={<>We combine industry gold-standards with cutting edge technology to keep your data safe.</>}
			/>
			<div className="flex flex-col md:flex-row gap-y-8 md:gap-y-0 md:gap-x-4 m-0 !w-full items-center justify-center">
				{content.map((item, index) => {
					return (
						<div key={item.title} className="w-full md:w-[32%]">
							<AnimateInView duration={1000 * (index + 1)} delay={2000 * (index + 1)}>
								<FeatureBullets icon={item.icon} color={colors[index]} title={item.title} bullets={item.bullets} />
							</AnimateInView>
						</div>
					);
				})}
			</div>
		</>
	);
};

export default Technical;
