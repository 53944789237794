import Description from '@/components/common/Description';
import IconDetail from '@/components/common/IconDetail';
import { ui } from '@/components/ui';
import { useDiaryByIdQuery } from '@/features/diary/api/getDiaryById';
import TrackerButtons from '@/features/trackers/components/TrackerButtons';
import { Route as NewRoute } from '@/routes/_app/diary/$diaryId/home/new';
import type { StatefulTrackerViewT } from '@/types/database/trackers';
import { useAuth } from '@/utils/auth';
import { ErrorBoundary } from 'react-error-boundary';
import NewStateSwitch from './NewStateSwitch';

interface Props {
	tracker: StatefulTrackerViewT;
}
const NewCard = ({ tracker }: Props) => {
	const auth = useAuth();
	const { diaryId } = NewRoute.useParams();
	const { data: diary } = useDiaryByIdQuery(diaryId);
	return (
		<ui.Card className="flex flex-col justify-center items-center gap-y-4">
			<ui.CardHeader className="flex flex-col justify-center items-center gap-2 mx-auto px-8 w-full text-center">
				<ui.CardTitle>{tracker.tracker_name}</ui.CardTitle>
			</ui.CardHeader>
			<ui.CardContent className="flex flex-col justify-center items-center">
				<NewStateSwitch diary={diary} tracker={tracker} />
			</ui.CardContent>
			<ui.CardFooter className="flex flex-col gap-2 w-full">
				<TrackerButtons
					showEditButton={auth.isSuperUser}
					showNextEntryButton={tracker?.next_entry?.is_locked === false && !!tracker?.next_entry?.due_at}
					diaryid={diaryId}
					tracker={tracker}
				/>
			</ui.CardFooter>
		</ui.Card>
	);
};

export default NewCard;
