import { ui } from '@/components/ui';
import { sliderFace } from '@/features/diary/components/slider/sliderFace';
import ReactSlider from 'react-slider';

import type { InputT } from '../../types/database/diaries';

interface Props {
	input: InputT;
	label: string;
	value: number | null;
	setValue: (value: number) => void;
}
const Slider = (props: Props) => {
	return (
		<ui.Card className="flex flex-col items-center justify-center w-full gap-4 px-0 mx-auto">
			<ui.CardHeader className="flex items-center justify-center">
				<ui.Label className="text-2xl font-normal text-slate-800">{props.label}</ui.Label>
				<p className="text-sm font-normal text-slate-700">{props.input.description}</p>
			</ui.CardHeader>
			<ui.CardContent className="flex flex-col w-full mb-8 gap-y-16">
				<ui.Input
					min={props.input.min_value}
					max={props.input.max_value}
					onChange={(e) => props.setValue(Number(e.target.value))}
					value={Number(Math.round(props.value)).toString()}
					className="w-[70px] h-[70px] text-center p-auto m-auto text-4xl flex items-center justify-center [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
					type="number"
				/>
				<ReactSlider
					min={props.input.min_value}
					max={props.input.max_value}
					step={0.001}
					value={props.value}
					thumbClassName="w-12 h-12 leading-12 -mt-4 text-center z-10 flex items-center justify-center rounded-full cursor-grab"
					trackClassName="px-2 h-[15px] rounded-full bg-indigo-400"
					renderThumb={(props: any, state: State) => (
						<div {...props}>
							<div className="w-12 h-12 bg-white border-4 border-indigo-600 rounded-full"></div>
						</div>
					)}
					onChange={props.setValue}
					className="w-full"
				/>
			</ui.CardContent>
			<ui.CardFooter></ui.CardFooter>
		</ui.Card>
	);
};

export default Slider;
