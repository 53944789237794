import { useState, useEffect } from 'react';

const getLocalValue = (key: string, initValue: any) => {
    // if a value is already store
    try {
        const localValue = JSON.parse(localStorage.getItem(key)!);
        if (localValue) return localValue;

        // return result of a function
        if (initValue instanceof Function) return initValue();

        return initValue;
    } catch (e) {
        return;
    }
};

const useLocalStorage = (key: string, initValue: any) => {
    const [value, setValue] = useState(() => {
        return getLocalValue(key, initValue);
    });

    useEffect(() => {
        try {
            localStorage.setItem(key, JSON.stringify(value));
        } catch (e) {
            console.log(e);
        }
    }, [key, value]);

    return [value, setValue];
};

export default useLocalStorage;
