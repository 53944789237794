import { ExtendedDiaryInputT } from "@/types/database/diaries";

export const sortInputs = (
  diary_input_templates: ExtendedDiaryInputT[],
) => {
  const sortedByPage = diary_input_templates?.sort((a, b) => {
    return a.page_index - b.page_index;
  });
  const sortedByOrder = sortedByPage?.sort((a, b) => {
    return a.order_index - b.order_index;
  });
  return sortedByOrder;
};
