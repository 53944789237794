import { supabaseClient } from '@/lib/supabase';
import type { StatefulDiaryT } from '@/types/database/diaries';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'sonner';

type TriggerEntryRemindersT = { patient_id: string; tracker_id: string; next_entry: StatefulDiaryT['next_entry'] };
const triggerEntryReminders = async (formData: TriggerEntryRemindersT) => {
	const { data, error } = await supabaseClient.functions.invoke('admin-trigger-entry-reminders', {
		body: formData,
	});
	if (error) throw error;
	return data;
};
export const useTriggerEntryReminders = () => {
	return useMutation({
		mutationFn: (formData: TriggerEntryRemindersT) => triggerEntryReminders(formData),
		onSuccess: () => {
			toast.info('Entry Reminders Triggered for Tracker');
		},
		onError: (error) => {
			toast.error(error.message);
		},
	});
};
