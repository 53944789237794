import { useAuth } from '@/utils/auth';
import { useNavigate } from '@tanstack/react-router';
import { useEffect } from 'react';
import { toast } from 'sonner';

const useSkipOrganisation = () => {
	const auth = useAuth();
	const navigate = useNavigate();

	useEffect(() => {
		if (auth?.roles?.hasRole) {
			console.log('Organisation already added with role', JSON.stringify(auth.roles));
			toast.info('Organisation already added');
			navigate({ to: '/onboarding/name' });
		}
	}, [auth, navigate]);
};

export default useSkipOrganisation;
