import type { StatefulDiaryT } from '../../../types/database/diaries';
import { useDiaryByIdQuery } from '../../diary/api/getDiaryById';

export const extractEntryValues = (diary: StatefulDiaryT) => {
	if (!diary?.trackers_view) return;
	const entryValues = diary.trackers_view?.flatMap((tv) =>
		tv.entries_view?.flatMap((e) =>
			e.entry_values?.flatMap((ev) => ({ calculated_entry_time: e.calculated_entry_time, ...ev })),
		),
	);
	return entryValues;
};

const useExtractEntryValues = (diaryId: string) => {
	const { data: diary } = useDiaryByIdQuery(diaryId);
	return extractEntryValues(diary);
};

export default useExtractEntryValues;
