import { commonComponents as cc } from '@/components/common';
import { ui } from '@/components/ui';
import { cn } from '@/lib/utils';

import { CatchBoundary } from '@tanstack/react-router';
import { icons } from '../../assets/icons';

interface Props {
	title?:  React.ReactNode;
	children: React.ReactNode;
	lock?: boolean;
	editChildren?: React.ReactNode;
	className?: string;
	show?: boolean;
	contentClassName?: string;
}
const Group = ({ title, children, lock, className, show, editChildren, contentClassName }: Props) => {
	return (
		<section className={cn('mb-4', lock && 'cursor-not-allowed', show === false && 'hidden', className)}>
			<CatchBoundary getResetKey={() => 'reset-group'} errorComponent={GroupFallack}>
				<ui.Card className={cn(lock && 'pointer-events-none ')}>
					{!!title && (
						<ui.CardHeader className="flex flex-row justify-between items-center pb-0 w-full">
							{title && <ui.CardTitle className="text-xl">{title}</ui.CardTitle>}
							{/* {title && <h3 className="flex w-full font-semibold text-lg text-slate-700">{title}</h3>} */}
							{lock && <icons.Padlock className="mt-2 mr-1" />}
							{!lock && editChildren && <cc.EditButton>{editChildren}</cc.EditButton>}
						</ui.CardHeader>
					)}

					<ui.CardContent className={cn('py-2', lock && 'opacity-50', contentClassName)}>{children}</ui.CardContent>
				</ui.Card>
			</CatchBoundary>
		</section>
	);
};

export default Group;

const GroupFallack = () => {
	return (
		<ui.Card>
			<ui.CardHeader>
				<ui.CardTitle>Error in Group</ui.CardTitle>
			</ui.CardHeader>
			<ui.CardContent>
				<ui.Skeleton />
				<ui.Skeleton className="w-1/2" />
			</ui.CardContent>
		</ui.Card>
	);
};
