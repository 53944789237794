interface Props {
    fill?: string;
}
const BlueRoundTick = ({ fill }: Props) => {
    return (
        <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="16.5" cy="16.5" r="16.5" fill={fill ? fill : 'rgb(79, 70, 229)'} />
            <rect
                width="7.89141"
                height="3.50729"
                rx="1"
                transform="matrix(0.704951 0.709256 -0.704951 0.709256 9.47241 16.9154)"
                fill="white"
            />
            <rect
                width="18.4848"
                height="3.50729"
                rx="1"
                transform="matrix(-0.704951 0.709256 -0.704951 -0.709256 26 11.4875)"
                fill="white"
            />
        </svg>
    );
};

export default BlueRoundTick;
