import { defaultLineSettings } from '@/features/charts/services/generateData';
import type { ClinicianStatisticT} from '@/types/database/profiles';
import type { ChartDataset } from 'chart.js';
import {  Line } from 'react-chartjs-2';
import { generateOptions } from '@/features/charts/services/generateOptions';

const CreatedDiairesSparkChart = ({
	cliniciansStats,
}: {
	cliniciansStats: ClinicianStatisticT[];
}) => {
	const data = cliniciansStats.map((stat) => ({
		x: stat.created_at,
		y: stat.created_diaries,
	}));

	const datasets = {
		datasets: [
			{
				label: 'Recent Diaries',
				data: data,
				...defaultLineSettings,
				pointRadius: 4,
				pointBorderWidth: 2,
				tension: 0.4,
				borderWidth: 3,
			},
		],
	};
	const createdDiaries = cliniciansStats.map((cs) => cs.created_diaries);
	// Find how many diaries created by the clinician
	const max = Math.max(...createdDiaries);
	// Roundup to nearest 10
	const maxY = Math.ceil(max / 10) * 10;

	const penultimate = createdDiaries[createdDiaries.length - 2];
	const increase = penultimate ? max - penultimate : undefined;
	const percentage = penultimate ? Math.round((increase / penultimate) * 100) : undefined;

	const options = generateOptions({
		datasets: datasets as ChartDataset,
		x: {
			display: false,
		},
		y: { min: 0, max: maxY, display: false },
		legend: {
			display: false,
		},
	});

	return (
		<div className="flex flex-col justify-between items-between w-full h-40">
			<div>
				<b className="font-bold text-2xl">{max} Diaries</b>
				<div className="flex justify-between">
					<span className="text-sm">+{increase} Diaries</span>
					<span className="text-sm">+{percentage}%</span>
				</div>
			</div>
			<div className="flex w-full h-24">
				<Line className="h-10" options={options} data={datasets} />
			</div>
		</div>
	);
};

export default CreatedDiairesSparkChart;