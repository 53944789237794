import WaveHand from '@/assets/openmoji/WaveHand';
import { commonComponents as cc } from '@/components/common';
import { ui } from '@/components/ui';
import { selectFormByIdQueryOptions } from '@/features/forms/api/selectFormById';
import { useSelectFormImageById } from '@/features/forms/api/selectFormImageById';
import { services as s } from '@/services';
import { Link, createFileRoute } from '@tanstack/react-router';

const Page = () => {
	const { trackerId, formId } = Route.useParams();
	const { form } = Route.useLoaderData();
	const { data: image } = useSelectFormImageById(form.image_id);
	return (
		<section className="md-width flex flex-col gap-4">
			<cc.Title words={form?.form_name ?? undefined} description={form?.form_description ?? undefined}></cc.Title>
			{image?.publicUrl ? (
				<img className="mx-auto my-4 flex max-w-sm px-4" src={image?.publicUrl} alt="Form Image" />
			) : (
				<WaveHand className="mx-auto flex h-32 w-32" />
			)}
			<ui.Button size="lg" asChild>
				<Link
					className="my-4"
					to="/tracker/$trackerId/form/$formId/entry/$entryId/page/1"
					params={{ trackerId: trackerId, formId: formId, entryId: s.uuidv4() }}
				>
					Start Now
				</Link>
			</ui.Button>
		</section>
	);
};

export const Route = createFileRoute('/tracker/$trackerId/form/$formId/')({
	component: Page,
	loader: async ({ context, params }) => {
		const form = await context?.queryClient.ensureQueryData(selectFormByIdQueryOptions(params.formId));
		return { form };
	},
});
