import React from 'react';

function DiaryIcon() {
    return (
        <svg width="36" height="42" viewBox="0 0 36 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="36" height="42" fill="white" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M33.4872 3.75H5.19141C5.05334 3.75 4.94141 3.86193 4.94141 4V39.3166C4.94141 39.4547 5.05334 39.5666 5.19141 39.5666H33.4872C33.6252 39.5666 33.7372 39.4547 33.7372 39.3166V4C33.7372 3.86193 33.6252 3.75 33.4872 3.75ZM5.19141 2C4.08684 2 3.19141 2.89543 3.19141 4V39.3166C3.19141 40.4212 4.08684 41.3166 5.19141 41.3166H33.4872C34.5917 41.3166 35.4872 40.4212 35.4872 39.3166V4C35.4872 2.89543 34.5917 2 33.4872 2H5.19141Z"
                fill="black"
            />
            <path
                d="M0 8.21249C0 7.10793 0.89543 6.21249 2 6.21249H7.19089C8.29546 6.21249 9.19089 7.10792 9.19089 8.21249V8.80794C9.19089 9.91251 8.29546 10.8079 7.19089 10.8079H2C0.895431 10.8079 0 9.91251 0 8.80794V8.21249Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.19089 7.21249H2C1.44772 7.21249 1 7.66021 1 8.21249V8.80794C1 9.36022 1.44772 9.80794 2 9.80794H7.19089C7.74317 9.80794 8.19089 9.36022 8.19089 8.80794V8.21249C8.19089 7.66021 7.74317 7.21249 7.19089 7.21249ZM2 6.21249C0.89543 6.21249 0 7.10793 0 8.21249V8.80794C0 9.91251 0.895431 10.8079 2 10.8079H7.19089C8.29546 10.8079 9.19089 9.91251 9.19089 8.80794V8.21249C9.19089 7.10792 8.29546 6.21249 7.19089 6.21249H2Z"
                fill="black"
            />
            <path
                d="M0 16.6375C0 15.5329 0.89543 14.6375 2 14.6375H7.19089C8.29546 14.6375 9.19089 15.5329 9.19089 16.6375V17.2329C9.19089 18.3375 8.29546 19.2329 7.19089 19.2329H2C0.895431 19.2329 0 18.3375 0 17.2329V16.6375Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.19089 15.6375H2C1.44772 15.6375 1 16.0852 1 16.6375V17.2329C1 17.7852 1.44772 18.2329 2 18.2329H7.19089C7.74317 18.2329 8.19089 17.7852 8.19089 17.2329V16.6375C8.19089 16.0852 7.74317 15.6375 7.19089 15.6375ZM2 14.6375C0.89543 14.6375 0 15.5329 0 16.6375V17.2329C0 18.3375 0.895431 19.2329 2 19.2329H7.19089C8.29546 19.2329 9.19089 18.3375 9.19089 17.2329V16.6375C9.19089 15.5329 8.29546 14.6375 7.19089 14.6375H2Z"
                fill="black"
            />
            <path
                d="M0 25.0625C0 23.9579 0.89543 23.0625 2 23.0625H7.19089C8.29546 23.0625 9.19089 23.9579 9.19089 25.0625V25.6579C9.19089 26.7625 8.29546 27.6579 7.19089 27.6579H2C0.895431 27.6579 0 26.7625 0 25.6579V25.0625Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.19089 24.0625H2C1.44772 24.0625 1 24.5102 1 25.0625V25.6579C1 26.2102 1.44772 26.6579 2 26.6579H7.19089C7.74317 26.6579 8.19089 26.2102 8.19089 25.6579V25.0625C8.19089 24.5102 7.74317 24.0625 7.19089 24.0625ZM2 23.0625C0.89543 23.0625 0 23.9579 0 25.0625V25.6579C0 26.7625 0.895431 27.6579 2 27.6579H7.19089C8.29546 27.6579 9.19089 26.7625 9.19089 25.6579V25.0625C9.19089 23.9579 8.29546 23.0625 7.19089 23.0625H2Z"
                fill="black"
            />
            <path
                d="M0 33.4875C0 32.3829 0.89543 31.4875 2 31.4875H7.19089C8.29546 31.4875 9.19089 32.3829 9.19089 33.4875V34.0829C9.19089 35.1875 8.29546 36.0829 7.19089 36.0829H2C0.895431 36.0829 0 35.1875 0 34.0829V33.4875Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.19089 32.4875H2C1.44772 32.4875 1 32.9352 1 33.4875V34.0829C1 34.6352 1.44772 35.0829 2 35.0829H7.19089C7.74317 35.0829 8.19089 34.6352 8.19089 34.0829V33.4875C8.19089 32.9352 7.74317 32.4875 7.19089 32.4875ZM2 31.4875C0.89543 31.4875 0 32.3829 0 33.4875V34.0829C0 35.1875 0.895431 36.0829 2 36.0829H7.19089C8.29546 36.0829 9.19089 35.1875 9.19089 34.0829V33.4875C9.19089 32.3829 8.29546 31.4875 7.19089 31.4875H2Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.8203 15.8095V2.70209H22.0703V15.5759L25.0069 13.6182C25.4145 13.3464 25.9512 13.3697 26.3337 13.6757L28.5432 15.4433V2.70209H29.7932V15.7034C29.7932 16.6467 28.702 17.1712 27.9654 16.5819L25.6242 14.7089L22.5694 16.7455C21.8217 17.2439 20.8203 16.708 20.8203 15.8095Z"
                fill="black"
            />
        </svg>
    );
}

export default DiaryIcon;
