import { inputs as i } from '@/components/input';
import { Route as FootPhotos } from '@/routes/_app/diary/$diaryId/new/tracker/$trackerId/entry/$entryId/page/$pageIndex/foot-photos/start';
import { services as s } from '@/services';
import type { InputT } from '@/types/database/forms';
import { Navigate, useParams } from '@tanstack/react-router';
import React from 'react';

import { formComponents as fc } from '@/components/form';
import YesNo from '@/components/form/YesNo';
import { ui } from '@/components/ui';
import { useEntryValuesStore } from '../hooks/useEntryValuesStore';

interface Props {
	is_preview?: boolean;
	input: InputT;
	form: any;
}

const InputSwitch = ({ input, is_preview, form }: Props) => {
	const { entryId, entryValueId } = useParams({ strict: false }) as { entryId: string; entryValueId: string };
	const entryValue = useEntryValuesStore((state) => state.getEntryValueByInputId(input.input_id));
	const upsertEntryValue = useEntryValuesStore((state) => state.upsertEntryValue);

	React.useEffect(() => {
		upsertEntryValue({
			...entryValue,
			entry_id: entryId,
			input_id: input.input_id,
			entry_value_id: entryValueId ?? s.uuidv4(),
			time_unit: input.input_key === 'duration' ? 'hour' : undefined,
		});
	}, []);

	switch (input.input_key) {
		case 'long-text':
			return (
				<fc.Textarea
					name={input.input_id}
					maxLength={input.max_length ?? undefined}
					label={input.input_label ?? undefined}
					description={input.description}
				/>
			);
		case 'number':
			return (
				<i.Number
					value={entryValue?.number_value ?? ''}
					setValue={(val: string) =>
						upsertEntryValue({
							...entryValue,
							input_id: input.input_id,
							number_value: val,
						})
					}
					label={input.input_label ?? undefined}
					description={input.description}
				/>
			);
		case 'yes-no':
			return (
				<ui.Card>
					<ui.CardContent className="pt-4">
						<YesNo control={form.control} name={input.input_id} label={input.input_label} />
					</ui.CardContent>
				</ui.Card>
			);
		case 'dropdown':
			return (
				<fc.Select
					options={input.options?.map((o) => ({ label: o, value: o })) ?? []}
					name={input.input_id}
					label={input.input_label ?? 'Select'}
				/>
			);
		case 'duration':
			return <i.Duration form={form} input={input} />;
		case 'email':
			return <fc.Email name={input.input_id} />;
		case 'slider':
			return (
				<i.Slider
					setValue={(val: number) =>
						upsertEntryValue({
							...entryValue,
							input_id: input.input_id,
							number_value: val,
						})
					}
					value={entryValue?.number_value ?? 0}
					input={input}
					label={input.input_label}
				/>
			);
		case 'single-choice':
			return <fc.SingleChoice input={input} />;
		case 'scale-rating':
			return (
				<ui.Card>
					<ui.CardContent className="pt-4">
						<fc.Rating input={input} name={input.input_id} label={input.input_label} />
					</ui.CardContent>
				</ui.Card>
			);
		case 'star':
			return (
				<ui.Card>
					<ui.CardContent className="pt-4">
						<fc.Star input={input} name={input.input_id} label={input.input_label} />
					</ui.CardContent>
				</ui.Card>
			);
		case 'multiple-choice':
			return <i.MultipleChoice input={input} />;
		case 'photo':
			return <i.Photo input={input} />;
		case 'foot-photos':
			return is_preview ? (
				<p>No Preview Available</p>
			) : (
				Navigate({ to: FootPhotos.to, params: (prev) => ({ ...prev }) })
			);

		// Custom Inputs
		case 'pain-slider':
			return <i.ci.PainSlider name={input.input_id} label={input.input_label ?? undefined} />;
		case 'glucose-slider':
			return <i.ci.GlucoseSlider name={input.input_id} label={input.input_label ?? undefined} />;
		case 'visa-a-8':
			return <i.ci.VisaA8 name={input.input_id} />;
	}
};

export default InputSwitch;
