import { formComponents as fc } from '@/components/form';
import { ui } from '@/components/ui';
import { roleOptions } from '@/definitions/roles';
import { useAddOrganisationMutation } from '@/features/auth/api/addOrganisationMutation';
import { RoleZ } from '@/types/zod/auth';
import { useAuth } from '@/utils/auth';
import { zodResolver } from '@hookform/resolvers/zod';
import { Link } from '@tanstack/react-router';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

const newOrganisationFormSchema = z.object({
	user_id: z.string().uuid(),
	organisation_id: z.string().uuid(),
	organisation_code: z.string().min(1, 'Code is required'),
	role: RoleZ,
});

type FormSchemaType = z.infer<typeof newOrganisationFormSchema>;

interface Props {
	isOnboarding?: boolean;
}
const AddOrganisationForm = ({ isOnboarding }: Props) => {
	const auth = useAuth();
	// const { roles } = useRouteContext({ strict: false });
	const form = useForm<FormSchemaType>({
		resolver: zodResolver(newOrganisationFormSchema),
		defaultValues: {
			user_id: auth.id,
			// role: roles.isClinician ? 'clinician' : 'patient',
		},
	});

	const orgId = form.watch('organisation_id');

	useEffect(() => {
		if (orgId === '9737937c-ea5a-4f0a-8d9f-0e5e7f1b9e57') {
			form.setValue('organisation_code', 'NOORG');
			// form.setValue('organisation_id', '9737937c-ea5a-4f0a-8d9f-0e5e7f1b9e57');
		} else {
			form.setValue('organisation_code', '');
		}
	}, [orgId, form.setValue]);

	const addOrganisation = useAddOrganisationMutation(isOnboarding);

	const onSubmit = async (formData: FormSchemaType) => {
		addOrganisation.mutate({ ...formData, organisation_code: formData.organisation_code.toUpperCase() });
	};

	return (
		<ui.Form {...form}>
			<form className="flex flex-col space-y-6 px-2 w-full" onSubmit={form.handleSubmit(onSubmit)}>
				<fc.Select autoFocus={true} name="role" label="Role" options={roleOptions} />

				<fc.OrganisationsComboBox />

				{orgId !== '9737937c-ea5a-4f0a-8d9f-0e5e7f1b9e57' && (
					<ui.FormField
						control={form.control}
						name="organisation_code"
						data-cy="organisation_code"
						render={({ field }) => (
							<ui.FormItem>
								<ui.FormLabel>Organisation Code</ui.FormLabel>
								<ui.FormControl>
									<ui.Input type="text" placeholder="" {...field} />
								</ui.FormControl>
								<ui.FormMessage />
								<ui.FormDescription>
									To join an organisation, you should use the code they provided to you. If you don't have one,{' '}
									<Link
										tabIndex={-1}
										className="text-indigo-600 underline underline-offset-2"
										to="/get-started"
										target="_blank"
									>
										request a code
									</Link>
									.
								</ui.FormDescription>
							</ui.FormItem>
						)}
					/>
				)}
				<ui.Button size="lg" type="submit" disabled={addOrganisation.isPending}>
					Next
				</ui.Button>
			</form>
		</ui.Form>
	);
};

export default AddOrganisationForm;
