import { InputOTP, InputOTPGroup, InputOTPSeparator, InputOTPSlot } from '@/components/ui/input-otp';
import type React from 'react';
import { useState } from 'react';

import { commonComponents as cc } from '../../../components/common';
import ModalWrap from '../../../components/modal/ModalWrap';
import { ui } from '../../../components/ui';
import useVerifyOtp from '../api/useVerifyOtp';

interface Props {
	codeRef: React.RefObject<HTMLInputElement>;
	showModal: boolean;
	setShowModal: (val: boolean) => void;
	resendCode: () => void;
	type: 'email' | 'sms';
	phoneOrEmail: string;
	onSuccess: () => void;
}

const VerifyOtpModal = ({ showModal, setShowModal, codeRef, resendCode, type, phoneOrEmail, onSuccess }: Props) => {
	const verifyCode = useVerifyOtp();
	const [code, setCode] = useState('');

	const handleSubmit = () => {
		if (type === 'sms') {
			verifyCode.mutate({ phone: phoneOrEmail, token: code, type: 'sms' }, { onSuccess: onSuccess });
		}
		if (type === 'email') {
			verifyCode.mutate({ email: phoneOrEmail, token: code, type: 'email' }, { onSuccess: onSuccess });
		}
	};

	return (
		<ModalWrap cardStyle="p-4 lg:p-8" showModal={showModal} closeModal={() => setShowModal(false)}>
			<cc.Title words="Enter Confirmation Code" />

			<p className="mb-6 w-full px-8 text-center text-xl">
				To confirm your account please use the code sent to your {type === 'email' ? 'email address' : 'phone number'}.
			</p>

			<InputOTP maxLength={6} value={code} onChange={(value) => setCode(value)} tabIndex={0}>
				<InputOTPGroup>
					<InputOTPSlot index={0} />
					<InputOTPSlot index={1} />
					<InputOTPSlot index={2} />
					<InputOTPSlot index={3} />
					<InputOTPSlot index={4} />
					<InputOTPSlot index={5} />
				</InputOTPGroup>
			</InputOTP>
			<div className="my-8 flex flex-col gap-y-4">
				<ui.Button size="lg" variant="outline" onClick={resendCode}>
					Resend Code
				</ui.Button>
				<ui.Button size="lg" type="submit" onClick={handleSubmit}>
					Next
				</ui.Button>
			</div>
			<div className="h-[1.5px] w-full bg-slate-300" />
			<p className="text-md my-4">
				For assistance, contact{' '}
				<a className="text-indigo-600 underline  underline-offset-4" href="mailto:support@patient-watch.com">
					support@patient-watch.com
				</a>
				.
			</p>
		</ModalWrap>
	);
};

export default VerifyOtpModal;
