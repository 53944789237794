import { createClient } from '@supabase/supabase-js';

import type { Database } from '../types/database/database';

const supabaseUrl: string = import.meta.env.VITE_SUPABASE_URL;
const supabaseKey: string = import.meta.env.VITE_SUPABASE_ANON_KEY;

const options = {
	auth: {
		localStorage: true,
		autoRefreshToken: true,
		persistSession: true,
		detectSessionInUrl: true,
	},

	realtime: {
		params: {
			eventsPerSecond: 10,
		},
	},
	timeout: 60000, // Set timeout to 60 seconds (adjust as needed)
};

export const supabaseClient = createClient<Database>(supabaseUrl, supabaseKey, options);
