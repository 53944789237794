import type { EntryViewT, StatefulDiaryT } from '@/types/database/diaries';

export function flattenEntriesView(entries: EntryViewT[]) {
	return entries?.flatMap((entry) => entry.entry_values_view);
}

export function combineTrackerEntries(diary: StatefulDiaryT): EntryViewT[] {
	const entries = diary?.trackers_view.flatMap((tracker) => tracker.entries_view);
	return entries;
}

export function findLatestEntry(diary: StatefulDiaryT) {
	try {
		const entries = combineTrackerEntries(diary);
		if (!entries.length) return null;
		const latestEntry = entries?.reduce((latest, entry) => {
			if (!latest) return entry;
			if (entry?.calculated_entry_time > latest?.calculated_entry_time) return entry;
			return latest;
		}, null); // Add null as the initial value
		return latestEntry;
	} catch (e) {
		console.error(e);
		return null;
	}
}

export const getEntryValues = (diary: StatefulDiaryT) => {
	const entries = combineTrackerEntries(diary);
	const entryValues = flattenEntriesView(entries);
	return entryValues;
};
