import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { cn } from '@/lib/utils';
import { flexRender } from '@tanstack/react-table';

const DiariesTable = ({ table, diaryId }) => {
	return (
		<div className="bg-white dark:bg-slate-900 border rounded-md w-full h-fit md:h-[76vh] overflow-scroll">
			<Table className="overflow-x-scroll">
				<TableHeader>
					{table.getHeaderGroups().map((headerGroup) => (
						<TableRow key={headerGroup.id}>
							{headerGroup.headers.map((header) => {
								return (
									<TableHead className="first:pr-0 pl-2 first:pl-4 dark:text-slate-200" key={header.id}>
										{header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
									</TableHead>
								);
							})}
						</TableRow>
					))}
				</TableHeader>
				<TableBody>
					{table.getRowModel()?.rows?.map((row) => (
						<TableRow
							className={cn(
								'cursor-pointer ',
								row.original.is_read && 'bg-slate-200 text-slate-600 dark:bg-slate-700 opacity-70',
								diaryId === row.original.diary_id
									? 'bg-indigo-200 dark:bg-indigo-500 dark:bg-text-slate-950'
									: 'dark:text-slate-100',
							)}
							key={row.id}
							data-state={row.getIsSelected() && 'selected'}
						>
							{row?.getVisibleCells().map((cell) => (
								<TableCell className="p-0" key={cell.id}>
									{flexRender(cell.column.columnDef.cell, cell.getContext())}
								</TableCell>
							))}
						</TableRow>
					))}
				</TableBody>
			</Table>
		</div>
	);
};

export default DiariesTable;
