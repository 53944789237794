import { components as c } from "@/components";
import AuthWrap from "@/features/auth/components/AuthWrap";
import { useAuth } from "@/utils/auth";
import { Navigate, createFileRoute, redirect } from "@tanstack/react-router";
import ConfirmAccountForm from "@/features/auth/components/ConfirmAccountForm";
import { z } from "@/utils/form";
import { MailCheck } from "lucide-react";

const ConfirmAccount = () => {
  const { email, invited_by_name } = Route.useSearch();
  const auth = useAuth();
  if (auth.isAuthenticated) {
    return Navigate({ to: "/change-password" });
  }
  return (
    <AuthWrap>
      <c.cc.Title words="Confirm Account" />
      {invited_by_name && (
        <c.cc.Call icon={<MailCheck />} words="Invitation found">
          Invitation from {invited_by_name} found.
        </c.cc.Call>
      )}
      <br />
      <ConfirmAccountForm email={email} />
    </AuthWrap>
  );
};

const ConfirmAccountZ = z.object({
  email: z.string().email().optional(),
  invited_by_name: z.string().optional(),
});

export const Route = createFileRoute("/_auth/confirm-account")({
  component: ConfirmAccount,
  beforeLoad: ({ context }) => {
    if (context?.auth?.isAuthenticated === true) {
      throw redirect({
        to: "/change-password",
      });
    }
    return context;
  },
  validateSearch: (search) => ConfirmAccountZ.parse(search),
});
