import { supabaseClient } from '@/lib/supabase';
import { queryOptions, useSuspenseQuery } from '@tanstack/react-query';

export async function selectTemplateById(id: string) {
	return await supabaseClient
		.from('diaries')
		.select('*,forms(*,pages(*,inputs(*)))')
		.eq('diary_id', id)
		.eq('is_template', true)
		.throwOnError()
		.single()
		.then((res) => res.data);
}

export const templatesQueryKey = (id: string) => ['diary-templates', { diary_id: id }];

export const diaryTemplateByIdQueryOptions = (id: string) => {
	return queryOptions({
		queryKey: templatesQueryKey(id),
		queryFn: () => selectTemplateById(id),
	});
};

export const useDiaryTemplateByIdQuery = (id: string) => {
	const options = diaryTemplateByIdQueryOptions(id);
	const query = useSuspenseQuery(options);
	return query;
};
