interface Props {
    className?: string;
    strokeWidth?: number;
    stroke?: string;
    fill?: string;
}
const SortArrow = ({ stroke, fill, strokeWidth, className }: Props) => {
    return (
        <svg
            className={className}
            width="14"
            height="12"
            viewBox="0 0 14 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                strokeWidth={strokeWidth ? strokeWidth : 1}
                d="M13 1H1L7 11L13 1Z"
                fill={fill ? fill : 'black'}
                stroke={stroke ? stroke : 'black'}
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default SortArrow;
