import { Trash2 } from 'lucide-react';

import { cn } from '../../lib/utils';
import { ui } from '../ui';
import { buttonVariants } from '../ui/button';

interface Props {
	words?: string;
	deleteFunction: () => void;
	size?: 'sm' | 'md' | 'lg';
	variant?: 'destructive' | 'destructive-secondary' | 'destructive-link';
}

const DeleteButton = (props: Props) => {
	return (
		<ui.AlertDialog>
			<ui.AlertDialogTrigger
				className={cn(buttonVariants({ size: props.size ?? 'lg', variant: props.variant ?? 'destructive-secondary' }))}
			>
				<Trash2 />
				{props.words || 'Delete'}
			</ui.AlertDialogTrigger>

			<ui.AlertDialogContent>
				<ui.AlertDialogHeader>
					<ui.AlertDialogTitle>Are you absolutely sure?</ui.AlertDialogTitle>
					<ui.AlertDialogDescription>This action cannot be undone.</ui.AlertDialogDescription>
				</ui.AlertDialogHeader>
				<ui.AlertDialogFooter className="flex flex-col gap-y-4 md:flex-row">
					<ui.AlertDialogAction
						onClick={props.deleteFunction}
						className={buttonVariants({ variant: 'destructive', size: 'lg' })}
					>
						Delete
					</ui.AlertDialogAction>
					<ui.AlertDialogAction className={cn(buttonVariants({ variant: 'outline', size: 'lg' }), 'text-slate-800')}>
						Cancel
					</ui.AlertDialogAction>
				</ui.AlertDialogFooter>
			</ui.AlertDialogContent>
		</ui.AlertDialog>
	);
};

export default DeleteButton;
