const Quality = () => {
    return (
        <svg
            className="h-full w-full"
            width="70"
            height="70"
            viewBox="0 0 70 70"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.7254 54.9536L20.7358 39.226L36.5382 44.9371L26.1483 62.9861L22.1569 55.9652C21.8014 55.3399 21.1375 54.9536 20.4182 54.9536L11.7254 54.9536Z"
                fill="white"
                stroke="black"
                strokeWidth="2"
            />
            <path
                d="M45.0865 62.9732L34.4367 44.4246L49.5567 38.2569L59.2768 55.2673H50.5762C49.8525 55.2673 49.1853 55.6583 48.8315 56.2897L45.0865 62.9732Z"
                fill="white"
                stroke="black"
                strokeWidth="2"
            />
            <path
                d="M31.0297 9.80157L34.1165 11.083C35.0886 11.4865 36.1806 11.4906 37.1557 11.0944L40.0803 9.90623C41.0027 9.53147 42.0602 9.89223 42.5613 10.7525L43.9391 13.1181C44.4686 14.0273 45.3351 14.6912 46.3508 14.966L49.9277 15.9339C50.856 16.1851 51.4741 17.0613 51.3993 18.0201L51.1413 21.3256C51.0563 22.4148 51.4205 23.4914 52.1493 24.3053L54.3915 26.8091C55.0915 27.5908 55.0684 28.7805 54.3385 29.5344L52.2475 31.6943C51.4602 32.5076 51.0543 33.6164 51.1305 34.7458L51.3996 38.735C51.4714 39.7997 50.6946 40.7333 49.6345 40.8563L46.9237 41.1706C45.5798 41.3265 44.4058 42.1511 43.8032 43.3623L42.5961 45.7888C42.0938 46.7983 40.8569 47.1933 39.8625 46.6617L37.3184 45.3017C36.2945 44.7543 35.0834 44.6788 33.9995 45.0947L30.1999 46.5528C29.2503 46.9172 28.1784 46.5108 27.709 45.6084L26.3895 43.0715C25.8678 42.0684 24.946 41.333 23.8521 41.0471L20.7685 40.2414C19.8051 39.9896 19.1736 39.0675 19.2872 38.0782L19.6688 34.7547C19.795 33.6551 19.4601 32.5525 18.7436 31.7089L16.523 29.0946C15.8569 28.3104 15.8945 27.1488 16.6098 26.4093L18.5692 24.3838C19.3917 23.5335 19.7957 22.3627 19.6725 21.186L19.2871 17.5067C19.1765 16.4507 19.9104 15.4923 20.9587 15.3237L23.8789 14.8541C24.8794 14.6933 25.7815 14.1586 26.403 13.3583L28.6833 10.422C29.2362 9.71004 30.1971 9.45595 31.0297 9.80157Z"
                fill="white"
                stroke="black"
                strokeWidth="2"
                strokeLinejoin="round"
            />
            <circle cx="35.5" cy="27.5" r="10.5" stroke="black" strokeWidth="2" />
            <path
                d="M31 28.5455L33.6129 31L40 25"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default Quality;
