import { ui } from '@/components/ui';
import { services as s } from '@/services';

import TrackerForm from './TrackerForm';

const newTrackerId = s.uuidv4();

interface Props {
    diaryId: string;
    path: string;
}

const TrackerDialog = ({ diaryId, path }: Props) => {
    return (
        <ui.AlertDialog>
            <ui.Button asChild>
                <ui.AlertDialogTrigger>Next</ui.AlertDialogTrigger>
            </ui.Button>
            <ui.AlertDialogContent>
                <ui.AlertDialogTitle>How will you follow-up with the patient?</ui.AlertDialogTitle>
                <ui.AlertDialogDescription>
                    <TrackerForm trackerId={newTrackerId} diaryId={diaryId} trackerPath={`${path}/${newTrackerId}`} />
                </ui.AlertDialogDescription>
            </ui.AlertDialogContent>
        </ui.AlertDialog>
    );
};

export default TrackerDialog;
