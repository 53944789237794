import Title from '@/components/common/Title';
import Input from '@/components/form/Input';
import { ui } from '@/components/ui';
import { supabaseClient } from '@/lib/supabase';
import { useMutation } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';

const UpdateEmailForm = () => {
	const form = useForm();
	const { handleSubmit } = form;

	const updateEmailMutation = useMutation({
		mutationFn: async ({ old_email, new_email }: { old_email: string; new_email: string }) => {
			const { data, error } = await supabaseClient.rpc('update_email', { old_email, new_email });
			if (error) throw error;
			return data;
		},
		onSuccess: () => {
			toast.success('Email updated successfully');
		},
		onError: (error) => {
			console.error('Error updating email:', error);
			toast.error('Failed to update email. Please try again.');
		},
	});

	const onSubmit = (data) => {
		updateEmailMutation.mutate(data);
	};

	return (
		<section className="md-width">
			<Title words="Update Email" />
			<ui.Form {...form}>
				<form onSubmit={handleSubmit(onSubmit)} className="form">
					<Input name="old_email" label="Old Email" placeholder="Old Email" />

					<Input name="new_email" label="New Email" placeholder="New Email" />

					<ui.Button type="submit" disabled={updateEmailMutation.isPending}>
						{updateEmailMutation.isPending ? 'Updating...' : 'Update Email'}
					</ui.Button>
				</form>
			</ui.Form>
		</section>
	);
};

export const Route = createFileRoute('/_app/admin/update-email')({
	component: UpdateEmailForm,
});
