import { supabaseClient } from '@/lib/supabase';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';

import type { TimingT } from '../../../types/zod/timings';
import { templatesQueryKey } from './selectSchedules';

export async function upsertTiming(timings: TimingT[]) {
	return await supabaseClient
		.from('timings')
		.upsert(timings)
		.select()
		.throwOnError()
		.then((res) => res.data);
}

export const useTimingMutation = () => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationKey: ['timing'],
		mutationFn: async (timing: TimingT[]) => {
			return await upsertTiming(timing);
		},
		onError: () => {
			toast.error('Error creating diary log');
		},
		onSuccess: (data) => {
			return data;
		},
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey: templatesQueryKey });
		},
	});
};
