import { services as s } from '@/services';
import { TimeUnitT, TimingT } from '@/types/zod/timings';

type GenerateTimeT = Partial<TimingT> & {
    repeats: number;
    interval: number;
    time_unit: TimeUnitT;
};

export const generateTimeLabel = (time: GenerateTimeT) => {
    return s.capitaliseSentence(
        `${time.time_unit} ${time.interval} ${time?.repeats > 0 ? '- ' + (1 + time.repeats) * time.interval : ''}`,
    );
};

export const generateSingleTimeLabel = (time: GenerateTimeT, index: number) => {
    const factor = index === 0 ? 1 : index + 1;
    return s.capitaliseSentence(`${time.time_unit} ${factor * time.interval}`);
};
