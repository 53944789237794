import * as fuzzy from 'fuzzy';

export function fuzzySearch(searchString: string, searchField: string, patients: any[]): any[] {
    try {
        if (searchString) {
            const options = {
                extract: function (el: any) {
                    return typeof el === 'string' ? el : '';
                },
            };
            const names = patients?.map((patient: any) => patient[searchField]);
            let results = fuzzy.filter(searchString, names, options);
            const matches = results.map(function (el) {
                return el.string;
            });

            results = results?.map((result: any) => result.string);
            const searchedPatients = patients?.filter((patient) => matches?.includes(patient[searchField]));
            return searchedPatients;
        } else {
            return patients;
        }
    } catch (error) {
        console.log(error);
        return patients;
    }
}
