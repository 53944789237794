import { components as c } from '@/components';
import VerifyOtpModal from '@/features/auth/components/VerifyOtpModal';
import useForgotPasswordMutation from '@/features/auth/hooks/useForgotPasswordMutation';
import { type SubmitHandler, useForm } from '@/utils/form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate } from '@tanstack/react-router';
import { useEffect, useRef, useState } from 'react';
import { z } from 'zod';

interface Props {
	email?: string;
}
const ForgotPasswordForm = ({ email }: Props) => {
	const navigate = useNavigate();
	const [showModal, setShowModal] = useState(false);

	const forgotPasswordMutation = useForgotPasswordMutation(setShowModal);

	const formSchema = z.object({
		email: z.string().email('Invalid email').min(1, 'Email is required'),
	});

	type FormSchemaType = z.infer<typeof formSchema>;

	const form = useForm<FormSchemaType>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			email: email ?? '',
		},
	});

	const onSubmit: SubmitHandler<FormSchemaType> = async (formData) => {
		forgotPasswordMutation.mutate(formData);
	};

	const resendCode = async () => {
		await forgotPasswordMutation.mutate({ email: form.getValues(['email'])[0] });
	};

	const codeRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		codeRef?.current?.focus();
	}, [forgotPasswordMutation.isSuccess]);

	return (
		<>
			<VerifyOtpModal
				onSuccess={() => navigate({ to: '/change-password' })}
				type="email"
				phoneOrEmail={form.getValues(['email'])[0]}
				showModal={showModal}
				setShowModal={setShowModal}
				codeRef={codeRef}
				resendCode={resendCode}
			/>

			<c.ui.Form {...form}>
				<form onSubmit={form.handleSubmit(onSubmit)} className="form">
					{/* Email */}
					<c.fc.Email autoFocus={true} tabIndex={0} />

					<c.ui.Button data-cy="submit" type="submit" disabled={forgotPasswordMutation.isPending} size="lg">
						Submit
					</c.ui.Button>
				</form>
			</c.ui.Form>
		</>
	);
};

export default ForgotPasswordForm;
