const Eight = () => {
	return (
		<svg
			role="img"
			aria-label="Pain Eight"
			width="50"
			height="50"
			viewBox="0 0 50 50"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect x="1" y="1" width="48" height="48" rx="24" fill="white" stroke="black" strokeWidth="2" />
			<path
				d="M18.4172 36.1311L16.5382 37.4902C16.0773 37.8236 15.7175 38.2779 15.4987 38.803C15.3197 39.2328 15.7585 39.6602 16.1834 39.4698L18.3832 38.4843C20.273 37.6377 22.3205 37.2 24.3914 37.2L25.0619 37.2C27.2212 37.2 29.3587 37.6307 31.3496 38.4668L33.8208 39.5047C34.2533 39.6864 34.6885 39.2524 34.5081 38.8194C34.2848 38.2836 33.9177 37.82 33.4474 37.4798L31.5828 36.1311C29.6674 34.7458 27.3638 34 25 34C22.6362 34 20.3326 34.7458 18.4172 36.1311Z"
				fill="black"
				stroke="black"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M6.20367 16.8176C6.05836 16.2847 6.3725 15.735 6.90532 15.5897L7.18756 15.5127C9.99644 14.7466 12.4717 13.0923 14.2512 10.8105C14.5909 10.375 15.2089 10.2481 15.6684 10.5544V10.5544C16.128 10.8608 16.2547 11.484 15.9192 11.9227C13.8687 14.6048 10.9887 16.5491 7.7138 17.4422L7.43156 17.5192C6.89873 17.6645 6.34899 17.3504 6.20367 16.8176Z"
				fill="black"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M44.2968 16.8176C44.4421 16.2847 44.128 15.735 43.5952 15.5897L43.3129 15.5127C40.504 14.7466 38.0288 13.0923 36.2492 10.8105C35.9096 10.375 35.2916 10.2481 34.8321 10.5544V10.5544C34.3725 10.8608 34.2458 11.484 34.5813 11.9227C36.6318 14.6048 39.5118 16.5491 42.7867 17.4422L43.0689 17.5192C43.6018 17.6645 44.1515 17.3504 44.2968 16.8176Z"
				fill="black"
			/>
			<path
				d="M11.1655 21.9922C10.4407 20.7367 10.8708 19.1312 12.1263 18.4064L15.3739 16.5314C16.6295 15.8065 18.2349 16.2367 18.9598 17.4922V17.4922C20.1679 19.5847 19.4509 22.2604 17.3584 23.4685L17.1419 23.5935C15.0494 24.8017 12.3737 24.0847 11.1655 21.9922V21.9922Z"
				fill="#0E0E0E"
			/>
			<path
				d="M31.7925 17.4923C32.5174 16.2368 34.1228 15.8066 35.3783 16.5315L38.6259 18.4065C39.8814 19.1314 40.3116 20.7368 39.5867 21.9923V21.9923C38.3786 24.0848 35.7029 24.8018 33.6103 23.5937L33.3938 23.4687C31.3013 22.2605 30.5844 19.5848 31.7925 17.4923V17.4923Z"
				fill="#0E0E0E"
			/>
		</svg>
	);
};

export default Eight;
