import { createGetDiaryKey } from '@/features/diary/api/getDiaryById';
import { supabaseClient } from '@/lib/supabase';
import type { Tracker } from '@/types/database/trackers';
import type { UuidT } from '@/types/zod/auth';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';

type UpdateTrackerT = Tracker['Update'] & { tracker_id: string };

export async function updateTracker(tracker: UpdateTrackerT) {
	return await supabaseClient
		.from('trackers')
		.update(tracker)
		.eq('tracker_id', tracker.tracker_id)
		.select()
		.throwOnError()
		.single()
		.then((res) => res.data);
}

interface TrackerMutation {
	diaryId: UuidT;
}

export const useUpdateTracker = ({ diaryId }: TrackerMutation) => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationKey: ['update-tracker'],
		mutationFn: async (data: UpdateTrackerT) => {
			return await updateTracker(data);
		},
		onError: () => {
			toast.error('Error saving');
		},
		onSuccess: () => {
			toast.success('Saved');
		},
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey: createGetDiaryKey(diaryId) });
		},
	});
};
