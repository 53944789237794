import React from 'react';

const Attachment = () => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M27.1002 17.8999L12.1002 2.89994C9.6002 0.29994 5.5002 0.29994 2.9002 2.79994C0.300195 5.29994 0.300195 9.49994 2.9002 11.9999L3.0002 12.0999L5.10655 14.2815C5.48766 14.6762 6.11836 14.6818 6.50633 14.2938V14.2938C6.88954 13.9106 6.88954 13.2893 6.50634 12.9061L4.3002 10.6999C2.6002 8.99994 2.6002 6.09994 4.3002 4.39994C6.0002 2.69994 8.9002 2.59994 10.6002 4.29994L10.7002 4.39994L25.6002 19.2999C27.4002 20.9999 27.4002 23.8999 25.7002 25.5999C24.0002 27.3999 21.1002 27.3999 19.4002 25.6999L19.3002 25.5999L11.9002 18.1999C10.9002 17.1999 11.0002 15.5999 11.9002 14.6999C12.9002 13.7999 14.4002 13.7999 15.4002 14.6999L18.8002 18.0999C19.1868 18.4865 19.8136 18.4865 20.2002 18.0999V18.0999C20.5868 17.7133 20.5868 17.0865 20.2002 16.6999L16.7002 13.1999C14.9002 11.4999 12.1002 11.5999 10.4002 13.3999C8.8002 15.0999 8.8002 17.7999 10.4002 19.5999L17.9002 27.0999C20.4002 29.6999 24.5002 29.6999 27.1002 27.1999C29.7002 24.6999 29.7002 20.4999 27.1002 17.8999Z"
                fill="black"
            />
        </svg>
    );
};

export default Attachment;
