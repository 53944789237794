import { components as c } from '@/components';
import ChevronButton from '@/components/common/ChevronButton';
import { ui } from '@/components/ui';
import { Route as GuidedInjectionFormRoute } from '@/routes/_app/new-diary/patient/$patientId/organisation/$organisationId/diary/guided/injection-diary/time';
import { Link, createFileRoute } from '@tanstack/react-router';
const Guided = () => {
	const { patientId, organisationId } = Route.useParams();
	return (
		<>
			<c.cc.Title words="Choose Diary Type" />
			<Link to={GuidedInjectionFormRoute.to} params={{ patientId, organisationId }}>
				<ui.Card className="flex flex-row items-center justify-center">
					<ui.CardHeader className="flex-grow w-full">
						<ui.CardTitle>Injection</ui.CardTitle>
						<ui.CardDescription>Diary for tracking pain around musculo-skeletal injections</ui.CardDescription>
					</ui.CardHeader>
					<ui.CardContent className="mt-4 flex items-center justify-center">
						<ChevronButton />
					</ui.CardContent>
				</ui.Card>
			</Link>
		</>
	);
};

export const Route = createFileRoute('/_app/new-diary/patient/$patientId/organisation/$organisationId/diary/guided/')({
	component: Guided,
});
