import { commonComponents as cc } from '@/components/common';
import { ui } from '@/components/ui';
import { useProfileQuery } from '@/features/profile/api/selectProfile';
import { trackerTypesQueryOptions } from '@/features/trackers/api/selectTrackerTypes';
import TrackerCheckForm from '@/features/trackers/components/TrackerCheckForm';
import { Route as CustomTrackerRoute } from '@/routes/_app/diary/$diaryId/new/tracker/$trackerId/custom';
import { Link, createFileRoute } from '@tanstack/react-router';

const Tracker = () => {
	const { diaryId, trackerId } = Route.useParams();
	const { trackerTypes } = Route.useLoaderData();
	const { data: profile } = useProfileQuery();
	return (
		<div className="space-y-4">
			<cc.Title words="Select Tracker" />
			{profile?.is_super_user && (
				<ui.Button asChild size="lg" variant="link">
					<Link to={CustomTrackerRoute.to} params={(prev) => ({ ...prev })}>
						Custom Tracker
					</Link>
				</ui.Button>
			)}
			<TrackerCheckForm trackerId={trackerId} diaryId={diaryId} trackerTypes={trackerTypes} />
			<ui.Button asChild size="lg" variant="secondary">
				<Link to="/diary/$diaryId/edit" params={{ diaryId: diaryId }}>
					Skip
				</Link>
			</ui.Button>
		</div>
	);
};

export const Route = createFileRoute('/_app/diary/$diaryId/new/tracker/$trackerId/')({
	component: Tracker,
	loader: async ({ context }) => {
		const trackerTypes = await context?.queryClient.ensureQueryData(trackerTypesQueryOptions());
		return { trackerTypes };
	},
});
