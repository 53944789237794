import { Button } from '@/components/ui/button';
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Route as PrintDiaryRoute } from '@/routes/preview/patient/$patientId/diary/$diaryId';
import type { StatefulDiaryT } from '@/types/database/diaries';
import { DotsHorizontalIcon } from '@radix-ui/react-icons';
import { Link } from '@tanstack/react-router';

interface Props {
	diary: StatefulDiaryT;
}
const TableRowMenu = ({ diary }: Props) => {
	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Button variant="ghost" className="h-8 w-8 p-0">
					<span className="sr-only">Open menu</span>
					<DotsHorizontalIcon className="h-4 w-4" />
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuContent align="end">
				<DropdownMenuLabel>Actions</DropdownMenuLabel>
				<DropdownMenuItem asChild>
					<Link
						to={PrintDiaryRoute.to}
						params={{ diaryId: diary.diary_id, patientId: diary.patient_id }}
						target="_blank"
					>
						<span>Print Diary</span>
					</Link>
				</DropdownMenuItem>
				<DropdownMenuSeparator />
				<DropdownMenuItem asChild>
					<Link to="/dashboard/diary/$diaryId" params={{ diaryId: diary.diary_id }} search={{}}>
						View diary details
					</Link>
				</DropdownMenuItem>
				<DropdownMenuItem asChild>
					<Link to="/diary/$diaryId/edit" params={{ diaryId: diary.diary_id }}>
						View patient details
					</Link>
				</DropdownMenuItem>
			</DropdownMenuContent>
		</DropdownMenu>
	);
};

export default TableRowMenu;
