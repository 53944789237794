import { ui } from '@/components/ui';
import { services as s } from '@/services';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate } from '@tanstack/react-router';
import { type SubmitHandler, useForm } from 'react-hook-form';

import { useAuth } from '../../../utils/auth';
import { useInsertScheduleMutation } from '../api/insertSchedule';
import { useTimingMutation } from '../api/upsertTimings';
import { mapTimesToTimings } from '../services/mapTimesToTimings';
import { type InsertScheduleT, InsertScheduleZ } from '../types/templateSchema';

interface Props {
	timings: TimingT[];
}
const SaveTemplateDialog = ({ timings }: Props) => {
	const auth = useAuth();
	const navigate = useNavigate();
	const form = useForm<InsertScheduleT>({
		resolver: zodResolver(InsertScheduleZ),
		defaultValues: {
			schedule_id: s.uuidv4(),
			schedule_name: '',
			is_public: false,
			author: auth?.id,
		},
	});

	const upsertTimings = useTimingMutation();
	const insertSchedule = useInsertScheduleMutation();

	const onSubmit: SubmitHandler<InsertScheduleT> = async (formData) => {
		const newUuid = s.uuidv4();
		const data = { ...formData, schedule_id: newUuid, author: auth?.id };
		insertSchedule.mutateAsync(data).then(() => {
			upsertTimings.mutate(mapTimesToTimings(timings, newUuid));
		});
		navigate({ to: '/timing/$scheduleId', params: { scheduleId: newUuid } });
	};

	return (
		<ui.Dialog>
			<ui.DialogTrigger asChild>
				<ui.Button size="lg" variant="secondary">
					Save as
				</ui.Button>
			</ui.DialogTrigger>
			<ui.DialogContent className="sm:max-w-[425px]">
				<ui.Form {...form}>
					<form onSubmit={form.handleSubmit(onSubmit)} className="form">
						<ui.DialogHeader>
							<ui.DialogTitle>Save Template</ui.DialogTitle>
							<ui.DialogDescription>Save your new template to reuse it in future.</ui.DialogDescription>
						</ui.DialogHeader>
						<ui.FormField
							control={form.control}
							name="schedule_name"
							data-cy="schedule_name"
							render={({ field }) => (
								<ui.FormItem>
									<ui.FormLabel>Template Name</ui.FormLabel>
									<ui.FormControl>
										<ui.Input tabIndex={1} type="text" {...field} />
									</ui.FormControl>
									<ui.FormMessage />
								</ui.FormItem>
							)}
						/>

						<ui.DialogFooter>
							<ui.DialogClose>
								<ui.Button type="submit">Save changes</ui.Button>
							</ui.DialogClose>
						</ui.DialogFooter>
					</form>
				</ui.Form>
			</ui.DialogContent>
		</ui.Dialog>
	);
};

export default SaveTemplateDialog;
