import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { cn } from '@/lib/utils';
import { format } from 'date-fns';
import dayjs from 'dayjs';
import { CalendarIcon } from 'lucide-react';

interface Props {
	value: Date | null;
	onChange: (date: Date | null) => void;
	label?: string;
	description?: string;
	disabled?: boolean;
}

const DateInput = ({ value, onChange, label, description, disabled }: Props) => {
	return (
		<div className="flex flex-col h-fit w-full text-sm">
			{label && <label className="font-medium mb-1">{label}</label>}
			{description && <p className="text-sm text-gray-500 mb-2">{description}</p>}
			<Popover>
				<PopoverTrigger className="h-12 border-slate-300 rounded-md bg-white hover:bg-white" asChild>
					<Button variant={'outline'} className={cn('pl-3 pr-6 text-left text-sm font-normal text-slate-800')}>
						{value ? format(value, 'PPP') : <span className="text-sm text-slate-600">Pick a date</span>}
						<CalendarIcon className="ml-auto h-4 w-4  stroke-slate-800" />
					</Button>
				</PopoverTrigger>
				<PopoverContent className="!min-w-sm flex items-center justify-center p-0" align="start">
					<Calendar
						mode="single"
						selected={value}
						onSelect={(date) => {
							if (date) {
								// Set the selected date to 12 PM before updating the value
								const dateWithNoon = dayjs(date).set('hour', 12).set('minute', 0).toDate();
								onChange(dateWithNoon);
							} else {
								onChange(null);
							}
						}}
						disabled={disabled ?? false}
						initialFocus
					/>
				</PopoverContent>
			</Popover>
		</div>
	);
};

export default DateInput;
