import PatientCard from '../../patient/PatientCard';
import ActionEmail from './ActionEmail';
import TakeActionTitle from './ActionTitle';
import DiaryCard from './DiaryCard';
import InterfaceButtons from './InterfaceButtons';
import NoDiarySelected from './NoDiarySelected';
import SelectAction from './SelectAction';
import StateCard from './StateCard';
import AiSummary from './AiSummary';
export const interfaceComponents = {
	PatientCard,
	AiSummary,
	NoDiarySelected,
	DiaryCard,
	StateCard,
	SelectAction,
	ActionEmail,
	ActionTitle: TakeActionTitle,
	InterfaceButtons,
};
