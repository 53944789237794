import { injectionQueryOptions, useInjectionQuery } from './selectInjection';
import { injectionContentsQueryOptions, useInjectionContentsQuery } from './selectInjectionContents';
import { injectionLocationsQueryOptions, useInjectionLocationsQuery } from './selectInjectionLocations';
import { injectionMethodsQueryOptions, useInjectionMethodsQuery } from './selectInjectionMethods';

// import { useInjectionMutation } from "./upsertInjection";
export const injectionApi = {
	injectionContentsQueryOptions,
	useInjectionContentsQuery,
	injectionLocationsQueryOptions,
	useInjectionLocationsQuery,
	injectionQueryOptions,
	useInjectionQuery,
	injectionMethodsQueryOptions,
	useInjectionMethodsQuery,
	// useInjectionMutation,
};
