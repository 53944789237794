interface Props {
    stroke?: string;
    className?: string;
}
const Shape = ({ stroke, className }: Props) => {
    return (
        <svg
            className={className}
            width="48"
            height="45"
            viewBox="0 0 48 45"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M30.4798 9.07923C27.3455 4.37087 20.6545 4.37087 17.5202 9.07923C15.0517 12.7874 10.9916 15 6.65569 15H2.66667C1.19391 15 0 13.758 0 12.226C0 10.694 1.19391 9.45204 2.66667 9.45204H6.65569C9.24174 9.45204 11.6633 8.13239 13.1355 5.92077C18.3906 -1.97359 29.6094 -1.97359 34.8645 5.92077C36.3367 8.13239 38.7583 9.45204 41.3443 9.45204H45.3333C46.8061 9.45204 48 10.694 48 12.226C48 13.758 46.8061 15 45.3333 15H41.3443C37.0084 15 32.9483 12.7874 30.4798 9.07923Z"
                fill="currentcolor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.2457 36.327C21.3909 40.5236 27.6091 40.5236 30.7543 36.327C33.2699 32.9705 37.1854 31 41.3395 31L45.2789 31C46.7817 31 48 32.2369 48 33.7628C48 35.2886 46.7817 36.5256 45.2789 36.5256L41.3395 36.5256C38.885 36.5256 36.5715 37.6898 35.0851 39.673C29.762 46.7756 19.238 46.7756 13.9149 39.673C12.4285 37.6898 10.115 36.5256 7.66051 36.5256L3.72105 36.5256C2.21825 36.5256 1 35.2886 1 33.7628C1 32.2369 2.21825 31 3.72105 31L7.66051 31C11.8146 31 15.7301 32.9705 18.2457 36.327Z"
                fill="currentcolor"
            />
        </svg>
    );
};

export default Shape;
