import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';

import LazyImage from './LazyImage';

interface Props {
    imageBR: string;
    imageBL: string;
    imageTR: string;
    imageTL: string;
    linkBR?: () => void;
    linkBL?: () => void;
    linkTR?: () => void;
    linkTL?: () => void;
    date?: string;
    imageParam?: string;
    allImageURLs?: Array<string>;
    selectable?: boolean;
    select?: (btn: string) => void;
    selected?: any;
}

const ImageGallery = ({
    imageBR,
    imageBL,
    imageTR,
    imageTL,
    linkBR,
    linkBL,
    linkTR,
    linkTL,
    date,
    imageParam,
    allImageURLs,
    selectable,
    selected,
}: Props) => {
    const [showDate, setShowDate] = useState<boolean>(false);
    const [fullScreen, setFullScreen] = useState<boolean>(false);

    useEffect(() => {
        if (isMobile) {
            setFullScreen(true);
        } else {
            setFullScreen(false);
        }
    }, [imageParam]);

    const imageSrc = import.meta.env.VITE_SUPABASE_CND_URL + '/' + imageParam?.replace('_', '/')?.replace('_', '/');

    return (
        <div className="">
            {date && (
                <h3
                    className={`${
                        showDate ? 'visible' : 'hidden'
                    } w-full flex align-center text-xl font-semibold justify-center py-2`}
                >
                    {date?.split('T')[0]} {date?.split('T')[1]}
                </h3>
            )}
            <div className={`flex-col z-2 flex gap-y-2`}>
                <div className={`flex w-full justify-center gap-x-2`}>
                    <LazyImage
                        image={imageTL}
                        imageSrc={imageSrc}
                        setShowDate={setShowDate}
                        onClick={linkTL}
                        placement="tl"
                        fullScreen={fullScreen}
                        selectable={selectable}
                        selected={selected?.LT}
                    />
                    <LazyImage
                        image={imageTR}
                        imageSrc={imageSrc}
                        setShowDate={setShowDate}
                        onClick={linkTR}
                        placement="tr"
                        fullScreen={fullScreen}
                        selectable={selectable}
                        selected={selected?.RT}
                    />
                </div>
                <div className={`flex w-full gap-x-2 `}>
                    <LazyImage
                        image={imageBL}
                        imageSrc={imageSrc}
                        setShowDate={setShowDate}
                        onClick={linkBL}
                        placement="bl"
                        fullScreen={fullScreen}
                        selectable={selectable}
                        selected={selected?.LS}
                    />
                    <LazyImage
                        image={imageBR}
                        imageSrc={imageSrc}
                        setShowDate={setShowDate}
                        onClick={linkBR}
                        placement="br"
                        fullScreen={fullScreen}
                        selectable={selectable}
                        selected={selected?.RS}
                    />
                </div>
            </div>
        </div>
    );
};

export default ImageGallery;
