import { services as s } from '@/services';
import dayjs from 'dayjs';
import duration, { DurationUnitType } from 'dayjs/plugin/duration';

import { generateSingleTimeLabel } from './generateTimeLabel';

dayjs.extend(duration);

import type { TimingT, EntryT } from '@/types/zod/timings';

export const processSchedule = (times: TimingT[], schedule_id: string) => {
    const entries: EntryT[] = [];

    times.forEach((time) => {
        if (!time.is_infinite && time.repeats === 0) {
            entries.push(time);
            return;
        }
        const length = time.is_infinite ? 99 : time.repeats;

        for (let i = 0; i <= length; i++) {
            const newId = s.uuidv4();
            const interval = (i + 1) * time.interval;

            entries.push({
                schedule_id: schedule_id,
                timing_id: time.timing_id,
                time_index: i + 1,
                entry_time_id: newId,
                time_unit: time.time_unit,
                interval: interval,
                timestamp: dayjs.duration(interval, `${time.time_unit}s` as DurationUnitType).asMilliseconds(),
                label: time.is_autoname
                    ? generateSingleTimeLabel(
                          {
                              time_unit: time.time_unit,
                              interval: time.interval,
                              repeats: time.repeats,
                          },
                          i,
                      )
                    : time.label,
            });
        }
    });

    entries.sort((a, b) => a.timestamp! - b.timestamp!);

    return entries;
};
