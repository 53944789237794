import { supabaseClient } from '@/lib/supabase';
import type { OrganisationCodeSchemaType } from '@/routes/_app/admin/add-organisation';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'sonner';

const addOrganisation = async (formData: OrganisationCodeSchemaType) => {
	const { data, error } = await supabaseClient.functions.invoke('admin-add-organisation', { body: formData });
	if (error) throw error;
	return data;
};
export const useAddOrganisation = () => {
	return useMutation({
		mutationFn: (formData: OrganisationCodeSchemaType) => addOrganisation(formData),
		onSuccess: () => {
			toast.info('New organisation saved');
		},
		onError: (error) => {
			toast.error(error.message);
		},
	});
};
