import { commonComponents as cc } from '@/components/common';
import { diaryTemplatesQueryOptions } from '@/features/builder/api/selectDiaryTemplates';
import { useFavouriteDiariesQuery } from '@/features/builder/api/selectFavouriteDiaries';
import DiaryTemplates from '@/features/templates/components/DiaryTemplates';
import { hooks as h } from '@/hooks';
import { Route as SelectPatientNewDiaryRoute } from '@/routes/_app/new-diary/patient/select';
import type { DiaryTemplateT, ExtendedFavouriteDiaryTemplateT } from '@/types/database/diaries';
import { createFileRoute } from '@tanstack/react-router';

const NewDiary = () => {
	const { patientId, organisationId, diaryId } = Route.useParams();

	const { templates } = Route.useLoaderData();
	const { data: favouriteDiaries } = useFavouriteDiariesQuery();

	h.useNav([{ route: { to: SelectPatientNewDiaryRoute.to }, label: 'New Diary' }]);

	return (
		<>
			<cc.Stepper
				steps={[
					{ stage: 'past', words: 'Patient' },
					{ stage: 'current', words: 'Diary' },
					{ stage: 'future', words: 'Form' },
					{ stage: 'future', words: 'Intervention' },
				]}
			/>
			<DiaryTemplates
				createable={true}
				diaryId={diaryId}
				patientId={patientId}
				organisationId={organisationId}
				templates={templates as DiaryTemplateT[]}
				favouriteDiaryTemplates={favouriteDiaries as ExtendedFavouriteDiaryTemplateT[]}
			/>
		</>
	);
};

export const Route = createFileRoute(
	'/_app/new-diary/patient/$patientId/organisation/$organisationId/diary/$diaryId/diary-templates',
)({
	component: NewDiary,
	loader: async ({ context }) => {
		const templates = await context?.queryClient.ensureQueryData(diaryTemplatesQueryOptions());
		return { templates };
	},
});
