import CardGrid from '@/components/common/CardGrid';
import { selectFormsQueryOptions } from '@/features/forms/api/selectForms';
import FormCard from '@/features/forms/components/FormCard';
import { Route as FormResults } from '@/routes/_app/admin/form/$formId/results';
import type { FormViewT } from '@/types/database/forms';
import { Link, createFileRoute } from '@tanstack/react-router';

const FormTemplatesPage = () => {
	const { forms } = Route.useLoaderData();

	const renderForm = (f) => (
		<FormCard
			link={
				<Link to={FormResults.to} params={{ formId: f.form_id }}>
					Open
				</Link>
			}
			form={f}
		/>
	);

	return <CardGrid items={forms} renderItem={renderForm} />;
};

export const Route = createFileRoute('/_app/admin/forms')({
	component: FormTemplatesPage,
	loader: async ({ context }) => {
		const forms = (await context?.queryClient.ensureQueryData(selectFormsQueryOptions())) as FormViewT[];
		return { forms };
	},
});
