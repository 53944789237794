import { useFindState } from '@/definitions/hooks/useFindState';
import LineChart from '@/features/pain-watch/diary/components/LineChart';
import { services as s } from '@/services';
import { useEffect, useState } from 'react';

import { processInjection } from './services/processInjection';

import type { DiaryT } from './content/exampleDiaries';
interface Props {
	diary: DiaryT;
}
const DiaryRecord = ({ diary }: Props) => {
	const [data, setData] = useState<number[]>([]);
	const [labels, setLabels] = useState<string[]>([]);

	const { findState } = useFindState();

	useEffect(() => {
		setData(diary?.entries?.map((entry) => entry?.value));
		setLabels(diary?.entries?.map((entry) => entry?.entry_td_friendly));
	}, [diary]);

	return (
		<section className="w-full">
			{diary?.current_state && (
				<div className="">
					<section className="max-w-[800px] rounded-xl border border-slate-300 bg-white p-4 shadow-md">
						<div>
							<div className="mb-4 flex flex-col gap-y-2">
								<div className="flex w-full items-start justify-between">
									<div className="flex items-center gap-x-2 [&_svg]:h-[32px] [&_svg]:w-[32px]">
										<div data-tip={[diary.current_state]} data-for="state-icon">
											{findState(diary?.current_state)?.icon}
										</div>
										<h2 className="text-2xl text-slate-700">
											{findState(diary.current_state)?.name ?? 'State Name Missing'}
										</h2>
									</div>
									<b className="rounded-md bg-indigo-200 px-3 py-1 text-lg font-medium capitalize text-indigo-700">
										{diary.hospital_name}
									</b>
								</div>
								<hr />
								<div className="flex w-full  items-start justify-between">
									<h3 className="text-xl font-medium text-slate-700">{diary.patient_name}</h3>
									<h4 className="text-md font-semibold text-slate-900">{diary.patient_hospital_number}</h4>
								</div>
								<h6 className="text-md font-medium text-slate-500">{s.time.formatDate(diary.inj_date)}</h6>
								<h5 className="text-md font-regular text-slate-900">{processInjection(diary)}</h5>
							</div>

							<div className="border-lg mx-auto my-2 flex h-full w-full items-center justify-center">
								{data?.length > 0 ? (
									<LineChart
										data={{
											labels: labels,
											datasets: [
												{
													label: 'Pain Score',
													data: data,
													backgroundColor: '#fbffff',
													borderColor: 'lightblue',
													fill: false,
													tension: 0.4,
												},
											],
										}}
									/>
								) : (
									<h4>No Pain Scores Logged</h4>
								)}
							</div>
							<div className="mx-auto my-2 flex max-w-[600px] items-center justify-center font-normal text-slate-500">
								<i>
									{diary?.entries[0] &&
										`Last logged
               ${s.time.toNow(s.time.findEarliest(diary.entries, 'entry_time')?.entry_time)}`}
								</i>
							</div>
						</div>
					</section>
				</div>
			)}
		</section>
	);
};

export default DiaryRecord;
