const Seven = () => {
	return (
		<svg
			role="img"
			aria-label="Pain Seven"
			width="50"
			height="50"
			viewBox="0 0 50 50"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect x="1" y="1" width="48" height="48" rx="24" fill="white" stroke="black" strokeWidth="2" />
			<rect x="11" y="16" width="8" height="8" rx="4" fill="#0E0E0E" />
			<rect x="31" y="16" width="8" height="8" rx="4" fill="#0E0E0E" />
			<path
				d="M18.8425 35.5456L17.2936 36.3754C16.9386 36.5656 16.6451 36.8531 16.4476 37.2042C16.2894 37.4855 16.5603 37.8141 16.8666 37.7124L19.4618 36.8512C20.8946 36.3757 22.3945 36.1333 23.9042 36.1333L25.6335 36.1333C27.2022 36.1333 28.7618 36.3703 30.2597 36.8363L33.1379 37.7318C33.448 37.8283 33.7166 37.4961 33.5574 37.2131C33.3566 36.8562 33.0583 36.564 32.6974 36.3706L31.1575 35.5456C29.2637 34.5309 27.1485 34 25 34C22.8515 34 20.7363 34.5309 18.8425 35.5456Z"
				fill="black"
				stroke="black"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M6.20367 15.8176C6.05836 15.2847 6.3725 14.735 6.90532 14.5897L7.18756 14.5127C9.99644 13.7466 12.4717 12.0923 14.2512 9.81047C14.5909 9.37497 15.2089 9.24808 15.6684 9.55444V9.55444C16.128 9.86079 16.2547 10.484 15.9192 10.9227C13.8687 13.6048 10.9887 15.5491 7.7138 16.4422L7.43156 16.5192C6.89873 16.6645 6.34899 16.3504 6.20367 15.8176Z"
				fill="black"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M44.2968 15.8176C44.4421 15.2847 44.128 14.735 43.5952 14.5897L43.3129 14.5127C40.504 13.7466 38.0288 12.0923 36.2492 9.81047C35.9096 9.37497 35.2916 9.24808 34.8321 9.55444V9.55444C34.3725 9.86079 34.2458 10.484 34.5813 10.9227C36.6318 13.6048 39.5118 15.5491 42.7867 16.4422L43.0689 16.5192C43.6018 16.6645 44.1515 16.3504 44.2968 15.8176Z"
				fill="black"
			/>
		</svg>
	);
};

export default Seven;
