import { ui } from '@/components/ui';
import { Link } from '@tanstack/react-router';

const GoToDiariesCard = () => {
	return (
		<ui.Card className="mb-4">
			<ui.CardHeader>
				<ui.CardTitle>Go to Diaries</ui.CardTitle>
				<ui.CardDescription className="text-md">
					Diaries serve as your personal health logs, allowing your care team to make better decisions.
				</ui.CardDescription>
			</ui.CardHeader>
			<ui.CardFooter>
				<ui.Button asChild>
					<Link to="/diaries">Go to Diaries</Link>
				</ui.Button>
			</ui.CardFooter>
		</ui.Card>
	);
};

export default GoToDiariesCard;
