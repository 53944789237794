import { formComponents as fc } from '@/components/form';
import { ui } from '@/components/ui';
import useUserUpdate from '@/features/profile/api/useUserMutation';
import type { ProfileT } from '@/types/database/profiles';
import type { TitleT } from '@/types/zod/auth';
import { FirstNameZ, LastNameZ, TitleZ } from '@/types/zod/profiles';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import type { SubmitHandler } from 'react-hook-form';
import * as z from 'zod';

export const NameSchemaZ = z.object({
	title: TitleZ,
	first_name: FirstNameZ,
	last_name: LastNameZ,
});

type FormSchemaType = z.infer<typeof NameSchemaZ>;

interface Props {
	userId: string;
	title: TitleT | null;
	firstName: ProfileT['first_name'];
	lastName: ProfileT['last_name'];
	autoFocus?: boolean;
	onSuccess?: () => void;
}
const NameForm = (props: Props) => {
	const form = useForm<z.infer<typeof NameSchemaZ>>({
		resolver: zodResolver(NameSchemaZ),
		defaultValues: {
			title: props?.title ?? undefined,
			first_name: props?.firstName ?? undefined,
			last_name: props.lastName ?? undefined,
		},
	});

	const userMutation = useUserUpdate(props.userId);

	const onSubmit: SubmitHandler<FormSchemaType> = async (formData) => {
		userMutation.mutate(formData, { onSuccess: props.onSuccess });
	};

	return (
		<ui.Form {...form}>
			<form onSubmit={form.handleSubmit(onSubmit)} className="form">
				<fc.SelectTitle autoFocus={props.autoFocus} />
				<fc.FirstName name="first_name" />
				<fc.LastName name="last_name" />
				<fc.SaveButton />
			</form>
		</ui.Form>
	);
};

export default NameForm;
