import { colors } from '@/assets/colors';
import JazzySpinner from '@/assets/icons/JazzySpinner';
import { ui } from '@/components/ui';
import AnimatedThetis from '@/features/navigation/assets/DottyThetis';
import { useRouterState } from '@tanstack/react-router';
import BouncingThetis from '../../features/navigation/assets/BouncingThetis';
import { cn } from '../../lib/utils';
import Spinner from './Spinner';

export function RouterSpinner() {
	const isLoading = useRouterState({ select: (s) => s.status === 'pending' });
	return (
		<ui.Card
			className={cn(
				'z-50 fixed top-[50svh] left-[50vw] h-[100px] w-[100px] translate-y-[-50px] translate-x-[-50px]',
				!isLoading && 'hidden',
			)}
		>
			<ui.CardContent className="m-auto p-5 w-full h-full ">
				<Spinner className="h-full w-full p-1" />
				{/* <BouncingThetis /> */}
				{/* <AnimatedThetis /> */}
				{/* <JazzySpinner className="" fill={colors['indigo-600']} /> */}
			</ui.CardContent>
		</ui.Card>
	);
}
