import {
	AlertDialog,
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
	AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import { Link } from '@tanstack/react-router';

import type { ProfileT } from '@/types/database/profiles';
import useProfileNavigation from '../hooks/useProfileNavigation';
import useProfileProgress from '../hooks/useProfileProgress';

interface Props {
	profile: ProfileT;
}

export function CompleteProfile({ profile }: Props) {
	const { nextAttribute } = useProfileNavigation();
	const { incomplete } = useProfileProgress(profile.user_id);
	if (!incomplete) {
		return <></>;
	}
	return (
		<AlertDialog defaultOpen>
			<AlertDialogTrigger asChild>
				<Button className="flex mx-0 my-2" size="sm" variant="default">
					Complete Profile
				</Button>
			</AlertDialogTrigger>
			<AlertDialogContent>
				<AlertDialogHeader>
					<AlertDialogTitle>Profile Incomplete</AlertDialogTitle>
					<AlertDialogDescription>Click continue to add your missing details.</AlertDialogDescription>
				</AlertDialogHeader>
				<AlertDialogFooter>
					<AlertDialogCancel>Skip</AlertDialogCancel>
					<AlertDialogAction className="w-fit" asChild>
						<Link to={nextAttribute}>Continue</Link>
					</AlertDialogAction>
				</AlertDialogFooter>
			</AlertDialogContent>
		</AlertDialog>
	);
}

export default CompleteProfile;
