import { usePhotosStore } from '@/features/camera/hooks/usePhotoStore';
import { services as s } from '@/services';
import { Camera, Trash2 } from 'lucide-react';
import React from 'react';
import { toast } from 'sonner';

import { cameraComponents as cac } from '../../features/camera/components';
import { useCameraStore } from '../../features/camera/hooks/useCameraStore';
import { InputT } from '../../types/database/diaries';
import { ui } from '../ui';

interface Props {
    input: InputT;
}

const Photo = (props: Props) => {
    const camera = useCameraStore();
    const { cameraOpen } = camera.getCameraAttributes();
    const photos = usePhotosStore();

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = Array.from(event.target.files || []);

        const validFiles = selectedFiles.filter((file) => file.type.startsWith('image/'));
        const invalidFiles = selectedFiles.filter((file) => !file.type.startsWith('image/'));

        if (invalidFiles.length > 0) {
            toast.error('Please select only image files.');
        }

        const validImgs = await Promise.all(
            validFiles.map(async (file) => {
                const data = await s.convertFileToBase64(file);
                return data;
            }),
        );

        photos.addPhotos(
            validImgs.map((img) => ({
                label: props.input.label ?? '',
                checked: false,
                delay: 0,
                img: img,
            })),
        );
    };

    return (
        <div className="flex flex-col items-center justify-center gap-2">
            {cameraOpen && <cac.Camera input={props.input} />}
            <p className="px-4 text-center text-md text-slate-700">
                Add photos using the camera or clicking choose file to upload from your device.
            </p>
            <ui.Button
                onClick={() => {
                    camera.toggleCamera();
                    photos.setCurrentPhotoId(s.uuidv4());
                }}
                className="w-full h-12"
                variant="secondary"
            >
                <Camera />
                Camera
            </ui.Button>
            <b>OR</b>
            <ul className="flex flex-row flex-wrap w-full gap-2">
                {photos.getPhotos().map((photo) => (
                    <li className="relative">
                        <img className="w-full max-w-sm rounded-lg max-h-sm" src={photo.img} alt={photo.label} />
                        <ui.Button
                            onClick={() => photos.removePhoto(photo.id)}
                            variant="outline"
                            size="icon"
                            className="absolute top-0 right-0 m-2 bg-opacity-90"
                        >
                            <Trash2 size={24} />
                        </ui.Button>
                    </li>
                ))}
            </ul>
            <ui.Input className="cursor-pointer" type="file" accept=".jpg, .jpeg, .png" onChange={handleFileChange} />
        </div>
    );
};

export default Photo;
