import { useProfileQuery } from '@/features/profile/api/selectProfile';
import EmailForm from '@/features/profile/components/EmailForm';
import ProfileWrap from '@/features/profile/layouts/ProfileWrap';
import { createFileRoute } from '@tanstack/react-router';
import { useAuth } from '../../../utils/auth';

const Name = () => {
	const auth = useAuth();
	const { data: profile } = useProfileQuery();

	return (
		<ProfileWrap title="Email">
			<EmailForm userId={auth.id} email={profile?.email ?? ''} />
		</ProfileWrap>
	);
};

export const Route = createFileRoute('/_app/profile/email')({
	component: Name,
});

export default Name;
