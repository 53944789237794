import { useDiaryByIdQuery } from '@/features/diary/api/getDiaryById';

import { extractTrackerById } from '../../diary/services/extractTrackerById';

const useExtractTracker = (diaryId: string, trackerId: string) => {
	const { data: diary } = useDiaryByIdQuery(diaryId);
	if (!diary) return;
	return extractTrackerById(diary, trackerId);
};

export default useExtractTracker;
