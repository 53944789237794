import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import { Route as TrackerRoute } from '@/routes/_app/diary/$diaryId/new/tracker/$trackerId/templates';
import { Link } from '@tanstack/react-router';
import { useState } from 'react';
import { findTrackerByPath } from '../../../definitions/managements';
import type { TrackerKey } from '../../../types/database/trackers';
import TrackerCheckCard from './TrackerCheckCard';

interface Props {
	trackerTypes: TrackerKey[];
	trackerId: string;
	diaryId: string;
}

export function TrackerCheckForm(props: Props) {
	const [trackers, setTrackers] = useState<TrackerKey[]>([]);

	return (
		<div className="form">
			{props.trackerTypes.map((item: TrackerKey) => (
				<TrackerCheckCard
					onClick={() => {
						if (trackers.map((m) => m.tracker_type)?.includes(item.tracker_type)) {
							setTrackers(trackers.filter((m: TrackerKey) => m.tracker_type !== item.tracker_type));
						} else {
							setTrackers([...trackers, item]);
						}
					}}
					tracker={findTrackerByPath(item.tracker_type)}
					checked={trackers.map((m) => m.tracker_type)?.includes(item.tracker_type)}
				>
					<Checkbox
						className="h-6 w-6 rounded-full border-2"
						checked={trackers.map((m) => m.tracker_type)?.includes(item.tracker_type)}
					/>
				</TrackerCheckCard>
			))}

			<Button asChild size="lg" type="submit">
				<Link to={TrackerRoute.to} params={(prev) => ({ ...prev })} search={{ trackers: trackers }}>
					Next
				</Link>
			</Button>
		</div>
	);
}

export default TrackerCheckForm;
