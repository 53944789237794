import { ui } from '@/components/ui';
import EntryCardEntryValues from '@/features/diary/history/EntryCardEntryValues';
import { entryValuesViewByFormIdQueryOptions } from '@/features/entries/api/selectEntryValuesByFormId';
import type { EntryT, EntryValueT } from '@/types/database/diaries';
import { createFileRoute } from '@tanstack/react-router';

type EntryWithValues = EntryT & { entry_values: EntryValueT[] };

function groupByEntryId(entryValues: EntryValueT[]): EntryWithValues[] {
	return entryValues.reduce((acc: EntryWithValues[], item: EntryValueT) => {
		const group = acc.find((g) => g.entry_id === item.entry_id);
		const entryValue = { ...item };

		if (group) {
			group.entry_values.push(entryValue);
		} else {
			acc.push({
				entry_id: item.entry_id,
				calculated_entry_time: item.calculated_entry_time,
				entry_values: [entryValue],
			});
		}

		return acc.filter((e) => e.entry_values.length > 0 && e.entry_id !== null);
	}, []);
}

export const FormResultsPage = () => {
	const { entryValues } = Route.useLoaderData();
	console.log(groupByEntryId(entryValues));
	return (
		<ul>
			{groupByEntryId(entryValues).map((e, i) => (
				<li key={e.entry_id}>
					<ui.Card>
						<ui.CardContent>
							Entry {i}
							<EntryCardEntryValues entryValues={e.entry_values} />
						</ui.CardContent>
					</ui.Card>
				</li>
			))}
		</ul>
	);
};

export const Route = createFileRoute('/_app/admin/form/$formId/results')({
	component: FormResultsPage,
	loader: async ({ context, params }) => {
		const entryValues = await context?.queryClient.ensureQueryData(entryValuesViewByFormIdQueryOptions(params.formId));
		return { entryValues };
	},
});
