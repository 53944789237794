import ProfileWrap from '@/features/profile/layouts/ProfileWrap';
import type { ExtendedProfileT } from '@/types/database/profiles';
import { createFileRoute } from '@tanstack/react-router';

import { selectProfileByIdQueryOptions } from '@/features/profile/api/selectProfile';
import PreferencesForm from '../../features/knock/components/PreferencesForm';
import OnboardingStepper from '../../features/onboarding/components/OnboardingStepper';
import { useNavigateToNextOnboardingRoute } from '../../features/onboarding/hooks/useNavigateToNextOnboardingRoute';

const Name = () => {
	const nextPage = useNavigateToNextOnboardingRoute('/onboarding/notifications');

	return (
		<ProfileWrap title="Onboarding">
			<OnboardingStepper currentRoute={Route.to} />

			<PreferencesForm onSuccess={nextPage} />
		</ProfileWrap>
	);
};
export const Route = createFileRoute('/onboarding/notifications')({
	component: Name,
	loader: async ({ context }) => {
		let profile: ExtendedProfileT | null = null;
		if (context?.auth?.id) {
			profile = await context?.queryClient.ensureQueryData(selectProfileByIdQueryOptions(context.auth.id));
		}
		return { profile };
	},
});

export default Name;
