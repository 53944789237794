import { commonComponents as cc } from '@/components/common';
import { diaryByIdQueryOptions, useDiaryByIdQuery } from '@/features/diary/api/getDiaryById';
import DiaryTrackersTemplates from '@/features/templates/components/DiaryTrackersTemplates';
import { createFileRoute } from '@tanstack/react-router';

const TrackerTemplates = () => {
	const { diaryId } = Route.useParams();
	const { data: diary } = useDiaryByIdQuery(diaryId);

	return (
		<>
			<cc.Title words="New Tracker" />
			<DiaryTrackersTemplates diary={diary} />
		</>
	);
};

export const Route = createFileRoute('/_app/diary/$diaryId/new/tracker/$trackerId/templates')({
	component: TrackerTemplates,
	loader: async ({ context, params }) => {
		await context?.queryClient.ensureQueryData(diaryByIdQueryOptions(params.diaryId));
	},
	errorComponent: () => <p>Tracker Templates Error</p>,
});
