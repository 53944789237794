import { supabaseClient } from '@/lib/supabase';
import { useAuth } from '@/utils/auth';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';
import { toast } from 'sonner';

type VerifyOptData =
	| {
			phone: string;
			token: string;
			type: 'sms';
	  }
	| {
			email: string;
			token: string;
			type: 'email';
	  };

const verifyCode = async (code: VerifyOptData) => {
	const { data, error } = await supabaseClient.auth.verifyOtp(code);
	if (error) throw error;
	return data;
};

const useVerifyOtp = () => {
	const queryClient = useQueryClient();
	const auth = useAuth();

	return useMutation({
		mutationKey: ['verifyOtp'],
		mutationFn: async (data: VerifyOptData) => {
			return verifyCode(data);
		},
		onError: () => {
			toast.error('Code incorrect');
		},
		onSuccess: (data, mutation) => {
			if (data?.user?.id) {
				auth.login(data.user.id);
				toast.success('Code correct');
				mutation?.onSuccess && mutation.onSuccess();
			} else if (!data?.user) {
				toast.error('Error signing in');
			}
		},
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey: ['profile'] });
			queryClient.invalidateQueries({ queryKey: ['user'] });
		},
	});
};

export default useVerifyOtp;
