import React from 'react';

const GreenTick = () => {
    return (
        <svg
            className="w-full h-full"
            width="31"
            height="31"
            viewBox="0 0 31 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="15.5" cy="15.5" r="15.5" fill="#57D77B" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.201 10.8073C24.5915 10.4168 24.5915 9.78362 24.201 9.39309L23.1008 8.29289C22.7103 7.90237 22.0771 7.90237 21.6866 8.29289L11.2465 18.733L8.80731 16.2938C8.41678 15.9032 7.78362 15.9032 7.39309 16.2938L6.29289 17.394C5.90237 17.7845 5.90237 18.4177 6.29289 18.8082L10.5361 23.0514C10.9266 23.4419 11.5598 23.4419 11.9503 23.0514L12.2912 22.7105C12.3161 22.69 12.3401 22.6682 12.3633 22.645L24.201 10.8073Z"
                fill="white"
            />
        </svg>
    );
};

export default GreenTick;
