import { services as s } from '@/services';
import { Controller, useFormContext } from 'react-hook-form';

import type { InputT } from '../../types/database/forms';
import { ui } from '../ui';
import AddOption from './AddOption';

interface Props {
	input: InputT;
}

const MultipleChoice = ({ input }: Props) => {
	const { control } = useFormContext();

	return (
		<ui.Card>
			<ui.CardHeader>
				<ui.Label>{input.input_label}</ui.Label>
			</ui.CardHeader>
			<ui.CardContent>
				<Controller
					name={input.input_id}
					control={control}
					render={({ field: { value, onChange } }) => (
						<ul className="flex flex-col">
							{input.options?.map((o) => (
								<li key={o} className="flex items-center gap-2 p-2">
									<ui.Checkbox
										checked={Array.isArray(value) && value.includes(o)}
										onCheckedChange={(checked) => {
											const newValue = Array.isArray(value) ? value : [];
											if (checked) {
												newValue.push(o);
											} else {
												newValue.splice(newValue.indexOf(o), 1);
											}
											onChange(newValue);
										}}
									/>
									<ui.Label className="font-normal capitalize">{s.capitaliseSentence(o, '-')}</ui.Label>
								</li>
							))}
						</ul>
					)}
				/>
				<ui.FormMessage />
			</ui.CardContent>
			<ui.CardFooter className="flex w-full flex-col items-start gap-2">
				<AddOption input={input} />
			</ui.CardFooter>
		</ui.Card>
	);
};

export default MultipleChoice;
