import { supabaseClient } from '@/lib/supabase';
import { queryOptions, useSuspenseQuery } from '@tanstack/react-query';

export async function getOrganisations() {
	const { data, error } = await supabaseClient.from('organisations').select('organisation_id, organisation_name');
	if (error) throw error;
	return data;
}

export const organisationsQueryOptions = () =>
	queryOptions({
		queryKey: ['organisation'],
		queryFn: async () => getOrganisations(),
	});

function useOrganisationsQuery() {
	return useSuspenseQuery(organisationsQueryOptions());
}

export default useOrganisationsQuery;
