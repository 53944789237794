import type { StatefulDiaryT } from '@/types/database/diaries';
import type { StatefulTrackerViewT } from '@/types/database/trackers';

import TrackerComplete from '../../../entries/components/TrackerComplete';
import EntryDue from './new-entry-states/EntryDue';
import EntryLocked from './new-entry-states/EntryLocked';
import EntryReadyToReview from './new-entry-states/EntryReadyToReview';

interface Props {
	diary: StatefulDiaryT;
	tracker: StatefulTrackerViewT;
}
const NewStateSwitch = ({ tracker, diary }: Props) => {
	if (tracker.end_date !== null) {
		return <TrackerComplete />;
	}
	if (tracker?.tracker_complete) {
		// TODO - At some point I should allow some patients to mark their own trackers complete
		return <EntryReadyToReview canMarkComplete={false} />;
	}
	if (tracker?.next_entry?.is_locked === true) {
		return <EntryLocked tracker={tracker} diary={diary} />;
	}
	if (!tracker?.next_entry?.is_locked) {
		return <EntryDue tracker={tracker} diary={diary} />;
	}
	return <p>Something went wrong with this entry state</p>;
};

export default NewStateSwitch;
