import React from 'react';

const Back = () => {
    return (
        <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11 5C11 5.55228 10.5523 6 10 6L3.41421 6L5.70711 8.29289C6.09763 8.68342 6.09763 9.31658 5.70711 9.70711C5.31658 10.0976 4.68342 10.0976 4.29289 9.70711L0.292893 5.70711C-0.0976317 5.31658 -0.0976317 4.68342 0.292893 4.29289L4.29289 0.292893C4.68342 -0.0976314 5.31658 -0.0976313 5.70711 0.292893C6.09763 0.683417 6.09763 1.31658 5.70711 1.70711L3.41421 4L10 4C10.5523 4 11 4.44772 11 5Z"
                fill="black"
            />
        </svg>
    );
};

export default Back;
