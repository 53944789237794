import { services as s } from '@/services';
import { ManipulateType, TimingT } from '@/types/zod/timings';
import { create } from 'zustand';

import { generateTimeLabel } from '../services/generateTimeLabel';

const uuid1 = s.uuidv4();

const day1 = {
    is_autoname: true,
    is_infinite: false,
    time_unit: 'day' as ManipulateType,
    interval: 1,
    timing_id: uuid1,
    label: 'Day 1',
    repeats: 0,
    index: 1,
};

type Store = {
    times: TimingT[];
    setTimes: (times: TimingT[]) => void;
    updateTimes: (newTime: TimingT) => void;
    deleteTime: (time: TimingT) => void;
    addNewTime: () => void;
};

export const useScheduleStore = create<Store>((set) => ({
    times: [day1],
    setTimes: (times) => set({ times }),
    updateTimes: (newTime) =>
        set((state) => ({
            times: state.times.map((t) =>
                t.timing_id === newTime.timing_id ? { ...newTime, label: generateTimeLabel(newTime) } : t,
            ),
        })),
    deleteTime: (time) =>
        set((state) => ({
            times: state.times.filter((t) => t.timing_id !== time.timing_id),
        })),
    addNewTime: () =>
        set((state) => {
            const lastTime = state.times.slice(-1)[0];
            const newTimeId = s.uuidv4();
            if (state.times.length === 0) {
                return { times: [day1] };
            } else {
                const newLabel = generateTimeLabel({
                    interval: lastTime.interval + 1,
                    time_unit: lastTime.time_unit,
                    repeats: 0,
                });
                return {
                    times: [
                        ...state.times,
                        {
                            time_unit: lastTime.time_unit,
                            interval: lastTime.interval + 1,
                            timing_id: newTimeId,
                            label: newLabel,
                            repeats: 0,
                            is_autoname: true,
                            is_infinite: false,
                        },
                    ],
                };
            }
        }),
}));
