import { cn } from '../../lib/utils';
import type { MaybeStringT } from '../../types/zod/common';

interface Props {
	icon: React.ReactNode;
	words: React.ReactNode | MaybeStringT;
	className?: string;
}

const IconDetail = ({ icon, words, className }: Props) => {
	return (
		<div
			className={cn('text-md flex w-fit items-center gap-x-2 gap-y-1 text-slate-800 dark:text-slate-200', className)}
			style={{ flexWrap: 'nowrap' }}
		>
			<span className="flex-shrink-0 [&_svg]:h-6 [&_svg]:w-6">{icon}</span>
			<span className="flex flex-grow">{words ?? ''}</span>
		</div>
	);
};

export default IconDetail;
