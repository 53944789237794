import type { ChartData } from 'chart.js';

import type { EntryValueViewT } from '../../../types/database/entries';
import { type GenerateDataT, graphSettings, separateByTrackerId } from './generateData';

const getAverageNumberValue = (data: EntryValueViewT[]) => {
	if (!data) return null;
	const { sum, count } = data.reduce(
		(acc, item) => {
			if (item.number_value !== null) {
				acc.sum += item.number_value;
				acc.count += 1;
			}
			return acc;
		},
		{ sum: 0, count: 0 },
	);

	return count > 0 ? sum / count : null;
};

const getMaxNumberValue = (data: EntryValueViewT[]) => {
	if (!data) return null;

	return data.reduce((maxValue, item) => {
		if (item.number_value !== null && (maxValue === null || item.number_value > maxValue)) {
			return item.number_value;
		}
		return maxValue;
	}, null);
};
const getMinNumberValue = (data: EntryValueViewT[]) => {
	if (!data) return null;

	return data.reduce((minValue, item) => {
		if (item.number_value !== null && (minValue === null || item.number_value < minValue)) {
			return item.number_value;
		}
		return minValue;
	}, null);
};

type DataPointT = { x: string; y: number };
type MinMaxT = { min: DataPointT[]; max: DataPointT[]; average: DataPointT[] };

function getMinMaxAverage(data: EntryValueViewT[]): MinMaxT {
	const result: MinMaxT = {
		min: [],
		max: [],
		average: [],
	};
	if (data.length === 0) {
		return result;
	}
	data.forEach((trackerData) => {
		const date = trackerData[0].reference_time;
		const min = getMinNumberValue(trackerData);
		const max = getMaxNumberValue(trackerData);
		const average = getAverageNumberValue(trackerData);
		result.average.push({ x: date, y: average?.toFixed(1) ?? 0 });
		result.min.push({ x: date, y: min?.toFixed(1) ?? 0 });
		result.max.push({ x: date, y: max?.toFixed(1) ?? 0 });
	});

	return result;
}
const getMinMaxChartData = (data: MinMaxT): ChartData => {
	return {
		labels: data.map((e) => Object.keys(e)),
		datasets: { data: Object.values(data) },
	};
};
export const generateMinMaxData = ({ entryValues, inputType, lineSettings = {} }: GenerateDataT) => {
	const onlyGlucoseSliders = entryValues.filter((ev) => ev.input_key === inputType);
	const separated = separateByTrackerId(onlyGlucoseSliders);
	const data = getMinMaxAverage(separated);
	const datasets = {
		labels: ['Average', 'Minimum', 'Maximum'],
		datasets: [
			{
				data: data.min,
				label: 'Minimum',
				...graphSettings(3, lineSettings),
			},
			{
				data: data.max,
				label: 'Maximum',
				...graphSettings(2, lineSettings),
			},
			{
				label: 'Average',
				data: data.average,
				...graphSettings(1, lineSettings),
			},
		],
	};

	return datasets;
};
