import { colors } from '../colors';

const ClipboardWarning = () => {
	return (
		<svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect x="6.5" y="6.5" width="45" height="57" rx="2.5" fill="white" stroke="black" strokeWidth="3" />
			<circle cx="53.1459" cy="54.9762" r="12.5238" fill={colors['amber-400']} stroke="black" strokeWidth="3" />
			<path d="M53.4849 47.4553V55.6628" stroke="black" strokeWidth="3" strokeLinecap="round" />
			<circle cx="53.485" cy="60.7923" r="2.05185" fill="black" />
			<path
				d="M15.5 5.5H42.5V12C42.5 12.8284 41.8284 13.5 41 13.5H17C16.1716 13.5 15.5 12.8284 15.5 12V5.5Z"
				fill="black"
				stroke="black"
			/>
			<path d="M15 22H43" stroke="black" strokeWidth="3" strokeLinecap="round" />
			<path d="M15 31H43" stroke="black" strokeWidth="3" strokeLinecap="round" />
			<path d="M15 39H43" stroke="black" strokeWidth="3" strokeLinecap="round" />
			<path d="M15 48H35" stroke="black" strokeWidth="3" strokeLinecap="round" />
		</svg>
	);
};

export default ClipboardWarning;
