import { commonComponents as cc } from '@/components/common';
import { ui } from '@/components/ui';
import { OrganisationCheckboxes } from '@/features/new-diary/features/select-organisation/OrganisationCheckboxes';
import { zodResolver } from '@hookform/resolvers/zod';
import { createFileRoute } from '@tanstack/react-router';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';

const watchSchema = z.object({
	organisations: z.array(z.string()).refine((value) => value.some((item) => item), {
		message: 'You have to select at least one item.',
	}),
});

export type WatchSchemaType = z.infer<typeof watchSchema>;

const NewFootWatchDiary = () => {
	const form = useForm<WatchSchemaType>({
		resolver: zodResolver(watchSchema),
		defaultValues: {
			organisations: [],
		},
	});

	const onSubmit: SubmitHandler<WatchSchemaType> = async (formData) => {
		/* eslint-disable */ console.log(...oo_oo(`1991579809_26_8_26_29_4`, formData));
	};

	return (
		<section className="mx-auto my-8 max-w-md">
			<cc.Title words="Share Foot Watch Diary" />

			<ui.Form {...form}>
				<form onSubmit={form.handleSubmit(onSubmit)} className="form">
					{/* Organisation */}

					<OrganisationCheckboxes form={form} name="organisations"></OrganisationCheckboxes>

					<ui.Button type="submit">Create Diary</ui.Button>
				</form>
			</ui.Form>
		</section>
	);
};

export const Route = createFileRoute('/_app/foot-watch/new-diary/share')({
	component: NewFootWatchDiary,
	errorComponent: () => <p>Foot Watch Share Error</p>,
});
