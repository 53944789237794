import { ui } from "@/components/ui";
import AdminPageCardLink from './AdminPageCardLink';
import { useUpdateAudiencesMutation } from '../api/updateAudiences';

const UpdateAudiencesCard = () => {
    const { mutate: updateAudiences, isPending } = useUpdateAudiencesMutation();
    return (
        <AdminPageCardLink
            title="Update Audiences"
            description="Get the session of the current admin user"
            button={
                <ui.Button
                    size="md"
                    onClick={() =>updateAudiences()}
                >
                    {isPending?'Updating':'Trigger'}
                </ui.Button>
            }
        />
    );
};

export default UpdateAudiencesCard;
