import { useFormContext } from 'react-hook-form';
import { cn } from '../../lib/utils';
import { deSnakeCase } from '../../services/deSnakeCase';

const Errors = (className: { className?: string }) => {
	const form = useFormContext();
	return (
		<div>
			{form.formState.errors &&
				Object.entries(form.formState.errors).map(([fieldName, error]) => (
					<p
						className={cn('text-sm text-red-600 py-2 px-4 border-y border-red-300 bg-red-200', className)}
						key={fieldName}
					>
						{deSnakeCase(fieldName)}: {String(error?.message)}
					</p>
				))}
		</div>
	);
};

export default Errors;
