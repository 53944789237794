import { commonComponents as cc } from '@/components/common';
import useAuthQuery from '@/features/auth/api/useAuthQuery';
import exampleFeet from '@/features/foot-watch/assets/example-4-photos.png';
import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { useEffect } from 'react';

const StartFootCheck = () => {
	const { data: auth } = useAuthQuery();
	const { diaryId } = Route.useParams();
	const navigate = useNavigate();
	useEffect(() => window.scrollTo({ top: 0, behavior: 'smooth' }), []);
	return (
		<>
			<cc.Title
				words="Add New Photos"
				description=" Here, you take photos of the top and bottom of each foot as shown."
			/>

			<div className="mx-auto w-full flex-col px-2">
				<div className="mb-8 flex w-full items-center justify-center lg:mb-0">
					<img src={exampleFeet} alt="Example Check-in Photos" width="400" height="600" />
				</div>

				{auth?.role === 'patient' ? (
					<cc.FooterButtons
						func2={() => navigate({ to: '/foot-watch/$diaryId/new/add-photos', params: { diaryId: diaryId } })}
						words2="Upload"
						func1={() =>
							navigate({
								to: '/foot-watch/$diaryId/new/instructions/$angle/$side',
								params: { diaryId: diaryId, side: 'left', angle: 'top' },
							})
						}
						words1="Use Camera"
					/>
				) : (
					<cc.FooterButtons
						func1={() =>
							navigate({
								to: '/foot-watch/$diaryId/new/instructions/$angle/$side',
								params: { diaryId: diaryId, side: 'left', angle: 'top' },
							})
						}
						words1="Use Camera"
					/>
				)}
			</div>
		</>
	);
};

export default StartFootCheck;

export const Route = createFileRoute('/_app/foot-watch/$diaryId/new/start')({
	component: StartFootCheck,
});
