import { cn } from '@/lib/utils';
import type React from 'react';

const Description = ({ children, className }: { children: React.ReactNode; className?: string }) => {
	return (
		<p className={cn('text-md mt-4 text-slate-700 dark:text-slate-200 px-8 text-center', className)}>{children}</p>
	);
};

export default Description;
