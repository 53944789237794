import FaqBlock from '@/features/website/components/FaqBlock';
import Hero from '@/features/website/components/Hero';
import Section from '@/features/website/components/Section';
import SectionTitle from '@/features/website/components/SectionTitle';
import HeadlineStudy from '@/features/website/evidence/HeadlineStudy';
import PotentialSavings from '@/features/website/evidence/PotentialSavings';
import StudyCard from '@/features/website/evidence/StudyCard';
import { content } from '@/features/website/evidence/content/evidenceContent';
import { evidenceFAQs } from '@/features/website/evidence/content/evidenceFaqs';
import { createFileRoute } from '@tanstack/react-router';
import { useNavigate } from '@tanstack/react-router';
import { useRef } from 'react';

import AnimateInView from '../../components/ui/animate-in-view';

import type { EvidenceItem } from '@/features/website/evidence/content/evidenceContent';
const Evidence = () => {
	const navigate = useNavigate();
	const firstContentRef = useRef<HTMLDivElement>(null);
	return (
		<>
			<Section>
				<Hero
					title={<span>Driving Design and Demonstrating Value</span>}
					description="Our development pipeline is data driven from start to finish. We tackle demonstrable problems in the NHS to create products we can prove."
					caption="Evidence"
				/>
			</Section>

			<div ref={firstContentRef} />
			{/* <Section>
				<PotentialSavings />
			</Section> */}
			<Section>
				<SectionTitle
					caption="Headline Studies"
					title="Our Recent Work"
					description="The latest research to help you believe in us"
				/>

				<div className="flex flex-col lg:flex-row gap-4">
					{content.map((study: EvidenceItem) => {
						return (
							study.headline &&
							study?.heading && (
								<div key={study.title} className="w-full flex-auto lg:w-1/3">
									<AnimateInView>
										<HeadlineStudy
											publisher={study.publisher}
											title={study.heading}
											description={study.description}
											date={study.date}
											participants={study.participants}
											href={study.href}
											watch={study.watch}
										/>
									</AnimateInView>
								</div>
							)
						);
					})}
				</div>
			</Section>

			<Section>
				<SectionTitle
					caption="Work so Far"
					title="Evidence Database"
					description="A compiled database of all the evidence relevant to Patient Watch."
				/>
				{/* <div className="w-full flex flex-col lg:flex-row flex-wrap"> */}
				<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-4">
					{content
						.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
						.map((study: EvidenceItem) => {
							return (
								<div key={study.title} className="">
									<AnimateInView>
										<StudyCard
											title={study.title}
											description={study.description}
											date={study.date}
											href={study.href}
											watch={study.watch}
										/>
									</AnimateInView>
								</div>
							);
						})}
				</div>
			</Section>
			<Section>
				<FaqBlock FAQs={evidenceFAQs} />
			</Section>
		</>
	);
};

export const Route = createFileRoute('/_website/evidence')({
	component: Evidence,
});

export default Evidence;
