import { commonComponents as cc } from '@/components/common';
import { ui } from '@/components/ui';
import { useLogoutMutation } from '@/features/auth/api/useLogoutMutation';
import DeleteUserButton from '@/features/auth/components/DeleteUserButton';
import { profileComponents as pc } from '@/features/profile/components';
import {
	ClinicianNumberDialog,
	DateOfBirthDialog,
	EmailDialog,
	NameDialog,
	NhsNumberDialog,
	PhoneDialog,
} from '@/features/profile/components/EditProfileDialogs';
import type { ExtendedProfileT } from '@/types/database/profiles';
import { useAuth } from '@/utils/auth';
import { Link, createFileRoute } from '@tanstack/react-router';
import { Clipboard, Lock, LogOut } from 'lucide-react';
import DarkModeSwitch from '../../auth/components/DarkModeSwitch';
import SuperUserSwitch from '../../auth/components/SuperUserSwitch';
import PreferencesCard from '../../knock/components/PreferencesCard';

interface Props {
	profile: ExtendedProfileT;
}
const Profile = ({ profile }: Props) => {
	const auth = useAuth();
	const logout = useLogoutMutation();

	return (
		<>
			<cc.Group title="Contact Details">
				<NameDialog profile={profile} />
				<DateOfBirthDialog profile={profile} show={auth.roles?.isPatient} />
				<EmailDialog profile={profile} locked={true} />
				<PhoneDialog show={auth.roles?.isPatient} profile={profile} />
			</cc.Group>

			<cc.Group>
				<pc.OrganisationsRoles
					userId={auth.id as string}
					usersOrganisationsRoles={profile?.users_organisations_roles_view ?? []}
				/>
				<ui.Button className="flex mx-auto my-2" variant="link" asChild>
					<Link data-testid="add-organisation-link" to="/profile/organisation">
						Join Organisation
					</Link>
				</ui.Button>
			</cc.Group>

			<cc.Group show={auth.roles?.isClinician} title="Hospital Details">
				<ClinicianNumberDialog profile={profile} show={auth.roles?.isClinician} />
				<NhsNumberDialog profile={profile} show={false} />
			</cc.Group>

			<cc.Group show={auth.roles?.isClinician}>
				<pc.FavouriteUsers />
				<ui.Button className="flex mx-auto my-2" variant="link" asChild>
					<Link to="/invite">Add New User</Link>
				</ui.Button>
			</cc.Group>

			{auth.roles.isAdmin && (
				<cc.Group title="Build your Own">
					<div className="flex flex-col gap-y-4 my-4">
						<SuperUserSwitch isSuperUser={profile.is_super_user ?? false} />
						<DarkModeSwitch />
					</div>
				</cc.Group>
			)}

			<PreferencesCard />

			{/* <pc.SelectFont /> */}

			<cc.Group title="Other Actions">
				<a
					className="flex items-center gap-x-3 my-4 w-full font-medium text-xl cursor-pointer"
					href="https://xe123o0delx.typeform.com/to/kOZp90tY"
					target="_blank"
					rel="noreferrer"
				>
					<Clipboard size={32} strokeWidth={1.75} />
					Review
				</a>
				{/* <PWAPrompt /> */}
				<Link
					className="flex items-center gap-x-3 my-4 w-full font-medium text-xl cursor-pointer"
					to="/profile/change-password"
				>
					<Lock size={32} strokeWidth={1.75} />
					Change Password
				</Link>
				<button
					type="button"
					className="flex items-center gap-x-3 my-4 w-full font-medium text-xl cursor-pointer"
					onClick={() => logout.mutate()}
				>
					<LogOut size={32} strokeWidth={1.75} />
					Logout
				</button>
			</cc.Group>
			<DeleteUserButton />
		</>
	);
};
export const Route = createFileRoute('/_app/profile/')({
	component: Profile,
});

export default Profile;
