import { ui } from '@/components/ui';
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { cn } from '../../../../lib/utils';
import Description from '../../../common/Description';
import { Slider } from '../../../ui/slider';
import PainScaleKey from './PainScaleKey';
import { sliderFace } from './sliderFace';

interface Props {
	label?: string;
	name: string;
	disabled?: boolean;
}

const PainSlider = ({ label, name, disabled }: Props) => {
	const form = useFormContext();
	const { control, formState } = form;

	useEffect(() => {
		form.setValue(name, 5);
	}, [form.setValue, name]);

	const limitNumber = (textVal: string): number => {
		const numVal = Number(textVal);
		if (numVal > 10) return 10;
		if (numVal < 0) return 0;
		return numVal;
	};

	return (
		<ui.Card className="flex flex-col justify-center items-center gap-4 mx-auto px-0 w-full">
			<ui.CardHeader className="flex flex-col justify-center items-center px-4">
				<ui.Label
					className={cn(
						'font-normal text-2xl text-center text-slate-800',
						label?.length > 15 && 'text-xl py-2',
						label?.length > 25 && 'text-lg pt-2 pb-4',
					)}
				>
					{label ?? 'Pain Score'}
				</ui.Label>

				<PainScaleKey />
			</ui.CardHeader>
			<ui.CardContent className="flex flex-col gap-y-12 mb-8 w-full">
				<Controller
					name={name}
					control={control}
					render={({ field: { value, onChange } }) => (
						<>
							<ui.Input
								min={0}
								max={10}
								onChange={(e) => onChange(limitNumber(e.target.value))}
								value={Math.round(value).toString()}
								className="flex justify-center items-center m-auto p-auto w-[70px] h-[70px] text-4xl text-center [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
								type="number"
								disabled={disabled}
							/>
							<Slider
								value={[value]}
								onValueChange={(val) => onChange(val[0])}
								name={name}
								disabled={disabled}
								min={0}
								max={10}
								step={1}
								thumb={sliderFace(value)?.icon}
								thumbClassName="w-12 h-12 leading-12 text-center z-10 border-0 ring-0 flex items-center justify-center rounded-full cursor-grab"
							/>
							<Description>{sliderFace(value)?.message}</Description>
						</>
					)}
				/>
			</ui.CardContent>
			<ui.CardFooter>
				{formState.errors[name] && (
					<p className="text-red-500">{(formState.errors[name]?.message as string) ?? 'Please select a value.'}</p>
				)}
			</ui.CardFooter>
		</ui.Card>
	);
};

export default PainSlider;
