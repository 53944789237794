import { VariationsType } from '../services/variations';
import { ImagesT } from './useImagesStore';
import { URLtoVariation } from './useImagesStore';

export const nextImage = (images: ImagesT, nextURL: string) => {
    // Crazy method to skip the current image when deciding if all the images are checked and the user can be navigated to the upload page

    // Get the current image
    const current = window.location.href.split('/')[4];
    const variation: VariationsType = URLtoVariation[current];

    // Remove current variation from the images array
    const imagesExcept = images;
    delete imagesExcept[variation];

    // Here we check if all the images except the current one are checked
    const allChecked = Object.values(imagesExcept)?.every((value) => value?.checked === true);

    if (allChecked) {
        return '/check-in/upload/review';
    } else if (nextURL) {
        // If not all checked just navigate to the given next URL
        return nextURL;
    } else {
        return '/check-in/upload/review';
    }
};
