import type { SchemaT } from '@/routes/_auth/delete-account';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'sonner';
import { supabaseClient } from '../../../lib/supabase';

const deleteRequest = async (req: SchemaT) => {
	const { data, error } = await supabaseClient.functions.invoke('public-email', {
		body: {
			to_email: 'support@patient-watch.com',
			cc_email: req.email,
			subject: 'DELETE REQUEST IMPORTANT',
			body: req.reason,
			pretty: true,
		},
	});
	if (error) throw error;
	return data;
};

export const useDeleteRequest = () => {
	return useMutation({
		mutationKey: ['delete-request'],
		mutationFn: (formData: SchemaT) => deleteRequest(formData),
		onError: (e) => {
			toast.error(e.message);
		},
		onSuccess: (mutation) => {
			toast.info('Deletion Request Sent');
			!!mutation?.onSuccess && mutation.onSuccess();
		},
	});
};
