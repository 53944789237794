import { supabaseClient } from '@/lib/supabase';
import { queryOptions, useSuspenseQuery } from '@tanstack/react-query';

export async function selectInjectionMethods() {
	const { data, error } = await supabaseClient.from('injection_methods').select('*').throwOnError();
	if (error) throw error;
	return data;
}

export const injectionMethodsQueryOptions = () => {
	return queryOptions({
		queryKey: ['injection-methods'],
		queryFn: () => selectInjectionMethods(),
	});
};

export const useInjectionMethodsQuery = () => {
	const options = injectionMethodsQueryOptions();
	const query = useSuspenseQuery(options);
	return query;
};
