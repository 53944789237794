import { useNav } from '@/utils/nav';
import useAuthQuery from '../features/auth/api/useAuthQuery';
import useIsDashboard from '../features/dashboard/hooks/useIsDashboard';
import useLocalStorage from './useLocalStorage';
import usePrint from './usePrint';
export const hooks = {
	useLocalStorage,
	usePrint,
	useAuthQuery,
	useNav,
	useIsDashboard,
};
