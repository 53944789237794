interface Props {
	show: boolean;
}

const HideShow = ({ show }: Props) => {
	return (
		<>
			{show ? (
				<svg
					className="h-full w-full"
					width="30"
					height="30"
					viewBox="0 0 30 30"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M17.853 11.605C18.8769 11.9891 19.8476 12.5559 20.7163 13.3045L22.7211 15.033C22.9516 15.2315 22.9526 15.5878 22.7241 15.788L21.4052 16.9414C17.5366 20.3259 11.7861 20.4119 7.81782 17.1446L6.18208 15.7978C5.93794 15.5969 5.9394 15.2227 6.18501 15.0235L8.52778 13.1227C9.27241 12.5185 10.0849 12.0437 10.9365 11.6977C10.6479 12.2449 10.4848 12.8684 10.4848 13.5299C10.4848 15.7032 12.2465 17.4649 14.4199 17.4649C16.5932 17.4649 18.3549 15.7032 18.3549 13.5299C18.3549 12.8307 18.1723 12.1741 17.853 11.605ZM21.3691 12.5471C17.519 9.22834 11.8452 9.14372 7.8979 12.3461L5.55512 14.247C4.81831 14.8445 4.8144 15.967 5.54682 16.5698L7.18257 17.9166C11.5288 21.4951 17.8266 21.4009 22.0639 17.6941L23.3823 16.5407C24.0688 15.9401 24.0649 14.871 23.374 14.2756L21.3691 12.5471Z"
						fill="black"
					/>
				</svg>
			) : (
				<svg
					className="h-full w-full"
					width="30"
					height="30"
					viewBox="0 0 30 30"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M17.9331 12.1402C18.957 12.5243 19.9277 13.091 20.7959 13.8396L22.8013 15.568C23.0317 15.7665 23.0327 16.1229 22.8042 16.3231L21.4854 17.4765C17.6167 20.861 11.8662 20.947 7.89796 17.6797L6.26222 16.3329C6.01808 16.1319 6.01954 15.7578 6.26515 15.5586L8.60792 13.6578C9.35255 13.0536 10.165 12.5787 11.0166 12.2327C10.728 12.7799 10.565 13.4034 10.565 14.065C10.565 16.2382 12.3267 18 14.5 18C16.6734 18 18.4351 16.2382 18.4351 14.065C18.4351 13.3658 18.2525 12.7092 17.9331 12.1402ZM21.4492 13.0821C17.5991 9.76341 11.9248 9.67878 7.97804 12.8812L5.63526 14.7821C4.89845 15.3796 4.89454 16.5021 5.62648 17.1049L7.26222 18.4517C11.6089 22.0302 17.9067 21.936 22.1441 18.2291L23.4624 17.0757C24.1489 16.4752 24.145 15.4061 23.4541 14.8106L21.4492 13.0821Z"
						fill="black"
					/>
					<path d="M7.5 9L23.5 21" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
					<path d="M6.5 10L22.5 22" stroke="black" strokeWidth="1.5" strokeLinecap="round" />
				</svg>
			)}
		</>
	);
};

export default HideShow;
