import Title from "@/components/common/Title";
import { selectCliniciansStatisticsQueryOptions } from "@/features/admin/api/selectCliniciansStatistics";
import CreatedDiairesSparkChart from "@/features/charts/clinician-statistics/CreatedDiairesSparkChart";
import DiariesByTabSparkChart from "@/features/charts/clinician-statistics/DiariesByTabSparkChart";
import { selectProfileByIdQueryOptions } from "@/features/profile/api/selectProfile";
import { createFileRoute } from "@tanstack/react-router";
import { sortBy } from "underscore";
import { ui } from "@/components/ui";
import { selectDiariesViewQueryOptions } from "@/features/diaries/api/selectDiariesView";
const StatisticsPage = () => {
  const { cliniciansStats, cliniciansProfiles, diaries } =
    Route.useLoaderData();

  return (
    <div className="lg-width">
      <Title words="Clinicians Statistics" />
      <section className="flex flex-col md:flex-row gap-4">
        <ui.Card className="w-full">
          <ui.CardHeader>
            <ui.CardTitle>Created by You</ui.CardTitle>
          </ui.CardHeader>
          <ui.CardContent className="flex flex-col items-center">
            <CreatedDiairesSparkChart
              cliniciansStats={sortBy(cliniciansStats, "created_at").filter(
                (cs) => cs.clinician_id === cliniciansProfiles.user_id,
              )}
            />
          </ui.CardContent>
        </ui.Card>
        <ui.Card className="w-full">
          <ui.CardHeader>
            <ui.CardTitle>Managed by You</ui.CardTitle>
          </ui.CardHeader>
          <ui.CardContent className="flex flex-col items-center">
            <DiariesByTabSparkChart
              diaryBreakdown={sortBy(cliniciansStats, "created_at")}
            />
          </ui.CardContent>
        </ui.Card>
      </section>
    </div>
  );
};

export const Route = createFileRoute("/_app/statistics")({
  component: StatisticsPage,
  loader: async ({ context }) => {
    const cliniciansStats = await context?.queryClient.ensureQueryData(
      selectCliniciansStatisticsQueryOptions(),
    );
    const cliniciansProfiles = await context?.queryClient.ensureQueryData(
      selectProfileByIdQueryOptions(context.auth.id),
    );
    const diaries = await context.queryClient.ensureQueryData(
      selectDiariesViewQueryOptions(),
    );

    return { cliniciansStats, cliniciansProfiles, diaries };
  },
});
