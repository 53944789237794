interface Props {
    fill?: string;
    stroke?: string;
    active?: boolean;
    className?: string;
}
const Label = ({ stroke, fill, active, className }: Props) => {
    return (
        <svg
            className={`${className}`}
            width="70"
            height="70"
            viewBox="0 0 70 70"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M57.588 31.3802L57.8711 18.3693C57.9206 16.0924 56.0619 14.2337 53.785 14.2832L40.7741 14.5663C39.7436 14.5887 38.7615 15.008 38.0326 15.7369L14.8284 38.9411C13.2663 40.5032 13.2663 43.0359 14.8284 44.598L27.5563 57.3259C29.1184 58.888 31.6511 58.888 33.2132 57.3259L56.4174 34.1217C57.1463 33.3928 57.5656 32.4107 57.588 31.3802Z"
                fill={fill ? fill : 'white'}
                stroke={stroke ? stroke : 'black'}
                strokeWidth="3"
            />
            <circle
                cx="50.8909"
                cy="21.2635"
                r="3"
                transform="rotate(45 50.8909 21.2635)"
                fill="white"
                stroke={active ? 'white' : stroke ? stroke : 'black'}
                strokeWidth="1.5"
            />
        </svg>
    );
};

export default Label;
