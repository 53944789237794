import { z } from "zod";

export const DiaryInputZ = z.object({
  diary_input_id: z.string(),
  diary_id: z.boolean(),
  input_id: z.string(),
  page_index: z.number(),
  order_index: z.number(),
  page_id: z.string().uuid(),
});

export type DiaryInputT = z.infer<typeof DiaryInputZ>;
