// hooks/useTrackerUpdate.ts
import { useUpdateTracker } from '@/features/trackers/api/updateTracker';
import type { TrackerOverviewFormSchemaT } from '../components/TrackerOverviewForm';
export const useTrackerUpdate = (diaryId: string, trackerId: string) => {
	const updateTracker = useUpdateTracker({ diaryId });

	const updateStartDate = async (date: string | null) => {
		await updateTracker.mutate({
			tracker_id: trackerId,
			start_date: date,
		});
	};

	const updateReferenceDate = async (date: string | null) => {
		await updateTracker.mutate({
			tracker_id: trackerId,
			reference_date: date,
		});
	};

	const updateOverview = async ({ start_date, reference_date, contact_id }: TrackerOverviewFormSchemaT) => {
		await updateTracker.mutate({
			tracker_id: trackerId,
			start_date: start_date,
			reference_date: reference_date,
			contact_id: contact_id,
		});
	};

	return { updateStartDate, updateReferenceDate, updateOverview };
};
