import { ui } from '@/components/ui';
import { toast } from '@/components/ui/use-toast';
import useSetRoleMutation from '@/features/auth/hooks/useSetRoleMutation';
import { zodResolver } from '@hookform/resolvers/zod';
import { createFileRoute } from '@tanstack/react-router';
import { useForm } from 'react-hook-form';
import * as z from 'zod';

const FormSchema = z.object({
	role: z.enum(['PATIENT', 'CLINICIAN'], {
		required_error: 'You need to select a notification type.',
	}),
});

export function Role() {
	const form = useForm<z.infer<typeof FormSchema>>({
		resolver: zodResolver(FormSchema),
	});

	const setRoleMutation = useSetRoleMutation();

	function onSubmit(data: z.infer<typeof FormSchema>) {
		setRoleMutation.mutate(data);
		toast({
			title: 'You submitted the following values:',
			description: (
				<pre className="mt-2 w-[340px] rounded-md bg-slate-950 p-4">
					<code className="text-white">{JSON.stringify(data, null, 2)}</code>
				</pre>
			),
		});
	}

	return (
		<ui.Form {...form}>
			<form onSubmit={form.handleSubmit(onSubmit)} className="mx-auto space-y-6 py-6">
				<ui.FormField
					control={form.control}
					name="role"
					render={({ field }) => (
						<ui.FormItem className="space-y-3 ">
							<ui.FormLabel>Sign up as a</ui.FormLabel>
							<ui.FormControl>
								<ui.RadioGroup
									onValueChange={field.onChange}
									defaultValue={field.value}
									className="flex flex-col space-y-1"
								>
									<ui.FormItem className="flex items-center space-x-3 space-y-0 rounded-md border border-indigo-100 bg-white p-4 text-lg">
										<ui.FormControl>
											<ui.RadioGroupItem value="PATIENT" />
										</ui.FormControl>
										<ui.FormLabel className="font-normal">Patient</ui.FormLabel>
									</ui.FormItem>
									<ui.FormItem className="flex items-center space-x-3 space-y-0 rounded-md border-indigo-100 bg-white p-4 text-lg">
										<ui.FormControl>
											<ui.RadioGroupItem value="CLINICIAN" />
										</ui.FormControl>
										<ui.FormLabel className="font-normal">Clinician</ui.FormLabel>
									</ui.FormItem>
								</ui.RadioGroup>
							</ui.FormControl>
							<ui.FormMessage />
						</ui.FormItem>
					)}
				/>
				<ui.Button type="submit">Submit</ui.Button>
			</form>
		</ui.Form>
	);
	createFileRoute;
}
createFileRoute;
export const Route = createFileRoute('/_app/role')({
	component: Role,
});
