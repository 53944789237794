import type { DashboardSearchT } from '../../../routes/_app/dashboard';
import type { Auth } from '../../../utils/auth';

export const prepDiariesData = (settings: DashboardSearchT, auth?: Auth) => {
	if (!settings?.tab) throw new Error('Tab is required');

	if (!auth) throw new Error('Not logged in');

	return {
		...settings,
		diary_access: settings?.diary_access ?? undefined,
		user_id: settings?.diaries === 'mine' ? auth.id : undefined,
	};
};
