import { z } from 'zod';

import { TokenZ } from '../../../types/zod/auth';

const baseVerifySchema = z.object({
	token: TokenZ,
});

export const VerifySchemaWithoutEmailZ = baseVerifySchema;

export const EmailSchemaZ = baseVerifySchema.extend({
	email: z.string().email('Invalid email').min(1, 'Email is required'),
});

export type VerifySchemaWithoutEmailT = z.infer<typeof VerifySchemaWithoutEmailZ>;
export type EmailSchemaT = z.infer<typeof EmailSchemaZ>;
