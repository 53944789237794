import { ExtendedInjectionViewT, ExtendedTrackerViewT, ManagementTypeT } from '../../../types/database/trackers';

export const checkIfMissingManagement = (managementType: ManagementTypeT, tracker: ExtendedTrackerViewT): boolean => {
	switch (managementType) {
		case 'injection':
			return checkIfInjection(tracker.injections_view);
		case 'surgery':
			return checkIfSurgery();
		default:
			return false;
	}
};

const checkIfInjection = (injections: ExtendedInjectionViewT[]): boolean => {
	// Check Injection
	if (injections.length === 0) return true;
	for (const i of injections) {
		if (i.injection_method_id === null || i.injecting_clinician_id === null) return true;

		// Check Injection Details
		if (i.injection_details_view.length === 0) return true;
		for (const id of i.injection_details_view) {
			if (!id.injection_location || !id.injection_content) return true;
		}
	}
	return false; // Ensure a boolean is returned in all cases
};

const checkIfSurgery = () => {
	return true;
};
