import ExistingInjections from './ExistingInjections';
import InjectionContent from './InjectionContent';
import InjectionDetail from './InjectionDetail';
import InjectionDetailCard from './InjectionDetailCard';
import InjectionDetailsForm from './InjectionDetailsForm';
import InjectionForm from './InjectionForm';
import InjectionLink from './InjectionLink';
import InjectionLinks from './InjectionLinks';
import InjectionLocation from './InjectionLocation';
import InjectionsGroup from './InjectionsGroup';

export const injectionComponents = {
	ExistingInjections,
	InjectionContent,
	InjectionDetailsForm,
	InjectionDetailCard,
	InjectionDetail,
	InjectionsGroup,
	InjectionForm,
	InjectionLocation,
	InjectionLink,
	InjectionLinks,
};
