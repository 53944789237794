import { supabaseClient } from '@/lib/supabase';
import { useMutation } from '@tanstack/react-query';
import { useNavigate, useSearch } from '@tanstack/react-router';
import { toast } from 'sonner';

interface Props {
	imagePath: string;
	footLogId: string;
}
const createFootImage = async (imagePath: string, footLogId: string) => {
	return await supabaseClient
		.from('foot_images')
		.insert({
			image_path: imagePath,
			foot_entry_id: footLogId,
		})
		.select()
		.throwOnError();
};

const useCreateImageLabelMutation = () => {
	const { imageLabels } = useSearch({ strict: false });
	const navigate = useNavigate();
	return useMutation({
		mutationKey: ['createImageLabel'],
		mutationFn: async (data: Props) => {
			createFootImage(data.imagePath, data.footLogId);
		},
		onError: (error) => {
			console.log(error);
			toast.error(error.message);
		},
		onSuccess: () => {
			toast.success('Successfully created image label');
			navigate({ search: { imageLabels: Number.parseInt(imageLabels) + 1 } });
			return 'ok';
		},
	});
};
export { useCreateImageLabelMutation };
export default useCreateImageLabelMutation;
