import { Table, TableBody, TableCaption, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';

function usersStatisticsTable({ usersStatistics }) {
	return (
		<Table>
			<TableCaption>A list of users statistics.</TableCaption>
			<TableHeader>
				<TableRow>
					<TableHead className="w-1/2">User</TableHead>
					<TableHead className="w-1/2">Number of Diaries</TableHead>
				</TableRow>
			</TableHeader>
			<TableBody>
				{usersStatistics?.map((us) => (
					<TableRow key={us.user_id}>
						<TableCell>{us.name}</TableCell>
						<TableCell>{us.number_of_diaries}</TableCell>
					</TableRow>
				))}
			</TableBody>
		</Table>
	);
}
export default usersStatisticsTable;
