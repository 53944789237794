import { cn } from '@/lib/utils';
import { warningColors } from '../../utils/warningColors';

interface Props {
	label: string | React.ReactNode;
	info: string | React.ReactNode;
	description?: string;
	noInfo?: boolean;
}

const RequiredInfo = ({ label, info, description, noInfo = false }: Props) => {
	const booleanToString = (value: boolean) => (value ? 'Yes' : 'No');

	const displayInfo =
		info === null || info === undefined || info === ''
			? 'Information Missing'
			: typeof info === 'boolean'
				? booleanToString(info)
				: info;

	const isInfoMissing = !info || noInfo;
	return (
		<div className="flex flex-col mb-4 max-w-[calc(100%-80px)]">
			<p className={cn('w-full p-0 text-left text-sm font-semibold', warningColors(isInfoMissing))}>{label}</p>
			{description && <p className="text-left text-slate-600 text-sm dark:text-slate-300">{description}</p>}
			<p className={cn('w-full p-0 text-left text-lg', warningColors(isInfoMissing))}>{displayInfo}</p>
		</div>
	);
};

export default RequiredInfo;
