// TODO - Add Hospital Number - NON OPTIONAL FOR NHS
import { commonComponents as cc } from '@/components/common';
import { ui } from '@/components/ui';
import { patientsQueryOptions } from '@/features/new-diary/api/selectPatients';
import SelectPatientForm from '@/features/new-diary/features/invite-patient/SelectPatientForm';
import { Route as InviteRoute } from '@/routes/_app/new-diary/patient/invite';
import { useAuth } from '@/utils/auth';
import { CatchBoundary, Link, createFileRoute } from '@tanstack/react-router';

const SelectPatientPage = () => {
	const auth = useAuth();
	const search = Route.useSearch();

	return (
		<section className="md-width">
			<cc.Title words="New Diary" />
			<SelectPatientForm organisation={search?.organisation} diary_route={search?.diary_route} />

			{auth?.roles?.isClinician && (
				<CatchBoundary getResetKey={() => 'select-patient-form-error'}>
					<>
						<cc.Or />
						<ui.Button asChild type="button" tabIndex={0} size="lg" variant="secondary">
							<Link search={search} to={InviteRoute?.to} className="w-full">
								Add New Patient
							</Link>
						</ui.Button>
					</>
				</CatchBoundary>
			)}
		</section>
	);
};

import { z } from 'zod';

export const NewDiaryRouteSearchZ = z.object({
	organisation: z.object({ organisation_id: z.string().uuid(), organisation_name: z.string() }),
	diary_route: z.string().min(1, 'Required'),
});

export type NewDiaryRouteSearchT = z.infer<typeof NewDiaryRouteSearchZ>;

export const Route = createFileRoute('/_app/new-diary/patient/select')({
	component: SelectPatientPage,
	loader: async ({ context }) => {
		await context?.queryClient.ensureQueryData(patientsQueryOptions());
	},
	validateSearch: (search) => NewDiaryRouteSearchZ.parse(search),
});
