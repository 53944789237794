import { ui } from '@/components/ui';
import { selectFormsQueryOptions } from '@/features/forms/api/selectForms';
import { services as s } from '@/services';
import { Link, createFileRoute } from '@tanstack/react-router';
import CardGrid from '../../../components/common/CardGrid';
import FormCard from '../../../features/forms/components/FormCard';
import type { FormViewT } from '../../../types/database/forms';

const FormTemplatesPage = () => {
	const { forms } = Route.useLoaderData();

	const renderForm = (f) => (
		<FormCard
			link={
				<Link
					to="/builder/form/$formId/page-input/$pageInputId/input/$inputId/page/$pageIndex"
					params={{
						formId: f.form_id,
						pageInputId: f.pages[0]?.inputs[0]?.input_id,
						inputId: f.pages[0]?.inputs[0]?.input_id,
						pageIndex: '1',
					}}
				>
					Edit
				</Link>
			}
			link2={
				<Link
					to="/public/form/$formId/page/$pageIndex"
					target="_blank"
					params={{
						formId: f.form_id,
						pageIndex: '1',
					}}
				>
					Preview
				</Link>
			}
			form={f}
		/>
	);

	return (
		<CardGrid items={forms} renderItem={renderForm} />
		// <ul className="flex lg:flex-row flex-col flex-wrap p-4">
		// 	{forms?.map((f) => (
		// 		<li key={f.form_id} className="p-2 lg:w-1/3">
		// 			<ui.Card>
		// 				<ui.CardHeader>
		// 					<ui.CardTitle>{f.form_name}</ui.CardTitle>
		// 					<ui.CardDescription>{f.form_description}</ui.CardDescription>
		// 				</ui.CardHeader>
		// 				<ui.CardContent>
		// 					{f.pages.map((p) => (
		// 						<div key={p.page_id}>
		// 							{p.page_name}
		// 							{p.inputs?.map((pi) => (
		// 								<p>{pi.label}</p>
		// 							))}
		// 						</div>
		// 					))}
		// 				</ui.CardContent>
		// 				<ui.CardFooter className="flex gap-2">
		// 					<ui.Button size="sm" asChild>
		// 						<Link to="/form/$formId/entry/$entryId/page/1" params={{ formId: f.form_id, entryId: s.uuidv4() }}>
		// 							Open
		// 						</Link>
		// 					</ui.Button>
		// 					<ui.Button size="sm" variant="secondary" asChild>
		// 						<Link
		// 							to="/builder/form/$formId/page-input/$pageInputId/input/$inputId/page/$pageIndex"
		// 							params={{
		// 								formId: f.form_id,
		// 								pageInputId: f.pages[0]?.inputs[0]?.input_id,
		// 								inputId: f.pages[0]?.inputs[0]?.input_id,
		// 								pageIndex: 1,
		// 							}}
		// 						>
		// 							Edit
		// 						</Link>
		// 					</ui.Button>
		// 				</ui.CardFooter>
		// 			</ui.Card>
		// 		</li>
		// 	))}
		// </ul>
	);
};

export const Route = createFileRoute('/_app/builder/forms')({
	component: FormTemplatesPage,
	loader: async ({ context }) => {
		const forms = (await context?.queryClient.ensureQueryData(selectFormsQueryOptions())) as FormViewT[];
		return { forms };
	},
});
