import { commonComponents as cc } from "@/components/common";
import { ui } from "@/components/ui";
import { InputTypeZ } from "@/definitions/inputs";
import { useDiaryTemplateByIdQuery } from "@/features/builder/api/selectDiaryTemplateById";
import { inputComponents as ic } from "@/features/inputs/components";
import { sortInputs } from "@/features/inputs/services/sortInputs";
import { hooks as h } from "@/hooks";
import { Route as TemplatesRoute } from "@/routes/_app/builder/diary-templates";
import { services as s } from "@/services";
import type { ExtendedDiaryTemplateT } from "@/types/database/diaries";
import { Link, createFileRoute } from "@tanstack/react-router";
import { ExternalLink } from "lucide-react";
import React from "react";
import { z } from "zod";

const InputInputId = () => {
  const { inputId, diaryTemplateId } = Route.useParams();
  const { input } = Route.useSearch();
  const { data: template } = useDiaryTemplateByIdQuery(diaryTemplateId);
  h.useNav([
    {
      label: "Templates",
      route: { to: TemplatesRoute.to },
    },
    {
      label: "Form Item",
      options: sortInputs(template?.diary_inputs)?.map(
        (t: ExtendedDiaryTemplateT) => ({
          label: t.inputs?.label,
          route: {
            to: `/templates/diary-template/${t.diary_template_id}/input/${t.input_id}`,
          },
        }),
      ),
    },
  ]);

  const [value, setValue] = React.useState();

  return (
    <section className="flex w-full flex-col gap-4 px-2">
      {/* <Outlet /> */}
      <div>
        <div className="flex flex-row items-center justify-between">
          <cc.Subtitle words="Preview" />
          <ui.Button variant="link">
            <Link
              target="_blank"
              to={`/diary/${s.uuidv4()}/${input.input_key}/entry/${s.uuidv4()}/page/1`}
            >
              <ExternalLink />
            </Link>
            Open Full
          </ui.Button>
        </div>
        <ic.InputSwitch setValue={setValue} input={input} />
      </div>
      <div>
        <cc.Subtitle words="Settings" />
        <ic.InputConfig input={input} />
      </div>
    </section>
  );
};

export const LabelZ = z.string().nullable();
export const MaxValueZ = z.number().default(10).nullable();
export const MinValueZ = z.number().default(0).nullable();
export const MaxLengthZ = z.number().nullable();
export const OptionsZ = z.array(z.string()).nullable();

export const InputZ = z.object({
  input_key: InputTypeZ,
  label: LabelZ,
  max_value: MaxValueZ,
  min_value: MinValueZ,
  max_length: MaxLengthZ,
  options: OptionsZ,
});

export const InputSchemaZ = z.object({
  input: InputZ,
});

export type InputSchemaT = z.infer<typeof InputSchemaZ>;

export const Route = createFileRoute(
  "/_app/builder/diary-templates/$diaryTemplateId/input/$inputId/page/$pageId/",
)({
  component: InputInputId,
  validateSearch: (search) => InputSchemaZ.parse(search),
});
