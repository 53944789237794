import { Button } from '@/components/ui/button';
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem } from '@/components/ui/command';
import { FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { cn } from '@/lib/utils';
import { CheckIcon, ChevronsUpDown, Lock } from 'lucide-react';

import type { OptionT } from './Select';

interface Props {
	label: React.ReactNode;
	options: OptionT[];
	form: any;
	disabled: boolean;
	description?: string;
	attribute: string;
	placeholder: string;
}

export function ProfileCombobox({ label, attribute, description, placeholder, disabled, options, form }: Props) {
	if (options?.length === 0) {
		return <p>No Options</p>;
	}

	return (
		<FormField
			control={form.control}
			name={attribute}
			render={({ field }) => (
				<FormItem className="flex flex-col">
					<FormLabel>{label}</FormLabel>
					<Popover>
						<PopoverTrigger disabled={disabled} asChild>
							<FormControl>
								<Button
									variant="outline"
									role="combobox"
									className={cn(
										'h-fit w-full justify-between text-left font-normal text-sm pl-3',
										!field.value && 'text-muted-foreground',
									)}
								>
									{field.value ? options.find((o) => o.value === field.value)?.label : placeholder}
									{disabled ? (
										<Lock className="opacity-50 ml-2 w-4 h-4 shrink-0" />
									) : (
										<ChevronsUpDown className="opacity-50 ml-2 w-4 h-4 shrink-0" />
									)}
								</Button>
							</FormControl>
						</PopoverTrigger>
						<PopoverContent className="p-0 w-full">
							<Command>
								<CommandInput placeholder={placeholder} className="h-9" />
								<CommandEmpty>No results found.</CommandEmpty>
								<CommandGroup>
									{options.map((o) => (
										<CommandItem
											value={o.label}
											key={o.value}
											onSelect={() => {
												form.setValue(`${attribute}`, o.value);
											}}
										>
											{o.label}
											<CheckIcon
												className={cn('ml-auto h-4 w-4', o.value === field.value ? 'opacity-100' : 'opacity-0')}
											/>
										</CommandItem>
									))}
								</CommandGroup>
							</Command>
						</PopoverContent>
					</Popover>
					{description ?? <FormDescription>{description}</FormDescription>}
					<FormMessage />
				</FormItem>
			)}
		/>
	);
}

export default ProfileCombobox;
