import { usersOrganisationsRolesQueryOptions } from '@/features/auth/api/selectUsersOrganisationsRoles';
import Nav from '@/features/navigation/components/Navigation';
import { Outlet, createFileRoute } from '@tanstack/react-router';
import useAuthQuery from '../features/auth/api/useAuthQuery';
import useSkipOnboarding from '../features/auth/hooks/useSkipOnboarding';
import { useAuth } from '../utils/auth';

export const Route = createFileRoute('/onboarding')({
	component: LayoutComponent,
	loader: async ({ context }) => {
		const roles = context?.auth?.id
			? await context?.queryClient?.ensureQueryData(usersOrganisationsRolesQueryOptions(context.auth.id))
			: [];
		if (roles) {
			context?.auth?.setRoles(roles);
		}
	},
});

import { ui } from '../components/ui';

function LayoutComponent() {
	const auth = useAuth();
	const { refetch } = useAuthQuery();
	// useSkipOnboarding();

	return (
		<main className="pb-2 min-h-screen">
			<Nav app={true} />
			<section className="flex flex-col justify-center items-center mx-auto px-2 lg:px-0 max-w-lg">
				{auth?.isAuthenticated ? (
					<Outlet />
				) : (
					<ui.Card>
						<ui.CardHeader>
							<ui.CardTitle>Begin Onboarding</ui.CardTitle>
							<ui.CardDescription>Please click continue to add your details.</ui.CardDescription>
						</ui.CardHeader>
						<ui.CardFooter>
							<ui.Button onClick={() => refetch()}>Continue</ui.Button>
						</ui.CardFooter>
					</ui.Card>
				)}
			</section>
		</main>
	);
}
