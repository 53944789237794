import { commonComponents as cc } from '@/components/common';

import { useDeleteEventMutation } from '../api/deleteEvent';

interface Props {
	eventId: string;
	diaryId: string;
}

const DeleteEventButton = (props: Props) => {
	const deleteEvent = useDeleteEventMutation(props.diaryId);
	return <cc.DeleteButton variant="destructive-secondary" deleteFunction={() => deleteEvent.mutate(props.eventId)} />;
};

export default DeleteEventButton;
