import { Route as NewFormRoute } from '@/routes/_app/new-diary/patient/$patientId/organisation/$organisationId/diary/$diaryId/tracker/$trackerId/tracker-templates';
import type { StatefulDiaryT } from '@/types/database/diaries';
import { Link } from '@tanstack/react-router';
import { Clipboard } from 'lucide-react';

import type { ButtonSize, ButtonVariant } from '@/components/ui/button';
import { buttonVariants } from '@/components/ui/button';
import { cn } from '@/lib/utils';

interface Props {
	diary: StatefulDiaryT;
	variant?: ButtonVariant;
	size?: ButtonSize;
	className?: string;
}

const NewTrackerLink = ({ diary, variant, size, className }: Props) => {
	return (
		<Link
			className={cn(buttonVariants({ size: size ?? 'lg', variant: variant ?? 'secondary' }), className)}
			to={NewFormRoute.to}
			params={{ diaryId: diary.diary_id, patientId: diary.patient_id, organisation_id: diary.organisation_id }}
		>
			<Clipboard />
			Add Form
		</Link>
	);
};

export default NewTrackerLink;
