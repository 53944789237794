import { ui } from '@/components/ui';
import { Button } from '@/components/ui/button';
import { useAuth } from '@/utils/auth';
import { Menu } from 'lucide-react';

import { useLogoutMutation } from '../../auth/api/useLogoutMutation';
import MobileNavigationApp from '../app/MobileNavigation';
import WebsiteNavigationMobile from '../website/MobileNavigation';

interface Props {
	app: boolean;
}

export function Burger(props: Props) {
	const auth = useAuth();
	const logout = useLogoutMutation();

	return (
		<ui.Sheet>
			<ui.SheetTrigger asChild>
				<Button className="px-4 w-fit text-md" variant="outline">
					<Menu size={20} />
					Menu
				</Button>
			</ui.SheetTrigger>
			<ui.SheetContent>
				{props.app ? <MobileNavigationApp /> : <WebsiteNavigationMobile />}

				<ui.SheetFooter className="gap-4 md:gap-2 mt-8">
					{auth.isAuthenticated && (
						<ui.SheetClose>
							<Button onClick={() => logout.mutate()} variant="outline" className="w-full">
								Logout
							</Button>
						</ui.SheetClose>
					)}
					<ui.SheetClose>
						<Button variant="outline" className="w-full">
							Close
						</Button>
					</ui.SheetClose>
				</ui.SheetFooter>
			</ui.SheetContent>
		</ui.Sheet>
	);
}
