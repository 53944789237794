import { ui } from '@/components/ui';
import { useSelectFormById } from '@/features/forms/api/selectFormById';
import { inputComponents as ic } from '@/features/inputs/components';
import { hooks as h } from '@/hooks';
import { Outlet, createFileRoute } from '@tanstack/react-router';

const InputInputId = () => {
	const { inputId, pageInputId, formId } = Route.useParams();
	const { data: form } = useSelectFormById(formId);
	h.useNav([
		{
			label: 'Builder',
			route: '/builder',
		},
		{
			label: 'Forms',
			route: '/builder/form/templates',
		},
	]);

	return (
		<div>
			<div className="flex justify-between items-center gap-4 px-2 py-4 w-full">
				<ui.Input className="text-xl" value={form?.form_name} />
				{/* <tc.AddInputDialog formId={formId} /> */}
			</div>

			<div className="flex lg:flex-row flex-col">
				<ui.ResizablePanelGroup direction="horizontal">
					<ui.ResizablePanel>
						<Outlet />
					</ui.ResizablePanel>
					<ui.ResizableHandle className="mx-2" />
					<ui.ResizablePanel>
						<ic.OrderInputs inputId={inputId} pageInputId={pageInputId} pages={form?.pages} />
					</ui.ResizablePanel>
				</ui.ResizablePanelGroup>
			</div>
		</div>
	);
};

export const Route = createFileRoute('/_app/builder/form/$formId/page-input/$pageInputId/input/$inputId')({
	component: InputInputId,
});
