import useSupabase from '@/config/useSupabase';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';
import { toast } from 'sonner';

import { useAuth } from '../../../utils/auth';
import type { EmailSchemaT } from '../types/verifySchema';

const useVerifyEmailOtpMutation = () => {
	const auth = useAuth();
	const navigate = useNavigate();
	const client = useSupabase();
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: async (formData: EmailSchemaT) => {
			const { data, error } = await client.auth.verifyOtp({
				token: formData.token,
				email: formData.email,
				type: 'signup',
			});
			if (error) throw error;
			return data;
		},
		onError: () => {
			toast.error('Email Verification Failed');
		},
		onSuccess: (data) => {
			toast.success('Email Verified');
			if (data?.user?.id) {
				auth.login(data.user?.id);
			}
			if (auth.roles?.hasRole) {
				navigate({ to: '/onboarding/name' });
			} else {
				navigate({ to: '/onboarding/organisation' });
			}
		},
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey: ['user'] });
		},
	});
};

export default useVerifyEmailOtpMutation;
