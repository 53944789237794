import { Card, CardContent, CardDescription, CardFooter, CardHeader } from '@/components/ui/card';
import { ExternalLink } from 'lucide-react';

import WatchBadge from './WatchBadge';

import type { Watch } from '@/definitions/diaryTypes';
interface Props {
	title: string;
	date: string;
	description: string;
	href?: string;
	watch?: Watch;
}

const StudyCard = ({ date, title, description, href, watch }: Props) => {
	return (
		<Card className="flex h-full w-full flex-col">
			<CardHeader>
				<a target="_blank" className="flex w-full items-start justify-between" href={href} rel="noreferrer">
					<div className="flex flex-col gap-y-2">
						<h4 className="pb-1 text-xl font-bold text-slate-950">{title}</h4>
						<WatchBadge watch={watch} />
					</div>

					<span>
						<ExternalLink />
					</span>
				</a>
			</CardHeader>
			<CardContent className="border-t border-slate-200 mt-auto flex flex-col gap-y-8 py-6">
				<CardDescription className="">{description}</CardDescription>
			</CardContent>
			<CardFooter className="flex flex-col items-start">
				<p className="font-medium text-slate-800">{date}</p>
			</CardFooter>
		</Card>
	);
};

export default StudyCard;
