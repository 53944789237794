interface Props {
    stroke?: string;
    className?: string;
}
const Color = ({ stroke, className }: Props) => {
    return (
        <svg
            className={className}
            width="49"
            height="49"
            viewBox="0 0 49 49"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M24.96 48.96C11.736 48.96 0.959961 38.184 0.959961 24.96C0.959961 11.736 11.736 0.959961 24.96 0.959961C38.184 0.959961 48.96 10.656 48.96 22.56C48.96 30.504 42.504 36.96 34.56 36.96H30.312C29.64 36.96 29.112 37.488 29.112 38.16C29.112 38.448 29.232 38.712 29.424 38.952C30.408 40.08 30.96 41.496 30.96 42.96C30.96 44.5513 30.3278 46.0774 29.2026 47.2026C28.0774 48.3278 26.5513 48.96 24.96 48.96V48.96ZM24.96 5.75996C14.376 5.75996 5.75996 14.376 5.75996 24.96C5.75996 35.544 14.376 44.16 24.96 44.16C25.632 44.16 26.16 43.632 26.16 42.96C26.1525 42.6487 26.0332 42.3505 25.824 42.12C24.84 41.016 24.312 39.6 24.312 38.16C24.312 36.5687 24.9441 35.0425 26.0693 33.9173C27.1945 32.7921 28.7207 32.16 30.312 32.16H34.56C39.864 32.16 44.16 27.864 44.16 22.56C44.16 13.296 35.544 5.75996 24.96 5.75996Z"
                fill={stroke ? stroke : 'currentColor'}
            />
            <path
                d="M11.5482 27.7834C13.4974 27.7834 15.0776 26.2033 15.0776 24.254C15.0776 22.3048 13.4974 20.7246 11.5482 20.7246C9.59897 20.7246 8.0188 22.3048 8.0188 24.254C8.0188 26.2033 9.59897 27.7834 11.5482 27.7834Z"
                fill={stroke ? stroke : 'currentColor'}
            />
            <path
                d="M18.607 17.9006C20.5563 17.9006 22.1365 16.3204 22.1365 14.3712C22.1365 12.422 20.5563 10.8418 18.607 10.8418C16.6578 10.8418 15.0776 12.422 15.0776 14.3712C15.0776 16.3204 16.6578 17.9006 18.607 17.9006Z"
                fill={stroke ? stroke : 'currentColor'}
            />
            <path
                d="M31.3129 17.9006C33.2621 17.9006 34.8423 16.3204 34.8423 14.3712C34.8423 12.422 33.2621 10.8418 31.3129 10.8418C29.3636 10.8418 27.7834 12.422 27.7834 14.3712C27.7834 16.3204 29.3636 17.9006 31.3129 17.9006Z"
                fill={stroke ? stroke : 'currentColor'}
            />
            <path
                d="M38.3717 27.7834C40.3209 27.7834 41.9011 26.2033 41.9011 24.254C41.9011 22.3048 40.3209 20.7246 38.3717 20.7246C36.4225 20.7246 34.8423 22.3048 34.8423 24.254C34.8423 26.2033 36.4225 27.7834 38.3717 27.7834Z"
                fill={stroke ? stroke : 'currentColor'}
            />
        </svg>
    );
};

export default Color;
