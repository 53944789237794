import { ui } from '@/components/ui';
import NoDiarySelected from '@/features/dashboard/features/interface/components/NoDiarySelected';
import { useRefetchDashboardData } from '@/features/dashboard/features/overview/table/hooks/useRefetchDashboardData';
import { cn } from '@/lib/utils';
import { isSmall } from '@/services/isSmall';
import type { DiariesCountT, StatefulDiaryT } from '@/types/database/diaries';
import { Outlet } from '@tanstack/react-router';
import type { DashboardSearchT } from '../../../routes/_app/dashboard';
import DashboardTable from '../features/overview/table/components/DashboardTable';

interface Props {
	diaryId: string;
	diariesCount: DiariesCountT;
	settings: DashboardSearchT;
	diaries: StatefulDiaryT[];
}
const DashboardPage = ({ diaryId, diariesCount, settings, diaries }: Props) => {
	useRefetchDashboardData();

	return (
		<div className="px-2 w-full">
			{isSmall() ? (
				diaryId ? (
					<Outlet />
				) : (
					<DashboardTable diaries={diaries ?? []} diariesCount={diariesCount} settings={settings} />
				)
			) : (
				<ui.ResizablePanelGroup
					direction="horizontal"
					className="md:min-h-[200px] md:max-h-[92vh] transition-transform duration-1000 ease-in-out"
				>
					<ui.ResizablePanel
						className={cn('md:pr-2', diaryId ? 'hidden md:flex' : 'flex', 'overflow-scroll')}
						defaultSize={60}
					>
						<DashboardTable diaries={diaries ?? []} diariesCount={diariesCount as DiariesCountT} settings={settings} />
					</ui.ResizablePanel>
					<ui.ResizableHandle withHandle className={cn('bg-slate-300')} />
					<ui.ResizablePanel className="p-2" defaultSize={40}>
						{diaryId ? <Outlet /> : <NoDiarySelected />}
					</ui.ResizablePanel>
				</ui.ResizablePanelGroup>
			)}
		</div>
	);
};

export default DashboardPage;
