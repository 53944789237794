import { formComponents as fc } from '@/components/form';
import { ui } from '@/components/ui';
import { Form } from '@/components/ui/form';
import usePatientMutation from '@/features/profile/api/usePatientMutation';
import { zodResolver } from '@hookform/resolvers/zod';
import { type SubmitHandler, useForm } from 'react-hook-form';
import * as z from 'zod';

const formSchema = z.object({
	nhs_number: z.string().min(10, 'Not long enough').max(10, 'Too long'),
});

type FormSchemaType = z.infer<typeof formSchema>;

interface Props {
	userId: string;
	nhsNumber: number | null;
}
const NhsNumberForm = ({ userId, nhsNumber }: Props) => {
	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			nhs_number: nhsNumber ? String(nhsNumber) : '',
		},
	});
	const patientMutation = usePatientMutation(userId, 'nhs-number');

	const onSubmit: SubmitHandler<FormSchemaType> = async (formData) => {
		await patientMutation.mutate(formData);
	};

	return (
		<Form {...form}>
			<form onSubmit={form.handleSubmit(onSubmit)} className="form">
				<fc.Input
					name="nhs_number"
					label="NHS Number"
					description="Please enter your 10 digit NHS number."
					placeholder="eg 0123456789"
				/>

				<ui.Button
					onClick={() => patientMutation.mutate({ nhs_number: 111111111 })}
					size="lg"
					variant="ghost"
					type="button"
				>
					Skip
				</ui.Button>
				<fc.SaveButton />
			</form>
		</Form>
	);
};

export default NhsNumberForm;
