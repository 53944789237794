import { supabaseClient } from '@/lib/supabase';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'sonner';

const updateAudiences = async () => {
	const { data, error } = await supabaseClient.functions.invoke('public-update-audiences');
	if (error) throw error;
	return data;
};

export const useUpdateAudiencesMutation = () => {
	return useMutation({
		mutationKey: ['update-audiences'],
		mutationFn: updateAudiences,
		onError: () => {
			toast.error('Error updating audiences');
		},
		onSuccess: () => {
			toast.success('Successfully updated audiences');
		},
	});
};
