import { components as c } from '@/components';
import MultipleChoiceCards from '@/components/form/MultipleChoiceCards';
import { useInsertEvent } from '@/features/events/api/insertEvent';
import { Route as InjectionRoute } from '@/routes/_app/new-diary/patient/$patientId/organisation/$organisationId/diary/$diaryId/event/$eventId/injection/$injectionId';
import { Route as SurgeryRoute } from '@/routes/_app/new-diary/patient/$patientId/organisation/$organisationId/diary/$diaryId/event/$eventId/surgery/$surgeryId/surgery-name';
import { services as s } from '@/services';

import { type SubmitHandler, useForm, useWatch, z, zodResolver } from '@/utils/form';
import { useNavigate } from '@tanstack/react-router';

// Zod Types
const EventOptionsSchemaZ = z.object({
	diary_id: z.string().uuid(),
	event_id: z.string().uuid(),
	event_time: z.union([z.date(), z.string()]).nullable().optional(),
	required_events: z.array(z.string()),
});

type EventOptionsSchemaT = z.infer<typeof EventOptionsSchemaZ>;

// Props

interface Props {
	patientId: string;
	organisationId: string;
	diaryId: string;
	eventId: string;
	trackerId?: string;
}

// Component

const EventForm = ({ patientId, organisationId, diaryId, eventId, trackerId }: Props) => {
	const navigate = useNavigate();

	const form = useForm<EventOptionsSchemaT>({
		resolver: zodResolver(EventOptionsSchemaZ),
		defaultValues: {
			diary_id: diaryId,
			event_id: eventId,
		},
	});

	const insertManagementTypes = useInsertEvent(trackerId);

	const onSubmit: SubmitHandler<EventOptionsSchemaT> = async (formData) => {
		insertManagementTypes.mutate(formData, {
			onSuccess: () => {
				if (formData.required_events.includes('injection')) {
					navigate({
						to: InjectionRoute.to,
						params: {
							patientId: patientId,
							organisationId: organisationId,
							diaryId: diaryId,
							eventId: eventId,
							injectionId: s.uuidv4(),
						},
					});
				} else if (formData.required_events.includes('surgery')) {
					navigate({
						to: SurgeryRoute.to,
						params: {
							patientId: patientId,
							organisationId: organisationId,
							diaryId: diaryId,
							eventId: eventId,
							surgeryId: s.uuidv4(),
						},
					});
				} else {
					navigate({
						to: '/dashboard/diary/$diaryId',
						params: {
							diaryId: diaryId,
						},
						search: {
							diaries: 'mine',
							tab: 'pending',
						},
					});
				}
			},
		});
	};

	const requiredEvents = useWatch({ control: form.control });

	return (
		<c.ui.Form {...form}>
			<form onSubmit={form.handleSubmit(onSubmit)} className="form">
				<div>
					<MultipleChoiceCards name="required_events" label="Intervention Options" options={['injection', 'surgery']} />
					{(requiredEvents?.required_events?.length ?? 0) > 1 && (
						<p className="text-sm p-2 text-amber-500">
							It is recommend monitor changes in a patient one intervention at a time.
						</p>
					)}
				</div>
				<c.ui.Button>Next</c.ui.Button>
			</form>
		</c.ui.Form>
	);
};

export default EventForm;
