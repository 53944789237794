import 'chartjs-adapter-date-fns';

import { Chart as ChartJS, registerables } from 'chart.js';
import { Chart } from 'react-chartjs-2';

import type { EntryValueViewT, ExtendedDiaryViewT } from '../../../types/database/diaries';
import { extractDatasetsAndTrackers, generateData } from '../services/generateData';
import { defaultY } from '../services/generateOptions';
import { generateOptions } from '../services/generateOptions';

ChartJS.register(...registerables);
interface Data {
    labels: string[];
    datasets: {
        label: string;
        data: number[];
        fill: boolean;
        borderColor: string;
        backgroundColor: string;
        tension: number;
    }[];
}
interface Props {
    entryValues: EntryValueViewT[];
    trackers: ExtendedDiaryViewT['trackers_view'];
}
const y = defaultY;
y.max = 30;

const GlucoseAllTrackersChart = ({ entryValues, trackers }: Props) => {
    const lineSettings = {
        pointRadius: 0,
        pointBorderWidth: 0,
        tension: 0.1,
    };
    const data = generateData({
        entryValues: entryValues,
        inputType: 'glucose-slider',
        trackers: trackers,
        lineSettings: lineSettings,
    });
    data.labels = [];
    const options = generateOptions({ datasets: data, y: y });
    console.log(data);
    return <Chart type="line" options={options} className="w-full max-w-md max-h-[350px] min-h-[250px]" data={data} />;
};

export default GlucoseAllTrackersChart;
