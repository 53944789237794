import FormPage from '@/features/pages/components/FormPage';
import useExtractTracker from '@/features/trackers/hooks/useExtractTracker';
import type { FormViewT, PageViewT } from '@/types/database/forms';
import { createFileRoute } from '@tanstack/react-router';

const Page = () => {
	const { diaryId, entryId, pageIndex, trackerId } = Route.useParams();
	const tracker = useExtractTracker(diaryId, trackerId);
	const page = tracker.form?.pages.find((p: PageViewT) => p.page_index === Number(pageIndex));

	if (!page) {
		return <p>Page not found</p>;
	}

	return (
		<>
			<FormPage
				pageIndex={Number(pageIndex)}
				tracker={tracker}
				form={tracker.form as FormViewT}
				entryId={entryId}
				route={Route}
				nextEntry={tracker.next_entry}
				page={page}
			/>
		</>
	);
};

export const Route = createFileRoute('/_app/diary/$diaryId/new/tracker/$trackerId/entry/$entryId/page/$pageIndex/')({
	component: Page,
});
