import { useEntryValuesStore } from '@/features/inputs/hooks/useEntryValuesStore';
import { cn } from '@/lib/utils';
import { Route as PageInputRoute } from '@/routes/_app/diary/$diaryId/new/tracker/$trackerId/entry/$entryId/page/$pageIndex';
import { Link } from '@tanstack/react-router';
import { v4 as uuidv4 } from 'uuid';

interface Props {
	diaryId: string;
	trackerId: string;
	children?: React.ReactNode;
	is_locked?: boolean;
}
const NewEntryLink = ({ diaryId, trackerId, children, is_locked }: Props) => {
	const { resetEntryValues } = useEntryValuesStore();
	const newEntryId = uuidv4();

	return (
		<Link
			className={cn(
				'mx-auto flex w-full items-center justify-center',
				is_locked ? 'pointer-events-none cursor-not-allowed' : 'cursor-pointer',
			)}
			onClick={resetEntryValues}
			to={PageInputRoute.to}
			params={{
				diaryId: diaryId,
				entryId: newEntryId,
				trackerId: trackerId,
				pageIndex: String(1),
			}}
		>
			{children ?? 'Add New Entry'}
		</Link>
	);
};

export default NewEntryLink;
