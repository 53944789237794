import { BookHeart, Calendar, Clipboard, Clock, Flag, GaugeCircle } from 'lucide-react';

export const builderContent = {
	diaries: {
		title: 'Diary Templates',
		description: 'The builder allows you to create and edit forms, trackers, and rules.',
		link: '/builder/diaries',
		templates: '/builder/diary-templates',
		icon: <BookHeart />,
	},
	forms: {
		title: 'Forms',
		description: 'Forms determine what information is collected throughout the diary. E.g. A pain slider and comments.',
		link: '/builder/forms',
		icon: <Clipboard />,
	},
	trackers: {
		title: 'Tracker Templates',
		description: 'Trackers determine how often the forms should be completed. E.g. Weekly monitoring post-surgery.',
		link: '/builder/tracker-templates',
		icon: <Calendar />,
	},
	rules: {
		title: 'Rules',
		description:
			'Rules determine when to change the status of the diary. E.g. trigger a warning if a patient has worsening symptoms.',
		link: '/builder/rules',
		icon: <Flag />,
	},
	schedules: {
		title: 'Schedules',
		description:
			'Schedules determine the timing and frequency of diary entries. E.g. Daily, weekly, or custom schedules.',
		link: '/builder/schedules',
		icon: <Clock />,
	},
	quickstarts: {
		title: 'Quickstarts',
		description: 'Ways for users to get started with Patient Watch using pre-configured profile options',
		link: '/builder/quickstarts',
		icon: <GaugeCircle />,
	},
};

export type BuilderT = {
	title: string;
	description: string;
	link: string;
	icon: JSX.Element;
	templates?: string;
};

export type BuilderContent = {
	[key: string]: {
		title: string;
		description: string;
		link: string;
		icon: JSX.Element;
	};
};
