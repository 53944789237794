import { commonComponents as cc } from '@/components/common';
import { ui } from '@/components/ui';
import { entryComponents as ec } from '@/features/entries/components';
import useExtractEntry from '@/features/entries/hooks/useExtractEntry';
import { Link, createFileRoute } from '@tanstack/react-router';
import { Clipboard } from 'lucide-react';

const EditEntry = () => {
	const { diaryId, trackerId, entryId } = Route.useParams();

	const entry = useExtractEntry(diaryId, trackerId, entryId);

	return (
		<div className="">
			<cc.Title words="Entry" />
			<ec.EditEntry entry={entry} />
			<ec.DeleteEntryButton diaryId={diaryId} entryId={entryId} />
		</div>
	);
};

export const Route = createFileRoute('/_app/diary/$diaryId/edit/tracker/$trackerId/entry/$entryId/')({
	component: EditEntry,
});
