import { ui } from '@/components/ui';
import { z } from '@/utils/form';
import { zodResolver } from '@hookform/resolvers/zod';
import { createFileRoute } from '@tanstack/react-router';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { formComponents as fc } from '../../components/form';
import { useDeleteRequest } from '../../features/auth/api/useDeleteRequest';
import AuthWrap from '../../features/auth/components/AuthWrap';
import Title from '../../components/common/Title';

export const SchemaZ = z.object({
	email: z.string().email().min(1, 'Email is required'),
	reason: z.string().min(1, 'Reason is required'),
});

export type SchemaT = z.infer<typeof SchemaZ>;


const DeleteAccountForm = () => {
	const deleteRequest = useDeleteRequest();

	const form = useForm<SchemaT>({
		resolver: zodResolver(SchemaZ),
		defaultValues: {
			email: '',
		},
	});

	const onSubmit: SubmitHandler<SchemaT> = async (formData) => {
		await deleteRequest.mutate(formData);
  };
  
  return (
    <AuthWrap>
      <Title words="Delete Account" />
		<ui.Form {...form}>
			<form onSubmit={form.handleSubmit(onSubmit)} className="form">
				<fc.Email />
				<fc.Textarea name="reason" label="Reason for deleting account" />
				<ui.Button tabIndex={0} className="w-full" type="submit" disabled={deleteRequest.isPending}>
					Submit
				</ui.Button>
			</form>
      </ui.Form>
      </AuthWrap>
	);
};

const loginSchemaZ = z.object({
	email: z.string().email().optional(),
});

export const Route = createFileRoute('/_auth/delete-account')({
	component: DeleteAccountForm,
	validateSearch: (search) => loginSchemaZ.parse(search),
});
