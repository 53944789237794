import { ui } from '@/components/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import { type SubmitHandler, useForm } from 'react-hook-form';

import { formComponents as fc } from '@/components/form';
import useSmartAuthMutation from '@/features/auth/hooks/useSmartAuth';
import { z } from 'zod';

export const smartAuthSchema = z.object({
	email: z.string().email('Invalid email').min(1, 'Email is required'),
});

export type SmartAuthSchemaType = z.infer<typeof smartAuthSchema>;

interface Props {
	onSuccess?: () => void;
}
const SmartAuthForm = (props: Props) => {
	const form = useForm<SmartAuthSchemaType>({
		resolver: zodResolver(smartAuthSchema),
		defaultValues: {
			email: '',
		},
	});

	const smartAuthMutation = useSmartAuthMutation();

	const onSubmit: SubmitHandler<SmartAuthSchemaType> = async (formData) => {
		await smartAuthMutation.mutate(formData, { onSuccess: props.onSuccess });
	};

	return (
		<ui.Form {...form}>
			<form onSubmit={form.handleSubmit(onSubmit)} className="form">
				{/* Email */}
				<fc.Email tabIndex={0} />
				<ui.Button tabIndex={0} size="lg" type="submit" disabled={smartAuthMutation.isPending}>
					Next
				</ui.Button>
			</form>
		</ui.Form>
	);
};

export default SmartAuthForm;
