import { interfaceComponents as ic } from '@/features/dashboard/features/interface/components';
import StateCard from '@/features/dashboard/features/interface/components/StateCard';
import PatientTitle from '@/features/dashboard/features/patient/PatientTitle';
import { usePatientDiariesQuery } from '@/features/diaries/api/patientDiariesQuery';
import { diaryByIdQueryOptions } from '@/features/diary/api/getDiaryById';
import { selectProfileByIdQueryOptions } from '@/features/profile/api/selectProfile';
import { hooks as h } from '@/hooks';
import type { StatefulDiaryT } from '@/types/database/diaries';
import { createFileRoute } from '@tanstack/react-router';

const Interface = () => {
	const { diaryId, patientId } = Route.useParams();
	const { profile } = Route.useLoaderData();
	const { data: diaries, isLoading }: { data: StatefulDiaryT[]; isLoading: boolean } =
		usePatientDiariesQuery(patientId);

	h.useNav([
		{ label: 'Dashboard', route: { to: '/dashboard' } },
		{ label: profile.name ?? 'Patient', route: { to: '/dashboard/patient/$patientId' } },
	]);

	return (
		<>
			<PatientTitle editing={true} diaryId={diaryId} profile={profile} />
			<ic.PatientCard profile={profile} />
			{isLoading && <div>Loading...</div>}
			<h3 className="font-slate-700 mb-2 mt-4 text-xl">{diaries?.length > 0 ? 'Existing Diaries' : 'No Diaries'}</h3>
			<ul className="flex flex-col gap-2">
				{diaries?.length > 0 ? (
					diaries?.map((diary) => (
						<li key={diary.diary_id}>
							{' '}
							<StateCard diary={diary} />
						</li>
					))
				) : (
					<li>No Diaries</li>
				)}
			</ul>
		</>
	);
};

export const Route = createFileRoute('/_app/diary/$diaryId/patient/$patientId')({
	component: Interface,
	loader: async ({ context, params }) => {
		const profile = await context?.queryClient.ensureQueryData(selectProfileByIdQueryOptions(params.patientId));
		const diary = await context?.queryClient.ensureQueryData(diaryByIdQueryOptions(params.diaryId));

		return { diary, profile };
	},
});
