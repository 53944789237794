import { supabaseClient } from '@/lib/supabase';
import type { Action } from '@/types/database/actions';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';

import { createGetDiaryKey } from '../../../../diary/api/getDiaryById';

export const insertAction = async (data: Action['Insert']) => {
	return supabaseClient
		.from('actions')
		.insert(data)
		.select('*, action_definitions(*)')
		.throwOnError()
		.single()
		.then((result) => result.data);
};
export const useInsertActionMutation = () => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: async (data: Action['Insert']) => {
			return await insertAction(data);
		},
		onError: (error) => {
			toast.error(`${error.message}`);
		},
		onSuccess: (data) => {
			toast.success(data?.action_definitionsitions?.action_name + ' Complete');
		},
		onSettled: (data) => {
			queryClient.invalidateQueries({ queryKey: createGetDiaryKey(data?.diary_id as string) });
			queryClient.invalidateQueries({ queryKey: ['diaries'] });
		},
	});
};
export default useInsertActionMutation;
