import Deleted from '../icons/Deleted';
import FinishFlag from '../states/FinishFlag';
import Invited from '../states/Invited';
import Calendar from './Calendar';
import Email from './Email';
import Hourglass from './Hourglass';
import Injection from './Injection';
import Rewind from './Rewind';
import Wait from './Wait';

const actions = {
    Calendar,
    Wait,
    Deleted,
    Injection,
    FinishFlag,
    Invited,
    Hourglass,
    Rewind,
    Email,
};

export default actions;
