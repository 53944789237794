interface Props {
	active?: boolean;
	fill?: string;
	className?: string;
}

const Safe = ({ active, fill, className }: Props) => {
	return (
		<svg
			className={className}
			width="43"
			height="46"
			viewBox="0 0 43 46"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			{active ? (
				<>
					<path
						d="M3.0309 20.1063L2.09937 11.8778C2.04497 11.3973 2.39841 10.9668 2.88033 10.9266L3.56359 10.8697C10.2405 10.3133 16.4655 7.04141 20.7547 1.8943C21.1349 1.43814 21.8448 1.44334 22.2094 1.91205C26.2173 7.06507 32.2063 10.2965 38.7137 10.8171L40.1166 10.9293C40.5997 10.968 40.9548 11.399 40.9003 11.8806L39.9691 20.1063C38.7575 30.8091 31.7349 39.9681 21.7134 43.9159C21.5763 43.97 21.4237 43.97 21.2866 43.9159C11.2651 39.9681 4.24254 30.8091 3.0309 20.1063Z"
						fill="black"
						stroke={fill ? fill : 'black'}
						strokeWidth="3.5"
					/>
					<path
						d="M14.7584 24.2416L19.5103 29.324L30.5618 17.9202"
						stroke="white"
						strokeWidth="3.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</>
			) : (
				<>
					<path
						d="M3.0309 20.1063L2.09937 11.8778C2.04497 11.3973 2.39841 10.9668 2.88033 10.9266L3.56359 10.8697C10.2405 10.3133 16.4655 7.04141 20.7547 1.8943C21.1349 1.43814 21.8448 1.44334 22.2094 1.91205C26.2173 7.06507 32.2063 10.2965 38.7137 10.8171L40.1166 10.9293C40.5997 10.968 40.9548 11.399 40.9003 11.8806L39.9691 20.1063C38.7575 30.8091 31.7349 39.9681 21.7134 43.9159C21.5763 43.97 21.4237 43.97 21.2866 43.9159C11.2651 39.9681 4.24254 30.8091 3.0309 20.1063Z"
						stroke={fill ? fill : 'black'}
						strokeWidth="3.5"
					/>
					<path
						d="M14.7584 24.2416L19.5103 29.324L30.5618 17.9202"
						stroke={fill ? fill : 'black'}
						strokeWidth="3.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</>
			)}
		</svg>
	);
};

export default Safe;
