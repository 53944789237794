import * as changeCase from 'change-case';
import { Lock } from 'lucide-react';
import { useEffect } from 'react';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { sortBy, uniq } from 'underscore';
import { cn } from '../../lib/utils';
import { ui } from '../ui';
import { buttonVariants } from '../ui/button';
import { RequiredMarker } from './RequiredMarker';

export const arrayToOptions = (arr: string[]): OptionT[] => {
	return arr.map((item) => ({
		value: item,
		label: item,
	}));
};

export interface OptionT {
	value: string;
	label: React.ReactNode;
	locked?: boolean;
}

interface Props {
	name: string;
	description?: string;
	placeholder?: string;
	required?: boolean;
	label?: string;
	disabled?: boolean;
	options: OptionT[];
	side?: 'top' | 'bottom';
	autoFocus?: boolean;
	clearable?: boolean;
	addOptionForm?: React.ReactNode;
	dependsOn?: string; // Add this prop to specify which field this select depends on
}

const Select = ({
	name,
	description,
	label,
	required,
	options,
	placeholder,
	side,
	autoFocus,
	dependsOn,
	clearable,
	disabled,
	addOptionForm,
}: Props) => {
	const { control, setValue } = useFormContext();

	useEffect(() => {
		if (dependsOn && options.length === 1) {
			// If there's only one option and this field depends on another, set it as default
			setValue(name, options[0]?.value);
		}
	}, [dependsOn, options, setValue, name]);

	if (!options) {
		return <p className="text-sm">No Options Provided</p>;
	}

	const [orderedOptions, setOrderedOptions] = React.useState<OptionT[]>([]);

	React.useMemo(() => {
		const unique = uniq(options, (o) => o.value);
		const sorted = sortBy(unique, 'label');
		setOrderedOptions(sorted);
	}, [options]);

	return (
		<ui.FormField
			control={control}
			name={name}
			render={({ field }) => (
				<ui.FormItem className="w-full">
					{!!label && (
						<ui.FormLabel>
							{label}
							{required && <RequiredMarker />}
						</ui.FormLabel>
					)}
					{!!description && <ui.FormDescription>{description}</ui.FormDescription>}
					<ui.Select disabled={disabled} onValueChange={field.onChange} defaultValue={field.value} value={field.value}>
						<ui.FormControl>
							<ui.SelectTrigger testId={`${changeCase.kebabCase(name)}-trigger`} autoFocus={autoFocus}>
								<ui.SelectValue placeholder={placeholder ?? <span className="text-slate-500">Select</span>} />
							</ui.SelectTrigger>
						</ui.FormControl>
						<ui.SelectContent side={side ?? 'bottom'}>
							{orderedOptions.length > 8 ? (
								<ui.ScrollArea className="h-[300px]">
									{orderedOptions.map((option) => (
										<ui.SelectItem
											data-testid={`${changeCase.kebabCase(String(option.label))}-option`}
											className="flex justify-between-between items-center"
											key={option.value}
											value={option.value}
											disabled={option.locked}
										>
											<p>{option.label}</p>
											{option.locked && <Lock size={20} />}
										</ui.SelectItem>
									))}
									<ui.ScrollBar orientation="vertical" />
								</ui.ScrollArea>
							) : (
								orderedOptions.map((option) => (
									<ui.SelectItem
										data-testid={`${changeCase.kebabCase(String(option.label))}-option`}
										className="flex flex-row justify-between items-center w-full"
										key={option.value}
										value={option.value}
										disabled={option.locked}
									>
										<p className="flex flex-row justify-between items-center gap-x-2 w-full">
											{option.label}
											{option.locked && <Lock size={20} />}
										</p>
									</ui.SelectItem>
								))
							)}
						</ui.SelectContent>
					</ui.Select>
					<ui.FormMessage />
					{clearable && (
						<ui.Button
							variant="destructive-link"
							className="mt-2"
							size="sm"
							type="button"
							onClick={() => {
								setValue(name, null);
							}}
						>
							Clear
						</ui.Button>
					)}
					{addOptionForm && (
						<ui.Dialog>
							<ui.DialogTrigger className={cn(buttonVariants({ size: 'sm', variant: 'secondary' }), 'mt-4')}>
								Add Option
							</ui.DialogTrigger>
							<ui.DialogContent>
								<ui.DialogHeader>
									<ui.DialogTitle>Add Option</ui.DialogTitle>
								</ui.DialogHeader>
								<ui.ScrollArea className="md:p-2 lg:p-4 max-h-[80vh]">
									{addOptionForm}
									<ui.ScrollBar />
								</ui.ScrollArea>
							</ui.DialogContent>
						</ui.Dialog>
					)}
				</ui.FormItem>
			)}
		/>
	);
};

export default Select;
