interface Props {
    active?: boolean;
    fill?: string;
    className?: string;
}

const Table = ({ active, fill, className }: Props) => {
    return (
        <svg
            className={`w-full h-full p-2 ${className}`}
            width="39"
            height="39"
            viewBox="0 0 39 39"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            {active ? (
                <>
                    <g clipPath="url(#clip0_1828_5846)">
                        <rect width="38.4067" height="38.4067" transform="translate(0.116699 0.417969)" fill="white" />
                        <path
                            d="M0.116699 13.22H10.7852V38.8244H3.31726C1.54964 38.8244 0.116699 37.3915 0.116699 35.6239V13.22Z"
                            fill={fill ? fill : 'black'}
                        />
                        <rect x="13.991" y="13.22" width="10.6685" height="25.6045" fill={fill ? fill : 'black'} />
                        <path
                            d="M0.116699 3.61853C0.116699 1.85091 1.54964 0.417969 3.31726 0.417969H35.3229C37.0905 0.417969 38.5234 1.85091 38.5234 3.61853V10.0196H0.116699V3.61853Z"
                            fill={fill ? fill : 'black'}
                        />
                        <path
                            d="M27.8499 13.22H38.5184V35.6239C38.5184 37.3915 37.0854 38.8244 35.3178 38.8244H27.8499V13.22Z"
                            fill={fill ? fill : 'black'}
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_1828_5846">
                            <rect
                                width="38.4067"
                                height="38.4067"
                                fill="white"
                                transform="translate(0.116699 0.417969)"
                            />
                        </clipPath>
                    </defs>
                </>
            ) : (
                <>
                    <g clipPath="url(#clip0_1828_5841)">
                        <rect
                            width="38.4067"
                            height="38.4067"
                            transform="translate(0.116699 0.00561523)"
                            fill="white"
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M35.3231 3.20617L3.3175 3.20617V9.6073H11.847V9.60726H15.0476V9.6073H23.5929V9.60726H26.7934V9.6073H35.323V12.8079H26.7934V35.2117H23.5929V12.8079H15.0476V35.2117H11.847V12.8079H3.3175V35.2118H35.3231V3.20617ZM38.5237 3.20617C38.5237 1.43855 37.0907 0.00561523 35.3231 0.00561523H3.3175C1.54988 0.00561523 0.116943 1.43855 0.116943 3.20617V35.2118C0.116943 36.9794 1.54988 38.4123 3.3175 38.4123H35.3231C37.0907 38.4123 38.5237 36.9794 38.5237 35.2118V3.20617Z"
                            fill={fill ? fill : 'black'}
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_1828_5841">
                            <rect
                                width="38.4067"
                                height="38.4067"
                                fill="white"
                                transform="translate(0.116699 0.00561523)"
                            />
                        </clipPath>
                    </defs>
                </>
            )}
        </svg>
    );
};

export default Table;
