import 'chartjs-adapter-date-fns';

import { Chart as ChartJS, type LegendOptions, registerables } from 'chart.js';
import { Chart } from 'react-chartjs-2';

import type { EntryValueViewT, StatefulDiaryT } from '../../../types/database/diaries';
import { type LineSettingsT, generateData } from '../services/generateData';
import { defaultY, generateOptions } from '../services/generateOptions';

const y = defaultY;

ChartJS.register(...registerables);

interface Props {
	entryValues: EntryValueViewT[];
	trackers: StatefulDiaryT['trackers_view'];
	lineSettings?: LineSettingsT;
	legend?: LegendOptions<'line'>;
}

const PainChart = ({ entryValues, trackers, lineSettings, legend }: Props) => {
	const data = generateData({
		entryValues: entryValues,
		inputType: 'pain-slider',
		trackers: trackers,
		lineSettings: lineSettings ?? {},
	});

	const options = generateOptions({ datasets: data, y: { ...defaultY, max: 10 }, legend: legend });

	return (
		<Chart
			type="line"
			options={options}
			className="max-h-[450px] min-h-[250px] w-full px-0 max-w-lg mx-auto"
			data={data}
		/>
	);
};

export default PainChart;
