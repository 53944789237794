import 'chartjs-adapter-date-fns';

import { type ChartData, Chart as ChartJS, registerables } from 'chart.js';
import { Chart } from 'react-chartjs-2';

import { cn } from '../../../lib/utils';
import type { EntryValueViewT } from '../../../types/database/entries';
import { defaultY } from '../services/generateOptions';
import { generateOptions } from '../services/generateOptions';
import { generateMinMaxData } from '../services/maxMinByTracker';

ChartJS.register(...registerables);
type LineChartData = ChartData<'line', (number | null)[]>;

interface Props {
	entryValues: EntryValueViewT[];
	className: string;
	lineSettings?: LineChartData;
}
const y = defaultY;
y.max = 30;

const GlucoseMinMaxAvChart = ({ entryValues, className, lineSettings }: Props) => {
	const data = generateMinMaxData({
		entryValues: entryValues,
		inputType: 'glucose-slider',
		lineSettings: lineSettings,
	});
	console.log(data);
	const options = generateOptions({ datasets: data, y: y });
	return (
		<Chart
			type="line"
			options={options}
			className={cn('w-full max-w-md max-h-72 min-h-[250px]', className)}
			data={data}
		/>
	);
};

export default GlucoseMinMaxAvChart;
