import { supabaseClient } from '@/lib/supabase';
import { queryOptions, useQuery } from '@tanstack/react-query';

export async function selectAnatomicRegions() {
	const { data, error } = await supabaseClient.from('orthopaedic_areas').select();
	if (error) throw error;
	return data;
}

export const anatomicRegionsQueryKey = ['orthopaedic_areas'];

export const selectAnatomicRegionsQueryOptions = () => {
	return queryOptions({
		queryKey: anatomicRegionsQueryKey,
		queryFn: () => selectAnatomicRegions(),
	});
};

export function useAnatomicRegionsQuery() {
	return useQuery(selectAnatomicRegionsQueryOptions());
}
