import { ui } from '@/components/ui';
import { cn } from '@/lib/utils';
import { Controller, useFormContext } from 'react-hook-form';
import ReactSlider from 'react-slider';

import { glucoseSliderReadings } from './glucoseSliderReadings';

interface Props {
	name: string;
	label?: string;
}

const GlucoseSlider = (props: Props) => {
	const { control } = useFormContext();

	return (
		<ui.Card className="flex flex-col justify-center items-center gap-4 mx-auto px-0 w-full">
			<ui.CardHeader>
				<ui.Label className="px-4 font-semibold text-center text-slate-700 text-xl">{props.label}</ui.Label>
			</ui.CardHeader>
			<ui.CardContent className="flex flex-col gap-y-12 mb-8 w-full">
				<Controller
					name={props.name}
					control={control}
					render={({ field: { value, onChange } }) => (
						<>
							<ui.Input
								min={0}
								max={30}
								step={0.1}
								className={cn(
									'p-auto m-auto flex h-[70px] w-[110px] items-center justify-center text-center text-4xl font-semibold ring-2 ring-offset-2 transition-all duration-500 [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none',
									'bg-lime-100 text-lime-800 ring-lime-400',
									value > 20 && 'bg-red-100 text-red-800 ring-red-400',
									value < 5 && 'bg-red-100 text-red-800 ring-red-400',
								)}
								type="number"
								value={Math.round(value).toString()}
								onChange={(e) => onChange(Number(e.target.value))}
							/>
							<ReactSlider
								min={0}
								max={30}
								step={0.1}
								value={value}
								thumbClassName={cn(
									'w-12 h-12 leading-12 -mt-4 text-center z-10 flex items-center justify-center rounded-full cursor-grab bg-white border-4 border-indigo-800 transition duration-200 hover:scale-110',
								)}
								trackClassName={cn('px-2 h-[15px] rounded-full bg-indigo-300')}
								onChange={(val) => onChange(Math.round(val))}
								className="w-full"
							/>
							<p className="flex justify-center items-center text-lg text-slate-900">
								{glucoseSliderReadings(value)?.message}
							</p>
						</>
					)}
				/>
			</ui.CardContent>
			<ui.CardFooter></ui.CardFooter>
		</ui.Card>
	);
};

export default GlucoseSlider;
