import { formComponents as fc } from '@/components/form';
import { ui } from '@/components/ui';
import { Button } from '@/components/ui/button';
import { useUpdateTracker } from '@/features/trackers/api/updateTracker';
import type { TrackerViewT } from '@/types/database/trackers';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
const reminderStatusOptions: string[] = ['PENDING', 'CANCELLED', 'ACTIVE', 'COMPLETED', 'INACTIVE'];
interface TrackerReminderStatusFormProps {
	tracker: TrackerViewT;
}

import { arrayToOptions } from '@/components/form/Select';
import { Check } from 'lucide-react';
import { z } from 'zod';

const reminderStatusSchema = z.object({
	tracker_id: z.string(),
	tracker_reminder_status: z.enum(['PENDING', 'CANCELLED', 'ACTIVE', 'COMPLETED', 'INACTIVE']),
});

export function TrackerReminderStatusForm({ tracker }: TrackerReminderStatusFormProps) {
	const updateTrackerMutation = useUpdateTracker({ diaryId: tracker.diary_id as string });

	const form = useForm<z.infer<typeof reminderStatusSchema>>({
		resolver: zodResolver(reminderStatusSchema),
		defaultValues: {
			tracker_id: tracker.tracker_id,
			tracker_reminder_status: tracker.tracker_reminder_status,
		},
	});

	const onSubmit = (data: z.infer<typeof reminderStatusSchema>) => {
		updateTrackerMutation.mutate(data);
	};

	return (
		<ui.Form {...form}>
			<form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-row items-center gap-x-2">
				<fc.Select name="tracker_reminder_status" options={arrayToOptions(reminderStatusOptions)} />
				<Button type="submit" className="rounded-md" size="icon" disabled={updateTrackerMutation.isPending}>
					<Check />
					<span className="sr-only">Save</span>
				</Button>
			</form>
		</ui.Form>
	);
}
