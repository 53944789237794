import React from 'react';

const Diary = () => {
    return (
        <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M33.6018 4.75H37.2958C37.4338 4.75 37.5458 4.86193 37.5458 5V40.3166C37.5458 40.4547 37.4338 40.5666 37.2958 40.5666H9C8.86193 40.5666 8.75 40.4547 8.75 40.3166V5C8.75 4.86193 8.86193 4.75 9 4.75H24.6289V16.8095C24.6289 17.708 25.6303 18.2439 26.3779 17.7455L29.4328 15.7089L31.774 17.5819C32.5106 18.1712 33.6018 17.6467 33.6018 16.7034V4.75ZM32.3518 4.75H25.8789V16.5759L28.8155 14.6182C29.2231 14.3464 29.7598 14.3697 30.1423 14.6757L32.3518 16.4433V4.75ZM7 5C7 3.89543 7.89543 3 9 3H37.2958C38.4003 3 39.2958 3.89543 39.2958 5V40.3166C39.2958 41.4212 38.4003 42.3166 37.2958 42.3166H9C7.89543 42.3166 7 41.4212 7 40.3166V5Z"
                fill="black"
            />
        </svg>
    );
};

export default Diary;
