import { supabaseClient } from '@/lib/supabase';
import type { Surgery } from '@/types/database/events';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';

import { createGetDiaryKey } from '../../../../diary/api/getDiaryById';

export async function upsertSurgery(surgeryData: Surgery['Insert']) {
	const { data, error } = await supabaseClient.from('surgeries').upsert(surgeryData).select().single();
	if (error) throw error;
	return data;
}

interface SurgeryMutation {
	diaryId: string;
}

export const useSurgeryMutation = ({ diaryId }: SurgeryMutation) => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationKey: ['upsert-surgery'],
		mutationFn: async (data: Surgery['Insert']) => {
			return await upsertSurgery(data);
		},
		onError: () => {
			toast.error('Error creating new surgery');
		},
		onSuccess: (mutation) => {
			toast.success('Surgery saved');

			if (mutation?.onSuccess) {
				mutation.onSuccess();
			}
		},
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey: createGetDiaryKey(diaryId) });
			queryClient.invalidateQueries({ queryKey: ['diaries'] });
		},
	});
};
