import FinishFlag from '@/assets/states/FinishFlag';

const TrackerComplete = () => {
	return (
		<>
			<div className="animate-wave">
				<FinishFlag />
			</div>
			<div className="flex flex-col items-center justify-center px-6 text-center">
				<h3 className="mx-auto text-xl font-semibold text-slate-950">Tracker Complete</h3>
			</div>
		</>
	);
};

export default TrackerComplete;
