const nullOrNull = (val: any) => {
	if (!val || val === 'null') {
		return false;
	}
	return true;
};

export function processInjection(response: any) {
	if (nullOrNull(response?.inj_side) && nullOrNull(response?.site_name)) {
		return response?.inj_side + ' ' + response?.site_name;
	} else {
		return 'No Location Specified';
	}
	// return injSide + injSite;
}

export default processInjection;
