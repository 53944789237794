import React from 'react';

const Goggles = () => {
    return (
        <svg width="71" height="71" viewBox="0 0 71 71" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.1624 21.7318C16.7324 17.0961 21.7941 14.7723 26.2209 16.655L27.4024 17.1575C28.2514 17.5185 28.6537 18.5157 28.3009 19.3846C27.9481 20.2535 26.9738 20.6653 26.1248 20.3042L24.9433 19.8017C22.2872 18.6721 19.2502 20.0663 18.3082 22.8478L10.9656 44.5283H12.66C13.5794 44.5283 14.3248 45.2911 14.3248 46.2321C14.3248 47.1731 13.5794 47.9359 12.66 47.9359H8.66472C7.74532 47.9359 7 47.1731 7 46.2321C7 46.0746 7.02087 45.9221 7.05994 45.7774C7.07379 45.657 7.10048 45.5363 7.14086 45.4171L15.1624 21.7318ZM44.779 16.655C49.2059 14.7723 54.2676 17.0961 55.8376 21.7318L63.8591 45.4171C63.8995 45.5363 63.9261 45.6569 63.94 45.7772C63.9791 45.922 64 46.0745 64 46.2321C64 47.1731 63.2547 47.9359 62.3353 47.9359H58.34C57.4206 47.9359 56.6752 47.1731 56.6752 46.2321C56.6752 45.2911 57.4206 44.5283 58.34 44.5283H60.0343L52.6917 22.8478C51.7498 20.0663 48.7127 18.6721 46.0566 19.8017L44.8751 20.3042C44.0261 20.6653 43.0519 20.2535 42.6991 19.3846C42.3463 18.5157 42.7485 17.5185 43.5975 17.1575L44.779 16.655ZM38.9231 47.4771C37.7887 44.1644 33.2112 44.1644 32.0769 47.4771L28.9348 46.3501C31.1104 39.9966 39.8896 39.9966 42.0652 46.3501L38.9231 47.4771Z"
                fill="black"
            />
            <ellipse cx="22.049" cy="46.3002" rx="10.4544" ry="10.6998" fill="black" />
            <ellipse cx="22.0491" cy="46.3002" rx="7.25818" ry="7.4285" fill="#D1BBF9" />
            <ellipse cx="48.951" cy="46.3002" rx="10.4544" ry="10.6998" fill="black" />
            <ellipse cx="48.951" cy="46.3002" rx="7.25818" ry="7.4285" fill="#C8EAFC" />
        </svg>
    );
};

export default Goggles;
