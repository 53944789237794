import { supabaseClient } from '@/lib/supabase';

import type { Event } from '@/types/database/events';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'sonner';

import { useInsertTrackerEvent } from './insertTrackerEvent';

export async function insertEvent(event: Event['Insert']) {
	const { data, error } = await supabaseClient.from('events').upsert(event).select('*').single();
	if (error) throw error;
	return data;
}
export const useInsertEvent = (trackerId?: string) => {
	const insertTrackerEvent = useInsertTrackerEvent();
	return useMutation({
		mutationKey: ['new-event'],
		mutationFn: (event: Event['Insert']) => insertEvent(event),
		onError: () => {
			toast.error('Error creating event');
		},
		onSuccess: (data, mutation) => {
			toast.success('Event saved');
			if (trackerId) {
				insertTrackerEvent.mutate({ tracker_id: trackerId, event_id: data.event_id });
			}
			mutation?.onSuccess && mutation.onSuccess();
		},
	});
};
