import React from 'react';

const DiariesNotify = () => {
    return (
        <svg width="36" height="42" viewBox="0 0 36 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="36" height="42" fill="white" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M33.4872 3.75H5.19141C5.05334 3.75 4.94141 3.86193 4.94141 4V39.3166C4.94141 39.4547 5.05334 39.5666 5.19141 39.5666H33.4872C33.6252 39.5666 33.7372 39.4547 33.7372 39.3166V4C33.7372 3.86193 33.6252 3.75 33.4872 3.75ZM5.19141 2C4.08684 2 3.19141 2.89543 3.19141 4V39.3166C3.19141 40.4212 4.08684 41.3166 5.19141 41.3166H33.4872C34.5917 41.3166 35.4872 40.4212 35.4872 39.3166V4C35.4872 2.89543 34.5917 2 33.4872 2H5.19141Z"
                fill="black"
            />
            <path
                d="M0 8.21249C0 7.10793 0.89543 6.21249 2 6.21249H7.19089C8.29546 6.21249 9.19089 7.10792 9.19089 8.21249V8.80794C9.19089 9.91251 8.29546 10.8079 7.19089 10.8079H2C0.895431 10.8079 0 9.91251 0 8.80794V8.21249Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.19089 7.21249H2C1.44772 7.21249 1 7.66021 1 8.21249V8.80794C1 9.36022 1.44772 9.80794 2 9.80794H7.19089C7.74317 9.80794 8.19089 9.36022 8.19089 8.80794V8.21249C8.19089 7.66021 7.74317 7.21249 7.19089 7.21249ZM2 6.21249C0.89543 6.21249 0 7.10793 0 8.21249V8.80794C0 9.91251 0.895431 10.8079 2 10.8079H7.19089C8.29546 10.8079 9.19089 9.91251 9.19089 8.80794V8.21249C9.19089 7.10792 8.29546 6.21249 7.19089 6.21249H2Z"
                fill="black"
            />
            <path
                d="M0 16.6375C0 15.5329 0.89543 14.6375 2 14.6375H7.19089C8.29546 14.6375 9.19089 15.5329 9.19089 16.6375V17.2329C9.19089 18.3375 8.29546 19.2329 7.19089 19.2329H2C0.895431 19.2329 0 18.3375 0 17.2329V16.6375Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.19089 15.6375H2C1.44772 15.6375 1 16.0852 1 16.6375V17.2329C1 17.7852 1.44772 18.2329 2 18.2329H7.19089C7.74317 18.2329 8.19089 17.7852 8.19089 17.2329V16.6375C8.19089 16.0852 7.74317 15.6375 7.19089 15.6375ZM2 14.6375C0.89543 14.6375 0 15.5329 0 16.6375V17.2329C0 18.3375 0.895431 19.2329 2 19.2329H7.19089C8.29546 19.2329 9.19089 18.3375 9.19089 17.2329V16.6375C9.19089 15.5329 8.29546 14.6375 7.19089 14.6375H2Z"
                fill="black"
            />
            <path
                d="M0 25.0625C0 23.9579 0.89543 23.0625 2 23.0625H7.19089C8.29546 23.0625 9.19089 23.9579 9.19089 25.0625V25.6579C9.19089 26.7625 8.29546 27.6579 7.19089 27.6579H2C0.895431 27.6579 0 26.7625 0 25.6579V25.0625Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.19089 24.0625H2C1.44772 24.0625 1 24.5102 1 25.0625V25.6579C1 26.2102 1.44772 26.6579 2 26.6579H7.19089C7.74317 26.6579 8.19089 26.2102 8.19089 25.6579V25.0625C8.19089 24.5102 7.74317 24.0625 7.19089 24.0625ZM2 23.0625C0.89543 23.0625 0 23.9579 0 25.0625V25.6579C0 26.7625 0.895431 27.6579 2 27.6579H7.19089C8.29546 27.6579 9.19089 26.7625 9.19089 25.6579V25.0625C9.19089 23.9579 8.29546 23.0625 7.19089 23.0625H2Z"
                fill="black"
            />
            <path
                d="M0 33.4875C0 32.3829 0.89543 31.4875 2 31.4875H7.19089C8.29546 31.4875 9.19089 32.3829 9.19089 33.4875V34.0829C9.19089 35.1875 8.29546 36.0829 7.19089 36.0829H2C0.895431 36.0829 0 35.1875 0 34.0829V33.4875Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.19089 32.4875H2C1.44772 32.4875 1 32.9352 1 33.4875V34.0829C1 34.6352 1.44772 35.0829 2 35.0829H7.19089C7.74317 35.0829 8.19089 34.6352 8.19089 34.0829V33.4875C8.19089 32.9352 7.74317 32.4875 7.19089 32.4875ZM2 31.4875C0.89543 31.4875 0 32.3829 0 33.4875V34.0829C0 35.1875 0.895431 36.0829 2 36.0829H7.19089C8.29546 36.0829 9.19089 35.1875 9.19089 34.0829V33.4875C9.19089 32.3829 8.29546 31.4875 7.19089 31.4875H2Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.8203 15.8095V2.70209H22.0703V15.5759L25.0069 13.6182C25.4145 13.3464 25.9512 13.3697 26.3337 13.6757L28.5432 15.4433V2.70209H29.7932V15.7034C29.7932 16.6467 28.702 17.1712 27.9654 16.5819L25.6242 14.7089L22.5694 16.7455C21.8217 17.2439 20.8203 16.708 20.8203 15.8095Z"
                fill="black"
            />
            <path d="M19 8C19 3.58172 22.5817 0 27 0H36V9C36 13.4183 32.4183 17 28 17H19V8Z" fill="white" />
            <circle cx="27.8684" cy="7.86842" r="7.36842" fill="#F24E1E" />
            <path
                d="M31.8091 10.3238C31.8091 10.2484 31.7767 10.1765 31.7201 10.1266V10.1266C31.4676 9.90167 31.2467 9.64372 31.0631 9.35972C30.8627 8.96777 30.7426 8.53975 30.7098 8.10075V6.80776C30.7115 6.11824 30.4614 5.45182 30.0064 4.93371C29.6261 4.50058 29.1245 4.19603 28.5706 4.05672C28.3834 4.00964 28.239 3.84945 28.239 3.65643V3.65643C28.239 3.56376 28.2022 3.47488 28.1366 3.40935C28.0711 3.34382 27.9822 3.30701 27.8896 3.30701C27.7969 3.30701 27.708 3.34382 27.6425 3.40935C27.5769 3.47488 27.5401 3.56376 27.5401 3.65643V3.65643C27.5401 3.85295 27.3941 4.01645 27.2041 4.06669C26.6587 4.21092 26.166 4.51583 25.7924 4.94552C25.3434 5.46189 25.0967 6.12348 25.0981 6.80776V8.10075C25.0653 8.53975 24.9452 8.96777 24.7448 9.35972C24.5644 9.64301 24.347 9.90092 24.0983 10.1266V10.1266C24.0417 10.1765 24.0093 10.2484 24.0093 10.3238V10.4346C24.0093 10.7155 24.237 10.9432 24.5179 10.9432H31.3005C31.5814 10.9432 31.8091 10.7155 31.8091 10.4346V10.3238Z"
                fill="white"
            />
            <path
                d="M27.2205 11.218C27.2434 11.3839 27.3256 11.5359 27.4519 11.6459C27.5782 11.756 27.74 11.8166 27.9075 11.8166C28.075 11.8166 28.2368 11.756 28.3631 11.6459C28.4894 11.5359 28.5716 11.3839 28.5946 11.218H27.2205Z"
                fill="white"
            />
        </svg>
    );
};

export default DiariesNotify;
