import { formComponents as fc } from '@/components/form';
import { ui } from '@/components/ui';
import { type SubmitHandler, useForm, useWatch, z, zodResolver } from '@/utils/form';
import { createFileRoute } from '@tanstack/react-router';
import { useEffect } from 'react';
import Title from '../../../components/common/Title';
import { useAddOrganisation } from '../../../features/admin/api/addOrganisation';

export const organisationCodeSchema = z.object({
	organisation_name: z.string(),
	organisation_code: z.string(),
	nhs_flag: z.boolean(),
});

export type OrganisationCodeSchemaType = z.infer<typeof organisationCodeSchema>;

function convertOrganisationNameToCode(name: string): string {
	try {
		const currentYear = new Date().getFullYear();

		// Split the name into words
		const words = name.split(/\s+/);

		// Extract the first letter of each word, excluding common words
		const abbreviation = words
			.filter((word) => !['and', 'of', 'the'].includes(word.toLowerCase()))
			.map((word) => word[0]?.toUpperCase())
			.join('');

		// Combine the abbreviation with the current year
		return `${abbreviation}${currentYear}`;
	} catch (error) {
		console.error(error);
		return '';
	}
}

const AddOrganisationForm = () => {
	const addOrganisation = useAddOrganisation();
	const form = useForm<OrganisationCodeSchemaType>({
		resolver: zodResolver(organisationCodeSchema),
		defaultValues: {
			nhs_flag: false,
		},
	});

	const { control } = form;

	const organisationName = useWatch({ control: control, name: 'organisation_name' });

	useEffect(() => {
		const newCode = convertOrganisationNameToCode(organisationName);
		if (newCode) form.setValue('organisation_code', convertOrganisationNameToCode(organisationName));
	}, [organisationName, form]);

	const onSubmit: SubmitHandler<OrganisationCodeSchemaType> = async (formData) => {
		addOrganisation.mutate(formData);
	};

	return (
		<section className="md-width">
			<Title words="Add Organisation" />
			<ui.Form {...form}>
				<form onSubmit={form.handleSubmit(onSubmit)} className="form">
					<fc.Input label="Organisation Name" name="organisation_name" />
					<fc.Input label="Organisation Code" name="organisation_code" />
					<fc.Switch label="NHS Flag" name="nhs_flag" />
					<ui.Button type="submit" size="lg">
						Save
					</ui.Button>
				</form>
			</ui.Form>
		</section>
	);
};
export const Route = createFileRoute('/_app/admin/add-organisation')({
	component: AddOrganisationForm,
});
