import React from 'react';

const Message = () => {
    return (
        <svg width="57" height="50" viewBox="0 0 57 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14 4C8.47715 4 4 8.47716 4 14V24.4719C4 29.425 7.60291 33.5404 12.3309 34.3336L14 34.6136V44.3131L29.416 34.4719H43C48.5228 34.4719 53 29.9948 53 24.4719V14C53 8.47715 48.5229 4 43 4H14ZM0 14C0 6.26802 6.26801 0 14 0H43C50.732 0 57 6.26801 57 14V24.4719C57 32.2039 50.732 38.4719 43 38.4719H30.5839L14.6142 48.6665C12.6172 49.9413 10 48.507 10 46.1378V37.8916C4.21678 36.17 0 30.8151 0 24.4719V14Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 14C12 12.8954 12.8954 12 14 12H32C33.1046 12 34 12.8954 34 14C34 15.1046 33.1046 16 32 16H14C12.8954 16 12 15.1046 12 14Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 24C12 22.8954 12.8954 22 14 22H40C41.1046 22 42 22.8954 42 24C42 25.1046 41.1046 26 40 26H14C12.8954 26 12 25.1046 12 24Z"
                fill="black"
            />
        </svg>
    );
};

export default Message;
