import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import { FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { cn } from '@/lib/utils';
import { format } from 'date-fns';
import dayjs from 'dayjs';
import { CalendarIcon } from 'lucide-react';
import { useFormContext } from 'react-hook-form';

interface Props {
	name: string;
	label?: string;
	description?: string;
	disabled?: boolean;
}

const DateInput = ({ name = 'date', label, description, disabled }: Props) => {
	const { control } = useFormContext();
	return (
		<FormField
			control={control}
			name={name}
			render={({ field }) => (
				<FormItem className="flex flex-col text-sm">
					{label && <FormLabel>{label}</FormLabel>}
					{!!description && <FormDescription>{description}</FormDescription>}
					<Popover>
						<PopoverTrigger className="h-12 border-slate-300 bg-white hover:bg-white" asChild>
							<FormControl>
								<Button variant={'outline'} className={cn('pl-3 pr-6 text-left text-sm font-normal')}>
									{field.value ? (
										format(field.value, 'PPP')
									) : (
										<span className="text-sm text-slate-600">Pick a date</span>
									)}
									<CalendarIcon className="ml-auto h-4 w-4 opacity-50 stroke-black" />
								</Button>
							</FormControl>
						</PopoverTrigger>
						<PopoverContent className="w-auto p-0" align="start">
							<Calendar
								mode="single"
								selected={field.value}
								onSelect={(date) => {
									// Set the selected date to 12 PM before updating the field value
									const dateWithNoon = dayjs(date).set('hour', 12).set('minute', 0).toDate();
									field.onChange(dateWithNoon);
								}}
								disabled={disabled ?? false}
								initialFocus
							/>
						</PopoverContent>
					</Popover>
					<FormMessage />
				</FormItem>
			)}
		/>
	);
};

export default DateInput;
