import React from 'react';

const Profile = () => {
    return (
        <svg width="57" height="58" viewBox="0 0 57 58" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M46.9732 51.1229C51.7393 48.8252 53.7713 46.0759 53.7713 43.7427C53.7713 41.4095 51.7393 38.6601 46.9732 36.3625C42.3608 34.1389 35.8323 32.7015 28.5 32.7015C21.1677 32.7015 14.6392 34.1389 10.0268 36.3625C5.26072 38.6601 3.22865 41.4095 3.22865 43.7427C3.22865 46.0759 5.26072 48.8252 10.0268 51.1229C14.6392 53.3464 21.1677 54.7839 28.5 54.7839C35.8323 54.7839 42.3608 53.3464 46.9732 51.1229ZM28.5 57C43.6878 57 56 51.0645 56 43.7427C56 36.4209 43.6878 30.4854 28.5 30.4854C13.3122 30.4854 1 36.4209 1 43.7427C1 51.0645 13.3122 57 28.5 57Z"
                fill="black"
                stroke="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M28.5001 24.3065C34.6481 24.3065 39.5122 19.5205 39.5122 13.7613C39.5122 8.00216 34.6481 3.21615 28.5001 3.21615C22.3521 3.21615 17.488 8.00216 17.488 13.7613C17.488 19.5205 22.3521 24.3065 28.5001 24.3065ZM28.5001 26.5227C35.8127 26.5227 41.7408 20.8092 41.7408 13.7613C41.7408 6.71344 35.8127 1 28.5001 1C21.1874 1 15.2593 6.71344 15.2593 13.7613C15.2593 20.8092 21.1874 26.5227 28.5001 26.5227Z"
                fill="black"
                stroke="black"
            />
        </svg>
    );
};

export default Profile;
