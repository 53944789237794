import { ui } from '@/components/ui';
import { cn } from '@/lib/utils';
import * as changeCase from 'change-case';
import { Lock, Pencil } from 'lucide-react';
import { useState } from 'react';
import { buttonVariants } from '../ui/button';
import RequiredInfo from './RequiredInfo';

interface Props {
	label: string | React.ReactNode;
	info: string | React.ReactNode;
	children?: React.ReactNode;
	locked?: boolean;
	description?: string;
	noInfo?: boolean;
	show?: boolean;
	help?: () => void;
	editable?: boolean;
}

const EditDialog = ({
	label,
	info,
	children,
	locked = false,
	description,
	noInfo = false,
	show = true,
	editable = true,
}: Props) => {
	if (!show) return null;

	if (editable === false) {
		return <RequiredInfo label={label} info={info} description={description} noInfo={noInfo} />;
	}

	const [open, setOpen] = useState<boolean>(false);

	const openDialog = () => {
		if (locked !== true) {
			setOpen(true);
		} else {
			setOpen(false);
		}
	};

	return (
		<ui.Dialog open={open} onOpenChange={setOpen}>
			<button
				onClick={openDialog}
				type="button"
				className={cn(
					'flex w-full max-w-[90vw] overflow-clip items-center justify-between',
					locked ? 'cursor-text' : 'cursor-pointer',
				)}
				data-testid={`${changeCase.kebabCase(String(label))}-trigger`}
			>
				<RequiredInfo label={label} info={info} description={description} noInfo={noInfo} />
				<div className="flex justify-end items-center min-w-[70px]">
					<div
						className={cn(
							buttonVariants({
								variant: locked ? 'ghost' : 'secondary',
								size: 'icon',
							}),
							'rounded-full p-0 text-slate-800 ml-auto',
						)}
						tabIndex={-1}
					>
						{locked ? <Lock /> : <Pencil />}
					</div>
				</div>
			</button>
			<ui.DialogContent>
				<ui.DialogHeader>
					<ui.DialogTitle className="text-slate-900 dark:text-slate-100">{label}</ui.DialogTitle>
				</ui.DialogHeader>
				<ui.ScrollArea className="md:p-2 lg:p-4 max-h-[80vh]">
					{children}
					<ui.ScrollBar />
				</ui.ScrollArea>
			</ui.DialogContent>
		</ui.Dialog>
	);
};

export default EditDialog;
