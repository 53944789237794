import type { EntryValue, EntryValueT } from '@/types/database/diaries';
import dayjs from 'dayjs';
// Function to update a single entry value
export const updateEntryValues = (entryValues: EntryValueT[], newVal: Partial<EntryValueT>): EntryValueT[] => {
	// Find the index of the entry with matching input_id
	const index = entryValues.findIndex((value) => value.input_id === newVal.input_id);

	if (index !== -1) {
		const oldEntry = entryValues[index];
		entryValues[index] = {
			entry_id: oldEntry?.entry_id as string,
			entry_value_id: oldEntry?.entry_value_id as string,
			input_id: oldEntry?.input_id as string,
			last_edited: dayjs().toISOString() as string,
			number_value: oldEntry?.number_value as number,
			object_id: oldEntry?.object_id as string,
			text_array: oldEntry?.text_array as string[],
			text_value: oldEntry?.text_value as string,
			...newVal,
		} as EntryValue['Update'];
	} else {
		// If no existing entry is found
		// Add a new entry with the provided values and a new timestamp
		entryValues.push({
			...newVal,
			created_at: new Date().toISOString(),
		} as EntryValueT);
	}

	// Return the updated array
	return entryValues;
};

// Function to update multiple entry values
export const updateEntryValuesArray = (
	entryValues: EntryValueT[],
	newValues: Partial<EntryValueT>[],
): EntryValueT[] => {
	// Iterate through each new value
	for (const newVal of newValues) {
		// Update the entryValues array for each new value
		entryValues = updateEntryValues(entryValues, newVal);
	}

	// Return the final updated array
	return entryValues;
};
