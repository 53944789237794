import { ui } from '../ui';

interface Props {
	label?: string;
	maxLength?: number;
	description?: string;
	value: string;
	setValue: (text: string) => void;
}
const NumberInput = (props: Props) => {
	return (
		<>
			{props.label && <ui.Label>{props.label}</ui.Label>}
			<ui.Input
				className="h-14 rounded-lg text-xl"
				type="number"
				value={props.value}
				onChange={(e) => props.setValue(e.target.value)}
			/>
			<p className="text-sm font-normal text-slate-700">{props.description ?? 'Type a number.'}</p>
		</>
	);
};

export default NumberInput;
