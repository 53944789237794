import { ui } from '@/components/ui';
import { injectionApi as ia } from '@/features/injection/api';
import InjectionDetailsForm from '@/features/injection/components/InjectionDetailsForm';
import { Route as EditEntryRoute } from '@/routes/_app/diary/$diaryId/edit/event/$eventId';
import type { InjectionContentT, InjectionLocationT } from '@/types/database/pain';
import { createFileRoute, useNavigate } from '@tanstack/react-router';

const InjectionDetailsPage = () => {
	// const { diaryId, eventId, injectionId } = Route.useParams();
	// const { injectionLocations, injectionContents } = Route.useLoaderData();
	// const navigate = useNavigate();
	// return (
	// 	<InjectionDetailsForm
	// 		diaryId={diaryId}
	// 		injectionId={injectionId}
	// 		eventId={eventId}
	// 		injectionLocations={injectionLocations}
	// 		injectionContents={injectionContents}
	// 		navigateToNextPage={() => navigate({ to: EditEntryRoute.to, params: { eventId, diaryId } })}
	// 	/>
	// );
	return <></>;
};

export const Route = createFileRoute('/_app/diary/$diaryId/edit/event/$eventId/injection/$injectionId/details')({
	component: InjectionDetailsPage,
	// loader: async ({ context, params }) => {
	// 	const injection = await context?.queryClient.ensureQueryData(ia.injectionQueryOptions(params.injectionId));
	// 	const injectionLocations = (await context?.queryClient.ensureQueryData(
	// 		ia.injectionLocationsQueryOptions(),
	// 	)) as InjectionLocationT[];
	// 	const injectionContents = (await context?.queryClient.ensureQueryData(
	// 		ia.injectionContentsQueryOptions(),
	// 	)) as InjectionContentT[];

	// 	return { injectionLocations, injectionContents, injection };
	// },
});
