import { ui } from '@/components/ui';
import type { TimingT } from '@/types/zod/timings';

import { timeComponents as tc } from '.';
import { useTimingMutation } from '../api/upsertTimings';

interface Props {
	timings: TimingT[];
	scheduleId: string;
	saveDisabled: boolean;
}

const SaveTimingButtons = ({ timings, saveDisabled }: Props) => {
	const upsertTiming = useTimingMutation();

	return (
		<div className="flex items-center gap-x-2 mt-4 ml-auto w-full max-w-md">
			<tc.SaveTemplateDialog timings={timings} />
			<ui.Button disabled={saveDisabled} onClick={() => upsertTiming.mutate(timings)} size="lg">
				Save
			</ui.Button>
		</div>
	);
};

export default SaveTimingButtons;
