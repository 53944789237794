// components/TrackerDateForm.tsx
import { ui } from '@/components/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import type { SubmitHandler } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import * as z from 'zod';
import DateTimeInput from './DateTime';

const formSchema = z.object({
	date_time: z.string().nullable(),
});

type FormSchemaType = z.infer<typeof formSchema>;

interface TrackerDateFormProps {
	initialDate: string | null;
	onSubmit: (date: string | null) => void;
	submitButtonText?: string;
}

const DateTimeForm: React.FC<TrackerDateFormProps> = ({ initialDate, onSubmit, submitButtonText = 'Next' }) => {
	const form = useForm<FormSchemaType>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			date_time: initialDate,
		},
	});

	const handleSubmit: SubmitHandler<FormSchemaType> = (formData) => {
		onSubmit(formData.date_time);
	};

	return (
		<FormProvider {...form}>
			<ui.Form {...form}>
				<form onSubmit={form.handleSubmit(handleSubmit)} className="form">
					<DateTimeInput
						name="date_time"
						label="Date and Time"
						description="Select the date and time for your tracker entry"
					/>
					<ui.Button size="lg" type="submit">
						{submitButtonText}
					</ui.Button>
				</form>
			</ui.Form>
		</FormProvider>
	);
};

export default DateTimeForm;
