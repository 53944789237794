interface Props {
	className: string;
	stroke?: string;
}

const Minimize = ({ className, stroke }: Props) => {
	return (
		<svg
			className={className}
			width="21"
			height="20"
			viewBox="0 0 21 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M19.498 5.5L15.7253 5.5C15.173 5.5 14.7253 5.05228 14.7253 4.5L14.7253 1"
				stroke={stroke ? stroke : 'black'}
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<path
				d="M1.99976 5.5L5.77248 5.5C6.32477 5.5 6.77248 5.05229 6.77248 4.5L6.77248 1"
				stroke={stroke ? stroke : 'black'}
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<path
				d="M19.498 14.0005L15.7253 14.0005C15.173 14.0005 14.7253 14.4482 14.7253 15.0005L14.7253 18.5005"
				stroke={stroke ? stroke : 'black'}
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<path
				d="M1.99829 14.0005L5.77102 14.0005C6.3233 14.0005 6.77102 14.4482 6.77102 15.0005L6.77102 18.5005"
				stroke={stroke ? stroke : 'black'}
				strokeWidth="2"
				strokeLinecap="round"
			/>
		</svg>
	);
};

export default Minimize;
