import { services as s } from '@/services';
import { Controller, useFormContext } from 'react-hook-form';

import { ui } from '../ui';

interface Props {
	label: string;
	name: string;
	options: string[];
}

const MultipleChoiceCards = ({ label, name, options }: Props) => {
	const { control } = useFormContext();

	return (
		<ui.Card>
			<ui.CardHeader>
				<ui.Label>{label}</ui.Label>
			</ui.CardHeader>
			<ui.CardContent>
				<Controller
					name={name}
					control={control}
					render={({ field: { value, onChange } }) => (
						<ul className="flex flex-col">
							{options?.map((o) => (
								<li key={o} className="flex items-center gap-2 p-2">
									<ui.Checkbox
										checked={Array.isArray(value) && value.includes(o)}
										onCheckedChange={(checked) => {
											const newValue = Array.isArray(value) ? value : [];
											if (checked) {
												newValue.push(o);
											} else {
												newValue.splice(newValue.indexOf(o), 1);
											}
											onChange(newValue);
										}}
									/>
									<ui.Label className="font-normal capitalize">{s.capitaliseSentence(o, '-')}</ui.Label>
								</li>
							))}
						</ul>
					)}
				/>
				<ui.FormMessage />
			</ui.CardContent>
		</ui.Card>
	);
};

export default MultipleChoiceCards;
