import {
	AlertDialog,
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
	AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import OnboardingStepper from '@/features/onboarding/components/OnboardingStepper';
import { useNavigateToNextOnboardingRoute } from '@/features/onboarding/hooks/useNavigateToNextOnboardingRoute';
import { selectProfileByIdQueryOptions } from '@/features/profile/api/selectProfile';
import PhoneForm from '@/features/profile/components/PhoneForm';
import ProfileWrap from '@/features/profile/layouts/ProfileWrap';
import type { ExtendedProfileT } from '@/types/database/profiles';
import { useAuth } from '@/utils/auth';
import { createFileRoute } from '@tanstack/react-router';

const Name = () => {
	const auth = useAuth();
	const { profile } = Route.useLoaderData();
	const nextPage = useNavigateToNextOnboardingRoute('/onboarding/phone');

	return (
		<ProfileWrap title="Onboarding">
			<OnboardingStepper currentRoute={Route.to} />
			<PhoneForm userId={auth.id} phone={profile?.phone ?? ''} onSuccess={nextPage} />
			<AlertDialog>
				<AlertDialogTrigger asChild>
					<Button variant="secondary">Skip</Button>
				</AlertDialogTrigger>
				<AlertDialogContent>
					<AlertDialogHeader>
						<AlertDialogTitle>Are you sure you want to skip?</AlertDialogTitle>
						<AlertDialogDescription>
							SMS is used for important notifications. Skipping this step may affect your ability to receive timely
							updates.
						</AlertDialogDescription>
					</AlertDialogHeader>
					<AlertDialogFooter>
						<AlertDialogCancel>Cancel</AlertDialogCancel>
						<AlertDialogAction onClick={nextPage}>Continue</AlertDialogAction>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialog>
		</ProfileWrap>
	);
};
export const Route = createFileRoute('/onboarding/phone')({
	component: Name,
	loader: async ({ context }) => {
		let profile: ExtendedProfileT | null = null;
		if (context?.auth?.id) {
			profile = await context?.queryClient.ensureQueryData(selectProfileByIdQueryOptions(context.auth.id));
		}
		return { profile };
	},
});

export default Name;
