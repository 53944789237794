import type { RouteT } from '@/types/zod/routes';

import useProfileAttributes, { type ProfileAttributesT } from './useProfileAttributes';

function findRouteFromProfileAttributes(obj: ProfileAttributesT, keyToRemove?: string): RouteT {
	if (keyToRemove) {
		delete obj[keyToRemove];
	}
	const firstNonTruePair = Object.entries(obj).find(([_, value]) => value === true);
	return firstNonTruePair ? `/profile/${firstNonTruePair[0]}` : '/profile';
}

const useProfileNavigation = () => {
	const profileAttributes = useProfileAttributes();
	const nextAttribute = findRouteFromProfileAttributes(profileAttributes);

	return {
		nextAttribute,
		findNextMissingProfileAttribute: (keyToRemove?: string) =>
			findRouteFromProfileAttributes(profileAttributes, keyToRemove),
	};
};

export default useProfileNavigation;
