import { supabaseClient } from '@/lib/supabase';
import type { PatientT } from '@/types/database/profiles';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';
import { createProfileQueryKey } from './selectProfile';

type PartialPatient = Partial<PatientT>;

export async function updatePatient(patientId: string, data: PartialPatient) {
	return await supabaseClient
		.from('patients')
		.update(data)
		.eq('patient_id', patientId)
		.select()
		.throwOnError()
		.single()
		.then((result) => result.data);
}

function usePatientMutation(userId: string) {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: (data: PartialPatient) => updatePatient(userId, data),

		onMutate: async () => {
			// Cancel any outgoing refetches
		},
		onSuccess: (mutation) => {
			toast.success('Successfully updated profile');
			mutation?.onSuccess && mutation.onSuccess();
		},

		onError: (error) => {
			toast.error(error.message);
		},
		onSettled: (data) => {
			// Invalidate to make sure last image label updates...
			queryClient.invalidateQueries({ queryKey: createProfileQueryKey(data?.patient_id ?? '') });
			queryClient.invalidateQueries({ queryKey: createProfileQueryKey(data?.patient_id ?? '') });
		},
	});
}

export default usePatientMutation;
