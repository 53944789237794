import { useState, useEffect } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { icons } from '@/assets/icons';
import WarningMessage from './WarningMessage';
import { hooks as h } from '@/hooks';
import { useToast } from '@/components/ui/use-toast';
const DeviceCheck = () => {
    const [showMessage, setShowMessage] = useState<boolean>(false);
    const { toast } = useToast();
    useEffect(() => {
        if (!isMobile && !isTablet) {
            toast({
                title: 'Wrong Device Type',
                description: 'To capture images, please use a mobile or tablet with a camera.',
                variant: 'destructive',
            });
        } else {
            setShowMessage(false);
        }
    }, []);
    return <></>;
};

export default DeviceCheck;
