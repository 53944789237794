import NewEntryLink from '../../diary/new/components/NewEntryLink';
import DeleteEntryButton from './DeleteEntryButton';
import EditEntry from './EditEntry';
import TrackerComplete from './TrackerComplete';

export const entryComponents = {
	EditEntry,
	DeleteEntryButton,
	TrackerComplete,
	NewEntryLink,
};
