import { cn } from '../../lib/utils';

const Or = ({ className }: { className?: string }) => {
	return (
		<div className={cn('flex items-center justify-center py-2', className)}>
			<hr className="w-full h-[1.5px] bg-slate-300" />
			<p className="px-4 text-slate-500 dark:text-slate-300">OR</p>
			<hr className="w-full h-[1.5px] bg-slate-300" />
		</div>
	);
};

export default Or;
