import { ui } from '@/components/ui';
import { cn } from '@/lib/utils';
import { useNavigate } from '@tanstack/react-router';
import { Check } from 'lucide-react';

import type { ToT } from '../../types/zod/routes';

interface SubstepT {
	stage: StageT;
	words: string;
	route?: ToT;
}
export interface StepT {
	stage: StageT;
	words: string;
	route?: ToT;
	substeps?: SubstepT[];
}

interface Props {
	steps: StepT[];
}

export const stageColors = (stage: StageT) => {
	return cn(
		stage === 'current' && 'border-slate-800 text-slate-800 dark:border-slate-200 dark:text-slate-200',
		stage === 'future' && 'border-slate-400 text-slate-400',
		stage === 'past' && 'border-indigo-600 text-indigo-600 dark:border-indigo-500 dark:text-indigo-500',
	);
};

const Stepper = ({ steps }: Props) => {
	const navigate = useNavigate();
	return (
		<ul
			className={cn(
				'justify-star mx-auto my-4 w-full flex-col flex-wrap items-start md:px-0',
				steps?.length > 1 ? 'flex' : 'hidden',
			)}
		>
			{steps?.map((step, index) => {
				return (
					<li className="flex flex-col w-full" key={step.words}>
						<div className="flex justify-between items-center w-full">
							<Step stage={step.stage} words={step.words} number={index + 1} />
							{step.route && (
								<ui.Button
									disabled={step.stage === 'future'}
									variant="link"
									size="sm"
									onClick={() =>
										navigate({ to: step.route?.to, params: (prev) => ({ ...prev, ...step.route?.params }) })
									}
									className={cn(
										step.stage === 'current'
											? 'pointer-events-none text-slate-800 dark:text-slate-200 no-underline'
											: '',
									)}
								>
									{step.stage === 'current' ? 'Current' : 'Change'}
								</ui.Button>
							)}
						</div>
						{step.substeps && (
							<ul className="flex flex-col pl-4">
								{step.substeps.map((substep, index) => (
									<li className="flex justify-between items-center w-full" key={substep.words}>
										<SubStep stage={substep.stage} words={substep.words} number={index + 1} />
										{substep.route && (
											<ui.Button
												disabled={substep.stage === 'future'}
												variant="link"
												size="sm"
												onClick={() =>
													navigate({ to: substep.route?.to, params: (prev) => ({ ...prev, ...substep.route?.params }) })
												}
												className={cn(
													substep.stage === 'current'
														? 'pointer-events-none text-slate-800 dark:text-slate-200 no-underline'
														: '',
												)}
											>
												{substep.stage === 'current' ? 'Current' : 'Change'}
											</ui.Button>
										)}
									</li>
								))}
							</ul>
						)}
					</li>
				);
			})}
		</ul>
	);
};

export default Stepper;

type StageT = 'future' | 'current' | 'past';

interface StepProps {
	stage: StageT;
	words: string;
	number: number;
}
const Step = (props: StepProps) => {
	return (
		<div className={cn(stageColors(props.stage), 'text-md mb-2 flex flex-nowrap items-center justify-center')}>
			<div className="flex justify-center items-center gap-x-2 h-full">
				<span
					className={cn(
						stageColors(props.stage),
						'text-md flex h-7 w-7 flex-nowrap items-center justify-center rounded-full border-2 ',
					)}
				>
					{props.stage === 'future' && `${props.number}`}
					{props.stage === 'current' && `${props.number}`}
					{props.stage === 'past' && <Check strokeWidth={3} size={16} />}
				</span>
				<p className={cn(stageColors(props.stage), 'text-wrap')}>{props.words}</p>
			</div>
		</div>
	);
};

interface SubStepProps {
	stage: 'current' | 'future' | 'past';
	number: number;
	words: string;
}

const SubStep = (props: SubStepProps) => {
	const letters = ['a', 'b', 'c', 'd', 'e']; // Add more if needed

	return (
		<div className={cn(stageColors(props.stage), 'mb-1 flex flex-nowrap items-center justify-center text-sm')}>
			<div className="flex justify-center items-center gap-x-2 h-full dark:text-slate-200">
				<span
					className={cn(
						stageColors(props.stage),
						'flex h-6 w-6 flex-nowrap items-center justify-center rounded-full border-2 text-sm ',
					)}
				>
					{props.stage !== 'past' && `${letters[props.number - 1]}`}
					{props.stage === 'past' && <Check strokeWidth={4} size={12} />}
				</span>
				<p className="text-wrap">{props.words}</p>
			</div>
		</div>
	);
};
