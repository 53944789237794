import { buttonVariants } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { Link } from '@tanstack/react-router';
import { ArrowRight, UserPlus2 } from 'lucide-react';

const JoinTeam = () => {
	return (
		<div className="flex flex-col items-center gap-y-2 mx-auto py-8 rounded-xl w-full md:max-w-md lg:max-w-lg">
			<div className={cn('my-4 animate-bounce')}>
				<UserPlus2 size={60} strokeWidth={1.5} />
			</div>
			<h3 className="font-medium text-center text-slate-800 text-xl">Want to be a part of Patient Watch?</h3>
			<p className="text-lg text-slate-600">Help us dream bigger.</p>

			<Link to="/contact" className={cn(buttonVariants({ variant: 'link', size: 'lg', className: 'md:pl-0 gap-x-1' }))}>
				Join the Team
				<ArrowRight />
			</Link>
		</div>
	);
};

export default JoinTeam;
