import { colors } from '../colors';

const MIA = () => {
    return (
        <svg width="71" height="70" viewBox="0 0 71 70" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M65.1212 33.5606C65.1212 50.4388 51.4388 64.1212 34.5606 64.1212C17.6824 64.1212 4 50.4388 4 33.5606C4 16.6824 17.6824 3 34.5606 3C51.4388 3 65.1212 16.6824 65.1212 33.5606Z"
                fill="white"
                stroke="black"
                strokeWidth="2"
            />
            <path
                d="M62.942 33.5606C62.942 49.2352 50.2352 61.942 34.5606 61.942C18.886 61.942 6.17925 49.2352 6.17925 33.5606C6.17925 17.886 18.886 5.17925 34.5606 5.17925C50.2352 5.17925 62.942 17.886 62.942 33.5606ZM34.5606 65.2462C52.0601 65.2462 66.2462 51.0601 66.2462 33.5606C66.2462 16.0611 52.0601 1.875 34.5606 1.875C17.0611 1.875 2.875 16.0611 2.875 33.5606C2.875 51.0601 17.0611 65.2462 34.5606 65.2462Z"
                fill="black"
                stroke="black"
                strokeWidth="0.25"
            />
            <path
                d="M24.38 16.2533L25.9876 15.5113C33.0727 12.2413 41.4744 13.4527 47.3991 18.531V18.531C56.9676 26.7326 56.5354 41.851 46.4951 49.4678V49.4678C40.4856 54.0267 32.3311 54.7421 25.6492 51.2421V51.2421C19.2359 47.8827 15.2173 41.2412 15.2173 34.0013V31.5245M17.2535 24.398C17.2535 24.398 18.2715 22.3618 19.2896 21.3437"
                stroke="black"
                strokeWidth="3"
                strokeLinecap="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M34.5605 18.7983C35.4039 18.7983 36.0876 19.4821 36.0876 20.3255V34.1344L28.5832 42.7109C28.0278 43.3456 27.063 43.4099 26.4283 42.8545C25.7935 42.2991 25.7292 41.3343 26.2846 40.6996L33.0333 32.9868V20.3255C33.0333 19.4821 33.7171 18.7983 34.5605 18.7983Z"
                fill="black"
            />
            <circle cx="56.0238" cy="54.9762" r="12.5238" fill={colors['red-400']} stroke="black" strokeWidth="3" />
            <path d="M56.3628 47.4553V55.6628" stroke="black" strokeWidth="3" strokeLinecap="round" />
            <circle cx="56.3629" cy="60.7923" r="2.05185" fill="black" />
        </svg>
    );
};

export default MIA;
