import { colors } from '@/assets/colors';
import { defaultLineSettings } from '@/features/charts/services/generateData';
import { generateOptions } from '@/features/charts/services/generateOptions';
import type { ChartDataset } from 'chart.js';
import { Line } from 'react-chartjs-2';
import type { ClinicianStatisticT } from '../../../types/database/profiles';

type DiaryStat = unknown & {
	created_at: string;
	active_diaries: number;
	pending_diaries: number;
	inactive_diaries: number;
};
interface DiariesByTabProps {
	diaryBreakdown: DiaryStat[];
}

const DiariesByTabSparkChart = ({
	diaryBreakdown,
}: {
	diaryBreakdown: ClinicianStatisticT[];
}) => {
	const datasets = {
		labels: ['Active', 'Pending', 'Inactive'],
		datasets: [
			{
				label: 'Active',
				data: diaryBreakdown?.map((stat: DiaryStat) => ({
					x: stat?.created_at,
					y: stat?.active_diaries,
				})),
				...defaultLineSettings,
				borderWidth: 2,
				fill: true,
				pointRadius: 4,
				backgroundColor: colors['blue-100'],
				borderColor: colors['blue-300'],
			},
			{
				label: 'Pending',
				data: diaryBreakdown?.map((stat: DiaryStat) => ({
					x: stat?.created_at,
					y: stat?.pending_diaries,
				})),
				...defaultLineSettings,
				fill: true,

				borderWidth: 2,
				pointRadius: 4,
				backgroundColor: colors['cyan-100'],
				borderColor: colors['cyan-300'],
			},
			{
				label: 'Inactive',
				data: diaryBreakdown?.map((stat: DiaryStat) => ({
					x: stat?.created_at,
					y: stat?.inactive_diaries,
				})),
				...defaultLineSettings,
				fill: true,
				pointRadius: 4,
				backgroundColor: colors['zinc-100'],
				borderColor: colors['zinc-300'],
				borderWidth: 2,
			},
		],
	};

	const options = generateOptions({
		datasets: datasets as ChartDataset[],
		x: {
			display: false,
		},
		y: { min: 0, max: undefined, stacked: true, display: false },
		legend: {
			display: false,
		},
	});

	const lastDiary = diaryBreakdown.reduce((prev: DiaryStat | null, current: DiaryStat) => {
		if (!prev || current?.created_at > prev?.created_at) {
			return current;
		}
		return prev;
	}, null);

	const activeDiaries = lastDiary?.active_diaries ?? 0;
	const pendingDiaries = lastDiary?.pending_diaries ?? 0;
	const inactiveDiaries = lastDiary?.inactive_diaries ?? 0;
	const totalDiaries = activeDiaries + pendingDiaries + inactiveDiaries;

	return (
		<div className="flex flex-col justify-between items-between w-full h-full">
			<div>
				<b className="font-bold text-2xl">{totalDiaries} Diaries</b>
				<div className="flex gap-4">
					<span className="py-1 rounded-md text-blue-600 text-sm">{activeDiaries} Active</span>
					<span className="py-1 rounded-md text-cyan-600 text-sm">{pendingDiaries} Pending</span>
					<span className="py-1 rounded-md text-sm text-zinc-600">{inactiveDiaries} Inactive</span>
				</div>
			</div>

			<div className="flex w-full h-24">
				<Line options={options} data={datasets} />
			</div>
		</div>
	);
};

export default DiariesByTabSparkChart;
