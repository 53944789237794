import { ui } from '@/components/ui';
import { cn } from '@/lib/utils';
import type { StatefulDiaryT } from '@/types/database/diaries';
import type { Column } from '@tanstack/react-table';
import { ArrowUpDown } from 'lucide-react';
import type React from 'react';

interface Props {
	column: Column<StatefulDiaryT, unknown>;
	words: React.ReactNode;
	className?: string;
	disabled?: boolean;
}

const SortButton = ({ column, words, className, disabled }: Props) => {
	return (
		<ui.Button
			disabled={disabled}
			size="sm"
			variant="ghost"
			className={cn('px-2 text-slate-700 disabled:text-slate-700 disabled:opacity-100', className)}
			onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
		>
			{words}
			{!disabled && <ArrowUpDown className="ml-2 w-4 h-4" />}
		</ui.Button>
	);
};

export default SortButton;
