interface Props {
	strokeWidth: string;
	stroke: string;
	className: string;
}
const FlipCamera = ({ strokeWidth, stroke, className }: Props) => {
	return (
		<svg
			className={className}
			width="48"
			height="41"
			viewBox="0 0 48 41"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M23.5 11.0001C17.1487 11.0001 12 16.1488 12 22.5001C12 25.7422 13.3416 28.671 15.5 30.7615"
				stroke={stroke}
				strokeWidth={strokeWidth}
				strokeLinecap="round"
			/>
			<path
				d="M12.0388 32.3003L17.0388 32.3009L17.0394 27.3009"
				stroke={stroke}
				strokeWidth={strokeWidth}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M4 6.65517H10.4597C11.8244 6.65517 13.1299 6.09731 14.0731 5.111L17.1187 1.9265C17.6847 1.33472 18.468 1 19.2868 1H28.0371C28.856 1 29.6393 1.33472 30.2052 1.9265L33.2508 5.111C34.1941 6.09731 35.4995 6.65517 36.8643 6.65517H44C45.6569 6.65517 47 7.99832 47 9.65517V37C47 38.6569 45.6569 40 44 40H4C2.34315 40 1 38.6569 1 37V9.65517C1 7.99832 2.34315 6.65517 4 6.65517Z"
				stroke={stroke}
				strokeWidth={strokeWidth}
			/>
			<path
				d="M24 33.7614C30.3513 33.7614 35.5 28.6126 35.5 22.2614C35.5 19.0193 34.1584 16.0905 32 14"
				stroke={stroke}
				strokeWidth={strokeWidth}
				strokeLinecap="round"
			/>
			<path
				d="M35.4612 12.4612L30.4612 12.4606L30.4606 17.4606"
				stroke={stroke}
				strokeWidth={strokeWidth}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default FlipCamera;
