import React from 'react';

const Clipboard = () => {
    return (
        <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="12.5" y="6.5" width="45" height="57" rx="2.5" fill="white" stroke="black" strokeWidth="3" />
            <path
                d="M21.5 5.5H48.5V12C48.5 12.8284 47.8284 13.5 47 13.5H23C22.1716 13.5 21.5 12.8284 21.5 12V5.5Z"
                fill="black"
                stroke="black"
            />
            <path d="M21 22H49" stroke="black" strokeWidth="3" strokeLinecap="round" />
            <path d="M21 31H49" stroke="black" strokeWidth="3" strokeLinecap="round" />
            <path d="M21 39H49" stroke="black" strokeWidth="3" strokeLinecap="round" />
            <path d="M21 48H41" stroke="black" strokeWidth="3" strokeLinecap="round" />
        </svg>
    );
};

export default Clipboard;
