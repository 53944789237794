interface Props {
    fill: string;
    opacity: string;
}

const Footprint = ({ fill, opacity }: Props) => {
    return (
        <svg
            className="h-full w-full"
            width="262"
            height="556"
            viewBox="0 0 262 556"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M73.8974 55.265C73.8974 85.7871 57.3549 110.53 36.9487 110.53C16.5425 110.53 0 85.7871 0 55.265C0 24.743 16.5425 0 36.9487 0C57.3549 0 73.8974 24.743 73.8974 55.265Z"
                fill={fill}
                fillOpacity={opacity}
            />
            <path d="M73.8974 55.265C73.8974 85.7871 57.3549 110.53 36.9487 110.53C16.5425 110.53 0 85.7871 0 55.265C0 24.743 16.5425 0 36.9487 0C57.3549 0 73.8974 24.743 73.8974 55.265Z" />
            <path
                d="M131 36.8434C131 53.4917 120.473 66.9879 107.487 66.9879C94.5014 66.9879 83.9744 53.4917 83.9744 36.8434C83.9744 20.195 94.5014 6.69879 107.487 6.69879C120.473 6.69879 131 20.195 131 36.8434Z"
                fill={fill}
                fillOpacity={opacity}
            />
            <path d="M131 36.8434C131 53.4917 120.473 66.9879 107.487 66.9879C94.5014 66.9879 83.9744 53.4917 83.9744 36.8434C83.9744 20.195 94.5014 6.69879 107.487 6.69879C120.473 6.69879 131 20.195 131 36.8434Z" />
            <path
                d="M181.385 55.265C181.385 69.1387 172.361 80.3855 161.231 80.3855C150.1 80.3855 141.077 69.1387 141.077 55.265C141.077 41.3914 150.1 30.1446 161.231 30.1446C172.361 30.1446 181.385 41.3914 181.385 55.265Z"
                fill={fill}
                fillOpacity={opacity}
            />
            <path d="M181.385 55.265C181.385 69.1387 172.361 80.3855 161.231 80.3855C150.1 80.3855 141.077 69.1387 141.077 55.265C141.077 41.3914 150.1 30.1446 161.231 30.1446C172.361 30.1446 181.385 41.3914 181.385 55.265Z" />
            <path
                d="M225.051 90.4337C225.051 103.382 216.78 113.879 206.577 113.879C196.374 113.879 188.103 103.382 188.103 90.4337C188.103 77.485 196.374 66.9879 206.577 66.9879C216.78 66.9879 225.051 77.485 225.051 90.4337Z"
                fill={fill}
                fillOpacity={opacity}
            />
            <path d="M225.051 90.4337C225.051 103.382 216.78 113.879 206.577 113.879C196.374 113.879 188.103 103.382 188.103 90.4337C188.103 77.485 196.374 66.9879 206.577 66.9879C216.78 66.9879 225.051 77.485 225.051 90.4337Z" />
            <path
                d="M262 139C262 151.024 252.977 160.771 241.846 160.771C230.716 160.771 221.692 151.024 221.692 139C221.692 126.976 230.716 117.229 241.846 117.229C252.977 117.229 262 126.976 262 139Z"
                fill={fill}
                fillOpacity={opacity}
            />
            <path d="M262 139C262 151.024 252.977 160.771 241.846 160.771C230.716 160.771 221.692 151.024 221.692 139C221.692 126.976 230.716 117.229 241.846 117.229C252.977 117.229 262 126.976 262 139Z" />
            <path
                d="M152.834 110.53C120.924 102.157 65.5 110.53 41.9875 128.952C18.4749 147.374 17.8224 177.973 28.552 202.639C44.579 239.482 104.129 218.827 136.039 229.434C156.193 236.133 156.193 269.627 156.193 288.048C156.193 373.323 91.0664 427.048 73.8982 465.566C56.73 504.084 67.1802 556 122.603 556C178.026 556 164.59 432.072 172.988 378.482C179.811 334.94 240.167 252.88 240.167 217.711C240.167 189.241 213.841 178.488 194.821 155.747C182.466 140.975 184.744 118.904 152.834 110.53Z"
                fill={fill}
                fillOpacity={opacity}
            />
        </svg>
    );
};

export default Footprint;
