const Thetis = () => {
	return (
		<svg width="40" height="38" viewBox="0 0 40 38" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_5664_16195)">
				<path
					d="M21.4459 35.4993C21.4459 36.3491 20.7778 37 19.9998 37C19.2219 37 18.5537 36.3491 18.5537 35.4993C18.5537 34.6495 19.2219 33.9985 19.9998 33.9985C20.7778 33.9985 21.4459 34.6495 21.4459 35.4993Z"
					stroke="currentColor"
					strokeWidth="2"
				/>
				<path
					d="M22.0576 28.8547C22.0576 30.0497 21.1157 30.9806 20 30.9806C18.8843 30.9806 17.9424 30.0497 17.9424 28.8547C17.9424 27.6596 18.8843 26.7288 20 26.7288C21.1157 26.7288 22.0576 27.6596 22.0576 28.8547Z"
					stroke="currentColor"
					strokeWidth="2"
				/>
				<path
					d="M22.6694 20.9597C22.6694 22.5001 21.4536 23.7108 20.0002 23.7108C18.5468 23.7108 17.3311 22.5001 17.3311 20.9597C17.3311 19.4194 18.5468 18.2086 20.0002 18.2086C21.4536 18.2086 22.6694 19.4194 22.6694 20.9597Z"
					stroke="currentColor"
					strokeWidth="2"
				/>
				<path
					d="M23.2801 4.37629C23.2801 6.26191 21.7906 7.75258 19.9994 7.75258C18.2083 7.75258 16.7187 6.26191 16.7187 4.37629C16.7187 2.49068 18.2083 1 19.9994 1C21.7906 1 23.2801 2.49068 23.2801 4.37629Z"
					stroke="currentColor"
					strokeWidth="2"
				/>
				<path
					d="M25.0366 9.4211L25.0368 9.42103C25.6378 9.27849 26.8072 9.20406 28.3092 9.24318C29.7827 9.28155 31.4898 9.42641 33.1219 9.6815C34.7636 9.9381 36.2814 10.2989 37.3999 10.7513C37.9606 10.9781 38.3764 11.2103 38.6512 11.4318C38.9289 11.6556 38.9851 11.804 38.9955 11.8555C39.0193 11.9765 39.0091 12.2156 38.8413 12.5991C38.6772 12.9741 38.3924 13.4164 37.9831 13.8984C37.1652 14.8615 35.9356 15.8826 34.4942 16.7304C31.539 18.4683 28.1212 19.2479 25.7224 17.949C24.9425 17.5245 23.9815 16.7318 23.2461 15.6879C22.5147 14.6498 22.0466 13.4235 22.1272 12.108L22.136 11.9653C22.2771 10.6787 23.3237 9.82684 25.0366 9.4211Z"
					stroke="currentColor"
					strokeWidth="2"
				/>
				<path
					d="M14.9712 9.42103L14.9715 9.4211C16.7159 9.83432 17.7693 10.7102 17.879 12.0368V12.0369V12.037V12.0371V12.0372V12.0373V12.0374V12.0375V12.0375V12.0376V12.0377V12.0378V12.0379V12.038V12.0381V12.0382V12.0383V12.0384V12.0385V12.0386V12.0387V12.0388V12.0389V12.039V12.0391V12.0391V12.0392V12.0393V12.0394V12.0395V12.0396V12.0397V12.0398V12.0399V12.04V12.0401V12.0402V12.0403V12.0404V12.0405V12.0406V12.0406V12.0407V12.0408V12.0409V12.041V12.0411V12.0412V12.0413V12.0414V12.0415V12.0416V12.0417V12.0418V12.0419V12.042V12.0421V12.0422V12.0422V12.0423V12.0424V12.0425V12.0426V12.0427V12.0428V12.0429V12.043V12.0431V12.0432V12.0433V12.0434V12.0435V12.0436V12.0437V12.0437V12.0438V12.0439V12.044V12.0441V12.0442V12.0443V12.0444V12.0445V12.0446V12.0447V12.0448V12.0449V12.045V12.0451V12.0452V12.0453V12.0453V12.0454V12.0455V12.0456V12.0457V12.0458V12.0459V12.046V12.0461V12.0462V12.0463V12.0464V12.0465V12.0466V12.0467V12.0468V12.0468V12.0775L17.8809 12.108C17.9615 13.4235 17.4934 14.6498 16.762 15.6879C16.0265 16.7318 15.0655 17.5245 14.2856 17.949C11.8868 19.2479 8.46903 18.4683 5.51393 16.7304C4.07246 15.8826 2.84289 14.8615 2.02497 13.8984C1.61564 13.4164 1.33087 12.9741 1.16677 12.5991C0.999119 12.216 0.988778 11.977 1.01255 11.8559C1.02277 11.8047 1.07855 11.6561 1.35686 11.4318C1.63164 11.2103 2.04747 10.9781 2.60814 10.7513C3.72669 10.2989 5.24447 9.9381 6.88623 9.6815C8.5183 9.42641 10.2254 9.28155 11.6989 9.24318C13.2008 9.20406 14.3703 9.27849 14.9712 9.42103Z"
					stroke="currentColor"
					strokeWidth="2"
				/>
			</g>
			<defs>
				<clipPath id="clip0_5664_16195">
					<rect width="40" height="38" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default Thetis;
