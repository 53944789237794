import { ui } from '@/components/ui';
import type { ProfileT } from '../../../types/database/profiles';

interface Props {
	profile: ProfileT;
	link: React.ReactNode;
}

const ProfileCard = ({ profile, link }: Props) => {
	return (
		<ui.Card>
			<ui.CardHeader>
				<ui.CardTitle>{profile.name}</ui.CardTitle>
			</ui.CardHeader>
			<ui.CardContent>
				<p>{profile.roles?.join(', ')}</p>
				<div className="flex flex-row flex-wrap gap-1 my-2">
					{profile?.organisations?.map((o) => (
						<ui.Badge variant="outline" key={o.organisation_id}>
							{o.organisation_name}
						</ui.Badge>
					))}
				</div>
			</ui.CardContent>
			<ui.CardFooter className="flex gap-2">
				<ui.Button size="sm" asChild>
					{link}
				</ui.Button>
			</ui.CardFooter>
		</ui.Card>
	);
};

export default ProfileCard;
