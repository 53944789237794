import { cn } from '@/lib/utils';
import { Link } from '@tanstack/react-router';

interface Props {
	children: React.ReactNode;
	className: string;
	diaryId: string;
}

const Cell = ({ children, className, diaryId }: Props) => {
	return (
		<Link
			to="/dashboard/diary/$diaryId"
			params={{ diaryId: diaryId }}
			search={(prev) => ({ ...prev })}
			className={cn('flex h-full items-center justify-start text-nowrap p-4', className)}
		>
			{children}
		</Link>
	);
};

export default Cell;
