import Title from '@/components/common/Title';
import useSelectOrganisationById from '@/features/auth/api/selectOrganisationById';
import type { SignupSearch } from '@/routes/_auth/signup';
import { CatchBoundary } from '@tanstack/react-router';

interface TitleContent {
	title: string;
	description?: string;
}

interface OrganisationBasicT {
	organisation_id: string;
	organisation_name: string;
}

const getTitleContent = (search: SignupSearch, organisation?: OrganisationBasicT): TitleContent => {
	try {
		if (search.quickstart) {
			return {
				title: `Sign up (${search.quickstart.quickstart_name})`,
				description: search.quickstart.quickstart_description ?? '',
			};
		}

		const roleText = search.role ? ` as ${search.role}` : '';
		const orgText = organisation?.organisation_name ? ` for ${organisation.organisation_name}` : '';

		return {
			title: `Sign up${roleText}${orgText}`,
			description: '',
		};
	} catch (e) {
		return { title: 'Sign Up' };
	}
};

const SignupTitle = ({ search }: { search: SignupSearch }) => {
	const { data } = useSelectOrganisationById(search.organisation?.organisation_id);

	const titleContent = getTitleContent(search, data);

	return (
		<CatchBoundary getResetKey={() => 'signup-title'} errorComponent={<Title words="Sign Up" />}>
			<Title words={titleContent.title} description={titleContent.description} />
		</CatchBoundary>
	);
};

export default SignupTitle;
