import { cn } from '@/lib/utils';
import { useState } from 'react';
import RatingButton from './RatingButton';

interface RatingOption {
	value: string;
	label: string;
}

interface Props {
	options: RatingOption[];
	orientation: 'vertical' | 'horizontal';
	value: string;
	onChange: (value: string) => void;
	name: string;
}

const RatingOptions = ({ options, orientation, value, onChange, name }: Props) => {
	const [focusedIndex, setFocusedIndex] = useState<number | null>(null);

	return (
		<div
			className={cn('flex gap-2 p-1 relative', orientation === 'vertical' ? 'flex-col' : 'flex-row overflow-x-auto')}
			role="radiogroup"
			aria-label={name}
		>
			{options.map((option, index) => (
				<div
					key={`scale-rating-option-${option.value}`}
					className={cn(
						'flex items-center',
						orientation === 'vertical' ? 'flex-row w-full' : 'flex-col w-12',
						orientation !== 'vertical' && index === 0 && 'items-start',
						orientation !== 'vertical' && index === options.length - 1 && 'items-end',
					)}
				>
					<RatingButton
						value={option.value}
						isSelected={value === option.value}
						isFocused={focusedIndex === index}
						onClick={() => onChange(option.value)}
						onFocus={() => setFocusedIndex(index)}
						onBlur={() => setFocusedIndex(null)}
					/>
					<span
						className={cn(
							'text-sm text-slate-600 ',
							orientation === 'vertical' ? 'ml-2 text-left w-3/4' : 'mt-1 overflow-x-visible text-nowrap',
							orientation !== 'vertical' && index === 0 && 'text-left',
							orientation !== 'vertical' && index === options.length - 1 && 'text-right',
						)}
					>
						{option.label}
					</span>
				</div>
			))}
			{orientation !== 'vertical' && (
				<div
					className="top-0 right-0 bottom-0 absolute bg-gradient-to-l from-white w-8 pointer-events-none"
					aria-hidden="true"
				/>
			)}
		</div>
	);
};

export default RatingOptions;
