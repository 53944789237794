import { Button } from '@/components/ui/button';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import EmailUs from '@/features/website/components/EmailUs';
import Section from '@/features/website/components/Section';
import SectionTitle from '@/features/website/components/SectionTitle';
import { useForm as formspree } from '@formspree/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { createFileRoute } from '@tanstack/react-router';
import { useForm } from 'react-hook-form';
import * as z from 'zod';

const FormSchema = z.object({
	name: z
		.string()
		.min(1, {
			message: 'This field has to be filled.',
		})
		.max(30, {
			message: 'Name must not be longer than 30 letters.',
		}),
	email: z.string().min(1, { message: 'This field has to be filled.' }).email('This is not a valid email.'),
	message: z
		.string()
		.min(10, {
			message: 'Bio must be at least 10 characters.',
		})
		.max(200, {
			message: 'Bio must not be longer than 200 characters.',
		}),
});

const Contact = () => {
	const form = useForm<z.infer<typeof FormSchema>>({
		resolver: zodResolver(FormSchema),
	});

	const [state, handleSubmit] = formspree('xnqkeqbe');

	if (state.succeeded) {
		return (
			<Section>
				<SectionTitle
					left={true}
					title="Thank You for Contacting Patient Watch"
					caption="Message Sent"
					description="Your message has been sent to the Patient Watch Team, we aim to get back to you within 48 hours."
				/>
			</Section>
		);
	}

	function onSubmit(values: z.infer<typeof FormSchema>) {
		handleSubmit(values);
	}

	return (
		<Section>
			<SectionTitle left={true} caption="Contact Us" title="We want to hear from you" />
			<div className="flex flex-col-reverse w-full lg:flex-row-reverse">
				<EmailUs
					email="info@patient-watch.com"
					description="If you are interested in using Patient Watch, have questions or want to learn more use the form or email us directly."
				/>
				<Form {...form}>
					<form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6 text-lg lg:w-2/3 lg:pr-24">
						<FormField
							control={form.control}
							name="name"
							render={({ field }) => (
								<FormItem>
									<FormLabel>Name</FormLabel>
									<FormControl>
										<Input placeholder="e.g. Joe Di Maggio" {...field} />
									</FormControl>
									<FormMessage />
								</FormItem>
							)}
						/>
						<FormField
							control={form.control}
							name="email"
							render={({ field }) => (
								<FormItem>
									<FormLabel>Email</FormLabel>
									<FormControl>
										<Input placeholder="e.g. joe@mail.com" {...field} />
									</FormControl>
									<FormMessage />
								</FormItem>
							)}
						/>
						<FormField
							control={form.control}
							name="message"
							render={({ field }) => (
								<FormItem>
									<FormLabel>Message</FormLabel>
									<FormControl>
										<Textarea placeholder="Let us know how we can help" className="resize-none" {...field} />
									</FormControl>
									<FormMessage />
								</FormItem>
							)}
						/>
						<Button type="submit">{state.submitting ? 'Submitting' : 'Submit'}</Button>
					</form>
				</Form>
			</div>
		</Section>
	);
};

export const Route = createFileRoute('/_website/contact')({
	component: Contact,
});
