import { components as c } from '@/components';
import { tableComponents as tc } from '@/features/dashboard/features/overview';
import type { DashboardSearchT } from '@/routes/_app/dashboard';
import type { DiariesCountT, StatefulDiaryT } from '@/types/database/diaries';
import { useIsFetching } from '@tanstack/react-query';
import { useParams } from '@tanstack/react-router';
import type { PaginationState } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import useDiariesTable from '../hooks/useDiariesTable';
import DiariesTable from './DiariesTable';
import TableMenu from './TableMenu';

interface Props {
	settings: DashboardSearchT;
	diariesCount: DiariesCountT;
	diaries: StatefulDiaryT[];
}
function DashboardTable({ settings, diariesCount, diaries }: Props) {
	const { diaryId } = useParams({ strict: false }) as { diaryId: string | undefined };

	const isFetching = useIsFetching({ queryKey: ['dashboard-diaries'] });

	const getCount = (obj: DiariesCountT | undefined, key: string | undefined): number => {
		if (!obj || !key) return 0;
		return Object.prototype.hasOwnProperty.call(obj, key) ? obj[key as keyof DiariesCountT] : 0;
	};

	const [count, setCount] = useState<number>(0);
	const [startIndex, setStartIndex] = useState<number>(1);
	const [endIndex, setEndIndex] = useState<number>(0);

	const { table, prevPage, nextPage, isFinalPage } = useDiariesTable({
		diaries: diaries ?? [],
		pagination: settings.pagination as PaginationState,
		pageCount: count,
	});

	useEffect(() => {
		const newCount = getCount(diariesCount, settings.tab);
		setCount(newCount);
	}, [diariesCount, settings.tab, getCount]);

	useEffect(() => {
		const pageSize = table.getState().pagination.pageSize;
		const pageIndex = table.getState().pagination.pageIndex;
		const newStartIndex = table.getState().pagination.pageIndex * table.getState().pagination.pageSize + 1;
		const newEndIndex = Math.min((pageIndex + 1) * pageSize, count);
		setStartIndex(newStartIndex);
		setEndIndex(newEndIndex);
	}, [table.getState, count, settings]);

	return (
		<div className="w-full">
			<div className="flex flex-row flex-wrap-reverse justify-between gap-2 my-2">
				<div className="flex justify-between md:justify-center items-center gap-x-2 md:w-fit">
					<tc.StateTabs tab={settings.tab} diariesCount={diariesCount} />
					<c.cc.Spinner className={isFetching ? 'opacity-100' : 'opacity-0'} />
				</div>

				<div className="flex justify-between md:justify-center items-center gap-x-2 w-full md:w-fit">
					<tc.Search />
					<TableMenu />
				</div>
			</div>
			<DiariesTable table={table} diaryId={diaryId} />
			<div className="flex justify-end items-center space-x-2 py-4">
				<div className="flex-1 text-muted-foreground text-sm">
					{count === 0 ? 'No diaries on this page' : `Showing diaries ${startIndex} to ${endIndex} of ${count}`}
				</div>
				<div className="space-x-2">
					<c.ui.Button variant="outline" size="sm" onClick={prevPage} disabled={!table.getCanPreviousPage()}>
						Previous
					</c.ui.Button>
					<c.ui.Button variant="outline" size="sm" onClick={() => nextPage()} disabled={isFinalPage}>
						Next
					</c.ui.Button>
				</div>
			</div>
		</div>
	);
}

export default DashboardTable;
