import { ui } from '@/components/ui';
import type { StatefulTrackerViewT } from '@/types/database/trackers';
import type { SizeT } from '@/types/zod/common';

import TrackerBody from './TrackerBody';
import TrackerPreview from './TrackerPreview';

interface Props {
	tracker: StatefulTrackerViewT;
	editable?: boolean;
	size?: SizeT;
}
const TrackerCard = ({ tracker, editable, size }: Props) => {
	return (
		<>
			<ui.CardHeader className="flex">
				<TrackerPreview size={size} editable={editable} tracker={tracker} />
			</ui.CardHeader>
			<ui.CardContent>
				<TrackerBody tracker={tracker} />
			</ui.CardContent>
			<ui.CardFooter>
				<ui.Button className="w-full" variant="secondary">
					Print
				</ui.Button>
			</ui.CardFooter>
		</>
	);
};

export default TrackerCard;
