import CardGrid from '@/components/common/CardGrid';
import Title from '@/components/common/Title';
import { ui } from '@/components/ui';
import OrganisationsComboBox from '@/features/organisations/components/OrganisationsComboBox';
import { selectProfilesQueryOptions } from '@/features/profile/api/selectProfiles';
import ProfileCard from '@/features/profile/components/UserCard';
import { Route as UserRoute } from '@/routes/_app/admin/user/$userId';
import type { ProfileT } from '@/types/database/profiles';
import { Link, createFileRoute } from '@tanstack/react-router';
import React from 'react';
import { useForm } from 'react-hook-form';
import SelectRole from '../../../features/invite/components/SelectRole';

const UsersPage = () => {
	const { profiles } = Route.useLoaderData();
	const form = useForm();

	const renderProfileCard = (p: ProfileT) => (
		<ProfileCard
			link={
				<Link to={UserRoute.to} params={{ userId: p.user_id }}>
					Open
				</Link>
			}
			profile={p}
		/>
	);

	const org = form.watch('organisation_id');
	const role = form.watch('role');

	const [shown, setShown] = React.useState<ProfileT[]>(profiles);

	React.useMemo(() => {
		let filtered = profiles;
		if (org) filtered = filtered.filter((p) => p.organisations?.map((o) => o.organisation_id).includes(org));
		if (role) filtered = filtered.filter((p) => p.roles?.includes(role.toUpperCase()));
		setShown(filtered);
	}, [org, role, profiles]);

	return (
		<section className="mt-8 px-4">
			<Title words={`${shown.length} Profiles`} />
			<ui.Form {...form}>
				<div className="flex flex-row flex-wrap">
					<OrganisationsComboBox />
					<SelectRole />
				</div>
			</ui.Form>
			<CardGrid items={shown} renderItem={renderProfileCard} />
		</section>
	);
};

export const Route = createFileRoute('/_app/admin/users')({
	component: UsersPage,
	loader: async ({ context }) => {
		const profiles = (await context?.queryClient.ensureQueryData(selectProfilesQueryOptions())) as ProfileT[];
		return { profiles };
	},
});
