import UnauthorizedPage from '@/components/pages/UnauthorizedPage';
import { diaryByIdQueryOptions } from '@/features/diary/api/getDiaryById';
import { diariesBreadcrumb, editBreadcrumb } from '@/features/navigation/services/breadcrumbRoutes';
import { hooks as h } from '@/hooks';
import { createFileRoute } from '@tanstack/react-router';

const EditEventPage = () => {
	const { auth } = Route.useRouteContext();
	const { diaryId, eventId, injectionId } = Route.useParams();

	h.useNav([
		diariesBreadcrumb,
		editBreadcrumb,
		{
			route: {
				to: '/diary/$diaryId/edit/event/$eventId/injection/$injectionId',
				params: { diaryId, eventId, injectionId },
			},
			label: 'Event',
		},
	]);

	if (!auth.roles?.isClinician) {
		return <UnauthorizedPage message="This page is only editable by clinicians." />;
	}

	return (
		<section className="flex flex-col gap-4 items-center justify-center">
			{/* <ui.Tabs defaultValue="injection" className="w-full justify-center items-center flex flex-col">
				<ui.TabsList className="mx-auto">
					<ui.TabsTrigger asChild value="injection">
						<Link to={InjectionRoute.to} params={{ diaryId, eventId, injectionId }}>
							Injection
						</Link>
					</ui.TabsTrigger>
					<ui.TabsTrigger asChild value="details">
						<Link to={DetailsRoute.to} params={{ diaryId, eventId, injectionId }}>
							Details
						</Link>
					</ui.TabsTrigger>
				</ui.TabsList>
				<ui.TabsContent value="injection">
					<InjectionForm
						injection={injection}
						diaryId={diaryId}
						eventId={eventId}
						injectionId={injectionId}
						navigateToNextPage={() =>
							navigate({ to: EditInjectionDetailsRoute.to, params: { diaryId, injectionId, eventId } })
						}
					/>
				</ui.TabsContent>
			</ui.Tabs> */}
		</section>
	);
};

export const Route = createFileRoute('/_app/diary/$diaryId/edit/event/$eventId/injection/$injectionId')({
	component: EditEventPage,
	loader: async ({ context, params }) => {
		await context?.queryClient.ensureQueryData(diaryByIdQueryOptions(params.diaryId));
	},
});
