import type { StatefulDiaryT } from '@/types/database/diaries';
import type { Surgery } from '@/types/database/events';

import { useDiaryByIdQuery } from '../../../../diary/api/getDiaryById';

function extractSurgeryById(diary: StatefulDiaryT, eventId: string, surgeryId: string) {
	const event = diary?.events_view?.find((e) => e.event_id === eventId);
	const surgery = event?.surgeries?.find((surgery: Surgery['Row']) => surgery.surgery_id === surgeryId);
	return surgery;
}

const useExtractSurgery = (diaryId: string, eventId: string, surgeryId: string) => {
	const { data: diary } = useDiaryByIdQuery(diaryId);
	return extractSurgeryById(diary, eventId, surgeryId) as Surgery['Row'];
};

export default useExtractSurgery;
