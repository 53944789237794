import { createFileRoute } from '@tanstack/react-router';

import { commonComponents as cc } from '@/components/common';
import AuthWrap from '@/features/auth/components/AuthWrap';
import SmartAuthForm from '@/features/auth/components/SmartAuthForm';

const AuthenticatePage = () => {
	return (
		<AuthWrap>
			<cc.Title words="Sign up / Sign in" />
			<SmartAuthForm />
		</AuthWrap>
	);
};

export const Route = createFileRoute('/_auth/authenticate')({
	component: AuthenticatePage,
});
