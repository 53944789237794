import { ExtendedEventViewT } from '@/types/database/events';
import { Syringe } from 'lucide-react';

import InjectionLink from './InjectionLink';
import MissingInjectionsLink from './MissingInjectionsLink';

interface Props {
	event: ExtendedEventViewT;
}

const InjectionLinks = ({ event }: Props) => {
	return (
		<ul>
			{event.injections_view?.length ? (
				event.injections_view.map((inj, index) => (
					<li key={inj.injection_id} className="">
						<h4 className="text-md flex w-fit items-center justify-center gap-2 pb-2 font-medium text-slate-900">
							<Syringe />
							Injection {index > 0 && index + 1}
						</h4>

						<InjectionLink diaryId={event.diary_id} injection={inj} />
					</li>
				))
			) : (
				<MissingInjectionsLink eventId={event.event_id} diaryId={event.diary_id} />
			)}
		</ul>
	);
};

export default InjectionLinks;
