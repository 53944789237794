import GDPR from '@/assets/compliance/GDPR.png';
import ICO from '@/assets/compliance/ICO.png';
import MHRA from '@/assets/compliance/MHRA.png';
import UKCA from '@/assets/compliance/UKCA.svg';

import ImageReel from './ImageReel';

const partners = [
	{ alt: 'GDPR', src: GDPR },
	{ alt: 'ICO', src: ICO },
	{ alt: 'MHRA', src: MHRA },
	{ alt: 'UKCA', src: UKCA },
];
export function ComplianceReel() {
	return <ImageReel items={partners} direction="right" />;
}
