import { components as c } from '@/components';
import { Route as GuidedInjectionFormRoute } from '@/routes/_app/new-diary/patient/$patientId/organisation/$organisationId/diary/guided/injection-diary/time';
import { Route as InviteRoute } from '@/routes/_app/new-diary/patient/invite';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate } from '@tanstack/react-router';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import GuidedDiaryRouteSelect from './invite-patient/GuidedDiaryRouteSelect';

export const NewDiaryFormSchemaZ = z.object({
	organisation: z.string().min(1, 'Required'),
	diary_route: z.string().min(1, 'Required'),
});

export type NewDiaryFormSchemaT = z.infer<typeof NewDiaryFormSchemaZ>;

const DiaryRouteForm = () => {
	const navigate = useNavigate();

	const form = useForm<z.infer<typeof NewDiaryFormSchemaZ>>({
		resolver: zodResolver(NewDiaryFormSchemaZ),
		defaultValues: {
			diary_route: GuidedInjectionFormRoute.to,
		},
	});

	const onSubmit = (data: z.infer<typeof NewDiaryFormSchemaZ>) => {
		navigate({
			to: InviteRoute.to,
			search: { organisation: JSON.parse(data.organisation), diary_route: data.diary_route },
		});
	};

	return (
		<c.ui.Form {...form}>
			<form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
				<GuidedDiaryRouteSelect />
				<c.fc.SelectFromMyOrganisations />
				<div className="flex flex-col gap-y-2">
					<c.ui.Button size="lg" type="submit">
						Next
					</c.ui.Button>
				</div>
			</form>
		</c.ui.Form>
	);
};

export default DiaryRouteForm;
