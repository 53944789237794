import { formComponents as fc } from '@/components/form';
import { ui } from '@/components/ui';
import type { ExtendedDiaryViewT } from '@/types/database/diaries';
import { UuidZ } from '@/types/zod/auth';
import { zodResolver } from '@hookform/resolvers/zod';
import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';
import type { SubmitHandler } from 'react-hook-form';
import { z } from 'zod';

import useUpdateDiary from '../../api/updateDiary';

export const DiarySchemaZ = z.object({
	diary_id: UuidZ,
	diary_name: z.string().min(1, 'Required'),
});

export type DiarySchemaT = z.infer<typeof DiarySchemaZ>;

interface Props {
	diaryId: string;
	diary?: ExtendedDiaryViewT;
}
const DiaryNameForm = ({ diaryId, diary }: Props) => {
	const form = useForm<DiarySchemaT>({
		resolver: zodResolver(DiarySchemaZ),
		defaultValues: {
			diary_id: diaryId,
			diary_name: diary?.diary_name ?? undefined,
		},
	});

	const updateDiary = useUpdateDiary();

	const onSubmit: SubmitHandler<DiarySchemaT> = async (formData) => {
		updateDiary.mutate({ diary_id: formData.diary_id, diary_name: formData.diary_name ?? null });
	};

	return (
		<ui.Form {...form}>
			<form onSubmit={form.handleSubmit(onSubmit)} className="form">
				<fc.Input label="Diary Name" name="diary_name" type="text" />

				<div className="my-4 flex flex-col gap-y-4">
					<ui.Button size="lg" type="submit">
						Next
					</ui.Button>
				</div>
			</form>
		</ui.Form>
	);
};

export default DiaryNameForm;
