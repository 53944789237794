import { ui } from '@/components/ui';
import { GripVertical } from 'lucide-react';
import React from 'react';

import { inputComponents as ic } from '.';

import type { InputT } from '@/types/database/diaries';
interface Props {
	label: string;
	input: InputT;
}
const EditInputCard = ({ input, label }: Props) => {
	const [editing, setEditing] = React.useState(false);
	return (
		<ui.Card>
			<ui.CardHeader className="flex flex-row items-center justify-between gap-6 space-y-0 text-lg">
				<div className="flex w-full flex-row items-center justify-start gap-2 text-lg">
					<GripVertical className="cursor-move" />
					<p className="w-5/6">{label}</p>
				</div>
				<ui.Button variant="link" className="px-0" onClick={() => setEditing(!editing)}>
					{editing ? 'Done' : 'Edit'}
				</ui.Button>
			</ui.CardHeader>
			{editing && (
				<ui.CardContent>
					<ic.InputSettings input={input} />
				</ui.CardContent>
			)}
		</ui.Card>
	);
};

export default EditInputCard;
