import { cn } from '@/lib/utils';
import type { SurgeryViewT } from '@/types/database/events';
import { warningColors } from '@/utils/warningColors';
import { Crosshair, Hammer } from 'lucide-react';

interface Props {
	surgery: SurgeryViewT;
}
const SurgerySummary = ({ surgery }: Props) => {
	return (
		<div className={cn('text-sm')}>
			<p>
				Surgery referred by{' '}
				<span className={cn('text-semibold', warningColors(!surgery?.referring_clinician_id))}>
					{surgery?.referring_clinician_id ? surgery?.referring_clinician_name : 'an unknown clinician'}
				</span>{' '}
				and performed by{' '}
				<span className={cn('text-semibold', warningColors(!surgery?.operating_clinician_id))}>
					{surgery?.operating_clinician_id ? surgery?.operating_clinician_name : 'an unknown clinician'}
				</span>
				.
			</p>
			<p className={cn('flex flex-col gap-y-1 ml-2')}>
				<span
					className={cn(
						warningColors(!surgery.orthopaedic_area || !surgery.side),
						'flex gap-2 items-center justify-start',
					)}
				>
					<Crosshair size={20} />
					{surgery.side ?? 'Unknown Side'} {surgery.orthopaedic_area ?? 'Unknown Anatomic Region'}
				</span>
				<span className={cn(warningColors(!surgery.surgery_name), 'flex gap-2 items-center justify-start')}>
					<Hammer size={20} />
					{surgery.surgery_name}
				</span>
			</p>
		</div>
	);
};

export default SurgerySummary;
