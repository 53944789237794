import { commonComponents as cc } from '@/components/common';
import DiaryTitle from '@/features/diaries/components/DiaryTitle';
import { diaryByIdQueryOptions } from '@/features/diary/api/getDiaryById';
import { hooks as h } from '@/hooks';
import { Outlet, createFileRoute } from '@tanstack/react-router';
const Edit = () => {
	const { diaryId } = Route.useParams();
	const { diary } = Route.useLoaderData();

	h.useNav([
		{ route: { to: '/diaries' }, label: 'Diaries' },
		{ route: { to: '/diary/$diaryId/home/new', params: { diaryId: diaryId } }, label: 'Diary' },
		{ route: { to: '/diary/$diaryId/edit', params: { diaryId: diaryId } }, label: 'Edit' },
	]);

	return (
		<section className="md-width">
			<DiaryTitle diary={diary} editing={true} />
			<Outlet />
		</section>
	);
};

export const Route = createFileRoute('/_app/diary/$diaryId/edit')({
	component: Edit,
	loader: async ({ context, params }) => {
		const diary = await context?.queryClient.ensureQueryData(diaryByIdQueryOptions(params.diaryId));
		return { diary };
	},
});
