import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Label } from '@/components/ui/label';
import { useFormContext } from 'react-hook-form';
import RatingOptions from './RatingOptions';

import type { InputT } from '@/types/database/forms';

interface Props {
	input: InputT;
	name: string;
	label: string;
}

const Rating = ({ input, name, label }: Props) => {
	const { control } = useFormContext();

	return (
		<FormField
			control={control}
			name={name}
			render={({ field }) => (
				<FormItem>
					<FormLabel>
						<Label>{label}</Label>
					</FormLabel>
					<FormControl>
						<RatingOptions
							options={input.scale_options || []}
							orientation={input.orientation || 'horizontal'}
							value={field.value}
							onChange={field.onChange}
							name={name}
						/>
					</FormControl>
					<FormMessage />
				</FormItem>
			)}
		/>
	);
};

export default Rating;
