import { cn } from '@/lib/utils';
import { type VariantProps, cva } from 'class-variance-authority';
import * as React from 'react';

const callVariants = cva(
	'relative w-full rounded-lg border text-sm p-4 [&>svg~*]:pl-10 [&>svg+div]:translate-y-[-3px] [&>svg]:absolute [&>svg]:left-4 [&>svg]:top-4 [&>svg]:h-8 [&>svg]:w-8 dark:border-slate-800',
	{
		variants: {
			variant: {
				info: 'bg-indigo-100 border-indigo-300 text-indigo-800 ',
				destructive: 'bg-red-100 border-red-300 text-red-800',
				warning: 'bg-amber-100 border-amber-300 text-amber-800',
				success: 'bg-green-100 border-green-300 text-green-800',
			},
		},
		defaultVariants: {
			variant: 'info',
		},
	},
);

const Call = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement> & VariantProps<typeof callVariants>>(
	({ className, variant, ...props }, ref) => (
		<div ref={ref} role="alert" className={cn(callVariants({ variant }), className)} {...props} />
	),
);
Call.displayName = 'Call';

const CallTitle = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLHeadingElement>>(
	({ className, ...props }, ref) => (
		<h5 ref={ref} className={cn('text-md mb-1 font-medium leading-none tracking-tight', className)} {...props} />
	),
);
CallTitle.displayName = 'CallTitle';

const CallDescription = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLParagraphElement>>(
	({ className, ...props }, ref) => (
		<div ref={ref} className={cn('text-sm [&_p]:leading-relaxed', className)} {...props} />
	),
);
CallDescription.displayName = 'CallDescription';

export { Call, CallDescription, CallTitle };
