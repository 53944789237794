import { supabaseClient } from '@/lib/supabase';
import { useQuery } from '@tanstack/react-query';

const selectStaff = async () => {
	const { data, error } = await supabaseClient
		.from('profiles')
		.select('*')
		.overlaps('roles', ['CLINICIAN', 'SECRETARY']);
	if (error) throw error;
	return data;
};

export const selectStaffQueryKey = ['select-staff'];

export const useSelectStaff = () => {
	return useQuery({
		queryKey: selectStaffQueryKey,
		queryFn: () => selectStaff(),
	});
};
