import { supabaseClient } from '@/lib/supabase';
import { queryOptions, useQuery } from '@tanstack/react-query';

function getInjectionLocations() {
	return supabaseClient
		.from('injection_locations')
		.select('*')
		.throwOnError()
		.then((result) => result.data);
}

export const injection_locations_key = ['injection-locations'];

export const injectionLocationsQueryOptions = () => {
	return queryOptions({
		queryKey: injection_locations_key,
		queryFn: async () => getInjectionLocations(),
	});
};

export function useInjectionLocationsQuery() {
	return useQuery(injectionLocationsQueryOptions());
}
