import React from 'react';

const Change = () => {
    return (
        <svg width="41" height="49" viewBox="0 0 41 49" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M20.4916 1.09748C19.9829 0.607346 19.1855 0.640099 18.7157 1.16798C18.251 1.69016 18.2803 2.50255 18.7834 2.98727L20.8342 4.96292C12.1899 4.97404 5.18846 10.3356 2.24878 17.4301C-0.996296 25.2617 0.750114 35.0699 10.2312 42.0117C10.8007 42.4287 11.5859 42.2829 11.9818 41.6975C12.3738 41.1178 12.243 40.3159 11.6796 39.9034C3.12772 33.6419 1.78485 25.1217 4.55064 18.4469C7.15733 12.156 13.4513 7.3856 21.261 7.55211L18.7489 10.1659C18.2647 10.6698 18.2647 11.4827 18.7489 11.9865C19.2385 12.496 20.0366 12.496 20.5262 11.9865L24.789 7.55119C25.4202 6.89441 25.4009 5.82706 24.7452 5.19536L20.4916 1.09748Z"
                fill="black"
                stroke="black"
                strokeWidth="0.5"
                strokeLinecap="round"
            />
            <path
                d="M20.5084 47.9025C21.0171 48.3927 21.8145 48.3599 22.2843 47.832C22.749 47.3098 22.7197 46.4975 22.2166 46.0127L20.1658 44.0371C28.8101 44.026 35.8115 38.6644 38.7512 31.5699C41.9963 23.7383 40.2499 13.9301 30.7688 6.98829C30.1993 6.57129 29.4142 6.7171 29.0182 7.30253C28.6262 7.88221 28.757 8.68415 29.3204 9.09663C37.8723 15.3581 39.2151 23.8783 36.4494 30.5531C33.8427 36.844 27.5487 41.6144 19.7389 41.4479L22.2511 38.8341C22.7353 38.3302 22.7353 37.5173 22.2511 37.0135C21.7615 36.504 20.9634 36.504 20.4738 37.0135L16.211 41.4488C15.5798 42.1056 15.5991 43.1729 16.2548 43.8046L20.5084 47.9025Z"
                fill="black"
                stroke="black"
                strokeWidth="0.5"
                strokeLinecap="round"
            />
        </svg>
    );
};

export default Change;
