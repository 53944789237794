import { components as c } from '@/components';
import { ui } from '@/components/ui';
import type { TrackerTemplateViewT } from '@/types/database/trackers';
import { type SubmitHandler, useForm, z, zodResolver } from '@/utils/form';
import dayjs from 'dayjs';
import { Calendar, Clipboard, User2 } from 'lucide-react';
import { cn } from '../../../lib/utils';
import { useAuth } from '../../../utils/auth';
import FavouriteUsersComboBox from '../../profile/components/FavouriteUsersComboBox';
import { useInsertTracker } from '../../trackers/api/insertTracker';
import { hideTemplate } from '../utils/hideTemplate';
import Draft from './Draft';

export const TrackerSchemaZ = z.object({
	contact_person_id: z.string().uuid().optional(),
	tracker_name: z.string().optional().nullable(),
	diary_id: z.string().uuid(),
	tracker_id: z.string().uuid(),
	start_date: z.string().datetime(),
	reference_date: z.string().datetime().optional().nullable(),
	form_id: z.string().uuid(),
	schedule_id: z.string().uuid(),
	back_up_schedule_id: z.string().uuid().optional().nullable(),
	contact_id: z.string().uuid().nullable(),
	emergency_contact_id: z.string().uuid().nullable(),
	tracker_template_id: z.string().uuid(),
	management_types: z.array(z.string()),
	tracker_type: z.string().nullable(),
	is_pausable: z.boolean().optional(),
	late_timing_id: z.string().uuid().nullable().optional(),
	reminder_schedule_id: z.string().uuid().nullable().optional(),
});

type TrackerFromTemplateSchemaT = z.infer<typeof TrackerSchemaZ>;
interface Props {
	diaryId: string;
	trackerId: string;
	tracker_template: TrackerTemplateViewT;
	onSuccess: () => void;
}
const TrackerTemplateCard = ({ diaryId, trackerId, tracker_template, onSuccess }: Props) => {
	const auth = useAuth();

	const { mutate: insertTracker } = useInsertTracker();

	const form = useForm<TrackerFromTemplateSchemaT>({
		resolver: zodResolver(TrackerSchemaZ),
		defaultValues: {
			diary_id: diaryId,
			tracker_id: trackerId,
			start_date: dayjs().toISOString(),
			form_id: tracker_template.form_id,
			schedule_id: tracker_template.schedule_id,
			back_up_schedule_id: tracker_template.back_up_schedule_id,
			contact_id: tracker_template.contact_id ?? null,
			emergency_contact_id: tracker_template.emergency_contact_id ?? null,
			tracker_template_id: tracker_template.tracker_template_id ?? '',
			management_types: tracker_template.management_types ?? [],
			tracker_type: tracker_template.tracker_type ?? null,
			is_pausable: tracker_template.is_pausable ?? false,
			late_timing_id: tracker_template.late_timing_id,
			reminder_schedule_id: tracker_template.reminder_schedule_id ?? null,
		},
	});

	const onSubmit: SubmitHandler<TrackerFromTemplateSchemaT> = async (formData) => {
		await insertTracker(formData, {
			onSuccess: onSuccess,
		});
	};

	return (
		<ui.Form {...form}>
			<form onSubmit={form.handleSubmit(onSubmit)} className="form">
				<ui.Card>
					{tracker_template?.is_draft && <Draft>Draft Tracker Template</Draft>}
					<c.fc.Errors />
					<ui.CardHeader>
						<ui.CardTitle>{tracker_template.tracker_template_name}</ui.CardTitle>
					</ui.CardHeader>

					<ui.CardContent className="flex flex-col gap-y-2">
						<c.cc.IconDetail icon={<Clipboard />} words={tracker_template.form.form_name} />
						<c.cc.IconDetail icon={<Calendar />} words={tracker_template.schedules?.schedule_name} />
						{auth.roles?.isClinician && (
							<c.cc.IconDetail
								className="pr-12 w-full"
								icon={<User2 />}
								words={
									<FavouriteUsersComboBox
										includeUser={true}
										placeholder="Contact Person"
										name="contact_id"
										form={form}
									/>
								}
							/>
						)}
					</ui.CardContent>
					<ui.CardFooter>
						<ui.Button>Select</ui.Button>
					</ui.CardFooter>
				</ui.Card>
			</form>
		</ui.Form>
	);
};

export default TrackerTemplateCard;
