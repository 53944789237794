import { formComponents as fc } from '@/components/form';
import { ui } from '@/components/ui';
import { filterSurgeryOptions } from '@/features/injection/utils/injectionOptions';
import { SideZ } from '@/types/zod/common';
import { type SubmitHandler, useForm, useWatch, z, zodResolver } from '@/utils/form';
import { useAnatomicRegionsQuery } from '../../../api/selectAnatomicRegions';
import { sideOptions } from '../../../utils/sideOptions';
import { useSelectSurgeryOptions } from '../api/selectSurgeryOptions';
import { useSurgeryMutation } from '../api/upsertSurgery';
import SurgeryOptionForm from './SurgeryOptionForm';

interface Props {
	diaryId: string;
	eventId: string;
	surgeryId: string;
	navigateToNextPage?: () => void;
	surgery?: SurgeryViewT;
}

const SurgerySchemaZ = z.object({
	event_id: z.string(),
	surgery_id: z.string(),
	side: SideZ,
	surgery_option_id: z.string().uuid(),
});
type SurgerySchemaT = z.infer<typeof SurgerySchemaZ>;

const SurgeryForm = ({ surgery, diaryId, eventId, surgeryId, navigateToNextPage }: Props) => {
	const { data: surgeryOptions } = useSelectSurgeryOptions();
	const { data: anatomicRegions } = useAnatomicRegionsQuery();

	const surgeryMutation = useSurgeryMutation({ diaryId: diaryId });

	const form = useForm<SurgerySchemaT>({
		resolver: zodResolver(SurgerySchemaZ),
		defaultValues: {
			event_id: eventId,
			surgery_id: surgeryId,
			surgery_option_id: surgery?.surgery_option_id ?? undefined,
			orthopaedic_area: surgery?.orthopaedic_area ?? undefined,
			side: surgery?.side ?? undefined,
		},
	});

	const watch = useWatch({ control: form.control });

	const onSubmit: SubmitHandler<SurgerySchemaT> = async (formData) => {
		surgeryMutation.mutate(
			{
				event_id: formData.event_id,
				surgery_option_id: formData.surgery_option_id,
				surgery_id: formData.surgery_id,
				side: formData.side,
			},
			{
				onSuccess: () => {
					!!navigateToNextPage && navigateToNextPage();
				},
			},
		);
	};

	return (
		<ui.Form {...form}>
			<form onSubmit={form.handleSubmit(onSubmit)} className="form">
				{/* Referrer */}
				<fc.Select name="side" label="Side" options={sideOptions} />

				<fc.Select
					autoFocus
					name="orthopaedic_area"
					label="Anatomic Region"
					options={anatomicRegions?.map((ar) => ({ label: ar.orthopaedic_area, value: ar.orthopaedic_area })) ?? []}
				/>
				<fc.Select
					options={filterSurgeryOptions(surgeryOptions, 'orthopaedic_area', watch.orthopaedic_area)}
					name="surgery_option_id"
					label="Surgery"
					addOptionForm={<SurgeryOptionForm anatomicRegion={watch.orthopaedic_area} />}
				/>
				<ui.Button size="lg" type="submit">
					Next
				</ui.Button>
			</form>
		</ui.Form>
	);
};

export default SurgeryForm;
