import { z } from '@/utils/form';
import { createFileRoute, useNavigate } from '@tanstack/react-router';

import { commonComponents as cc } from '@/components/common';
import AuthWrap from '@/features/auth/components/AuthWrap';
import LoginForm from '@/features/auth/components/LoginForm';

const Login = () => {
	const { email } = Route.useSearch();
	const navigate = useNavigate();
	const onSuccess = () => {
		navigate({ to: '/authentication-complete' });
	};

	return (
		<AuthWrap>
			<cc.Title words="Sign in" />
			<LoginForm email={email} onSuccess={onSuccess} />
		</AuthWrap>
	);
};

const loginSchemaZ = z.object({
	email: z.string().email().optional(),
});

export const Route = createFileRoute('/_auth/login')({
	component: Login,
	validateSearch: (search) => loginSchemaZ.parse(search),
});
