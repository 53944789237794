import { icons } from '@/assets/icons';
import { commonComponents as cc } from '@/components/common';
import useNextFootWatchPage from '@/features/foot-watch/hooks/useNextFootWatchPage';
import { capitaliseSentence } from '@/services/capitaliseSentence';
import { createFileRoute } from '@tanstack/react-router';

const PrepForTop = () => {
	const { angle, side, diaryId } = Route.useParams();
	const { navigateNext } = useNextFootWatchPage(diaryId, 'instruction', angle, side);

	return (
		<div className="mx-auto h-full w-full flex-col content-between items-stretch justify-between px-2">
			<cc.Title words={capitaliseSentence(angle + ' ' + side)} />
			<div className="mx-auto mb-6 mt-4 w-[50vw] max-w-[250px] flex-col justify-center">
				{angle === 'top' ? <icons.TopOfFootDrawing /> : <icons.SoleOfFootDrawing />}
			</div>
			{angle === 'top' ? (
				<ol className="mx-auto flex list-decimal flex-col px-8 py-4 text-xl font-medium">
					<li className="mb-4">Sit at a table in a well lit room and remove shoes and socks.</li>
					<li className="mb-4">Press ‘next’ to start the timer.</li>
					<li className="">Point the camera at your foot and wait for the photo to be taken.</li>
				</ol>
			) : (
				<ol className="mx-auto flex list-decimal flex-col px-8 py-4 text-xl font-medium">
					<li className="mb-4">Press "next" to start the timer.</li>
					<li className="mb-4">Place your phone screen-up on the floor under the table.</li>
					<li className="mb-0">
						Position your foot in frame, keeping your face out of the photo, and wait for the photo to be taken.
					</li>
				</ol>
			)}

			<cc.FooterButtons
				func1={() => navigateNext()}
				words1="Next"
				icon1={
					<div className="ml-[2px] flex h-8 w-8 rotate-180 items-center justify-center p-[6px]">
						<icons.BackArrow fill="white" />
					</div>
				}
			/>
		</div>
	);
};

export const Route = createFileRoute(
	'/_app/diary/$diaryId/new/tracker/$trackerId/entry/$entryId/page/$pageIndex/foot-photos/instructions/$angle/$side',
)({
	component: PrepForTop,
});
