import { Control } from 'react-hook-form';

import { ui } from '../ui';

interface Props {
    control: Control;
    name: string;
    label: string;
    disabled?: boolean;
    tabIndex?: number;
}

const Text: React.FC<Props> = ({ name, label, control, disabled, tabIndex }) => {
    return (
        <ui.FormField
            control={control}
            name={name}
            disabled={disabled}
            data-cy={name}
            render={({ field }) => (
                <ui.FormItem>
                    <ui.FormLabel>{label}</ui.FormLabel>
                    <ui.FormControl>
                        <ui.Input tabIndex={tabIndex} type="text" {...field} />
                    </ui.FormControl>
                    <ui.FormMessage />
                </ui.FormItem>
            )}
        />
    );
};

export default Text;
