import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { ui } from "@/components/ui";
import { supabaseClient } from "@/lib/supabase";
import type { StatefulDiaryT } from "@/types/database/diaries";
import { Copy, RefreshCw } from "lucide-react";
import { toast } from "sonner";
import { cn } from "@/lib/utils";

const getAiDiarySummary = async (diary: StatefulDiaryT) => {
    const { data, error } = await supabaseClient.functions.invoke(
        "public-ai-summary",
        {
            body: ({ diary: diary }),
        },
    );
    if (error) throw error;
    return data;
};
const useAiSummary = (diary: StatefulDiaryT) => {
    return useQuery({
        queryKey: ["ai-summary", { diary_id: diary.diary_id }],
        queryFn: () => getAiDiarySummary(diary),
        enabled: false,
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
    });
};

interface AiSummaryProps {
    diary: StatefulDiaryT;
}

const AiSummary = ({ diary }: AiSummaryProps) => {
    const { data: summary, isLoading, isFetching, error, refetch } =
        useAiSummary(diary);
    const [displayedSummary, setDisplayedSummary] = useState("");
    const [wordIndex, setWordIndex] = useState(0);
    const [isCopied, setIsCopied] = useState(false);

    useEffect(() => {
        setDisplayedSummary(""); // Reset displayed summary
        setWordIndex(0); // Reset word index

        if (summary) {
            const words = summary.split(" ");
            const intervalId = setInterval(() => {
                setWordIndex((prevIndex) => {
                    if (prevIndex < words.length) {
                        setDisplayedSummary(
                            words.slice(0, prevIndex + 1).join(" "),
                        );
                        return prevIndex + 1;
                    }
                    clearInterval(intervalId);
                    return prevIndex;
                });
            }, 100);

            return () => clearInterval(intervalId);
        }
    }, [summary]);

    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(summary || "");
        setIsCopied(true);
        toast.success("Copied to clipboard");
        setTimeout(() => setIsCopied(false), 300);
    };

    return (
        <ui.Card>
            <ui.CardHeader className="flex flex-row justify-between items-center">
                <ui.CardDescription>AI Summary</ui.CardDescription>
                <div className="flex gap-2 text-neutral-600">
                    <ui.Button
                        variant="ghost"
                        size="icon"
                        onClick={() => refetch()}
                        className={cn(
                            "p-0 h-8 w-8 rounded-sm",
                        )}
                        disabled={isFetching}
                    >
                        <RefreshCw
                            className={cn(
                                "transition",
                                isFetching && "animate-spin",
                            )}
                        />
                    </ui.Button>
                    <ui.Button
                        disabled={isLoading}
                        variant="ghost"
                        size="icon"
                        onClick={handleCopyToClipboard}
                        className={cn(
                            "p-0 h-8 w-8 rounded-sm",
                        )}
                    >
                        <Copy />
                    </ui.Button>
                </div>
            </ui.CardHeader>
            <ui.CardContent>
                {isLoading
                    ? (
                        <div className="flex flex-col gap-2">
                            <ui.Skeleton className="w-full h-4" />
                            <ui.Skeleton className="w-full h-4" />
                            <ui.Skeleton className="w-3/4 h-4" />
                        </div>
                    )
                    : error
                    ? <p className="text-red-500">Error loading summary</p>
                    : (
                        <p className={cn(isCopied && "animate-wiggle")}>
                            {displayedSummary}
                        </p>
                    )}
                {!isLoading && !error && !summary && (
                    <ui.Button
                        variant="outline"
                        size="sm"
                        onClick={() => refetch()}
                        disabled={isFetching}
                    >
                        Generate
                    </ui.Button>
                )}
            </ui.CardContent>
        </ui.Card>
    );
};

export default AiSummary;
